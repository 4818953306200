<app-pharmacy-navbar></app-pharmacy-navbar>
<section class="blog-area-two pt-100">
    <div class="container">
        <h2 class="mb-20 color-red">
            Best Selling products
        </h2>
        <div class="row">
            <h4 class="mb-20 mt-50">Health Care Products</h4>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <h4 class="mb-20 mt-50">Personal Care Products</h4>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <h4 class="mb-20 mt-50">Wellness</h4>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <h4 class="mb-20 mt-50">Energy Drinks</h4>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <h4 class="mb-20 mt-50">Medical Kits</h4>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Product Name</a></h3>
                        <p><span class="font-size-25 ml-10">&#x20B9;449</span>
                            <del class="ml-10">&#x20B9;550</del>
                            <span class="color-red ml-10">16% Off</span></p>
                        <p>product Description</p>
                        <ul>
                            <li><a routerLink="/blog-details">Add To Cart</a></li>
                            <li>
                                <button class="btn btn-outline-danger">Buy Now</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid text-center mt-20 mb-20" style="background-color: #ced4da; padding: 50px">
        <section style="padding: 0px;">
            <h2>Still not sure what is right for you?</h2>
            <p>Take our assessment, our doctors will understand your concern and recommend the right regime for you.
                Backed by research, trusted by doctors.</p>
            <a class="btn btn-outline-primary" href="/appointment">Begin Consultation</a>
        </section>
    </div>
</section>
