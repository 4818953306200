import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";

@Component({
    selector: 'app-panchakarma-therapies-details',
    templateUrl: './panchakarma-therapies-details.component.html',
    styleUrls: ['./panchakarma-therapies-details.component.scss']
})
export class PanchakarmaTherapiesDetailsComponent implements OnInit {
    hostName: any = 'https://www.draxico.com/panchakarma-treatment';
    panchkarmaName: any;
    panchkarmaUrlArr: any;
    validUrl: any = [];

    constructor(private route: ActivatedRoute,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaServices: MetaService,
                private metaService: Meta) {
    }

    ngOnInit(): void {
        this.validUrl = [
            'shirodhara',
            'abhyanga',
            'sthanik-basti',
            'nasyam',
            'kizhi',
            'swedana',
            'lepam',
            'pizhichil',
            'eye-treatments',
            'udavartana',
            'thalapothichil',
            'pichu',
            'vamana',
            'virechana',
            'basti',
            'janu-basti',
            'kati-basti',
            'leech-therapy',
            'urticaria',
        ];
        this.panchkarmaName = this.route.snapshot.params['panchakarma-therapies-name'];
        if (!this.validUrl.includes(this.panchkarmaName)) {
            this.router.navigateByUrl('/404-error-page');
        }
        this.metaServices.updateCanonicalUrl(this.hostName + '/' + this.panchkarmaName);
        this.getAllTitle();
    }

    capitalizeFirstLetter(str) {
        // converting first letter to uppercase
        let capitalized = str.charAt(0).toUpperCase() + str.slice(1);
        capitalized = capitalized.replace('-', ' ')
        return capitalized;
    }

    getAllTitle() {
        this.titleService.getPanchakarmaJson()
            .subscribe(res => {
                this.panchkarmaUrlArr = res.meta_keywords;
                if (this.panchkarmaName && this.panchkarmaUrlArr.length > 0) {
                    this.panchkarmaUrlArr.forEach(panchkarmaName => {
                        if (panchkarmaName.url === this.panchkarmaName) {
                            this.titleLibraryService.setTitle(panchkarmaName.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: panchkarmaName.keywords});
                            this.metaService.updateTag({name: 'description', content: panchkarmaName.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: panchkarmaName.og_title});
                            this.metaService.updateTag({property: 'og:url', content: panchkarmaName.og_url});
                            this.metaService.updateTag({property: 'og:type', content: panchkarmaName.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: panchkarmaName.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: panchkarmaName.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: panchkarmaName.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: panchkarmaName.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: panchkarmaName.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: panchkarmaName.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: panchkarmaName.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: panchkarmaName.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: panchkarmaName.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: panchkarmaName.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: panchkarmaName.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: panchkarmaName.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: panchkarmaName.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: panchkarmaName.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: panchkarmaName.twitter_image});
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: panchkarmaName.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: panchkarmaName.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: panchkarmaName.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: panchkarmaName.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: panchkarmaName.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: panchkarmaName.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: panchkarmaName.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: panchkarmaName.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: panchkarmaName.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }
}
