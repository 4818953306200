import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class QuestionService {

    constructor(private http: HttpClient) {
    }

    getQuestionJson() {
        return this.http.get<any>("assets/kapha.json");
    }

    getQuestionVataJson() {
        return this.http.get<any>("assets/vata.json");
    }

    getQuestionPittaJson() {
        return this.http.get<any>("assets/pitta.json");

    }

    getReview() {
        return this.http.get<any>("assets/review.json")
    }
}
