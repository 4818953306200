<div class="page-title-area page-title-four mb-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Payment Policy</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Payment Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="privacy-area">
    <div class="container">
        <div class="privacy-item">
            <h2>
                <span class="font-bold" style="margin-bottom: -37px;">PAYMENT POLICY- DR.AXICO </span>
            </h2>
            <p>To purchase, explore the Dr. Axico Online collection. Choose the item you want to order, enter the
                desired quantity, and click "Order."</p> <br>
            <p>Once your order includes all of the items and quantities you want to buy, click the "Checkout" option to
                finish the transaction. Following that, you will be required to give us your shipping and payment
                details. Please refer to the information about your payment options in the section below. Your order
                will be handled online right away after your payment has been received successfully. Your order will be
                sent to you right away. Information on our shipping policy is provided below.</p>
        </div>
        <div class="privacy-item">
            <p><strong>Payment Options</strong></p>
            <p>The following methods of payment are accepted by Dr. Axico Online for immediate orders placed online, and
                a complete list is given while checking out and before submitting the order:</p> <br>
            <ol type="a">
                <li>Credit cards both internationally and domestically.</li>
                <li>Net banking and debit cards.</li>
                <li>Wallet.</li>
                <li>COD (Cash on Delivery)</li>
            </ol>
            <br>
            <p>One of the payment options for purchases on www.draxico.com is cash on delivery (COD).</p> <br>
            <p>With COD, you can pay in cash for your order when it is delivered without having to make an online
                payment first. Please choose the "Cash On Delivery" option on the payment page if you would like to
                place an order and pay for it by COD. For COD, there are no additional fees. Only the amount stated on
                the invoice is paid.</p> <br>
            <p>Please take note that the Company has only designated certain regions/areas in which this mode of payment
                facility is available. Therefore, the COD option may or may not be accessible to you depending on your
                region and your selection of products.</p>
        </div>
        <div class="privacy-item">
            <p><strong>Shipping Policy</strong></p>
            <p>All domestic orders are processed within one business day. For urban areas, delivery of the order can be
                anticipated in 5-7 working days. This may take 10–12 working days for places that are interior or in a
                tough coverage area.</p> <br>
            <p>Order delivery will be done Monday through Saturday between 10:00 AM and 6:00 PM. not including legal
                holidays.</p> <br>
            <p>The things must be signed for when they are delivered. Please recommend an alternative person to sign for
                your delivery if you are unable to do so personally, such as a friend, coworker, neighbor, etc. But Dr.
                Axico declines responsibility for goods with someone else's signature on them. Dr. Axico is not liable
                for injury that occurs after delivery.</p> <br>
            <p>Please contact Customer Care within 7 business days after order delivery if you have any problems about
                your order. The product, packaging, size, volume, kind, and other factors may affect the shipping and
                handling costs. Customers are informed of the shipping and handling costs at the time of checkout, so
                they can prepare their purchases accordingly.</p>
        </div>
        <div class="privacy-item">
            <p><strong>Shipping Orders</strong></p>
            <p>For the majority of addresses, we charge regular delivery fees. We deliver goods all around India. On the
                other hand, orders paid for using cash on delivery only have a flat shipping fee of Rs. 100.</p>
        </div>
        <div class="privacy-item">
            <p><strong>Pricing Policy</strong></p>
            <p>Although the mentioned product prices are current, they may change at any time and without prior warning.
                All prices are processed in Indian Rupees on this website. All orders are accepted at the price in
                effect. At the time of shipping, we will bill at the current pricing. In India, GST is applicable to our
                products.</p>
        </div>
        <div class="privacy-item">
            <p><strong>Out of stock Items</strong></p>
            <p>We sincerely regret any trouble this may have caused. Within 2-4 business days if your item is out of
                stock, we will get in touch with you to discuss how you would like the order to be handled.</p>
        </div>
        <div class="privacy-item">
            <p><strong>Returns, Refunds and Cancellation Policy</strong></p>
            <p>If a cancellation is requested prior to shipment, we handle the refund within 24 to 48 hours after
                receiving the request. Please contact our Customer Care within 24 hours of receiving a tampered-with or
                damaged product, together with any accompanying images and videos, so that we can address the issue as
                soon as possible. When a shipment is returned or cancelled after it has been delivered, we handle the
                refund when the returned goods have been received and examined at our warehouse.</p> <br>
            <p>If you decide to cancel your order within a week, you will be charged 2.5% in shipping and payment
                gateway fees. The cardholder will receive a refund of their remaining debt through the same payment
                method.</p> <br>
            <p>If a product is damaged or has a quality problem, we can only exchange it if the problem is reported
                within the timeframe provided below:</p> <br>
            <ol type="a">
                <li>Within 7 days of receiving your order, please contact our Customer Care with any issues regarding
                    product quality.
                </li>
                <li>Dr. Axico disclaims liability for any harm incurred following delivery. Our exchange and return
                    policy does not cover damages brought on by carelessness, poor use, or incorrect application.
                </li>
                <li>Only items returned in resalable condition, in their original packing, undamaged, and subject to the
                    following conditions will be accepted for exchange:
                </li>
                <li>Requests for returns and exchanges will be checked through and verified by Dr. Axico.</li>
            </ol>
        </div>
    </div>
</section>
