<section class="doctors-area custom-padding">
    <div class="custom-background-image"></div>
</section>
<section class="welcome-area" id="diseases">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1 class=" mt-50px mt--20px">
                    <span class="font-bold">Diseases</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/cholesterol-level')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/cholestrol_levels.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Cholesterol Level</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/acne')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/acne.png"
                             width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Acne</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/appendicitis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/apendicitis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>appendicitis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/asthma')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/asthama.png" style="margin-right: 10px"
                             width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Asthma</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/bacterial-vaginosis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/bacterial_vaginosis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Bacterial Vaginnosis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/bronchiectasis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Bronchiectasis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Bronchiectasis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/chest-pain')" class="service-item-home cursor-pointer">
                    <div>
                        <img class="mr-10px" height="80px" src="assets/img/diseases/chest_pain.png"
                             width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Chest Pain</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/cirrhosis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/cirroshis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Cirrhosis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/common-cold')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/common_cold.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Common Cold</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/cough')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/cough.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Cough</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/diabetes')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/diabeties.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Diabetes</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/heart-disease')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/heart_dieseas.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Heart Diseases</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/food-poisoning')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/food_poisoning.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Food Poisoning</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/gout')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/gout.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Gout</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/hepatitis-a')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/hepatities_A.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Hepatitis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/hepatitis-c')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/hepatities_c.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Hepatitis C</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/kidney-stone')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/kidney_stone.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Kidney Stone</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/malaria')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/malaria.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Malaria</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/stomach-ache-and-abdominal-pain')"
                     class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/stomach_ache_and_abdominal_pain.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500">
                            <span>Stomach Ache And Abdominal Pain</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/allergies')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/allergies.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Allergies</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/arthritis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/arthirities.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Arthritis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/bronchitis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Bronchitis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Bronchitis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/chest-infection')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Chest_infections.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Chest Infection</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/chronic-kidney-diseases')"
                     class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/chronic_kidney_diseaseas.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Chronic Kidney Diseases</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/constipation')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/constipation.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Constipation</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/dehydration')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/dehydration.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Dehydration</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/flu')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/flu.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Flu</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/hair-loss-and-balding')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/hair_loss_and_balding.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Hair Loss And Balding</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/gastroenteritis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Gastroenteritis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Gastroenteritis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/headaches')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/headache.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Headache</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/hepatitis-b')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/hepatities_B.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Hepatitis B</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/kidney-infection')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/kidney_infections.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Kidney Infections</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/liver-disease')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/liver_disease.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Liver Disease</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/migraine')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/migraine.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Migraine</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/cervical-radiculopathy')"
                     class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Cervical-Radiculopathy.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Cervical Radiculopathy</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/dengue')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/dengue.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Dengue</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/eczema')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Eczema.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Eczema</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/irritable-bowel-syndrome')"
                     class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Irritable-Bowel-Syndrome.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Irritable Bowel Syndrome</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/osteoarthritis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Osteoarthritis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Osteoarthritis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/peptic-ulcer-disease')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Peptic-Ulcer-Disease.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Peptic Ulcer Disease</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/psoriasis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Psoriasis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Psoriasis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/rheumatoid-arthritis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Rheumatoid-arthritis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Rheumatoid Arthritis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/sciatica')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Sciatica.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Sciatica</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/ulcerative-colitis')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Ulcerative-colitis.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Ulcerative Colitis</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div (click)="goTo('/diseases/urticaria')" class="service-item-home cursor-pointer">
                    <div>
                        <img height="80px" src="assets/img/diseases/Urticaria.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Urticaria</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</section>



