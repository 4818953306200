import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OutletService} from "../../../shared/services/outlet.service";

@Component({
    selector: 'app-our-outlets',
    templateUrl: './our-outlets.component.html',
    styleUrls: ['./our-outlets.component.scss']
})
export class OurOutletsComponent implements OnInit {
    pagination = {
        page: 1,
        total: null,
        perpage: 50,
        pages: null
    };
    outlets: any[];

    constructor(private modalService: NgbModal,
                private outletServices: OutletService) {
    }

    ngOnInit(): void {
        this.get();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.outletServices.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.outlets = data['data'];
            } else {
                this.outlets = [];
            }
            this.pagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
        }, error => {
            this.outlets = [];
        });
    }

    openXl(content) {
        this.modalService.open(content, {size: 'xl'});
    }

    openXl2(content2) {
        this.modalService.open(content2, {size: 'xl'});
    }

    onPageChange(page: any) {
        this.pagination.page = page;
        this.get();
    }
}
