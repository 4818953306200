import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutComponent} from './components/pages/about/about.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {TermsConditionComponent} from './components/pages/terms-condition/terms-condition.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {LoginComponent} from './components/pages/login/login.component';
import {SignupComponent} from './components/pages/signup/signup.component';
import {DepartmentsComponent} from './components/pages/departments/departments.component';
import {AppointmentComponent} from './components/pages/appointment/appointment.component';
import {DoctorComponent} from './components/pages/doctor/doctor.component';
import {DoctorDetailsComponent} from './components/pages/doctor-details/doctor-details.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {BlogDetailsComponent} from './components/pages/blog-details/blog-details.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {MedicinesComponent} from './components/pages/medicines/medicines.component';
import {UserDashboardComponent} from './components/pages/user-dashboard/user-dashboard.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ConsultComponent} from './components/pages/consult/consult.component';
import {PrescriptionsComponent} from './components/pages/prescriptions/prescriptions.component';
import {
    PharmacyProductDetailsComponent
} from './components/pages/pharmacy-product-details/pharmacy-product-details.component';
import {CartComponent} from './components/pages/cart/cart.component';
import {CheckoutComponent} from './components/pages/checkout/checkout.component';
import {EstoreComponent} from './components/pages/estore/estore.component';
import {BmiCalculatorComponent} from './components/pages/bmi-calculator/bmi-calculator.component';
import {TestingComponent} from './components/pages/testing/testing.component';
import {ProductsComponent} from './components/pages/products/products.component';
import {LearnMoreComponent} from './components/pages/learn-more/learn-more.component';
import {MedicinalKitsComponent} from './components/pages/medicinal-kits/medicinal-kits.component';
import {UserAppointmentsComponent} from './components/pages/user-appointments/user-appointments.component';
import {SelfAssessmentComponent} from './components/pages/self-assessment/self-assessment.component';
import {SelfAssessmentsComponent} from './components/pages/self-assessments/self-assessments.component';
import {AwardsComponent} from './components/pages/awards/awards.component';
import {DiseasesComponent} from "./components/pages/diseases/diseases.component";
import {DiseasesDetailsComponent} from "./components/pages/diseases-details/diseases-details.component";
import {SuccessComponent} from "./components/pages/success/success.component";
import {FailedComponent} from "./components/pages/failed/failed.component";
import {OrdersComponent} from "./components/pages/orders/orders.component";
import {QuestionComponent} from "./components/pages/prakriti/question/question.component";
import {PaymentPolicyComponent} from "./components/pages/payment-policy/payment-policy.component";
import {PanchakarmaTherapiesComponent} from "./components/pages/panchakarma-therapies/panchakarma-therapies.component";
import {
    PanchakarmaTherapiesDetailsComponent
} from "./components/pages/panchakarma-therapies-details/panchakarma-therapies-details.component";
import {OurOutletsComponent} from "./components/pages/our-outlets/our-outlets.component";
import {MeetingComponent} from "./components/pages/meeting/meeting.component";

const routes: Routes = [
    {path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule)},
    {path: 'about/:about-name', component: AboutComponent},
    {path: 'appointment', component: AppointmentComponent},
    {path: 'departments', component: DepartmentsComponent},
    {path: 'signup', component: SignupComponent},
    {path: 'login', component: LoginComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-condition', component: TermsConditionComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'doctor', component: DoctorComponent},
    {path: 'doctor-details/:doctor-name', component: DoctorDetailsComponent},
    {path: 'products', component: ProductsComponent},
    {path: 'medicinal-kits', component: MedicinalKitsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'contact/submitted', component: ContactComponent},
    {path: 'medicines', component: MedicinesComponent},
    {path: 'user-dashboard', component: UserDashboardComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'consult', component: ConsultComponent},
    {path: 'prescriptions', component: PrescriptionsComponent},
    {path: 'products/:url', component: PharmacyProductDetailsComponent},
    {path: 'medicinal-kits/:url', component: PharmacyProductDetailsComponent},
    {path: 'cart', component: CartComponent},
    {path: 'checkout', component: CheckoutComponent},
    {path: 'estore', component: EstoreComponent},
    {path: 'bmi-calculator', component: BmiCalculatorComponent},
    {path: 'testing', component: TestingComponent},
    {path: 'learn-more/:type', component: LearnMoreComponent},
    {path: 'appointments', component: UserAppointmentsComponent},
    {path: 'self-assessment/:type', component: SelfAssessmentComponent},
    {path: 'self-assessment', component: SelfAssessmentsComponent},
    {path: 'approvals', component: AwardsComponent},
    {path: 'diseases', component: DiseasesComponent},
    {path: 'diseases/:diseases-name', component: DiseasesDetailsComponent},
    {path: 'payment/success', component: SuccessComponent},
    {path: 'payment/failed', component: FailedComponent},
    {path: 'orders', component: OrdersComponent},
    {path: 'self-assessment-prakriti', component: QuestionComponent},
    {path: 'panchakarma-treatment', component: PanchakarmaTherapiesComponent},
    {path: 'panchakarma-treatment/:panchakarma-therapies-name', component: PanchakarmaTherapiesDetailsComponent},
    {path: 'blogs', component: BlogComponent},
    {path: 'blogs/:blog-name', component: BlogDetailsComponent},
    {path: 'privacy-policies', component: PrivacyPolicyComponent},
    {path: 'terms-and-conditions', component: TermsConditionComponent},
    {path: 'payment-policies', component: PaymentPolicyComponent},
    {path: 'our-outlets', component: OurOutletsComponent},
    {path: 'doctor/:username', component: MeetingComponent},
    {path: '**', redirectTo: '404-error-page'},
    {
        path: '**',
        loadChildren: () => import('./components/pages/error/error.module').then((m) => m.ErrorModule)
    },
];


@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
