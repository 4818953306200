import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-learn-more',
    templateUrl: './learn-more.component.html',
    styleUrls: ['./learn-more.component.scss']
})
export class LearnMoreComponent implements OnInit {
    bannerType: any;
    validUrl: any = [];

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.validUrl = [
            'live-interaction',
            'phyto-nanotechnology',
            'your-health-our-care',
            'self-care-assessment',
            'get-guidance'
        ];
        this.bannerType = this.activatedRoute.snapshot.params['type'];
        if (!this.validUrl.includes(this.bannerType)) {
            this.router.navigateByUrl('/404-error-page');
        }
    }
}
