<ng-template #content>
    <div id="modal">
        <div class="modal-header font-weight-bold">
            <i (click)="this.modalService.dismissAll()"
               class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"></i>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="signup-area">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="signup-item">
                                        <div class="signup-head">
                                            <h2>Login Here</h2>
                                            <p>Didn't have account yet? <a (click)="goTo('/signup')"
                                                                           class="cursor-pointer">Register Here</a>
                                            </p>
                                        </div>
                                        <div class="signup-form">
                                            <form [formGroup]='loginForm' novalidate>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <input
                                                                [ngClass]="{'is-invalid': submitted && f.username.errors }"
                                                                aria-describedby="login-username" autofocus=""
                                                                class="form-control"
                                                                formControlName="username"
                                                                id="username"
                                                                placeholder="Username"
                                                                tabindex="1" type="text"/>
                                                            <small
                                                                *ngIf="submitted && loginForm.get('username').hasError('required')"
                                                                class="text-danger">
                                                                Field is required.
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <input
                                                                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
                                                                [type]="passwordTextType ? 'text' : 'password'"
                                                                aria-describedby="login-password"
                                                                class="form-control form-control-merge"
                                                                formControlName="password"
                                                                id="password"
                                                                placeholder="············" tabindex="2">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text cursor-pointer">
                                                                    <i (click)="togglePasswordTextType()"
                                                                       [ngClass]="{'icon-eye-off': passwordTextType, 'icon-eye': !passwordTextType}"
                                                                       class="feather font-small-4"></i>
                                                                </span>
                                                            </div>
                                                            <small
                                                                *ngIf="submitted && loginForm.get('password').hasError('required')"
                                                                class="text-danger">
                                                                Field is required.
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <div class="forgot-pass">
                                                                <a href="#">Forgot Password?</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="text-center">
                                                            <button (click)="login(content)" class="btn signup-btn"
                                                                    type="submit">Login
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a class="logo" routerLink="/">
            <img alt="Logo" class="img-mv d-lg-block d-none" height="80px" src="assets/img/logo.gif" width="200px">
            <img alt="Logo" class="d-sm-block d-lg-none img-mv " src="assets/img/DrAxico-Logo-White.png">
        </a>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand ms-5" routerLink="/">
                    <img alt="Logo" class="" src="assets/img/logo.gif"
                         style="width: 110px !important;height: 70px !important;"/>
                </a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a aria-expanded="false" class="nav-link dropdown-toggle cursor-pointer"
                               data-bs-toggle="dropdown"
                               href="#" id="navbarDropdown" role="button">
                                About Us
                            </a>
                            <ul aria-labelledby="navbarDropdown" class="dropdown-menu">
                                <li><a class="dropdown-item cursor-pointer"
                                       href="/panchakarma-treatment">Panchakarma</a></li>
                                <li><a class="dropdown-item cursor-pointer" href="/about/axico">About Dr Axico</a></li>
                                <li><a class="dropdown-item cursor-pointer" href="/our-outlets">Our Outlets</a></li>
                                <li><a class="dropdown-item cursor-pointer" href="/about/ayurveda">About Ayurveda</a>
                                <li><a class="dropdown-item cursor-pointer" href="/about/achievements">Achievements</a>
                                </li>
                            </ul>
                        </li>
                        <!--                        <li class="nav-item">-->
                        <!--                            <a [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle cursor-pointer"-->
                        <!--                               routerLinkActive="active">About Us</a>-->
                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a (click)="goTo('/panchakarma-treatment')"-->
                        <!--                                       class="nav-link cursor-pointer">-->
                        <!--                                        Panchakarma-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a (click)="goTo('/about/axico')"-->
                        <!--                                       class="nav-link cursor-pointer">-->
                        <!--                                        About Dr Axico-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a class="nav-link cursor-pointer"-->
                        <!--                                       routerLink="/our-outlets">-->
                        <!--                                        Our Outlets-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a (click)="goTo('/about/ayurveda')"-->
                        <!--                                       class="nav-link cursor-pointer">-->
                        <!--                                        About Ayurveda-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
                        <li class="nav-item" style="position: relative">
                            <a class="nav-link" href="javascript:void(0)" routerLink="/self-assessment">
                                Self Assessment
                                <img alt="" class="now-badge" src="assets/img/buttons/now.gif">
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)" routerLink="/appointment">
                                Book Appointment
                                <img alt="" class="now-badge" src="assets/img/buttons/now.gif">
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a aria-expanded="false" class="nav-link dropdown-toggle cursor-pointer"
                               data-bs-toggle="dropdown"
                               href="#" id="navbarDropdown2" role="button">
                                Diseases
                            </a>
                            <ul aria-labelledby="navbarDropdown2" class="dropdown-menu">
                                <li>
                                    <a class="dropdown-item cursor-pointer"
                                       href="/diseases/arthritis">
                                        Arthritis
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item cursor-pointer"
                                       href="/diseases/diabetes">
                                        Diabetes
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item cursor-pointer"
                                       href="/diseases/kidney-infection">
                                        Kidney
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item cursor-pointer"
                                       href="/diseases/heart-disease">
                                        Heart diseases
                                    </a>
                                </li>
                                <li class="nav-item"><a class="nav-link cursor-pointer" routerLink="/diseases">All
                                    Diseases
                                </a></li>
                            </ul>
                        </li>
                        <!--                        <li class="nav-item">-->
                        <!--                            <a [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle cursor-pointer"-->
                        <!--                               routerLinkActive="active">Diseases</a>-->
                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a class="nav-link cursor-pointer"-->
                        <!--                                       routerLink="/diseases/arthritis">-->
                        <!--                                        Joint Pain-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a (click)="goTo('/diseases/diabetes')"-->
                        <!--                                       class="nav-link cursor-pointer">-->
                        <!--                                        Diabetes-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a (click)="goTo('/diseases/kidney-infection')"-->
                        <!--                                       class="nav-link cursor-pointer">-->
                        <!--                                        Kidney-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                <li class="nav-item">-->
                        <!--                                    <a (click)="goTo('/diseases/heart-disease')"-->
                        <!--                                       class="nav-link cursor-pointer">-->

                        <!--                                        Heart diseases-->
                        <!--                                    </a>-->
                        <!--                                </li>-->
                        <!--                                                        <li class="nav-item"><a class="nav-link cursor-pointer" routerLink="/diseases">All-->
                        <!--                                                            Diseases-->
                        <!--                                                        </a></li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
                        <li class="nav-item d-block d-sm-none">
                            <a class="nav-link" href="javascript:void(0)" routerLink="/blogs">Blogs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)" routerLink="/medicinal-kits">Medicinal
                                Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)" routerLink="/products">Products</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLinkActiveOptions]="{exact: true}" class="nav-link" routerLink="/cart"
                               routerLinkActive="active">
                                <i class="icofont-cart" style="font-size: 1.8rem;display: inline-block;"></i>
                                <span *ngIf="cartcount" alt=""
                                      class="cart-badge customise-cart-badge">{{this.cartcount}}</span>
                            </a>
                        </li>
                        <li *ngIf="!(this.user && this.user.user_id)" class="nav-item tablet-bottom-margin">
                            <a *ngIf="!(this.user && this.user.user_id)" [routerLinkActiveOptions]="{exact: true}"
                               class="nav-link"
                               routerLink="/login"
                               routerLinkActive="active">
                                <button class="btn btn-primary">
                                    <i class="icofont-login"></i>
                                    Login
                                </button>
                            </a>
                        </li>
                        <li *ngIf="(this.user && this.user.user_id)" class="nav-item">
                            <button class="btn btn-primary mx-3 login-margin mt-lg-0 mt-xl-0 dropdown-toggle">
                                <i class="icofont-user"></i>
                                <span class="ml-10 text-capitalize">{{this.user.name.toLowerCase()}}</span>
                            </button>
                            <ul class="dropdown-menu">
                                <!--<li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link" routerLink="/cart"
                                       routerLinkActive="active">
                                        <i class="icofont-cart"></i><span class=" ml-10">Cart</span>
                                    </a>
                                </li>-->
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/appointments"
                                       routerLinkActive="active">
                                        <i class="icofont-calendar"></i>
                                        <span class=" ml-10">Appointments</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link" routerLink="/orders"
                                       routerLinkActive="active">
                                        <i class="icofont-medicine"></i><span class=" ml-10">Orders</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link" routerLink="/profile"
                                       routerLinkActive="active">
                                        <i class="icofont-user"></i><span class=" ml-10">Profile</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="logOut()" class="nav-link cursor-pointer"
                                       routerLinkActive="active">
                                        <i class="icofont-logout"></i><span class=" ml-10">Log Out</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="container-fluid" style="background-color: #0c7339">
        </div>
    </div>
</div>
