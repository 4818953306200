import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Stepper from 'bs-stepper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from "../../../shared/services/alert.service";
import {SelfAssessmentService} from '../../../shared/services/self-assessment.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {QuestionService} from "../../../shared/services/question.service";
import {interval, Observable} from "rxjs";
import {Prakriti} from "../../../shared/constants/prakriti";
import {AppUrl} from "../../../shared/constants/app-url";
import {MetaService} from 'src/app/shared/services/meta.service';
import {TitleService} from 'src/app/shared/services/title.service';
import {Title} from '@angular/platform-browser';
import {Meta} from '@angular/platform-browser';


@Component({
    selector: 'app-self-assessment',
    templateUrl: './self-assessment.component.html',
    styleUrls: ['./self-assessment.component.scss']
})
export class SelfAssessmentComponent implements OnInit, AfterViewInit {
    phoneView: boolean = true;
    assessmentType: any;
    prakriti: any = Prakriti.prakritiData;
    public name: string = "";
    public kaphaList: any = [];
    public pittaList: any = [];
    public vataList: any = [];
    public currentQuestion: number = 0;
    public currentPittaQuestion: number = 0;
    public currentVataQuestion: number = 0;
    public points: number = 0;
    public pittaPoints: number = 0;
    public vataPoints: number = 0;
    counter = 60;
    correctAnswer: number = 0;
    correctPittaAnswer: number = 0;
    correctVitaAnswer: number = 0;
    inCorrectAnswer: number = 0;
    interval$: any;
    progress: string = "0";
    isQuizCompleted: boolean = false;
    isPittaQuizCompleted: boolean = false;
    isVataQuizCompleted: boolean = false;
    private stepperLiver: Stepper;
    private stepperWomenCare: Stepper;
    private stepperMaleRejuventaor: Stepper;
    private stepperObesityPacifier: Stepper;
    private stepperDiabetesManager: Stepper;
    private stepperThyroGuard: Stepper;
    private stepperAcidityUprooter: Stepper;
    private stepperOrthoFortify: Stepper;
    private stepperDetoxManagement: Stepper;
    private stepperFibroid: Stepper;
    private stepperPrakriti: Stepper;
    private stepperNaturalKidneyCare: Stepper;
    private prakritiAssessment: Stepper;
    public liverSaviorForm: FormGroup;
    public womenCareForm: FormGroup;
    public maleRejuvenatorForm: FormGroup;
    public obesityPacifierForm: FormGroup;
    public diabetesManagerForm: FormGroup;
    public thyroGuardForm: FormGroup;
    public acidityUprooterForm: FormGroup;
    public orthoFortifyForm: FormGroup;
    public detoxManagementForm: FormGroup;
    public fibroidForm: FormGroup;
    public naturalKidneyCareForm: FormGroup;
    public prakritiForm: FormGroup;
    liverFormStep = 1;
    womenCareFormStep = 1;
    maleRejuvenatorFormStep = 1;
    obesityPacifierFormStep = 1;
    diabetesManagerFormStep = 1;
    thyroguardFormStep = 1;
    acidityUprooterFormStep = 1;
    orthoFortifyFormStep = 1;
    detoxManagementFormStep = 1;
    fibroidFormStep = 1;
    naturalKidneyCareFormStep = 1;
    prakritiFormStep = 1;
    submitted: any = false;
    submitting: any = false;
    chances: any;
    hostName: any = 'https://www.draxico.com/self-assessment';
    selfAssessmentsUrlArr: any;
    selfAssessmentUrl: any;
    validUrl: any = [];
    liverSaviorTrueArr = [
        'YES_CHOLESTEROL',
        'YES_DAILY',
        'MOTHER',
        'DIABETIC',
        'RED_OR_PINK_URINE',
        'DISCOLORATION',
        'YELLOW_WHITES_OF_YOUR_EYES',
        'YELLOWISH_TONGUE',
        'OFTEN',
        'OFTEN_SWELLING'
    ];
    womenCareTrueArr = [
        'DIABETIC',
        'MOTHER',
        'OFTEN_HEAVINESS',
        'EXCESSIVE',
        'IRREGULAR',
        'OFTEN_WHITE_DISCHARGE',
        'OFTEN_FEELING_STRESSED',
        'OFTEN_LACK_OF_TASTE',
        'OFTEN_FREQUENT_URINATION',
        'OFTEN_COITAL_BLEEDING'
    ];
    maleRejuvenatorTrueArr = [
        'DIABETIC',
        'MOTHER',
        'NEVER_SEXUAL_DESIRE',
        'OFTEN_DELAYED_EJACULATION',
        'OFTEN_PAIN',
        'OFTEN_BOWEL_MOMENTS',
        'ALCOHOL_FOOD_HABIT',
        'OFTEN_FEELING_STRESSED',
        'YES_DAILY_DRINK_ALCOHOL',
        'OFTEN_FEEL_WEAK'
    ];
    obesityPacifierTrueArr = [
        'OBESE',
        'SUDDENLY',
        '4_TIMES_MORE',
        'NEVER_DOING_EXERCISE',
        'DIABETIC',
        'HAVING_MEDICAL_CONDITION',
        'OFTEN_STERIOD_THYROID_MED',
        'YES_MOTHER_FATHER_SIBLING_OVERWEIGHT',
        'OFTEN_TAKING_ANY_MED_TO_LOOSE_WEIGHT',
        'NONE_BOWEL_MOVEMENTS'
    ];
    diabetesManagerTrueArr = [
        'DIABETIC',
        'MOTHER',
        'MORE_THAN_3_YEARS',
        'YES_OVERWEIGHT_OBESE',
        'NO_PHYSICALLY_ACTIVE',
        'YES_DIABETES_RELATED_COMPLICATIONS',
        'ALLOPATHIC_ALREADY_TAKING',
        'NEVER_EXCERCISE_WALK',
        'NO_PROPER_DIABETIC_DIET',
        'NOT_CHECKING_AT_ALL'
    ];
    thyroGuardTrueArr = [
        'GOITRE_MEDICAL_HISTORY',
        'MOTHER_FAMILY_HISOTRY',
        'YES_HAIR_FALLING',
        'YES_WEIGHT_INCREASING_FAST',
        'NO_REDUCTION_AFTER_GYM',
        'YES_FEEL_MUSCLE_WEAKNESS',
        'OFTEN_FEEL_PUFFINESS_SWELLING',
        'OFTEN_MUSCULAR_FATIGUE_LETHARGIC',
        'CONSTIPATION_BOWEL_MOVEMENT',
        '4_OR_MORE_TIMES'
    ];
    acidityUprooterTrueArr = [
        'OTHER_MEDICAL_HISTORY',
        'MOTHER_FAMILY_HISOTRY',
        'OFTEN_HEAVINESS_IN_BODY_HEAD',
        'EXCESSIVE_APPETITE',
        'OFTEN_BLOATING_ISSUES',
        'OFTEN_ACIDITY_HEARTBURN',
        'OFTEN_NAUSEA',
        'OFTEN_BAD_BREATH',
        'OFTEN_IRRITATION_IN_THROAT',
        'OFTEN_BOWEL_EVACUATION'
    ];
    orthoFortifyTrueArr = [
        'OTHER_MEDICAL_HISTORY',
        'MOTHER_FAMILY_HISOTRY',
        'SEVERE_PAIN_IN_JOINT',
        'SEVERE_SWELLING_OVER_JOINT_KNEE',
        'OFTEN_CRACKING_SOUND',
        'OFTEN_STIFNESS_IN_JOINT',
        'NOT_AT_ALL_SEVERE_PAIN_ON_WALKING',
        'ALLOPATHIC_TREATMENT',
        'NO_IMPOSSIBLE_HOUSE_HOLD_WORK',
        'CONSTIPATION_BOWEL_MOVEMENTS'
    ];
    detoxManagementTrueArr = [
        'YES_CHOLESTROL_LEVELS_ABNORMAL',
        'YES_KNOW_ABOUT_FATTY_LIVER',
        'NEVER_PHYSICALLY_ACTIVE',
        'DAILY_DRINK_ALCOHOL',
        'DIABETIC_MEDICAL_HISTORY',
        'DARK_BROWN_URINE_COLOR',
        'YELLOW_WHITES_OF_YOUR_EYES',
        'YELLOWISH_TONGUE',
        'ALCOHOL_FOOD_HABIT',
        'CONSTIPATION_BOWEL_MOVEMENT'
    ];
    fibroidTrueArr = [
        'AFTER_15_YEARS',
        'GREATER_THAN_35_DAYS',
        'IRREGULAR',
        'HEAVY',
        'THYROID_MEDICAL_HISTORY',
        'YES_INTERMENSTRUAL',
        'YES_WHITE_DISCHARGE',
        'YES_PAIN_WITH_PERIODS',
        'MOTHER',
        'YES_FEEL_FIBROID'
    ];
    naturalKidneyCareTrueArr = [
        'DIABETIC_MEDICAL_HISOTYR',
        'MOTHER_MEDICAL_HISTORY',
        'OFTEN_PASSING_URINE',
        'OFTEN_SWELLING_ON_LEG',
        'OFTEN_SWELLING_ON_FACE',
        'SOMETIMES_ABDOMINAL_PAIN',
        'ALCOHOL_FOOD_HABIT',
        'CONSTIPATION_BOWEL_MOVEMENT',
        'RED_PINK_URINE',
        'YES_DAILY_ALCOHOL'
    ];
    prakritiTrueArr = [
        'NULL'
    ];
    @ViewChild('content') templateRef: TemplateRef<any>;

    constructor(private activatedRoute: ActivatedRoute,
                private questionService: QuestionService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private selfAssessmentService: SelfAssessmentService,
                public modalService: NgbModal,
                private router: Router,
                private metaServices: MetaService,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta) {
    }

    ngOnInit() {
        this.validUrl = [
            'liver-health-assessment',
            'women-health-assessment',
            'male-health-assessment',
            'obesity-assessment',
            'diabetes-assessment',
            'thyroid-assessment',
            'acidity-assessment',
            'joints-assessment',
            'general-health-assessment',
            'uterine-fibroid-assessment',
            'kidney-assessment',
            'prakriti'];
        this.selfAssessmentUrl = this.activatedRoute.snapshot.params['type'];
        this.assessmentType = this.activatedRoute.snapshot.params['type'];
        if (!this.validUrl.includes(this.selfAssessmentUrl)) {
            this.router.navigateByUrl('/404-error-page');
        }
        this.refresh();
        this.name = localStorage.getItem("name")!;
        this.getAllQuestions();
        this.getAllPittaQuestions();
        this.getAllVataQuestions();
        this.startCounter();
        this.metaServices.updateCanonicalUrl(this.hostName + '/' + this.selfAssessmentUrl);
        this.getAllTitle();
    }

    getAllTitle() {
        this.titleService.getSelfAssessmentTitleJson()
            .subscribe(res => {
                this.selfAssessmentsUrlArr = res.title;
                if (this.selfAssessmentUrl && this.selfAssessmentsUrlArr.length > 0) {
                    this.selfAssessmentsUrlArr.forEach(selfAssessmentUrl => {
                        if (selfAssessmentUrl.url === this.selfAssessmentUrl) {
                            this.titleLibraryService.setTitle(selfAssessmentUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: selfAssessmentUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: selfAssessmentUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: selfAssessmentUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: selfAssessmentUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: selfAssessmentUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: selfAssessmentUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: selfAssessmentUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: selfAssessmentUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: selfAssessmentUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: selfAssessmentUrl.og_ib_edge});
                            this.metaService.updateTag({
                                property: 'og:ib.libge',
                                content: selfAssessmentUrl.og_ib_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:im.libge',
                                content: selfAssessmentUrl.og_im_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: selfAssessmentUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: selfAssessmentUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: selfAssessmentUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: selfAssessmentUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: selfAssessmentUrl.twitter_card});
                            this.metaService.updateTag({
                                name: 'twitter:title',
                                content: selfAssessmentUrl.twitter_title
                            });
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: selfAssessmentUrl.twitter_description
                            });
                            this.metaService.updateTag({
                                name: 'twitter:image',
                                content: selfAssessmentUrl.twitter_image
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: selfAssessmentUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: selfAssessmentUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: selfAssessmentUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: selfAssessmentUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: selfAssessmentUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: selfAssessmentUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: selfAssessmentUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: selfAssessmentUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: selfAssessmentUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }


    getAllQuestions() {
        this.questionService.getQuestionJson()
            .subscribe(res => {
                this.kaphaList = res.kapha;
            })
    }

    getAllPittaQuestions() {
        this.questionService.getQuestionPittaJson()
            .subscribe(res => {
                this.pittaList = res.pitta;
            })
    }

    getAllVataQuestions() {
        this.questionService.getQuestionVataJson()
            .subscribe(res => {
                this.vataList = res.vata;
            })
    }

    answer(currentQno: number, option: any) {
        if (currentQno === this.kaphaList.length) {
            this.isQuizCompleted = true;
            this.stopCounter();
        }
        if (option.correct) {
            this.points += 1;
            this.correctAnswer++;
            setTimeout(() => {
                this.currentQuestion++;
                this.resetCounter();
            }, 1000);
        } else {
            setTimeout(() => {
                this.currentQuestion++;
                this.inCorrectAnswer++;
                this.resetCounter();
            }, 1000);
            this.points -= 0;
        }
    }

    pitta(currentQno: number, option: any) {
        if (currentQno === this.pittaList.length) {
            this.isPittaQuizCompleted = true;
            this.stopCounter();
        }
        if (option.correct) {
            this.pittaPoints += 1;
            this.correctPittaAnswer++;
            setTimeout(() => {
                this.currentPittaQuestion++;
                this.resetCounter();
            }, 1000);
        } else {
            setTimeout(() => {
                this.currentPittaQuestion++;
                this.inCorrectAnswer++;
                this.resetCounter();
            }, 1000);
            this.pittaPoints -= 0;
        }
    }

    vata(currentQno: number, option: any) {
        if (currentQno === this.vataList.length) {
            this.isVataQuizCompleted = true;
            this.stopCounter();
        }
        if (option.correct) {
            this.vataPoints += 1;
            this.correctVitaAnswer++;
            setTimeout(() => {
                this.currentVataQuestion++;
                this.resetCounter();
            }, 1000);
        } else {
            setTimeout(() => {
                this.currentVataQuestion++;
                this.inCorrectAnswer++;
                this.resetCounter();
            }, 1000);

            this.vataPoints -= 0;
        }
    }


    startCounter() {
        this.interval$ = interval(1000)
            .subscribe(val => {
                this.counter--;
                if (this.counter === 0) {
                    this.currentQuestion++;
                    this.currentPittaQuestion++;
                    this.currentVataQuestion++;
                    this.counter = 60;
                    this.points -= 0;
                }
            });
        setTimeout(() => {
            this.interval$.unsubscribe();
        }, 600000);
    }

    stopCounter() {
        this.interval$.unsubscribe();
        this.counter = 0;
    }

    resetCounter() {
        this.stopCounter();
        this.counter = 60;
        this.startCounter();
    }


    ngAfterViewInit(): void {
        if (this.assessmentType === 'liver-health-assessment') {
            this.stepperLiver = new Stepper(document.querySelector('#stepperLiver'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'women-health-assessment') {
            this.stepperWomenCare = new Stepper(document.querySelector('#stepperWomenCare'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'male-health-assessment') {
            this.stepperMaleRejuventaor = new Stepper(document.querySelector('#stepperMaleRejuvenator'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'obesity-assessment') {
            this.stepperObesityPacifier = new Stepper(document.querySelector('#stepperObesityPacifier'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'diabetes-assessment') {
            this.stepperDiabetesManager = new Stepper(document.querySelector('#stepperDiabetesManager'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'thyroid-assessment') {
            this.stepperThyroGuard = new Stepper(document.querySelector('#stepperThyroGuard'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'acidity-assessment') {
            this.stepperAcidityUprooter = new Stepper(document.querySelector('#stepperAcidityUprooter'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'joints-assessment') {
            this.stepperOrthoFortify = new Stepper(document.querySelector('#stepperOrthoFortify'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'general-health-assessment') {
            this.stepperDetoxManagement = new Stepper(document.querySelector('#stepperDetoxManagement'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'uterine-fibroid-assessment') {
            this.stepperFibroid = new Stepper(document.querySelector('#stepperFibroid'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'kidney-assessment') {
            this.stepperNaturalKidneyCare = new Stepper(document.querySelector('#stepperNaturalKidneyCare'), {
                linear: false,
                animation: true
            });
        } else if (this.assessmentType === 'prakriti') {
            this.stepperPrakriti = new Stepper(document.querySelector('#stepperPrakriti'), {
                linear: false,
                animation: true,
            });
        }
    }

    refresh() {
        this.liverSaviorForm = this.fb.group({
            'cholesterol_levels': [''],
            'drink_alcohol': [''],
            'family_history': [''],
            'medical_history': [''],
            'urine_color': [''],
            'abnormalities_fingernail': [''],
            'eyes': [''],
            'tongue': [''],
            'puffiness_or_swelling_face': [''],
            'swelling_on_your_leg': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.womenCareForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'heaviness_in_body_head': [''],
            'appetite': [''],
            'menstrual_history': [''],
            'white_discharge': [''],
            'feeling_stressed_anxiety': [''],
            'lack_of_taste': [''],
            'frequent_urination': [''],
            'coital_pain_bleeding': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.maleRejuvenatorForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'sexual_desire_interest': [''],
            'rapid_delayed_ejaculation': [''],
            'pain': [''],
            'bowel_movement': [''],
            'food_habit': [''],
            'feeling_stressed_anxiety': [''],
            'drink_alcohol': [''],
            'feel_weak': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.obesityPacifierForm = this.fb.group({
            'weigh_in_high_school': [''],
            'start_gain_weight': [''],
            'times_eat_in_a_day': [''],
            'doing_any_kind_of_excersie': [''],
            'medical_history': [''],
            'prevent_from_losing_weight': [''],
            'taking_steroid_thyroid_med': [''],
            'mother_father_sibling_overweight': [''],
            'taking_any_med_to_loose_weight': [''],
            'bowel_movements': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.diabetesManagerForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'how_long_suffering_from_diabetes': [''],
            'overweight_or_obese': [''],
            'physically_active': [''],
            'diabetes_related_complications': [''],
            'medicines_already_taking': [''],
            'doing_excercises_walk': [''],
            'proper_diabetic_diet': [''],
            'checking_blood_sugar_level_regularly': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.thyroGuardForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'hair_falling': [''],
            'weight_increasing_very_fast': [''],
            'no_weight_reduction_after_gym': [''],
            'muscle_weakness': [''],
            'feel_puffiness_swelling_on_face': [''],
            'feel_muscular_fatigue_lethargy': [''],
            'bowel_movement': [''],
            'how_many_times_eat': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.acidityUprooterForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'heaviness_in_body_head': [''],
            'appetite': [''],
            'gas_bloating_issues': [''],
            'experience_acidity_heartburn': [''],
            'experience_nausea': [''],
            'bad_breath': [''],
            'mucous_irritation_in_throat': [''],
            'difficulty_in_bowel_evacuation': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.orthoFortifyForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'pain_in_joint_knee_joint': [''],
            'swelling_over_joint_knee': [''],
            'joint_make_cracking_sound': [''],
            'stifness_in_joint': [''],
            'walk_before_pain_in_knee': [''],
            'taking_any_treatment': [''],
            'able_to_do_household_work': [''],
            'bowel_movements': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.detoxManagementForm = this.fb.group({
            'cholestrol_levels_abnormal': [''],
            'know_about_fatty_liver': [''],
            'physically_active': [''],
            'drink_alcohol': [''],
            'medical_history': [''],
            'urine_color': [''],
            'eyes': [''],
            'tongue': [''],
            'food_habit': [''],
            'bowel_movement': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.fibroidForm = this.fb.group({
            'period_age': [''],
            'cycle_duration': [''],
            'periods_interval': [''],
            'medical_history': [''],
            'family_history': [''],
            'amount_of_flow': [''],
            'pain_with_periods': [''],
            'intermenstrual': [''],
            'white_discharge': [''],
            'feel_fibroid': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.naturalKidneyCareForm = this.fb.group({
            'medical_history': [''],
            'family_history': [''],
            'difficulty_passing_urine': [''],
            'swelling_on_leg': [''],
            'swelling_on_face': [''],
            'backache_abdomial_pain': [''],
            'food_habit': [''],
            'bowel_movement': [''],
            'urine_color': [''],
            'drink_alcohol': [''],
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
        this.prakritiForm = this.fb.group({
            'NULL': null,
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'gender': ['', Validators.required],
            'age': ['', Validators.required]
        });
    }

    next(type) {
        if (type === 'LIVER_SAVIOR') {
            if (this.liverFormStep === 1) {
                if (!this.liverSaviorForm || !this.liverSaviorForm.value || !this.liverSaviorForm.value.cholesterol_levels || !this.liverSaviorForm.value.drink_alcohol || !this.liverSaviorForm.value.family_history || !this.liverSaviorForm.value.medical_history) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.liverFormStep = 2;
                    this.stepperLiver.next();
                }
            } else if (this.liverFormStep === 2) {
                if (!this.liverSaviorForm || !this.liverSaviorForm.value || !this.liverSaviorForm.value.urine_color || !this.liverSaviorForm.value.abnormalities_fingernail || !this.liverSaviorForm.value.eyes || !this.liverSaviorForm.value.tongue || !this.liverSaviorForm.value.puffiness_or_swelling_face || !this.liverSaviorForm.value.swelling_on_your_leg) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let liverSaviorAssessment = [
                        this.liverSaviorForm.value.cholesterol_levels,
                        this.liverSaviorForm.value.drink_alcohol,
                        this.liverSaviorForm.value.family_history,
                        this.liverSaviorForm.value.medical_history,
                        this.liverSaviorForm.value.urine_color,
                        this.liverSaviorForm.value.abnormalities_fingernail,
                        this.liverSaviorForm.value.eyes,
                        this.liverSaviorForm.value.tongue,
                        this.liverSaviorForm.value.puffiness_or_swelling_face,
                        this.liverSaviorForm.value.swelling_on_your_leg
                    ];
                    let difference = this.liverSaviorTrueArr.filter(x => !liverSaviorAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.liverFormStep = 3;
                    this.stepperLiver.next();
                }
            } else if (this.liverFormStep === 3) {
                if (!this.liverSaviorForm || !this.liverSaviorForm.value || !this.liverSaviorForm.value.urine_color || !this.liverSaviorForm.value.abnormalities_fingernail || !this.liverSaviorForm.value.eyes || !this.liverSaviorForm.value.tongue || !this.liverSaviorForm.value.puffiness_or_swelling_face || !this.liverSaviorForm.value.swelling_on_your_leg) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'WOMEN_CARE') {
            if (this.womenCareFormStep === 1) {
                if (!this.womenCareForm || !this.womenCareForm.value || !this.womenCareForm.value.medical_history || !this.womenCareForm.value.family_history || !this.womenCareForm.value.heaviness_in_body_head || !this.womenCareForm.value.appetite || !this.womenCareForm.value.menstrual_history) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.womenCareFormStep = 2;
                    this.stepperWomenCare.next();
                }
            } else if (this.womenCareFormStep === 2) {
                if (!this.womenCareForm || !this.womenCareForm.value || !this.womenCareForm.value.white_discharge || !this.womenCareForm.value.feeling_stressed_anxiety || !this.womenCareForm.value.lack_of_taste || !this.womenCareForm.value.frequent_urination || !this.womenCareForm.value.coital_pain_bleeding) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let womenCareAssessment = [
                        this.womenCareForm.value.medical_history,
                        this.womenCareForm.value.family_history,
                        this.womenCareForm.value.heaviness_in_body_head,
                        this.womenCareForm.value.appetite,
                        this.womenCareForm.value.menstrual_history,
                        this.womenCareForm.value.white_discharge,
                        this.womenCareForm.value.feeling_stressed_anxiety,
                        this.womenCareForm.value.lack_of_taste,
                        this.womenCareForm.value.frequent_urination,
                        this.womenCareForm.value.coital_pain_bleeding,
                    ];
                    let difference = this.womenCareTrueArr.filter(x => !womenCareAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.womenCareFormStep = 3;
                    this.stepperWomenCare.next();
                }
            } else if (this.womenCareFormStep === 3) {
                if (!this.womenCareForm || !this.womenCareForm.value || !this.womenCareForm.value.name || !this.womenCareForm.value.email || !this.womenCareForm.value.gender || !this.womenCareForm.value.contact || !this.womenCareForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'MALE_REJUVENATOR') {
            if (this.maleRejuvenatorFormStep === 1) {
                if (!this.maleRejuvenatorForm || !this.maleRejuvenatorForm.value || !this.maleRejuvenatorForm.value.medical_history || !this.maleRejuvenatorForm.value.family_history || !this.maleRejuvenatorForm.value.sexual_desire_interest || !this.maleRejuvenatorForm.value.rapid_delayed_ejaculation || !this.maleRejuvenatorForm.value.pain || !this.maleRejuvenatorForm.value.bowel_movement) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.maleRejuvenatorFormStep = 2;
                    this.stepperMaleRejuventaor.next();
                }
            } else if (this.maleRejuvenatorFormStep === 2) {
                if (!this.maleRejuvenatorForm ||
                    !this.maleRejuvenatorForm.value || !this.maleRejuvenatorForm.value.food_habit || !this.maleRejuvenatorForm.value.feeling_stressed_anxiety || !this.maleRejuvenatorForm.value.drink_alcohol || !this.maleRejuvenatorForm.value.feel_weak) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let maleRejuvenatorAssessment = [
                        this.maleRejuvenatorForm.value.medical_history,
                        this.maleRejuvenatorForm.value.family_history,
                        this.maleRejuvenatorForm.value.sexual_desire_interest,
                        this.maleRejuvenatorForm.value.rapid_delayed_ejaculation,
                        this.maleRejuvenatorForm.value.pain,
                        this.maleRejuvenatorForm.value.bowel_movement,
                        this.maleRejuvenatorForm.value.food_habit,
                        this.maleRejuvenatorForm.value.feeling_stressed_anxiety,
                        this.maleRejuvenatorForm.value.drink_alcohol,
                        this.maleRejuvenatorForm.value.feel_weak
                    ];
                    let difference = this.maleRejuvenatorTrueArr.filter(x => !maleRejuvenatorAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.maleRejuvenatorFormStep = 3;
                    this.stepperMaleRejuventaor.next();
                }
            } else if (this.maleRejuvenatorFormStep === 3) {
                if (!this.maleRejuvenatorForm || !this.maleRejuvenatorForm.value || !this.maleRejuvenatorForm.value.name || !this.maleRejuvenatorForm.value.email || !this.maleRejuvenatorForm.value.gender || !this.maleRejuvenatorForm.value.contact || !this.maleRejuvenatorForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'OBESITY_PACIFIRE') {
            if (this.obesityPacifierFormStep === 1) {
                if (!this.obesityPacifierForm || !this.obesityPacifierForm.value || !this.obesityPacifierForm.value.weigh_in_high_school || !this.obesityPacifierForm.value.start_gain_weight || !this.obesityPacifierForm.value.times_eat_in_a_day || !this.obesityPacifierForm.value.doing_any_kind_of_excersie || !this.obesityPacifierForm.value.medical_history) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.obesityPacifierFormStep = 2;
                    this.stepperObesityPacifier.next();
                }
            } else if (this.obesityPacifierFormStep === 2) {
                if (!this.obesityPacifierForm || !this.obesityPacifierForm.value || !this.obesityPacifierForm.value.prevent_from_losing_weight || !this.obesityPacifierForm.value.taking_steroid_thyroid_med || !this.obesityPacifierForm.value.mother_father_sibling_overweight || !this.obesityPacifierForm.value.taking_any_med_to_loose_weight || !this.obesityPacifierForm.value.bowel_movements) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let obesityPacifierAssessment = [
                        this.obesityPacifierForm.value.weigh_in_high_school,
                        this.obesityPacifierForm.value.start_gain_weight,
                        this.obesityPacifierForm.value.times_eat_in_a_day,
                        this.obesityPacifierForm.value.doing_any_kind_of_excersie,
                        this.obesityPacifierForm.value.medical_history,
                        this.obesityPacifierForm.value.prevent_from_losing_weight,
                        this.obesityPacifierForm.value.taking_steroid_thyroid_med,
                        this.obesityPacifierForm.value.mother_father_sibling_overweight,
                        this.obesityPacifierForm.value.taking_any_med_to_loose_weight,
                        this.obesityPacifierForm.value.bowel_movements
                    ];
                    let difference = this.obesityPacifierTrueArr.filter(x => !obesityPacifierAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.obesityPacifierFormStep = 3;
                    this.stepperObesityPacifier.next();
                }
            } else if (this.obesityPacifierFormStep === 3) {
                if (!this.obesityPacifierForm || !this.obesityPacifierForm.value || !this.obesityPacifierForm.value.name || !this.obesityPacifierForm.value.email || !this.obesityPacifierForm.value.gender || !this.obesityPacifierForm.value.contact || !this.obesityPacifierForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'DIABETES_MANAGER') {
            if (this.diabetesManagerFormStep === 1) {
                if (!this.diabetesManagerForm || !this.diabetesManagerForm.value || !this.diabetesManagerForm.value.medical_history || !this.diabetesManagerForm.value.family_history || !this.diabetesManagerForm.value.how_long_suffering_from_diabetes) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.diabetesManagerFormStep = 2;
                    this.stepperDiabetesManager.next();
                }
            } else if (this.diabetesManagerFormStep === 2) {
                if (!this.diabetesManagerForm || !this.diabetesManagerForm.value || !this.diabetesManagerForm.value.overweight_or_obese || !this.diabetesManagerForm.value.physically_active || !this.diabetesManagerForm.value.diabetes_related_complications || !this.diabetesManagerForm.value.medicines_already_taking || !this.diabetesManagerForm.value.doing_excercises_walk || !this.diabetesManagerForm.value.proper_diabetic_diet || !this.diabetesManagerForm.value.checking_blood_sugar_level_regularly) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let diabetesManagerAssessment = [
                        this.diabetesManagerForm.value.medical_history,
                        this.diabetesManagerForm.value.family_history,
                        this.diabetesManagerForm.value.how_long_suffering_from_diabetes,
                        this.diabetesManagerForm.value.overweight_or_obese,
                        this.diabetesManagerForm.value.physically_active,
                        this.diabetesManagerForm.value.diabetes_related_complications,
                        this.diabetesManagerForm.value.medicines_already_taking,
                        this.diabetesManagerForm.value.doing_excercises_walk,
                        this.diabetesManagerForm.value.proper_diabetic_diet,
                        this.diabetesManagerForm.value.checking_blood_sugar_level_regularly
                    ];
                    let difference = this.diabetesManagerTrueArr.filter(x => !diabetesManagerAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.diabetesManagerFormStep = 3;
                    this.stepperDiabetesManager.next();
                }
            } else if (this.diabetesManagerFormStep === 3) {
                if (!this.diabetesManagerForm || !this.diabetesManagerForm.value || !this.diabetesManagerForm.value.name || !this.diabetesManagerForm.value.email || !this.diabetesManagerForm.value.gender || !this.diabetesManagerForm.value.contact || !this.diabetesManagerForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'THYRO_GUARD') {
            if (this.thyroguardFormStep === 1) {
                if (!this.thyroGuardForm || !this.thyroGuardForm.value || !this.thyroGuardForm.value.medical_history || !this.thyroGuardForm.value.family_history || !this.thyroGuardForm.value.hair_falling || !this.thyroGuardForm.value.weight_increasing_very_fast) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.thyroguardFormStep = 2;
                    this.stepperThyroGuard.next();
                }
            } else if (this.thyroguardFormStep === 2) {
                if (!this.thyroGuardForm || !this.thyroGuardForm.value || !this.thyroGuardForm.value.muscle_weakness || !this.thyroGuardForm.value.feel_puffiness_swelling_on_face || !this.thyroGuardForm.value.feel_muscular_fatigue_lethargy || !this.thyroGuardForm.value.bowel_movement || !this.thyroGuardForm.value.how_many_times_eat || !this.thyroGuardForm.value.no_weight_reduction_after_gym) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let thyroGuardAssessment = [
                        this.thyroGuardForm.value.medical_history,
                        this.thyroGuardForm.value.family_history,
                        this.thyroGuardForm.value.hair_falling,
                        this.thyroGuardForm.value.weight_increasing_very_fast,
                        this.thyroGuardForm.value.no_weight_reduction_after_gym,
                        this.thyroGuardForm.value.muscle_weakness,
                        this.thyroGuardForm.value.feel_puffiness_swelling_on_face,
                        this.thyroGuardForm.value.feel_muscular_fatigue_lethargy,
                        this.thyroGuardForm.value.bowel_movement,
                        this.thyroGuardForm.value.how_many_times_eat,
                        this.thyroGuardForm.value.name,
                        this.thyroGuardForm.value.email,
                        this.thyroGuardForm.value.contact,
                        this.thyroGuardForm.value.gender,
                        this.thyroGuardForm.value.age
                    ];
                    let difference = this.thyroGuardTrueArr.filter(x => !thyroGuardAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.thyroguardFormStep = 3;
                    this.stepperThyroGuard.next();
                }
            } else if (this.thyroguardFormStep === 3) {
                if (!this.thyroGuardForm || !this.thyroGuardForm.value || !this.thyroGuardForm.value.name || !this.thyroGuardForm.value.email || !this.thyroGuardForm.value.gender || !this.thyroGuardForm.value.contact || !this.thyroGuardForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'ACIDITY_UPROOTER') {
            if (this.acidityUprooterFormStep === 1) {
                if (!this.acidityUprooterForm || !this.acidityUprooterForm.value || !this.acidityUprooterForm.value.medical_history || !this.acidityUprooterForm.value.family_history || !this.acidityUprooterForm.value.heaviness_in_body_head || !this.acidityUprooterForm.value.appetite) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.acidityUprooterFormStep = 2;
                    this.stepperAcidityUprooter.next();
                }
            } else if (this.acidityUprooterFormStep === 2) {
                if (!this.acidityUprooterForm || !this.acidityUprooterForm.value || !this.acidityUprooterForm.value.gas_bloating_issues || !this.acidityUprooterForm.value.experience_acidity_heartburn || !this.acidityUprooterForm.value.experience_nausea || !this.acidityUprooterForm.value.bad_breath || !this.acidityUprooterForm.value.mucous_irritation_in_throat || !this.acidityUprooterForm.value.difficulty_in_bowel_evacuation) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let acidityUprooterAssessment = [
                        this.acidityUprooterForm.value.medical_history,
                        this.acidityUprooterForm.value.family_history,
                        this.acidityUprooterForm.value.heaviness_in_body_head,
                        this.acidityUprooterForm.value.appetite,
                        this.acidityUprooterForm.value.gas_bloating_issues,
                        this.acidityUprooterForm.value.experience_acidity_heartburn,
                        this.acidityUprooterForm.value.experience_nausea,
                        this.acidityUprooterForm.value.bad_breath,
                        this.acidityUprooterForm.value.mucous_irritation_in_throat,
                        this.acidityUprooterForm.value.difficulty_in_bowel_evacuation,
                        this.acidityUprooterForm.value.name,
                        this.acidityUprooterForm.value.email,
                        this.acidityUprooterForm.value.contact,
                        this.acidityUprooterForm.value.gender,
                        this.acidityUprooterForm.value.age,
                    ];
                    let difference = this.acidityUprooterTrueArr.filter(x => !acidityUprooterAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.acidityUprooterFormStep = 3;
                    this.stepperAcidityUprooter.next();
                }
            } else if (this.acidityUprooterFormStep === 3) {
                if (!this.acidityUprooterForm || !this.acidityUprooterForm.value || !this.acidityUprooterForm.value.name || !this.acidityUprooterForm.value.email || !this.acidityUprooterForm.value.gender || !this.acidityUprooterForm.value.contact || !this.acidityUprooterForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'ORTHO_FORTIFY') {
            if (this.orthoFortifyFormStep === 1) {
                if (!this.orthoFortifyForm || !this.orthoFortifyForm.value || !this.orthoFortifyForm.value.medical_history || !this.orthoFortifyForm.value.family_history || !this.orthoFortifyForm.value.pain_in_joint_knee_joint || !this.orthoFortifyForm.value.swelling_over_joint_knee) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.orthoFortifyFormStep = 2;
                    this.stepperOrthoFortify.next();
                }
            } else if (this.orthoFortifyFormStep === 2) {
                if (!this.orthoFortifyForm || !this.orthoFortifyForm.value ||
                    !this.orthoFortifyForm.value.joint_make_cracking_sound || !this.orthoFortifyForm.value.stifness_in_joint || !this.orthoFortifyForm.value.walk_before_pain_in_knee || !this.orthoFortifyForm.value.taking_any_treatment || !this.orthoFortifyForm.value.able_to_do_household_work || !this.orthoFortifyForm.value.bowel_movements) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let orthoFortifyAssessment = [
                        this.orthoFortifyForm.value.medical_history,
                        this.orthoFortifyForm.value.family_history,
                        this.orthoFortifyForm.value.heaviness_in_body_head,
                        this.orthoFortifyForm.value.appetite,
                        this.orthoFortifyForm.value.gas_bloating_issues,
                        this.orthoFortifyForm.value.experience_acidity_heartburn,
                        this.orthoFortifyForm.value.experience_nausea,
                        this.orthoFortifyForm.value.bad_breath,
                        this.orthoFortifyForm.value.mucous_irritation_in_throat,
                        this.orthoFortifyForm.value.difficulty_in_bowel_evacuation,
                        this.orthoFortifyForm.value.name,
                        this.orthoFortifyForm.value.email,
                        this.orthoFortifyForm.value.contact,
                        this.orthoFortifyForm.value.gender,
                        this.orthoFortifyForm.value.age
                    ];
                    let difference = this.orthoFortifyTrueArr.filter(x => !orthoFortifyAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.orthoFortifyFormStep = 3;
                    this.stepperOrthoFortify.next();
                }
            } else if (this.orthoFortifyFormStep === 3) {
                if (!this.orthoFortifyForm || !this.orthoFortifyForm.value || !this.orthoFortifyForm.value.name || !this.orthoFortifyForm.value.email || !this.orthoFortifyForm.value.gender || !this.orthoFortifyForm.value.contact || !this.orthoFortifyForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'DETOX_MANAGEMENT') {
            if (this.detoxManagementFormStep === 1) {
                if (!this.detoxManagementForm || !this.detoxManagementForm.value || !this.detoxManagementForm.value.cholestrol_levels_abnormal || !this.detoxManagementForm.value.know_about_fatty_liver || !this.detoxManagementForm.value.physically_active || !this.detoxManagementForm.value.drink_alcohol || !this.detoxManagementForm.value.medical_history) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.detoxManagementFormStep = 2;
                    this.stepperDetoxManagement.next();
                }
            } else if (this.detoxManagementFormStep === 2) {
                if (!this.detoxManagementForm || !this.detoxManagementForm.value || !this.detoxManagementForm.value.urine_color || !this.detoxManagementForm.value.eyes || !this.detoxManagementForm.value.tongue || !this.detoxManagementForm.value.food_habit || !this.detoxManagementForm.value.bowel_movement) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let detoxManagementAssessment = [
                        this.detoxManagementForm.value.cholestrol_levels_abnormal,
                        this.detoxManagementForm.value.know_about_fatty_liver,
                        this.detoxManagementForm.value.physically_active,
                        this.detoxManagementForm.value.drink_alcohol,
                        this.detoxManagementForm.value.medical_history,
                        this.detoxManagementForm.value.urine_color,
                        this.detoxManagementForm.value.eyes,
                        this.detoxManagementForm.value.tongue,
                        this.detoxManagementForm.value.food_habit,
                        this.detoxManagementForm.value.bowel_movement,
                        this.detoxManagementForm.value.name,
                        this.detoxManagementForm.value.email,
                        this.detoxManagementForm.value.contact,
                        this.detoxManagementForm.value.gender,
                        this.detoxManagementForm.value.age
                    ];
                    let difference = this.detoxManagementTrueArr.filter(x => !detoxManagementAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.detoxManagementFormStep = 3;
                    this.stepperDetoxManagement.next();
                }
            } else if (this.detoxManagementFormStep === 3) {
                if (!this.detoxManagementForm || !this.detoxManagementForm.value || !this.detoxManagementForm.value.name || !this.detoxManagementForm.value.email || !this.detoxManagementForm.value.gender || !this.detoxManagementForm.value.contact || !this.detoxManagementForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'FIBROID') {
            if (this.fibroidFormStep === 1) {
                if (!this.fibroidForm || !this.fibroidForm.value || !this.fibroidForm.value.period_age || !this.fibroidForm.value.cycle_duration || !this.fibroidForm.value.periods_interval || !this.fibroidForm.value.family_history || !this.fibroidForm.value.medical_history) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.fibroidFormStep = 2;
                    this.stepperFibroid.next();
                }
            } else if (this.fibroidFormStep === 2) {
                if (!this.fibroidForm || !this.fibroidForm.value || !this.fibroidForm.value.amount_of_flow || !this.fibroidForm.value.pain_with_periods || !this.fibroidForm.value.intermenstrual || !this.fibroidForm.value.white_discharge || !this.fibroidForm.value.feel_fibroid) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let fibroidAssessment = [
                        this.fibroidForm.value.period_age,
                        this.fibroidForm.value.cycle_duration,
                        this.fibroidForm.value.periods_interval,
                        this.fibroidForm.value.amount_of_flow,
                        this.fibroidForm.value.pain_with_periods,
                        this.fibroidForm.value.intermenstural,
                        this.fibroidForm.value.white_discharge,
                        this.fibroidForm.value.medical_history,
                        this.fibroidForm.value.family_history,
                        this.fibroidForm.value.feel_fibroid,
                        this.fibroidForm.value.name,
                        this.fibroidForm.value.email,
                        this.fibroidForm.value.contact,
                        this.fibroidForm.value.gender,
                        this.fibroidForm.value.age
                    ];
                    let difference = this.fibroidTrueArr.filter(x => !fibroidAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.fibroidFormStep = 3;
                    this.stepperFibroid.next();
                }
            } else if (this.fibroidFormStep === 3) {
                if (!this.fibroidForm || !this.fibroidForm.value || !this.fibroidForm.value.name || !this.fibroidForm.value.email || !this.fibroidForm.value.gender || !this.fibroidForm.value.contact || !this.fibroidForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'NATURAL_KIDNEY_CARE') {
            if (this.naturalKidneyCareFormStep === 1) {
                if (!this.naturalKidneyCareForm || !this.naturalKidneyCareForm.value || !this.naturalKidneyCareForm.value.medical_history || !this.naturalKidneyCareForm.value.family_history || !this.naturalKidneyCareForm.value.difficulty_passing_urine) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.naturalKidneyCareFormStep = 2;
                    this.stepperNaturalKidneyCare.next();
                }
            } else if (this.naturalKidneyCareFormStep === 2) {
                if (!this.naturalKidneyCareForm || !this.naturalKidneyCareForm.value || !this.naturalKidneyCareForm.value.swelling_on_leg || !this.naturalKidneyCareForm.value.swelling_on_face || !this.naturalKidneyCareForm.value.backache_abdomial_pain || !this.naturalKidneyCareForm.value.food_habit || !this.naturalKidneyCareForm.value.bowel_movement || !this.naturalKidneyCareForm.value.urine_color || !this.naturalKidneyCareForm.value.drink_alcohol) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let naturalKidneyCareAssessment = [
                        this.naturalKidneyCareForm.value.medical_history,
                        this.naturalKidneyCareForm.value.family_history,
                        this.naturalKidneyCareForm.value.difficulty_passing_urine,
                        this.naturalKidneyCareForm.value.swelling_on_leg,
                        this.naturalKidneyCareForm.value.swelling_on_face,
                        this.naturalKidneyCareForm.value.backache_abdomial_pain,
                        this.naturalKidneyCareForm.value.food_habit,
                        this.naturalKidneyCareForm.value.bowel_movement,
                        this.naturalKidneyCareForm.value.urine_color,
                        this.naturalKidneyCareForm.value.drink_alcohol,
                        this.naturalKidneyCareForm.value.name,
                        this.naturalKidneyCareForm.value.email,
                        this.naturalKidneyCareForm.value.contact,
                        this.naturalKidneyCareForm.value.gender,
                        this.naturalKidneyCareForm.value.age
                    ];
                    let difference = this.naturalKidneyCareTrueArr.filter(x => !naturalKidneyCareAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = 'SEVERE';
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = 'MODERATE';
                    } else {
                        this.chances = 'MILD';
                    }
                    this.naturalKidneyCareFormStep = 3;
                    this.stepperNaturalKidneyCare.next();
                }
            } else if (this.naturalKidneyCareFormStep === 3) {
                if (!this.naturalKidneyCareForm || !this.naturalKidneyCareForm.value || !this.naturalKidneyCareForm.value.name || !this.naturalKidneyCareForm.value.email || !this.naturalKidneyCareForm.value.gender || !this.naturalKidneyCareForm.value.contact || !this.naturalKidneyCareForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        } else if (type === 'PRAKRITI') {
            if (this.prakritiFormStep === 1) {
                if (!this.prakritiForm || !this.prakritiForm.value || !this.prakritiForm.value.medical_history || !this.prakritiForm.value.family_history || !this.prakritiForm.value.how_long_suffering_from_diabetes) {
                    this.alertService.showErrors('Please fill complete previous history to proceed');
                } else {
                    this.prakritiFormStep = 2;
                    this.stepperDiabetesManager.next();
                }
            } else if (this.prakritiFormStep === 2) {
                if (!this.prakritiForm || !this.prakritiForm.value || !this.prakritiForm.value.overweight_or_obese || !this.prakritiForm.value.physically_active || !this.prakritiForm.value.diabetes_related_complications || !this.prakritiForm.value.medicines_already_taking || !this.prakritiForm.value.doing_excercises_walk || !this.prakritiForm.value.proper_diabetic_diet || !this.prakritiForm.value.checking_blood_sugar_level_regularly) {
                    this.alertService.showErrors('Please fill complete abnormalities to proceed');
                } else {
                    let prakritiAssessment = [];
                    let difference = this.prakritiTrueArr.filter(x => !prakritiAssessment.includes(x));
                    if (difference.length <= 3) {
                        this.chances = null;
                    } else if (difference.length >= 3 && difference.length < 8) {
                        this.chances = null;
                    } else {
                        this.chances = null;
                    }
                    this.prakritiFormStep = 3;
                    this.prakritiAssessment.next();
                }
            } else if (this.prakritiFormStep === 4) {
                if (!this.prakritiForm || !this.prakritiForm.value || !this.prakritiForm.value.name || !this.prakritiForm.value.email || !this.prakritiForm.value.gender || !this.prakritiForm.value.contact || !this.prakritiForm.value.age) {
                    this.alertService.showErrors('Please fill complete general details to proceed');
                }
            }
        }
    }

    back(type) {
        if (type === 'LIVER_SAVIOR') {
            this.liverFormStep = this.liverFormStep - 1;
            this.stepperLiver.previous();
        } else if (type === 'WOMEN_CARE') {
            this.womenCareFormStep = this.womenCareFormStep - 1;
            this.stepperWomenCare.previous();
        } else if (type === 'MALE_REJUVENATOR') {
            this.maleRejuvenatorFormStep = this.maleRejuvenatorFormStep - 1;
            this.stepperMaleRejuventaor.previous();
        } else if (type === 'OBESITY_PACIFIER') {
            this.obesityPacifierFormStep = this.obesityPacifierFormStep - 1;
            this.stepperObesityPacifier.previous();
        } else if (type === 'DIABETES_MANAGER') {
            this.diabetesManagerFormStep = this.diabetesManagerFormStep - 1;
            this.stepperDiabetesManager.previous();
        } else if (type === 'THYRO_GUARD') {
            this.thyroguardFormStep = this.thyroguardFormStep - 1;
            this.stepperThyroGuard.previous();
        } else if (type === 'ACIDITY_UPROOTER') {
            this.thyroguardFormStep = this.thyroguardFormStep - 1;
            this.stepperAcidityUprooter.previous();
        } else if (type === 'ORTHO_FORTIFY') {
            this.orthoFortifyFormStep = this.orthoFortifyFormStep - 1;
            this.stepperOrthoFortify.previous();
        } else if (type === 'DETOX_MANAGEMENT') {
            this.detoxManagementFormStep = this.detoxManagementFormStep - 1;
            this.stepperDetoxManagement.previous();
        } else if (type === 'FIBROID') {
            this.fibroidFormStep = this.fibroidFormStep - 1;
            this.stepperFibroid.previous();
        } else if (type === 'NATURAL_KIDNEY_CARE') {
            this.naturalKidneyCareFormStep = this.naturalKidneyCareFormStep - 1;
            this.stepperNaturalKidneyCare.previous();
        } else if (type === 'PRAKRITI') {
            this.prakritiFormStep = this.prakritiFormStep - 1;
            this.stepperFibroid.previous();
        }
    }

    submit(type) {
        if (type === 'LIVER_SAVIOR') {
            this.submitted = true;
            if (!this.liverSaviorForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.liverSaviorForm.value && this.liverSaviorForm.value.name) ? this.liverSaviorForm.value.name : '',
                    'email': (this.liverSaviorForm.value && this.liverSaviorForm.value.email) ? this.liverSaviorForm.value.email : '',
                    'gender': (this.liverSaviorForm.value && this.liverSaviorForm.value.gender) ? this.liverSaviorForm.value.gender : '',
                    'contact': (this.liverSaviorForm.value && this.liverSaviorForm.value.contact) ? this.liverSaviorForm.value.contact : '',
                    'age': (this.liverSaviorForm.value && this.liverSaviorForm.value.age) ? this.liverSaviorForm.value.age : '',
                    'type': 'LIVER_SAVIOR',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.liverSaviorForm.reset();
                        this.liverSaviorForm.patchValue({
                            'cholesterol_levels': '',
                            'drink_alcohol': '',
                            'family_history': '',
                            'medical_history': '',
                            'urine_color': '',
                            'abnormalities_fingernail': '',
                            'eyes': '',
                            'tongue': '',
                            'puffiness_or_swelling_face': '',
                            'swelling_on_your_leg': '',
                            'name': '',
                            'email': '',
                            'gender': '',
                            'contact': '',
                            'age': ''
                        });
                        this.stepperLiver.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'WOMEN_CARE') {
            this.submitted = true;
            if (!this.womenCareForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.womenCareForm.value && this.womenCareForm.value.name) ? this.womenCareForm.value.name : '',
                    'email': (this.womenCareForm.value && this.womenCareForm.value.email) ? this.womenCareForm.value.email : '',
                    'gender': (this.womenCareForm.value && this.womenCareForm.value.gender) ? this.womenCareForm.value.gender : '',
                    'contact': (this.womenCareForm.value && this.womenCareForm.value.contact) ? this.womenCareForm.value.contact : '',
                    'age': (this.womenCareForm.value && this.womenCareForm.value.age) ? this.womenCareForm.value.age : '',
                    'type': 'WOMEN_CARE',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.womenCareForm.reset();
                        this.womenCareForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'heaviness_in_body_head': '',
                            'appetite': '',
                            'menstrual_history': '',
                            'white_discharge': '',
                            'feeling_stressed_anxiety': '',
                            'lack_of_taste': '',
                            'frequent_urination': '',
                            'coital_pain_bleeding': '',
                            'name': '',
                            'email': '',
                            'gender': '',
                            'contact': '',
                            'age': ''
                        });
                        this.stepperWomenCare.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'MALE_REJUVENATOR') {
            this.submitted = true;
            if (!this.maleRejuvenatorForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.maleRejuvenatorForm.value && this.maleRejuvenatorForm.value.name) ? this.maleRejuvenatorForm.value.name : '',
                    'email': (this.maleRejuvenatorForm.value && this.maleRejuvenatorForm.value.email) ? this.maleRejuvenatorForm.value.email : '',
                    'gender': (this.maleRejuvenatorForm.value && this.maleRejuvenatorForm.value.gender) ? this.maleRejuvenatorForm.value.gender : '',
                    'contact': (this.maleRejuvenatorForm.value && this.maleRejuvenatorForm.value.contact) ? this.maleRejuvenatorForm.value.contact : '',
                    'age': (this.maleRejuvenatorForm.value && this.maleRejuvenatorForm.value.age) ? this.maleRejuvenatorForm.value.age : '',
                    'type': 'MALE_REJUVENATOR',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.maleRejuvenatorForm.reset();
                        this.maleRejuvenatorForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'sexual_desire_interest': '',
                            'rapid_delayed_ejaculation': '',
                            'pain': '',
                            'bowel_movement': '',
                            'food_habit': '',
                            'feeling_stressed_anxiety': '',
                            'drink_alcohol': '',
                            'feel_weak': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperMaleRejuventaor.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'OBESITY_PACIFIRE') {
            this.submitted = true;
            if (!this.obesityPacifierForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.obesityPacifierForm.value && this.obesityPacifierForm.value.name) ? this.obesityPacifierForm.value.name : '',
                    'email': (this.obesityPacifierForm.value && this.obesityPacifierForm.value.email) ? this.obesityPacifierForm.value.email : '',
                    'gender': (this.obesityPacifierForm.value && this.obesityPacifierForm.value.gender) ? this.obesityPacifierForm.value.gender : '',
                    'contact': (this.obesityPacifierForm.value && this.obesityPacifierForm.value.contact) ? this.obesityPacifierForm.value.contact : '',
                    'age': (this.obesityPacifierForm.value && this.obesityPacifierForm.value.age) ? this.obesityPacifierForm.value.age : '',
                    'type': 'OBESITY_PACIFIRE',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.obesityPacifierForm.reset();
                        this.obesityPacifierForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'sexual_desire_interest': '',
                            'rapid_delayed_ejaculation': '',
                            'pain': '',
                            'bowel_movement': '',
                            'food_habit': '',
                            'feeling_stressed_anxiety': '',
                            'drink_alcohol': '',
                            'feel_weak': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperObesityPacifier.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'DIABETES_MANAGER') {
            this.submitted = true;
            if (!this.diabetesManagerForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.diabetesManagerForm.value && this.diabetesManagerForm.value.name) ? this.diabetesManagerForm.value.name : '',
                    'email': (this.diabetesManagerForm.value && this.diabetesManagerForm.value.email) ? this.diabetesManagerForm.value.email : '',
                    'gender': (this.diabetesManagerForm.value && this.diabetesManagerForm.value.gender) ? this.diabetesManagerForm.value.gender : '',
                    'contact': (this.diabetesManagerForm.value && this.diabetesManagerForm.value.contact) ? this.diabetesManagerForm.value.contact : '',
                    'age': (this.diabetesManagerForm.value && this.diabetesManagerForm.value.age) ? this.diabetesManagerForm.value.age : '',
                    'type': 'DIABETES_MANAGER',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.diabetesManagerForm.reset();
                        this.diabetesManagerForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'how_long_suffering_from_diabetes': '',
                            'overweight_or_obese': '',
                            'physically_active': '',
                            'diabetes_related_complications': '',
                            'medicines_already_taking': '',
                            'doing_excercises_walk': '',
                            'proper_diabetic_diet': '',
                            'checking_blood_sugar_level_regularly': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperDiabetesManager.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'THYRO_GUARD') {
            this.submitted = true;
            if (!this.thyroGuardForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.thyroGuardForm.value && this.thyroGuardForm.value.name) ? this.thyroGuardForm.value.name : '',
                    'email': (this.thyroGuardForm.value && this.thyroGuardForm.value.email) ? this.thyroGuardForm.value.email : '',
                    'gender': (this.thyroGuardForm.value && this.thyroGuardForm.value.gender) ? this.thyroGuardForm.value.gender : '',
                    'contact': (this.thyroGuardForm.value && this.thyroGuardForm.value.contact) ? this.thyroGuardForm.value.contact : '',
                    'age': (this.thyroGuardForm.value && this.thyroGuardForm.value.age) ? this.thyroGuardForm.value.age : '',
                    'type': 'THYRO_GUARD',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.thyroGuardForm.reset();
                        this.thyroGuardForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'hair_falling': '',
                            'weight_increasing_very_fast': '',
                            'no_weight_reduction_after_gym': '',
                            'muscle_weakness': '',
                            'feel_puffiness_swelling_on_face': '',
                            'feel_muscular_fatigue_lethargy': '',
                            'bowel_movement': '',
                            'how_many_times_eat': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperThyroGuard.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'ACIDITY_UPROOTER') {
            this.submitted = true;
            if (!this.acidityUprooterForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.acidityUprooterForm.value && this.acidityUprooterForm.value.name) ? this.acidityUprooterForm.value.name : '',
                    'email': (this.acidityUprooterForm.value && this.acidityUprooterForm.value.email) ? this.acidityUprooterForm.value.email : '',
                    'gender': (this.acidityUprooterForm.value && this.acidityUprooterForm.value.gender) ? this.acidityUprooterForm.value.gender : '',
                    'contact': (this.acidityUprooterForm.value && this.acidityUprooterForm.value.contact) ? this.acidityUprooterForm.value.contact : '',
                    'age': (this.acidityUprooterForm.value && this.acidityUprooterForm.value.age) ? this.acidityUprooterForm.value.age : '',
                    'type': 'ACIDITY_UPROOTER',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.acidityUprooterForm.reset();
                        this.acidityUprooterForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'heaviness_in_body_head': '',
                            'appetite': '',
                            'gas_bloating_issues': '',
                            'experience_acidity_heartburn': '',
                            'experience_nausea': '',
                            'bad_breath': '',
                            'mucous_irritation_in_throat': '',
                            'difficulty_in_bowel_evacuation': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperAcidityUprooter.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'ORTHO_FORTIFY') {
            this.submitted = true;
            if (!this.orthoFortifyForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.orthoFortifyForm.value && this.orthoFortifyForm.value.name) ? this.orthoFortifyForm.value.name : '',
                    'email': (this.orthoFortifyForm.value && this.orthoFortifyForm.value.email) ? this.orthoFortifyForm.value.email : '',
                    'gender': (this.orthoFortifyForm.value && this.orthoFortifyForm.value.gender) ? this.orthoFortifyForm.value.gender : '',
                    'contact': (this.orthoFortifyForm.value && this.orthoFortifyForm.value.contact) ? this.orthoFortifyForm.value.contact : '',
                    'age': (this.orthoFortifyForm.value && this.orthoFortifyForm.value.age) ? this.orthoFortifyForm.value.age : '',
                    'type': 'ORTHO_FORTIFY',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.orthoFortifyForm.reset();
                        this.orthoFortifyForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'heaviness_in_body_head': '',
                            'appetite': '',
                            'gas_bloating_issues': '',
                            'experience_acidity_heartburn': '',
                            'experience_nausea': '',
                            'bad_breath': '',
                            'mucous_irritation_in_throat': '',
                            'difficulty_in_bowel_evacuation': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperOrthoFortify.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'DETOX_MANAGEMENT') {
            this.submitted = true;
            if (!this.detoxManagementForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.detoxManagementForm.value && this.detoxManagementForm.value.name) ? this.detoxManagementForm.value.name : '',
                    'email': (this.detoxManagementForm.value && this.detoxManagementForm.value.email) ? this.detoxManagementForm.value.email : '',
                    'gender': (this.detoxManagementForm.value && this.detoxManagementForm.value.gender) ? this.detoxManagementForm.value.gender : '',
                    'contact': (this.detoxManagementForm.value && this.detoxManagementForm.value.contact) ? this.detoxManagementForm.value.contact : '',
                    'age': (this.detoxManagementForm.value && this.detoxManagementForm.value.age) ? this.detoxManagementForm.value.age : '',
                    'type': 'DETOX_MANAGEMENT',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.detoxManagementForm.reset();
                        this.detoxManagementForm.patchValue({
                            'cholestrol_levels_abnormal': '',
                            'know_about_fatty_liver': '',
                            'physically_active': '',
                            'drink_alcohol': '',
                            'medical_history': '',
                            'urine_color': '',
                            'eyes': '',
                            'tongue': '',
                            'food_habit': '',
                            'bowel_movement': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperDetoxManagement.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'FIBROID') {
            this.submitted = true;
            if (!this.fibroidForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.fibroidForm.value && this.fibroidForm.value.name) ? this.fibroidForm.value.name : '',
                    'email': (this.fibroidForm.value && this.fibroidForm.value.email) ? this.fibroidForm.value.email : '',
                    'gender': (this.fibroidForm.value && this.fibroidForm.value.gender) ? this.fibroidForm.value.gender : '',
                    'contact': (this.fibroidForm.value && this.fibroidForm.value.contact) ? this.fibroidForm.value.contact : '',
                    'age': (this.fibroidForm.value && this.fibroidForm.value.age) ? this.fibroidForm.value.age : '',
                    'type': 'FIBROID',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.fibroidForm.reset();
                        this.fibroidForm.patchValue({
                            'period_age': '',
                            'cycle_duration': '',
                            'periods_interval': '',
                            'amount_of_flow': '',
                            'pain_with_periods': '',
                            'intermenstural': '',
                            'white_discharge': '',
                            'medical_history': '',
                            'family_history': '',
                            'feel_fibroid': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperFibroid.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'NATURAL_KIDNEY_CARE') {
            this.submitted = true;
            if (!this.naturalKidneyCareForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.naturalKidneyCareForm.value && this.naturalKidneyCareForm.value.name) ? this.naturalKidneyCareForm.value.name : '',
                    'email': (this.naturalKidneyCareForm.value && this.naturalKidneyCareForm.value.email) ? this.naturalKidneyCareForm.value.email : '',
                    'gender': (this.naturalKidneyCareForm.value && this.naturalKidneyCareForm.value.gender) ? this.naturalKidneyCareForm.value.gender : '',
                    'contact': (this.naturalKidneyCareForm.value && this.naturalKidneyCareForm.value.contact) ? this.naturalKidneyCareForm.value.contact : '',
                    'age': (this.naturalKidneyCareForm.value && this.naturalKidneyCareForm.value.age) ? this.naturalKidneyCareForm.value.age : '',
                    'type': 'NATURAL_KIDNEY_CARE',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.fibroidForm.reset();
                        this.naturalKidneyCareForm.patchValue({
                            'medical_history': '',
                            'family_history': '',
                            'difficulty_passing_urine': '',
                            'swelling_on_leg': '',
                            'swelling_on_face': '',
                            'backache_abdomial_pain': '',
                            'food_habit': '',
                            'bowel_movement': '',
                            'urine_color': '',
                            'drink_alcohol': '',
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperNaturalKidneyCare.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        } else if (type === 'PRAKRITI') {
            this.submitted = true;
            if (!this.prakritiForm.valid) {
                return;
            }
            if (this.submitted) {
                const params = {
                    'name': (this.prakritiForm.value && this.prakritiForm.value.name) ? this.prakritiForm.value.name : '',
                    'email': (this.prakritiForm.value && this.prakritiForm.value.email) ? this.prakritiForm.value.email : '',
                    'gender': (this.prakritiForm.value && this.prakritiForm.value.gender) ? this.prakritiForm.value.gender : '',
                    'contact': (this.prakritiForm.value && this.prakritiForm.value.contact) ? this.prakritiForm.value.contact : '',
                    'age': (this.prakritiForm.value && this.prakritiForm.value.age) ? this.prakritiForm.value.age : '',
                    'type': 'PRAKRITI',
                    'chance': this.chances
                };
                this.submitting = true;
                this.selfAssessmentService.create(params).subscribe(data => {
                    if (data) {
                        this.submitting = false;
                        this.submitted = false;
                        this.prakritiForm.reset();
                        this.prakritiForm.patchValue({
                            'chance': null,
                            'name': '',
                            'email': '',
                            'contact': '',
                            'gender': '',
                            'age': ''
                        });
                        this.stepperPrakriti.to(1);
                        this.modalService.open(this.templateRef);
                    }
                }, error => {
                    this.submitting = false;
                    this.submitted = false;
                    if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                        if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].name[0]);
                            return;
                        }
                        if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].email[0]);
                            return;
                        }
                        if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].gender[0]);
                            return;
                        }
                        if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].contact[0]);
                            return;
                        }
                        if (error['error']['errors'].age && error['error']['errors'].age.length > 0) {
                            this.alertService.showErrors(error['error']['errors'].age[0]);
                            return;
                        }
                    }
                });
            }
        }
    }

    goTo(url) {
        this.modalService.dismissAll('');
        this.router.navigateByUrl(url);
    }


}
