import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-pharmacy-navbar',
    templateUrl: './pharmacy-navbar.component.html',
    styleUrls: ['./pharmacy-navbar.component.scss']
})
export class PharmacyNavbarComponent implements OnInit {
    users: any = [];

    constructor() {
    }

    ngOnInit(): void {
    }

}
