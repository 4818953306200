<section class="welcome-area mb-5" id="self-assessment">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-5">
                <h1 class=" mt-50px mt--20px">
                    <span class="font-bold">Our Outlets</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card bg-white"
                     style="background-color: whitesmoke;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;border: none">
                    <div class="card-body mx-lg-5">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h2>Franchises:</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngFor="let outlet of outlets" class="col-lg-4 col-md-6 mt-3" style="cursor: pointer">
                                <div class="card shadow-lg p-3 mb-5 bg-body rounded outlet-card"
                                     style="border-radius: 15px !important;height: 90%!important;border: 3px dashed #467e41;">
                                    <div class="card-body px-0">
                                        <h5 class="card-title" style="color: #467e41;">{{outlet?.name}}</h5>
                                        <div class="row">
                                            <div class="col-4">
                                                <h6 style="color: #3d4294;">Code-</h6>
                                            </div>
                                            <div class="col-8">
                                                <p class="card-text">{{outlet?.code}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <h6 style="color: #3d4294;">Location-</h6>
                                            </div>
                                            <div class="col-8">
                                                <p class="card-text">{{outlet?.address}}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <h2>The future of Ayurveda Clinic:</h2>
                                <p class="text-justify">
                                    Ayurveda clinics have a bright future as more people seek out natural and comprehensive
                                    treatments for illness. An ancient Indian medical practise called Ayurveda places a strong
                                    emphasis on achieving harmony between the physical, mental, and spiritual bodies. The
                                    holistic nature of Ayurveda makes it ideal for preventive healthcare. Ayurveda clinics could
                                    concentrate on educating people on leading healthy lifestyles, including self-care,
                                    exercise, and stress management. Ayurveda clinics may employ cutting-edge diagnostic methods
                                    to evaluate patients' health conditions and provide individualised treatment regimens for
                                    the best outcomes.
                                </p>

                                <h2>Vision:</h2>
                                <p class="text-justify">The primary goal of the Dr. Axico clinic is to promote holistic
                                    well-being by addressing the physical, mental, and spiritual aspects of an individual. It
                                    aims to restore balance and harmony in the body and mind, leading to optimal health and
                                    vitality. The Dr. Axico Clinic envisions providing personalised care by understanding the
                                    specific needs of each patient and designing tailored treatment plans accordingly. The
                                    vision of the Dr. Axico Clinic is to harness the healing power of nature and provide
                                    therapies, medicines, and dietary recommendations that are gentle, safe, and effective. The
                                    vision of the Dr. Axico Clinic may involve promoting the acceptance and integration of
                                    Ayurveda as a complementary or alternative therapy in healthcare systems globally, thereby
                                    expanding its reach and impact.
                                </p>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <h2>Models:</h2>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col cus-button">
                                <button (click)="openXl(content)" class="btn btn-outline-primary mb-2 me-2">Dr. Axico Base Model
                                </button>
                                <button (click)="openXl2(content2)" class="btn btn-outline-primary mb-2 me-2">Dr. Axico Standard
                                    Model
                                </button>
                            </div>
                        </div>

                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title">Dr. Axico Base Model</h4>
                                <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close"
                                        type="button"></button>
                            </div>
                            <div class="modal-body">
                                <p>In essence, the Dr. Axico base model is a teleconsultation clinic and pharmacy
                                    where the doctor only makes a weekly physical visit and all other consultations
                                    are handled over the phone or via video through the Dr. Axico app.</p>
                                <p><strong>Basic Requirement of Franchise:-</strong></p>
                                <ul>
                                    <li>
                                        <p>Area Requirement: 80-100 sqft</p>
                                    </li>
                                    <li>
                                        <p>Franchise fees: 10000/-</p>
                                    </li>
                                    <li>
                                        <p>Medicine worth 30000/</p>
                                    </li>
                                    <li>
                                        <p>Desktop or Laptop along with printer for billing purpose.
                                        </p>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong>Company&rsquo;s Support:-</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p>Doctor Hiring &amp; Training.</p>
                                    </li>
                                    <li>
                                        <p>Marketing &amp; Advertisement.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Branding.</p>
                                    </li>
                                    <li>
                                        <p>GST Consulting Service.</p>
                                    </li>
                                </ul>
                                <p><br/><strong>Dr&rsquo;s Support:-</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p>Physical Consultation (Once in a week)
                                        </p>
                                    </li>
                                    <li>
                                        <p>Tele Consultation.</p>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong>Franchise Benefit:-</strong></p>
                                <ul>
                                    <li>
                                        <p>30% margin on all Products.</p>
                                    </li>
                                    <li>
                                        <p>Dr. Visit once in a week.</p>
                                    </li>
                                    <li>
                                        <p>Tele consultation service.</p>
                                    </li>
                                    <li>
                                        <p>ROI: 20-30 %</p>
                                    </li>
                                    <li>
                                        <p>Area Radius &ndash; 3 km</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="modal-footer">
                                <button (click)="modal.close('Close click')" class="btn btn-light" type="button">Close</button>
                            </div>
                        </ng-template>

                        <ng-template #content2 let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title">Dr. Axico Standard Model</h4>
                                <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close"
                                        type="button"></button>
                            </div>
                            <div class="modal-body">
                                <p><strong>Basic Requirement of Franchise:</strong>
                                </p>

                                <ul>
                                    <li>
                                        <p>Area Requirement: 200-250 sqft
                                        </p>
                                    </li>
                                    <li>
                                        <p>Franchise fees: 25,000/(Refundable)
                                        </p>
                                    </li>
                                    <li>
                                        <p>Medicine worth 75,000/</p>
                                    </li>
                                    <li>
                                        <p>Desktop or Laptop along with printer for billing purpose.
                                        </p>
                                    </li>
                                </ul>

                                <p><br/><strong>Company&rsquo;s Support:</strong>
                                </p>

                                <ul>
                                    <li>
                                        <p>Doctor Hiring &amp; Training.</p>
                                    </li>
                                    <li>
                                        <p>Marketing &amp; Advertisement.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Branding.</p>
                                    </li>
                                    <li>
                                        <p>GST Consulting Service.</p>
                                    </li>
                                    <li>
                                        <p>Free Medical Camp once in a month.
                                        </p>
                                    </li>
                                </ul>

                                <p><br/><strong>Dr&rsquo;s Support:</strong>
                                </p>

                                <ul>
                                    <li>
                                        <p>Physical Consultation (Mon/Tues/Wed- 8am to 2pm and Fri/Sat/Sun- 3pm to 9pm)
                                        </p>
                                    </li>
                                    <li>
                                        <p>Tele Consultation.</p>
                                    </li>
                                    <li>
                                        <p>Para Surgical Treatment like Leech Therapy, Rakta mokshana (Bloodletting),
                                            Agni Karma (Cautery), Cupping therapy.
                                        </p>
                                    </li>
                                </ul>

                                <p><br/><strong>Franchise Benefit:-</strong>
                                </p>

                                <ul>
                                    <li>
                                        <p>40% margin on all Products.</p>
                                    </li>
                                    <li>
                                        <p>Dr. Visit on Daily basis.</p>
                                    </li>
                                    <li>
                                        <p>Tele consultation service.</p>
                                    </li>
                                    <li>
                                        <p>ROI: 30-40 %</p>
                                    </li>
                                    <li>
                                        <p>Area Radius &ndash; 5 km</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="modal-footer">
                                <button (click)="modal.close('Close click')" class="btn btn-light" type="button">Close</button>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
