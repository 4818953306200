<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Terms & Conditions</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>1.What is Terms & Conditions?</h2>
            <p>According to the Information Technology Act of 2000 and its implementing rules, as well as the amended
                provisions relating to electronic records in various statutes as amended by the Information Technology
                Act of 2000, this document qualifies as an electronic record. This electronic record is produced by a
                computer system without the need for any manual or electronic signatures.</p>
        </div>

        <div class="privacy-item">
            <h2>2.Welcome to the Dr. Axico</h2>
            <p>The following terms and conditions, our privacy statement, payment statement, and other terms and
                conditions and policies that you may find on our website in connection with particular functionality,
                features, or promotions, as well as customer service, are all deemed to be a part of and included within
                these terms and conditions. Dr. Axico provides the content and services available on this website to you
                subject to these terms and conditions (collectively, "Terms and Conditions"). Please carefully read this
                Agreement before using this website as you are indicating that you have read, understood, and agree,
                without restriction or qualification, to be bound by its Terms and Conditions.</p>
        </div>
        <div class="privacy-item">
            <h2>3.Privacy</h2>
            <p>Please review our privacy-policy so that you may understand our privacy practices.</p>
        </div>
        <div class="privacy-item">
            <h2>4.Payment Policy</h2>
            <p>Please see our Payment Policy to understand the purchase processes of our products.</p> <br>
            <p>All international shipping orders may attract local duties applicable in that country and accordingly the
                customer will have to pay such local duties/taxes.</p>
        </div>
        <div class="privacy-item">
            <h2>5.General</h2>
            <p>You understand and agree that these Terms and Conditions supersede and apply to all earlier proposals,
                agreements, or other communications and are the whole and only agreement between us regarding your use
                of the website.</p>
            <p>We reserve the right to modify these Terms and Conditions at any time by posting the modifications online
                and notifying you of the change. Any modifications take effect right away after being posted on the
                website and given notice. Your continued use of the website following the changes signifies your
                acceptance of all modified Terms and Conditions. Any rights provided by these Terms and Conditions may
                be terminated by us with or without notice. Any notification of termination or other change must be
                complied with immediately by you, including, if necessary, stopping all website usage.</p>
            <p>Nothing in these Terms and Conditions shall be interpreted as establishing between us any kind of
                partnership, agency, or other joint venture. Our failure to insist that you abide by any of the terms of
                this agreement will not limit our ability to do so in the future, and neither will our waiver of a
                violation of any such provision be interpreted as a waiver of the provision in question. In the event
                that any provision of these Terms and Conditions is found to be unlawful, void, or otherwise
                unenforceable pursuant to any applicable law, applicable arbitral award, or applicable court decision,
                such unenforceability, voidness, or unenforceability shall not affect the validity or enforceability of
                the remaining provisions of these Terms and Conditions, which shall be modified to the greatest extent
                possible to reflect the original intentions of the parties.</p>
        </div>
        <div class="privacy-item">
            <h2>6.Products and Services for Personal Use</h2>
            <p>The goods and services mentioned on this website, as well as any samples we may give you, are exclusively
                intended for private use. Any of the goods or services, or samples thereof, that you obtain from us may
                not be sold or resold. Any items or services that we are going to give you that we consider, in our sole
                discretion, may lead to a breach of our Terms and Conditions, we reserve the right to cancel or reduce
                the quantity of with or without prior notice.</p>
        </div>
        <div class="privacy-item">
            <h2>7.Accuracy, Completeness and Timeliness of Information</h2>
            <p>We make an effort to provide the most accurate product descriptions on the internet. However, we do not
                guarantee that the product descriptions, colors, information, or other content available on the website
                are accurate, comprehensive, reliable, current, or error-free, except as expressly provided by
                applicable law.</p> <br>
            <p>Inaccurate, incomplete, or out-of-date information on this website is not our responsibility. The content
                on this website is provided for informational purposes only and should not be relied upon or used as the
                only basis for choices without consulting primary, more reliable, complete, or up-to-date sources of
                data. Your use of the information on this website is at your own risk.</p> <br>
            <p>There may be historical data on this website. Inevitably, historical material is not current and is only
                supplied for your reference. Although we reserve the right to change the information on this site at any
                time, we are under no obligation to do so. You acknowledge that it is your duty to keep track of updates
                to our website.</p>
        </div>
        <div class="privacy-item">
            <h2>8.Intellectual Property</h2>
            <p>Including but not limited to trademarks, logos, service marks, text, graphics, logos, button icons,
                images, audio clips, data compilations, software, and the compilation and organization thereof
                (collectively, the "Content"), all information and content available on the website and its "look and
                feel" are the property of Dr. Axico, our Affiliates, partners, or licensors and are protected by laws of
                India, including laws governing all applicable forms of intellectual property.</p> <br>
            <p>Neither the Content nor any part of this website may be used, reproduced, duplicated, copied, sold,
                resold, accessed, modified, or otherwise exploited, in whole or in part, for any purpose without our
                express, prior written consent, with the exception of the limited licenses described in Section 6 or as
                required by applicable law.</p>
        </div>
        <div class="privacy-item">
            <h2>9.Limited Licenses</h2>
            <p>You are given a constrained, terminable, and non-exclusive license to access and utilize the Dr. Axico
                website for your own personal use. This limited license does not grant you the right to: (a) frame the
                website or any part of it; (b) republish, redistribute, transmit, sell, license, or download it or any
                of its contents (other than caching as required to view the website); (c) use the website or any of its
                contents for any purpose other than personal use; (d) modify, reverse engineer, or produce any
                derivative works based on the website or any of its contents;(e) Use any meta tags or other "hidden
                text" utilizing any and/or all Content. (e) Collect account information for your own or another party's
                benefit. (f) Use any software robots, spiders, crawlers, or similar data gathering and extraction tools.
                (g) Take any other action that could place an unreasonable load on our infrastructure. All proprietary
                notices on the website, attached to the website, or contained in the website, shall remain
                unaltered.</p> <br>
            <p>Additionally, for your personal, non-commercial use only, we hereby grant you a constrained, revocable,
                and nonexclusive permission to erect a hyperlink to the website's home page. A website that links to the
                website (i)must I not replicate any and/or all of our Content; (ii) not imply that we support the
                website or any of its services or products; (iii) not misrepresent our affiliation with the website;
                (iv) not contain any material that could be interpreted as offensive, controversial, illegal, or
                unsuitable for any age; and (v) not present us or our services in a false, misleading, derogatory, or
                otherwise offensive manner(vi) is only permitted to link to the home page of the website. You must
                remove any link to the website immediately upon receiving a request from us to do so, unless we
                separately and expressly give you written permission to restart connecting. We reserve the right to
                request that you remove any link to the website at any time.</p> <br>
            <p>The restricted rights outlined in this Section are automatically terminated by any illegal use by you of
                the Dr. Axico website or any and/or all of our Content, without limiting any other remedies made
                available by applicable law or these Terms and Conditions.</p>
        </div>
        <div class="privacy-item">
            <h2>10.Your Obligations and Responsibilities</h2>
            <p>ou must abide by these Terms and Conditions as well as any additional cautions or usage guidelines that
                may be posted on the Dr. Axico website in order to access or use the website. You must always act in
                good faith, in accordance with the law, and with custom. You are not permitted to modify the website in
                any manner, including by adding, deleting, or changing any of the services or Content that may be
                offered on the website. Without limiting the generality of any other provision of these Terms and
                Conditions, you are responsible for any losses and damages that Dr. Axico, our Affiliates, partners, or
                licensors may incur as a result of your negligence or willful failure to comply with any of your
                obligations under these Terms and Conditions.</p>
        </div>
        <div class="privacy-item">
            <h2>11.Third Party Links</h2>
            <p>Any external pages or other websites that are linked to or from the Dr. Axico website are not under our
                control, and we have no control over their content. Links on this website are solely there for your
                convenience and are not an endorsement of the content, product, service, or provider by us, our
                affiliates, or our partners. You do so at your own risk if you connect to or from any off-site pages or
                other websites. Off-site pages or any other websites linked to or from the site are not under our
                control, and we are not liable for their offerings. We also disclaim any responsibility or liability for
                the actions, content, goods, or services of such pages and websites, including without limitation, their
                privacy practices and terms and conditions. The terms and privacy policies of any off-website pages and
                other websites that you visit should be carefully read.</p>
        </div>
        <div class="privacy-item">
            <h2>12.Special Features, Functionality and Events</h2>
            <p>In addition to or instead of these Terms and Conditions, Dr. Axico may offer special features,
                functionality, or events (such as competitions, promotions, or other services) that may (a) be governed
                by additional terms of use, rules, and/or policies; (b) be provided by us or by third parties. If this
                is the case, we will let you know, and if you decide to use these offerings, you accept that your use of
                them will be governed by such extra or distinct terms of use, rules, and/or policies.</p>
        </div>
        <div class="privacy-item">
            <h2>13.Submissions</h2>
            <p>We have a policy of rejecting unsolicited ideas and suggestions. Any questions, comments, suggestions,
                ideas, or other information you give us (collectively, "Submissions") will be considered as
                non-proprietary and non-confidential, despite our policy on unsolicited ideas and suggestions. By
                sending or posting any Submission, you hereby grant us the right to copy, use, reproduce, modify, adapt,
                translate, publish, license, distribute, sell, or assign the Submission in any way we see fit, including
                but not limited to copying in whole or in part, creating derivative works from, distributing, and
                displaying any Submission in any form, media, or technology, whether now known or hereafter developed,
                alone or as part of other materials. This right is subject to the terms of our Privacy Policy.
                Additionally, you accept that your submission, together with any ideas, concepts, or know-how contained
                therein, won't be returned and that we may utilize it, without remuneration or any other type of
                recompense, for any objective, including but not limited to creating, producing, distributing, and
                selling goods.</p> <br>
            <p>If you submit something, you guarantee that you are the sole owner of the intellectual property rights
                therein. Additionally, you guarantee and indicate that the Submission does not involve or include any
                spam, chain letters, mass mailings, or other types of commercial solicitation. You are not allowed to
                submit something using a fake email account, pretend to be someone else or something else, or otherwise
                deceive us about where it came from. You consent to hold us harmless from any claims based on, relating
                to, or resulting from any claims to any rights in, or damages resulting from, any Submission.</p>
        </div>
        <div class="privacy-item">
            <h2>14.User Content</h2>
            <p>You are solely responsible for all User Content that you transmit, upload, post, e-mail, or otherwise
                make accessible on the website, including any data, text, software, music, sound, photos, graphics,
                pictures, videos, messages, or other materials. According to Section 10 above, such User Content
                qualifies as a Submission. Therefore, all third parties, and not us, are totally liable for all User
                Content that they submit to the website. You acknowledge that it is against the law to transmit, upload,
                post, email, or otherwise make available on the website any User Content that (a) is unlawful, harmful,
                threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, pornographic, libelous, invading
                another person's privacy, hateful, or objectionable due to race, ethnicity, or other factors. You also
                agree not to assist or encourage others to do so; (b) is false, inaccurate, or misleading in your
                opinion; (c) was provided to you for free or in exchange for something else; (d) you received payment or
                another benefit from a third party; or (e) violates any third party's patent, trademark, trade secret,
                copyright, or other proprietary or intellectual property rights. Additionally, you undertake not to
                distribute any computer viruses, unsolicited or illegal advertising, solicitation, or promotional
                materials, such as chain letters, bulk mail, or any other type of "spam," via transmission, upload,
                posting, email, or any other method. You further agree not to: (i) impersonate any person or entity;
                (ii) falsely state or otherwise misrepresent your affiliation with any person or entity; (iii) "stalk"
                or otherwise harass another person, including advocating harassment of another person; (iv) trap or harm
                any third party, including harming minors in any way; (v) forge headers or otherwise manipulate
                identifiers in order to disguise the origin of any User Content; and intentionally or
                unintentionally. </p> <br>
            <p>We do not guarantee the authenticity, integrity, or quality of User Content because we do not support or
                exercise control over it when it is transmitted or put on the Dr. Axico website. By using this website,
                you acknowledge that you may be exposed to User Content that you find offensive, obscene, or
                objectionable. The use of any User Content that is sent, uploaded, posted, emailed, or otherwise made
                accessible via the website is at your own risk. Under no circumstances will we be liable in any way for
                any User Content, including, without limitation, for any mistakes or omissions in such User Content.</p>
            <br>
            <p>You agree that we have the right to refuse to post or remove any User Content and that we have the right
                to edit, compress, or remove any User Content in our sole discretion. Without limiting the generality of
                the aforementioned or any other provision of these Terms and Conditions, we reserve the right to refuse
                service to any users who violate these Terms and Conditions or infringe on the rights of others, as well
                as the right to remove any User Content that does so.</p>
        </div>
        <div class="privacy-item">
            <h2>15.Modifications to The Service and Prices</h2>
            <p>Without prior notice, our product prices can vary.</p> <br>
            <p>We retain the right to change or stop providing the Service (or any feature or material within) at any
                time and without prior notice.</p>  <br>
            <p>Any time the Service is altered, its pricing is changed, it is suspended, or it is discontinued, we won't
                be responsible to you or any third parties.</p>
        </div>
        <div class="privacy-item">
            <h2>16.Personal Information</h2>
            <p>Our Privacy Policy applies to any personal information you submit through the store.</p>
        </div>
        <div class="privacy-item">
            <h2>17.Errors, Inaccuracies and Omissions</h2>
            <p>On rare occasions, material on our website or in the Service may have clerical or other errors,
                inaccuracies, or omissions that may pertain to product descriptions, costs, special offers, promotions,
                transit times, and availability. Without prior notice, we retain the right to change or update
                information, cancel orders, and/or correct any mistakes, inaccuracies, or omissions in any part of the
                Service or on any connected website (including after you have submitted your order).</p> <br>
            <p>Except as required by law, we make no commitment to update, modify, or clarify any information in the
                Service or on any associated website, including without limitation pricing information. It should not be
                assumed that all of the information in the Service or on any connected website has been updated or
                amended because there is no explicit update or refresh date applied.</p>
        </div>
        <div class="privacy-item">
            <h2>18.Disclaimer of Warranties; Limitation of Liability</h2>
            <p>Your use of our service will not be uninterrupted, timely, secure, or error-free, and we do not promise,
                represent, or warrant that it will be.</p> <br>
            <p>We do not guarantee the accuracy or dependability of any outcomes that may be attained through the use of
                the service.</p>  <br>
            <p>You acknowledge that we reserve the right to discontinue the service at any time and without prior
                warning, or to remove it altogether.</p>
            <p>You explicitly acknowledge that the service is used at your own risk, whether you can use it or not.
                Except where expressly stated by us, the service and all products and services delivered to you through
                the service are provided "as is" and "as available" for your use, free from any express or implied
                representation, warranty, or condition of any kind, including without limitation any implied warranty or
                condition of title, non-infringement, merchantability, or fitness for a particular purpose.</p> <br>
            <p>In no case shall Axico HealthCare Pvt Ltd, our directors, officers, employees, affiliates, agents,
                contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim,
                or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including,
                without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any
                similar damages, whether based in contract, tort (including negligence), strict liability or otherwise,
                arising from your use of any of the service or any products procured using the service, or for any other
                claim related in any way to your use of the service or any product, including, but not limited to, any
                errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of
                the service or any content (or product) posted, transmitted, or otherwise made available via the
                service, even if advised of their possibility. Because some states or jurisdictions do not allow the
                exclusion or the limitation of liability for consequential or incidental damages, in such states or
                jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
        </div>
        <div class="privacy-item">
            <h2>19.Copyright Complaints</h2>
            <p>We value other people's intellectual property. Please send an email or writing notice to us for notices
                of infringement if you think that copyrighted items have been copied in a way that violates copyright.
                Include the following information. I identifying the work(s) protected by intellectual property that you
                assert have been violated and demonstrating your ownership of or authority over those works; I Your
                address, phone number, and email address to: customers@draxico.com; (ii) A description of the material
                you believe is infringing and the placement of the material on the website; and (iii) Your address,
                phone number, and email address to customers@draxico.com</p> <br>
            <p><strong>Note:</strong> Dr. Axico can only be notified of possible copyright violations through the
                contact information provided above. All additional questions should be sent to our customer care team
                via email at customer@draxico.com instead of being answered through this process.</p>
        </div>
        <div class="privacy-item">
            <h2>20.Representations and Warranties and Limitation of Liability</h2>
            <p>The Dr. Axico website is made available "As Is." Except to the extent that such representations and
                warranties are not legally excludable, we make no representations or warranties of any kind, express or
                implied, in connection with these terms and conditions or the website, including but not limited to
                warranties of merchantability, non-infringement, or fitness for a particular purpose.</p> <br>
            <p>You agree that, to the fullest extent permitted by applicable law, we will not be responsible or liable
                (whether in contract, tort (including negligence) or otherwise), under any circumstances, for any (a)
                interruption of business; (b) access delays or access interruptions to the site; (c) data non-delivery,
                mis-delivery, corruption, destruction or other modification; (d) loss or damages of any sort incurred as
                a result of dealings with or the presence of off-website links on the website; (e) computer viruses,
                system failures or malfunctions which may occur in connection with your use of the site, including
                during hyperlink to or from third party websites (f) any inaccuracies or omissions in content or (g)
                events beyond our reasonable control.</p> <br>
            <p>The formulations used in the products by Dr. Axico are all-natural and Ayurvedic. Although every effort
                is made and safety precaution is taken to make the products completely safe for human consumption, it is
                still conceivable that some substances could trigger allergic reactions in some people or have a
                negative impact on people who already have medical conditions. To ensure that our goods are safe for you
                to use, please familiarize yourself with the ingredients and usage guidelines that come with each of our
                products. Before using any of our product to which you may be allergic, it will be your exclusive
                obligation to exercise adequate caution and seek professional medical or dermatological advice. You
                acknowledge and accept that Dr. Axico is not responsible or liable for any problems relating to our
                products, including without limitation any allergic reactions or adverse drug reaction you may
                experience as a result of using them. You shall, among other things, be subject to the disclaimer and
                responsibility restrictions detailed in further detail with the product packaging or leaflets for any
                purchases made through the Dr. Axico.</p> <br>
            <p>In addition, to the fullest extent allowed by law, we will not be liable for any indirect, special,
                punitive, incidental, consequential, or other damages of any kind (including lost profits) related to
                the website or your use of it, regardless of the form of action, including contract, tort (including
                negligence), or other, even if we have been informed of the possibility of such damages. In no case
                shall our maximum aggregate liability exceed Indian Rupees 2000 only.</p> <br>
            <p>You acknowledge that you may not bring any claims or actions arising out of or connected with your use of
                the website or these terms and conditions more than one (1) year after the relevant cause of action gave
                rise to such claims or actions.</p>

        </div>
        <div class="privacy-item">
            <h2>21.Indemnification</h2>
            <p>By using Dr. Axico or by violating these Terms and Conditions, you undertake to defend, indemnify, and
                keep us harmless from any loss, damages, or costs, including reasonable attorneys' fees, coming from a
                third party claim, action, or demand. The use of software robots, spiders, crawlers, or other similar
                data gathering and extraction tools, or any other action you take that places an unreasonable burden or
                load on our infrastructure, constitutes another agreement by which you agree to indemnify us from loss,
                damages, or costs, including reasonable attorneys' fees.</p>
        </div>
        <div class="privacy-item">
            <h2>22.Disputes</h2>
            <p>All rights, obligations, and actions contemplated by these terms and conditions with regard to any
                dispute relating to the website shall be governed by Indian law, as well as the courts located in New
                Delhi, India, as if these terms and conditions were a contract entirely entered into and entirely
                performed within New Delhi, India, subject to mandatory provisions of foreign law. Any disagreements or
                claims resulting from your visit to the Dr. Axico website shall, to the fullest extent authorized by
                existing law, be submitted to the sole Arbitrator chosen by the Chairman and Managing Director of Dr.
                Axico in line with the law. The arbitration will take place in New Delhi, India, and the arbitrator's
                decision will be final, binding on all parties, and admissible as evidence in any court with
                jurisdiction. No arbitration under these Terms and Conditions may be joined to an arbitration involving
                any other party subject to these Terms and Conditions, whether through class arbitration proceedings or
                otherwise, to the fullest extent authorized by applicable law.</p> <br>
            <p>Without limiting the law or any other applicable provisions of this Section, and subject to our right to
                seek injunctive or other appropriate relief in any court, if you have in any way violated or threatened
                to violate our intellectual property, any dispute relating in any way to your visit to the website shall
                be submitted to an appropriate court or other judicial body in New Delhi, India, as applicable.</p>
        </div>
        <div class="privacy-item">
            <h2>23.Consent to Receive Notices Electronically by Posting On the Website and Via Email</h2>
            <p>You agree to receive any agreements, notifications, disclosures, and other communications (collectively,
                "Notices") from us electronically, including without limitation by email or by posting notices on this
                website. This consent applies to all "Notices" that these Terms and Conditions refer to. You acknowledge
                and agree that all Notices provided to you electronically by us satisfies any legal requirement that
                such communications be made in writing and in the English language. You can change your mind about
                receiving notices electronically at any time by sending us an email at customer@draxico.com and ceasing
                to use this website. All of the rights that have been given to you in accordance with these Terms and
                Conditions, including but not limited to the restricted licenses outlined in Section 6 hereof, shall
                automatically expire in such a case. Unfortunately, we are unable to offer the advantages of this
                website to any user who is unable to accept Notices electronically.</p> <br>
            <p>Please be aware that your choice to receive marketing messages is completely independent from this
                agreement to get Notices. Our Privacy Policy outlines your options with regard to receiving marketing
                emails.</p>
        </div>
        <div class="privacy-item">
            <h2>24.Changes to Terms of Service</h2>
            <p>On this page, you can always consult the most recent version of the Terms of Service.</p> <br>
            <p>By publishing updates and changes to our website, we retain the right to amend, modify, or replace any
                portion of these Terms of Service at any time. It is your duty to often check our website for updates.
                Any modifications to these Terms of Service that are posted after you first use our website or use the
                Service indicate your acceptance of those modifications.</p> <br>
            <p>Please email us at the following address if you have any issues about our terms and conditions:
                customer@draxico.com </p>
        </div>
    </div>
</section>
