import {Component, OnInit} from '@angular/core';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../shared/services/storage.service';
import {ConsultService} from '../../../shared/services/consult.service';

@Component({
    selector: 'app-consult',
    templateUrl: './consult.component.html',
    styleUrls: ['./consult.component.scss']
})
export class ConsultComponent implements OnInit {
    users: any = [];
    user: any;

    constructor(private appointmentService: AppointmentService,
                private consultService: ConsultService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.getConsult();
    };

    getConsult() {
        this.consultService.detail(this.user.userId).subscribe(data => {
            if (data) {
                this.users = data;
            }
        });
    }
}
