<div class="page-title-area page-title-three"
     style="background-image: url('assets/img/doctor/meet-doctor.png') !important; background-repeat: no-repeat; background-size: 100% 100%;">
    <div class="d-table">
        <div class="d-table-cell">
            <div *ngIf="doctorName === 'dr-yogesh'" class="page-title-item-two">
                <h2>Dr. Yogesh</h2>
                <h3>Skin Specialist</h3>
            </div>
            <div *ngIf="doctorName === 'dr-teena'" class="page-title-item-two">
                <h2>Dr. Teena Somra</h2>
                <h3>Gaynecologist</h3>
            </div>
            <div *ngIf="doctorName === 'dr-vishwa-deep'" class="page-title-item-two">
                <h2>Dr. Vishwa Deep Biswas</h2>
                <h3>Diabetes Expert</h3>
            </div>
            <div *ngIf="doctorName === 'dr-sonu'" class="page-title-item-two">
                <h2>Dr. Sonu</h2>
                <h3>Child Specialist</h3>
            </div>
            <div *ngIf="doctorName === 'dr-akshata'" class="page-title-item-two">
                <h2>Dr. Akshata</h2>
                <h3>E.N.T Specialist</h3>
            </div>
            <div *ngIf="doctorName === 'dr-jagjeet'" class="page-title-item-two">
                <h2>Dr. Jagjeet</h2>
                <h3>Skin Specialist</h3>
            </div>
            <div *ngIf="doctorName === 'dr-vikas-meena'" class="page-title-item-two">
                <h2>Dr. Vikas Meena</h2>
                <h3>General Physcian</h3>
            </div>
            <div *ngIf="doctorName === 'dr-akansha'" class="page-title-item-two">
                <h2>Dr. Akansha</h2>
                <h3>General Physcian</h3>
            </div>
            <div *ngIf="doctorName === 'dr-anup'" class="page-title-item-two">
                <h2>Dr. Anup</h2>
                <h3>Surgeon</h3>
            </div>
            <div *ngIf="doctorName === 'dr-ashwani'" class="page-title-item-two">
                <h2>Dr. Ashwani</h2>
                <h3>General Physcian</h3>
            </div>
            <div *ngIf="doctorName === 'dr-divya-jain'" class="page-title-item-two">
                <h2>Dr. Divya Jain</h2>
                <h3>General Physcian</h3>
            </div>
            <div *ngIf="doctorName === 'dr-garima'" class="page-title-item-two">
                <h2>Dr. Garima</h2>
                <h3>Medicine</h3>
            </div>
            <div *ngIf="doctorName === 'dr-pradeep'" class="page-title-item-two">
                <h2>Dr. Pradeep</h2>
                <h3>General Physcian</h3>
            </div>
            <div *ngIf="doctorName === 'dr-uma-saini'" class="page-title-item-two">
                <h2>Dr. Uma Saini</h2>
                <h3>General Physcian</h3>
            </div>
            <div *ngIf="doctorName === 'dr-alok'" class="page-title-item-two">
                <h2>Dr. Alok</h2>
                <h3>Lifestyle Expert</h3>
            </div>
        </div>
    </div>
</div>

<div class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="doctor-details-item doctor-details-left">
                    <img *ngIf="doctorName === 'dr-teena'" alt="Doctor" src="assets/img/Doctorimage/dr-teena.webp">
                    <img *ngIf="doctorName === 'dr-vishwa-deep'" alt="Doctor"
                         src="assets/img/Doctorimage/dr-vishwa.webp">
                    <img *ngIf="doctorName === 'dr-yogesh'" alt="Doctor" src="assets/img/Doctorimage/dr-yogesh.webp">
                    <img *ngIf="doctorName === 'dr-sonu'" alt="Doctor" src="assets/img/Doctorimage/dr-sonu.webp">
                    <img *ngIf="doctorName === 'dr-akshata'" alt="Doctor" src="assets/img/Doctorimage/dr-akshata.webp">
                    <img *ngIf="doctorName === 'dr-jagjeet'" alt="Doctor" src="assets/img/Doctorimage/dr-jagjeet.webp">
                    <img *ngIf="doctorName === 'dr-vikas-meena'" alt="Doctor"
                         src="assets/img/Doctorimage/dr-vikas-meena.webp">
                    <img *ngIf="doctorName === 'dr-akansha'" alt="Doctor" src="assets/img/Doctorimage/dr-akansha.webp">
                    <img *ngIf="doctorName === 'dr-anup'" alt="Doctor" src="assets/img/Doctorimage/dr-anup.webp">
                    <img *ngIf="doctorName === 'dr-ashwani'" alt="Doctor" src="assets/img/Doctorimage/dr-ashwani.webp">
                    <img *ngIf="doctorName === 'dr-divya-jain'" alt="Doctor"
                         src="assets/img/Doctorimage/dr-divya-jain.webp">
                    <img *ngIf="doctorName === 'dr-garima'" alt="Doctor" src="assets/img/Doctorimage/dr-garima.webp">
                    <img *ngIf="doctorName === 'dr-pradeep'" alt="Doctor" src="assets/img/Doctorimage/dr-pradeep.webp">
                    <img *ngIf="doctorName === 'dr-uma-saini'" alt="Doctor"
                         src="assets/img/Doctorimage/dr-uma-saini.webp">
                    <img *ngIf="doctorName === 'dr-alok'" alt="Doctor" src="assets/img/Doctorimage/dr-alok2.webp">

                    <div class="doctor-details-work">
                        <h3>Working hours</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li>Monday</li>
                                        <li>Tuesday</li>
                                        <li>Wednesday</li>
                                        <li>Thrusday</li>
                                        <li>Friday</li>
                                        <li>Saturday</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                        <li>9:00 am - 8:00 pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h3>Biography</h3>
                            <p *ngIf="doctorName === 'dr-yogesh'" style="text-align: justify">
                                We are honoured to have Dr. Yogesh Drall, a highly talented and accomplished Ayurvedic
                                practitioner, as a member of our team.
                                <br>
                                Dr. Drall graduated from Chaudhary Brahm Prakash Ayurved Charak Sansthan with a Bachelor
                                of Ayurveda Medicine and Surgery (BAMS) and a Postgraduate degree in Rog Nidan. As a
                                result of his considerable study and education, Dr. Drall is a recognised authority in
                                the field of alternative medicine. He has a thorough understanding of Ayurvedic concepts
                                and practises.
                                <br>
                                Dr. Drall has experience in traditional Ayurveda knowledge study and documentation from
                                his time at the CSIR TKDL. This expertise has given him a distinctive viewpoint on the
                                use of Ayurveda in contemporary healthcare and has enabled him to create ground-breaking
                                methods for treating a variety of medical ailments.
                                <br>
                                Treatment of joint, skin, and intestinal issues is Dr. Drall's area of expertise. His
                                knowledge of Ayurveda medicine enables him to offer focused and efficient treatments for
                                these widespread health issues.
                                <br>
                                Dr. Drall uses a holistic strategy to treat skin conditions like acne, psoriasis, and
                                eczema by considering both internal and exterior causes that may be involved. To promote
                                good skin from the inside out, he uses a combination of herbal medicines, dietary
                                changes, and lifestyle adjustments.
                                Dr. Drall uses Ayurveda principles to treat the underlying imbalances in the digestive
                                tract in cases of digestive illnesses like irritable bowel syndrome (IBS), acid reflux,
                                and constipation. He can offer long-lasting comfort and restore ideal digestive function
                                by locating and treating the problem's underlying cause.
                                <br>
                                Dr. Drall's practise also focuses on joint problems such arthritis and joint discomfort.
                                He is able to lessen discomfort, increase mobility, and reduce inflammation caused by
                                these illnesses by combining herbal medicines, massage, and specific exercises.
                                At Dr. Axico Healthcare, we consider Ayurveda to be a way of life rather than just a
                                science. This concept guides all we do, including the way we provide patient care as
                                well as the products and services we offer. We are certain that we can offer you the
                                best Ayurveda healthcare options available with Dr. Drall on our team.
                            </p>
                            <p *ngIf="doctorName === 'dr-teena'" style="text-align: justify">
                                We are honoured to have Dr.Teena Somra, a highly talented and accomplished Ayurvedic
                                practitioner, as a member of our team.
                                <br>
                                Meet Dr. Teena Somra, a medical professional trained in Ayurveda who received her degree
                                from the Ch. Braham Prakash Ayurveda Charaka Sansthan and has experience treating a wide
                                range of medical conditions.
                                Dr. Teena is passionate about using a holistic and patient-centered approach to assist
                                patients attain their highest levels of health and wellness. They specialise in
                                developing individualised treatment plans that take into account each patient's unique
                                needs and combine Ayurvedic therapies, lifestyle changes, and cutting-edge medical
                                procedures.
                                <br>
                                Dr. Teena has gained a reputation for being an expert in treating a wide range of
                                illnesses through their experience in practise, including but not limited to
                                gynaecological disorders, digestive disorders, respiratory conditions, and skin
                                problems, as well as chronic illnesses like diabetes, arthritis, and heart disease.
                                <br>
                                Dr. Teena is actively interested in Ayurvedic medicine research and education in
                                addition to their clinical practise. They frequently impart their knowledge and skills
                                to other Ayurvedic practitioners and students.
                                Patients who see Dr. Teena can be sure that they will receive kind and efficient care
                                that gets to the bottom of their health issues and fosters long-term health and
                                wellness.
                            </p>
                            <p *ngIf="doctorName === 'dr-vishwa-deep'" style="text-align: justify">
                                We are honoured to have Dr. Vishwa Deep Biswas, a highly talented and accomplished
                                Ayurvedic
                                practitioner, as a member of our team.
                                <br>
                                Dr. Vishwa Deep Biswas is a seasoned Ayurvedic physician who has devoted his career to
                                advocating natural health and healing. He received a Bachelor of Ayurvedic Medicine and
                                Surgery (BAMS) degree from Delhi Government College, Ch Brahm Prakash Ayurved Charak
                                Sansthan, and a one-year certificate in Kayachikitsa (ayurvedic internal medicine) from
                                Rashtriya Ayurveda Vidyapeeth under the Ministry of Ayush in Nagpur. Dr. Vishwadeep
                                Biswas is also certified in Panchkarma, a traditional Ayurvedic treatment involving body
                                detoxification and revitalization.
                                <br>
                                With over 6 years of experience, Dr. Vishwa deep has worked in various Ayurvedic and
                                Panchkarma hospitals, where he has helped numerous patients achieve optimal health and
                                wellness. He has a comprehensive understanding of Ayurvedic principles and therapeutic
                                methods and is committed to providing individualised care for each of his patients.
                                He has extensive experience treating patients with gastro-intestinal and joint
                                disorders, which can cause considerable pain and suffering. He has developed a profound
                                understanding of the underlying causes and effective remedies for these conditions due
                                to his extensive experience in their treatment.
                                <br>
                                In practice, he employs a customised method to determine the underlying cause of each
                                patient's condition. He takes the time to attend to and comprehend his patients'
                                individual requirements and concerns. On the basis of this information, he formulates a
                                treatment plan that includes Ayurvedic therapies, such as medicinal remedies, massage,
                                and Panchkarma treatments.
                                Dr. Vishwa Deep Biswas is a compassionate physician who employs a holistic approach to
                                patient care. He is committed to educating his patients on the advantages of Ayurveda
                                because he believes in empowering his patients to take an active role in their own
                                health and wellness.
                            </p>
                            <p *ngIf="doctorName === 'dr-sonu'" style="text-align: justify">
                                Ayurvedic (pediatrician) child medicine specialist Dr. Sonu Parkash is a committed and
                                experienced doctor. Dr. Parkash has substantial accomplishments in a range of healthcare
                                settings and has a solid academic foundation and a wide range of experiences.
                                <br>
                                Dr. Parkash was an integral part of the Central Council of Research in Ayurvedic
                                Sciences (CCRAS) project in the Department of Plastic and Reconstructive Surgery, under
                                the direction of Dr. Maneesh Singhal, while serving as a Senior Research Fellow at the
                                prestigious All India Institute of Medical Sciences (AIIMS), New Delhi. Dr. Parkash
                                conducted cutting-edge research over a 15-month period, furthering the study of
                                ayurvedic sciences.
                                <br>
                                Dr. Parkash gave young children in the Department of Pediatrics sensitive treatment
                                throughout a noteworthy term as a medical officer at KEM Hospital Pune. This five-month
                                experience deepened Dr. Parkash's grasp of pediatric healthcare and highlighted his
                                dedication to the total well-being of his patients.
                                <br>
                                A brief but significant traineeship at KLE CCH& MRC Yellur Road, Belagavi, Karnataka,
                                demonstrates Dr. Parkash's commitment to learning and development. Dr. Parkash improved
                                his fundamental abilities here under the direction of a pro.
                                <br>
                                Dr. Parkash worked as a medical officer at the COVID Emergency Unit at KLE's Dr.
                                Prabhakar Kore Hospital in Belagavi, Karnataka, during the ongoing COVID-19 epidemic.
                                This pivotal position demonstrated Dr. Parkash's fortitude and unflinching dedication to
                                public health amid trying circumstances.
                                <br>
                                In academic and consultancy roles, Dr. Parkash made noteworthy contributions as an
                                Assistant In the discipline of Kaumarbhritya (Ayurvedic Pediatrics), professor or
                                consultant. Dr. Parkash supervised prospective professionals for a total of 12 months at
                                both Shree Ram Ayurvedic Medical College and Hospital, Meerut, and IAMR Ayurvedic
                                Medical College and Hospital, Meerut.
                                <br>
                                The fact that Dr. Sonu Parkash has both a Master's degree (MD) and a Bachelor's degree
                                (BAMS) in Ayurvedic medicine demonstrates his dedication to academic achievement and
                                specialization in the subject.
                                <br>
                                Dr. Parkash has had a wonderful career committed to healing, research, and education.
                                This career has been shaped by his broad and varied experiences as well as a passion for
                                Ayurvedic medicine.
                            </p>
                            <p *ngIf="doctorName === 'dr-akshata'" style="text-align: justify">
                                With Dr. Akshata, a renowned Ayurvedic practitioner, explore the world of holistic
                                healing and specialized knowledge. Dr. Akshata is a leader in her industry due to her
                                extensive education, which includes a Master's degree in Shalkya Tantra as well as a
                                Bachelor's degree in Ayurvedic Medicine and Surgery (BAMS).
                                <br>
                                With more than 7 years of practical expertise, Dr. Akshata has proven to be remarkably
                                skilled in treating a range of challenging and varied medical issues. Her special
                                combination of abilities includes a thorough knowledge of many diseases, from myopia
                                subtleties to vision-related problems like corneal ulcers, retinitis pigmentosa, macular
                                edema, and other retinal ailments.
                                <br>
                                Her expertise also includes disorders including Meniere's illness, vertigo, tinnitus,
                                and mild to moderate hearing loss.
                                <br>
                                Dr. Akshata's approach to dermatological issues demonstrates her healing touch. Through
                                the use of Ayurvedic treatments, she has demonstrated outstanding skill in the treatment
                                of alopecia areata, uneven hair loss, hair fall, premature graying of hair, and chronic
                                dandruff.
                                <br>
                                Dr. Akshata has demonstrated her skill in treating diseases like allergic rhinitis,
                                tonsillitis, and epistaxis, demonstrating her holistic approach to health.
                                <br>
                                Her journey is marked by a never-ending quest for excellence, encouraging the harmonic
                                fusion of age-old knowledge and contemporary innovations. Dr. Akshata's dedication to
                                Ayurveda is demonstrated by her outstanding achievements, which have helped her patients
                                live healthier and more balanced lives.
                                <br>
                                Dr. Akshata continues to make waves in the industry with her unshakable commitment to
                                the Ayurvedic principles and a history of outstanding accomplishments, illustrating the
                                very best in holistic health and wellness.
                            </p>
                            <p *ngIf="doctorName === 'dr-jagjeet'" style="text-align: justify">
                                Dr. Jagjeet Singh is a distinguished and accomplished Ayurvedic practitioner. With a
                                Bachelor of Ayurvedic Medicine and Surgery (BAMS), a Master of Ayurveda (MD), and a
                                Doctorate in Ayurveda (PhD), Dr. Singh is a beacon of knowledge and devoted practice.
                                <br>
                                Dr. Singh's career has been characterized by an unwavering dedication to the principles
                                and practices of Ayurveda. With years of experience and a plethora of knowledge, he has
                                developed a comprehensive understanding of Ayurvedic medicine. His extensive knowledge
                                and intuitive comprehension of Ayurvedic principles enable him to provide comprehensive
                                and individualized treatments for a wide variety of health conditions.
                                <br>
                                Dr. Jagjeet Singh is genuinely distinguished by his personal devotion to Ayurveda. Not
                                only does he impart his wisdom to patients, but he also adheres to the principles he
                                promotes. The profound conviction in Ayurveda held by Dr. Singh is reflected in his own
                                lifestyle choices, which reflect a harmonious alignment with nature and holistic
                                well-being.
                                <br>
                                Dr. Singh's holistic approach transcends the boundaries of conventional medicine.
                                Extensive research and clinical experience have prompted him to devise innovative and
                                effective treatment protocols that integrate traditional Ayurvedic knowledge with modern
                                insights. In addition to his medical expertise, his patients benefit from his
                                compassionate care and genuine concern for their overall health and vitality.
                                <br>
                                In a world where wellness is frequently fragmented, Dr. Jagjeet Singh serves as a
                                beacon, exemplifying Ayurveda in its purest form. His journey exemplifies the profound
                                effect Ayurveda can have on one's health and life when it is practiced with integrity
                                and devotion. Through his expertise and unwavering dedication, Dr. Singh continues to
                                empower individuals to adopt the Ayurvedic path to holistic well-being and transform
                                lives.
                            </p>
                            <p *ngIf="doctorName === 'dr-vikas-meena'" style="text-align: justify">
                                Dr. Vikas Meena is a dynamic and enthusiastic practitioner of Ayurveda who embodies the
                                essence of this ancient science. Dr. Meena's journey has been influenced by his
                                education and experience. He received his Bachelor of Ayurvedic Medicine and Surgery
                                (BAMS) from the esteemed Chaudhary Brahm Prakash Ayurvedic Charak Sansthan.
                                <br>
                                Dr. Meena is a shining example of the transformative force of this ancient wisdom due to
                                her unwavering commitment to Ayurvedic principles. His journey has been one of
                                continuous learning and investigation, and his knowledge of Ayurvedic treatment
                                encompasses numerous facets.
                                <br>
                                Dr. Meena's commitment transcends the boundaries of his professional existence. Not only
                                does he practice Ayurveda, but he also lives by its principles. His lifestyle choices
                                reflect a profound harmony with nature and the principles of equilibrium, making him a
                                living embodiment of the holistic philosophy he promotes.
                                <br>
                                Dr. Meena provides a new and innovative perspective to Ayurvedic healing, having honed
                                his skills and knowledge at a prestigious institute. His youthful vigor and enthusiasm
                                infuse traditional practices with new life, resulting in effective and individualized
                                treatments that are tailored to the specific requirements of each individual.
                                <br>
                                His unwavering devotion to Ayurveda as a way of life distinguishes him as a beacon of
                                health and vitality. His holistic approach, which is rooted in both tradition and
                                contemporary understanding, resonates with patients desiring an all-encompassing path to
                                health.
                                <br>
                                Dr. Meena's commitment to Ayurveda serves as a guiding beacon, offering a holistic and
                                sustainable solution in a world where the pressures of modern life frequently compromise
                                health. His journey is an inspiration for those who aspire to embrace the knowledge of
                                Ayurveda and embark on a transformative path to a healthier, more balanced existence.
                            </p>
                            <div *ngIf="doctorName === 'dr-akansha'" style="text-align: justify">
                                <p>Dr. Akansha is a skilled Ayurvedic practitioner with a Bachelor of Ayurvedic Medicine
                                    and
                                    Surgery (BAMS) degree. Her dedication to Ayurveda as a holistic therapeutic system
                                    has
                                    made her a well-respected healthcare expert renowned for her knowledge of
                                    traditional
                                    Indian medicine.</p>
                                <p>Dr. Akansha began her career in healthcare by pursuing a BAMS degree, which provided
                                    her
                                    with a comprehensive comprehension of Ayurvedic principles, herbal medicine, and
                                    therapeutic techniques. Her educational heritage demonstrates her commitment to
                                    preserving and promoting the ancient Ayurvedic wisdom.</p>
                                <p>&nbsp;</p><h4><strong>Domains of Competence:</strong></h4>
                                <p>Dr. Akansha has a comprehensive understanding of Ayurvedic principles and treatments.
                                    She
                                    employs this knowledge to diagnose and treat a wide variety of health conditions,
                                    emphasizing the importance of natural remedies, dietary modifications, and lifestyle
                                    changes.</p>
                                <p>Holistic Healing: Dr. Akansha's healthcare philosophy is grounded in holistic
                                    healing.
                                    She believes in treating the underlying causes of illness and addressing the
                                    physical,
                                    mental, and emotional well-being of her patients.</p>
                                <p>Herbal Remedies: Utilizing Ayurveda's extensive pharmacopoeia, Dr. Akansha prescribes
                                    individualized herbal formulations to treat specific health issues. She emphasizes
                                    the
                                    use of natural remedies to promote healing and equilibrium in her patients.</p>
                                <p>Lifestyle Counseling: Dr. Akansha provides individualized lifestyle counseling,
                                    assisting
                                    patients in making healthy alterations to their daily routines. This includes
                                    information on nutrition, physical activity, and stress management.</p>
                                <p>Dr. Akansha is an advocate for preventative healthcare. She educates her patients on
                                    the
                                    significance of early detection and proactive health maintenance and disease
                                    prevention
                                    measures.</p>
                                <p>Philosophy of Care Dr. Akansha's philosophy of care emphasizes patient well-being and
                                    individualized care. She adheres to a patient-centered approach in which open
                                    communication, active patient participation, and a compassionate bedside manner are
                                    of
                                    the uttermost importance. Her dedication to preserving and advancing the principles
                                    of
                                    Ayurveda in contemporary medicine makes her a valuable resource for those in search
                                    of
                                    natural and balanced healthcare solutions.</p>
                                <p>Dr. Akansha is a skilled and dedicated Ayurveda health care practitioner. Her
                                    expertise
                                    in Ayurvedic medicine and passion for holistic health continue to have a positive
                                    effect
                                    on her patients' lives. Dr. Akansha is a trusted healthcare professional in the
                                    field of
                                    traditional Indian medicine due to her unwavering conviction to Ayurvedic principles
                                    and
                                    her dedication to providing holistic healthcare solutions.</p>

                            </div>
                            <div *ngIf="doctorName === 'dr-anup'" style="text-align: justify">
                                <p>Dr. Anup Sahu, a recognized healthcare professional, mixes Ayurvedic wisdom with
                                    modern surgical expertise. His Bachelor of Ayurvedic Medicine and Surgery (BAMS) and
                                    Master of Surgery (MS) degrees demonstrate his dedication to providing holistic
                                    healthcare that combines traditional and modern methods.</p>
                                <p>Dr. Anup Sahu's healthcare career began with a BAMS degree, which gave him a strong
                                    foundation in Ayurvedic concepts, herbal medicine, and holistic healing. He earned a
                                    Master's in Surgery after receiving his BAMS. This dual education gave him unique
                                    skills to bridge Ayurveda and modern surgery.</p><h4><strong>Specialties:</strong>
                            </h4>
                                <p>Dr. Anup Sahu knows Ayurvedic ideas and treatments well. He uses his skills to
                                    provide holistic health treatments for diverse diseases. He uses Ayurvedic
                                    treatments and lifestyle advice to balance mind, body, and spirit.</p>
                                <p>Dr. Anup Sahu has a Master's in Surgery and is well-versed in latest surgical
                                    procedures. He performs elective and emergency procedures and offers a wide range of
                                    services.</p>
                                <p>Integrative Medicine: Dr. Sahu promotes integrative medicine, which applies Ayurvedic
                                    and modern medicine best practices. He can provide patients with a comprehensive
                                    treatment plan that meets their needs using this method.</p>
                                <p>Chronic Disease Management: Dr. Anup Sahu manages diabetes, hypertension, and
                                    obesity. He uses a holistic approach that incorporates diet, lifestyle, and medical
                                    interventions.</p>
                                <p>Dr. Sahu provides thorough post-surgical care to help patients recuperate.</p>
                                <p>The philosophy of Dr. Anup Sahu is to provide patient-centered care that prioritizes
                                    individual well-being. His treatment programs are customized to each patient's needs
                                    and preferences. Dr. Sahu promotes open communication to keep patients informed and
                                    involved in their treatment decisions.</p>
                                <p>In conclusion, Dr. Anup Sahu, BAMS, MS (Surgery), combines Ayurveda and modern
                                    surgical expertise. His holistic approach and surgical expertise make him a great
                                    resource for people seeking complete healthcare. Dr. Sahu's commitment to
                                    integrating ancient Ayurveda and modern treatment makes him a trustworthy healthcare
                                    provider.</p>


                            </div>
                            <div *ngIf="doctorName === 'dr-ashwani'" style="text-align: justify">
                                <p>Dr. Ashwani Kumar is a hardworking and skilled general practitioner who holds a
                                    Bachelor of Ayurvedic Medicine and Surgery (BAMS) degree. He has made a name for
                                    himself in the medical community as a reliable healthcare practitioner who is
                                    well-known for his dedication to delivering all-encompassing medical services.</p>
                                <p>His education began with the pursuit of a Bachelor of Ayurvedic and Unani Medicine
                                    (BAMS) degree, which offered him a firm grounding in Ayurvedic concepts, herbal
                                    medicine, and holistic treatment methods. This was the beginning of Dr. Ashwani
                                    Kumar's career in the medical field. His academic career is illustrative of his
                                    commitment to Ayurveda, which is an ancient form of medical practice.</p><h4>
                                <br><strong>Expertise in the Following Areas:</strong></h4>
                                <p>Dr. Ashwani Kumar practices general medicine and provides patients of all ages with
                                    primary medical care. He sees patients of all ages. He is an expert in the diagnosis
                                    and treatment of a wide variety of medical disorders, whether they are acute or
                                    chronic.</p>
                                <p><strong>Holistic Healing:</strong> Dr. Kumar bases his method of providing medical
                                    care on the concept of holistic healing. He adheres to the philosophy of treating
                                    the patient as a whole, meaning that he takes into account the patient's mental,
                                    emotional, and physiological states to achieve optimal health.</p>
                                <p><strong>Ayurvedic Medicine: </strong>Dr. Ashwani Kumar, who holds a BAMS degree, is
                                    extremely well-versed in the fundamentals of Ayurvedic medicine as well as its
                                    various therapeutic modalities. In his treatment programs, he frequently includes
                                    Ayurvedic treatments, nutritional suggestions, and lifestyle adjustments.</p>
                                <p><strong>Herbal Remedies:</strong> Drawing on the long and illustrious history of
                                    herbal treatment in Ayurveda, Dr. Kumar recommends herbal concoctions that are
                                    specifically formulated to meet patients' individual health concerns. The use of
                                    these natural medicines is intended to facilitate healing and restore balance.</p>
                                <p><strong>Healthcare Preventative Measures:</strong> Dr. Kumar is an advocate for
                                    healthcare preventative measures. He emphasizes to his patients the significance of
                                    maintaining optimal health through regular checkups, vaccinations, and the choices
                                    they make in their lifestyle in order to ward off sickness.</p>
                                <p>Philosophy of Care Dr. Ashwani Kumar's philosophy of care focuses on the overall
                                    health and happiness of his patients as well as providing them with individualized
                                    attention. He is a firm believer in the importance of establishing a trustworthy
                                    doctor-patient relationship that is characterized by open and honest communication
                                    and collaborative decision-making. Because of his dedication to holistic healing and
                                    his profound regard for Ayurveda, he is an invaluable resource for those who are
                                    looking for natural and holistic approaches to their medical needs.</p>
                                <p>Dr. Ashwani Kumar, BAMS is a devoted and competent general physician who integrates
                                    the teachings of Ayurveda with contemporary methods of providing medical care. The
                                    combination of his dedication to holistic therapy and his extensive knowledge of
                                    general medicine continues to have a positive effect on the lives of his patients.
                                    Because of his unshakable devotion to Ayurveda and his determination to provide
                                    patients with holistic healthcare solutions, Dr. Kumar is regarded as a reliable
                                    healthcare specialist in the field of general medicine.</p>


                            </div>
                            <div *ngIf="doctorName === 'dr-divya-jain'" style="text-align: justify">
                                <h4><strong>The introductory phrase:</strong></h4>
                                <p>Dr. Divya Jain is a devoted Ayurvedic physician who holds a Bachelor of Ayurvedic
                                    Medicine and Surgery (BAMS) degree. Her passion for Ayurveda and holistic healing
                                    has led her to become a trusted healthcare professional known for her expertise in
                                    traditional Indian medicine.</p>
                                <p>Dr. Divya Jain began her career in healthcare by pursuing a BAMS degree, which
                                    provided her with a solid foundation in Ayurvedic principles, botanical medicine,
                                    and therapeutic techniques. Her educational heritage demonstrates her dedication to
                                    promoting the ancient knowledge of Ayurveda in modern healthcare.</p><h4>
                                <br><strong>Domains of Competence:</strong></h4>
                                <p>Dr. Divya Jain has a comprehensive understanding of Ayurvedic principles and
                                    treatments. She employs this knowledge to diagnose and treat a variety of health
                                    conditions with natural remedies, dietary recommendations, and modifications to her
                                    lifestyle.</p>
                                <p><strong>Holistic Healing</strong>: Dr. Jain's healthcare philosophy is grounded in
                                    holistic healing. She believes in treating the underlying causes of illness and
                                    addressing the physical, mental, and emotional well-being of her patients.</p>
                                <p><strong>Herbal Remedies:</strong> Utilizing Ayurveda's extensive pharmacopoeia, Dr.
                                    Divya Jain prescribes customized herbal formulations to address specific health
                                    issues. She emphasizes the use of natural remedies to aid in the recovery and
                                    restoration of her patients' health.</p>
                                <p><strong>Lifestyle Counseling: </strong>Dr. Jain provides individualized lifestyle
                                    counseling, assisting patients in making healthy alterations to their daily
                                    routines. This includes information on nutrition, physical activity, and stress
                                    management.</p>
                                <p>Dr. Jain is an advocate for preventative healthcare. She educates her patients on the
                                    significance of early detection and proactive health maintenance and disease
                                    prevention measures.</p>
                                <p>Philosophy of Care Dr. Divya Jain's philosophy of care focuses on the patient's
                                    well-being and individualized care. She believes in a patient-centered approach that
                                    prioritizes open communication, active patient participation, and a compassionate
                                    bedside demeanor. Her commitment to holistic healing and her profound respect for
                                    Ayurvedic traditions make her a valuable resource for those seeking natural and
                                    balanced healthcare solutions.</p>
                                <p>Dr. Divya Jain, BAMS, is a committed and knowledgeable Ayurvedic practitioner, as
                                    evidenced by her BAMS degree. Her commitment to the principles of Ayurveda, combined
                                    with her knowledge of natural healing and holistic health, continues to have a
                                    positive effect on the lives of her patients. Dr. Jain's unwavering dedication to
                                    advancing Ayurveda in contemporary healthcare demonstrates her devotion to
                                    traditional Indian medicine.</p>


                            </div>
                            <div *ngIf="doctorName === 'dr-garima'" style="text-align: justify">
                                <p>Meet Dr. Garima Kashyap, a famous Ayurvedic medicine expert in the healthcare
                                    industry. She is a BAMS and MD graduate who has dedicated her career to advancing
                                    Ayurveda and holistic healthcare.</p>
                                <p>Dr. Garima Kashyap's healthcare career began with a BAMS degree, which gave her a
                                    strong foundation in Ayurvedic concepts, herbal medicine, and therapeutic
                                    procedures. Ayurvedic medicine was her specialty when she received her MD. This
                                    rigorous training gave her the skills to treat complex health concerns using
                                    Ayurveda.</p><h4><br><strong>Specialties:</strong></h4>
                                <p>Dr. Garima Kashyap promotes Ayurveda as a holistic therapeutic system. Her extensive
                                    Ayurvedic expertise helps her to diagnose and treat a wide range of health ailments
                                    with natural treatments, food, and lifestyle changes.</p>
                                <p><strong>Internal Medicine: </strong>Dr. Kashyap has an MD in internal medicine and
                                    can diagnose and treat cardiovascular disease, diabetes, respiratory illnesses, and
                                    more.</p>
                                <p><strong>Holistic Health Promotion: </strong>Dr. Kashyap loves preventive care and
                                    helping her patients live healthier. Her message is that balanced living, diet, and
                                    stress management are essential to well-being.</p>
                                <p>Dr. Kashyap provides herbal remedies based on Ayurvedic pharmacopeia to treat
                                    specific health issues. She recommends natural remedies for healing and harmony.</p>
                                <p><strong>Lifestyle Advice:</strong> Dr. Garima Kashyap tailors lifestyle advice to
                                    each patient's Prakriti and imbalances. Dietary changes, yoga, and meditation are
                                    common recommendations.</p>
                                <p>Dr. Kashyap's philosophy of care emphasizes patient-centered, holistic healing. She
                                    prefers treating diseases' causes rather than symptoms. Her approach emphasizes open
                                    communication, patient involvement, and comprehensive, customized healthcare.</p>
                                <p><strong>Conclusion: </strong>Dr. Garima Kashyap, BAMS, MD (Medicine), is a
                                    significant figure in Ayurvedic and internal medicine. Her dedication to merging
                                    traditional Ayurveda with contemporary medicine has made her a trusted healthcare
                                    expert who improves patient wellness. Dr. Kashyap's holistic approach, profound
                                    understanding, and dedication to promote Ayurveda as a valued healthcare system
                                    continue to improve the lives of those seeking natural, balanced, and effective
                                    treatment.</p>


                            </div>
                            <div *ngIf="doctorName === 'dr-pradeep'" style="text-align: justify">
                                <p>Dr. Pradeep is an expert general physician specializing in Ayurveda. After five years
                                    in the sector, he is a reputable healthcare professional known for mixing Ayurvedic
                                    principles with modern medicine.</p>
                                <p>Dr. Pradeep began his healthcare career with a Bachelor's degree in Ayurvedic
                                    Medicine and Surgery (BAMS). His study emphasized Ayurveda, herbal medicine, and
                                    holistic health. After graduating, Dr. Pradeep completed internships and residencies
                                    to improve his diagnosis and treatment skills.</p>
                                <p>With over five years of professional experience, Dr. Pradeep has a strong awareness
                                    of various health disorders and illnesses. He specializes on acute and chronic
                                    illnesses and treats patients compassionately.</p>
                                <p>&nbsp;</p><h4><strong>Key Practice Areas:</strong></h4>
                                <p>Dr. Pradeep provides primary care to people of all ages. He does complete medical
                                    exams, preventive care, and treatments for common health issues.</p>
                                <p>The unique approach of Dr. Pradeep is to integrate Ayurvedic ideas into his regular
                                    medical practice. He uses Ayurvedic treatments and lifestyle advice in his treatment
                                    regimens because he believes in its holistic healing power.</p>
                                <p><strong>Wellness Promotion:</strong> Dr. Pradeep promotes preventive healthcare as
                                    well as treatment. His patients learn the value of a balanced lifestyle, food, and
                                    stress management to be healthy.</p>
                                <p><strong>Herbal Medicine:</strong> Dr. Pradeep prescribes herbal supplements and
                                    formulations to enhance conventional treatments using Ayurvedic knowledge. He
                                    tailors these therapies to his patients' requirements and constitution.</p>
                                <p>Dr. Pradeep is known for patient-centered care. He listens to his patients,
                                    understands their issues, and involves them in healthcare decisions.</p>
                                <p>In summary, Dr. Pradeep, BAMS, is an experienced general physician who combines
                                    Ayurvedic and modern medical expertise in his practice. He has over five years of
                                    experience offering holistic healthcare solutions that prioritize prevention and
                                    treatment, earning his patients' trust. Dr. Pradeep's focus to overall well-being
                                    and patient health makes him a great asset in healthcare, integrating ancient
                                    Ayurveda and modern medicine.</p>


                            </div>
                            <div *ngIf="doctorName === 'dr-uma-saini'" style="text-align: justify">
                                <p>Introduction: Dr. Uma Saini, a renowned general physician, with a BAMS degree in
                                    Ayurvedic Medicine and Surgery. She is trusted and known for her empathetic approach
                                    to patient care due to her devotion to holistic treatment.</p>
                                <p>In her pursuit of a BAMS degree, Dr. Uma Saini got a broad understanding of Ayurvedic
                                    concepts, herbal medicine, and holistic treatment practices. Her education prepared
                                    her to become a general physician with a distinct viewpoint on healthcare.</p>
                                <p>&nbsp;</p><h4><strong>Specialties:</strong></h4>
                                <p>Dr. Uma Saini provides primary care to people of all ages. She can diagnose and treat
                                    common and chronic ailments.</p>
                                <p><strong>Holistic Healing:</strong> Dr. Saini practices holistic medicine. Her
                                    holistic approach addresses physical, mental, emotional, and spiritual well-being.
                                </p>
                                <p>Dr. Saini promotes preventive care. She advises her patients on lifestyle, nutrition,
                                    and checkups to be healthy and avoid sickness.</p>
                                <p>Dr. Uma Saini uses herbal treatments and natural therapies in her treatment
                                    strategies due to her Ayurvedic background. These medicines are customized for her
                                    patients' demands and health.</p>
                                <p>Dr. Saini offers tailored lifestyle counseling to help individuals improve their
                                    health by making healthy lifestyle adjustments. This may include diet, exercise, and
                                    stress management advice.</p>
                                <p><strong>Philosophy of Care:</strong> Dr. Uma Saini prioritizes patient-centered
                                    treatment. She listens to her patients, understands their issues, and involves them
                                    in healthcare decisions. Her approach emphasizes empathy, open communication, and
                                    holistic solutions.</p>
                                <p>In conclusion, Dr. Uma Saini, BAMS, is a devoted general physician who combines
                                    Ayurvedic wisdom with modern healthcare procedures for her patients. Her holistic
                                    approach and compassionate, patient-centered care make her a remarkable healthcare
                                    asset. Dr. Saini's passion to holistic health and patient well-being continues to
                                    improve her patients' lives.</p>


                            </div>
                            <div *ngIf="doctorName === 'dr-alok'" style="text-align: justify">
                                <p>Dr. Alok is a well regarded Ayurvedic practitioner who possesses a significant amount
                                    of clinical expertise in addition to a comprehensive scholastic history. Because of
                                    his persistent dedication to Ayurveda as a comprehensive therapeutic method, many
                                    people look to him as an authority, particularly with regard to the management of
                                    lifestyle illnesses. Dr. Alok, who holds degrees including a Bachelor of Ayurvedic
                                    Medicine and Surgery (BAMS) and a Master of Ayurvedic Medicine (MD), has devoted his
                                    professional life to advancing the concepts of Ayurveda and putting those principles
                                    into practice in order to improve the health of his patients.</p>
                                <p>Education and Training: Prior to beginning his career in the field of Ayurveda, Dr.
                                    Alok underwent an intensive training program at a renowned Ayurvedic institution. He
                                    graduated with a Bachelor of Ayurvedic and Naturopathic Medicine (BAMS) degree,
                                    which provided him with a strong foundation in Ayurvedic concepts, herbal medicine,
                                    and therapeutic procedures. Because of his hunger for information and his desire to
                                    have a more profound understanding of the complexities of Ayurveda, he decided to
                                    earn a Master of Science (MD) degree in Ayurvedic Medicine. His diagnostic abilities
                                    were much improved by this more advanced training, and he gained a more in-depth
                                    comprehension of the Ayurvedic approach to health and treatment as a result.</p>
                                <p>Expertise in Lifestyle problems Dr. Alok's area of specialty lies in the application
                                    of Ayurvedic principles to the treatment of lifestyle problems. He is certain that
                                    many of today's health problems may be traced back to imbalances brought on by
                                    unhealthy lifestyles, poor food decisions, and the effects of stress. Dr. Alok
                                    provides comprehensive solutions to these modern health concerns by integrating the
                                    traditional Ayurvedic wisdom with the cutting-edge scientific understanding he has
                                    gained over the years.</p>
                                <p>&nbsp;</p><h4><strong>Services Provided and Method:</strong></h4>
                                <p>Personalized Wellness Plans: When it comes to patient care, Dr. Alok takes a very
                                    individualized approach. He performs in-depth evaluations in order to gain an
                                    understanding of each individual patient's particular constitution (Prakriti) and
                                    imbalances (Vikriti). On the basis of this study, he develops individualized
                                    wellness programs for his clients. These plans may include suggestions for nutrition
                                    and herbal medicines, as well as changes in lifestyle and methods for stress
                                    management.</p>
                                <p>Yoga and Meditation Due to the tremendous effect that yoga and meditation have on
                                    one's well-being as a whole, Dr. Alok frequently includes the practice of yoga and
                                    meditation as part of his treatment programs. According to him, they are potent
                                    tools that can help bring harmony to the mind, body, and soul.</p>
                                <p>Dr. Alok places a strong emphasis on the significance of dietary decisions when it
                                    comes to the upkeep of one's health. He counsels his patients on the appropriate
                                    food options for them based on their Ayurvedic constitution and suggests dietary
                                    adjustments to bring about a state of equilibrium.</p>
                                <p>Stress Management In our fast-paced society, stress is a major contributor to
                                    lifestyle disorders. One way to manage stress is through stress management
                                    techniques. Meditation and other Ayurvedic relaxation practices are two of the
                                    stress management approaches that Dr. Alok offers his patients in order to assist
                                    them in regaining their equilibrium.</p>
                                <p>Particularly in the field of lifestyle problems, Dr. Alok, BAMS, MD, is regarded as a
                                    shining example of Ayurvedic knowledge. Numerous people have been given the tools
                                    they need to live healthier lives as a direct result of his holistic approach, which
                                    combines ancient Ayurvedic knowledge with contemporary observations. Dr. Alok
                                    continues to make a substantial influence on the well-being of his patients and
                                    works for the integration of Ayurvedic principles into contemporary healthcare
                                    practices because he has a deep-seated faith in the curative potential of
                                    Ayurveda.</p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

