import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductService} from '../../../shared/services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AddToCartService} from "../../../shared/services/add-to-cart.service";
import {StorageService} from "../../../shared/services/storage.service";
import {AlertService} from "../../../shared/services/alert.service";
import {DataService} from '../../../shared/services/data.service';
import {QuestionService} from "../../../shared/services/question.service";
import {Meta, Title} from '@angular/platform-browser';
import {TitleService} from "../../../shared/services/title.service";
import {MetaService} from '../../../shared/services/meta.service';

@Component({
    selector: 'app-pharmacy-product-details',
    templateUrl: './pharmacy-product-details.component.html',
    styleUrls: ['./pharmacy-product-details.component.scss']
})
export class PharmacyProductDetailsComponent implements OnInit {
    hostName: any = 'https://www.draxico.com/product-detail';
    animation: any;
    reviews: any = [];
    counter = 1;
    submitted: any = false;
    submitting: any = false;
    productDetails: any;
    productUrlArr: any;
    productImageUrl: any;
    productUrl: any;
    public productId: any;
    selectedKeyIngredient: any;
    user: any;
    progress: string = "0";
    objects: any = [];
    cartParams: any = [];
    storedData: any = {};
    status: any = true;


    isCartItemExists(): boolean {
        const storedData = localStorage.getItem('localProducts');
        return storedData === null;
    }

    constructor(public modalService: NgbModal,
                private productService: ProductService,
                private addToCartService: AddToCartService,
                private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private questionService: QuestionService,
                private router: Router,
                private dataService: DataService,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaServices: MetaService,
                private metaService: Meta,
    ) {
        this.user = StorageService.getItem('self');
        const data = localStorage.getItem('localProducts');
        if (data) {
            this.storedData = JSON.parse(data);
        }
    }

    ngOnInit(): void {
        this.productUrl = this.activatedRoute.snapshot.params['url'];
        this.refresh();
        this.getAllReview();
        this.metaServices.updateCanonicalUrl(this.hostName + '/' + this.productUrl);
    }

    refresh() {
        this.getDetails();
        this.getAllTitle();
    }

    increment() {
        (this.counter) ? this.counter += 1 : 1;
    }

    decrement() {
        (this.counter > 1) ? this.counter -= 1 : 1;
    }

    getDetails(params: any = {}) {
        this.submitting = true;
        this.productService.detailByUrl(this.productUrl).subscribe(data => {
                if (data) {
                    this.productDetails = data;
                    this.productDetails['total_price'] = Math.round(parseFloat(this.productDetails['discounted_price']) + (parseFloat(this.productDetails['discounted_price']) * parseFloat(this.productDetails['igst']) / 100))
                } else {
                    this.productDetails = [];
                }
            this.submitting = false;
        }, error => {
            this.router.navigateByUrl('/404-error-page');
            this.submitting = false;
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
        this.modalService.dismissAll('');
    }

    openKeyIngredientAbout(content, data) {
        this.modalService.open(content, {'size': 'lg'});
        this.selectedKeyIngredient = data;
    }

    addToCart() {
        if ((this.user && this.user['user_id'])) {
            this.submitting = true;
            let params = {
                'product_id': this.productDetails.id,
                'quantity': (this.counter),
                'user_id': (this.user && this.user['user_id']) ? this.user['user_id'] : '',
            };
            this.addToCartService.create(params).subscribe(data => {
                if (data) {
                    this.router.navigateByUrl('/cart');
                }
                this.submitting = false;
            }, error => {
                this.submitting = false;
            });
        } else if (!(this.user)) {
            this.submitting = true;
            let params = {
                'product': this.productDetails,
                'quantity': (this.counter)
            };
            try {
                if (this.isCartItemExists()) {
                    this.addObject(params);
                    this.alertService.showSuccess({title: 'Success', message: 'Cart updated successfully'});
                    this.router.navigateByUrl('/cart');
                } else {
                    let products: any = localStorage.getItem('localProducts');
                    let productExists = false;
                    products = JSON.parse(products);
                    products.forEach((items) => {
                        if (items.product.id === params['product']['id']) {
                            items['quantity'] = JSON.parse(items['quantity']) + 1;
                            localStorage.removeItem('localProducts');
                            localStorage.setItem('localProducts', JSON.stringify(products));
                            productExists = true;
                        }
                    });
                    if (!productExists) {
                        this.addMoreCartItem(params);
                    }

                    this.alertService.showSuccess({title: 'Success', message: 'Cart updated successfully'});
                    this.router.navigateByUrl('/cart');
                }
            } catch (error) {
                this.alertService.showErrors('Addition To Cart failed, Please try again');
                this.submitting = false;
            }

        } else {
            this.dataService.sendMessage('OPEN');
        }
    }

    addObject(newObject: any) {
        this.objects.push(newObject);
        localStorage.setItem('localProducts', JSON.stringify(this.objects));
    }

    addMoreCartItem(params) {
        const existingData = localStorage.getItem('localProducts');
        if (existingData) {
            this.objects = JSON.parse(existingData);
        }
        this.objects.push(params);
        localStorage.removeItem('localProducts');
        localStorage.setItem('localProducts', JSON.stringify(this.objects));
    }

    scrollTo(x, y) {
        window.scrollTo(x, y);
    }

    getAllReview() {
        this.questionService.getReview().subscribe(res => {
            this.reviews = res.review;
            this.progress = ((this.reviews.rating / (this.reviews.rating - 1 + this.reviews.rating - 2 + this.reviews.rating - 3 + this.reviews.rating - 4)) * 100).toString();
            return this.progress;
        })
    }

    changeProductImg(url: string) {
        this.productImageUrl = url;
    }

    getAllTitle() {
        this.titleService.getProductTitleJson()
            .subscribe(res => {
                this.productUrlArr = res.title;
                if (this.productUrl && this.productUrlArr.length > 0) {
                    this.productUrlArr.forEach(productUrl => {
                        if (productUrl.url === this.productUrl) {
                            this.titleLibraryService.setTitle(productUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: productUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: productUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: productUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: productUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: productUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: productUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: productUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: productUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: productUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: productUrl.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: productUrl.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: productUrl.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: productUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: productUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: productUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: productUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: productUrl.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: productUrl.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: productUrl.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: productUrl.twitter_image});
                            this.metaService.updateTag({name: 'twitter:ib.scge', content: productUrl.twitter_ib_scge});
                            this.metaService.updateTag({name: 'twitter:im.scge', content: productUrl.twitter_im_scge});
                            this.metaService.updateTag({name: 'twitter:ib.edge', content: productUrl.twitter_ib_edge});
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: productUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: productUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: productUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({name: 'twitter:idfmge', content: productUrl.twitter_idfmge});
                            this.metaService.updateTag({name: 'twitter:idmmge', content: productUrl.twitter_idmmge});
                            this.metaService.updateTag({name: 'twitter:idrmge', content: productUrl.twitter_idrmge});
                        }

                    });
                }
            });
    }
}

