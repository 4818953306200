<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Address</h4>
        <button (click)="closeModal()" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form [formGroup]="addAddressForm" novalidate>
                    <div
                        *ngIf="pinCodeLoader"
                        style="position: absolute; width: 100%; background-color: rgba(255, 255, 255, 0.8); opacity: 0.9; height: 100%; top: 0; left: 0; text-align: center">
                        <i class="fas fa-spinner fa-pulse fa-2x" style="margin-top: 350px"></i>
                        <br>
                        <small>Please wait while we are fetching pin code data...</small>
                    </div>
                    <div class="row p-10">
                        <label> <i class="icofont-address-book text-success"></i><strong> Shipping Address
                            :-</strong></label>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-address-book"></i>
                                <label class="p-10">Address Line 1<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" formControlName="address_1_shipping" id="shipping-address-line1"
                                       placeholder="Enter Your Address"
                                       type="text"/>
                                <small
                                    *ngIf="submitted && addAddressForm.get('address_1_shipping').hasError('required')"
                                    class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-address-book"></i>
                                <label class="p-10">Address Line 2</label>
                                <input class="form-control" formControlName="address_2_shipping" id="shipping-address-line2"
                                       placeholder="Enter Your Address"
                                       type="text"/>
                                <small
                                    *ngIf="submitted && addAddressForm.get('address_2_shipping').hasError('required')"
                                    class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-location-pin"></i>
                                <label class="p-10">Landmark</label>
                                <input class="form-control" formControlName="landmark_shipping" id="shipping-landmark"
                                       placeholder="Enter Your landmark"
                                       type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('landmark_shipping').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-code"></i>
                                <label class="p-10">Pin Code<small class="text-danger ml-5px">*</small></label>
                                <input (change)="getPinCodeData($event, 'SHIPPING')" class="form-control" formControlName="pin_code_shipping"
                                       id="shipping-pin-code"
                                       placeholder="Enter Pincode"
                                       type="number"/>
                                <small *ngIf="submitted && addAddressForm.get('pin_code_shipping').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-map-pins"></i>
                                <label class="p-10">City / District<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" disabled formControlName="city_shipping" id="shipping-city"
                                       placeholder="Enter City" type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('city_shipping').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-map-pins"></i>
                                <label class="p-10">State<small class="text-danger ml-5px">*</small></label>
                                <select class="form-control" disabled formControlName="state_id_shipping"
                                        id="shipping-state">
                                    <option value="">Select State</option>
                                    <option *ngFor="let state of states" [value]="state.id">{{state?.name}}</option>
                                </select>
                                <small *ngIf="submitted && addAddressForm.get('state_id_shipping').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-user"></i>
                                <label class="p-10">Name<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" formControlName="name_shipping" id="shipping-email"
                                       placeholder="Enter Your Name"
                                       type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('name_shipping').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-ui-call"></i>
                                <label class="p-10">Contact no.<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" formControlName="contact_shipping" id="shipping-contact"
                                       placeholder="Enter Your Contact Number"
                                       type="number"/>
                                <small *ngIf="submitted && addAddressForm.get('contact_shipping').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-10">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <i (click)="sameBilling(true)" *ngIf="!sameBillingAddress"
                               class="fas fa-check-circle ml-5px cursor-pointer"></i>
                            <i (click)="sameBilling(false)"
                               *ngIf="sameBillingAddress" class="fas fa-check-circle text-success ml-5px cursor-pointer"></i>
                            <label class="form-check-label">
                                <strong class="ml-5px">Billing address same as Shipping address </strong>
                            </label>
                        </div>
                    </div>
                    <div class="row p-10">
                        <label> <i class="icofont-address-book text-danger"></i><strong> Billing Address
                            :-</strong></label>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-address-book"></i>
                                <label class="p-10">Address Line 1<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" formControlName="address_1_billing" id="billing-address-line1"
                                       placeholder="Enter Your Address"
                                       type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('address_1_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-address-book"></i>
                                <label class="p-10">Address Line 2</label>
                                <input class="form-control" formControlName="address_2_billing" id="billing-address-line2"
                                       placeholder="Enter Your Address"
                                       type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('address_2_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-location-pin"></i>
                                <label class="p-10">Landmark</label>
                                <input class="form-control" formControlName="landmark_billing" id="billing-landmark"
                                       placeholder="Enter Your landmark"
                                       type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('landmark_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-code"></i>
                                <label class="p-10">Pin Code<small class="text-danger ml-5px">*</small></label>
                                <input (change)="getPinCodeData($event, 'BILLING')" class="form-control" formControlName="pin_code_billing"
                                       id="billing-pin-code"
                                       placeholder="Enter Pincode" type="number"/>
                                <small *ngIf="submitted && addAddressForm.get('pin_code_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-map-pins"></i>
                                <label class="p-10">City / District<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" disabled formControlName="city_billing" id="billing-city"
                                       placeholder="Enter City" type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('city_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-map-pins"></i>
                                <label class="p-10">State<small class="text-danger ml-5px">*</small></label>
                                <select class="form-control" disabled formControlName="state_id_billing"
                                        id="billing-state">
                                    <option value="">Select State</option>
                                    <option *ngFor="let state of states" [value]="state.id">{{state?.name}}</option>
                                </select>
                                <small *ngIf="submitted && addAddressForm.get('state_id_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-user"></i>
                                <label class="p-10">Name<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" formControlName="name_billing" id="billing-email" placeholder="Enter Your Name"
                                       type="text"/>
                                <small *ngIf="submitted && addAddressForm.get('name_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <i class="icofont-ui-call"></i>
                                <label class="p-10">Contact no.<small class="text-danger ml-5px">*</small></label>
                                <input class="form-control" formControlName="contact_billing" id="billing-contact"
                                       placeholder="Enter Your Contact Number"
                                       type="number"/>
                                <small *ngIf="submitted && addAddressForm.get('contact_billing').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="submitAddress()" class="btn btn-outline-dark pull-right" type="button">Submit</button>
    </div>
</ng-template>
<section class="blog-area-two mt-50 margin-top-xs-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-xs-12">
                <div class="card">
                    <div class="card-header" style="background-color: #2874f0;">
                        <h5 style="color: white; margin-top: 10px">Checkout</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-xs-12">
                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button (click)="scrollTo(250, 110)" aria-controls="flush-collapseOne"
                                                    aria-expanded="true" class="accordion-button"
                                                    data-bs-target="#flush-collapseOne" data-bs-toggle="collapse"
                                                    type="button">
                                                Account
                                            </button>
                                        </h2>
                                        <div aria-labelledby="flush-headingOne" class="accordion-collapse collapse show"
                                             data-bs-parent="#accordionFlushExample" id="flush-collapseOne">
                                            <div class="accordion-body">
                                                <div class="card">
                                                    <div
                                                        class="card-body text-capitalize background-color-grey font-bold">
                                                        {{user?.name.toLowerCase()}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingThree">
                                            <button (click)="scrollTo(250, 110)" aria-controls="flush-collapseThree"
                                                    aria-expanded="false" class="accordion-button collapsed"
                                                    data-bs-target="#flush-collapseThree" data-bs-toggle="collapse"
                                                    type="button">
                                                Billing & Shipping Address
                                            </button>
                                        </h2>
                                        <div aria-labelledby="flush-headingThree" class="accordion-collapse collapse"
                                             data-bs-parent="#accordionFlushExample"
                                             id="flush-collapseThree">
                                            <div class="accordion-body">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div *ngIf="addresses && addresses.length > 0" class="row m-10">
                                                            <div
                                                                *ngFor="let address of addresses"
                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="row p-8px shadow-light">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 text-center">
                                                                            <i *ngIf="address && address['status'] === 'SELECTED'"
                                                                               class="fas fa-check-circle text-success cursor-pointer"
                                                                               style="font-size: 20px; margin-top: 35px"></i>
                                                                            <i (click)="chooseAddress(address.id, 'SELECTED')"
                                                                               *ngIf="address && address['status'] === 'UN_SELECTED'"
                                                                               class="fas fa-check-circle text-secondary cursor-pointer"
                                                                               style="font-size: 20px; margin-top: 35px"></i>
                                                                        </div>
                                                                        <div
                                                                            class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-1">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                            <small>Shipping
                                                                                                Address</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-10 text-capitalize">
                                                                                            {{address?.name_shipping}}
                                                                                            - {{address?.contact_shipping}}
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                                                                                            <span>{{address?.address_1_shipping}}
                                                                                                ,</span>
                                                                                            <span
                                                                                                *ngIf="address && address['address_2_shipping']"> {{address?.address_2_shipping}}
                                                                                                ,</span>
                                                                                            <span> {{address?.landmark_shipping}}
                                                                                                ,</span>
                                                                                            <span> {{address?.city_shipping}}</span><br>
                                                                                            <span> {{address?.state_shipping?.name}}
                                                                                                - {{address?.pin_code_shipping}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                            <small>Billing
                                                                                                Address</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-10 text-capitalize">
                                                                                            {{address?.name_billing}}
                                                                                            - {{address?.contact_billing}}
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                                                                                            <span>{{address?.address_1_billing}}
                                                                                                ,</span>
                                                                                            <span
                                                                                                *ngIf="address && address['address_2_billing']"> {{address?.address_2_billing}}
                                                                                                ,</span>
                                                                                            <span> {{address?.landmark_billing}}
                                                                                                ,</span>
                                                                                            <span> {{address?.city_billing}}</span><br>
                                                                                            <span> {{address?.state_billing?.name}}
                                                                                                - {{address?.pin_code_billing}}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-10">
                                                                            <button (click)="chooseAddress(address.id, 'SELECTED')"
                                                                                    *ngIf="address && address['status'] === 'UN_SELECTED'"
                                                                                    class="btn btn-secondary">
                                                                                Deliver
                                                                                Here
                                                                            </button>
                                                                            <button *ngIf="address && address['status'] === 'SELECTED'"
                                                                                    class="btn btn-success">
                                                                                Deliver
                                                                                Here
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row text-center mt-10">
                                                            <div
                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <button (click)="addAddressModal(content)"
                                                                        class="btn btn-primary">
                                                                    <i class="fas fa-plus-circle mr-10px"></i>Add
                                                                    New Address
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingFour">
                                            <button (click)="scrollTo(250, 110)" aria-controls="flush-collapseFour"
                                                    aria-expanded="false" class="accordion-button collapsed"
                                                    data-bs-target="#flush-collapseFour" data-bs-toggle="collapse"
                                                    type="button">
                                                Payment options
                                            </button>
                                        </h2>
                                        <div aria-labelledby="flush-headingThree" class="accordion-collapse collapse"
                                             data-bs-parent="#accordionFlushExample"
                                             id="flush-collapseFour">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    <div
                                                        *ngFor="let payment of paymentOptions"
                                                        class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 {{payment?.textClass}}">
                                                        <button (click)="payNow(payment['type'])" class="btn btn-primary ml-10px"
                                                                type="submit">{{payment?.name}}</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div
                                                        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                                        <h6 class="text-danger mt-20">&#8377;100 will be added as
                                                            shipping charge in Cash on Delivery Payment Option</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <h6 class="mt-10">Order Details</h6>
                    </div>
                    <div class="card-body">
                        <div *ngIf="products && products.length > 0; else noTotalToShow" class="row">
                            <div class="col-12">
                                <table class="table-striped" style="width:100%">
                                    <tr>
                                        <th>Price:</th>
                                        <td>(<b>&#x20b9;</b>) {{actualSum | number : '1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <th>Sub Total:</th>
                                        <td>(<b>&#x20b9;</b>) {{discountedPriceSum | number : '1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <th>Shipping:</th>
                                        <td>Free</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <ng-template #noTotalToShow>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <h5 style="padding: 91px">No data to show</h5>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div *ngIf="products && products.length > 0" class="card-footer">
                        <table style="width:100%">
                            <tr>
                                <th>Total Amount</th>
                                <td class="text-right">(<b>&#x20b9;</b>)
                                    <strong>{{discountedPriceSum | number : '1.2-2'}}</strong></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="card mt-10">
                    <div class="card-header">
                        <h6 class="mt-10">Shipping Address</h6>
                    </div>
                    <div class="card-body">
                        <table class="table-striped" style="width:100%">
                            <tr>
                                <th>Address:</th>
                                <td class="text-capitalize">{{selectedAddress?.address_1_shipping.toLowerCase()}} {{selectedAddress?.address_2_shipping.toLowerCase()}}</td>
                            </tr>
                            <tr>
                                <th>State:</th>
                                <td class="text-capitalize">{{selectedAddress?.state_shipping?.name.toLowerCase()}}</td>
                            </tr>
                            <tr>
                                <th>City:</th>
                                <td class="text-capitalize">{{selectedAddress?.city_shipping.toLowerCase()}}</td>
                            </tr>
                            <tr>
                                <th>PinCode:</th>
                                <td class="text-capitalize">{{selectedAddress?.pin_code_shipping.toLowerCase()}}</td>
                            </tr>
                            <tr>
                                <th>Contact:</th>
                                <td class="text-capitalize">{{selectedAddress?.contact_shipping.toLowerCase()}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
