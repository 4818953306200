<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Privacy Policy</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="privacy-area pt-100">
    <div class="container">
        <p>Dr. Axico a venture of Axico Healthcare Pvt Ltd, a company incorporated under the Companies Act, 1956 with
            its registered office at A-23, Vijay Enclave, Palam-Dabri Road, New Delhi-110045, India. We value the trust
            you place in us &amp; respect your privacy. Hence, we maintain highest standards for secure transactions and
            user information privacy. Please read the following statement to learn about our information gathering and
            dissemination practices.</p>

        <p>By downloading/using/accessing the Dr.Axico app as defined for business and/or personal use, you(&ldquo;User&rdquo;)
            indicate that you understand, agree and consent to this privacy policy. You confirm that You have the
            capacity to enter into a legally binding contract under Indian law, in particular, the Indian Contract Act,
            1872, and have read, understood and agreed to the practices and policies outlined in this Privacy Policy and
            agree to be bound by the Privacy Policy. If You are accessing or using Services on the App or the Site from
            an overseas location, you do so at your own risk, and shall be solely liable for compliance with any
            applicable local laws. You hereby provide your unconditional consent or agreements to the Dr.Axico Entities
            (including but not limited to Dr.Axico and Dr. Axico a venture of Axico Healthcare Pvt Ltd) as provided
            under the information
            technology act, 2000 and the rules and regulations made under therein for accessing the Dr.Axico app.</p>

        <p>This privacy policy is a legally binding contract between You (&ldquo;User&rdquo;) and the Dr.Axico ,
            Dr. Axico a venture of Axico Healthcare Pvt Ltd and its Group / Associates / Subsidiaries, &ldquo;We&rdquo;,
            &ldquo;Us&rdquo;) as
            the operator of the platform (Dr.Axico App)/ website ( www.axicoayurveda.com ). The terms of this privacy
            policy will govern the relationship between you and the Dr.Axico Entities for your use of the Dr.Axico app
            and website for our facilities for the purchase of medicine and pharmaceutical drugs. This Privacy Policy
            shall be read together with the respective terms of use or other terms and conditions of the respective
            product offering on Dr.Axico app. This document is published and shall be construed in accordance with the
            provisions of the information technology (reasonable security practices and procedures and sensitive
            personal data or information) rules, 2011 under the information technology act, 2000 read with Information
            Technology (Intermediaries Guidelines) Rules, 2011; that require publishing of the privacy policy for
            collection, use, storage, transfer/ disclose of personal information including sensitive personal
            information. This privacy policy does not require any physical, electronic or digital signature.</p>

        <h3>Note:</h3>

        <p>By downloading this app, you agree to be bound by the terms and conditions of this Privacy Policy, as this
            Privacy Policy enables us to provide you a secure and a wholesome experience and Privacy Policy forms part
            and parcel of the Terms of Use and other terms on the App (Terms of Use), we will not be able to register
            you, if you are not in agreement with these terms. By mere use of the Dr.Axico app Services, you expressly
            consent to our use and disclosure of your personal information in accordance with this Privacy Policy.</p>

        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking
            at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
            as opposed to using &#39;Content here, content here&#39;, making it look like readable English. Many desktop
            publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for
            &#39;lorem ipsum&#39; will uncover many web sites still in their infancy. Various versions have evolved over
            the years, sometimes by accident, sometimes on purpose</p>

        <h4>Definitions</h4>

        <p>&quot;Dr.Axico &quot; - Refers to any platform owned/subscribed/used by Dr. Axico a venture of Axico
            Healthcare Pvt Ltd or its any
            other Entities not limited to websites, mobile applications, devices, URLs/links, notifications, chatbot, or
            any other communication medium used by Dr.Axico entities to provide its services to its Users. &quot;Dr.Axico
            App&quot; shall include all services extended / to be extended by Dr.Axico and Dr. Axico a venture of Axico
            Healthcare Pvt Ltd as a
            group but not limited to Medical consultancy by the third party and supply of medicine and diagnostic tests
            etc. &ldquo;Cookies&rdquo; - &ldquo;Cookies are small files placed on your device hard-drive/storage that
            assist us in providing our services. We offer certain features that are only available through the use of a
            &quot;cookie&quot;. &quot;Session Cookies&quot; - These are temporary files that are automatically deleted
            from your device hard-drive/storage at the end of a session. &quot;Business Partners&quot; &ndash; Shall
            refer to any individual, group of individuals defined under law with whom Dr.Axico or Dr.Axico Private
            Limited Entities have a contractual relationship not limited to Merchants, Advertisers, Deal partners,
            Financial institutions. &quot;Service Providers&quot; &ndash; Refers to any individual, group of individuals
            defined under law whose services are used by Dr.Axico or Dr. Axico a venture of Axico Healthcare Pvt Ltd in
            order to provide the
            intended services to you through Dr.Axico .</p>

        <p>Amendment of Privacy Policy</p>

        <p>Our Privacy Policy is subject to change at any time without prior notice and the same shall be updated and
            available within Dr.Axico App and on its website at www.axicoayurveda.com from time to time. Though every
            effort would be made to keep you informed of the changes, if any, to the policy, you are kindly advised to
            review the policy periodically. Any changes to the Policy will become effective upon posting of the revised
            Policy on the Internet, accessible through Dr.Axico Platform.</p>

        <p>Scope &amp; Applicability</p>

        <p>This Privacy Policy is applicable to all users of Dr.Axico including merchants, customers, business partners
            whether registered or non-registered accessing, offering, selling or purchasing products or services on the
            website, mobile applications, links, notifications, short messaging services, or any other communication
            medium used or owned by Dr.Axico and Dr. Axico a venture of Axico Healthcare Pvt Ltd. This Privacy Policy is
            also applicable to
            Dr.Axico&rsquo;s Group/Affiliate/Subsidiary Companies defined as Dr.Axico Entities who provide their
            products/ services on the Dr.Axico&rsquo;s website and mobile applications or control Dr.Axico directly or
            indirectly.</p>

        <p>Purpose</p>

        <p>We collect, processes, store and record your data and information while You visit any of Dr.Axico platforms
            or avail Dr.Axico Services. This Privacy Policy is provided to familiarize You with;</p>

        <ul>
            <li>&nbsp;The type of information that is provided or shared by &ldquo;You&rdquo; for availing products and
                services provided on Dr.Axico platforms and the information that &ldquo;Dr.Axico Entities&rdquo; and
                &ldquo;service providers&rdquo; collect from you.
            </li>
            <li>&nbsp;The purpose of collection of such data and information from &ldquo;You&rdquo;. and how &ldquo;We&rdquo;
                intend to use the data provided and collected by &ldquo;Us&rdquo;.
            </li>
            <li>&nbsp;The information security practices followed to secure your data and information including
                data/information retention and destruction policy.
            </li>
            <li>&nbsp;To make you aware of data/information handling disclosures, and &ldquo;Your&rdquo; rights.</li>
        </ul>

        <p>Information Collection</p>

        <p>Our primary goal is to provide you a safe, efficient, smooth and customized experience. This allows us to
            provide services and features that most likely meet your needs, and to customize our Website/Application to
            make your experience safer and easier. More importantly, while doing so we collect personal information from
            you that we consider necessary for achieving this purpose.</p>

        <p>&nbsp;</p>

        <p>Provided that, any information that is freely available or accessible in public domain or furnished under the
            Right to Information Act, 2005 or any other law for the time being in force shall not be regarded as
            Sensitive Personal Information and may be regarded as Personal Information.</p>

        <p>We and our Service Providers also use cookies to allow you to enter your password less frequently during a
            session. Cookies can also help us provide information that is targeted to your interests. Most cookies are
            session cookies, you are always free to decline our cookies if your device permits, although in that case
            you may not be able to use certain features on the app and you may be required to re-enter your password
            more frequently during a session</p>

        <ol>
            <li>Personal Information - any information that relates to You, which, either directly or indirectly, in
                combination with other information available is capable of identifying You as a person. Your personal
                information shall include but not limited to the following -,
            </li>
            <li>Your name, address, telephone number, e-mail address and other demographic information.</li>
            <li>Your date of birth and gender.</li>
            <li>Any other information provided by you as part of &ldquo;Know your customer&rdquo; norms as required
                under applicable laws.
            </li>
            <li>Information related to transactions processed on Dr.Axico platforms, excluding payment instrument
                details.
            </li>
            <li>Sensitive Personal Information - Data or information of a person means which consists of information
                relating to -Password
            </li>
            <li>Financial information such as Bank account details</li>
            <li>Any information relating to the above points shared by You.</li>
            <li>Any of the information received by Dr.Axico Entities for processing, stored or processed under lawful
                contract otherwise.
            </li>
            <li>Non-Personal Information- We and our Service Providers may collect non-personal data while you access
                Dr.Axico platforms via url, smartphones or any other mobile devices. This information shall not identify
                &ldquo;you&rdquo; as a person.
            </li>
            <li>The devices and identification numbers of devices used by you to access Dr.Axico platforms.</li>
            <li>I. P. address and information related to the I.P address.</li>
            <li>Your geographic location, cookies stored on your browser/devices and behaviour on Dr.Axico platforms
            </li>
            <li>The operating system of your device, browser, browsing behaviour and any other data as permitted to be
                stored by mobile app stores.
            </li>
            <li>Your messages on message boards, chats or any other message/feedback areas shared on Dr.Axico
                Platforms.Information may be collected in various ways and formats during the course of your visit or
                access to Dr.Axico Platforms by way of -
            </li>
            <li>Visiting Dr.Axico platforms.</li>
            <li>Registering on Dr.Axico platforms as an &ldquo;user&rdquo; or &ldquo;merchant&rdquo; or any other
                relationship that may be governed by terms and conditions listed on Dr.Axico platforms.
            </li>
            <li>Transacting or attempting to transact on Dr.Axico platforms.</li>
            <li>Accessing, links, e-mails, notifications sent or owned by Dr.Axico platforms.</li>
            <li>Otherwise dealing with any of the &ldquo;Dr.Axico Entities&rdquo;</li>
        </ol>

        <p>Are Children Allowed to Use the Platform?</p>

        <p>Use of the Site or the App is available only to persons who can form a legally binding contract under the
            Indian Contract Act, 1872. If You are under 18 years of age, then please do not use or access the Service(s)
            at any time or in any manner. If We learn that a person under 18 years of age has used or accessed the
            Platform or Service or any personally identifiable information has been collected on the Platform from
            persons under 18 years of age, then we will take the appropriate steps to delete this information. If You
            are a parent or guardian and discover that your child under 18 years of age has obtained an account on or
            otherwise accessed the Service, then You may alert us www.axicoayurveda.com and request that we delete that
            child&rsquo;s personally identifiable information from our systems.</p>

        <p>Purpose and Use of Information</p>

        <p>Dr.Axico aspires to deliver a safe, efficient, smooth and customized experience. We aspire to provide
            services and features that most likely meet your needs, and to customize our Website/Application to make
            your experience safer and easier. Dr.Axico may collect, use, store and transfer your Personal Information
            including Sensitive Personal Information and Non-personal information for the following purposes</p>

        <ol>
            <li>Verification of your identity, access, privileges assigned in order to provide the services you request,
                or we intend to offer.
            </li>
            <li>To resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer
                interest in our products and services,
            </li>
            <li>To inform you about online and offline offers, products, services, and updates; customize your
                experience and Improving your experience while using the application by presenting advertising, products
                and offers tailored to you.
            </li>
            <li>To detect and protect us against error, fraud and other criminal activity; enforce our terms and
                conditions; and as otherwise described to you at the time of collection.
            </li>
            <li>To analyse demographic and profile data about our users&#39; activity on our app.</li>
            <li>Responding to the judicial process and providing information to law enforcement agencies or any
                government agency in connection with a query/investigation on matters as permitted by law.
            </li>
            <li>Sharing, disclosing and transfer of data in case of any major event such as merger, acquisition, sale or
                transfer of assets in case of any Force Majeure event to any other Dr.Axico Entity or third party.
            </li>
            <li>For data processing, statistical or risk analysis and for analytics and review for improvement of the
                product/services.
            </li>
            <li>For conducting risk management.</li>
        </ol>

        <p>Information Storage and Sharing</p>

        <p>Your Personal Information and Sensitive Personal Information shall be stored in electronic form with
            exceptions, wherein the data is required to be maintained in physical form in information and data centres
            located within Indian Jurisdictions.</p>

        <p>Retention period - We retain your information for as long as needed or permitted considering the purpose(s)
            for which it was obtained and will retain this information till such period as prescribed under applicable
            law.</p>

        <ol>
            <li>We shall share personal information only with Dr.Axico Entities for purposes defined under this Privacy
                Policy.
            </li>
            <li>We shall share personally identifiable information / Sensitive Personal Information provided by you to
                only Dr.Axico Entities in the course of making transactions.
            </li>
            <li>Your data may be shared with the internal investigation department within Dr.Axico Entities.</li>
            <li>We may disclose personal information if required to do so by law or in the good faith belief that such
                disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.
            </li>
        </ol>

        <p>Safeguarding your information</p>

        <p>Dr.Axico has deployed technical and physical security measures to safeguard user&rsquo;s Personal Information
            and Sensitive Personal Information and undergo strict internal and external reviews to ensure appropriate
            information security encryption or controls are placed for both data in motion and data at rest within our
            network and servers respectively.</p>

        <p>We have provided multiple levels of security to safeguard your App by Login/Logout option. We have preventive
            controls implemented to ensure you use Dr.Axico App on your device. All Sensitive Personal Information and
            some part of your Personal Information shall be retained under applicable laws.</p>

        <p>Information Breach -</p>

        <p>While We shall take all reasonable and appropriate processes and precautions to secure any Personal
            Information//Sensitive Personal Information which We hold about You, You acknowledge that no data
            transmission over the internet is fully secure, so we cannot provide any assurance or warrant the security
            of any information you submit to us.</p>

        <p>Indemnity -</p>

        <p>You shall indemnify and hold harmless the Dr.Axico Entities, their officers, directors, contractors or agents
            relying on the Information provided by You for any losses, including all claims, damages, liabilities,
            deficiencies, assessments, interest, awards, settlements, penalties, fines, costs or expenses, suffered,
            incurred, sustained by, or imposed on the Dr.Axico Entities, as a result of, arising out of, with respect
            to, in connection with or by reason of a breach or non-performance of any of the terms, conditions,
            representations or warranties contained in this Privacy Policy by You.</p>

        <p>Force Majeure -</p>

        <p>A Force Majeure Event shall mean any event that is beyond the reasonable control of the Dr.Axico and shall
            include but not limited to war, riots, fire, flood, acts of God, explosion, strikes, lockouts, slowdowns,
            lockdown, curfew, prolonged shortage of energy supplies, Pandemic, computer hacking, unauthorized access to
            computer data and storage devices, computer crashes ,acts of state or governmental action prohibiting or
            impeding PhonePe Entities from performing its respective obligations under the contract.</p>

        <p>Severability-</p>

        <p>Each clause of this Privacy Policy shall be and remain separate from and independent of and severable from
            all and any other clauses herein except where otherwise expressly indicated or indicated by the context of
            the Privacy Policy. The decision or declaration that one or more clauses are null and void shall have no
            effect on remaining clauses of this Privacy Policy.</p>

        <p>This Privacy Policy is governed by and shall be construed in accordance with the laws of Republic of India
            and any disputes arising out of or in connection with this Agreement shall be subject to jurisdiction of the
            Courts of Delhi only.</p>

        <p>Jurisdiction -</p>

        <p>This disclosure may be required for us to provide you access to our Services, to comply with our legal
            obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities, or to
            prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services.</p>

        <p>Notices</p>

        <p>If You have any concern about privacy or grievances on the Site or the App, please contact us with a thorough
            description and We will try to resolve the issue for You.</p>

        <p>If you have any concerns or questions in relation to this Privacy Policy, you may address them to Our
            grievance officer at:</p>

        <p>Name: _____________</p>

        <p>Address: _______________________</p>

        <p>Phone number: _________________</p>

        <p>E-mail: __________________________</p>

        <p>We shall endeavour to resolve Your grievances within one month from the date of receipt of such
            grievance.</p>

        <p>User Discretion and Choice/Opt-Out</p>

        <p>You agree and acknowledge that your Personal Information, Personal Sensitive Information and Non-Personal
            Information out of your free will. You may review, correct, update, change the information that you have
            provided by logging into your account. However, you are not permitted to delete any part of the personal
            information or any other information generated on the Platform or request Us to delete the same. You may
            update Your information at any point by writing to us at the details indicated below in the contact section.
            You further agree to all the provisions of this Privacy Policy and authorise us to manage the data shared by
            your or collected by us to be used according to this Privacy Policy.</p>

        <p>By using the Dr.Axico platforms and/ or by providing your information, you consent to the collection and use
            of the information you disclose on the app in accordance with this Privacy Policy.</p>

        <p>&nbsp;</p>

        <p>If we decide to change our privacy policy, we will post those changes on this page so that you are always
            aware of what information we collect, how we use it.</p>

    </div>
</section>
