import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";

@Component({
    selector: 'app-payment-policy',
    templateUrl: './payment-policy.component.html',
    styleUrls: ['./payment-policy.component.scss']
})
export class PaymentPolicyComponent implements OnInit {
    privacyPolicyArr: any;
    privacyPolicyUrl: any;

    hostName: any = 'https://www.draxico.com';

    constructor(private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta,
                private metaServices: MetaService) {
    }

    ngOnInit(): void {
        this.privacyPolicyUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.privacyPolicyUrl);
    }

    getAllTitle() {
        this.titleService.getPaymentPolicyTitleJson()
            .subscribe(res => {
                this.privacyPolicyArr = res.title;
                if (this.privacyPolicyUrl && this.privacyPolicyArr.length > 0) {
                    this.privacyPolicyArr.forEach(privacyPolicyUrl => {
                        if (privacyPolicyUrl.url === this.privacyPolicyUrl) {
                            this.titleLibraryService.setTitle(privacyPolicyUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: privacyPolicyUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: privacyPolicyUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: privacyPolicyUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: privacyPolicyUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: privacyPolicyUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: privacyPolicyUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: privacyPolicyUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: privacyPolicyUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: privacyPolicyUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: privacyPolicyUrl.og_ib_edge});
                            this.metaService.updateTag({
                                property: 'og:ib.libge',
                                content: privacyPolicyUrl.og_ib_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:im.libge',
                                content: privacyPolicyUrl.og_im_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: privacyPolicyUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: privacyPolicyUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: privacyPolicyUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: privacyPolicyUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: privacyPolicyUrl.twitter_card});
                            this.metaService.updateTag({
                                name: 'twitter:title',
                                content: privacyPolicyUrl.twitter_title
                            });
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: privacyPolicyUrl.twitter_description
                            });
                            this.metaService.updateTag({
                                name: 'twitter:image',
                                content: privacyPolicyUrl.twitter_image
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: privacyPolicyUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: privacyPolicyUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: privacyPolicyUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: privacyPolicyUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: privacyPolicyUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: privacyPolicyUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: privacyPolicyUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: privacyPolicyUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: privacyPolicyUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }
}
