<section class="blog-area-two custom-margin margin-top-xs-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-xs-12 tablet-top-margin">
                <div class="card">
                    <div class="card-header" style="background-color: #2874f0;">
                        <h5 style="color: white; margin-top: 8px">My Appointments</h5>
                    </div>
                    <div *ngIf="appointments && appointments.length > 0; else noAppointmentToShow" class="card-body">
                        <div *ngFor="let appointment of appointments; let i = index" class="row shadow-upper-light">
                            <div class="col-xl-1 col-md-1 col-lg-1 col-sm-1 col-xs-12 text-center mt-10">
                                <i class="fas fa-calendar-check fa-3x text-success" style="margin-top: 50px"></i>
                            </div>
                            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-12">
                                <div class="row mt-10 mb-10">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Appointment Id</small>
                                        <h6 class="text-info">{{appointment?.appointment_id}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Name</small>
                                        <h6 class="ml-10 text-capitalize text-info">{{appointment?.name.toLowerCase()}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Contact No</small>
                                        <h6 class="text-info">{{appointment?.contact}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Age</small>
                                        <h6 class="text-info">{{appointment?.age}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Gender</small>
                                        <h6 class="text-info ml-10 text-capitalize">{{appointment?.gender.toLowerCase()}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Doctor</small>
                                        <h6 *ngIf="appointment && appointment['doctor'] && appointment['doctor'].user && appointment['doctor'].user.name"
                                            class="text-info ml-10">{{appointment?.doctor?.user?.name}}</h6>
                                        <h6 *ngIf="!appointment || !appointment['doctor'] || !appointment['doctor'].user || !appointment['doctor'].user.name"
                                            class="text-info ml-10">
                                            To be Assigned</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Symptoms</small>
                                        <h6 class="text-info">{{appointment?.symptoms}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Invoice No.</small>
                                        <h6 class="text-info cursor-pointer" (click)="openExternalUrl('payment-receipt-pdf/'+appointment.id)">{{appointment?.invoice_no}}</h6>
                                        <h6 class="text-info cursor-pointer">-</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Appointment Status</small>
                                        <h6 class="text-info text-capitalize">{{appointment?.status.toLowerCase()}}</h6>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                        <small>Consults</small>
                                        <h6 (click)="appointment['consult_show_status'] = 'OPEN'"
                                            *ngIf="appointment['consult_show_status'] === 'CLOSE'"
                                            class="cursor-pointer text-info text-capitalize">
                                            View
                                        </h6>
                                        <h6 (click)="appointment['consult_show_status'] = 'CLOSE'"
                                            *ngIf="appointment['consult_show_status'] === 'OPEN'"
                                            class="cursor-pointer text-info text-capitalize">
                                            Hide
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="appointment && appointment['consults'].length > 0"
                                [ngClass]="{'display-none': (appointment['consult_show_status'] === 'CLOSE'), 'display-block': (appointment['consult_show_status'] === 'OPEN')}"
                                class="col-xl-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center mt-10 background-color-grey mb--15px">
                                <div *ngFor="let consult of appointment['consults']" class="row mt-10">
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12">
                                        <i class="fas fa-stethoscope text-danger fa-3x mt-25px"></i>
                                    </div>
                                    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-12">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Consult Id</small>
                                                <h6 class="text-info">{{consult?.consult_id}}</h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Consult Start Time</small>
                                                <h6 class="text-info">{{consult?.start_date_time}}</h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Consult End Time</small>
                                                <h6 class="text-info">{{consult?.end_date_time}}</h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Video Url</small>
                                                <h6 class="text-info">
                                                    <i (click)="openUrl(consult['url'])"
                                                       *ngIf="consult && consult['url']"
                                                       class="fas fa-tv cursor-pointer"></i>
                                                    <span *ngIf="!consult || !consult['url']">-</span>
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Remarks</small>
                                                <h6 class="text-info">{{consult?.remarks}}</h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Consult Status</small>
                                                <h6 class="text-info text-capitalize">{{consult?.status.toLowerCase()}}</h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Consultant</small>
                                                <h6 class="text-info text-capitalize">{{consult?.added_by?.name}}</h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                <small>Prescription</small>
                                                <h6 (click)="openExternalUrl('prescription-pdf/'+consult.id)"
                                                    *ngIf="consult && consult['prescriptions'] && consult['prescriptions'].length > 0"
                                                    class="text-info cursor-pointer">
                                                    Download</h6>
                                                <h6 *ngIf="!consult || !consult['prescriptions'] || consult['prescriptions'].length === 0"
                                                    class="cursor-pointer">
                                                    -</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #noAppointmentToShow>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="card" style="padding: 100px">
                                        <div class="card-body text-center">
                                            <h5>No data to show</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>

