import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { AppUrl } from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class OutletService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.OUTLETS(),data);
    }
}
