import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {AlertService} from "../../../shared/services/alert.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BlogService} from "../../../shared/services/blog.service";


@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    blogName: any;
    blogList: any;
    blogDetails: any;
    alias: any;

    @ViewChild('content') templateRef: TemplateRef<any>;


    constructor(private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private alertService: AlertService,
                public modalService: NgbModal,
                private router: Router,
                private blogServices: BlogService) {
        this.getBlogs();
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.getBlogs();
    }

    goTo(url: any) {
        this.router.navigateByUrl(url);
        this.modalService.dismissAll('');
    }

    getBlogs() {
        // @ts-ignore
        return this.blogServices.get({'page': -1}).subscribe((result: any) => {
            this.blogList = result;
        })
    }
}
