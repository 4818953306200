<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Dashboard</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Dashboard</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="blog-details-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <h3 class="p-10"><a routerLink="#">Appointment Details</a></h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6 p-20">
                                                    <label>Name.</label>
                                                    <div>{{users.name}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Age.</label>
                                                    <div>{{users.age}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Gender.</label>
                                                    <div>{{users.gender}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Symptoms.</label>
                                                    <div>{{users.symptoms}}</div>
                                                </div>

                                                <div class="col-6 p-20">
                                                    <label>Mode.</label>
                                                    <div>{{users.mode}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Status.</label>
                                                    <div>{{users.status}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Appointment No.</label>
                                                    <div>{{users.appointment_id}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Transaction Id.</label>
                                                    <div>{{users.transaction_id}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Invoice No.</label>
                                                    <div>{{users.invoice_no}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Created At.</label>
                                                    <div>{{users.created_at}}</div>
                                                </div>
                                                <div class="col-6 p-20">
                                                    <label>Consult Date</label>
                                                    <div>{{users.last_consult_date}}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="blog-bottom">
                                <ul>
                                    <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                                    </li>
                                    <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-area-two">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src=""></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg13.jpg"></a>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a>
                        </h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
