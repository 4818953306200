<div class="page-title-area page-title-five" style="background-image: url('assets/img/banners/contact-us.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Contact Us</h2>
            </div>
        </div>
    </div>
</div>
<div class="location-area">
    <div class="container">
        <div class="row location-wrap text-center" style="padding-left: 0 !important;">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <a>
                        <i class="icofont-location-pin"></i>
                        <h3>Location</h3>
                        <p style="font-weight: 500;font-size: 1rem;">
                            29/2, Main, Palam - Dabri Marg, Durgapuri & Dwarkapuri, Vinodpuri, Block C, Vijay Enclave,
                            Mahavir Enclave, New Delhi, Delhi 110045
                        </p>
                    </a>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <a href="mailto:info@draxico.com">
                        <i class="icofont-ui-message"></i>
                        <h3>Email</h3>
                        <ul class="mb-0 p-0">
                            <li>
                                <a href="mailto:info@draxico.com" style="font-weight: 500;font-size: 1rem;">info@draxico.com</a>
                            </li>
                            <li>
                                <a href="mailto:customer@draxico.com" style="font-weight: 500;font-size: 1rem;">customer@draxico.com</a>
                            </li>
                        </ul>
                    </a>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <a href="tel:9310828505">
                        <i class="icofont-ui-call"></i>
                        <h3>Phone</h3>
                        <ul class="mb-0 p-0">
                            <li style="font-weight: 500;font-size: 1rem;">+91 9310828505</li>
                            <a href="tel:1800 8890 865">
                                <li style="font-weight: 500;font-size: 1rem;"> 1800 8890 865</li>
                            </a>
                        </ul>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left">
                        <h2>Drop your message for any info or question.</h2>
                        <form [formGroup]='contactForm' novalidate>
                            <div class="row">
                                <div class="col-sm-6 col-lg-12">
                                    <div class="form-group mb-2">
                                        <input class="form-control" formControlName="name" id="name"
                                               placeholder="Enter Your Name"
                                               required type="text">
                                        <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group mb-2">
                                        <input class="form-control" formControlName="email" id="email"
                                               placeholder="Enter Your Email"
                                               required type="email">
                                        <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group mb-2">
                                        <input class="form-control" formControlName="phone" id="phone"
                                               pattern="[7-9]{1}[0-9]{9}"
                                               placeholder="Enter Your Number" required type="text">
                                    </div>
                                    <small *ngIf="submitted && contactForm.get('phone').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group mb-2">
                                        <textarea class="form-control" formControlName="message" id="message"
                                                  placeholder="Enter your message" required rows="3"></textarea>
                                    </div>
                                    <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="col-md-12 col-lg-12">
                                    <button (click)="submit()" class="drop-btn" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 p-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img alt="Contact" src="assets/img/home-two/bg4.jpg">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-map-area">
    <div id="map">
        <iframe
            allowfullscreen=""
            height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.769673055313!2d77.07880241495917!3d28.6066857824279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b4a42b5ba11%3A0xd49231c0a4282e13!2s29%2C%202%2C%20Palam%20-%20Dabri%20Marg%2C%20Durgapuri%20%26%20Dwarkapuri%2C%20Vinodpuri%2C%20Block%20C%2C%20Vijay%20Enclave%2C%20Mahavir%20Enclave%2C%20New%20Delhi%2C%20Delhi%20110045!5e0!3m2!1sen!2sin!4v1675772284936!5m2!1sen!2sin"
            style="border:0;"
            width="600"></iframe>
    </div>
</div>
