<footer class="text-white px-5">
    <div id="whatsapp">
        <ul class="buy-button list-inline mb-0">
            <li class="list-inline-item  mb-0">
                <a href="https://wa.me/9310828505" target="_blank">
                    <div class="btn btn-icon btn-pills  rounded-pill wha" style="width: 73px;">
                        <span class="badge whatsapp-btn_badge">
                            1
                        </span>
                        <img class="whatsapp-btn" src="assets/img/buttons/whatsapp.gif">
                    </div>
                </a>
            </li>
        </ul>
    </div>

    <div class="rounded-pill" id="call">
        <ul class="buy-button list-inline mb-0">
            <li class="list-inline-item ps-1 mb-0">
                <a href="tel:9310828505" target="_blank">
                    <img class="call-btn" src="assets/img/buttons/phone.gif">
                </a>
            </li>
        </ul>
    </div>
    <div class="row">
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
            <div class="w-100" style="">
                <img alt="" src="assets/img/logo-footer.png" style="height:120px;width: 200px;">
                <div class="mt-3" style="font-weight: 500;font-size: 1rem">"Dr. Axico” – an online communication
                    interface platform where people can consult with doctors and address their health-related concerns
                    to seek reliable advices.
                </div>
                <div class="d-flex social-icon"><!--                    footer-social-icon-->
                    <a href="https://www.facebook.com/doctoraxico" target="_blank">
                        <img alt="" class="img-fluid" src="assets/img/buttons/facebook.png">
                    </a>
                    <a href="https://www.instagram.com/accounts/login/?next=/doctoraxico/" target="_blank">
                        <img alt="" class="img-fluid" src="assets/img/buttons/instagram.png">
                    </a>

                    <a href="https://twitter.com/doctoraxico" target="_blank">
                        <img alt="" class="img-fluid" src="assets/img/buttons/twitter.png">
                    </a>
                    <a href="https://www.linkedin.com/company/dr-axico/" target="_blank">
                        <img alt="" class="img-fluid" src="assets/img/buttons/linkedin.png">
                    </a>

                    <a href="https://in.pinterest.com/doctoraxico" target="_blank">
                        <img alt="" class="img-fluid" src="assets/img/buttons/pinterest.png">
                    </a>
                    <a href="https://www.youtube.com/channel/UCc0n-Kgh05XOWP7mXUuTH1g" target="_blank">
                        <img alt="" class="img-fluid" src="assets/img/buttons/youtube.png">
                    </a>
                </div>
            </div>
            <hr class="bottom-border">
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 pt-5">
            <div class="row px-lg-4">
                <h5 class=" text-white">Quick Links</h5>
                <ul class="footer-quick ">
                    <li><a routerLink="/blogs">Latest Blogs</a></li>
                    <li><a routerLink="/faq">FAQ's and Help</a></li>
                    <li><a routerLink="/bmi-calculator">BMI Calculator</a></li>
                    <li><a routerLink="/diseases">About Diseases</a></li>
                </ul>
            </div>
            <hr class="bottom-border">
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 pt-5">
            <div class="row">
                <h5 class="text-white">Our Policies</h5>
                <ul class="footer-quick ">
                    <li><a routerLink="/terms-and-conditions">Terms & Condition</a></li>
                    <li><a routerLink="/payment-policies">Payment Policies</a></li>
                    <li><a routerLink="/privacy-policies">Privacy Policy</a></li>
                    <li><a routerLink="/approvals">Approvals</a></li>
                </ul>
            </div>
            <hr class="bottom-border">
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 pt-5">
            <div class="row">
                <h5 class=" text-white">Our Services</h5>
                <ul class="footer-quick ">
                    <li><a routerLink="/medicinal-kits">Medicinal Kits</a></li>
                    <li><a routerLink="/doctor">Our Doctors</a></li>
                    <li><a routerLink="/appointment">Consult Now</a></li>
                    <li><a routerLink="/products">Our Products</a></li>
                </ul>
            </div>
            <hr class="bottom-border">
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 pt-5">
            <div class="row">
                <h5 class="text-white">About US</h5>
                <ul class="footer-quick ">
                    <li>
                        <a (click)="goTo('/about/axico')" [routerLinkActiveOptions]="{exact: true}"
                           class="cursor-pointer" routerLinkActive="active">
                            About Dr Axico
                        </a>
                    </li>
                    <li>
                        <a (click)="goTo('/about/ayurveda')" [routerLinkActiveOptions]="{exact: true}"
                           class="cursor-pointer" routerLinkActive="active">
                            About Ayurveda
                        </a>
                    </li>
                    <li>
                        <a (click)="goTo('/about/achievements')" [routerLinkActiveOptions]="{exact: true}"
                           class="cursor-pointer" routerLinkActive="active">
                            Achievements
                        </a>
                    </li>
                    <li><a routerLink="/contact">Contact us</a></li>
                </ul>
            </div>
            <hr class="bottom-border">
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 pt-5">
            <div class="row">
                <h5 class="text-white">Contact Us</h5>
                <div class="contact-us">
                    <div class="d-flex gap-2">
                        <div class="d-flex">
                            <i class="icofont-ui-message" style="padding: 1rem 0;font-size: 2rem"></i>
                            <div class="m-2">
                                <a href="mailto:info@draxico.com"
                                   style="color: white;padding: 0.5rem 0;font-weight: 500;">info@draxico.com</a><br>
                                <a href="mailto:customer@draxico.com"
                                   style="color: white;padding: 0.5rem 0;font-weight: 500;">customer@draxico.com</a>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-2">
                        <i class="icofont-stock-mobile mt--10px" style="padding: 0.5rem 0;font-size: 2rem"></i>
                        <a href="tel:9310828505" style="color: white;padding: 0rem 0;font-weight: 600;">Call: +91
                            9310828505</a>
                    </div>
                    <div class="d-flex gap-2">
                        <i class="icofont-ui-dial-phone" style="padding: 0rem 0;font-size: 2rem"></i>
                        <a href="tel:1800 8890 865" style="color: white;padding: 0.1rem 0;font-weight: 600;">Toll Free:
                            1800 8890 865</a>
                    </div>
                    <div class="d-flex gap-2">
                        <i class="icofont-location-pin" style="padding: 1rem 0;font-size: 2rem"></i>
                        <a href="https://goo.gl/maps/DWAxzvQTxBoYK7CG9"
                           style="width: 100%;padding: 10px 0;color: white;font-weight: 500">29/2, Main, Palam -
                            Dabri Marg, Durgapuri & Dwarkapuri, Vinodpuri, Block C, Vijay Enclave, Mahavir Enclave, New
                            Delhi,110045</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>
<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Copyright @2023 Design & Developed by <a href="#" target="_blank">Dr.Axico</a>
            </p>
        </div>
    </div>
</div>
