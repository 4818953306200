import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BmicalculatorService} from '../../../shared/services/bmicalculator.service';
import {AlertService} from '../../../shared/services/alert.service';
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";

@Component({
    selector: 'app-bmi-calculator',
    templateUrl: './bmi-calculator.component.html',
    styleUrls: ['./bmi-calculator.component.scss']
})
export class BmiCalculatorComponent implements OnInit {
    public bmiUsForm: FormGroup;
    public adultHeightFeet: any;
    public adultHeightInches: any;
    public adultWeight: any;
    public feetToInches: any;
    public inchesToMeter: any;
    public bmiUsAdultResult: any;
    public heightSquare: any;
    public heightMetricUnitAdult: any;
    public weightMetricUnitAdult: any;
    public heightMetricUnitToMeter: any;
    public bmiMetricAdultResult: any;
    bmiCalculatorArr: any;
    bmiCalculatorUrl: any;
    hostName: any = 'https://www.draxico.com';

    constructor(private bmicalculatorService: BmicalculatorService,
                private alertService: AlertService,
                private fb: FormBuilder,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta,
                private metaServices: MetaService) {
    }

    ngOnInit(): void {
        this.refresh();
        this.bmiCalculatorUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.bmiCalculatorUrl);
    }

    refresh() {
        this.calculateAdultBmiUsUnit();
        this.calculateAdultBmiMetricUnit();
    }

    getAllTitle() {
        this.titleService.getBmiCalculatorTitleJson()
            .subscribe(res => {
                this.bmiCalculatorArr = res.title;
                if (this.bmiCalculatorUrl && this.bmiCalculatorArr.length > 0) {
                    this.bmiCalculatorArr.forEach(bmiCalculatorUrl => {
                        if (bmiCalculatorUrl.url === this.bmiCalculatorUrl) {
                            this.titleLibraryService.setTitle(bmiCalculatorUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: bmiCalculatorUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: bmiCalculatorUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: bmiCalculatorUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: bmiCalculatorUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: bmiCalculatorUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: bmiCalculatorUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: bmiCalculatorUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: bmiCalculatorUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: bmiCalculatorUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: bmiCalculatorUrl.og_ib_edge});
                            this.metaService.updateTag({
                                property: 'og:ib.libge',
                                content: bmiCalculatorUrl.og_ib_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:im.libge',
                                content: bmiCalculatorUrl.og_im_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: bmiCalculatorUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: bmiCalculatorUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: bmiCalculatorUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: bmiCalculatorUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: bmiCalculatorUrl.twitter_card});
                            this.metaService.updateTag({
                                name: 'twitter:title',
                                content: bmiCalculatorUrl.twitter_title
                            });
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: bmiCalculatorUrl.twitter_description
                            });
                            this.metaService.updateTag({
                                name: 'twitter:image',
                                content: bmiCalculatorUrl.twitter_image
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: bmiCalculatorUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: bmiCalculatorUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: bmiCalculatorUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: bmiCalculatorUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: bmiCalculatorUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: bmiCalculatorUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: bmiCalculatorUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: bmiCalculatorUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: bmiCalculatorUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }

    calculateAdultBmiUsUnit() {
        this.feetToInches = this.adultHeightFeet * 12;
        this.inchesToMeter = this.adultHeightInches;
        this.heightSquare = this.feetToInches + this.inchesToMeter;
        this.bmiUsAdultResult = 703 * this.adultWeight / (this.heightSquare * this.heightSquare);

    }

    calculateAdultBmiMetricUnit() {
        this.heightMetricUnitToMeter = this.heightMetricUnitAdult / 100;
        this.bmiMetricAdultResult = this.weightMetricUnitAdult / (this.heightMetricUnitToMeter * this.heightMetricUnitToMeter);
    }


    resetAdultUsUnit() {
        this.adultHeightFeet = '';
        this.adultHeightInches = '';
        this.adultWeight = '';
    }

    resetMetricUsUnit() {
        this.heightMetricUnitAdult = '';
        this.weightMetricUnitAdult = '';
    }

    resetMetricTeenUnit() {
        this.heightMetricUnitAdult = '';
        this.weightMetricUnitAdult = '';
    }
}
