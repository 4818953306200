<div class="page-title-area page-title-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>BMI Calculator</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>BMI Calculator</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area-two pt-5 mt-5 mt-lg-3" style="padding-bottom: 50px">
    <div class="container">
        <div class="row">
            <div class="col">

                <h1>
                    <span class="font-bold">
                    Understanding Your Body Composition Through the Use of a BMI Calculator and Weight Status Analysis
                    </span>
                </h1>
                <p>We would like to take this opportunity to welcome you to Dr. Axico&#39;s Health &amp; Wellness
                    Clinic, where we offer a thorough Body Mass Index (BMI) Calculator that is designed to offer you
                    useful insights on the health of your body as a whole and the degree to which it is lean. Your age,
                    height, and weight are all taken into consideration by our cutting-edge equipment, which results in
                    BMI figures that are more accurate than those produced by a standard evaluation. We also present the
                    Ponderal Index, which is a one-of-a-kind measurement that can further expand your awareness of your
                    body composition. This is done with an emphasis on holistic health.</p>
            </div>
        </div>
        <div class="row mt-0 mt-lg-3 mb-5">
            <div class="col-lg-7 col-xs-12">
                <div class="card shadow-lg">
                    <div class="card-header">
                        <h5>BMI Calculator</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-xs-12 p-10">
                                <div class="card">
                                    <div class="card-header">
                                        <h5>Metric Units</h5>
                                    </div>
                                    <div class="card-body">
                                        <form class="form">
                                            <div class="form-group">
                                                <label class="mt-10">Height</label>
                                                <input [(ngModel)]="heightMetricUnitAdult" class="form-control mb-20"
                                                       name="heightMetricUnitAdult" placeholder="Enter height in cm"
                                                       type="number">
                                            </div>
                                            <div class="form-group">
                                                <label class="mt-10">Weight</label>
                                                <input [(ngModel)]="weightMetricUnitAdult" class="form-control mb-20"
                                                       name="weightMetricUnitAdult" placeholder="Enter weight in kg"
                                                       type="number">
                                            </div>
                                            <button (click)="calculateAdultBmiMetricUnit()"
                                                    class="btn btn-outline-primary mt-10 mb-20 text-center">Calculate
                                            </button>
                                            <button (click)="resetMetricUsUnit()"
                                                    class="btn btn-outline-danger mt-10 mb-20 text-center float-right">
                                                Re-Set
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-xs-12 mt-3 mt-lg-0">
                <section class="blog-area-two sidebar sticky-bar" style="padding-bottom: 0px;">
                    <div class="container px-0">
                        <div class="card shadow-lg rounded">
                            <div class="card-header">
                                <h5>BMI Result</h5>
                            </div>
                            <div class="card-body text-center">
                                <h5 *ngIf="bmiUsAdultResult" class="" style="margin-bottom: 20px">Your BMI
                                    is<br> {{bmiUsAdultResult}}</h5>
                                <h5 *ngIf="bmiMetricAdultResult" class=""
                                    style="margin-bottom: 20px"><span>Your BMI
                                    is</span><br> {{bmiMetricAdultResult | number : '1.2-2'}}</h5>
                                <img *ngIf="bmiMetricAdultResult<=18.5" src="assets/img/emi/1.jpg"
                                     style="margin-top: 20px">
                                <img *ngIf="bmiMetricAdultResult>=18.6 && bmiMetricAdultResult<=24.9"
                                     src="assets/img/emi/2.jpg"
                                     style="margin-top: 20px">
                                <img *ngIf="bmiMetricAdultResult>=25 && bmiMetricAdultResult<=29.9"
                                     src="assets/img/emi/3.jpg"
                                     style="margin-top: 20px">
                                <img *ngIf="bmiMetricAdultResult>=30 && bmiMetricAdultResult<=34.9"
                                     src="assets/img/emi/4.jpg"
                                     style="margin-top: 20px">
                                <img *ngIf="bmiMetricAdultResult>=35" src="assets/img/emi/5.jpg"
                                     style="margin-top: 20px">
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h2> The Secret to Understanding the Significance of BMI</h2>

                <p>The Body Mass Index, also known as BMI, is an important indicator of how well your body is
                    proportioned in terms of its weight and height. It is an essential component in determining your
                    overall health and assists in the process of estimating the mass of your tissues. At Dr. Axico&#39;s
                    Clinic, we acknowledge that a healthy body mass index (BMI) is necessary for a life that is full of
                    vitality. Utilizing our BMI Calculator will provide you with an in-depth analysis that will assist
                    you in appropriately classifying your current weight status. Our body mass index (BMI) examination
                    gives you the information you need to make educated decisions and take charge of your health in a
                    proactive manner, whether your goal is to keep a healthy weight or to address potential health
                    problems.</p>

                <h2> BMI Classification for Making Well-Informed Health Decisions</h2>

                <p>The BMI categorizations that are recognized internationally are used at Dr. Axico&#39;s Clinic, and
                    age is taken into account for a more accurate assessment. Using our BMI Calculator, you will be able
                    to determine your current weight status, which can range from extremely low body fat to a number of
                    various degrees of obesity. We provide a clear classification according to the criteria provided by
                    the World Health Organization (WHO) for adults and the recommendations provided by the Centers for
                    Disease Control and Prevention (CDC) for children and teenagers. This gives you the ability to make
                    educated decisions that are catered to your specific requirements.</p>

                <h2> Beyond the Numbers: The Importance of a Healthy Body Mass Index</h2>

                <p>It is critical to one&#39;s health to keep their body mass index (BMI) at a healthy level. Utilizing
                    our BMI Calculator will assist you in determining if you are at a healthy weight, whether you are
                    overweight, or whether you are obese. We are strong believers in the power of knowledge, and the
                    results of our in-depth analysis shed light on the hazards associated with the BMI range you fall
                    into. Our tool identifies potential areas of concern, ranging from your cardiovascular health to
                    your quality of life, giving you the ability to take preventative measures toward a better
                    lifestyle.</p>

                <h2> Utilizing the BMI Prime and Ponderal Index to Gain Better Insights</h2>

                <p>At Dr. Axico&#39;s Clinic, our BMI calculations go above and beyond what is typically done. Through
                    the use of our BMI Calculator, you will become familiar with the BMI Prime, a dimensionless ratio
                    that provides a rapid assessment of how your BMI compares to the upper limit of normal. With this
                    additional information, a more detailed assessment of your current weight situation is possible. In
                    addition to this, we present the Ponderal Index, which provides an insightful analysis of your body
                    composition by taking into account your height cubed. This indicator is especially helpful for
                    people who have extreme height differences, which helps to ensure accurate assessments for a wider
                    spectrum of people.</p>

                <h2> Knowledge is the key to empowering yourself.</h2>

                <p>Your road to achieve optimal health starts with gaining an awareness of the components that make up
                    your body. The Body Mass Index Calculator provided by Dr. Axico gives you the information you need
                    to make educated decisions about your health. Our Body Mass Index (BMI) evaluation will provide you
                    with the knowledge you need to take charge of your health journey, regardless of whether your goal
                    is to achieve a healthy weight, manage preexisting health concerns, or proactively improve your
                    lifestyle.</p>

                <p>At Dr. Axico&#39;s Health and Wellness Clinic, you may experience the difference that a precise BMI
                    analysis can make. Utilizing our Body Mass Index Calculator (BMI Calculator) and gaining the
                    insights that will lead you to a happier, more fulfilling life is a great way to get started on the
                    path to improved health today.</p>

            </div>
        </div>
    </div>
</section>



