<div class="page-title-area page-title-one mb-md-0 mb-5"
     style="background-image: url('assets/img/banners/certificate_banner.jpg');">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1 class=" mt-50px mt--60px">
                    <span class="font-bold">Approvals</span>
                </h1>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h6>Certificate of Compliance</h6>
            </div>
            <div class="card-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/who-gmp-complaince.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About"  class="img-fluid border rounded" src="assets/img/approval-certificates/renew/gmp-complaince.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/kosher-complaince.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" src="assets/img/approval-certificates/renew/haccp.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/fda.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" src="assets/img/approval-certificates/product-list-complaince.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-10">
            <div class="card-header">
                <h6>Food Safety And Standards Authority Of India</h6>
            </div>
            <div class="card-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/fssai.webp">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-10">
            <div class="card-header">
                <h6>Form-20B, 21B, 25-E</h6>
            </div>
            <div class="card-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/form-20B.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/form-21B.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" src="assets/img/approval-certificates/Form-25.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-10">
            <div class="card-header">
                <h6>Certificate of Incorporation, Manufacturing Loan License</h6>
            </div>
            <div class="card-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/incorporation-certificate.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" src="assets/img/approval-certificates/Sla.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-10">
            <div class="card-header">
                <h6>Certificate of Registration</h6>
            </div>
            <div class="card-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/food-safety-management-system.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/QMS.webp">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="about-item shadow-lg p-3 mb-5 bg-white rounded">
                            <div>
                                <img alt="About" class="img-fluid border rounded" src="assets/img/approval-certificates/renew/udyam-registration.webp">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
