<div class="page-title-area page-title-one"
     style="background-image: url('assets/img/doctor/meet-doctor.png') !important; background-repeat: no-repeat; background-size: 100% 100%;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2 class="mt-100px">Meet Our Doctors</h2>
            </div>
        </div>
    </div>
</div>

<section class="doctors-area doctors-area-two pt-100">
    <div class="doctor-shape">
        <img alt="Shape" src="assets/img/doctor/2.png">
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-yogesh.webp">
                        <a routerLink="/doctor-details/dr-yogesh">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-yogesh">Dr. Yogesh</a></h3>
                        <!--                        <h6>BAMS, MD</h6>-->
                        <span>Skin Specialist</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-teena.webp">
                        <a routerLink="/doctor-details/dr-teena">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-yogesh">Dr. Teena Somra</a></h3>
                        <!--&lt;!&ndash;                        <h6>BAMS</h6>&ndash;&gt;-->
                        <span>Gaynecologist</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-vishwa.webp">
                        <a routerLink="/doctor-details/dr-vishwa-deep">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-vishwa-deep">Dr. Vishwa Deep Biswas</a></h3>
                        <!--&lt;!&ndash;                        <h6>BAMS</h6>&ndash;&gt;-->
                        <span>Diabetes Expert</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-sonu.webp">
                        <a routerLink="/doctor-details/dr-sonu">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-sonu">Dr. Sonu</a></h3>
                        <!--                        <h6>BAMS, MD</h6>-->
                        <span>Child Specialist</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-akshata.webp">
                        <a routerLink="/doctor-details/dr-akshata">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-akshata">Dr. Akshata</a></h3>
                        <!--                        <h6>BAMS, MD</h6>-->
                        <span>E.N.T Specialist</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-jagjeet.webp">
                        <a routerLink="/doctor-details/dr-jagjeet">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-jagjeet">Dr. Jagjeet</a></h3>
                        <!--                        <h6>BAMS, MD, PhD</h6>-->
                        <span>Skin Specialist</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-vikas-meena.webp">
                        <a routerLink="/doctor-details/dr-vikas-meena">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-vikas-meena">Dr. Vikas Meena</a></h3>
                        <!--&lt;!&ndash;                        <h6>BAMS</h6>&ndash;&gt;-->
                        <span>General Physcian</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-akansha.webp">
                        <a routerLink="/doctor-details/dr-akansha">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-akansha">Dr. Akansha</a></h3>
                        <!--                        <h6>BAMS, MD, PhD</h6>-->
                        <span>General Physcian</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-anup.webp">
                        <a routerLink="/doctor-details/dr-anup">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-anup">Dr. Anup</a></h3>
                        <!--&lt;!&ndash;                        <h6>BAMS</h6>&ndash;&gt;-->
                        <span>Surgeon</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-ashwani.webp">
                        <a routerLink="/doctor-details/dr-ashwani">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-ashwani">Dr. Ashwani</a></h3>
                        <!--&lt;!&ndash;                        <h6>BAMS</h6>&ndash;&gt;-->
                        <span>General Physcian</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-divya-jain.webp">
                        <a routerLink="/doctor-details/dr-divya-jain">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-divya-jain">Dr. Divya Jain</a></h3>
                        <!--                        <h6>BAMS</h6>-->
                        <span>General Physcian</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-garima.webp">
                        <a routerLink="/doctor-details/dr-garima">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-garima">Dr. Garima</a></h3>
                        <!--                        <h6>BAMS</h6>-->
                        <span>Medicine</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-pradeep.webp">
                        <a routerLink="/doctor-details/dr-pradeep">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-pradeep">Dr. Pradeep</a></h3>
                        <!--                        <h6>BAMS</h6>-->
                        <span>General Physcian</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-uma-saini.webp">
                        <a routerLink="/doctor-details/dr-uma-saini">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-uma-saini">Dr. Uma Saini</a></h3>
                        <!--                        <h6>BAMS</h6>-->
                        <span>General Physcian</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 my-2 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img alt="Doctor" height="448px" src="assets/img/Doctorimage/dr-alok2.webp">
                        <a routerLink="/doctor-details/dr-alok">View Details</a>
                    </div>
                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details/dr-alok">Dr. Alok</a></h3>
                        <!--                        <h6>BAMS</h6>-->
                        <span>Lifestyle Expert</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
