import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../../shared/services/product.service";
import {Helper} from "../../../shared/constants/helper";
import {environment} from "../../../../environments/environment";
import {ModalDismissReasons, NgbDatepickerModule, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ActivatedRoute, Router} from "@angular/router";
// import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import {FormGroup, FormsModule} from "@angular/forms";
import {isEmpty} from "rxjs/operators";
import {MetaService} from "../../../shared/services/meta.service";
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";


@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
    products: any = [];
    types: any = [];
    url: any;
    typesFilters: any = [];
    typeFilters: any = [];
    typeFilterData: any = [];
    submitting: any = false;
    filterProductPrice: FormGroup;

    ratingFilters: any = [];
    ratingFilterData: any = [];
    x: any = false;
    y: any = false;
    percentage: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };


    dropdownSetting = {};
    maxProductPrices: any = [
        // {
        //     'price': '10000',
        //     'name': 'Max'
        // },
        {
            'price': '500',
            'name': '500'
        },
        {
            'price': '1000',
            'name': '1000'
        },
        {
            'price': '1200',
            'name': '1200'
        },
        {
            'price': '1500',
            'name': '1500'
        },
        {
            'price': '10000',
            'name': 'More than 1500'
        }
    ];
    minProductPrices: any = [
        // {
        //     'price': '0',
        //     'name': 'Min'
        // },
        {
            'price': '300',
            'name': '300'
        },
        {
            'price': '800',
            'name': '800'
        },
        {
            'price': '1000',
            'name': '1000'
        },
        {
            'price': '1200',
            'name': '1200'
        },
        {
            'price': '1500',
            'name': '1500'
        }
    ];
    productTypes = [
        'Guggal',
        'Vati',
        'Ras',
        'Rasayan',
        'Loh',
        'Rishta',
        'Asav',
        'Oil',
        'Churna',
        'Quaath',
        'Bhasma',
        'Pak',
        'Pisthi',
        'Miss'
    ];


    minPrice: any = "0";
    maxPrice: any = "10000";
    productType: string = '';
    productRating: string = '0-5';

    searchProducts: any = this.products;
    searchRanges: any;
    rating = {'1': false, '2': false, '3': false, '4': false};
    searchStatus: boolean = true;

    productPageArr: any;
    productPageUrl: any;

    hostName: any = 'https://www.draxico.com';

    constructor(private productService: ProductService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta,
                private metaServices: MetaService,) {
    }

    closeResult = '';

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit(): void {
        this.url = this.activatedRoute.snapshot.params['url'];
        this.get();
        this.resetFilter();

        this.productPageUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.productPageUrl);
    }


    getAllTitle() {
        this.titleService.getProductPageTitleJson()
            .subscribe(res => {
                this.productPageArr = res.title;
                if (this.productPageUrl && this.productPageArr.length > 0) {

                    this.productPageArr.forEach(productPageUrl => {
                        if (productPageUrl.url === this.productPageUrl) {
                            this.titleLibraryService.setTitle(productPageUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: productPageUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: productPageUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: productPageUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: productPageUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: productPageUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: productPageUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: productPageUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: productPageUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: productPageUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: productPageUrl.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: productPageUrl.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: productPageUrl.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: productPageUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: productPageUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: productPageUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: productPageUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: productPageUrl.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: productPageUrl.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: productPageUrl.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: productPageUrl.twitter_image});
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: productPageUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: productPageUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: productPageUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: productPageUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: productPageUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: productPageUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: productPageUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: productPageUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: productPageUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }


    get(params: any = {}) {
        params.page = -1;
        this.submitting = true;
        this.products = [];
        this.productService.get({'page': -1, 'category_id': 1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.products = data['data'];
                if (this.products && this.products.length > 0) {
                    this.products.forEach(product => {
                        if (product && product['discounted_price'] && product['igst']) {
                            product['total_price'] = Math.round(parseFloat(product['discounted_price']) + ((parseFloat(product['discounted_price']) * parseFloat(product['igst'])) / 100))
                        }
                    });
                }
                this.searchProducts = [...this.products];
            } else {
                this.products = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    ratingFilter(event) {
        this.productRating = (event && event.target && event.target.value) ? event.target.value : '';
        this.customFilterProduct(event);
    }

    openExternalUrl(url) {
        window.open(environment.appUrl + url, '_blank');
    }

    searchProduct() {
        let str = this.searchRanges;

        let matchingProduct = [];
        this.searchProducts = this.products;
        this.searchProducts.forEach(item => {
            item['name'] = item['name'].toLowerCase()
            if (item['name'].search(str.toLowerCase()) == -1) {
            } else {
                matchingProduct.push(item);
            }
        })
        this.searchProducts = matchingProduct;
        this.searchStatus = false;
    }

    search(nameKey, myArray) {
        let toSearch = nameKey;
        let lanes = myArray;
        let result = [];
        const filters = [toSearch];
        for (let i = 0; i < lanes.length; i++) {
            let search = lanes[i].name;
            let isMatched = true;
            filters.forEach(filter => {
                if (this.similarity(search, filter) < 0.3) {
                    isMatched = false;
                    return;
                }
            });
            if (isMatched) {
                result.push(lanes[i]);
            }
        }
        return result;
    }

    similarity(s1, s2) {
        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
    }

    editDistance(s1, s2) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0)
                    costs[j] = j;
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) != s2.charAt(j - 1))
                            newValue = Math.min(Math.min(newValue, lastValue),
                                costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }

    filterPrice(event, value, type: any) {
        let priceRange = (event && event.target && event.target.value) ? event.target.value : '';
        if (priceRange) {
            let searchRanges = [];
            type === "min" ? this.minPrice = event.target.value : this.maxPrice = event.target.value;
            this.customFilterProduct(event);
        }
    }

    filterType(event) {
        this.productType = (event && event.target && event.target.value) ? event.target.value : '';
        this.customFilterProduct(event);
    }

    customFilterProduct(event) {
        let searchRanges = [];
        if (!this.searchStatus && this.searchProducts.length == 0) {
            this.searchProduct();
        } else if (this.searchStatus) {
            this.searchProducts = this.products;
        }
        if (this.productType != '') {
            let typeRangeArr = this.productType;

            this.searchProducts.forEach(search => {
                if (search.tagline === typeRangeArr) {
                    searchRanges.push(search);
                }
            });
            this.searchProducts = searchRanges;
        }
        let searchPrice = [];
        this.searchProducts.forEach(search => {
            if (parseInt(search.discounted_price) >= parseInt(this.minPrice) && parseInt(search.discounted_price) < parseInt(this.maxPrice)) {
                searchPrice.push(search);
            }
        });
        this.searchProducts = searchPrice;
        let searchRating = [];
        let ratingRangeArr = this.productRating.split("-");
        this.searchProducts.forEach(search => {
            if (parseInt(search.rating) >= parseInt(ratingRangeArr[0])) {

                searchRating.push(search);
            }
        });
        this.searchProducts = searchRating;
    }


    resetProductPage() {
        this.get();
        this.minPrice = 0;
        this.maxPrice = 10000;
        this.productType = '';
        ;
        for (let key in this.rating) {
            this.rating[key] = false;
        }
        this.searchStatus = true;
    }

    resetFilter() {
    }
}
