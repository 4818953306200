import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {StorageService} from '../../../shared/services/storage.service';
import {OrderService} from '../../../shared/services/order.service';
import {TransactionService} from '../../../shared/services/transaction.service';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
    submitted: any = false;
    submitting: any = false;
    orders: any = [];
    transactions: any = [];
    selectedOrder: any;
    user: any;
    modalType: any;
    selectedTransaction: any;
    closeResult: string = '';

    constructor(public modalService: NgbModal,
                public router: Router,
                public orderService: OrderService,
                public transactionService: TransactionService) {
        this.user = StorageService.getItem('self');
        if (!this.user || !this.user.user_id) {
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            });
        }
    }

    ngOnInit(): void {
        this.get();
    }

    get() {
        this.submitting = true;
        this.transactions = [];
        this.transactionService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.transactions = data['data'];
                if (this.transactions && this.transactions.length > 0) {
                    this.transactions.forEach(transaction => {
                        transaction['transaction_status_open'] = 'CLOSE';
                        if (transaction && transaction['orders'] && transaction['orders'].length > 0) {
                            transaction['orders'].forEach(order => {
                                this.selectedOrder = transaction['orders'];
                                if (order && order['delivering_date_time']) {
                                    order['delivering_date'] = (order && order['delivering_date_time']) ? order['delivering_date_time'].split(' ')[0] : '';
                                    order['placing_date'] = (order && order['placed_date_time']) ? order['placed_date_time'].split(' ')[0] : '';
                                    order['delivered_date'] = (order && order['delivered_date_time']) ? order['delivered_date_time'].split(' ')[0] : '';
                                }
                                if (order && order['igst'] && order['discounted_price']) {
                                    order['total_price'] = Math.round(parseFloat(order['product']['discounted_price']) + (parseFloat(order['product']['discounted_price']) * parseFloat(order['product']['igst']) / 100));
                                }
                            });
                        }
                    });
                }
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    openExternalUrl(url) {
        window.open(environment.appUrl + url, '_blank');
    }

    cancelOrderModal(content, data) {
        this.modalType = 'CANCEL_ORDER_MODAL';
        this.selectedTransaction = data;
        this.modalService.open(content);
    }

    cancelOrder() {
        this.submitted = true;
        if (this.submitted) {
            this.submitting = true;
            this.transactionService.updateStatus(this.selectedTransaction.id, {'status': 'CANCELLED'}).subscribe(data => {
                this.get();
                this.modalService.dismissAll('');
            });
        }
    }

    openBackDropCustomClass(content) {
        this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
    }

    openWindowCustomClass(content) {
        this.modalService.open(content, {windowClass: 'dark-modal'});
    }

    openOrderStatusModal(transaction, content) {
        this.modalType = 'SHOW_ORDER_STATUS';
        this.selectedTransaction = transaction;
        this.modalService.open(content, {size: 'lg'});
    }

    openModalDialogCustomClass(content) {
        this.modalService.open(content, {modalDialogClass: 'dark-modal'});
    }
}

