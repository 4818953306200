import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get APP_URL(): string {
        return environment.appUrl + 'admin/';
    }
    static get NEW_API_URL(): string {
        return environment.appiUrl + 'api/';
    }

    /*static get APPOINMENT_FORM(): string {
        return AppUrl.NEW_API_URL + 'contactUs';
    }*/

    static APPOINMENT_FORM(id?): string {
        if (id) {
            return AppUrl.NEW_API_URL + 'contactUs/' + id;
        } else {
            return AppUrl.NEW_API_URL + 'contactUs';
        }
    }

    static get LOGIN(): string {
        return AppUrl.API_URL + 'login';
    }

    static get USER_LOGIN(): string {
        return AppUrl.API_URL + 'user-login';
    }

    static get ADD_USER_LOGIN(): string {
        return AppUrl.API_URL + 'register';
    }

    static VERIFY_OTP(): string {
        return AppUrl.API_URL + 'verify-contact';
    }

    static RESEND_OTP(): string {
        return AppUrl.API_URL + 'resend-otp';
    }

    static get SELF(): string {
        return AppUrl.API_URL + 'self';
    }

    static UPDATE_SELF(): string {
        return AppUrl.API_URL + 'update-self-data';
    }

    static LOGOUT(): string {
        return AppUrl.API_URL + 'logout';
    }

    static get VERIFY_USER(): string {
        return AppUrl.APP_URL + 'verify-user';
    }

    static CONTACTUS(): string {
        return AppUrl.API_URL + 'mainContactUs';
    }

    static APPOINTMENTS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'appointment/' + id;
        } else {
            return AppUrl.API_URL + 'appointment';
        }
    }

    static CONSULT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'consult/' + id;
        } else {
            return AppUrl.API_URL + 'consult';
        }
    }

    static PRESCRIPTION(id?): string {
        if (id) {
            return AppUrl.API_URL + 'prescription/' + id;
        } else {
            return AppUrl.API_URL + 'prescription';
        }
    }

    static PRESCRIPTION_COUNT(): string {
        return AppUrl.APP_URL + 'prescription-count';
    }

    static PRODUCT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'products-active/' + id;
        } else {
            return AppUrl.API_URL + 'products-active';
        }
    }

    static PRODUCT_BY_URL(url): string {
        return AppUrl.API_URL + 'products-active-by-url/' + url;
    }

    static PRODUCT_COUNT(): string {
        return AppUrl.APP_URL + 'product-count';
    }

    static ADMIN_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-password/' + id;
        }
    }

    static ADMIN_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-status/' + id;
        }
    }

    static APPOINTMENTS_COUNT(): string {
        return AppUrl.APP_URL + 'appointment-count';
    }

    static CONSULT_COUNT(): string {
        return AppUrl.APP_URL + 'consult-count';
    }

    static UPDATE_USER_PASSWORD(): string {
        return AppUrl.API_URL + 'update-self-password';
    }

    static DOCTORS(username?): string {
        if(username){
            return AppUrl.API_URL + 'doctors/' + username;
        }else{
            return AppUrl.API_URL + 'doctor';
        }
    }

    static BMICALCULATOR(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'bmi-calculator/' + id;
        } else {
            return AppUrl.APP_URL + 'bmi-calculator';
        }
    }

    static PRODUCTS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'products-active/' + id;
        } else {
            return AppUrl.API_URL + 'products-active';
        }
    }

    static ADD_TO_CART_PRODUCT_ID(id?): string {
        if (id) {
            return AppUrl.API_URL + 'add-to-cart-byProductId/' + id;
        } else {
            return AppUrl.API_URL + 'add-to-cart-byProductId';
        }
    }

    static GET_PRODUCTS_BY_PRODUCT_ID(id?): string {
        if (id) {
            return AppUrl.API_URL + 'get-product-by-pid/' + id;
        } else {
            return AppUrl.API_URL + 'get-product-by-pid';
        }
    }

    static ADD_TO_CART(id?): string {
        if (id) {
            return AppUrl.API_URL + 'add-to-cart/' + id;
        } else {
            return AppUrl.API_URL + 'add-to-cart';
        }
    }

    static ADD_TO_CART_UPDATE_QUANTITY(id?): string {
        if (id) {
            return AppUrl.API_URL + 'add-to-cart-update-quantity/' + id;
        }
    }

    static ADD_TO_CART_COUNT(): string {
        return AppUrl.API_URL + 'add-to-cart-count';
    }

    static ADD_TO_CART_DETELE_ALL(): string {
        return AppUrl.API_URL + 'add-to-cart-delete-all';
    }

    static STATES(): string {
        return AppUrl.API_URL + 'states';
    }

    static PIN_CODE_DATA(pin_code): string {
        return AppUrl.API_URL + 'pin-code-data/' + pin_code;
    }

    static  BLOGS(title?): string {
        if (title) {
            return AppUrl.API_URL + 'blog/' + title;
        } else {
            return AppUrl.API_URL + 'blogs';
        }
    }

    static  HINDI_BLOGS(title?): string {
        if (title) {
            return AppUrl.API_URL + 'hindi-blog/' + title;
        } else {
            return AppUrl.API_URL + 'hindi-blog';
        }
    }

    static ADDRESS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'address/' + id;
        } else {
            return AppUrl.API_URL + 'address';
        }
    }

    static ADDRESS_COUNT(id?): string {
        return AppUrl.API_URL + 'address-count/' + id;
    }

    static UPDATE_ADDRESS_STATUS(id?): string {
        return AppUrl.API_URL + 'address-status/' + id;
    }

    static TRANSACTION(id?): string {
        if (id) {
            return AppUrl.API_URL + 'transaction/' + id;
        } else {
            return AppUrl.API_URL + 'transaction';
        }
    }

    static TRANSACTION_COUNT(id?): string {
        return AppUrl.API_URL + 'transaction-count/' + id;
    }

    static UPDATE_TRANSACTION_STATUS(id?): string {
        return AppUrl.API_URL + 'transaction-status/' + id;
    }

    static SELF_ASSESSMENT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'self-assessment/' + id;
        } else {
            return AppUrl.API_URL + 'self-assessment';
        }
    }

    static ORDER(id?): string {
        if (id) {
            return AppUrl.API_URL + 'order/' + id;
        } else {
            return AppUrl.API_URL + 'order';
        }
    }

    static ORDER_COUNT(id?): string {
        return AppUrl.API_URL + 'order-count/' + id;
    }

    static FORGOT_PASSWORD(id?): string {
        if (id) {
            return AppUrl.API_URL + 'forgot-password/' + id;
        } else {
            return AppUrl.API_URL + 'forgot-password';
        }
    }

    static FORGOT_PASSWORD_UPDATE(id?): string {
        if (id) {
            return AppUrl.API_URL + 'forgot-password-update/' + id;
        } else {
            return AppUrl.API_URL + 'forgot-password-update';
        }
    }

    static OTP_VERIFY(): string {
        return AppUrl.API_URL + 'otp-verify';
    }

    static OUTLETS(): string {
        return AppUrl.API_URL + 'outlet';
    }
}
