<section class="blog-area-two mt-lg-50">
    <ng-template #content>
        <div id="modal">
            <div class="modal-header font-weight-bold">
                <h5 *ngIf="modalType === 'CANCEL_ORDER_MODAL'">Cancel Order - {{selectedTransaction?.invoice_no}}</h5>
                <h5 *ngIf="modalType === 'SHOW_ORDER_STATUS'">Order Status - {{selectedTransaction?.invoice_no}}</h5>
                <i (click)="this.modalService.dismissAll()"
                   class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"></i>
            </div>
            <div class="modal-body">
                <div *ngIf="modalType === 'CANCEL_ORDER_MODAL'" class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6>Are you sure you want to cancel this order ?</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="modalType === 'SHOW_ORDER_STATUS'" class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body mb-2">
                            <!--<h6>Order ID: {{selectedTransaction['orders'][0]?.order_id}}</h6>-->
                            <div class="card">
                                <div class="row p-10 text-center">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize"><strong
                                        class="mt-5">Order Id:</strong>
                                        <br>{{selectedTransaction['orders'][0]?.order_id}}</div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"><strong class="mt-5">Estimated
                                        Delivery time:</strong>
                                        <br>{{selectedTransaction['orders'][0]?.delivering_date_time}}</div>
                                    <!--<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"><strong class="mt-5">Product
                                        Name:</strong> <br><i class="fa fa-product"></i>
                                        {{selectedTransaction['orders'][0]['product']?.name}}
                                    </div>-->
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize"><strong
                                        class="mt-5">Status:</strong>
                                        <br>{{selectedTransaction['orders'][0]?.delivery_status.toLowerCase()}}</div>
                                </div>
                            </div>
                            <div class="track">
                                <ng-container *ngIf="selectedTransaction['orders'][0]?.delivery_status === 'PLACED'">
                                    <div [ngClass]="{disabled: '' } || { active: (selectedTransaction['orders'][0]?.delivery_status === 'PLACED' || selectedTransaction['orders'][0]?.delivery_status === 'DELIVERING' || selectedTransaction['orders'][0]?.delivery_status === 'DELIVERED')}"
                                         class="step">
                                            <span class="icon bg-success">
                                                <i class="fa fa-check"></i>
                                            </span>
                                        <span class="text">
                                                Order placed
                                            </span>
                                    </div>
                                    <div [ngClass]="{disabled: '' } || { active: (selectedTransaction['orders'][0]?.delivery_status === 'DELIVERING' || selectedTransaction['orders'][0]?.delivery_status === 'DELIVERED')}"
                                         class="step">
                                            <span class="icon">
                                                <i class="fa fa-user"></i>
                                            </span>
                                        <span class="text">
                                                Order in Transit
                                            </span>
                                    </div>
                                    <div [ngClass]="{disabled: '' } || { active: selectedTransaction['orders'][0]?.delivery_status === 'DELIVERED'}"
                                         class="step">
                                            <span class="icon">
                                                <i class="fa fa-truck"></i>
                                            </span>
                                        <span class="text">
                                                Order Delivered
                                            </span>
                                    </div>
                                    <!--<div class="step" [ngClass]="{disabled: '' } || { active: selectedTransaction['orders'][0]?.delivery_status === 'DELIVERING'}">
                                        <span class="icon">
                                            <i class="fa fa-box"></i>
                                        </span>
                                        <span class="text">
                                            Out For Delivery
                                        </span>
                                    </div>
                                    <div class="step" [ngClass]="{disabled: '' } || { active: selectedTransaction['orders'][0]?.delivery_status === 'DELIVERED'}">
                                        <span class="icon">
                                            <i class="fa fa-box"></i>
                                        </span>
                                        <span class="text">
                                           Delivered
                                        </span>
                                    </div>-->
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="modalService.dismissAll('')" *ngIf="modalType === 'CANCEL_ORDER_MODAL'"
                        class="btn btn-primary">No
                </button>
                <button (click)="cancelOrder()" *ngIf="modalType === 'CANCEL_ORDER_MODAL'" class="btn btn-danger">Yes,
                    Sure
                </button>
                <button (click)="modalService.dismissAll('')" *ngIf="modalType === 'SHOW_ORDER_STATUS'"
                        class="btn btn-primary">Ok
                </button>
            </div>
        </div>
    </ng-template>
    <ng-template #mymodal let-modal>
        <!--<div class="container" *ngIf="transactions && transactions.length > 0;">
            <div class="card order-card" *ngFor="let transaction of transactions; let i = index">
                <div class="card-header order-card-header" style="color:white; background-color: brown;"> My Orders /
                    Tracking
                </div>
                <div class="card-body mb-2" *ngFor="let order of transaction['orders']; let i = index">
                    <h6>Order ID: {{order[0]?.order_id}}</h6>
                    <article class="card">
                        <div class="card-body row">
                            <div class="col"><strong>Estimated Delivery time:</strong>
                                <br>{{order?.delivering_date_time}}</div>
                            <div class="col"><strong>Product Name:</strong> <br><i class="fa fa-product"></i>
                                {{order['product']?.name}}
                            </div>
                            <div class="col"><strong>Status:</strong> <br>{{order?.delivery_status}}</div>
                            <div class="col"><strong>Tracking #:</strong> <br> BD045903594059</div>
                        </div>
                    </article>
                    <div class="track">
                        <ng-template *ngIf="order?.delivery_status === 'PLACED'">
                            <div class="step active" >
                            <span class="icon bg-success">
                            <i class="fa fa-check"></i>
                            </span>
                                <span class="text">
                                Order placed
                            </span>
                            </div>
                            <div class="step" [ngClass]="{disabled: '' } || { active: order?.delivery_status === 'DELIVERING'}">
                            <span class="icon">
                                <i class="fa fa-user"></i>
                            </span>
                                <span class="text">
                                Picked by courier
                            </span>
                            </div>
                            <div class="step" [ngClass]="{disabled: '' } || { active: order?.delivery_status === 'DELIVERING'}">
                            <span class="icon">
                                <i class="fa fa-truck"></i>
                            </span>
                                <span class="text">
                                On the way
                            </span>
                            </div>
                            <div class="step" [ngClass]="{disabled: '' } || { active: order?.delivery_status === 'DELIVERING'}">
                            <span class="icon">
                                <i class="fa fa-box"></i>
                            </span>
                                <span class="text">
                                Out For Delivery
                            </span>
                            </div>
                            <div class="step" [ngClass]="{disabled: '' } || { active: order?.delivery_status === 'DELIVERED'}">
                            <span class="icon">
                                <i class="fa fa-box"></i>
                            </span>
                                <span class="text">
                               Delivered
                            </span>
                            </div>
                        </ng-template>
                        &lt;!&ndash;<ng-template *ngIf="order?.delivery_status === 'PENDING'">
                            <div class="step active">
                            <span class="icon bg-danger">
                            <i class="fa fa-check"></i>
                            </span>
                                <span class="text">
                                Order not confirmed
                            </span>
                            </div>
                        </ng-template>&ndash;&gt;
                       <ng-container [ngClass]="{'disabled':order?.delivery_status === 'PENDING'}" >
                           <div class="step active">
                            <span class="icon">
                            <i class="fa icofont-brand-nexus"></i>
                            </span>
                               <span class="text">
                                Order is in process
                            </span>
                           </div>
                           <div class="step">
                            <span class="icon">
                                <i class="fa fa-user"></i>
                            </span>
                               <span class="text">
                                Picked by courier
                            </span>
                           </div>
                           <div class="step">
                            <span class="icon">
                                <i class="fa fa-truck"></i>
                            </span>
                               <span class="text">
                                On the way
                            </span>
                           </div>
                           <div class="step" >
                            <span class="icon">
                                <i class="fa fa-box"></i>
                            </span>
                               <span class="text">
                                Out For Delivery
                            </span>
                           </div>
                           <div class="step">
                            <span class="icon">
                                <i class="fa fa-box"></i>
                            </span>
                               <span class="text">
                               Delivered
                            </span>
                           </div>
                       </ng-container>
                    </div>
                    &lt;!&ndash;  <ul class="row">
                          <li class="col-md-4">
                              <figure class="itemside mb-3">
                                  <div class="aside"><img
                                      src="https://axicobucket.s3.ap-south-1.amazonaws.com/images/product-image/164/a98139ff-57a9-48d0-983f-c79c38b8fa9e.png"
                                      class="img-sm border">
                                  </div>
                                  <figcaption class="info align-self-center">
                                      <p class="title">Ras</p> <span
                                      class="text-muted">Rs.344.4 </span>
                                  </figcaption>
                              </figure>
                          </li>
                          <li class="col-md-4">
                              <figure class="itemside mb-3">
                                  <div class="aside"><img
                                      src="https://axicobucket.s3.ap-south-1.amazonaws.com/images/product-image/164/a98139ff-57a9-48d0-983f-c79c38b8fa9e.png"
                                      class="img-sm border">
                                  </div>
                                  <figcaption class="info align-self-center">
                                      <p class="title">Oil</p> <span class="text-muted">Rs.344.4 </span>
                                  </figcaption>
                              </figure>
                          </li>
                          <li class="col-md-4">
                              <figure class="itemside mb-3">
                                  <div class="aside"><img
                                      src="https://axicobucket.s3.ap-south-1.amazonaws.com/images/product-image/164/a98139ff-57a9-48d0-983f-c79c38b8fa9e.png"
                                      class="img-sm border">
                                  </div>
                                  <figcaption class="info align-self-center">
                                      <p class="title">Ras</p> <span
                                      class="text-muted">Rs.344.4</span>
                                  </figcaption>
                              </figure>
                          </li>
                      </ul>&ndash;&gt;
                </div>
                <div class="card-footer">
                    <a (click)="this.modalService.dismissAll()"  class="btn btn-warning" data-abc="true"> <i class="fa fa-chevron-left"></i> Back to
                        orders</a>
                </div>
            </div>
        </div>-->
    </ng-template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-xs-12 mobile-top-margin">
                <div class="card">
                    <div class="card-header" style="background-color: #2874f0;">
                        <h5 style="color: white; margin-top: 10px">Orders</h5>
                    </div>
                    <div *ngIf="transactions && transactions.length > 0; else noOrderToShow" class="card-body">
                        <div *ngFor="let transaction of transactions; let i = index"
                             class="row mt-20 shadow-upper-light">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 d-flex"
                                         style="justify-content: center">
                                        <img *ngFor="let order of transaction['orders'] | slice:0:4"
                                             class="width-70px mt-15px"
                                             src="{{order['product']['product_image'][0]['file'].url}}">
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Invoice No.</small>
                                                <h6 (click)="openExternalUrl('product-invoice-pdf/'+transaction?.transaction_id)"
                                                    class="cursor-pointer text-info">
                                                    {{transaction?.invoice_no}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Transaction No.</small>
                                                <h6 class="cursor-pointer text-info">
                                                    {{transaction?.transaction_id}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Payment Type</small>
                                                <h6 class="cursor-pointer text-capitalize">
                                                    <i *ngIf="transaction['type'] === 'CASH'"
                                                       class="fas fa-money-bill text-success f_22"
                                                       title="Cash On Delivery"></i>
                                                    <i *ngIf="transaction['type'] === 'ONLINE'"
                                                       class="fas fa-credit-card text-success f_22"
                                                       title="Online Payment"></i>
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Total Amount</small>
                                                <h6 class="cursor-pointer text-info">
                                                    &#8377;{{transaction?.amt | number : '1.2-2'}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Order Id</small>
                                                <h6 class="cursor-pointer text-info text-capitalize">
                                                    #{{transaction?.orders[0]?.order_id}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Order Status</small>
                                                <h6 *ngIf="transaction['status'] !== 'CANCELLED'"
                                                    class="cursor-pointer text-info text-capitalize">
                                                    {{transaction?.orders[0]?.delivery_status.toLowerCase()}}
                                                </h6>
                                                <h6 *ngIf="transaction['status'] === 'CANCELLED'"
                                                    class="cursor-pointer text-danger text-capitalize font-weight-100">
                                                    {{transaction?.status.toLowerCase()}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Order Placed</small>
                                                <h6 class="cursor-pointer text-info text-capitalize">
                                                    {{transaction?.orders[0]?.placing_date}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small
                                                    *ngIf="transaction && transaction.orders && transaction.orders[0] && !transaction.orders[0].delivered_date_time">Delivering
                                                    On</small>
                                                <h6 (click)="openOrderStatusModal(transaction, content)"
                                                    *ngIf="transaction && transaction.orders && transaction.orders[0] && !transaction.orders[0].delivered_date_time"
                                                    class="cursor-pointer text-info text-capitalize">
                                                    {{transaction?.orders[0]?.delivering_date}}
                                                </h6>
                                                <small
                                                    *ngIf="transaction && transaction.orders && transaction.orders[0] && transaction.orders[0].delivered_date_time">Delivered
                                                    On</small>
                                                <h6 *ngIf="transaction && transaction.orders && transaction.orders[0] && transaction.orders[0].delivered_date_time"
                                                    class="cursor-pointer text-info text-capitalize">
                                                    {{transaction?.orders[0]?.delivered_date_time}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Ship to</small>
                                                <h6 class="cursor-pointer text-info text-capitalize">
                                                    {{transaction?.address?.name_shipping.toLowerCase()}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>No. of Products</small>
                                                <h6 class="cursor-pointer text-info text-capitalize">
                                                    {{transaction?.orders.length}}
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>Cancel Order</small>
                                                <h6 (click)="cancelOrderModal(content, transaction)"
                                                    *ngIf="transaction && transaction.orders && transaction.orders[0] && transaction.orders[0].delivery_status && (transaction.orders[0].delivery_status === 'PLACED' || transaction.orders[0].delivery_status === 'PENDING') && transaction['status'] !== 'CANCELLED'"
                                                    class="cursor-pointer text-danger font-weight-100 text-capitalize">
                                                    Cancel Now
                                                </h6>
                                                <h6 (click)="cancelOrderModal(content, transaction)"
                                                    *ngIf="transaction['status'] === 'CANCELLED'"
                                                    class="cursor-pointer text-danger font-weight-100 text-capitalize">
                                                    -
                                                </h6>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                                                <small>View Order Details</small>
                                                <h6 (click)="transaction['transaction_status_open'] = 'OPEN'"
                                                    *ngIf="transaction['transaction_status_open'] === 'CLOSE'"
                                                    class="cursor-pointer text-info text-capitalize">
                                                    View
                                                </h6>
                                                <h6 (click)="transaction['transaction_status_open'] = 'CLOSE'"
                                                    *ngIf="transaction['transaction_status_open'] === 'OPEN'"
                                                    class="cursor-pointer text-info text-capitalize">
                                                    Hide
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="{'display-none': (transaction['transaction_status_open'] === 'CLOSE'), 'display-block': (transaction['transaction_status_open'] === 'OPEN')}"
                                     class="row mt-20 background-color-grey">
                                    <div *ngFor="let order of transaction['orders']; let i = index"
                                         class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                         style="margin-bottom: -20px">
                                        <div class="row">
                                            <div class="col-lg-3 col-xs-12 text-center mt-15px">
                                                <img (click)="goTo('product-detail/'+order?.product?.url)"
                                                     class="mb-20 cursor-pointer"
                                                     height="50px"
                                                     src="{{order?.product?.product_image[0]?.file?.url}}">
                                            </div>
                                            <div class="col-lg-3 col-xs-12" style="margin-top: 15px">
                                                <small>Product Name</small>
                                                <h6 (click)="goTo('product-detail/'+order?.product?.url)"
                                                    class="cursor-pointer text-info">
                                                    {{order?.product?.name}}
                                                </h6>
                                            </div>
                                            <div class="col-lg-3 col-xs-12" style="margin-top: 15px">
                                                <small>Quantity</small>
                                                <h6 class="cursor-pointer text-info ml-25px">
                                                    {{order?.quantity}}
                                                </h6>
                                            </div>
                                            <div class="col-lg-3 col-xs-12" style="margin-top: 15px">
                                                <small style="margin-top: 20px">Product Price</small>
                                                <h6 class="cursor-pointer text-info">
                                                    &#x20B9;{{order?.total_price | number : '1.2-2'}}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #noOrderToShow>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="card" style="padding: 100px">
                                        <div class="card-body text-center">
                                            <h5>No data to show</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div *ngIf="orders && orders.length > 0" class="card-footer text-center">
                        <button (click)='goTo("products")'
                                class="btn btn-outline-primary"
                                style="background-color: #fb641b; color: white; height: 48px; border: none;">
                            Continue Shopping
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
