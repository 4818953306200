<section class="doctors-area custom-padding">
    <div class="custom-background-image"></div>
</section>
<section class="doctors-area doctors-area-two pt-100">
    <div class="doctor-shape">
        <img alt="Shape" src="assets/img/doctor/2.png">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-lg-none  text-center"
                 style="margin-top: 41px;">
                <h1 class=" mt-50px mt--60px">
                    <span class="font-bold">Medicinal Kits</span>
                </h1>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-none d-lg-block text-center">
                <h1 class=" mt-50px mt--60px">
                    <span class="font-bold">Medicinal Kits</span>
                </h1>
            </div>
        </div>
        <div *ngIf="products && products.length > 0; else noDataToShow" class="row">
            <div
                *ngFor="let product of products"
                [ngClass]="product.product_status==='SOLD_OUT'? 'col-sm-6 col-lg-4 col-xs-12 my-2 sold-out-effect':'col-sm-6 col-lg-4 col-xs-12 my-2'"
                data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <div *ngIf="product?.product_status!='IN_STOCK'">
                            <div class="product-ribbon">
                                <div *ngIf="product.product_status==='NEW'" class="new-stock-ribbon ">
                                    <span>New</span>
                                </div>
                                <div *ngIf="product.product_status==='SOLD_OUT'" class="sold-out-ribbon ">
                                    <span>Sold Out</span>
                                </div>
                            </div>
                        </div>
                        <img
                            *ngIf="product['product_image'] && product['product_image'][0] && product['product_image'][0]['file'] && product['product_image'][0]['file']['url']"
                            alt="{{product?.name}}"
                            class="cursor-pointer"
                            routerLink="/medicinal-kits/{{product?.url}}"
                            src="{{product['product_image'][0]['file']['url']}}">
                        <img
                            *ngIf="!product['product_image'] || !product['product_image'][0] || !product['product_image'][0]['file'] || !product['product_image'][0]['file']['url']"
                            alt="{{product?.name}}" class="cursor-pointer"
                            routerLink="/medicinal-kits/{{product?.url}}"
                            src="assets/img/products/dummy.jpg">
                    </div>
                    <div class="doctor-bottom text-left">
                        <h6 class="cursor-pointer product-name ml-10" routerLink="/medicinal-kits/{{product?.url}}">
                            {{product?.name}}
                        </h6>
                        <span *ngIf="product?.rating == 5">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                </span>
                        <span *ngIf="product?.rating == 4.5">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star-half-alt text-warning"></i>
                                </span>
                        <span *ngIf="product?.rating == 4">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                </span>
                        <span *ngIf="product?.rating == 3.5">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star-half-alt text-warning"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                </span>
                        <span *ngIf="product?.rating == 3">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                </span>
                        <span class="ml-10 f_16 cursor-pointer" routerLink="/medicinal-kits/{{product?.url}}">
                            <span class="color-blue" style="font-size: 18px">
                                <small>&#8377;</small>{{product?.total_price | number : '1.2-2'}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noDataToShow>
            <div class="row text-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h4>No data to show</h4>
                </div>
            </div>
        </ng-template>
    </div>
</section>
