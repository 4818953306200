import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../../shared/services/product.service";
import {TitleService} from 'src/app/shared/services/title.service';
import {Title} from '@angular/platform-browser';
import {Meta} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {MetaService} from 'src/app/shared/services/meta.service';

@Component({
    selector: 'app-medicinal-kits',
    templateUrl: './medicinal-kits.component.html',
    styleUrls: ['./medicinal-kits.component.scss']
})
export class MedicinalKitsComponent implements OnInit {
    products: any = [];
    submitting: any = false;
    medicalKitUrlArr: any;
    medicalKit: any;

    constructor(private productService: ProductService,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaServices: MetaService,
                private metaService: Meta,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.medicalKit = this.route.snapshot.params['url'];
        this.get();
    }

    get(params: any = {}) {
        this.submitting = true;
        this.products = [];
        this.productService.get({'page': -1, 'category_id': 2}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.products = data['data'];
                if (this.products && this.products.length > 0) {
                    this.products.forEach(product => {
                        if (product && product['discounted_price'] && product['igst']) {
                            product['total_price'] = Math.round(parseFloat(product['discounted_price']) + ((parseFloat(product['discounted_price']) * parseFloat(product['igst'])) / 100));
                        }
                    });
                }
            } else {
                this.products = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getAllTitle() {
        this.titleService.getMedicalKitTitleJson()
            .subscribe(res => {
                this.medicalKitUrlArr = res.titles;
                if (this.medicalKit && this.medicalKitUrlArr.length > 0) {
                    this.medicalKitUrlArr.forEach(medicalKitUrl => {
                        if (medicalKitUrl.url === this.medicalKit) {
                            this.titleLibraryService.setTitle(medicalKitUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);
                        }
                    });
                }
            });
    }
}
