import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AlertService} from "../../../shared/services/alert.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-diseases',
    templateUrl: './diseases.component.html',
    styleUrls: ['./diseases.component.scss']
})
export class DiseasesComponent implements OnInit {

    @ViewChild('content') templateRef: TemplateRef<any>;

    constructor(private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private alertService: AlertService,
                public modalService: NgbModal,
                private router: Router) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
    }

    goTo(url) {
        this.modalService.dismissAll('');
        this.router.navigateByUrl(url);
    }
}

