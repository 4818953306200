<section class="section my-5 my-lg-5">
<!--    <pre>{{blogsData|json}}</pre>-->
    <div class="container">
        <div class="row justify-content-center mb-5">
            <div class="col-12 d-flex justify-content-center">
                <label class="switch custom-switch-btn custom-label mb-5" style="cursor:pointer">
                    <input type="checkbox" checked (click)="goTo('blogs/'+selectedBlogData?.english_title)" name="color_mode"
                           id="color_modes" value="1">
                    <label for="color_modes" data-on="Hindi" data-off="English"
                           class="custom-switch-btn-inner custom-label"></label>
                </label>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <!-- BLog Start -->
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="card blog blog-detail border-0 shadow rounded">
                    <img src="" class="img-fluid rounded-top" alt=""/>
                    <div class="card-body content p-0">
                        <img [src]="selectedBlogData?.blog_cover?.url" alt="Blog"
                             style=" width: 100%;"
                             *ngIf="selectedBlogData?.blog_cover?.url;else imageNotFound"/>
                        <ng-template #imageNotFound>
                            <img src="assets/img/blog/p.png" alt="Blog"
                                 style="height: 100%; width: 100%;"/>
                        </ng-template>
                        <div class="px-3">
                        <h1 class=" mt-2"><span
                            class="font-bold pt-2">{{selectedBlogData?.heading}}</span>
                        </h1>
                        <p class="text-dark text-justify" [innerHTML]="selectedBlogContent"></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- BLog End -->
            <!-- START SIDEBAR -->
            <div class="col-lg-4 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 sidebar sticky-bar ms-lg-4">
                    <div class="card-body p-0">
                        <div class="widget mt-4">
                            <span class="bg-soft-dark d-block py-2 rounded shadow text-center h6 mb-0 text-muted"
                                  style="background-color: #ebecee;">
                                Recent Post
                            </span>
                            <div class="mt-4">
                                <div class="clearfix post-recent pb-1"
                                     *ngFor="let blog of blogsData  | slice:0:5; let i = index">
                                    <div class="post-recent-content float-start w-100">
                                        <a class="cursor-pointer" (click)="goTo('blogs/'+blog?.title)">
                                            <p class="text-dark text-justify mb-0 customize_heading">
                                                {{i + 1}}. {{ blog.heading | lowercase}}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widget mt-4">
                            <span class="bg-soft-dark d-block py-2 rounded shadow text-center h6 mb-0 text-muted"
                                  style="background-color: #ebecee;">
                                Tagclouds
                            </span>
                            <div class="tagcloud text-center mt-4">
                                <a routerLink="/appointment" class="rounded">Book Appointment</a>
                                <a routerLink="/medicinal-kits" class="rounded">Medicinal Kits</a>
                                <a routerLink="/products" class="rounded">Products</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container" style="margin-top: -37px;">
    <dl>
        <dt style="color: #444a97;">Disclaimer</dt>
        <dd style="color: #f41522;">
            <marquee onMouseOver="this.stop()" onMouseOut="this.start()">
                Information on this site is provided for informational purpose and is not meant to substitute the advice
                provided by physician or any other medical professional.
            </marquee>
        </dd>
    </dl>
</div>
