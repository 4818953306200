import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";


@Component({
    selector: 'app-doctor-details',
    templateUrl: './doctor-details.component.html',
    styleUrls: ['./doctor-details.component.scss']
})
export class DoctorDetailsComponent implements OnInit {
    doctorName: any;
    validUrl: any = [];

    constructor(private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.validUrl = [
            'dr-yogesh',
            'dr-teena',
            'dr-vishwa-deep',
            'dr-sonu',
            'dr-akshata',
            'dr-jagjeet',
            'dr-vikas-meena',
            'dr-akansha',
            'dr-anup',
            'dr-ashwani',
            'dr-divya-jain',
            'dr-garima',
            'dr-pradeep',
            'dr-uma-saini',
            'dr-alok',
        ];
        this.doctorName = this.route.snapshot.params['doctor-name'];
        if (!this.validUrl.includes(this.doctorName)) {
            this.router.navigateByUrl('/404-error-page');
        }
    }
}
