import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from '../../../shared/services/auth.service';
import {StorageService} from '../../../shared/services/storage.service';
import {GlobalService} from '../../../shared/services/global.service';
import {Subject} from 'rxjs';
import {User} from '../../auth/models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../../shared/services/data.service';
import {AlertService} from '../../../shared/services/alert.service';
import {AddToCartService} from "../../../shared/services/add-to-cart.service";
import {NavbarService} from "../../../shared/services/navbar.service";

declare let $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})

export class NavbarComponent implements OnInit, AfterViewInit {
    public loginForm: FormGroup;
    public registerForm: FormGroup;
    public OtpForm: FormGroup;
    showLogin: any = false;
    location: string;
    routerSubscription: any;
    users: any = [];
    isBusy = false;
    data: any;
    user: any = [];
    id: any;
    newData: any;
    public currentUser: User;
    private _unsubscribeAll: Subject<any>;
    isValid: boolean = false;
    public passwordTextType: boolean;
    submitted: any = false;
    submitting: any = false;
    @ViewChild('content') templateRef: TemplateRef<any>;
    loginPressed = false;
    timerSixtySeconds: number = 60;
    decodedOtp: any;
    interval: any;
    public returnUrl: string;
    cartcount: any = 0;

    constructor(private router: Router,
                private authService: AuthService,
                private addToCartService: AddToCartService,
                private globalService: GlobalService,
                public modalService: NgbModal,
                private dataService: DataService,
                public fb: FormBuilder,
                private alertService: AlertService,
                private _route: ActivatedRoute,
                private navbarService: NavbarService
    ) {
        this._unsubscribeAll = new Subject();
        this.user = StorageService.getItem('self');
    }

    ngAfterViewInit() {
        this.dataService.message$.subscribe(message => {
            if (message === 'OPEN' && this.modalService.hasOpenModals() === false) {
                this.modalService.open(this.templateRef, {'size': 'md'});
            }
        });
    }

    ngOnInit(): void {
        this.recallJsFuntions();
        this.refresh();
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this.navbarService.stringSubject.subscribe(
            data => {
                this.cartcount = data;
            }
        );
        if (localStorage.getItem('localProducts')) {
            const products = JSON.parse(localStorage.getItem('localProducts'));
            this.navbarService.passValue(products.length);
        }
    }

    refresh() {
        this.loginForm = this.fb.group({
            'username': ['', (!this.id) ? Validators.required : ''],
            'password': ['', (!this.id) ? Validators.required : ''],
        });
        this.getUser();
        if (this.user) {
            this.getcartcount()
        }
    }

    getcartcount() {
        this.addToCartService.get({'page': -1}).subscribe(data => {
            if (data) {
                this.cartcount = data['data'].length;
            }
        });
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.loader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.loader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    getUser() {
        if (this.user && this.user['userId']) {
            this.authService.self(this.user.userId).subscribe(data => {
                if (data) {
                    this.users = data;
                }
            });
        }

    };

    login(content) {
        if (this.isBusy) {
            return;
        }
        this.isBusy = true;
        this.loginPressed = true;
        if (this.checkLoggedInStatus()) {
            this.setSelfData();
            this.router.navigateByUrl('');
        } else {
            this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                if (data) {
                    this.globalService.setAccessToken(data.token);
                    this.globalService.setSelf({
                        'name': data.name,
                        'user_id': data.id,
                        'username': data.username,
                        'email': data.email,
                        'contact': data.contact,
                        'user_type': data.user_type
                    });
                    this.router.navigate(['/']).then(() => {
                        window.location.reload();
                    });
                    this.loginForm.reset();
                    this.loginPressed = false;
                }
            }, error => {
                this.isBusy = false;
                this.loginPressed = false;
            });
        }
    }

    checkLoggedInStatus() {
        const accessToken = StorageService.getItem('accessToken');
        return accessToken && accessToken !== 'null';
    }

    setSelfData() {
        this.authService.self({all: true}).subscribe((data) => {
            this.isBusy = false;
            this.globalService.setSelf({
                'name': data.name,
                'userId': data.id,
                'username': data.username,
                'user_type': data.user_type
            });
            return;
        }, e => {
            this.isBusy = false;
            StorageService.clearAll();
        });
    }

    logOut() {
        this.authService.logout().subscribe(data => {
            StorageService.clearAll();
            this.router.navigate(['/login']).then(() => {
                window.location.reload();
            });
        }, error => {
            this.router.navigate(['/login']).then(() => {
                window.location.reload();
            });
        });
    }

    get f() {
        return this.loginForm.controls;
    }


    goTo(url) {
        this.router.navigateByUrl(url).then(() => {
            window.location.reload();
        });

    }

    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

}
