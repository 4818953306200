<div class="page-title-area page-title-blog">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Our Latest Blogs</h2>
            </div>
        </div>
    </div>
</div>
<section class="blog-area-two pt-100">
    <div class="container">
        <div class="row">
            <div *ngFor="let blog of blogList" class="col-sm-6 col-lg-4" data-wow-delay=".3s"
                 style="position: relative;">
                <div class="blog-item shadow mb-5 bg-body rounded">
                    <div class="blog-top">
                        <!--                        <pre>{{blog|json}}</pre>-->
                        <a (click)="goTo('blogs/'+blog?.title)"
                           class="cursor-pointer">
                            <img *ngIf="blog?.blog_cover?.url;else imageNotFound" alt="Blog"
                                 src="{{blog?.blog_cover?.url}}"/>
                            <ng-template #imageNotFound>
                                <img alt="Blog" src="assets/img/blog/p.png"/>
                            </ng-template>
                        </a>
                    </div>
                    <div class="blog-bottom" style="position: relative;">
                        <h1 class="blog-header" style="">
                            <a (click)="goTo('blogs/'+blog?.title)"
                               class="cursor-pointer">
                                {{blog?.heading |uppercase}}
                            </a>
                        </h1>
                        <p class="blog-description">{{blog?.short_description | slice:0:100}}...</p>
                        <ul>
                            <li>
                                <a (click)="goTo('blogs/'+blog?.title)"
                                   class="blog-custom-btn cursor-pointer">
                                    Read More..
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
