<div>
    <img class="banner-img" src="assets/img/banners/medicinal-kits.jpg">
</div>
<section class="welcome-area pt-50" id="self-assessment">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1 class=" mt-50px mt--20px">
                    <span class="font-bold">Self Assessments</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/liver-health-assessment"
                     class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/liver-savior.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Liver Health Assessment</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/women-health-assessment"
                     class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/women-care.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Women Health Assessment</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/male-health-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/male rejuvenator.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Male Health Assessment</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/obesity-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/OBESITY.png" style="margin-right: 10px"
                             width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Obesity<br> Assessment<br></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/diabetes-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/diabetes-manager.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Diabetes<br> Assessment</span></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/thyroid-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/thyro-guard.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Thyroid<br> Assessment<br></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/acidity-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/acidity-uprooter.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Acidity<br> Assessment <br></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/joints-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img class="mr-10px" height="80px" src="assets/img/dr_axico_icons/ortho-fortify.png"
                             width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Joints<br> Assessment<br></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/general-health-assessment"
                     class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/general helath examine.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>General Health Assessment</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/uterine-fibroid-assessment"
                     class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/FIBROID.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Uterine Fibroid Assessment</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/kidney-assessment" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/natural-kidney-care.png" width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Kidney <br>Assessment<br></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12">
                <div routerLink="/self-assessment/prakriti" class="service-item-home cursor-pointer">
                    <div class="box">
                        <img height="80px" src="assets/img/dr_axico_icons/PSHYCOSOMATIC%20ANALYSIS%20IN%20AYURVEDA.png"
                             width="100px">
                        <div class="cursor-pointer mt-10 font-size-18 fw-500"><span>Prakriti <br>Assessment<br></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
