<ng-template #content>
    <div id="modal">
        <div class="modal-header font-weight-bold">
            <h4 class="modal-title font-weight-bold">
                <span class="ml-10">Enter OTP sent to your Contact No.</span>
            </h4>
            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="otpForm" class="form mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="otp" placeholder="Enter OTP"
                                           type="number">
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="submitOtp(content2)" [disabled]="!otpForm.valid"
                    class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    type="submit"><b>Submit</b>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #content2 let-modal2>
    <div id="modal2">
        <div class="modal-header font-weight-bold">
            <h4 class="modal-title font-weight-bold">
                <i class="fa-lock fas cursor-pointer mr-2" style="font-size: 35px;"></i>
                <span class="ml-10">Appointment Details</span>
            </h4>
            <button (click)="modal2.close('Save click')" aria-label="Close" class="btn-close"
                    type="button"></button>
        </div>
        <div class="modal-body">
            <div class="card">
                <div class="card-header">
                    <p class="text-success text-center">
                        Hey {{this.appointment.name}}, Your appointment booking process is initiated
                        successfully, Your Appointment Id is {{this.appointment.appointment_id}}.
                    </p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col" style="text-align: -webkit-center;">
                            <img alt="" class="mx-auto" height="100px" src="assets/img/checkmark.gif"
                                 width="100px">
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col">
                            <div class="form-group" style="text-align: center;">
                                <button (click)="modal2.close('Save click')" class="btn btn-outline-dark"
                                        type="button">close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div [ngSwitch]="diseasesName">
    <div *ngSwitchCase="'acne'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/acne/Banner.jpg">
    </div>
    <div *ngSwitchCase="'arthritis'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/arthritis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'asthma'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/asthma/Banner.jpg">
    </div>
    <div *ngSwitchCase="'bacterial-vaginosis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/bacterial-vaginosis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'bronchiectasis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/bronchiectasis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'chest-pain'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/chest-pain/Banner.jpg">
    </div>
    <div *ngSwitchCase="'chronic-kidney-diseases'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/chronic-kidney-diseases/Banner.jpg">
    </div>
    <div *ngSwitchCase="'common-cold'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/common-cold/Banner.jpg">
    </div>
    <div *ngSwitchCase="'dehydration'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/dehydration/Banner.jpg">
    </div>
    <div *ngSwitchCase="'dengue'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/dengue/Banner.jpg">
    </div>
    <div *ngSwitchCase="'food-poisoning'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/food-poisoning/Banner.jpg">
    </div>
    <div *ngSwitchCase="'hair-loss-and-balding'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/hair-loss-and-balding/Banner.jpg">
    </div>
    <div *ngSwitchCase="'heart-disease'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/heart-disease/Banner.jpg">
    </div>
    <div *ngSwitchCase="'kidney-infection'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/kidney-infection/Banner.jpg">
    </div>
    <div *ngSwitchCase="'kidney-stone'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/kidney-stone/Banner.jpg">
    </div>
    <div *ngSwitchCase="'malaria'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/malaria/Banner.jpg">
    </div>
    <div *ngSwitchCase="'migraine'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/migraine/Banner.jpg">
    </div>
    <div *ngSwitchCase="'peptic-ulcer-disease'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/peptic-ulcer-disease/Banner.jpg">
    </div>
    <div *ngSwitchCase="'rheumatoid-arthritis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/rheumatoid-arthritis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'ulcerative-colitis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/ulcerative-colitis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'diabetes'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/diabetes/Banner.jpg">
    </div>
    <div *ngSwitchCase="'appendicitis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/appendicitis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'bronchitis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/appendicitis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'cervical-radiculopathy'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/cervical-radiculopathy/Banner.jpg">
    </div>
    <div *ngSwitchCase="'chest-infection'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/chest-infection/Banner.jpg">
    </div>
    <div *ngSwitchCase="'cholesterol-level'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/cholesterol-level/Banner.jpg">
    </div>
    <div *ngSwitchCase="'cirrhosis'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/cirrhosis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'constipation'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/constipation/Banner.jpg">
    </div>
    <div *ngSwitchCase="'cough'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/cough/Banner.jpg">
    </div>
    <div *ngSwitchCase="'eczema'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/eczema/Banner.jpg">
    </div>
    <div *ngSwitchCase="'gastroenteritis'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/gastroenteritis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'headaches'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/headaches/Banner.jpg">
    </div>
    <div *ngSwitchCase="'hepatitis-c'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/hepatitis-c/Banner.jpg">
    </div>
    <div *ngSwitchCase="'liver-disease'">
        <img alt="" class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/liver-disease/Banner.jpg">
    </div>
    <div *ngSwitchCase="'psoriasis'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/psoriasis/Banner.jpg">
    </div>
    <div *ngSwitchCase="'sciatica'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/sciatica/Banner.jpg">
    </div>
    <div *ngSwitchCase="'urticaria'">
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseasesImages/urticaria/Banner.jpg">
    </div>
    <div *ngSwitchCase="'irritable-bowel-syndrome'">
        <img alt=""
             class="img-fluid tablet-top-margin"
             src="assets/img/diseases/diseasesImages/irritable-bowel-syndrome/Banner.jpg">
    </div>
    <div *ngSwitchDefault>
        <img alt="" class="img-fluid tablet-top-margin" src="assets/img/diseases/diseases.jpg">
    </div>
</div>

<div class="about-area py-4 py-lg-5">
    <div class="container">
        <div class="row">
            <!--            Diesease Title Start-->
            <div class="col-8 text-center offset-2 offset-lg-0">
                <h1 class="mb-4">
                    <span class="font-bold" style="margin-bottom: 86px;">
                        {{ disease }}
                    </span>
                </h1>
            </div>
        </div>
        <div class="row">
            <!--            Diesease Content Start-->
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div *ngIf="diseasesName === 'cholesterol-level'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>High Cholesterol Levels? Know its Causes & Cure </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Cholesterol is a type of fat molecule that is important for the body to work
                                    normally. It is
                                    a waxy, fat-like material that is in all of the body's cells, but especially in the
                                    liver,
                                    brain, and nervous system. Cholesterol is important for many things in the body,
                                    such as
                                    building cell membranes, making hormones, and making vitamin D. The imbalanced
                                    cholesterol
                                    level can be managed by&nbsp;<a href="https://www.draxico.com">Ayurvedic doctor
                                        consultation</a>.&nbsp;</p>
                                <p>But having too much cholesterol in the blood can make you more likely to get heart
                                    disease or
                                    a stroke. Lipoproteins carry cholesterol through the blood. LDL cholesterol means
                                    "bad"
                                    cholesterol and HDL cholesterol means "good" cholesterol. Even though it's important
                                    to keep
                                    your cholesterol levels at a healthy level, it's also important to eat well and
                                    exercise
                                    regularly to help prevent health problems that can come from having high
                                    cholesterol.</p>
                                <h3><strong>What are Cholesterol Levels?</strong></h3>

                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cholesterol-level/Image-1.webp"
                                    >
                                </div>
                                <ul>
                                    <li><strong>Total cholesterol:</strong> The total quantity of both LDL and HDL
                                        cholesterol
                                        in the blood. A desirable total cholesterol level is below 200 (mg/dL), while a
                                        level
                                        between 200-239 mg/dL and 240 mg/dL or higher is considered excessive.
                                    </li>
                                </ul>
                                <ul>
                                    <li><strong>LDL (Low-density lipoprotein) cholesterol:</strong> This is commonly
                                        referred to
                                        as "bad" cholesterol because high levels can contribute to the accumulation of&nbsp;<a
                                            href="https://www.mayoclinic.org/diseases-conditions/coronary-artery-disease/symptoms-causes/syc-20350613#:~:text=Coronary%20artery%20disease%20starts%20when,to%20narrow%2C%20blocking%20blood%20flow."
                                            rel="nofollow">plaque
                                            in your arteries</a>, thereby increasing your risk of heart disease and
                                        stroke. A
                                        desirable LDL cholesterol level is below 100 mg/dL, while a level between 100
                                        and 129
                                        mg/dL is considered close to or above optimal, and a level of 130 mg/dL or
                                        higher is
                                        considered elevated.
                                    </li>
                                </ul>
                                <ul>
                                    <li><strong>HDL (High-density lipoprotein) cholesterol:</strong>&nbsp; Often
                                        referred to as
                                        "good" cholesterol because it aids in the removal of excess cholesterol from the
                                        circulation and transports it to the liver, where it can be eliminated from the
                                        body. An
                                        HDL cholesterol level of 60 mg/dL or higher is preferable, whereas a level below
                                        40
                                        mg/dL is considered low and may increase the risk of heart disease.
                                    </li>
                                </ul>
                                <ul>
                                    <li><strong>Triglycerides:</strong>&nbsp;<a
                                        href="https://www.nhlbi.nih.gov/health/high-blood-triglycerides#:~:text=Triglycerides%20are%20a%20type%20of,does%20not%20need%20right%20away."
                                        rel="nofollow">Triglycerides</a>
                                        are a type of blood lipid whose elevated levels can increase the risk of
                                        cardiovascular
                                        disease. A desirable triglyceride level is less than 150 mg/dL, while a level
                                        between
                                        150 and 199 mg/dL is considered borderline high, and 200 mg/dL or higher is
                                        considered
                                        high.
                                    </li>
                                </ul>
                                <h4><strong>How do you measure cholesterol levels?</strong></h4>
                                <ul>
                                    <li>Cholesterol levels are measured through a blood test called a lipid profile.
                                    </li>
                                    <li>A sample of blood is taken from a vein in your arm.</li>
                                    <li>Total cholesterol, LDL (bad) cholesterol, HDL (good) cholesterol, and
                                        triglyceride
                                        levels are measured.
                                    </li>
                                </ul>
                                <h3><strong>What do cholesterol numbers mean?</strong></h3>
                                <p>Here's an explanation of what cholesterol levels indicate:</p>
                                <ul>
                                    <li>Total cholesterol levels: A total cholesterol level below 200 mg/dL is
                                        considered
                                        optimal, while a total cholesterol level between 200 and 239 mg/dL is considered
                                        borderline high, and a total cholesterol level of 240 mg/dL or higher is
                                        considered
                                        high.
                                    </li>
                                    <li>LDL (bad) cholesterol levels: Less than 100 mg/dL is considered optimal, 100-129
                                        mg/dL
                                        is considered near optimal, 130-159 mg/dL is considered borderline high, 160-189
                                        mg/dL
                                        is considered high, and 190 mg/dL or higher is considered very high.
                                    </li>
                                    <li>HDL (excellent) cholesterol levels: A level of 60 mg/dL or higher is regarded as
                                        protective against heart disease, whereas a level of less than 40 mg/dL for men
                                        or less
                                        than 50 mg/dL for women is regarded as low and may increase the risk of heart
                                        disease.
                                    </li>
                                    <li>A triglyceride level below 150 mg/dL is considered normal, while a level between
                                        150-199
                                        mg/dL is considered borderline high, 200-499 mg/dL is considered high, and 500
                                        mg/dL or
                                        higher is considered very high.
                                    </li>
                                </ul>
                                <h3><strong>What are the main factors that affect Cholesterol Levels</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cholesterol-level/Image-2.webp"
                                    >
                                </div>
                                <p>Several factors can affect cholesterol levels in the body. These include:&nbsp;</p>
                                <ul>
                                    <li><strong>Diet:</strong> A diet high in saturated and trans fats can increase LDL
                                        (bad)
                                        cholesterol levels, whereas a diet rich in fruits, vegetables, and whole cereals
                                        can
                                        help reduce LDL cholesterol.
                                    </li>
                                    <li><strong>Overweight:</strong> Obesity can raise LDL cholesterol levels while
                                        lowering HDL
                                        (healthy) cholesterol levels.
                                    </li>
                                    <li><strong>Age and gender:&nbsp;</strong>cholesterol levels tend to rise with age,
                                        and men
                                        have higher cholesterol levels than women on average.
                                    </li>
                                    <li>Cholesterol levels can also be affected by genetic factors, including familial
                                        hypercholesterolemia.
                                    </li>
                                    <li>Smoking can reduce HDL cholesterol levels, increase LDL cholesterol levels, and
                                        increase
                                        the risk of cardiovascular disease.
                                    </li>
                                    <li>Certain medical conditions, including diabetes, hypothyroidism, and&nbsp;<a
                                        href="https://www.draxico.com/diseases/chronic-kidney-diseases">kidney
                                        disease</a>, can
                                        also affect cholesterol levels.
                                    </li>
                                </ul>
                                <h4><strong>What are the Early Signs and Noticeable Changes in the Body&nbsp;</strong>
                                </h4>
                                <p>In the early phases, high cholesterol does not typically cause noticeable symptoms.
                                    However,
                                    over time, high cholesterol levels can contribute to the accumulation of plaque in
                                    the
                                    arteries, which can result in a variety of health issues. Some symptoms and changes
                                    that may
                                    indicate high cholesterol levels and/or plaque buildup in the arteries are
                                    these:</p>
                                <ol>
                                    <li>Angina:&nbsp;<a href="https://www.draxico.com/diseases/chest-pain">Chest
                                        pain</a> is a
                                        frequent sign of heart disease that occurs when the heart does not receive
                                        enough
                                        oxygen-rich blood owing to restricted or clogged arteries.
                                    </li>
                                    <li>Shortness of breath: It is another symptom of&nbsp;<a
                                        href="https://www.draxico.com/diseases/heart-disease">heart disease</a> that
                                        occurs when
                                        the heart is unable to pump enough blood to meet the body's needs.
                                    </li>
                                    <li>Numbness or tingling in the hands and feet: This could be an indication of
                                        peripheral
                                        artery disease, a condition in which the arteries in the legs and feet constrict
                                        and
                                        limit blood flow to these areas.
                                    </li>
                                    <li>Xanthomas: These are fat deposits that occur beneath the skin and can indicate
                                        an
                                        underlying health issue, such as elevated cholesterol levels.
                                    </li>
                                    <li>Xanthelasma: Yellowish-orange patches around the eyes are called xanthelasma and
                                        can
                                        indicate excessive cholesterol levels.
                                    </li>
                                </ol>
                                <h3><strong>Ayurvedic Say on Cholesterol Levels and Risk Factors</strong></h3>
                                <p>According to Ayurveda, elevated cholesterol is primarily viewed as an imbalance of
                                    the Pitta
                                    dosha. When the pitta dosha, which is connected to the elements of fire and heat, is
                                    out of
                                    whack, it might cause a rise in the body's cholesterol levels. An improper diet, a
                                    lack of
                                    physical activity, mental and emotional stress, and other things can all contribute
                                    to an
                                    imbalance in the Pitta dosha.</p>
                                <p>An imbalance in the digestive fire (agni) and metabolism (dhatvagni) is what Ayurveda
                                    considers the accumulation of cholesterol to be. Cholesterol is considered a sort of
                                    fat
                                    (meda dhatu) in the body.</p>
                                <p>Dosha balance is associated with cholesterol levels and risk factors. Vata, Pitta,
                                    and Kapha
                                    are the three doshas or energies that Ayurveda recognizes as existing in the
                                    organism. These
                                    dosha imbalances can result in a variety of health issues, including excess
                                    cholesterol.</p>
                                <p><strong>The following are some of the risk factors that can contribute to high blood
                                    cholesterol levels, as identified by best ayurvedic medicine for
                                    cholesterol:</strong></p>
                                <ul>
                                    <li>Unbalanced diet: Consuming a diet that is rich in fat, sugar, and processed
                                        foods can
                                        lead to high cholesterol levels. Diets that are low in grain are also associated
                                        with
                                        higher levels of cholesterol.
                                    </li>
                                    <li>An unhealthy lifestyle involves little to no physical exercise and can raise the
                                        chance
                                        of getting high cholesterol levels as well as lead to weight gain.
                                    </li>
                                    <li>The presence of a high cholesterol history in one's family can be an indicator
                                        of a
                                        greater probability of getting high cholesterol nowadays.
                                    </li>
                                    <li>Track stress: Although anxiety may give rise to an imbalance among the Pitta
                                        dosha, it's
                                        important to regulate stress using practices like meditation, physical activity,
                                        and
                                        deep breathing.
                                    </li>
                                </ul>
                                <h4><strong>Which Stages of Cholesterol Levels are Curable in Ayurveda?</strong>
                                </h4>
                                <p>Ayurveda views cholesterol differently than Western medicine. Ayurveda prioritises
                                    dosha
                                    balance and general wellness above cholesterol levels. Ayurvedic practices may
                                    promote
                                    healthy metabolism, digestion, and cardiovascular health, which indirectly lowers
                                    cholesterol.</p>
                                <p>Ayurveda recognizes that Kapha and Pitta dosha imbalances may cause high
                                    cholesterol-related
                                    illnesses. Lifestyle, food, herbal, and stress management may correct these
                                    imbalances.</p>
                                <p>Light, easy-to-digest, low-saturated and trans-fat diets are recommended by Ayurveda
                                    for high
                                    cholesterol level management. Ayurveda recommends fresh fruits and vegetables, whole
                                    grains,
                                    legumes, lean meats, and healthy fats like ghee and coconut oil for good metabolism
                                    and
                                    cholesterol balance.</p>
                                <p><a href="https://www.draxico.com/about/ayurveda">Ayurveda</a> recommends yoga,
                                    meditation,
                                    and breathing exercises in addition to food adjustments. These practices increase
                                    circulation, metabolism, and cardiovascular health.</p>
                                <p>Ayurveda has cholesterol-lowering herbs and formulas. Guggul, Arjuna, Triphala, and
                                    Garlic
                                    (Allium sativum) are examples. These herbs may decrease cholesterol and boost
                                    antioxidants.
                                    These plants may lower cholesterol, although scientific data is few.</p><h4><strong>How
                                Ayurveda Can Help in Lowering Cholesterol Levels</strong></h4>
                                <p>As a traditional medical system, Ayurveda emphasizes making lifestyle modifications
                                    and using
                                    natural medicines to promote health and well-being. The following are some methods
                                    that
                                    Ayurveda can use to decrease cholesterol levels:</p>
                                <ul>
                                    <li>Diet: According to Ayurveda, a diet high in fibre and low in saturated and trans
                                        fats is
                                        best. Foods like red meat, fried foods, processed foods, and dairy products
                                        should be
                                        avoided or consumed in moderation. Whole grains, fruits, vegetables, nuts, and
                                        seeds are
                                        encouraged.
                                    </li>
                                    <li>Herbal treatments: Guggul, triphala, and arjuna, three ayurvedic medicines, have
                                        been
                                        demonstrated to decrease cholesterol. These herbs can be consumed or added to
                                        foods and
                                        beverages as supplements.
                                    </li>
                                    <li>Exercise: Ayurveda places a strong emphasis on the value of consistent exercise
                                        in
                                        preserving good health. Exercise can and ayurveda medicine to reduce cholesterol
                                        levels
                                        by boosting metabolism and burning extra fat.
                                    </li>
                                    <li>Management of stress: High cholesterol levels are largely caused by stress. To
                                        reduce
                                        tension and encourage relaxation, Ayurveda suggests practices including yoga,
                                        meditation, and pranayama.
                                    </li>
                                    <li>Detoxification: Ayurveda advises frequent cleansing to get rid of pollutants
                                        that can
                                        raise cholesterol levels in the body. Dietary adjustments, herbal medicines, and
                                        therapeutic procedures like massage and sweating therapy can all help with
                                        detoxification.
                                    </li>
                                </ul>
                                <p>Before utilizing any Ayurvedic therapies or making any dietary or lifestyle changes,
                                    it is
                                    vital to speak with a trained Ayurvedic practitioner. Working closely with your
                                    doctor will
                                    help you monitor your cholesterol levels and make sure that any treatment you
                                    receive is
                                    both secure and efficient.</p><h4><strong>Ayurvedic Remedies for Cholesterol
                                Levels</strong>
                            </h4>
                                <ul>
                                    <li>Triphala is a combination of three fruits - Amla, Haritaki, and Bibhitaki -
                                        known to
                                        have a favourable effect on cholesterol levels. Triphala can be ingested as a
                                        powder or
                                        in tablet form.
                                    </li>
                                    <li>Guggulu is a tree resin. It has been demonstrated to effectively lower
                                        cholesterol
                                        levels. Guggulu is available in tablet or capsule form for consumption.
                                    </li>
                                    <li>Arjuna is an herb traditionally used as best ayurvedic medicine for high
                                        cholesterol to
                                        treat heart-related disorders. It has been shown to have a favourable effect on
                                        cholesterol levels. Arjuna can be ingested in powder or ayurvedic cholesterol
                                        control
                                        tablets.
                                    </li>
                                    <li>It has been discovered that garlic has a beneficial effect on cholesterol
                                        levels. It is
                                        believed to inhibit cholesterol synthesis in the liver. Garlic is edible both
                                        fresh and
                                        cooked.
                                    </li>
                                    <li>Turmeric: Turmeric is a condiment that has been linked to numerous health
                                        benefits. It
                                        has been discovered to have a favourable impact on cholesterol levels. Turmeric
                                        can be
                                        consumed as a powder or in capsule form.
                                    </li>
                                    <li>Ginger, black pepper, and long pepper are three spices that are combined to make
                                        trikatu, which is said to enhance digestion and metabolism. Additionally, it has
                                        been
                                        discovered to lower cholesterol levels. Trikatu can be taken as a powder or in
                                        capsule
                                        form.
                                    </li>
                                </ul>
                                <h4><strong>How Ayurveda Helps in Naturally Managing Cholesterol Levels?</strong></h4>
                                <p>Ayurveda offers a holistic approach to managing cholesterol levels naturally by
                                    focusing on
                                    diet, lifestyle modifications, herbal remedies, and therapies. Here are some ways
                                    Ayurveda
                                    can help in managing cholesterol levels:</p>
                                <ol>
                                    <li>Diet Modifications: Ayurveda emphasizes a balanced diet that includes fresh,
                                        whole
                                        foods. Some dietary recommendations to manage cholesterol levels include:
                                        <ul style="list-style-type: disc;">
                                            <li>Favoring fresh fruits, vegetables, whole grains, and legumes.</li>
                                            <li>Consuming healthy fats such as ghee (clarified butter), olive oil, and
                                                coconut
                                                oil in moderation.
                                            </li>
                                            <li>Reducing the intake of saturated and trans fats found in processed
                                                foods, fried
                                                foods, and fatty meats.
                                            </li>
                                            <li>Including foods rich in soluble fiber, such as oats, barley, flaxseeds,
                                                and
                                                psyllium husk.
                                            </li>
                                            <li>Incorporating Ayurvedic spices like turmeric, cumin, coriander, and
                                                fenugreek,
                                                which have cholesterol-lowering properties.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Herbal Remedies: Ayurveda utilizes various herbs and herbal formulations to help
                                        manage
                                        cholesterol levels. Some commonly recommended herbs for cholesterol management
                                        include:
                                        <ul style="list-style-type: disc;">
                                            <li>Guggulu: Guggulu is a resin known for its lipid-lowering properties. It
                                                helps
                                                reduce LDL cholesterol and triglyceride levels while increase hdl
                                                cholesterol
                                                ayurvedic.
                                            </li>
                                            <li>Arjuna (Terminalia arjuna): Arjuna is a herb that supports
                                                cardiovascular health
                                                and helps regulate cholesterol levels.
                                            </li>
                                            <li>Amalaki (Emblica officinalis): Amalaki, also known as Indian gooseberry,
                                                is rich
                                                in antioxidants and helps in managing cholesterol levels.
                                            </li>
                                            <li>Garlic (Allium sativum): Garlic has cholesterol-lowering effects and can
                                                be
                                                consumed raw or in supplement form.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Lifestyle Modifications: Ayurveda emphasizes adopting a healthy lifestyle to
                                        support
                                        overall well-being and manage cholesterol levels. Some lifestyle recommendations
                                        include:
                                        <ul style="list-style-type: disc;">
                                            <li>Regular exercise: Engaging in physical activity helps improve
                                                metabolism,
                                                circulation, and overall cardiovascular health.
                                            </li>
                                            <li>Stress management: Chronic stress can affect cholesterol levels.
                                                Ayurveda
                                                suggests practices like yoga, meditation, and deep breathing exercises
                                                to manage
                                                stress.
                                            </li>
                                            <li>Adequate sleep: Getting sufficient and quality sleep is crucial for
                                                overall
                                                health and cholesterol management.
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="https://www.draxico.com/panchakarma-treatment/eye-treatments">Panchakarma
                                        Therapy</a>: Panchakarma is a detoxification and rejuvenation therapy in
                                        Ayurveda. It involves personalized Ayurvedic treatment for cholesterol,
                                        including <a
                                            href="https://www.draxico.com/panchakarma-treatment/abhyanga">herbal oil
                                            massage
                                            abhyanga</a>, steam therapies, and cleansing procedures, which can help
                                        eliminate
                                        toxins, balance doshas, and support cholesterol management.
                                    </li>
                                </ol>
                                <h4><strong>What to Eat and Not</strong></h4><h4><strong>Food to eat:</strong></h4>
                                <ul>
                                    <li>Whole grains: High in fiber and capable of lowering cholesterol, whole grains
                                        including
                                        brown rice, quinoa, and whole wheat bread are examples.
                                    </li>
                                    <li>Fruits and vegetables: Rich in fiber, vitamins, and minerals, fruits and
                                        vegetables can
                                        decrease cholesterol levels.
                                    </li>
                                    <li>Nuts and seeds: Nuts and seeds high in heart-healthy fats, like almonds,
                                        walnuts, and
                                        chia seeds, can reduce cholesterol levels.
                                    </li>
                                    <li>Lentils, chickpeas, and black beans are examples of legumes that are high in
                                        fiber and
                                        protein and can decrease cholesterol.
                                    </li>
                                    <li>Fish that are high in omega-3 fatty acids, like salmon, mackerel, and sardines,
                                        can help
                                        decrease cholesterol levels.
                                    </li>
                                </ul>
                                <h4><strong>Food to avoid:</strong></h4>
                                <ul>
                                    <li>Saturated and trans fats: Saturated and trans fats can cause cholesterol levels
                                        to rise
                                        and are present in foods like red meat, processed meats, butter, and margarine.
                                    </li>
                                    <li>Fried foods: Fried foods should be avoided because they are high in saturated
                                        and trans
                                        fats, like fried chicken and french fries.
                                    </li>
                                    <li>Dairy items with a high saturated fat content, such as cheese and cream, can
                                        cause
                                        cholesterol levels to rise.
                                    </li>
                                    <li>meals that have been processed: Because they frequently include a lot of
                                        saturated and
                                        trans fats, processed meals such as packaged snacks, cookies, and cakes should
                                        be
                                        avoided.
                                    </li>
                                    <li>Drinks heavy in sugar and calories, such as soda and fruit juice, can lead to
                                        weight
                                        gain, which can increase cholesterol levels.
                                    </li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>For your body to work properly, you need some cholesterol. However, unbalanced
                                    cholesterol
                                    might result in artery plaque accumulation and future difficulties. Because of this,
                                    it's
                                    crucial to work with your doctor to keep your cholesterol levels within the usual
                                    range.</p>
                                <p>Do not ignore your health as it may affect your body badly. Book your Ayurvedic
                                    doctor online
                                    consultation with Dr. Axico and you can buy Ayurvedic medicine online or offline
                                    too.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How to lower cholesterol levels at
                                        home?</strong></h4>
                                        <p>Best ayurvedic medicine to lower cholesterol levels at home can be achieved through
                                            various
                                            lifestyle modifications and dietary changes. Here are some strategies that may
                                            help:</p>
                                        <ol>
                                            <li>Adopt a Healthy Diet:
                                                <ul style="list-style-type: disc;">
                                                    <li>Choose heart-healthy fats: Opt for unsaturated fats found in foods like
                                                        olive
                                                        oil, avocados, nuts, and seeds. Limit saturated and trans fats found in
                                                        fatty
                                                        meats, full-fat dairy products, and processed foods.
                                                    </li>
                                                    <li>Increase fiber intake: Include soluble fiber-rich foods like oats,
                                                        barley,
                                                        legumes, fruits, and vegetables. Soluble fiber helps reduce LDL
                                                        cholesterol
                                                        levels.
                                                    </li>
                                                    <li>Consume omega-3 fatty acids: Eat fatty fish like salmon, mackerel, and
                                                        sardines,
                                                        which are rich in omega-3 fatty acids. Alternatively, consider adding
                                                        flaxseeds,
                                                        chia seeds, and walnuts to your diet.
                                                    </li>
                                                    <li>Limit cholesterol-rich foods: Reduce the intake of cholesterol-rich
                                                        foods like
                                                        organ meats, shellfish, and egg yolks.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Engage in Regular Physical Activity:
                                                <ul style="list-style-type: disc;">
                                                    <li>Incorporate aerobic exercises such as brisk walking, cycling, swimming,
                                                        or
                                                        jogging into your routine. Aim for at least 150 minutes of
                                                        moderate-intensity
                                                        exercise or 75 minutes of vigorous exercise per week.
                                                    </li>
                                                    <li>Include strength training exercises to build muscle mass, as they can
                                                        help
                                                        improve cholesterol levels.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Maintain a Healthy Weight:
                                                <ul style="list-style-type: disc;">
                                                    <li>Achieve and maintain a healthy body weight through a balanced diet and
                                                        regular
                                                        exercise. Losing excess weight can help improve cholesterol levels.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Quit Smoking:
                                                <ul style="list-style-type: disc;">
                                                    <li>Smoking can lower HDL (good) cholesterol and damage blood vessels.
                                                        Quitting
                                                        smoking can have a positive impact on cholesterol levels and overall
                                                        health.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Limit Alcohol Consumption:
                                                <ul style="list-style-type: disc;">
                                                    <li>Excessive alcohol consumption can raise cholesterol levels. If you drink
                                                        alcohol, do so in moderation. Women should limit to one drink per day,
                                                        and men
                                                        should limit to two drinks per day.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Manage Stress:
                                                <ul style="list-style-type: disc;">
                                                    <li>Chronic stress can affect cholesterol levels. Incorporate
                                                        stress-management
                                                        techniques like yoga, meditation, deep breathing exercises, or engaging
                                                        in
                                                        hobbies to reduce stress levels.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <h4><strong>What are healthy cholesterol levels by age?</strong></h4>
                                        <p>Healthy cholesterol levels can vary slightly depending on age and other risk factors.
                                            The
                                            following are general guidelines for healthy cholesterol levels based on age:</p>
                                        <p>For adults aged 20 years and older:</p>
                                        <ul>
                                            <li>Total cholesterol: Less than 200 mg/dL (milligrams per deciliter)</li>
                                            <li>LDL cholesterol (bad cholesterol): Less than 100 mg/dL</li>
                                            <li>HDL cholesterol (good cholesterol): 60 mg/dL or higher</li>
                                            <li>Triglycerides: Less than 150 mg/dL</li>
                                        </ul>
                                        <h4><strong>Does desi ghee increase cholesterol levels?</strong></h4>
                                        <p>Desi ghee, usually referred to as clarified butter, is a typical component in Indian
                                            cooking.
                                            Despite being a source of saturated fat, desi ghee may not have the same negative
                                            impact on
                                            cholesterol levels as previously thought, according to studies.</p>
                                        <p>Saturated fats, like the kind in desi ghee, have long been linked to higher levels of
                                            LDL
                                            (bad) cholesterol. Recent research has refuted this idea, demonstrating that there
                                            is a more
                                            nuanced connection between saturated fats and cholesterol levels.</p>
                                        <h4><strong>What reduces cholesterol quickly?</strong></h4>
                                        <p>Cholesterol reduction needs long-term lifestyle modifications. However, these methods
                                            may
                                            decrease cholesterol faster:</p>
                                        <p><strong>Eat Heart-Healthy:</strong></p>
                                        <ol>
                                            <li>Reduce saturated and trans fats: Avoid fatty meats, full-fat dairy, and
                                                processed meals.
                                                Avoid trans fats in commercially baked and fried foods.
                                            </li>
                                            <li>Eat soluble fiber-rich foods including oats, barley, legumes, fruits, and
                                                vegetables.
                                                Soluble fiber lowers LDL cholesterol.
                                            </li>
                                            <li>Eat omega-3s: Eat fatty fish like salmon, mackerel, and sardines or omega-3-rich
                                                flaxseeds, chia seeds, and walnuts.
                                            </li>
                                            <li>Healthy cooking oils: Use olive or canola oil for cooking and salad dressing.
                                            </li>
                                            <li>Exercise regularly: Get 150 minutes of moderate-intensity aerobic activity or 75
                                                minutes
                                                of strenuous exercise every week. Exercise raises HDL (good) cholesterol and
                                                lowers LDL.
                                            </li>
                                            <li>Stay Fit: Weight loss may lower cholesterol. To lose weight, eat well and
                                                exercise.
                                            </li>
                                            <li>Stop Smoking: Smoking lowers HDL and damages blood arteries. Quitting smoking
                                                improves
                                                cholesterol and cardiovascular health.
                                            </li>
                                            <li>Limit Alcohol: Alcohol increases cholesterol. Drink alcohol moderately. Men may
                                                have two
                                                drinks each day, women one.
                                            </li>
                                        </ol>
                                        <h4><strong>Are eggs bad for cholesterol?</strong></h4>
                                        <p>Eggs and cholesterol have been debated and studied. Eggs provide protein, vitamins,
                                            and
                                            minerals. Egg yolks contain cholesterol.</p>
                                        <p>Egg cholesterol was thought to directly affect blood cholesterol levels. Dietary
                                            cholesterol
                                            affects blood cholesterol levels less than saturated and trans fats, according to
                                            current
                                            studies.</p>
                                        <p>The American Heart Association and Dietary Guidelines for Americans no longer limit
                                            cholesterol consumption. Instead, they emphasize lowering saturated and trans fats
                                            and
                                            eating more fruits, vegetables, whole grains, and lean meats.</p>
                                        <p>Most healthy people may safely eat 1-2 eggs each day without affecting their
                                            cholesterol
                                            levels. However, health issues, a family history of heart disease, and nutrition
                                            should be
                                            considered. Eggs may be too high in cholesterol for certain people, especially those
                                            with
                                            diabetes or hereditary disorders.</p>
                                        <h4><strong>What are the warning signs of cholesterol?</strong></h4>
                                        <p>High cholesterol itself does not typically cause specific warning signs or symptoms.
                                            It is
                                            often referred to as a "silent" condition because it doesn't produce noticeable
                                            symptoms in
                                            the early stages. However, high cholesterol levels can contribute to the development
                                            of
                                            cardiovascular diseases over time, which can have warning signs. These signs and
                                            symptoms
                                            may include:</p>
                                        <ol>
                                            <li>Chest pain or discomfort (angina): This may be experienced as pressure,
                                                squeezing, or
                                                tightness in the chest. It can occur during physical activity or stress and is
                                                often
                                                relieved with rest.
                                            </li>
                                            <li>Shortness of breath: Difficulty breathing or shortness of breath can occur
                                                during
                                                physical activity or even at rest. It may be a sign that the heart is not
                                                receiving
                                                enough oxygen-rich blood.
                                            </li>
                                            <li>Heart attack: A heart attack occurs when blood flow to a part of the heart is
                                                blocked,
                                                usually by a blood clot. Symptoms can include chest pain or discomfort,
                                                shortness of
                                                breath, pain or discomfort in the arms, back, neck, jaw, or stomach, and a
                                                feeling of
                                                light-headedness or fainting.
                                            </li>
                                            <li>Stroke: A stroke occurs when blood flow to the brain is interrupted or reduced.
                                                Warning
                                                signs may include sudden weakness or numbness in the face, arm, or leg
                                                (especially on
                                                one side of the body), confusion, trouble speaking or understanding speech,
                                                sudden
                                                severe headache, difficulty walking, dizziness, or loss of balance.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'appendicitis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Appendix: Causes, Symptoms & Treatment Without Operation | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body">
                                <p>Appendicitis is a medical disease in which the appendix gets swollen, usually because
                                    of
                                    a
                                    blockage or infection. The appendix is a small organ in the lower right side of the
                                    abdomen.
                                    When it gets swollen, it can cause rapid, severe pain in the lower right side of the
                                    abdomen, fever, nausea, vomiting, and loss of appetite.&nbsp;<a
                                        href="https://www.draxico.com">Book your free Ayurvedic doctor online
                                        consultation</a>
                                    now and get relief from Appendix.&nbsp;</p>
                                <p>If the swollen appendix isn't addressed, it can burst, which can lead to a dangerous
                                    infection called peritonitis. Most of the time, the appendix is removed surgically,
                                    which is
                                    called an appendectomy. To avoid problems, it's important to find and treat
                                    appendicitis
                                    quickly. Here, we have highlighted the appendicitis causes, symptoms and
                                    treatment.</p>
                                <h3><strong>What is Appendix Pain?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/appendicitis/Image-2.webp"
                                    >
                                </div>
                                <p>Pain in the appendix is a special form of abdominal pain that is brought on by an
                                    inflammation of the appendix or an infection of the appendix.</p>
                                <p>The discomfort is most frequently localised to the lower right side of the abdominal
                                    region.</p>
                                <p>The discomfort could begin as a dull aching at the belly button, and then it might
                                    travel
                                    to
                                    the lower right side of the abdomen.</p>
                                <p>The discomfort may become strong and piercing, and it may get much more painful if
                                    you
                                    move,&nbsp;<a
                                        href="https://www.draxico.com/diseases/cough">cough</a>, or take big breaths.
                                </p>
                                <p>In addition to the pain, appendicitis can cause a sensation of malaise, nausea,
                                    vomiting,
                                    fever, loss of appetite, and an overall sense of being unwell.</p><h4><strong>What
                                is
                                Acute
                                and Chronic Appendicitis?</strong></h4>
                                <p>Appendicitis is a medical condition characterised by inflammation of the appendix.
                                    Acute
                                    and
                                    chronic appendicitis are the two primary forms of appendicitis. Here are the
                                    pertinent
                                    details about each:</p><h4><strong>Acute appendicitis:</strong>&nbsp;</h4>
                                <p>The most common form of appendicitis, acute appendicitis occurs when the appendix
                                    becomes
                                    inflamed suddenly and rapidly.</p>
                                <ul>
                                    <li>It is frequently caused by an obstruction in the appendix, which can contribute
                                        to
                                        bacterial growth and infection.
                                    </li>
                                    <li>Acute appendicitis is characterized by sudden, severe abdominal pain on the
                                        lower
                                        right
                                        side, fever, nausea, vomiting, loss of appetite, and a general sense of malaise.
                                    </li>
                                </ul>
                                <h4><strong>Chronic Appendicitis:</strong></h4>
                                <ul>
                                    <li>Chronic appendicitis is a less common form of appendicitis that occurs when the
                                        appendix
                                        is inflamed for a prolonged period, typically weeks or months.
                                    </li>
                                    <li>It may not always manifest with the classic symptoms of acute appendicitis but
                                        may
                                        cause
                                        broader symptoms such as abdominal pain, bloating, constipation, or diarrhoea.
                                    </li>
                                    <li>Chronic appendicitis may be difficult to diagnose, as it may not be initially
                                        suspected
                                        or detected by diagnostic imaging.
                                    </li>
                                </ul>
                                <p>If you are feeling any of these symptoms you can take a&nbsp;<a
                                    href="https://www.draxico.com/">free online and offline consultation with our
                                    doctor</a>.
                                </p><h4><strong>What are the main factors that cause Appendicitis?</strong></h4>

                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/appendicitis/Image-1.webp"
                                    >
                                </div>
                                <p>Although the precise origin of appendicitis is not always known, it is thought to
                                    start
                                    when
                                    the appendix gets infected and obstructed. Several of the major causes of
                                    appendicitis
                                    development are listed below:</p>
                                <ol>
                                    <li><strong>Appendix blockage:</strong> Stool, foreign objects, or a mucus build-up
                                        can
                                        all
                                        block the appendix's entrance, which frequently results in appendicitis.
                                    </li>
                                    <li><strong>Infection:</strong> If the appendix is obstructed, germs can grow there
                                        and
                                        lead
                                        to an infection, which in turn causes inflammation.
                                    </li>
                                    <li><strong>Hereditary:</strong> Appendicitis may have a hereditary component to its
                                        development since it may run in some families more frequently, according to some
                                        research.
                                    </li>
                                    <li><strong>Diet:</strong> An appendicitis risk-raising diet is heavy in processed
                                        foods
                                        and
                                        poor in fibre.
                                    </li>
                                    <li><strong>Age:</strong> Although appendicitis can strike anybody at any age, it
                                        most
                                        frequently affects persons between the ages of 10 and 30.
                                    </li>
                                    <li><strong>Gender:</strong> Men may have a little higher risk of appendicitis than
                                        women.
                                    </li>
                                    <li><strong>Medical conditions:</strong> Having&nbsp;<a
                                        href="https://en.wikipedia.org/wiki/Crohn%27s_disease" rel="nofollow">Crohn's
                                        disease</a>, for example,
                                        may make you more likely to get appendicitis.
                                    </li>
                                </ol>
                                <h4><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h4>
                                <p>The early indications and bodily changes depend on the ailment or cause. However,
                                    these
                                    common early indicators and changes may signal a health issue:</p>
                                <ul>
                                    <li><strong>Fatigue:</strong> Even after resting, feeling exhausted might indicate
                                        infections, hormone imbalances, or chronic diseases.
                                    </li>
                                    <li><strong>Changes in appetite or weight:</strong> Loss of appetite, increased
                                        desires,
                                        or
                                        unexplained weight loss or gain may indicate health issues.
                                    </li>
                                    <li><strong>Digestive diseases:</strong> Dietary intolerances and&nbsp;<a
                                        href="https://www.draxico.com/diseases/gastroenteritis">gastrointestinal
                                        infections</a>
                                        may cause bloating, gas, indigestion, stomach discomfort, diarrhoea, and
                                        constipation.
                                    </li>
                                    <li><strong>Skin changes:</strong> Rashes, itching, dryness, redness, and
                                        discoloration
                                        might indicate allergies, dermatological problems, or systemic illnesses.
                                    </li>
                                    <li><strong>Mood changes:</strong> unexpected mood swings, impatience, anxiety,
                                        despair,
                                        or
                                        sleep disorders may indicate psychological problems, hormone imbalances, or
                                        neurological
                                        issues.
                                    </li>
                                    <li><strong>Urination changes:</strong> Frequent urination, increased thirst, black
                                        urine,
                                        or trouble urinating may indicate kidney, urinary tract, or metabolic
                                        abnormalities.
                                    </li>
                                    <li><strong>Pain or discomfort:</strong>&nbsp;<a
                                        href="https://www.draxico.com/diseases/headaches">Headaches</a>,&nbsp;<a
                                        href="https://www.draxico.com/diseases/arthritis">joint pain</a>, muscular
                                        pains,
                                        and&nbsp;<a
                                            href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">stomach
                                            pain</a>
                                        may indicate underlying disorders.
                                    </li>
                                    <li><strong>Changes in the menstrual cycle:</strong> Hormonal imbalances,
                                        gynaecological
                                        diseases, and other health concerns might cause irregular periods, flow, or
                                        symptoms.
                                    </li>
                                    <li><strong>Changes in cognition or memory:</strong> Difficulty focusing, memory
                                        issues,
                                        disorientation, or cognitive decline may indicate neurological or other systemic
                                        illnesses.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Appendicitis?</strong></h4>
                                <p>Inflamed and infected appendicitis is a medical emergency. Untreated appendicitis can
                                    cause
                                    life-threatening complications. Thus, appendicitis must be treated quickly.</p><h4>
                                <strong>Appendicitis
                                    symptoms:</strong></h4>
                                <ul>
                                    <li>Lower right abdominal pain starts mild and escalates.</li>
                                    <li>Appetite loss</li>
                                    <li>Throwing up</li>
                                    <li>Bowel issues</li>
                                    <li>Swollen stomach</li>
                                    <li>Low fever</li>
                                </ul>
                                <p>These symptoms require immediate medical intervention. To diagnose appendicitis, your
                                    doctor
                                    will examine you, evaluate your medical history, and request blood, urine, or
                                    imaging
                                    tests.</p>
                                <p>If diagnosed, the inflamed appendix is removed surgically. Antibiotics may treat the
                                    infection.</p>
                                <h3><strong>Ayurvedic Say on Appendicitis</strong></h3>
                                <p>Ayurveda views appendicitis as a pitta dosha imbalance, which affects digestion and
                                    metabolism. Appendicitis can result from intestinal toxicity, according to Ayurveda.&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic appendicitis therapies</a> reduce
                                    inflammation
                                    and eliminate impurities.&nbsp;</p>
                                <p>Fasting, ginger, turmeric, <a href="https://www.draxico.com/panchakarma-treatment/">Panchakarma
                                    cleansing</a>, and an Ayurvedic diet may be used.
                                    Ayurvedic natural remedies for appendicitis should be utilised with conventional
                                    medical
                                    therapy under the supervision of a certified practitioner. Delaying or postponing
                                    appendix
                                    treatment might cause catastrophic consequences.</p><h4><strong>Ayurvedic Say on
                                Appendicitis Diagnosis</strong></h4>
                                <p>Ayurveda diagnosis appendicitis by examining the patient's symptoms, medical history,
                                    and
                                    constitution, especially dosha type. Indigestion, gas, and bloating indicate
                                    digestive
                                    system inflammation and imbalance to the Ayurvedic practitioner. To check for
                                    soreness,
                                    swelling, and discomfort, they may also examine the abdomen.</p>
                                <p>Hetu (causative factor): According to Ayurveda, the fundamental cause of appendicitis
                                    is
                                    a
                                    buildup of toxins (ama) in the colon, which can cause inflammation and infection of
                                    the
                                    appendix.</p>
                                <p>Purva Roopa of appendicitis is Indigestion, gas, bloating, and constipation, as well
                                    as
                                    pain
                                    in the lower right abdomen, which are all premonitory signs of appendicitis in
                                    Ayurveda.</p>
                                <p>The pathogenesis of appendicitis, according to Ayurveda, includes a buildup of toxins
                                    in
                                    the
                                    colon, which causes inflammation and infection of the appendix.</p>
                                <p>The appendix Ayurvedic treatment generally consists of lowering inflammation,
                                    eliminating
                                    toxins from the body, and restoring digestive system equilibrium. Fasting, herbal
                                    medicines,
                                    Panchakarma detoxification therapy, and adhering to an Ayurvedic diet may be
                                    included.</p>
                                <h4><strong>How Ayurveda Can Help in Appendicitis Pain Treatment?</strong></h4>
                                <p>Appendicitis is a serious medical problem that needs to be taken care of right away.
                                    The
                                    traditional Indian system of medicine, called Ayurveda, can be used to supplement
                                    standard
                                    medical care, but it is not suggested as an appendicitis ayurvedic treatment on its
                                    own.
                                    If
                                    you think you might have appendicitis, you should see a doctor right away.</p><h4>
                                <strong>Here
                                    is an Ayurvedic method of care for appendicitis that can be used along with
                                    traditional
                                    medical
                                    care:</strong></h4>
                                <ul>
                                    <li>Nourishing Diet: Eating foods that are good for you can help the body heal
                                        itself.
                                        Avoid
                                        foods that can make inflammation worse, like those that are hot, oily, or
                                        processed.
                                        Eat
                                        foods that are easy to break down, like warmed rice, soups, and boiled veggies.
                                        Drink
                                        warm drinks, like warm water with lemon or ginger tea.
                                    </li>
                                    <li>Ayurvedic Supplements: Some Ayurvedic supplements can help boost the immune
                                        system
                                        and
                                        prevent inflammation.&nbsp;<a
                                            href="https://www.draxico.com/products/triphaladi-guggulu">Triphala</a>,&nbsp;<a
                                            href="https://www.draxico.com/products/sudh-ashwagandha-capsule">Ashwagandha</a>,
                                        and&nbsp;<a
                                            href="https://www.draxico.com/products/haldi-tulsi-plus">Turmeric</a>,
                                        for
                                        example, can be used to help digestion and lower congestion. But before taking
                                        any
                                        vitamins, it's important to talk to a trained Ayurvedic practitioner.
                                    </li>
                                    <li>Rest: The body needs to rest to heal from appendicitis. Don't do anything too
                                        hard,
                                        and
                                        get a lot of rest to help your body heal.
                                    </li>
                                    <li>Yoga and Pranayama: Yoga and Pranayama can help you feel better generally by
                                        reducing
                                        stress and making it easier to digest food. But it's important to do yoga and
                                        breathing
                                        with the help of an experienced yoga teacher.
                                    </li>
                                </ul>
                                <h4><strong>Ayurvedic Remedies for Appendicitis</strong></h4>
                                <ul>
                                    <li>Ginger is a potent anti-inflammatory herb that can help reduce
                                        appendicitis-related
                                        inflammation and discomfort. Ginger can be consumed in the form of tea or as a
                                        supplement.
                                    </li>
                                    <li>Triphala is a traditional Ayurvedic formula comprising three fruits that aid in
                                        digestion and detoxification. It can aid in relieving constipation and
                                        stimulating
                                        bowel
                                        movements.
                                    </li>
                                    <li>Castor Oil Castor oil can be administered topically to the lower right abdomen
                                        to
                                        reduce
                                        inflammation and discomfort caused by appendicitis.
                                    </li>
                                    <li>Turmeric is a powerful anti-inflammatory herb that can reduce inflammation and
                                        discomfort. You can drink turmeric-infused tea or take turmeric supplements.
                                    </li>
                                    <li>Aloe vera is renowned for its anti-inflammatory and therapeutic properties. It
                                        can
                                        be
                                        ingested as a beverage or applied to the lower right abdomen topically.
                                    </li>
                                    <li>Fennel is a natural digestive aid that can help relieve indigestion and
                                        flatulence.
                                        You
                                        may eat fennel seeds or ingest fennel tea.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not</strong></h4><h4><strong>What to eat:</strong></h4>
                                <p>Appendicitis requires a mild, easy-to-digest diet. Eat this and avoid this:</p>
                                <ul>
                                    <li>Clear Liquids: Water, herbal tea, and broth are hydrating and nutrient-rich.
                                    </li>
                                    <li>Boiled veggies like carrots, beets, and potatoes are nutritious and simple to
                                        digest.
                                    </li>
                                    <li>Soups: Vegetable-broth soups are nutritious and simple to digest.</li>
                                    <li>Soft Foods: Boiled eggs, yoghurt, and bananas are easy on the stomach and give
                                        nutrients.
                                    </li>
                                    <li>Whole Grains: Brown rice, quinoa, and oats give fibre and minerals.</li>
                                </ul>
                                <h4><strong>What not to eat:</strong></h4>
                                <ul>
                                    <li>Spicy meals irritate the digestive tract and increase inflammation.</li>
                                    <li>Fried meals can cause inflammation and digestion issues.</li>
                                    <li>Dairy Products: Dairy can cause inflammation and digestion issues.</li>
                                    <li>Processed Foods: Additives and preservatives can irritate the digestive tract
                                        and
                                        aggravate inflammation.
                                    </li>
                                    <li>Caffeine and alcohol dehydrate and exacerbate inflammation.</li>
                                    <li>Avoid fizzy drinks: Gas and bloating can worsen inflammation.</li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Appendicitis?</strong>
                                </h4>
                                <ol>
                                    <li><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala
                                        Guggul</a>:
                                        Triphala Guggul is a combination of Triphala, a blend of three fruits, and
                                        Guggul, a
                                        resin with anti-inflammatory properties. It can help in supporting digestion,
                                        reducing
                                        inflammation, and promoting overall healing.
                                    </li>
                                    <li>Kutki (Picrorhiza kurroa) churna: Kutki is known for its hepatoprotective
                                        properties
                                        and
                                        is commonly used in Ayurveda to support liver health. It can help in reducing
                                        inflammation and promoting detoxification.
                                    </li>
                                    <li><a href="http://draxico.com/products/arogyavardhini-vati">Arogyavardhini
                                        Vati</a>:
                                        Arogyavardhini Vati is an Ayurvedic formulation that contains several herbs and
                                        minerals. It is believed to support liver function, improve digestion, and
                                        promote
                                        overall well-being.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/chandraprabha-vati">Chandraprabha
                                        Vati</a>:
                                        Chandraprabha Vati is a classical Ayurvedic formulation used for various
                                        conditions.
                                        It
                                        can help in supporting the urinary system, reduce inflammation, and promote
                                        overall
                                        healing.
                                    </li>
                                    <li>Amalaki (Emblica officinalis): Amalaki, also known as Indian gooseberry, is rich
                                        in
                                        antioxidants and has anti-inflammatory properties. It can be beneficial in
                                        supporting
                                        digestion, reducing inflammation, and promoting overall health.
                                    </li>
                                </ol>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>It's important to remember that Ayurvedic medicine for appendixes should be taken
                                    under
                                    the
                                    guidance and supervision of a qualified Ayurvedic practitioner. They can evaluate
                                    your
                                    specific condition, dosha imbalances, and overall health to provide appropriate
                                    recommendations and ensure the appendicitis Ayurvedic medicine is safe and effective
                                    for
                                    you.</p>
                                <p>Always consult with both Ayurvedic and conventional medical professionals for a
                                    comprehensive
                                    and integrated approach to your healthcare needs, especially in the case of acute
                                    conditions
                                    such as appendicitis. Book your free Ayurvedic doctor online consultation with Dr.
                                    Axcio
                                    and&nbsp;<a
                                        href="https://www.draxico.com/products/">buy Ayurvedic medicine online</a> as
                                    per
                                    your
                                    prescription.&nbsp;</p>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>What are the first signs of an
                                            appendix?</strong>
                                        </h4>
                                        <p>Appendicitis is the inflammation of the appendix, a small pouch-like structure
                                            located in
                                            the
                                            lower right abdomen. The symptoms of appendicitis can vary, but some common early
                                            signs
                                            and
                                            symptoms include:</p>
                                        <ol>
                                            <li>Abdominal pain: The pain typically starts around the navel (belly button) and
                                                then
                                                moves
                                                to the lower right side of the abdomen. The pain may gradually intensify and
                                                become
                                                constant.
                                            </li>
                                            <li>Loss of appetite: A person with appendicitis may experience a decreased desire
                                                to
                                                eat or
                                                a complete loss of appetite.
                                            </li>
                                            <li>Nausea and vomiting: These symptoms can occur in conjunction with abdominal
                                                pain.
                                            </li>
                                            <li>Fever: A low-grade fever (generally below 100.4°F or 38°C) may be present.</li>
                                            <li>Tenderness and rigidity: The abdomen may feel tender to touch, and there may be
                                                localized rigidity or stiffness in the abdominal muscles.
                                            </li>
                                            <li>Increased pain with movement: Activities such as coughing, sneezing, or moving
                                                may
                                                worsen the abdominal pain.
                                            </li>
                                        </ol>
                                        <h4><strong>What food can cause appendicitis?</strong></h4>
                                        <p>Foods seldom cause appendicitis. The appendix becomes obstructed, usually by a stool
                                            fragment, foreign substance, or enlarged lymph nodes. Blockage may cause appendix
                                            inflammation and infection.</p>
                                        <p>A balanced diet and lifestyle are vital for intestinal health, but particular foods
                                            don't
                                            cause appendicitis. This involves eating a balanced diet of fruits, vegetables,
                                            whole
                                            grains, lean meats, and healthy fats and keeping hydrated. Adequate fiber
                                            consumption
                                            may
                                            also encourage regular bowel movements and avoid constipation, which may minimize
                                            the
                                            chance
                                            of feces clogging the appendix.</p>
                                        <p>Appendicitis may develop sporadically and is not caused by diet. For appendicitis or
                                            stomach
                                            discomfort concerns, see a doctor. They can diagnose and treat your problems.</p>
                                        <h4><strong>Appendicitis pain location?</strong></h4>
                                        <p>Appendicitis discomfort begins at the navel and moves to the lower right side of the
                                            abdomen.
                                            Appendicitis causes pain migration. The lower right quadrant of the abdomen may
                                            experience
                                            increased discomfort.</p>
                                        <p>Sometimes the pain is vague and hard to localize. Pain at McBurney's point, the right
                                            lower
                                            abdomen, increases as inflammation advances. McBurney's point is two-thirds between
                                            the
                                            navel and the right hip bone. It's vital to remember that everyone's pain location
                                            and
                                            migratory pattern are different. Some people have unusual symptoms or abdominal
                                            discomfort.</p>
                                        <p>Seek medical assistance if you suspect appendicitis. To avoid complications like a
                                            burst
                                            appendix, appendicitis must be diagnosed and treated immediately. A doctor will
                                            diagnose
                                            stomach discomfort and treat it after a comprehensive assessment and physical.</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'acne'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Acne Problems with Ayurvedic Solution For Soothing Skin</h4>
                            </div>
                            <div class="card-body">
                                <p>Pimples are a highly prevalent symptom of the skin disorder acne. Your face will
                                    often
                                    develop pimples. Acne is caused by clogged pores. Acne is most common in teenage
                                    skin
                                    problems and young adults, but many adults also experience it. You can get ayurvedic
                                    treatment for acne on your skin and stop it from leaving scars. Acne problem
                                    solution is
                                    possible and available through&nbsp;<a href="https://www.draxico.com/">Ayurvedic
                                        doctor
                                        online consultation</a> easily.</p>
                                <h2>What is Acne?</h2>
                                <p>Acne is a common skin condition that is characterised by the appearance of zits,
                                    blackheads,
                                    whiteheads, and other blemishes. Acne occurs when the hair follicles get clogged
                                    with
                                    oil
                                    and dead skin cells. Acne may be caused by several factors, including but not
                                    limited to
                                    hormones, genetics, germs, and inflammation.&nbsp;</p>
                                <p>Because of hormones like testosterone, the skin may produce extra oil, which may clog
                                    the
                                    pores and lead to acne. There is some evidence that a person's genetic makeup may
                                    play a
                                    role in how prone they are to developing acne.</p>
                                <h3>Which is the Acne Impact Area?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/acne/Image.webp"
                                    >
                                </div>
                                <ul>
                                    <li>The face, neck, chest, back, and shoulders are the parts of the body that are
                                        most
                                        often
                                        affected by acne.
                                    </li>
                                    <li>Acne tends to occur in places that have a high concentration of hair follicles
                                        and
                                        oil
                                        glands.
                                    </li>
                                    <li>Acne problems on the face&nbsp;often manifests itself on the cheeks, the nose,
                                        and
                                        the
                                        forehead.
                                    </li>
                                    <li>It is possible for clothes or hair products to irritate the skin around the
                                        neck,
                                        which
                                        may lead to acne there.
                                    </li>
                                    <li>Acne on the chest and back can be especially difficult to treat.</li>
                                    <li>Shoulder acne may be caused by straps from backpacks or clothes that fit too
                                        snugly
                                        on
                                        the shoulders.
                                    </li>
                                </ul>
                                <h3>What are the main causes of Acne?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/acne/Image-2.webp"
                                    >
                                </div>
                                <p>Acne is a skin condition that is caused by a combination of factors, including:</p>
                                <ul>
                                    <li>Over Oil production: - Sebaceous glands in the skin produce an excessive amount
                                        of
                                        oil,
                                        it can clog the hair follicles and contribute to the development of acne.
                                    </li>
                                    <li>Bacteria: The skin contains bacteria naturally, but certain bacteria, such
                                        as&nbsp;<a
                                            href="https://journals.asm.org/doi/10.1128/JCM.02723-15#:~:text=Propionibacterium%20acnes%20is%20the%20most,clinical%20samples%20and%20surgical%20wounds.">Propionibacterium
                                            acnes</a>, can induce acne.
                                    </li>
                                    <li>Inflammation: When hair follicles become obstructed, it can cause inflammation
                                        in
                                        the
                                        skin, which can result in the development of zits, blackheads, and whiteheads.
                                    </li>
                                    <li>Hormones: Hormonal fluctuations, particularly during adolescence, can cause the
                                        sebaceous glands to produce more sebum, which can result in acne.
                                    </li>
                                    <li>Acne due to stomach problems &amp; Diet: - A diet high in sugar and refined
                                        carbohydrates may contribute to the development of acne, according to some
                                        studies.
                                    </li>
                                    <li>Stress: -Stress can cause hormonal changes in the body, which can result in acne
                                        development.
                                    </li>
                                    <li>Medication: Some medications, such as lithium, androgens, corticosteroids, and
                                        lithium,
                                        may hasten the onset of acne.
                                    </li>
                                    <li>Cosmetics: Some cosmetics can clog pores and lead to the development of acne,
                                        particularly those that are comedogenic or oil-based.
                                    </li>
                                    <li>Environmental factors: Exposure to certain environmental circumstances, such as
                                        pollution and high humidity, can lead to the development of pimples due to heat.
                                    </li>
                                </ul>
                                <h3>Does Acne Require Any Tests?
                                </h3>
                                <p>Most of the time, acne can be diagnosed without any special tests. A dermatologist
                                    can
                                    usually tell if someone has acne by looking at their face.</p>
                                <p>But if the acne is severe or out of the ordinary, a dermatologist may do more tests
                                    to
                                    rule
                                    out other face problems. These tests may include a skin biopsy, in which a small
                                    piece
                                    of
                                    skin is removed and looked at under a microscope, or blood tests to look for
                                    hormonal
                                    changes or other health problems that could be causing the acne.&nbsp;</p>
                                <p>It's important to remember that most cases of acne can be treated effectively with
                                    prescription ayurvedic medicine for pimples, so it's best to talk to an Ayurvedic
                                    doctor
                                    to
                                    figure out the best way to treat your acne based on its type and severity.</p>
                                <h2>What are the types of Acne?</h2>
                                <p>Acne comes in many forms. Common types include:</p>
                                <ul style="list-style-type: disc;">
                                    <li>Whiteheads: Closed, flesh-coloured or white pimples on the skin.</li>
                                    <li>Blackheads: Small, dark skin bumps. Oil and dead skin cells fill them.</li>
                                    <li>Papules: Small, red, raised bumps painful to touch.</li>
                                    <li>Pustules: Pus-filled papules.</li>
                                    <li>Nodules: Deep-seated, painful bumps.</li>
                                    <li>Cysts: Large, pus-filled lumps deep under the skin.</li>
                                </ul>
                                <h3>What is the difference between acne and pimple?
                                </h3>
                                <p>Acne is a skin condition that includes many types of blemishes.</p>
                                <p>Pimples are acne lesions and skin inflammations. Most pimples are on the face, neck,
                                    chest,
                                    and back. They start as little, red, sensitive pimples and may become whiteheads,
                                    blackheads, papules, pustules, nodules, or cysts as they get inflamed.</p>
                                <p>However, acne refers to a skin disorder that includes pimples and other lesions. Acne
                                    includes whiteheads, blackheads, nodules, and cysts. Hair follicles blocked with oil
                                    and
                                    dead skin cells cause inflammation and lesions in this chronic illness.</p>
                                <h2>Ayurvedic Say on Acnes and Risk Factors
                                </h2>
                                <p>An imbalance in the doshas, which can be thought of as the body's energy forces, is
                                    said
                                    to
                                    be the root cause of acne in Ayurvedic medicine. Acne is specifically linked to an
                                    excess of
                                    the Pitta dosha, which is responsible for controlling digestion and metabolism. Acne
                                    can
                                    be
                                    treated by balancing your doshas.</p>
                                <p>Alterations to a patient's diet, as well as their lifestyle and the use of various
                                    herbal
                                    medicines, are often included in an&nbsp;<a
                                        href="https://www.draxico.com/appointment">Ayurvedic
                                        treatment plan for acne</a>. Neem, turmeric, aloe vera, sandalwood, and tea tree
                                    oil
                                    are
                                    a few examples of the best ayurvedic medicine for pimples that are frequently
                                    recommended
                                    for acne.&nbsp;</p>
                                <p>In addition, in Ayurveda, there is a strong emphasis placed on the need of keeping a
                                    healthy
                                    lifestyle to help prevent and control acne. This includes getting enough sleep,
                                    learning
                                    how
                                    to manage stress, and developing good cleanliness practice.</p>
                                <h3> How Ayurveda Can Help in Acne Prevention?</h3>
                                <p>Ayurveda has many ways to avoid acne and treat it. Ayurveda can help in the following
                                    ways:</p>
                                <ul>
                                    <li>Diet: -Ayurveda recommends a diet that is balanced and tailored to the dosha
                                        type of
                                        the
                                        individual. In general, it's best to stay away from foods that are hot, fried,
                                        oily,
                                        and
                                        processed. Instead, try to eat whole, nutrient-dense foods like fresh fruits and
                                        veggies, whole grains, and lean protein sources.
                                    </li>
                                    <li>Lifestyle changes: Ayurveda stresses how important it is to live a healthy life
                                        to
                                        avoid
                                        acne. This means getting enough sleep, working out daily, dealing with stress,
                                        and
                                        taking care of your hygiene.
                                    </li>
                                    <li>Herbal treatments: Ayurveda has several herbal treatments for acne that can help
                                        avoid
                                        and treat it. Many people use neem, ginger, aloe vera, sandalwood, and tea tree
                                        oil
                                        as
                                        treatments.
                                    </li>
                                    <li>Detoxification: Ayurveda says that you should do it regularly to get rid of
                                        toxins
                                        in
                                        your body that can cause acne. This can be done with oil pulling, yoga, and
                                        meditation,
                                        among other things.
                                    </li>
                                    <li>Ayurvedic treatments: Ayurvedic facials and herbal steam baths can help clean
                                        the
                                        face
                                        and keep acne from happening.
                                    </li>
                                    <li>Mind-body practices: Ayurveda recognizes the connection between the mind and the
                                        body,
                                        and practices like yoga and meditation can help to reduce stress and improve
                                        general
                                        health and well-being.
                                    </li>
                                </ul>
                                <h3>Ayurvedic Remedies For Acne
                                </h3>
                                <p>Ayurveda has several natural Ayurvedic treatments for hormonal acne that can help
                                    stop
                                    breakouts and keep them from getting worse. Here are some popular acne &amp;
                                    whiteheads
                                    ayurvedic solution:</p>
                                <ul>
                                    <li>Neem: Neem is a strong plant that can kill both bacteria and fungi. It can help
                                        clear up
                                        acne and clean the face. Neem can be used as a face pack or as a supplement
                                        taken by
                                        mouth.
                                    </li>
                                    <li>Turmeric: Turmeric is a strong anti-inflammatory plant that can help reduce
                                        acne-related
                                        swelling and redness. You can put it on your face as a mask or take it as a
                                        vitamin.
                                    </li>
                                    <li>Aloe vera: Aloe vera is a soothing plant that can help reduce swelling and speed
                                        up
                                        the
                                        healing process. It can be put on the skin as a gel or cream, or it can be taken
                                        by
                                        mouth as a vitamin.
                                    </li>
                                    <li>Chandan: Sandalwood is a cooling plant that can help calm irritated skin and
                                        stop
                                        acne
                                        from happening. It can be put on the skin as a paste or in the form of a face
                                        pack.
                                    </li>
                                    <li>Tea tree oil: Tea tree oil is a strong antibiotic and antifungal oil that is
                                        termed
                                        a
                                        blackheads ayurvedic solution that can help clean the skin and stop acne. It can
                                        be
                                        put
                                        on the face directly or put in a facial stream.
                                    </li>
                                    <li>Guggulu: Guggulu is a resin that can help lower inflammation and rid the body of
                                        toxins.
                                    </li>
                                    <li>Manjistha: Manjistha is a herb that can help clean the blood and help the skin
                                        stay
                                        healthy.
                                    </li>
                                </ul>
                                <h3>What to Eat and Not
                                </h3>
                                <p>Acne is a complicated skin disease that can be caused by many different things. Even
                                    though
                                    nutrition is not a direct cause of acne, some foods can make it worse for some
                                    people.
                                    If
                                    you have pimples, here are some general rules about what to eat and what not to eat:
                                </p>
                                <h4>Things to eat:
                                </h4>
                                <ul>
                                    <li>Whole, nutrient-dense foods: A diet full of whole grains, fruits, vegetables,
                                        lean
                                        protein, and healthy fats can help improve general skin health and reduce
                                        inflammation.
                                    </li>
                                    <li>Vitamins: Vitamins A and C are important for healthy skin, and they can also
                                        help
                                        lower
                                        inflammation. Carrots, sweet potatoes, spinach, broccoli, oranges, and berries
                                        are
                                        all
                                        good places to get them.
                                    </li>
                                    <li>Zinc: Zinc is an important mineral for healthy skin, and it can also help
                                        prevent
                                        inflammation. Oysters, lean beef, chicken, beans, nuts, and whole grains are all
                                        good
                                        sources.
                                    </li>
                                    <li>Probiotics: Probiotics are good bacteria that can help improve the health of
                                        your
                                        gut
                                        and lower inflammation, which may be linked to acne.
                                    </li>
                                </ul>
                                <h4>What not to do:</h4>
                                <ul>
                                    <li>High-glycemic index foods: These foods can cause a spike in blood sugar and
                                        insulin
                                        levels, which can increase sebum production and inflammation, which can lead to
                                        acne.
                                        Sugary drinks, white bread, pasta, and rice are all examples.
                                    </li>
                                    <li>Some studies have found that dairy products, especially skim milk, might be
                                        linked
                                        to
                                        acne. It's not clear why this happens, but hormones or other things in milk
                                        could be
                                        to
                                        blame.
                                    </li>
                                    <li>Processed and fried foods: These foods are often high in&nbsp;<a
                                        href="https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/fats-and-cholesterol/#:~:text=%E2%80%9CBad%E2%80%9D%20fats%20%E2%80%94%20trans%20fats,from%20many%20of%20these%20foods."
                                        rel="nofollow">bad
                                        fats</a> and can cause inflammation, which can make acne worse.
                                    </li>
                                    <li>Iodine is an element that can be found in some foods and supplements. Some
                                        people
                                        who
                                        eat a lot of iodine get acne when they eat a lot of it. Seaweed, shrimp, and
                                        iodized
                                        salt are all foods that are high in iodine.
                                    </li>
                                </ul>
                                <h3>Recommended Ayurvedic Medication for Acne
                                </h3>
                                <ul>
                                    <li>Neem capsules or neem oil: Neem is a strong plant that can help treat acne
                                        because
                                        it
                                        kills bacteria and reduces inflammation. You can either take neem pills or put
                                        neem
                                        oil
                                        on the area directly.
                                    </li>
                                    <li>Turmeric capsules or paste: Turmeric is another herb that can help get rid of
                                        acne
                                        because it has anti-inflammatory qualities. You can take pills of turmeric or
                                        put a
                                        paste of turmeric on the area.
                                    </li>
                                    <li>Aloe vera gel: Aloe vera has healing and soothing qualities that can help reduce
                                        acne-related inflammation and redness. Fresh aloe vera juice should be put
                                        directly
                                        on
                                        the wound.
                                    </li>
                                    <li>Triphala capsules: Triphala is a mix of three fruits that are good for fighting
                                        germs
                                        and reducing inflammation. Taking Triphala capsules may help with digestion and
                                        lessen
                                        acne.
                                    </li>
                                    <li>Manjistha: Manjistha comes in the form of capsules or powder. Manjistha is a
                                        plant
                                        that
                                        helps clean the blood and get rid of toxins in the body. Taking pills or powder
                                        made
                                        from Manjistha may help clear up acne.
                                    </li>
                                </ul>
                                <h4>A Short Note from Dr. Axico
                                </h4>
                                <p>The most prevalent skin ailment, acne, can affect your mental well-being and sense of
                                    self.
                                    To get rid of obstinate acne, see a dermatologist or other healthcare professional.
                                    If
                                    at-home skin care regimens are unsuccessful, your acne may occasionally need a
                                    little
                                    extra
                                    assistance from medicine to clear up.&nbsp;</p>
                                <p>To avoid scarring, resist the urge to pick at your acne or pop pimples. Keep in mind
                                    that
                                    acne is transient and will go away with the proper skin-specific treatment. You can
                                    buy
                                    ayurvedic medicine online or offline from Acixo Ayurveda for better outcomes in
                                    addition
                                    to
                                    receiving the best ayurvedic treatment for acne.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4>What does acne look like
                                        </h4>
                                        <p>Acne includes pimples, blackheads, whiteheads, and occasionally cysts or nodules on
                                            the
                                            skin.&nbsp;
                                            Acne can have a variety of appearances. Raised lumps on the skin that are often red
                                            and
                                            inflammatory are known as pimples. They might have a pus-filled white, yellow, or
                                            orange
                                            centre.&nbsp;</p>
                                        <p>Whiteheads are similar to blackheads in that they are closed pores with a white or
                                            flesh-coloured bump on the surface, whereas blackheads are tiny, dark spots that
                                            form on
                                            the
                                            skin's surface. Nodules and cysts are larger, more painful pimples that can be
                                            highly
                                            inflammatory. They can leave scars after healing and are frequently pus-filled.</p>
                                        <h4>What causes acne on cheeks</h4>
                                        <p>There are several potential causes of acne on the cheeks. Hormonal changes, which can
                                            cause
                                            acne in women during menstruation or pregnancy or in teens going through puberty,
                                            are
                                            one of
                                            the most prevalent causes. Acne susceptibility and the location of acne on the face
                                            are
                                            both
                                            influenced by genetics. Additionally, unsound skin care practices might irritate the
                                            skin
                                            and cause outbreaks of acne.&nbsp;</p>
                                        <p>This includes using harsh skincare products or not washing the face correctly.
                                            Additionally,
                                            acne on the cheeks may result from a diet strong in processed foods, sweets, and
                                            dairy
                                            items. Environmental factors can also harm the skin and cause breakouts, such as
                                            exposure to
                                            pollution, UV radiation, and other chemicals.&nbsp;</p>
                                        <h4>How can I clear my acne naturally?</h4>
                                        <p>Clearing acne naturally involves maintaining good hygiene practices, managing stress,
                                            and
                                            using natural remedies such as tea tree oil, aloe vera, and zinc supplements.
                                            Drinking
                                            plenty of water and avoiding touching your face can also help prevent acne
                                            breakouts.
                                            However, if you have severe or persistent acne, it's best to consult a dermatologist
                                            for
                                            proper diagnosis and treatment options.
                                        </p>
                                        <h4>What foods cause acne
                                        </h4>
                                        <ul>
                                            <li>High-glycemic index foods: These foods can cause a spike in blood sugar and
                                                insulin
                                                levels, which can increase sebum production and inflammation, which can lead to
                                                acne.
                                                Sugary drinks, white bread, pasta, and rice are all examples.
                                            </li>
                                            <li>Some studies have found that dairy products, especially skim milk, might be
                                                linked
                                                to
                                                acne. It's not clear why this happens, but hormones or other things in milk
                                                could be
                                                to
                                                blame.
                                            </li>
                                            <li>Processed and fried foods: These foods are often high in bad fats and can cause
                                                inflammation, which can make acne worse.
                                            </li>
                                            <li>Iodine is an element that can be found in some foods and supplements. Some
                                                people
                                                who
                                                eat a lot of iodine get acne when they eat a lot of it. Seaweed, shrimp, and
                                                iodized
                                                salt are all foods that are high in iodine.
                                            </li>
                                        </ul>
                                        <h4> Can I pop my acne?
                                        </h4>
                                        <p>Popping or picking at acne is not advised since it can cause further inflammation,
                                            scarring,
                                            and infection. Squeezing or picking at a pimple can force its contents further into
                                            the
                                            skin, escalating inflammation and increasing the risk of scarring. Additionally,
                                            popping
                                            a
                                            pimple can cause an infection by introducing bacteria from your fingers into the
                                            affected
                                            area. It's ideal to let a pimple heal naturally.
                                        </p>
                                        <h4>Does stress cause acne?
                                        </h4>
                                        <p>Although not the only factor, stress can play a role in the onset of acne. Your body
                                            releases
                                            stress-related chemicals like cortisol and adrenaline, which can cause your skin's
                                            oil
                                            glands to generate extra oil. Acne can occur as a result of clogged pores caused by
                                            excessive oil production. Stress can also weaken your immune system, making it more
                                            difficult for your body to fight off microorganisms that cause acne. Stress can also
                                            make
                                            you more likely to touch your face or neglect proper hygiene habits, both of which
                                            can
                                            aggravate acne.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'asthma'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Asthma: Causes, Symptoms & Treatment in Ayurveda | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body">
                                <p>Asthma, often known as bronchial asthma, affects the lungs. This may make breathing
                                    challenging and cause coughing, wheezing when you exhale, and shortness of
                                    breath.</p>
                                <p>One of the most prevalent chronic inflammatory illnesses is asthma. It is a serious
                                    worldwide
                                    health issue, and prevalence rates are rising in many nations. According to the
                                    world
                                    health
                                    organization (who) estimates, 300 million people have asthma symptoms, and 2,55,000
                                    died
                                    of
                                    asthma in 2005.&nbsp;</p>
                                <p>Over 80% of asthma deaths occur in low- and lower-middle-income countries. Our
                                    Culture
                                    and
                                    heritage has a vast history and Ayurveda is among the top. Ayurveda would be helpful
                                    to
                                    live
                                    a healthy life,&nbsp;<a href="https://www.draxico.com/">book your Ayurvedic doctor
                                        online
                                        consultation</a> now to fight such chronic diseases.&nbsp;</p>
                                <p>It has previously been estimated that the prevalence of asthma in India is about 3%
                                    (30
                                    million patients). With a prevalence of 2.4% asthma symptoms in adults aged &gt;15
                                    years
                                    and
                                    between 4% and 20% in children. Asthma was predicted to be the cause of 57,000
                                    fatalities in
                                    India in 2004; it is one of the major causes of illness and mortality in rural
                                    India,
                                    and
                                    its prevalence is expected to rise over the next several decades. Public health is
                                    seriously
                                    hampered by asthma.</p>
                                <h2>What is asthma?
                                </h2>
                                <p>An inflammatory condition affecting the lungs&apos; airways is asthma. Understanding
                                    the
                                    mechanics of breathing will help you better comprehend asthma. In a typical
                                    breathing
                                    pattern, air passes by your nose or mouth, descends through your throat, and then
                                    travels
                                    through your airways until it reaches your lungs.&nbsp;</p>
                                <p>Numerous microscopic airways found in your lungs help transport oxygen from the
                                    outside
                                    air
                                    to your bloodstream. When the muscles surrounding your airways constrict and the
                                    lining
                                    of
                                    your airway swells, allergic asthma symptoms develop.</p>
                                <h3>What is an asthma attack?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/asthma/Image.webp"
                                    >
                                </div>
                                <p>Before an asthma attack, you could experience minor symptoms. These include:</p>
                                <ul>
                                    <li>
                                        <p>Feeling tired</p>
                                    </li>
                                    <li>
                                        <p>Tiring easily, especially with exertion</p>
                                    </li>
                                    <li>
                                        <p>symptoms of allergies or a cold, such as nasal congestion, a tickling in the
                                            throat,
                                            or a runny&nbsp;</p>
                                    </li>
                                </ul>
                                <h4>Common asthma attack symptoms include</h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/asthma/Image-2.webp"
                                    >
                                </div>
                                <ul>
                                    <li>
                                        <p>Shortness of breath</p>
                                    </li>
                                    <li>
                                        <p>Chest tightening</p>
                                    </li>
                                    <li>
                                        <p><a href="https://www.draxico.com/diseases/cough">Coughing</a> or wheezing</p>
                                    </li>
                                    <li>
                                        <p>Difficulty talking</p>
                                    </li>
                                </ul>
                                <h4>Asthma attack scale
                                </h4>

                                <ul>
                                    <li>
                                        <p>Mild: Mild sob with walking. Can talk normally. Speaks in sentences. Can lay
                                            down flat. Wheezes not heard or very mild.</p>
                                    </li>
                                    <li>
                                        <p>Moderate:&nbsp;sobbing at rest. Speaks in phrases. I Want to sit (can&apos;t
                                            lay down flat). Wheezing can be heard and retractions can be felt (ribs pull
                                            in with each breath).</p>
                                    </li>
                                    <li>
                                        <p>Severe:&nbsp;At repose, a severe sob. speaks in simple sentences. breathing
                                            difficulty. Some people wheeze loudly. Rarely, wheezing is absent because
                                            the airflow is inadequate. There may be severe retractions. A severe asthma
                                            episode may be fatal and necessitate immediate medical attention.</p>
                                    </li>
                                    If you are facing any of the above symptoms you can consult with an ayurvedic doctor
                                    by&nbsp;<a href="https://www.draxico.com/appointment">clicking here</a>.
                                </ul>
                                <h2> Types of asthma
                                </h2>
                                There are many forms of asthma. Asthma is defined by medical professionals as:
                                <ul>
                                    <li>
                                        <p>Intermittent:&nbsp;This form of asthma comes and goes, allowing you to
                                            experience regular health between asthma attacks.</p>
                                    </li>
                                    <li>
                                        <p>Persistent:&nbsp;If you have persistent asthma, you experience symptoms
                                            frequently. Symptoms might range from moderate to severe. On the frequency
                                            of your symptoms, medical professionals determine how severe your asthma
                                            is.</p>
                                    </li>
                                    They take into account your ability to function during an attack as well.
                                </ul>
                                <h3>Based on causes it can be classified into 2 types-</h3>
                                <ul>
                                    <li>
                                        <p>Allergic asthma symptoms:&nbsp;&nbsp;allergens include things like molds,
                                            pollens,
                                            pet dander, etc. These allergens may induce Asthma attacks.</p>
                                    </li>
                                    <li>
                                        <p>Non-allergic:&nbsp;Conditions such as exercise, stress, illness, and the
                                            weather
                                            can
                                            trigger an&nbsp;<a
                                                href="https://www.draxico.com/blogs/asthma-signs-causes-ayurvedic-treatment-and-home-remedies-dr-aaxico">asthma
                                                attack</a>.</p>
                                    </li>
                                </ul>
                                <h3>Based on the age of onset, it can be classified into 2 types-</h3>
                                <ul>
                                    <li>
                                        <p>Adult-onset:&nbsp;After the age of 18, this form of asthma develops.</p>
                                    </li>
                                    <li>
                                        <p>Pediatric: sometimes known as pediatric asthma, this form of the disease can
                                            affect
                                            newborns and toddlers and frequently develops before the age of five. By the
                                            time
                                            they reach their teens, many kids seem to &quot;grow out of asthma,&quot;
                                            but
                                            they
                                            might develop it again in the future</p>
                                    </li>
                                </ul>
                                <h4>In addition, based on causative factors it could be of further 3 types-
                                </h4>

                                <ul>
                                    <li>
                                        <p>Exercise-&nbsp;A type of asthma called exercise-induced asthma, sometimes
                                            referred to
                                            as exercise-induced bronchospasm, is triggered by physical activity.</p>
                                    </li>
                                    <li>
                                        <p>Occupational asthma-&nbsp;People who work near irritants are more likely to
                                            develop
                                            this type of asthma.</p>
                                    </li>
                                    <li>Asthma-COPD overlap syndrome (ACOS)- This condition develops when a person has
                                        both
                                        chronic
                                        obstructive pulmonary disease (COPD) and asthma. Breathing becomes challenging
                                        in
                                        both
                                        conditions.
                                    </li>
                                </ul>
                                <h2>Who can get asthma?
                                </h2>
                                <p>The given below are some major risk factors making a person more prone to asthma-</p>

                                <ul>
                                    <li>
                                        <p>Family history &ndash; Those whose parents are Asthma positive have a chance
                                            to
                                            develop these diseases 3-4 times more than others.</p>
                                    </li>
                                    <li>
                                        <p>Allergies- Due to a weak immune system, some people get affected by&nbsp;<a
                                            href="https://www.draxico.com/diseases/allergies">allergies</a> easily.
                                            Atopic
                                            dermatitis (<a href="https://www.draxico.com/diseases/eczema">eczema</a>)
                                            and
                                            allergic rhinitis (hay fever), two allergic diseases, are more prevalent in
                                            people
                                            who develop asthma.</p>
                                    </li>
                                    <li>
                                        <p>Viral respiratory- Wheezing can be brought on by viral respiratory infections
                                            and
                                            respiratory issues in a baby or young child. In children, Viral respiratory
                                            infections can lead to the development of persistent asthma.</p>
                                    </li>
                                    <li>
                                        <p>Occupational exposures- If you have asthma, being exposed to certain
                                            substances
                                            at
                                            work may aggravate your condition. Additionally, for some individuals,
                                            exposure
                                            to
                                            specific dust (industrial or wood dust), chemical fumes and vapors, and
                                            molds
                                            might
                                            result in the first-ever onset of asthma.</p>
                                    </li>
                                    <li>
                                        <p>Smoking cigarettes- Smoke irritates the airways. Smokers have a high risk of
                                            asthma.
                                            People with asthma are also more likely to have been exposed to secondhand
                                            smoke
                                            or
                                            to have had pregnant mothers who smoked.</p>
                                    </li>
                                    <li>
                                        <p>Air pollution- Exposure to the<a
                                            href="https://publications.gc.ca/Collection-R/LoPBdP/modules/prb98-4-smog/maincomponents-e.htm#:~:text=Smog%2C%20formed%20mainly%20above%20urban,NOx)%20and%20ammonia%20gas."
                                            rel="nofollow">&nbsp;main
                                            component of smog</a> (ozone) raises the risk of asthma.&nbsp;</p>
                                    </li>
                                </ul>
                                <p>Obesity in children and adults- Those who are&nbsp;<a
                                    href="https://www.lung.org/blog/the-link-between-asthma-weight">overweight or obese
                                    are
                                    at a
                                    greater risk of asthma</a>.</p>
                                <h2>When to see a doctor for asthma allergy?</h2>
                                <h4>Call your doctor right away if;
                                </h4>
                                <ul>
                                    <li>
                                        <p>You feel faint, dizzy, or weak.</p>
                                    </li>
                                    <li>
                                        <p>You have trouble doing a routine activity, such as cooking dinner, etc.</p>
                                    </li>
                                    <li>
                                        <p>You have a seasonal asthma cough that won&apos;t go away.</p>
                                    </li>
                                    <li>
                                        <p>When you breathe in or out, especially if this isn&apos;t how you usually
                                            breathe,
                                            you wheeze.</p>
                                    </li>
                                    <li>
                                        <p>Even after giving your medication time to start functioning (the majority of
                                            quick-relief medications act within 15 minutes), your wheezing persists.</p>
                                    </li>
                                </ul>
                                <h4>Visit the emergency room immediately if;
                                </h4>
                                <ul>
                                    <li>
                                        <p>If your lips or nails start to turn blue.</p>
                                    </li>
                                    <li>
                                        <p>Every time you take a breath in, your nostrils flare.</p>
                                    </li>
                                    <li>
                                        <p>Every time you breathe in, the skin between your ribs or at the base of your
                                            throat
                                            feels stretched.</p>
                                    </li>
                                    <li>
                                        <p>Your breathing rate is 30 or higher per minute.</p>
                                    </li>
                                    <li>
                                        <p>It&apos;s challenging to converse or move at a normal speed.</p>
                                    </li>
                                </ul>
                                <p>You can also book a free online appointment with Ayurvedic Doctor At Dr Axico.
                                </p>
                                <h3>What are the main factors that cause asthma?
                                </h3>
                                <p>Causes (triggers) of asthma attacks</p>
                                <ul>
                                    <li>
                                        <p>Breathing affects infections (like colds or the flu).</p>
                                    </li>
                                    <li>
                                        <p>Pollen&nbsp;(trees, grass, and weeds).</p>
                                    </li>
                                    <li>
                                        <p>Animal dander (like cats or rabbits)</p>
                                    </li>
                                    <li>
                                        <p>Tobacco smoke</p>
                                    </li>
                                    <li>
                                        <p>Food allergy (serious) asthma attacks caused by food allergies can be
                                            life-threatening (anaphylaxis).</p>
                                    </li>
                                    <li>
                                        <p>Family history of asthma.</p>
                                    </li>
                                    <li>
                                        <p>Anxiety shortness of breath</p>
                                    </li>
                                </ul>
                                <h3>What are the early signs and noticeable changes in the body?
                                </h3>
                                <p>Early warning signs and symptoms of asthma happen just before or at the very
                                    beginning of
                                    an
                                    asthma attack. These symptoms are the first indications that your asthma is getting
                                    worse
                                    and may appear before the more typical asthma symptoms.</p>
                                <p>Typically, these symptoms are not severe enough to keep you from performing your
                                    daily
                                    tasks.
                                    You may, however, prevent an asthma attack or stop it from getting worse by being
                                    aware
                                    of
                                    these symptoms. These are the warning signs of an asthma attack:</p>
                                <ul>
                                    <li>
                                        <p>Frequent&nbsp;<a
                                            href="https://www.webmd.com/cold-and-flu/rm-quiz-nighttime-cough">cough</a>,
                                            shortness of breath at night.</p>
                                    </li>
                                    <li>
                                        <p>breathlessness easily or shortness of breath.</p>
                                    </li>
                                    <li>
                                        <p>Feeling weak and very tired when exercising.</p>
                                    </li>
                                    <li>
                                        <p><a
                                            href="https://www.webmd.com/asthma/video/prevent-wheezing-in-children-with-asthma">wheezing</a>
                                            or&nbsp;<a
                                                href="https://www.webmd.com/cold-and-flu/cough-relief-12/slideshow-cough-treatments">coughing</a>
                                            after&nbsp;<a
                                                href="https://www.webmd.com/fitness-exercise/ss/slideshow-7-most-effective-exercises">exercise</a>.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Feeling grouchy, easily upset, tired, or moody.</p>
                                    </li>
                                    <li>
                                        <p>Reductions or modifications in lung function as determined by a peak flow
                                            meter.</p>
                                    </li>
                                    <li>
                                        <p>Cold or allergy symptoms (<a
                                            href="https://www.draxico.com/diseases/headaches">headache</a>,
                                            sore throat, runny nose, cough, nasal congestion).</p>
                                    </li>
                                    <li>
                                        <p>Shortness of breath after eating</p>
                                    </li>
                                    <li>
                                        <p>Trouble sleeping.</p>
                                    </li>
                                </ul>
                                <h2>Ayurvedic say on asthma allergy
                                </h2>
                                <p>According to Ayurvedic asthma treatment, the vata and kapha doshas are mostly to
                                    blame
                                    for
                                    swasa. The foods and behaviors that provoke kapha in shwasa roga are what start the
                                    disease&apos;s
                                    core aetiology. The body&apos;s ama (toxins) circulate as vitiated kapha in the
                                    pitta
                                    sthana
                                    (lower half of the amashaya). Not able to understand the doshas, read more about <a
                                        href="https://www.draxico.com/blogs/prakriti-and-its-ayurvedic-importance-for-health-dr-axico">ayurvedic
                                        doshas and prakriti</a>.</p>
                                <p>Following specific illnesses like jwara (fever), agnimandya (weak digestive fire),
                                    amatisara
                                    (diarrhoea), and visuchika, ama dosa can also circulate in the body. However, a
                                    systemic
                                    illness like kshaya, urakshata, pandu, or pratisyaya (rhinitis) can also aggravate
                                    the
                                    vata,
                                    aggravating it and causing vitiated kapha or ama as well as the pranavaha sroto
                                    vaigunya.&nbsp;</p>
                                <p>The repression of natural desires like udgara, aadhovata, and chhardi can also result
                                    in
                                    prana vaigunya. The pranavaha sroto vaigunya can also be attributed to physical
                                    effort.</p>
                                <p>The interaction of any of the precipitating causes, such as smoke, wind, dust,
                                    marmaghata,
                                    and usage of excessively cold water may increase the functional derangement of
                                    pranavaha
                                    srotas to a fully-fledged sroto dusti. As soon as srotodusti takes place, sanga and
                                    vimarga
                                    gamana cause the prana vayu to become aberrant. This then takes the form of shwasa
                                    roga.</p>
                                <h3>As per Ayurveda, the asthma attack causes are as follows:
                                </h3>
                                <ul>
                                    <li>intake of dry, cold, heavy, incompatible food and irregular intake of food</li>
                                    <li>excessive intake of black gram, beans, sesame, and meat of aquatic animals.</li>
                                    <li>intake of cold water and exposure to cold climate.</li>
                                    <li>exposure to dust, smoke, and wind.</li>
                                    <li>excessive exercise, overindulging in sexual activity.</li>
                                    <li>trauma to the throat, chest, and vital organs.</li>
                                    <li><a
                                        href="https://www.draxico.com/blogs/suppressible-non-suppressible-urges-and-ayurveda-dr-axico">suppression
                                        of natural urges</a>.
                                    </li>
                                </ul>
                                <h3>Treatment modalities in Ayurveda: </h3>
                                <p>According to Ayurveda, bronchial asthma is a sickness called vatakaphaja that starts
                                    in
                                    the
                                    stomach before spreading to the lungs and bronchi. Therefore, the goal of treating
                                    an
                                    asthma
                                    attack is to return any excess kapha to the stomach where it may be eliminated. For
                                    this
                                    purpose, the following methods are adopted.</p>
                                <ol>
                                    <li><a href="https://www.draxico.com/panchakarma-treatment/swedana"> Swedana</a>
                                        (sudation)
                                    </li>
                                    <li><a href="https://www.draxico.com/panchakarma-treatment/vamana"> Vamana</a>
                                        (therapeutic
                                        emesis)
                                    </li>
                                    <li><a href="https://www.draxico.com/panchakarma-treatment/virechana"> Virechana</a>
                                        (therapeutic purgation)
                                    </li>
                                </ol>
                                <p>These actions will be conducted following the unique requirements of each patient.
                                    Pranayama,
                                    laxatives, a light diet at night, and drinking warm water can all be used to cure
                                    asthma.</p>
                                <h3>Recommended ayurvedic herbs and medication for asthma allergy
                                </h3>
                                <p><strong>Ayurvedic herbs</strong></p>
                                <ul>
                                    <li>Kantakari (solanum xanthocarpum)</li>
                                    <li>Vasa (adhatoda vasica)</li>
                                    <li>Sunthi (zingiber officinalis)</li>
                                    <li>Bharangi (clerodendrum serratum)</li>
                                    <li>Pushkaramoola (innula racemosa)</li>
                                    <li>Karkataka shringi (pistacia inergerrima)</li>
                                    <li>Haridra (curcuma longa)</li>
                                </ul>
                                <p><strong>Ayurvedic drugs</strong></p>
                                <ul>
                                    <li>Talisadi churna or&nbsp;<a
                                        href="https://www.draxico.com/products/sitopaladi-churna">sitopaladi
                                        churna</a></li>
                                    <li>Somalata churna</li>
                                    <li><a href="https://www.draxico.com/products/kanakasava">Kanakasava</a></li>
                                    <li>Shringyadi churna</li>
                                    <li>Trikatu churna</li>
                                    <li>Vasavalehyam</li>
                                    <li>Kantakaryavalehyam</li>
                                    <li>Vyaghriharitaki avaleha</li>
                                    <li>Agastya haritaki avaleha</li>
                                    <li><a href="https://www.draxico.com/products/lavangadi-vati">Lavangadi vati</a>
                                    </li>
                                    <li>Swasakutara ras</li>
                                    <li><a href="https://www.draxico.com/products/naradiya-mahalakshmi-vilas-ras">Mahalakshmivilas
                                        ra</a>s
                                    </li>
                                </ul>
                                <p>Note: Although all these drugs can be taken as an ayurvedic inhaler but to be taken
                                    under
                                    the
                                    supervision of a qualified physician only!</p>
                                <h3>What to eat and not eat
                                </h3>
                                <h4>Do’s (pathya):
                                </h4>
                                <ul>
                                    <li>Godhuma (wheat), mudga (green gram), old rice, yava (barley), patola (snake
                                        gourd),
                                        kulattha (horse gram)
                                    </li>
                                    <li>Use of turmeric, garlic, ginger, and black pepper</li>
                                    <li>Lukewarm water, goat milk, honey</li>
                                    <li>Respiratory exercise, pranayama, yoga</li>
                                </ul>
                                <h4>Don’ts (apathya):
                                </h4>
                                <ul>
                                    <li>Cold diet, heavy diet, masha (black gram), deep fried items, mustard leaves,
                                        fish.
                                    </li>
                                    <li>Exposure to cold &amp; humid atmospheres.</li>
                                    <li>Sweets, chilled water, stored food items, curd.</li>
                                    <li>Suppression of natural urges.</li>
                                    <li>Excessive physical exertion.</li>
                                    <li>Exposure to smoke, dust and fumes, pollutants, and pollen.</li>
                                </ul>
                                <h3>Ayurvedic home remedies for asthma
                                </h3>
                                <ol>
                                    <li><strong>Sesame seeds:</strong>&nbsp;the application of warm oil of the good old
                                        tila
                                        in
                                        the chest area along with a warm compress would provide immediate relief from
                                        attacks of
                                        asthma.
                                    </li>
                                    <li><strong>Mulethi/licorice and ginger-&nbsp;</strong>mulethi also offers an
                                        anti-inflammatory effect for asthma patients. You can combine it with ginger
                                        extract
                                        and
                                        consume it regularly.
                                    </li>
                                    <li><strong>Black pepper:</strong>&nbsp;take a tablespoon of cow’s ghee and add a
                                        pinch/2-3
                                        crushed black pepper and heat it.&nbsp;
                                    </li>
                                    <li><strong>Fig:</strong>&nbsp;figs can remove phlegm from the respiratory system.
                                        Eating
                                        3-4 overnight soaked figs on an empty stomach first thing in the morning,
                                        continuously
                                        for at least two months, would yield good results. Also, the water in which figs
                                        were
                                        soaked can be consumed along with it to derive full benefit.
                                    </li>
                                    <li><strong>Ginger and turmeric milk-&nbsp;</strong>you can also make this simple
                                        ginger
                                        and
                                        turmeric milk and consume it twice a day. This is an all-natural remedy to lower
                                        asthmatic attacks, especially in winter and seasonal changes.
                                    </li>
                                </ol>
                                <p><strong>Herbal tea</strong>- herbal tea made by blending ajwain, tulsi, pepper, and
                                    ginger is
                                    a useful natural expectorant for asthmatics.</p>
                                <h2>How Ayurveda helps in naturally reducing the usage of inhalers?
                                </h2>
                                <p>The ayurvedic herbs, due to their microcellular penetration and kinetic effect can
                                    reach
                                    microcellular structures and can open the channels thus reducing blockage and
                                    inflammation.
                                    Also, the channel gets open due to the kashya-tikta rasa and ushna veerya (hot
                                    potency)
                                    of
                                    the ayurvedic medicines.</p>
                                <p>The ayurvedic herbs used in asthma attack treatment are anti-inflammatory and
                                    antihistamine.
                                    It will cause a reduction in the secretion of&nbsp;
                                    <a href="https://medlineplus.gov/lab-tests/allergy-blood-test/#:~:text=What%20is%20an%20Allergy%20Blood,in%20your%20blood%20than%20normal."
                                       rel="nofollow">IgE</a>
                                    which is responsible for immune responses thus reducing IgE will help for normal
                                    passage
                                    of
                                    air. It also acts on kapha dosha by kapha vilayana (liquefying the accumulated
                                    kapha)
                                    action
                                    that will also open the channels to create good air entry.</p>
                                <p>Thus, ayurvedic asthma medicine produces betterment in pulmonary functions by
                                    maintaining
                                    optimum oxygen saturation for a longer duration, keeping the patient in a mobile,
                                    self-dependent stage and thereby improving the quality of life of the patient,
                                    reducing
                                    their dependence on inhaler and other antihistamine drugs.</p>
                                <h3>Permanent cure for asthma in Ayurveda?</h3>
                                <p>The Ayurvedic way of living is comprehensive. is an all-encompassing approach to
                                    health
                                    that
                                    incorporates food, yoga, massage, detoxification, herbal medicines, meditation, and
                                    everyday
                                    habits. Ayurveda emphasizes prevention, has no negative side effects, and addresses
                                    the
                                    actual illness rather than merely its symptoms.&nbsp;</p>
                                <p>According to Ayurveda, the disease results from an imbalance in a person's physical
                                    or
                                    mental
                                    constitution, and it consequently aims to gently restore a person's body and mind to
                                    a
                                    state
                                    of harmony. Although Ayurveda can be used to treat a variety of ailments, its
                                    foundation
                                    is
                                    based on the idea that illnesses should be prevented rather than waiting for
                                    symptoms to
                                    appear.&nbsp;</p>
                                <p>According to Ayurveda, acute asthma is a sadhya (treatable with ease) while chronic
                                    asthma is
                                    yapya (difficult to cure) but treatment is possible. On the other hand, acc. To
                                    modern
                                    sciences it has been titled as an incurable disease but with proper intervention
                                    ayurveda,
                                    we can cure and manage asthma.</p>
                                <h4>A short note from Dr. Axico
                                </h4>
                                <p>Many people with asthma lead happy lives. Some asthmatic professional athletes have
                                    achieved
                                    sporting records. The ideal asthma management strategy might be discovered with the
                                    aid
                                    of
                                    your Ayurvedic doctor online consultation with Dr. Axico. How to manage your
                                    symptoms
                                    should
                                    be discussed with your healthcare professional and you can buy Ayurvedic medicine to
                                    have
                                    fast and safe results.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4>How do you know you have an asthma allergy?
                                        </h4>
                                        <p>Before or at the very start of an asthma attack, there are early warning signs and
                                            symptoms.
                                            These symptoms, which may manifest before the more common asthma symptoms, are the
                                            first
                                            signs that your asthma is getting worse. Asthma's main symptoms include coughing,
                                            shortness
                                            of breath, stuffy eyes, itchy eyes, wheezing, and rash.
                                        </p>
                                        <h4>How long does an asthma allergy last</h4>
                                        <p>Depending on the cause and how long the airways have been irritated, an attack's
                                            length
                                            can
                                            change. A mild episode could only last a few minutes, whereas a more severe one
                                            might
                                            linger
                                            for hours or even days. Mild attacks may go away on their own or necessitate
                                            medicine,
                                            usually a quick-acting inhaler.
                                        </p>
                                        <h4> How can I test for asthma at home</h4>
                                        <ul>
                                            <li>Check your upper airways, throat, and nose.</li>
                                            <li>A stethoscope can be used to hear your breathing. One of the primary symptoms of
                                                asthma
                                                is wheezing, which is high-pitched whistling as you exhale.
                                            </li>
                                            <li>Check your skin for indications of allergic reactions like hives and eczema.
                                            </li>
                                        </ul>
                                        <h4>What is the best natural asthma treatment?
                                        </h4>
                                        <p>Your quality of life may be improved by using certain dietary, psychological, and
                                            other
                                            complementary treatments to assist manage severe asthma symptoms. These typically
                                            continue
                                            to function best when used with prescribed asthma drugs.
                                            Some Natural Asthma Treatments – Nutritional food and Vitamins, breathing exercises,
                                            Mental
                                            Strategies, and therapies.
                                        </p>
                                        <h4>How to avoid asthma
                                        </h4>
                                        <p>Everyone may have distinct asthma triggers. Asthma attacks can be avoided with
                                            knowledge
                                            about how to do so.</p>
                                        <p>Here are some steps you may take to prevent the most typical asthma triggers:</p>
                                        <ul>
                                            <li>Avoid passive smoking and stop smoking.</li>
                                            <li>When painting, working on a construction site, or doing garden labor, wear a
                                                mask.
                                            </li>
                                            <li>Obtain a flu shot.</li>
                                            <li>Use HEPA filters in your furnace, air conditioner, and vacuum.</li>
                                            <li>To lessen allergies, use allergy-proof pillowcases and bedding.</li>
                                            <li>Don't let pets into your house.</li>
                                            <li>Reduce stress to prevent panic attacks.</li>
                                            <li>Consult a specialist to determine your allergies and discover ways to prevent
                                                them.
                                            </li>
                                        </ul>
                                        <h4>Who can get asthma
                                        </h4>
                                        <p>Asthma can strike anyone at any age. Asthma is more prone to occur in those who have
                                            allergies or are exposed to cigarette smoke. This includes exposure to thirdhand
                                            smoke
                                            (exposure to garments or surfaces in locations where someone has smoked) as well as
                                            secondhand smoke (exposure to someone else who is smoking).</p>
                                        <h4> What are common asthma triggers?
                                        </h4>
                                        <p>When you are exposed to "asthma triggers," if you have asthma, an attack could occur.
                                            The
                                            asthma triggers that apply to you might not apply to someone else. Learn how to
                                            avoid
                                            your
                                            triggers by being aware of them. Whenever you can't avoid the triggers, be on the
                                            lookout
                                            for an attack. Among the most typical triggers are:</p>
                                        <ol>
                                            <li>Tobacco Smoke</li>
                                            <li>&nbsp;Dust Mites</li>
                                            <li>Pests (e.g., cockroaches, mice)</li>
                                            <li>Outdoor Air Pollution</li>
                                            <li>Pets</li>
                                            <li>Mold</li>
                                            <li>Cleaning and Disinfection</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'bacterial-vaginosis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Bacterial Vaginosis: What are the Causes, Symptoms, & Treatment | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body">
                                <p>Bacterial overgrowth brought on by a bacterial imbalance causes bacterial vaginosis
                                    (BV),
                                    a
                                    typical vaginal bacterial infection. One of the bacterial vaginosis symptoms is an
                                    off-white
                                    or grey discharge that has a "fishy" odor. With the help of antibiotics from a
                                    medical
                                    professional, BV is easily curable.&nbsp;<a href="https://www.draxico.com/">Ayurvedic
                                        doctor
                                        online consultation for BV</a> is now at your fingertips, Thanks to Dr. Axico.
                                </p>
                                <h2> What is Bacterial Vaginosis?
                                </h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/bacterial-vaginosis/Image.webp"
                                    >
                                </div>
                                <p>Bacterial vaginosis (BV) is a common vaginal infection caused by an overgrowth of
                                    anaerobic
                                    bacteria in the vagina. Instead of a healthy balance of bacteria, BV is
                                    characterized by
                                    an
                                    overgrowth of anaerobic bacteria, such as&nbsp;<a
                                        href="https://www.ncbi.nlm.nih.gov/books/NBK459350/" rel="nofollow">Gardnerella
                                        vaginalis</a>, which
                                    can cause an unpleasant odor and discharge.</p>
                                <h3>What are the main factors that cause Bacterial Vaginosis?
                                </h3>
                                <p>The exact vaginal infection causes are not known, but several factors can increase
                                    the
                                    risk
                                    of developing this condition. These factors include:</p>
                                <ul>
                                    <li>Sexual activity: Having a new sexual partner or multiple sexual partners can
                                        increase
                                        the risk of developing BV. Can sperm cause BV? Semen impacts the pH level in the
                                        vagina.
                                    </li>
                                    <li>Douching: Douching increases the chance of getting BV by upsetting the normal
                                        balance of
                                        bacteria in the vagina.
                                    </li>
                                    <li>Antibiotics: Using antibiotics may cause the healthy bacteria in the vagina to
                                        die
                                        off,
                                        which may encourage the growth of dangerous germs.
                                    </li>
                                    <li>Hormonal changes: Hormonal changes during pregnancy, menstruation, or menopause
                                        can
                                        also
                                        disrupt the natural balance of bacteria in the vagina and increase the risk of
                                        developing BV.
                                    </li>
                                </ul>
                                <h2> What are the Early Signs and Noticeable Changes in the Body?
                                </h2>
                                <p>The early signs of vaginal infection and noticeable changes in the body that may
                                    indicate
                                    BV
                                    include:</p>
                                <ul>
                                    <li>Vaginal discharge: BV can cause a thin, greyish-white vaginal discharge that has
                                        a
                                        strong, fishy odor.
                                    </li>
                                    <li>Itching or burning: Some women with BV may experience bacterial vaginosis
                                        itching or
                                        burning around the vaginal area.
                                    </li>
                                    <li>Painful urination: BV can cause pain or discomfort during urination and it is a
                                        very
                                        common bv symptom in women.
                                    </li>
                                    <li>Discomfort during intercourse: Some women with BV may experience discomfort or
                                        pain
                                        during intercourse.
                                    </li>
                                </ul>
                                <h3>What are the Risk Factors of Bacterial Vaginosis?
                                </h3>
                                <p>If left untreated, BV can lead to several complications, including:</p>
                                <ul>
                                    <li>Increased risk of sexually transmitted infections (STIs): Women with BV are at a
                                        higher
                                        risk of contracting&nbsp;<a
                                            href="https://www.who.int/news-room/fact-sheets/detail/sexually-transmitted-infections-(stis)#:~:text=Key%20facts,%2C%20gonorrhoea%2C%20syphilis%20and%20trichomoniasis."
                                            rel="nofollow">STI</a>s
                                        like chlamydia, gonorrhea, and HIV.
                                    </li>
                                    <li>Pelvic inflammatory disease (PID): BV can increase the risk of developing PID, a
                                        serious
                                        infection of the reproductive organs that can cause infertility and chronic
                                        pain.
                                    </li>
                                    <li>Pregnancy complications: BV can increase the risk of premature delivery, low
                                        birth
                                        weight, and other complications during pregnancy.
                                    </li>
                                    <li>Post-surgical vaginal bacterial infection: Women with BV are at a higher risk of
                                        developing infections after gynaecologic surgery, such as a hysterectomy or a
                                        C-section.
                                    </li>
                                </ul>
                                <p>Increased risk of HIV transmission: Women with BV are at a higher risk of
                                    transmitting
                                    HIV to
                                    their partners<strong>.</strong>&nbsp;</p>
                                <h3>
                                    When to see a Doctor for Bacterial Vaginosis?
                                </h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/bacterial-vaginosis/Image-2.webp"
                                    >
                                </div>
                                <p>You should see a doctor if you suspect you have bacterial vaginosis (BV) or if you
                                    experience
                                    any symptoms such as vaginal discharge with a strong, fishy odor, itching, burning,
                                    or
                                    pain
                                    during urination or sex. Other reasons to see a doctor include:</p>
                                <ul>
                                    <li>Recurrent Bacterial vaginosis: If you have had BV before and it keeps coming
                                        back,
                                        you
                                        should see a doctor for further evaluation and treatment.
                                    </li>
                                    <li>Pregnancy: Bacterial vaginosis can increase the risk of complications during
                                        pregnancy,
                                        so it's important to see a doctor if you are pregnant and suspect you may have
                                        BV.
                                    </li>
                                    <li>Sexually transmitted infections: Some bacterial vaginosis symptoms can be
                                        similar to
                                        those of sexually transmitted infections (STIs). If you have any concerns about
                                        STIs
                                        or
                                        if you have had unprotected sex with a new partner, you should see a doctor for
                                        testing
                                        and treatment or&nbsp;<a href="https://www.draxico.com/appointment">book an
                                            online
                                            appointment with the doctor</a> at Dr Axico .
                                    </li>
                                    <li>Unusual symptoms: If you experience any unusual symptoms or if your BV symptoms
                                        do
                                        not
                                        improve with treatment, you should see a doctor for further evaluation.
                                    </li>
                                </ul>
                                <p>Your doctor can perform a pelvic exam and a laboratory test to diagnose BV and
                                    recommend
                                    appropriate treatment, such as antibiotics or other medications. It's important to
                                    seek
                                    prompt BV treatment to avoid potential complications and to improve your overall
                                    vaginal
                                    health.</p>
                                <h2>Ayurvedic Say on Bacterial Vaginosis?
                                </h2>
                                <p>In Ayurveda, bacterial vaginosis is believed to be caused by an imbalance of the
                                    Pitta
                                    dosha,
                                    which leads to the accumulation of Ama (toxins) in the body. This Ama accumulation
                                    can
                                    disrupt the natural balance of the microbiome in the vagina, leading to BV. The root
                                    cause
                                    of this Pitta imbalance is poor digestion, which can be caused by unhealthy dietary
                                    habits,
                                    stress, and lifestyle factors.</p>
                                <h3> How Ayurveda Can Help to Control Bacterial Vaginosis?</h3>
                                <p>Ayurveda, the traditional system of medicine from India, uses a holistic approach to
                                    treat
                                    bacterial vaginosis (BV). The treatment approach includes a combination of lifestyle
                                    modifications, natural remedies, and Ayurvedic therapies. Here is some Ayurvedic
                                    treatment
                                    for vaginal infection/itching that can help to manage BV:</p>
                                <ul>
                                    <li>Herbal remedies: Ayurveda recommends using herbal remedies to control bacterial
                                        growth
                                        and promote overall vaginal health. Some commonly used herbs include neem,
                                        turmeric,
                                        aloe vera, and holy basil. These herbs have antimicrobial and anti-inflammatory
                                        properties that can help to soothe irritated skin and prevent the growth of
                                        harmful
                                        bacteria.
                                    </li>
                                    <li>Ayurvedic therapies: Ayurvedic therapies like vaginal steam baths, oil massages,
                                        and
                                        herbal douches can help manage BV. Vaginal steam baths, also known as yoni
                                        steaming,
                                        involve sitting over a pot of hot water infused with herbs. This therapy helps
                                        to
                                        promote blood circulation and detoxify the vaginal area. <a
                                            href="https://www.draxico.com/panchakarma-treatment/abhyanga"> Abhyanga Oil
                                            massages </a> and herbal douches
                                        can also help to soothe irritated skin and promote overall vaginal health.
                                    </li>
                                    <li>Diet and lifestyle modifications: Ayurveda recommends making changes in diet and
                                        lifestyle to balance the doshas and promote overall health. Eating a balanced
                                        diet
                                        rich
                                        in whole grains, fruits, vegetables, and lean protein can help to boost the
                                        immune
                                        system and prevent infections like BV. Practicing stress-management techniques
                                        like
                                        yoga, meditation, and deep breathing exercises can also help to reduce stress
                                        and
                                        boost
                                        the immune system.
                                    </li>
                                </ul>
                                <p>Ayurvedic herbs and supplements: Ayurvedic practitioners may recommend herbal
                                    supplements
                                    like&nbsp;<a
                                        href="https://www.draxico.com/products/triphaladi-guggulu">triphala</a>,&nbsp;<a
                                        href="https://www.draxico.com/products/sudh-ashwagandha-capsule">ashwagandha</a>,
                                    and
                                    shatavari to boost the immune system and promote overall vaginal health.</p>
                                <h3>Ayurvedic Remedies for Bacterial Vaginosis
                                </h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/bacterial-vaginosis/Image-3.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Triphala: Mix one teaspoon of Triphala powder with water and use it to wash the
                                        vaginal
                                        area.
                                    </li>
                                    <li>Neem: Neem is a natural antimicrobial and antifungal agent that can be used to
                                        treat
                                        BV.
                                        Boil neem leaves in water and use the solution to rinse the vaginal area.
                                    </li>
                                    <li>Aloe vera: Aloe vera has anti-inflammatory and antimicrobial properties and can
                                        be
                                        used
                                        to soothe vaginal irritation and itching caused by BV. Apply aloe vera gel to
                                        the
                                        affected area.
                                    </li>
                                    <li>Turmeric: Turmeric has antimicrobial and anti-inflammatory properties and can be
                                        used to
                                        treat BV. Mix one teaspoon of turmeric powder with water and use it to wash the
                                        vaginal
                                        area.
                                    </li>
                                </ul>
                                <h3>What to eat:</h3>
                                <ul>
                                    <li>Probiotics: Eating foods high in probiotics like yogurt, kefir, and fermented
                                        foods
                                        can
                                        help to restore the natural balance of bacteria in the vagina.
                                    </li>
                                    <li>Fiber: Eating a diet rich in fiber can help to promote digestive health and
                                        prevent
                                        constipation, which can contribute to BV.
                                    </li>
                                    <li>Omega-3 fatty acids: Eating foods high in omega-3 fatty acids like salmon,
                                        walnuts,
                                        and
                                        flaxseeds can help to reduce inflammation in the body and promote overall
                                        health.
                                    </li>
                                    <li>Vitamin C: Eating foods high in vitamin C like oranges, kiwis, and strawberries
                                        can
                                        help
                                        to boost the immune system and promote overall vaginal health.
                                    </li>
                                </ul>
                                <h3>What to avoid:
                                </h3>
                                <ul>
                                    <li>Sugar: Eating too much sugar can promote the growth of harmful bacteria and
                                        contribute
                                        to BV. Avoid sugary drinks, desserts, and processed foods.
                                    </li>
                                    <li>Alcohol: Drinking alcohol can disrupt the natural balance of bacteria in the
                                        vagina
                                        and
                                        contribute to BV.
                                    </li>
                                    <li>Processed foods: Eating a diet high in processed foods can promote inflammation
                                        in
                                        the
                                        body and contribute to BV.
                                    </li>
                                    <li>Dairy and meat: Eating too much dairy and meat can contribute to inflammation in
                                        the
                                        body and disrupt the bacterial vaginosis natural treatment that balances
                                        bacteria in
                                        the
                                        vagina.
                                    </li>
                                </ul>
                                <h3>Recommended Ayurvedic Herbs and Medication for Bacterial Vaginosis</h3>
                                <ul>
                                    <li>Ashokarishta</li>
                                    <li><a href="https://www.draxico.com/products/lodhrasava">Lodhrasava</a></li>
                                    <li>Pushyanuga churna</li>
                                    <li><a href="https://www.draxico.com/products/chandraprabha-vati">Chandraprabha
                                        vati</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/arogyavardhini-vati">Arogyavardhini
                                        vati</a>
                                    </li>
                                    <li>Nimbadi guggulu</li>
                                    <li>Panchvallakal Kashaya</li>
                                    <li><a href="https://www.draxico.com/products/gandhak-rasayan">Gandhak rasayan</a>
                                    </li>
                                    <li>Nimba</li>
                                    <li><a href="https://www.draxico.com/products/giloy-plus-capsule">Giloy</a></li>
                                    <li>Shatavari</li>
                                    <li>Lasun</li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala</a></li>
                                    <li>Ashoka</li>
                                    <li>Manjistha</li>
                                </ul>
                                <p><strong>NOTE</strong>- These remedies should be used under the guidance of a
                                    qualified
                                    Ayurvedic practitioner, as they may have potential side effects and interactions
                                    with
                                    other
                                    medications.</p>
                                <h4>A Short Note from Dr. Axico
                                </h4>
                                <p>Despite being a mild infection, bacterial vaginosis (BV) can make you more
                                    susceptible to
                                    more severe illnesses. If you detect anything unusual about your vaginal discharge,
                                    don't
                                    wait to consult a doctor. Antibiotics can be used to treat BV by your Ayurvedic
                                    doctor
                                    online consultation. Book your appointment now with Dr. Axico and you can buy
                                    ayurvedic
                                    medicine for vaginal itching/ infections online and offline.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h3>How is Bacterial Vaginosis diagnosed?
                                        </h3>
                                        <p>The common methods used to diagnose BV:</p>
                                        <ul>
                                            <li>Medical history review: Your healthcare provider will ask you about your
                                                bacterial
                                                vaginosis symptoms, medical history, sexual history, and any medications or
                                                treatments
                                                you have recently used.
                                            </li>
                                            <li>Physical examination: Your healthcare provider will perform a pelvic exam to
                                                check
                                                for
                                                signs of BV, inflammation, discharge, and odor.
                                            </li>
                                            <li>Microscopic examination: A sample of vaginal discharge may be collected and
                                                examined
                                                under a microscope to look for the presence of bacterial cells.
                                            </li>
                                            <li>pH test: A pH test may be performed to measure the acidity of the vagina. BV is
                                                associated with a higher pH level.
                                            </li>
                                            <li>Whiff test: A sample of vaginal discharge may be mixed with a solution of
                                                potassium
                                                hydroxide (KOH) and observed for a characteristic fishy odor, which is
                                                associated
                                                with
                                                BV.
                                            </li>
                                        </ul>
                                        <h3> Is Bacterial vaginosis a serious infection?</h3>
                                        <p>While BV itself is not usually serious, it can increase the risk of developing other
                                            complications, such as pelvic inflammatory disease (PID), which can lead to more
                                            serious
                                            health problems, such as infertility. Additionally, BV can increase the risk of
                                            contracting
                                            sexually transmitted infections (STIs), including HIV.</p>
                                        <h3>How long does Bacterial Vaginosis last?</h3>
                                        <p>The duration of bacterial vaginosis (BV) can vary from person to person, and it may
                                            resolve
                                            on its own in some cases. However, without proper treatment, BV can persist for
                                            weeks or
                                            even months, and symptoms may come and go. In some cases, BV can recur even after
                                            treatment.</p>
                                        <h3> How can I permanently cure Bacterial Vaginosis?
                                        </h3>
                                        <p>Here are some points to bacterial vaginosis cure:&nbsp;</p>
                                        <ul>
                                            <li>Complete the full course of antibiotics</li>
                                            <li>Practice good hygiene.</li>
                                            <li>Wear loose and breathable clothing.</li>
                                            <li>Limit sexual partners.</li>
                                            <li>Consider probiotic-rich foods</li>
                                            <li>Discuss any underlying health conditions or lifestyle factors with your
                                                healthcare
                                                provider.
                                            </li>
                                            <li>Regular check-ups with your healthcare provider can help monitor and manage
                                                vaginal
                                                bacterial infection symptoms and overall vaginal health.
                                            </li>
                                        </ul>
                                        <h3>Ayurvedic medicine for Bacterial Vaginosis
                                        </h3>
                                        <p>Given are a few of the Ayurvedic formulations and Ayurvedic medicine for genital
                                            infection.
                                            Please note that proper guidance from an Ayurvedic expert must be taken before
                                            taking
                                            any of
                                            the herbs/formulations</p>
                                        <ul>
                                            <li>Nimba churna</li>
                                            <li>Pradrantak Churna</li>
                                            <li>Nirgundi Oil</li>
                                            <li>Gandhak Rasayana</li>
                                            <li>Pushyanug churna</li>
                                            <li>Ashokarishta</li>
                                            <li>Chandanasav</li>
                                            <li>Panchvallakal kwath</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'bronchiectasis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Bronchiectasis: Find Causes & Ayurvedic Treatment
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Bronchiectasis is a chronic lung condition that occurs when the bronchial tubes in
                                    the
                                    lungs
                                    become damaged and widened, leading to the accumulation of mucus and an increased
                                    risk
                                    of
                                    infections. To live a life free of Bronchiectasis, you can connect with an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online</a> in a few simple
                                    steps.&nbsp;&nbsp;&nbsp;
                                </p>
                                <p>A study conducted in Northern India found a prevalence rate of 3.9% among patients
                                    with
                                    chronic respiratory diseases. Another study conducted in Southern India found a
                                    higher
                                    prevalence rate of 7.6% among patients with chronic obstructive pulmonary disease
                                    (COPD).</p>
                                <h2> What are the causes and symptoms of Bronchiectasis?
                                </h2>
                                <p>The most common cause of bronchiectasis, including:</p>
                                <ul>
                                    <li>Chronic obstructive pulmonary disease (COPD)</li>
                                    <li>Cystic fibrosis</li>
                                    <li>Immune deficiencies</li>
                                    <li>Infections, such as tuberculosis or pneumonia</li>
                                    <li>Autoimmune diseases</li>
                                    <li><a href="https://www.draxico.com/diseases/allergies">Allergies</a></li>
                                    <li>Aspiration of a foreign body</li>
                                    <li>Genetic conditions such as primary ciliary dyskinesia</li>
                                </ul>
                                <h3> Bronchiectasis symptoms can include:
                                </h3>
                                <ul>
                                    <li>Chronic cough</li>
                                    <li>Excessive production of mucus</li>
                                    <li>Shortness of breath</li>
                                    <li>Wheezing</li>
                                    <li>Chest pain</li>
                                    <li>Fatigue</li>
                                    <li>Recurrent infections such as pneumonia</li>
                                    <li>Clubbing of fingers</li>
                                </ul>
                                <p>If you are facing any of the above issues, and want to know your condition at home,
                                    you
                                    can
                                    take a&nbsp;<a
                                        href="https://www.draxico.com/self-assessment/general-health-assessment">free
                                        Bronchiectasis self assessment disease test online</a>.</p>
                                <h2>What’s the difference between bronchiectasis and Bronchitis?</h2>
                                <p>Similar symptoms between&nbsp;<a href="https://www.draxico.com/diseases/bronchitis">bronchitis</a>
                                    and bronchiectasis include coughing up mucus. However, bronchitis is a transient
                                    infection
                                    that doesn't permanently expand your airways, whereas bronchiectasis does.
                                    Bronchiectasis
                                    meaning, It is a chronic lung disease caused by damage to the bronchial tubes,
                                    leading
                                    to
                                    mucus accumulation and an increased risk of infections. Symptoms may include cough,
                                    shortness of breath, chest pain, and fatigue.&nbsp;</p>
                                <p>In contrast, Bronchitis is a common condition and can affect people of all ages. It
                                    is
                                    more
                                    prevalent during the winter months and is more common in people who smoke or are
                                    exposed
                                    to
                                    air pollution. Treatment for bronchiectasis may include antibiotics,
                                    bronchodilators,
                                    and
                                    physical therapy.</p>
                                <h2> What is Acute and Chronic Bronchiectasis?
                                </h2>
                                <p>Acute bronchiectasis refers to a sudden onset of bronchial inflammation and damage
                                    that
                                    can
                                    result from an infection or other acute illness. It is a rare and severe condition
                                    that
                                    can
                                    progress rapidly, leading to respiratory failure and other complications. The
                                    symptoms
                                    of
                                    acute bronchiectasis may include coughing, wheezing, chest pain, fever, and
                                    difficulty
                                    breathing.</p>
                                <p>Chronic bronchiectasis, on the other hand, is a long-term condition in which the
                                    bronchial
                                    tubes in the lungs become damaged and widened over time. This can lead to the
                                    accumulation
                                    of mucus and an increased risk of infections.&nbsp;</p>
                                <p>The symptoms of chronic bronchiectasis may include a persistent cough, production of
                                    a
                                    large
                                    amount of mucus, shortness of breath, chest pain, and fatigue. Chronic
                                    bronchiectasis
                                    can be
                                    caused by a variety of factors, including respiratory infections, autoimmune
                                    diseases,
                                    and
                                    genetic disorders such as cystic fibrosis.</p>
                                <h2>What are the main factors that cause Bronchiectasis?
                                </h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/bronchiectasis/Image.webp"
                                    >
                                </div>
                                <p>Bronchiectasis can be caused by a variety of factors, including:</p>
                                <ol>
                                    <li><strong>Respiratory infections:</strong> Repeated or severe respiratory
                                        infections,
                                        such
                                        as pneumonia or tuberculosis, can cause damage to the bronchial tubes and lead
                                        to
                                        bronchiectasis.
                                    </li>
                                    <li><strong>Genetic disorders:</strong> Inherited conditions such as cystic
                                        fibrosis,
                                        primary ciliary dyskinesia, and&nbsp;<a
                                            href="https://www.nhlbi.nih.gov/health/alpha-1-antitrypsin-deficiency#:~:text=Alpha%2D1%20antitrypsin%20(AAT),This%20can%20lead%20to%20COPD."
                                            rel="nofollow">alpha-1
                                            antitrypsin deficiency</a> can increase the risk of developing
                                        bronchiectasis.
                                    </li>
                                    <li><strong>Immunodeficiency:</strong> Weakened immune systems due to conditions
                                        such as
                                        HIV
                                        or cancer can increase the risk of developing bronchiectasis.
                                    </li>
                                    <li><strong>Inhalation of foreign objects:</strong> Inhaling a foreign object, such
                                        as
                                        food
                                        or a small toy, can cause blockages in the airways and lead to bronchiectasis.
                                    </li>
                                    <li><strong>Allergic bronchopulmonary aspergillosis (ABPA):</strong> This is a
                                        condition
                                        in
                                        which a person has an allergic reaction to a type of fungus called Aspergillus,
                                        which
                                        can cause inflammation and damage to the bronchial tubes.
                                    </li>
                                </ol>
                                <p><strong>Chronic obstructive pulmonary disease (COPD):</strong> Long-term exposure to
                                    irritants such as cigarette smoke can lead to COPD, which can then cause
                                    bronchiectasis.
                                </p>
                                <h3>What are the Early Signs and Noticeable Changes in Body?
                                </h3>
                                <p>Some of the early signs and noticeable changes in the body that may indicate
                                    bronchiectasis
                                    include:</p>
                                <ul>
                                    <li><strong>Chronic cough:</strong> A persistent cough that lasts for weeks or
                                        months
                                        can be
                                        a sign of bronchiectasis.
                                    </li>
                                    <li><strong>Increased mucus production:</strong> People with bronchiectasis may
                                        produce
                                        more
                                        mucus than usual, which can be thick and sticky.
                                    </li>
                                    <li><strong>Shortness of breath:</strong> Bronchiectasis can cause difficulty
                                        breathing,
                                        particularly during physical activity or exercise.
                                    </li>
                                    <li><strong>Recurrent chest infections:</strong> People with bronchiectasis may
                                        experience
                                        frequent chest infections, which can cause symptoms such as fever, chest pain,
                                        and
                                        difficulty breathing.
                                    </li>
                                    <li><strong>Fatigue:</strong> People with bronchiectasis may feel tired or fatigued,
                                        particularly if their condition is affecting their ability to get enough oxygen.
                                    </li>
                                    <li><strong>Wheezing:</strong> Wheezing, a high-pitched whistling sound when
                                        breathing,
                                        can
                                        be a sign of bronchiectasis.
                                    </li>
                                </ul>
                                <p>If you are facing any of the above symptoms you can book a&nbsp;<a
                                    href="https://www.draxico.com/appointment">free appointment with Doctors</a> at Dr
                                    Axico.
                                </p>
                                <h3>When to see a Doctor for Bronchiectasis?
                                </h3>
                                <p>If you are experiencing any of the symptoms associated with bronchiectasis, such as
                                    chronic
                                    cough, increased mucus production, shortness of breath, recurrent chest infections,
                                    fatigue,
                                    or wheezing, you should make an appointment with your doctor.</p>
                                <p>You should also seek medical attention if you have a history of lung infections, or
                                    if
                                    you
                                    have a family history of lung disease bronchiectasis such as cystic fibrosis.</p>
                                <p>Early diagnosis and bronchiectasis treatment is important to prevent complications
                                    and to
                                    manage symptoms effectively. Your doctor may perform tests such as a chest x-ray, CT
                                    scan,
                                    or pulmonary function test to diagnose bronchiectasis and develop a new treatment
                                    for
                                    bronchiectasis plan that is right for you.</p>
                                <h2> Ayurvedic say on Bronchiectasis
                                </h2>
                                <p>In Ayurveda, Bronchiectasis is considered as a disease that occurs in the pranavaha
                                    srotas.
                                    Bronchiectasis can be correlated to Tamaka swasa along with kaphaja kasa.</p>
                                <p>Ayurveda has a number of formulations to treat this condition and is in practice with
                                    proven
                                    efficacy Due to the causative factors, pranavata combining with Kapha leaves its
                                    path
                                    and
                                    breathing becomes difficult, coughing, etc. start to occur as it starts to travel
                                    upward.&nbsp;</p>
                                <h3>How Ayurveda Can Help to Control Bronchiectasis?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/bronchiectasis/Image-1.webp"
                                    >
                                </div>
                                <p>Ayurveda can help to control bronchiectasis by addressing the underlying imbalances
                                    that
                                    contribute to the condition and by promoting respiratory health. Ayurveda can be
                                    helpful
                                    in
                                    managing bronchiectasis by:
                                </p>
                                <h4>Balancing the doshas: </h4>
                                <p>According to Ayurveda, imbalances in the doshas can contribute to respiratory
                                    conditions
                                    such
                                    as dry bronchiectasis. By identifying and addressing these imbalances through diet,
                                    lifestyle, and herbal remedies, Ayurveda can help to reduce inflammation and improve
                                    respiratory function.
                                </p>
                                <h4>Supporting the immune system:</h4>
                                <p>Ayurveda emphasizes the importance of maintaining a strong immune system to prevent
                                    infections that can worsen respiratory symptoms. Herbal remedies and dietary
                                    recommendations
                                    that support the immune system can be helpful in managing bronchiectasis.
                                </p>
                                <h4>Promoting detoxification</h4>
                                <p>Ayurveda uses a variety of therapies, such as <a
                                    href="https://www.draxico.com/panchakarma-treatment/"> Panchakarma</a>, to help the
                                    body
                                    eliminate toxins
                                    and reduce inflammation. These therapies can be helpful in managing respiratory
                                    symptoms
                                    in
                                    bronchiectasis.
                                </p>
                                <h4>Reducing stress:</h4>
                                <p>Stress can worsen respiratory symptoms in bronchiectasis. Ayurveda offers a variety
                                    of
                                    relaxation techniques, such as yoga and meditation, to help reduce stress and
                                    promote
                                    respiratory health.
                                </p>
                                <h4>Providing herbal remedies</h4>
                                <p> Ayurveda offers a variety of herbal remedies, such as ginger, turmeric, and
                                    licorice,
                                    that
                                    have anti-inflammatory and bronchodilatory properties. These remedies can be helpful
                                    in
                                    reducing inflammation in the <a
                                        href="https://www.lung.ca/lung-health/lung-info/respiratory-system"
                                        rel="nofollow">
                                        respiratory system</a> and improving lung function.
                                </p>
                                <h3> Ayurvedic Remedies for Bronchiectasis
                                </h3>
                                <ul>
                                    <li>Heat application on the chest area by a towel dipped with hot water.</li>
                                    <li>Steam inhalation as it breaks up the mucus and enables easy expectoration.</li>
                                    <li>Make a paste of turmeric with honey in 1:2 ratios and consume. Turmeric can be
                                        used
                                        with
                                        food and as tea or milk as it is anti-inflammatory and antioxidant.
                                    </li>
                                    <li>Salt water gargle helps in breaking the mucus and relieves throat pain.</li>
                                </ul>
                                <h3> What to eat and not eat?
                                </h3>
                                <p>Here are some specific dietary recommendations based on Ayurvedic principles:</p>
                                <ol>
                                    <li>Eat warm, cooked foods: Ayurveda emphasises the importance of eating warm,
                                        cooked
                                        foods
                                        that are easy to digest. This can help to support digestive health and prevent
                                        the
                                        build-up of toxins that can contribute to inflammation in the respiratory
                                        system.
                                    </li>
                                    <li>Avoid cold and raw foods: Cold and raw foods, such as salads and smoothies, can
                                        be
                                        difficult to digest and can aggravate the doshas. Instead, choose warm, cooked
                                        foods
                                        that are easy to digest.
                                    </li>
                                    <li>Limit dairy products: According to Ayurveda, dairy products can contribute to
                                        the
                                        buildup of mucus in the body, which can worsen respiratory symptoms in
                                        bronchiectasis.
                                        Limiting or avoiding dairy products can be helpful in managing these symptoms.
                                    </li>
                                    <li>Emphasize spices and herbs: Many spices and herbs have anti-inflammatory
                                        properties
                                        and
                                        can help to support respiratory health. Incorporating spices such as ginger,
                                        turmeric,
                                        cumin, and coriander into your diet can be beneficial.
                                    </li>
                                    <li>Drink warm fluids: Drinking warm fluids, such as herbal teas, can help to soothe
                                        the
                                        respiratory system and reduce inflammation. Avoid cold or iced drinks, which can
                                        aggravate the doshas.
                                    </li>
                                </ol>
                                <h3>Recommended Ayurvedic Herbs and Medication for Bronchiectasis
                                </h3>
                                <ul>
                                    <li>Talisadi Choorna</li>
                                    <li>Pippali Choorna</li>
                                    <li>Haridra Khanda</li>
                                    <li><a href="https://www.draxico.com/products/guduchi-ghana-vati">Guduchi Satwa</a>
                                    </li>
                                    <li>Kantakari Ghrita</li>
                                    <li>Dhatri Loha</li>
                                    <li><a href="https://www.draxico.com/products/kanakasava">Kanakasava</a></li>
                                    <li><a href="https://www.draxico.com/products/tribhuvan-kirti-ras">Tribhuvana Kirti
                                        Rasa</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/shwas-kuthar-ras">Shwasa Kuthara
                                        Rasa</a>
                                    </li>
                                    <li>Elakanadi Kashaya</li>
                                    <li>Shiva Gulika</li>
                                    <li>Siddha makaradhwaj</li>
                                    <li>Karpooradi taila</li>
                                    <li>Pippali</li>
                                    <li><strong>Maricha&nbsp;</strong></li>
                                    <li>Shunthi</li>
                                    <li>Vasa</li>
                                    <li>Yasthimadhu&nbsp;</li>
                                </ul>
                                <h4>A Short Note from Dr. Axico
                                </h4>
                                <p>Bronchiectasis shouldn't often prevent you from leading a full life. Discuss
                                    troublesome
                                    symptoms and how to handle exacerbations with your healthcare practitioner. While
                                    they
                                    might
                                    not be able to address every symptom, sometimes even small adjustments can have a
                                    big
                                    impact
                                    on how well you live.</p>
                                <p>Dr. Axico offers holistic health services in addition to ayurvedic doctor online
                                    consultations. Additionally, patients can buy Ayurvedic medicine from Dr. Axico's
                                    medical
                                    facilities or websites, either online or offline.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4>What is the danger of Bronchiectasis?</h4>
                                        <p>Bronchiectasis is a chronic lung condition characterised by damaged and widened
                                            airways,
                                            leading to recurrent infections and breathing difficulties. It can cause
                                            irreversible
                                            lung
                                            damage, reduce quality of life, and in severe cases, be life-threatening.
                                        </p>
                                        <h4> Is there a permanent cure for Bronchiectasis?
                                        </h4>
                                        <p>In the modern system of medicine, there are no definite bronchitis treatments of post
                                            tuberculous bronchiectasis which would be effective in reducing the symptoms. In the
                                            long-term care and therapy of bronchiectasis, which finally results in the cure of
                                            the
                                            condition, ayurveda has a key role to play.
                                        </p>
                                        <h4>What vitamin is good for Bronchiectasis?
                                        </h4>
                                        <p>Vitamin D may benefit individuals with bronchiectasis as it has anti-inflammatory
                                            properties
                                            and plays a role in regulating immune function. However, it's important to consult a
                                            healthcare provider before taking vitamin D supplements as excessive intake can have
                                            adverse
                                            effects. A balanced diet rich in other vitamins and nutrients is also crucial for
                                            bronchiectasis management.
                                        </p>
                                        <h4>Can Bronchiectasis lead to TB?
                                        </h4>
                                        <p>Bronchiectasis can increase the risk of developing tuberculosis bronchiectasis (TB)
                                            as it
                                            weakens the lungs and makes them more vulnerable to infections. Conversely,
                                            untreated TB
                                            can
                                            also lead to bronchiectasis. Prevention of infections and timely treatment of any
                                            infections
                                            is crucial in managing bronchiectasis and reducing the risk of complications like
                                            TB.
                                        </p>
                                        <h4> Best Bronchiectasis natural Ayurvedic medicine?
                                        </h4>
                                        <p>There is no universal Ayurvedic medicine for bronchiectasis. Ayurvedic treatment is
                                            personalised based on a person's dosha imbalance, symptoms, and overall health. Some
                                            Ayurvedic herbs and formulations used for respiratory ailments include Tulsi,
                                            Yashtimadhu,
                                            Triphala, Pippali, and Sitopaladi churna. Consultation with a qualified Ayurvedic
                                            practitioner is essential before using any herbal remedies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'chest-pain'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Chest Pain: Various Causes & Treatment in Ayurveda | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Chest pain is a common symptom that can be alarming and often prompts people to seek
                                    medical
                                    attention. It can be caused by a variety of factors, ranging from minor issues like
                                    muscle
                                    strain to life-threatening conditions like a heart attack. We are the creators of
                                    Ayurveda,
                                    a promising way to a healthy life. You can connect with an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online</a> with a few easy
                                    steps to
                                    lead a chest pain-free life.&nbsp;&nbsp;&nbsp;</p>
                                <p>Chest pain is not a disease but a symptom of an underlying condition that requires
                                    proper
                                    diagnosis and treatment. It's important to understand the different types of chest
                                    pain,
                                    chest pain causes, and how to identify them to determine when it's an emergency and
                                    when
                                    it's not. Timely management of chest pain can be critical in preventing
                                    complications
                                    and
                                    even saving lives.&nbsp;</p>
                                <h2> What is Chest pain?
                                </h2>
                                <p>Chest pain is a common symptom characterised by discomfort, pressure, squeezing, or
                                    tightness
                                    in the chest area. It can range from mild to severe and can be caused by a variety
                                    of
                                    conditions, including&nbsp;<a href="https://www.draxico.com/diseases/heart-disease">heart-related
                                        problems</a>,&nbsp;<a href="https://www.draxico.com/diseases/chest-infection">lung
                                        conditions</a>,&nbsp;<a href="https://www.draxico.com/diseases/gastroenteritis">gastrointestinal
                                        issues</a>, musculoskeletal problems, and anxiety or panic attacks.&nbsp;</p>
                                <p>Chest pain is a prevalent complaint and can affect individuals of all ages, although
                                    older
                                    adults and those with a history of heart disease are at a higher risk. If you
                                    experience
                                    sudden or severe chest pain, seek immediate medical attention.</p>
                                <h3>What’s the difference between Chest Pain and Heart Attack?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chest-pain/Image.webp"
                                    >
                                </div>
                                <p>Chest pain is a common symptom that can be caused by many different conditions,
                                    including
                                    musculoskeletal problems, respiratory issues, gastrointestinal disorders, and more.
                                    Chest
                                    pain causes a heart attack, which occurs when the blood flow to the heart is blocked
                                    or
                                    reduced. While chest pain can be a symptom of a&nbsp;<a
                                        href="https://www.draxico.com/diseases/heart-disease">heart attack</a>, not all
                                    chest
                                    pain is indicative of a heart attack.&nbsp;</p>
                                <p>Other symptoms, such as shortn ess of breath, sweating, nausea, and light-headedness,
                                    are
                                    also commonly present in a heart attack. It's important to seek medical attention
                                    immediately if you experience sudden or severe chest pain.</p>
                                <p>If you are confused about your condition you can take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/general-health-assessment">Free Self
                                    assessment test for chest pain online</a>.</p>
                                <h2> What is Acute and Chronic Chest Pain?
                                </h2>
                                <p>Acute chest pain refers to sudden and severe discomfort or pain in the chest that may
                                    last
                                    for a few seconds or hours. It is often a symptom of a serious medical condition and
                                    requires immediate medical attention. On the other hand, chronic chest pain refers
                                    to
                                    discomfort or pain in the chest that persists for weeks or months.&nbsp;</p>
                                <p>It can be caused by various factors, including musculoskeletal conditions, anxiety,
                                    acid
                                    reflux, and more. Chronic chest pain should also be evaluated by a healthcare
                                    provider
                                    to
                                    identify the underlying cause and develop an appropriate treatment plan.</p>
                                <h3> What are the main factors that cause Chest Pain?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chest-pain/Image-1.webp"
                                    >
                                </div>
                                <p>Various factors can cause chest pain, some of the common ones include:</p>
                                <ul>
                                    <li>Heart-related conditions such as angina, heart attack, pericarditis,
                                        myocarditis,
                                        etc.
                                    </li>
                                    <li>Respiratory problems such as pneumonia, pleurisy, pulmonary embolism, asthma,
                                        bronchitis, etc.
                                    </li>
                                    <li>Digestive issues such as gastroesophageal reflux disease (GERD), Acid reflux,
                                        gallstones, peptic ulcer disease, etc.
                                    </li>
                                    <li>Musculoskeletal problems such as injuries, strained muscles, rib cage
                                        inflammation,
                                        etc.
                                    </li>
                                    <li>Anxiety&nbsp;symptoms in chest and panic attacks</li>
                                    <li>Other conditions such as shingles, herpes zoster, costochondritis, etc.</li>
                                </ul>
                                <p>It is important to identify the underlying cause of chest pain to determine the
                                    appropriate
                                    treatment plan.</p>
                                <h2>What are the Early Signs and Noticeable Changes in the Body?
                                </h2>
                                <p>The early signs and noticeable changes in the body that may accompany chest pain can
                                    vary
                                    depending on the underlying cause. However, some common chest pain symptoms may
                                    include:</p>
                                <ul>
                                    <li>A sharp or dull ache or pressure in the chest area</li>
                                    <li>Pain or discomfort that may radiate to the neck, jaw, shoulders, arms, or back
                                    </li>
                                    <li>Shortness of breath or difficulty breathing</li>
                                    <li>Dizziness or light-headedness</li>
                                    <li>Nausea or vomiting</li>
                                    <li>Burning sensation in chest</li>
                                    <li>Cold sweats or clammy skin</li>
                                    <li>Rapid or irregular heartbeat</li>
                                    <li>Fatigue or weakness</li>
                                </ul>
                                <p>It is important to note that chest pain may also present without any noticeable
                                    symptoms,
                                    particularly in some cases of silent heart attacks.</p>
                                <h3>When to see a Doctor for Chest Pain?
                                </h3>
                                <p>If you experience chest pain, it is important to seek medical attention promptly. If
                                    you
                                    encounter any of the following,&nbsp;<a href="https://112.gov.in/" rel="nofollow">dial
                                        emergency
                                        services</a> or visit the closest emergency room:</p>
                                <ol>
                                    <li>Severe chest pain that lasts longer than a few minutes</li>
                                    <li>Chest pain that is accompanied by shortness of breath, dizziness, fainting, or
                                        nausea
                                    </li>
                                    <li>Chest pain that radiates to your neck, jaw, shoulder, arm, or back</li>
                                    <li>Chest pain that is accompanied by a feeling of tightness, pressure, or squeezing
                                        in
                                        the
                                        chest
                                    </li>
                                    <li>Chest pain that occurs during or after physical activity</li>
                                    <li>Chest pain that occurs along with other symptoms, such as fever, cough, or
                                        swelling
                                        in
                                        the legs
                                    </li>
                                </ol>
                                <p>If you are experiencing any chest pain that concerns you, it is always best to err on
                                    the
                                    side of caution and seek medical attention immediately.&nbsp;</p>
                                <p>If you are at the initial stages, you can always take a&nbsp;<a
                                    href="https://www.draxico.com/appointment">free online doctor consultation for chest
                                    pain</a>.</p>
                                <h3>Ayurvedic Say on Chest Pain?</h3>
                                <p>According to Ayurveda, chest pain is caused by an imbalance of the three doshas,
                                    particularly
                                    the aggravation of the Vata dosha. The Vata dosha governs movement and circulation
                                    in
                                    the
                                    body and can become imbalanced due to factors such as stress, improper diet, and
                                    lack of
                                    exercise. This can lead to a build-up of toxins (ama) in the body, which can cause
                                    inflammation and pain in the chest area.</p>
                                <h3>Ayurvedic Treatment Options for Chest Pain</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chest-pain/Image-2.webp"
                                    >
                                </div>
                                <p>Chest Pain Ayurvedic treatment depends on the underlying cause and severity of the
                                    condition.
                                    Some common chest pain treatment options are:</p>
                                <ul>
                                    <li><strong>Herbal remedies:</strong> Ayurvedic herbs like Arjuna,&nbsp;<a
                                        href="https://www.draxico.com/products/sudh-ashwagandha-capsule">Ashwagandha</a>,&nbsp;<a
                                        href="https://www.draxico.com/products/brahmi-vati">Brahmi</a>, and&nbsp;<a
                                        href="https://www.draxico.com/products/haldi-tulsi-plus">Tulsi</a> are known to
                                        have
                                        anti-inflammatory and analgesic properties. These herbs can help reduce
                                        inflammation
                                        and
                                        pain in the chest.
                                    </li>
                                    <li><strong>Lifestyle changes:</strong> Ayurveda emphasises the importance of
                                        maintaining a
                                        healthy lifestyle to prevent and manage diseases. Practising yoga, meditation,
                                        and
                                        pranayama can help reduce stress, which is a common cause of chest pain.
                                    </li>
                                    <li><strong>Dietary changes:&nbsp;</strong>Ayurveda recommends avoiding foods that
                                        are
                                        heavy, fried, or spicy, as they can aggravate chest pain. Instead, a diet that
                                        is
                                        rich
                                        in fruits, vegetables, and whole grains is recommended.
                                    </li>
                                    <li><strong>Panchakarma therapy:</strong> This is a type of Ayurvedic therapy that
                                        involves
                                        cleansing the body of toxins through techniques like massage, herbal steam
                                        therapy,
                                        and
                                        enemas. <a href="https://www.draxico.com/panchakarma-treatment/"> Panchakarma
                                            therapy </a> can help improve circulation, reduce inflammation, and
                                        relieve chest pain.
                                    </li>
                                </ul>
                                <p><strong>Ayurvedic medications:</strong> Heavy chest ayurvedic medicine like&nbsp;<a
                                    href="https://www.draxico.com/products/mahayograj-guggulu">Mahayograj Guggulu</a>,&nbsp;<a
                                    href="https://www.draxico.com/products/punarnavadi-guggulu">Punarnavadi Guggulu</a>,
                                    and&nbsp;<a
                                        href="https://www.draxico.com/products/dashamoola-rishta">Dashmoolarishta</a>
                                    are
                                    commonly
                                    used to treat chest pain. These medications can help reduce inflammation, improve
                                    circulation, and relieve pain. However, it is important to consult a qualified
                                    Ayurvedic
                                    practitioner before taking any medications.</p>
                                <h3>Ayurvedic Remedies for Chest Pain
                                </h3>
                                <p>Some Ayurvedic remedies that may help control chest pain include:</p>
                                <ol>
                                    <li><strong>Ginger tea:</strong> Ginger has anti-inflammatory properties and can
                                        help
                                        reduce
                                        chest pain. Boil a few slices of fresh ginger in water for a few minutes, strain
                                        the
                                        liquid, and drink it as tea.
                                    </li>
                                    <li><strong>Garlic</strong>: Garlic has many medicinal properties and can help
                                        reduce
                                        inflammation and improve heart health. You can eat 1-2 cloves of raw garlic
                                        daily or
                                        take garlic supplements.
                                    </li>
                                    <li><strong>Turmeric milk:</strong> Turmeric has anti-inflammatory and antioxidant
                                        properties that can help reduce chest pain. Add a teaspoon of turmeric powder to
                                        a
                                        glass
                                        of warm milk and drink it before bedtime.
                                    </li>
                                    <li><strong>Ajwain water:</strong> Ajwain or carom seeds can help relieve chest pain
                                        due
                                        to
                                        gas and indigestion pain in chest, which can sometimes cause chest pain. Boil a
                                        teaspoon
                                        of ajwain seeds in a cup of water, strain the liquid, and drink it warm.
                                    </li>
                                    <li><strong>Fennel seeds:</strong> Fennel seeds have a soothing effect on the
                                        digestive
                                        system and can help relieve chest pain caused by indigestion. Chew on a teaspoon
                                        of
                                        fennel seeds after meals or boil a teaspoon of fennel seeds in a cup of water,
                                        strain
                                        the liquid, and drink it as tea.
                                    </li>
                                    <li><strong>Aloe vera juice:</strong> Aloe vera has anti-inflammatory properties and
                                        can
                                        help reduce inflammation in the chest area. Drink a quarter cup of aloe vera
                                        juice
                                        daily
                                        to help reduce chest pain.
                                    </li>
                                </ol>
                                <p>It's important to note that these remedies may help alleviate mild chest pain but may
                                    not
                                    be
                                    effective for more severe cases. It's always best to consult with a healthcare
                                    professional
                                    if you're experiencing persistent chest pain.</p>
                                <h3> What to Eat and Not
                                </h3>
                                <p>Here are some Ayurvedic recommendations for foods to eat and avoid in case of chest
                                    pain-
                                </p>
                                <h4>Foods to Eat:
                                </h4>
                                <ul>
                                    <li>Cooked whole grains such as rice, quinoa, and barley</li>
                                    <li>Mung dal (split yellow lentils)</li>
                                    <li>Vegetables such as asparagus, bitter gourd, and pumpkin</li>
                                    <li>Fruits such as pomegranates, grapes, and apples</li>
                                    <li>Spices such as turmeric, cumin, coriander, and fennel</li>
                                    <li>Herbal teas such as ginger and Tulsi (holy basil)</li>
                                </ul>
                                <h4> Foods to Avoid:
                                </h4>
                                <ul>
                                    <li>Fried and fatty foods</li>
                                    <li>Processed and packaged foods</li>
                                    <li>Spicy and acidic foods</li>
                                    <li>Dairy products such as cheese and ice cream</li>
                                    <li>Meat and animal products</li>
                                    <li>Carbonated and caffeinated beverages</li>
                                </ul>
                                <h3>Recommended Ayurvedic Herbs and Medication for Chest Pain
                                </h3>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/hridayarnava-ras">Hridyarnav ras</a>
                                    </li>
                                    <li>Arjunarishta</li>
                                    <li>Brahmi ghrita</li>
                                    <li><a href="https://www.draxico.com/products/mahanarayan-oil">Mahanarayan Taila</a>
                                    </li>
                                    <li>Prasarini Taila</li>
                                    <li>Trayodashang Guggulu</li>
                                    <li>Shatavari Guda</li>
                                    <li><a
                                        href="https://www.draxico.com/products/ashwagandha-rishta">Ashwagandharishta</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/punarnavadi-guggulu">Punarnavadi
                                        Kashayam</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/dashamoola-rishta">Dashmool kwath</a>
                                    </li>
                                    <li>Arjuna</li>
                                    <li><a href="https://www.draxico.com/products/ashwagandha-rishta">Ashwagandha</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/brahmi-vati">Brahmi</a></li>
                                    <li>Guggulu</li>
                                    <li>Punarnava</li>
                                    <li>Shatavari</li>
                                </ul>
                                <p>Please note that these are just a few examples and should not be used without
                                    consulting
                                    an
                                    Ayurvedic practitioner or doctor. The dosage and duration of heavy chest ayurvedic
                                    treatment
                                    may vary depending on the individual's condition and other factors.</p>
                                <h4> A Short Note from Dr. Axico
                                </h4>
                                <p>Other illnesses can cause chest discomfort, even though the majority of people
                                    associate
                                    it
                                    with a heart attack. Recognize the warning signs of a heart attack and get help as
                                    soon
                                    as
                                    you feel any discomfort.&nbsp;</p>
                                <p>Tell your healthcare professional what you were doing when you experienced chest
                                    pain,
                                    and
                                    make a mental note of it. Your provider may be able to diagnose you better if you
                                    can
                                    describe the type of pain you're experiencing and where it is.</p>
                                <p>Along with Ayurvedic doctor online consultations, Dr. Axico provides holistic health
                                    services. Additionally, patients can buy Ayurvedic medicine online or offline from
                                    Dr.
                                    Axico's medical centres or websites.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4> How do I know if chest pain is serious?
                                        </h4>
                                        <p>If you experience chest pain, it is important to seek medical attention immediately,
                                            especially if it is severe, accompanied by shortness of breath, sweating, or
                                            light-headedness. Chest pain can indicate a serious condition such as a heart attack
                                            or
                                            pulmonary embolism, which requires urgent treatment.
                                        </p>
                                        <h4> Does serious chest pain go away?
                                        </h4>
                                        <p>It depends on the cause of the chest pain. Serious chest pain caused by a heart
                                            attack or
                                            other cardiac conditions will not go away on its own and requires immediate medical
                                            attention. However, chest pain caused by other conditions such as indigestion or
                                            muscle
                                            strain may go away with rest or simple treatments. It's important to seek medical
                                            attention
                                            if chest pain is severe, prolonged, or accompanied by other symptoms.
                                        </p>
                                        <h4>Where is heart pain located?
                                        </h4>
                                        <p>Heart pain, also known as angina, is typically located in the chest, often in the
                                            center
                                            or
                                            left side. The pain may also be felt in the arms, shoulders, neck, jaw, back, or
                                            stomach.
                                            The location of the pain can vary depending on the individual and the underlying
                                            cause.&nbsp;</p>
                                        <p>It is important to note that not all chest pain is related to the heart, and chest
                                            pain
                                            should always be evaluated by a healthcare professional to determine the cause and
                                            appropriate treatment.</p>
                                        <h4>Can gas cause chest pain?
                                        </h4>
                                        <p>Yes, gas can cause chest pain. When chest pain due to gas builds up in the digestive
                                            system,
                                            it can create pressure that may cause discomfort or pain in the chest. Gas pain in
                                            the
                                            chest
                                            sometimes is mistaken for heart-related chest pain. Gas-related chest pain is often
                                            accompanied by other symptoms such as bloating, belching, or indigestion.
                                        </p>
                                        <h4> Can high bp cause chest pain?
                                        </h4>
                                        <p>Yes, high blood pressure (hypertension) can cause chest pain, especially if it is
                                            very
                                            high
                                            or uncontrolled. This is because high blood pressure can cause the heart to work
                                            harder,
                                            which can lead to chest pain or discomfort. It can also increase the risk of heart
                                            attack,
                                            which is a medical emergency. It is important to monitor and control blood pressure
                                            levels
                                            to reduce the risk of chest pain and other complications.
                                        </p>
                                        <h4> Is left-sided chest pain serious?
                                        </h4>
                                        <p>Left-sided chest pain can be a sign of a serious condition like a heart attack,
                                            angina,
                                            or
                                            pulmonary embolism. It can also be caused by less serious conditions like acid
                                            reflux
                                            symptoms, chest pain or muscle strain. If you experience left-sided chest pain, it's
                                            important to seek medical attention to rule out any serious underlying conditions.
                                        </p>
                                        <h4> How do you relieve a tight chest?
                                        </h4>
                                        <p>Relieving a tight chest may involve various techniques such as deep breathing
                                            exercises,
                                            using a humidifier or steam, taking a warm bath, applying a warm compress to the
                                            chest,
                                            drinking warm fluids, and avoiding triggers such as smoke or allergens. It is
                                            important
                                            to
                                            seek medical attention if the tightness persists or is accompanied by other
                                            symptoms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'cirrhosis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Cirrhosis: Causes, Stages & Ayurvedic Medicine
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cirrhosis/Image-1.webp"
                                    >
                                </div>
                                <p>Liver cirrhosis is a long-term, worsening liver disease in which scar tissue replaces
                                    good
                                    liver tissue, making it hard for the liver to work properly. This damage is caused
                                    by
                                    things
                                    like drinking too much alcohol over a long period, having chronic viral hepatitis,
                                    having
                                    non-alcoholic liver cirrhosis disease, having autoimmune hepatitis, or having a
                                    genetic
                                    problem.&nbsp;<a href="https://www.draxico.com/">Ayurvedic doctor online
                                        consultation</a>
                                    would be a saviour in Cirrhosis.</p>
                                <p>Scarring makes it harder for the liver to do its important jobs, like cleaning toxins
                                    out
                                    of
                                    the blood, making bile, and saving energy. Depending on the stage and seriousness of
                                    the
                                    disease, symptoms of liver cirrhosis can include tiredness, weakness, loss of
                                    appetite,
                                    weight loss, nausea,&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">stomach
                                        pain</a>,
                                    jaundice, and swelling in the legs and belly. If liver cirrhosis isn't treated, it
                                    can
                                    lead
                                    to liver failure, liver cancer, and other major problems.</p>
                                <h3><strong>What causes Cirrhosis and what are the signs of it?</strong></h3>
                                <p>Cirrhosis of the liver can be caused by several things, such as:</p>
                                <ul>
                                    <li>Alcoholic liver cirrhosis: Heavy drinking over a long period can hurt liver
                                        cells
                                        and
                                        cause cirrhosis.
                                    </li>
                                    <li><a href="https://www.draxico.com/diseases/hepatitis-b">Hepatitis B</a> and&nbsp;<a
                                        href="https://www.draxico.com/diseases/hepatitis-c">Hepatitis C</a> viruses can
                                        cause
                                        inflammation and damage to the liver, which can lead to cirrhosis.
                                    </li>
                                    <li>Non-alcoholic fatty liver disease (NAFLD): Obesity and other metabolic problems
                                        can
                                        cause fat to build up in the liver, which can cause inflammation and scars.&nbsp;<a
                                            href="https://www.draxico.com/bmi-calculator">Check your BMI for free
                                            online</a>
                                        at
                                        Dr Axico.
                                    </li>
                                    <li>Autoimmune hepatitis: The immune system of the body can attack the liver,
                                        causing
                                        inflammation and damage that can lead to cirrhosis.
                                    </li>
                                    <li>Genetic disorders: Hemochromatosis, Wilson's disease, and alpha-1 antitrypsin
                                        deficiency
                                        are all genetic diseases that can damage the liver and lead to cirrhosis.
                                    </li>
                                </ul>
                                <h4><strong>Cirrhosis symptoms:&nbsp;</strong></h4>
                                <p>The signs and symptoms of cirrhosis depend on how far along the disease is and how
                                    bad it
                                    is.
                                    In the stages of cirrhosis of the liver, you might not be able to tell if anything
                                    is
                                    wrong.
                                    As the illness gets worse, there may be signs like:</p>
                                <ul>
                                    <li>Weakness and lack of energy</li>
                                    <li>Loss of hunger and losing weight</li>
                                    <li>Feeling sick and puking</li>
                                    <li>Pain and swelling in the middle</li>
                                    <li>Jaundice is when the skin and eyes turn yellow.</li>
                                    <li>Itchy skin</li>
                                    <li>Dark urine</li>
                                    <li>Stools that are pale or clay-coloured</li>
                                    <li>Legs and feet that are swollen</li>
                                </ul>
                                <h3><strong>What are the stages of Cirrhosis?</strong></h3>
                                <p>A chronic liver condition called cirrhosis is characterised by increasing scarring
                                    and
                                    loss
                                    of liver tissue. Based on the level and seriousness of&nbsp;<a
                                        href="https://www.draxico.com/diseases/liver-disease">liver disease</a>, there
                                    are
                                    typically four stages of cirrhosis:</p>
                                <p><strong>Stage 1:</strong> There may be minimal liver damage in the first stage of
                                    cirrhosis,
                                    with few or no symptoms. However, at this point, the liver may begin to work less
                                    efficiently.</p>
                                <p><strong>Stage 2:</strong> As cirrhosis advances, the liver becomes more severely
                                    damaged
                                    and
                                    scarred. Fatigue, stomach discomfort, and jaundice are just a few of the symptoms
                                    that
                                    may
                                    start to show as the liver function gets increasingly damaged.</p>
                                <p><strong>Stage 3:</strong> The liver is severely scarred and substantially less
                                    functional
                                    at
                                    this point. Ascites (fluid buildup in the abdomen), hepatic encephalopathy (brain
                                    dysfunction brought on by liver illness), and varicocele haemorrhage (bleeding from
                                    swollen
                                    veins in the oesophagus or stomach) may develop as the symptoms worsen.</p>
                                <p><strong>Stage 4:</strong> Also known as decompensated cirrhosis, this is the most
                                    advanced
                                    stage of cirrhosis. The liver is extensively damaged and has considerable scarring
                                    at
                                    this
                                    point. Serious problems such as liver failure, portal hypertension, and
                                    hepatocellular
                                    carcinoma (liver cancer) may develop as a result of the symptoms getting worse.
                                    Patients
                                    with decompensated cirrhosis have a dismal prognosis without liver transplantation.
                                </p>

                                <h4><strong>What are the Early Signs and Noticeable Changes in the Body</strong>
                                    <div class="text-center">
                                        <img alt="" class="my-2 img-fluid"
                                             src="assets/img/diseases/diseasesImages/cirrhosis/Image-2.webp"
                                        >
                                    </div>
                                </h4>
                                <p>The circumstance or environment determines early indications and physical changes.
                                    However,
                                    these early symptoms and changes may suggest a health issue:</p>
                                <ul>
                                    <li><strong>Fatigue:</strong> Viruses, hormone abnormalities, and chronic disorders
                                        may
                                        cause fatigue.
                                    </li>
                                    <li><strong>Appetite changes:</strong> Loss or increase in appetite might suggest
                                        digestive
                                        difficulties, hormone imbalances, or mental stress.
                                    </li>
                                    <li><strong>Weight changes:</strong> unexpected weight loss or increase without diet
                                        or
                                        activity modifications may indicate thyroid, diabetes, or&nbsp;<a
                                            href="https://www.draxico.com/diseases/gastroenteritis">gastrointestinal
                                            diseases</a>.
                                    </li>
                                    <li><strong>Digestive issues:</strong> Bloating, gas, indigestion, constipation, or
                                        diarrhoea may suggest digestive problems, dietary intolerances, or
                                        gastrointestinal
                                        infections.
                                    </li>
                                    <li><strong>Skin changes:</strong> new moles, color changes, rashes, or persistent
                                        itching
                                        may indicate skin problems, allergies, or systemic infections.
                                    </li>
                                    <li><strong>Changes in urination:</strong> Frequent urination, urgency, blood in the
                                        urine,
                                        or urine color or odor might indicate urinary tract infections, kidney
                                        difficulties,
                                        or
                                        other urinary illnesses.
                                    </li>
                                </ul>
                                <p>Depression, anxiety, and stress may cause mood swings.</p>
                                <p>Sleep problems, hormone imbalances, and psychological issues may cause difficulty
                                    falling
                                    asleep, staying asleep, or daytime drowsiness.</p>
                                <p>Joint or muscle discomfort: Unexplained joint or muscle pain, stiffness, swelling, or
                                    restricted range of motion may indicate inflammation, autoimmune illnesses, or
                                    musculoskeletal problems.</p>
                                <p>Changes in menstrual cycle, flow, or other anomalies may suggest hormonal imbalances,
                                    polycystic ovarian syndrome (PCOS), or reproductive health difficulties.</p><h4>
                                <strong>When
                                    to see a Doctor for Cirrhosis?</strong></h4>
                                <p>If you think you might have cirrhosis or are showing signs of liver disease, it's
                                    important
                                    to see a doctor. Here are some cases in which you should go to the doctor:</p>
                                <ul>
                                    <li>Fatigue that lasts for more than a few days or is very bad, loss of hunger,
                                        nausea,
                                        weight loss, stomach pain or discomfort, or other symptoms that don't go away.
                                    </li>
                                    <li>Jaundice, which is when the skin and whites of the eyes turn yellow and don't
                                        get
                                        better
                                        in a week or two.
                                    </li>
                                    <li>Itching that is very bad or doesn't get better with over-the-counter
                                        medicines.
                                    </li>
                                    <li>Leg, ankle, or foot swelling that doesn't go away when you rest or raise your
                                        legs.
                                    </li>
                                    <li>Bruising or bleeding easily, such as a nose that bleeds often or gums that
                                        bleed.
                                    </li>
                                    <li>Signs of cognitive decline include confusion, forgetting, and other problems.
                                    </li>
                                    <li>If you vomit blood, have black stools, or show any other signs of internal
                                        bleeding,
                                        call your doctor right away.
                                    </li>
                                    <li>Ascites, which is when fluid builds up in the belly, hepatic encephalopathy,
                                        which
                                        is
                                        when the brain doesn't work right because of liver disease, or variceal
                                        bleeding,
                                        which
                                        is when swollen veins in the oesophagus or stomach start to leak.
                                    </li>
                                </ul>
                                <p>If you have a history of drinking too much, getting hepatitis B or C, or other things
                                    that
                                    put you at risk for liver disease, you should also talk to a doctor about your
                                    worries
                                    and
                                    get checked for cirrhosis or other liver problems. Early detection and&nbsp;liver
                                    cirrhosis
                                    treatment can help avoid problems and make the result better.&nbsp;</p>
                                <h3><strong>Ayurvedic Say on Cirrhosis?</strong></h3>
                                <p>Cirrhosis of the liver has been considered to be either Yakritodara or Yakritvriddhi
                                    in
                                    Ayurvedic medical practices. According to the principles of Ayurveda, the liver is
                                    the
                                    organ
                                    that is in charge of the transformation and metabolism of a wide variety of
                                    substances,
                                    including food, poisons, and medications. Cirrhosis can occur when the liver is
                                    damaged
                                    to
                                    the point that it cannot perform its normal duties. This results in the buildup of
                                    toxins,
                                    which in turn leads to the development of cirrhosis.</p>
                                <p>According to Ayurveda, liver cirrhosis is caused by an imbalance in one of the three
                                    doshas,
                                    also known as body humour. These doshas are vata, pitta, and kapha. Pitta and Kapha
                                    are
                                    the
                                    primary doshas that are implicated in cirrhosis of the liver. When the Pitta dosha
                                    is
                                    inflamed, it results in inflammation and the loss of liver cells, which ultimately
                                    leads
                                    to
                                    damage to the liver. It is the Kapha dosha that is responsible for the buildup of
                                    fluids
                                    in
                                    the liver, which ultimately results in the organ being swollen and larger.</p>
                                <p>According to Ayurveda, liver cirrhosis is brought on by a confluence of
                                    circumstances,
                                    the
                                    most important of which are an unhealthy diet, regular intake of alcohol, viral
                                    hepatitis,
                                    and a hereditary susceptibility. According to Ayurveda, an imbalance in digestion
                                    and
                                    metabolism of food, as well as the buildup of toxins in the body, can also
                                    contribute to
                                    the
                                    development of liver cirrhosis. In addition, toxins can be accumulated in the body
                                    when
                                    there is an imbalance in the digestion and metabolism of food.</p><h4><strong>How
                                Ayurveda
                                Can Help to Control Cirrhosis?</strong></h4>
                                <p>According to Ayurveda liver cirrhosis is a condition brought on by the build-up of
                                    poisons in
                                    the body. The build-up of these toxins damages and inflames the liver, which can
                                    ultimately
                                    lead to cirrhosis.&nbsp;</p>
                                <ul>
                                    <li>Ayurveda provides several methods for managing and controlling cirrhosis,
                                        including
                                        dietary adjustments, lifestyle changes, and herbal treatments. Here are some
                                        methods
                                        that Ayurveda can aid with cirrhosis control:
                                    </li>
                                    <li>Dietary modifications: According to Ayurveda, some foods can assist the liver to
                                        cleanse
                                        and decrease inflammation. Green leafy vegetables, bitter gourd, turmeric,
                                        ginger,
                                        and
                                        amla are a few of the meals that are suggested for cirrhosis.
                                    </li>
                                    <li>Modifications to lifestyle: Ayurveda stresses the value of keeping a healthy
                                        lifestyle
                                        to prevent cirrhosis. This includes abstaining from alcohol and cigarettes,
                                        engaging
                                        in
                                        regular physical activity, and practicing yoga and meditation to reduce stress.
                                    </li>
                                    <li>Herbal therapies: To promote liver function and minimize inflammation, ayurvedic
                                        practitioners may also provide herbal medicines. Herbs including milk thistle,
                                        dandelion
                                        root, and licorice root are frequently utilized.
                                    </li>
                                </ul>
                                <p>However, including Ayurvedic techniques in a holistic <a
                                    href="https://www.draxico.com/panchakarma-treatment/">panchakarma therapeutic
                                    strategy</a>
                                    may aid in
                                    enhancing liver function and lowering the risk of cirrhosis-related problems.</p>
                                <h4>
                                    <strong>Ayurvedic Remedies for Cirrhosis</strong></h4>
                                <p>Liver cirrhosis cure in Ayurveda - Liver scarring is cirrhosis. Ayurveda improves
                                    liver
                                    function and treats cirrhosis symptoms but cannot cure it. You need medical or
                                    ayurvedic
                                    advice. Cirrhosis remedies:</p>
                                <p>Aloe vera improves liver function. Aloe vera juice cleanses the liver. Use
                                    unsweetened
                                    aloe
                                    juice.</p>
                                <p>Bhringraj (Eclipta alba): This Ayurvedic herb supports liver and cell regeneration.
                                    Ayurvedic
                                    powders are available.</p>
                                <p>Amla: Vitamin C and antioxidants in amla protect the liver from free radicals. Fresh
                                    amla
                                    juice is liver-friendly.</p>
                                <p>Ayurvedic papaya seeds promote liver function. Grind and drink lemon juice or water.
                                    Increase
                                    the dosage as tolerated.</p>
                                <p>Anti-inflammatory and antioxidant turmeric curcumin. Reduces inflammation and
                                    regenerates
                                    liver cells. Turmeric milk or food.</p>
                                <p>Dandelion root: Liver-stimulating. It boosts the liver, bile, and detoxifying. Boil
                                    dried
                                    dandelion root for 10-15 minutes and drink 1-2 times a day.</p><h4><strong>What to
                                eat
                                and
                                not to eat:</strong></h4>
                                <p>A healthy, balanced diet that promotes the body's natural healing processes is
                                    prioritized in
                                    Ayurveda. Ayurvedic food suggestions for liver cirrhosis may include the
                                    following:</p>
                                <h4>
                                    <strong>what to eat:</strong></h4>
                                <ul>
                                    <li>Fresh produce is a good source of vitamins, minerals, and antioxidants that can
                                        help
                                        the
                                        liver stay healthy.
                                    </li>
                                    <li>Whole grains: <a
                                        href="https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/whole-grains/"
                                        rel="nofollow">Whole grains</a> are rich in fiber and nutrients that can help
                                        control
                                        digestion and metabolism. Examples of such grains are brown rice, quinoa, and
                                        millet.
                                    </li>
                                    <li>Lean proteins: Healthy sources of lean protein that can improve liver function
                                        include
                                        fish, chicken, and turkey.
                                    </li>
                                    <li>Foods high in healthy fats, such as avocados, almonds, and seeds, can assist to
                                        decrease
                                        inflammation and maintain the health of the liver.
                                    </li>
                                    <li>Herbal teas: Teas with natural anti-inflammatory and digestive properties, such
                                        as
                                        chamomile, ginger, and licorice, are available.
                                    </li>
                                </ul>
                                <h4><strong>what not to eat:</strong>&nbsp;</h4>
                                <ul>
                                    <li>Alcohol: Since it is one of the main contributors to liver cirrhosis, alcohol
                                        should
                                        be
                                        avoided.
                                    </li>
                                    <li>Foods that have been processed: Foods that have been packed and processed
                                        frequently
                                        include high levels of salt, sugar, and bad fats, which can strain the liver
                                        even
                                        more.
                                    </li>
                                    <li>Foods that are fried or fatty might be hard for the liver to metabolize and can
                                        exacerbate liver damage and inflammation.
                                    </li>
                                    <li>Red meat: Red meat may include a lot of saturated fat, which might harm the
                                        liver.
                                    </li>
                                    <li>Caffeine: Caffeine can cause dehydration and increase liver stress. If you have
                                        liver
                                        cirrhosis, it is advised to avoid or restrict your caffeine intake.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Cirrhosis:</strong></h4>
                                <p>Here is the best Ayurvedic medicine for liver cirrhosis:</p>
                                <ol>
                                    <li>Kalmegh (Andrographis paniculata) is an Ayurvedic plant often used to support
                                        liver
                                        health. It contains anti-inflammatory and hepatoprotective qualities and is
                                        thought
                                        to
                                        assist persons with liver illness to improve their liver function.
                                    </li>
                                    <li>Kutki Churna: Kutki Churna is a powder prepared from the Kutki herb's root. It
                                        is
                                        widely
                                        used in Ayurvedic medicine to promote liver health. It contains
                                        anti-inflammatory
                                        and
                                        hepatoprotective qualities and is thought to assist persons with liver illness
                                        to
                                        improve their liver function.Kalmegh (Andrographis paniculata) is an Ayurvedic
                                        plant
                                        often used to support liver health. It contains anti-inflammatory and
                                        hepatoprotective
                                        qualities and is thought to assist persons with liver illness to improve their
                                        liver
                                        function.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/arogyavardhini-vati">Arogyavardhini
                                        Vati</a>
                                        is an ayurvedic treatment for liver cirrhosis that comprises a combination of
                                        herbs
                                        such
                                        as Shilajit, Guggul, Haritaki, Amalaki, and Bibhitaki. It is thought to aid with
                                        liver
                                        function, immunity, and inflammation reduction.
                                    </li>
                                    <li>Bhumi amla (Phyllanthus niruri) is an Ayurvedic plant that has traditionally
                                        been
                                        used
                                        to enhance liver function. It contains anti-inflammatory and antioxidant
                                        qualities,
                                        and
                                        it has been demonstrated to help protect liver cells from harm.
                                    </li>
                                    <li>Punarnava (Boerhavia diffusa) is a plant that has long been utilized in
                                        Ayurvedic
                                        medicine for liver health. It has diuretic qualities and has been demonstrated
                                        to
                                        aid in
                                        the reduction of liver edoema.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala</a>:
                                        Triphala
                                        is
                                        an Ayurvedic medicine that is made up of three fruits: amla, haritaki, and
                                        bibhitaki. It
                                        contains anti-inflammatory and antioxidant qualities and has been demonstrated
                                        to
                                        aid
                                        enhance liver function.
                                    </li>
                                </ol>
                                <p>It should be noted that these herbs and drugs should only be used as directed.</p>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>The result of liver disease and its complications is cirrhosis of the liver. Your
                                    liver
                                    doesn't work correctly as a result of cirrhosis. Many of the processes and
                                    activities
                                    that
                                    keep you alive depend on your liver. Don't give up if you have end-stage cirrhosis.
                                    Connect
                                    with Dr. Axico Ayurvedic doctor online consultation to lead a healthy life.
                                    Moreover,
                                    you
                                    can buy Ayurvedic medicine online or offline too.&nbsp;</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>What food is good for liver repair?</strong>
                                        </h4>
                                        <p>Several foods are beneficial for liver health and can support its natural repair
                                            processes.
                                            Here are some foods that are considered good for liver repair:</p>
                                        <ol>
                                            <li>Leafy greens: Foods like spinach, kale, and collard greens are rich in
                                                antioxidants
                                                and
                                                chlorophyll, which can help protect the liver and support its detoxification
                                                functions.
                                            </li>
                                            <li>Cruciferous vegetables: Vegetables such as broccoli, cauliflower, Brussels
                                                sprouts,
                                                and
                                                cabbage contain compounds that support liver detoxification and promote the
                                                production
                                                of enzymes involved in the detoxification processes.
                                            </li>
                                            <li>Fatty fish: Fish like salmon, sardines, and mackerel are high in omega-3 fatty
                                                acids,
                                                which have anti-inflammatory properties and may help reduce liver inflammation.
                                            </li>
                                            <li>Berries: Berries such as blueberries, strawberries, and raspberries are rich in
                                                antioxidants that can help protect the liver from damage caused by free
                                                radicals.
                                            </li>
                                            <li>Garlic: Garlic contains compounds that activate liver enzymes responsible for
                                                eliminating toxins from the body. It also has anti-inflammatory properties.
                                            </li>
                                            <li>Turmeric: Curcumin, the active compound in turmeric, has powerful antioxidant
                                                and
                                                anti-inflammatory properties that can support liver health and help reduce liver
                                                damage.
                                            </li>
                                            <li>Green tea: Green tea is rich in antioxidants known as catechins, which can help
                                                protect
                                                the liver and promote its detoxification processes.
                                            </li>
                                        </ol>
                                        <h4><strong>Can you live without a liver?</strong></h4>
                                        <p>The liver is an indispensable organ, and living without a functioning liver is not
                                            possible.
                                            It is crucial to take care of your liver through a healthy lifestyle, including a
                                            balanced
                                            diet, regular exercise, and avoiding behaviors that can damage the liver, such as
                                            excessive
                                            alcohol consumption or the use of certain medications without medical
                                            supervision.</p>
                                        <h4><strong>At what age cirrhosis starts?</strong></h4>
                                        <p>Cirrhosis, an advanced liver disease, may develop at any age. The underlying
                                            aetiology of
                                            the
                                            illness may affect the age at which cirrhosis begins.</p>
                                        <p>Chronic hepatitis B or C, excessive alcohol use, and <a
                                            href="https://www.nhs.uk/conditions/non-alcoholic-fatty-liver-disease/#:~:text=Non%2Dalcoholic%20fatty%20liver%20disease%20(NAFLD)%20is%20the%20term,cirrhosis%2C%20if%20it%20gets%20worse."
                                            rel="nofollow">NAFLD</a> are the main causes of cirrhosis
                                            in adulthood. These disorders may cause cirrhosis over time. Cirrhosis develops
                                            after
                                            years
                                            or decades of liver damage.</p>
                                        <p>Cirrhosis may affect children and teenagers. Genetic or metabolic liver diseases
                                            cause
                                            cirrhosis in children. Biliary atresia, alpha-1 antitrypsin deficiency, Wilson's
                                            disease,
                                            and autoimmune hepatitis. Cirrhosis may occur sooner than in persistent viral
                                            infections
                                            or
                                            alcohol-related liver disease.</p>
                                        <p>Cirrhosis development depends on the underlying cause, individual health conditions,
                                            lifestyle choices, and availability of medical treatment. Early identification,
                                            therapy,
                                            and
                                            lifestyle changes may delay and improve cirrhosis. If you have liver health issues
                                            or
                                            think
                                            you may have liver disease, see a doctor who can evaluate, diagnose, and advise
                                            you.</p>
                                        <h4><strong>How can I make my liver Healthy again at home?</strong></h4>
                                        <p>Home liver health tips:</p>
                                        <ul>
                                            <li>Balance your diet with fruits, vegetables, whole grains, lean meats, and healthy
                                                fats.
                                                Reduce processed meals, fizzy drinks, and saturated fats.
                                            </li>
                                            <li>Hydrate: Drink enough water daily to improve liver function and drain toxins.
                                            </li>
                                            <li>Alcohol abuse may harm the liver. If you have liver issues, avoid alcohol or
                                                follow
                                                prescribed restrictions.
                                            </li>
                                            <li>Maintain a healthy weight with exercise and a balanced diet. Fatty liver disease
                                                and
                                                liver damage may result from obesity.
                                            </li>
                                            <li>Smoking increases liver disease risk and impairs liver function. Smoking
                                                cessation
                                                improves liver health.
                                            </li>
                                            <li>Safe pharmaceutical use: Follow dose directions and avoid overusing medicines,
                                                particularly liver-damaging ones. If you have medication concerns, see a doctor.
                                            </li>
                                            <li>Avoid liver-damaging poisons, chemicals, and pollutants. Handle dangerous
                                                compounds
                                                safely.
                                            </li>
                                            <li>Regular exercise improves circulation, weight, and liver function.</li>
                                            <li>Stress management may improve liver health. Reduce stress via exercise,
                                                meditation,
                                                deep
                                                breathing, or hobbies.
                                            </li>
                                            <li>Check-ups and liver function tests: See your doctor regularly. This may identify
                                                liver
                                                abnormalities early.
                                            </li>
                                        </ul>
                                        <h4><strong>I have swelling in my liver; does it mean I have
                                            cirrhosis?&nbsp;</strong></h4>
                                        <p>Some potential liver cirrhosis causes swelling include fatty liver disease, hepatitis
                                            (viral
                                            or autoimmune), liver infections, certain medications, autoimmune hepatitis, liver
                                            tumors
                                            (benign or malignant), and others. Each of these conditions has different underlying
                                            causes,
                                            mechanisms, and treatment approaches.</p>
                                        <p>To determine the cause of liver swelling and whether it is related to cirrhosis, it's
                                            crucial
                                            to consult with a healthcare professional. They can evaluate your symptoms, perform
                                            appropriate diagnostic tests, such as liver function tests, imaging studies, or a
                                            liver
                                            biopsy if needed, and provide an accurate diagnosis.</p>
                                        <p>If you have concerns about liver health or are experiencing symptoms such as liver
                                            swelling,
                                            it's essential to seek medical attention. Only a healthcare professional can provide
                                            a
                                            proper evaluation, diagnosis, and guidance based on your specific
                                            situation.&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'common-cold'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Common Cold: Types, Causes, & Ayurvedic Healing
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>The common cold is a viral infection of the upper respiratory tract that affects the
                                    nose,
                                    throat, sinuses, and sometimes the ears. It is caused by a family of viruses known
                                    as
                                    rhinoviruses, coronaviruses, and adenoviruses.&nbsp;To quickly cure the common cold,
                                    an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a> might
                                    be a
                                    wise
                                    decision.</p>
                                <h3><strong>What are the symptoms of the Common Cold?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/common-cold/Image.webp"
                                    >
                                </div>
                                <p>The below-mentioned symptoms can be useful to catch a cold symptom:</p>
                                <ul>
                                    <li>Runny or stuffy nose</li>
                                    <li>Sore throat</li>
                                    <li><a href="https://www.draxico.com/diseases/cough">Cough</a></li>
                                    <li>Sneezing</li>
                                    <li>Fatigue</li>
                                    <li><a href="https://www.draxico.com/diseases/headaches">Headache</a></li>
                                    <li>Body aches</li>
                                    <li>Mild fever</li>
                                </ul>
                                <h3><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/common-cold/Image-2.webp"
                                    >
                                </div>
                                <p>Early signs of a common cold can include a scratchy or sore throat, nasal congestion,
                                    and
                                    a
                                    runny nose. These symptoms can appear as early as one to three days after exposure
                                    to
                                    the
                                    virus.</p>
                                <p>As the cold progresses, the nasal discharge may become thicker and yellow or green.
                                    Coughing
                                    may also become more pronounced, and there may be common colds and fever, especially
                                    in
                                    children. In some cases, the symptoms may last for a week or more.</p>
                                <h3><strong>Stages of Common Cold Which Leads to Serious Illness</strong></h3>
                                <p>The common cold typically has several stages. In most cases, the symptoms are mild to
                                    moderate and resolve on their own within a week or two.</p><h4><strong>The stages of
                                a
                                common cold include:</strong></h4>
                                <ul>
                                    <li><strong>&nbsp;Incubation period:</strong> The period between when you are
                                        exposed to
                                        the
                                        virus and when symptoms appear, which typically ranges from one to three days.
                                    </li>
                                    <li><strong>Early symptoms:</strong> The first symptoms of a cold are usually a
                                        scratchy
                                        or
                                        sore throat, nasal congestion, and a runny nose.
                                    </li>
                                    <li><strong>Peak symptoms:</strong> The symptoms of a cold typically peak around
                                        days
                                        three
                                        to five, and may include coughing, sneezing, fatigue, headache, and body aches.
                                    </li>
                                    <li><strong>Recovery:</strong> After the peak symptoms, the body starts to recover
                                        and
                                        the
                                        minor cold symptoms gradually improve. This period usually lasts from five to
                                        seven
                                        days.
                                    </li>
                                </ul>
                                <p>In rare cases, a common cold can lead to more serious complications such as pneumonia
                                    or&nbsp;<a
                                        href="https://www.draxico.com/diseases/bronchitis">bronchitis</a>, especially in
                                    individuals
                                    with weakened immune systems or pre-existing respiratory conditions. It is important
                                    to
                                    seek
                                    medical attention if you experience severe symptoms, such as high fever, difficulty
                                    breathing,&nbsp;<a href="https://www.draxico.com/diseases/chest-infection">chest
                                        pain</a>,
                                    or persistent cough.</p>
                                <h3><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h3>
                                <p>Children and babies tend to get more colds than adults because their immune systems
                                    are
                                    not
                                    fully developed. The immune system is responsible for fighting off infections,
                                    including
                                    the
                                    viruses that the common cold causes.</p>
                                <p>Babies are born with some immunity that they receive from their mothers, but this
                                    protection
                                    starts to fade after a few weeks or months. As a result, babies are more susceptible
                                    to
                                    infections and need time to develop their immune systems.</p>
                                <p>Young children are also more likely to catch colds because they tend to have more
                                    contact
                                    with other children, especially in settings like daycare or preschool, where
                                    infections
                                    can
                                    easily spread from one child to another. Additionally, young children may not have
                                    developed
                                    good hygiene habits like washing their hands regularly, which can increase their
                                    risk of
                                    catching colds.</p><h4><strong>How long is the common cold contagious?</strong></h4>
                                <p>The common cold is contagious and can be spread from person to person through
                                    respiratory
                                    droplets when an infected person coughs or sneezes.</p>
                                <p>The contagious period for a cold typically begins two to three days before symptoms
                                    appear
                                    and can last for up to two weeks after symptoms resolve. During this time, the
                                    infected
                                    person can still spread the virus even if they are feeling better.</p><h4><strong>At
                                Which
                                Stage Diagnosis and Tests of Common Cold Required?</strong></h4>
                                <p>Diagnostic tests are generally not needed for the common cold, as it is a viral
                                    infection
                                    that does not require specific treatment. However, if a person has severe or
                                    persistent
                                    symptoms, or if there is concern about a possible bacterial infection or other
                                    underlying
                                    medical condition, a doctor may order tests such as a throat culture, blood test, or
                                    chest
                                    X-ray to rule out other causes.</p>
                                <h3><strong>Ayurvedic Say on Common Cold and Risk Factors?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/common-cold/Image-3.webp"
                                    >
                                </div>
                                <p>According to Ayurveda, the common cold is caused by an imbalance in the body's
                                    doshas,
                                    particularly the Kapha dosha. The Kapha dosha governs the respiratory system and
                                    when it
                                    is
                                    imbalanced, it can lead to a buildup of mucus and congestion in the respiratory
                                    system,
                                    which can cause the symptoms of a cold.</p>
                                <p>In Ayurveda, several risk factors can contribute to an imbalance in the Kapha dosha
                                    and
                                    increase the risk of developing a cold. These include:</p>
                                <ul>
                                    <li>Exposure to cold and damp weather</li>
                                    <li>Poor digestion and a weak digestive system</li>
                                    <li>Eating cold or raw foods, particularly during the winter season</li>
                                    <li>Lack of sleep and rest</li>
                                    <li>Stress and anxiety</li>
                                    <li>Weak immune system</li>
                                </ul>
                                <h4><strong>How can ayurveda help with a common cold?</strong></h4>
                                <p>Ayurveda offers several natural remedies to help alleviate the symptoms of the common
                                    cold.
                                    One of the most popular remedies is the use of herbal teas and decoctions that
                                    contain
                                    ingredients like ginger, tulsi (holy basil), cinnamon, and black pepper. These herbs
                                    are
                                    believed to possess warming and anti-inflammatory properties that can help to reduce
                                    inflammation, soothe sore throats, and ease congestion.&nbsp;</p>
                                <p>Other common cold Ayurvedic medicine or remedies include steam inhalation with
                                    essential
                                    oils
                                    like eucalyptus or menthol, nasal irrigation with saline water, and gargling with
                                    warm
                                    saltwater. Additionally, practising good hygiene and eating a balanced diet can also
                                    help
                                    boost the immune system and prevent the common cold.</p><h4><strong>Ayurvedic
                                Remedies
                                for
                                Common Cold</strong></h4>
                                <p>Ayurveda offers several natural remedies that can help alleviate the symptoms of the
                                    common
                                    cold and boost the immune system. Here are some of the most effective Ayurvedic
                                    remedies
                                    for
                                    a common cold:</p>
                                <ol>
                                    <li>Ginger: Ginger is a powerful anti-inflammatory and immune booster. You can make
                                        ginger
                                        tea by boiling fresh ginger in water for 10-15 minutes, adding honey and lemon
                                        juice
                                        to
                                        taste.
                                    </li>
                                    <li>Turmeric: Turmeric is a potent antioxidant and anti-inflammatory agent that can
                                        help
                                        alleviate congestion and inflammation in the respiratory system. You can add
                                        turmeric
                                        powder to warm milk or water and drink it before bedtime to help reduce
                                        symptoms.
                                    </li>
                                    <li>Honey: Honey has antibacterial and anti-inflammatory properties and can help
                                        soothe
                                        sore
                                        throat and cough. Mix a spoonful of honey with warm water, ginger, and lemon
                                        juice
                                        for a
                                        soothing and healing drink.
                                    </li>
                                    <li>Tulsi (Holy Basil): Tulsi is a powerful herb that has antiviral and
                                        antibacterial
                                        properties. Boil fresh Tulsi leaves in water for 10-15 minutes, strain them, and
                                        drink
                                        it as a tea to help boost the immune system.
                                    </li>
                                    <li>Steam inhalation: Inhaling steam can help loosen mucus and clear congestion in
                                        the
                                        respiratory system. Boil water in a pot, add a few drops of eucalyptus oil, and
                                        inhale
                                        the steam for 10-15 minutes.
                                    </li>
                                    <li>Saltwater gargle: Gargling with salt water can help reduce inflammation and
                                        soothe a
                                        sore throat. Mix a teaspoon of salt in warm water and gargle several times a
                                        day.
                                    </li>
                                </ol>
                                <h4><strong>What to Eat and Not</strong></h4>
                                <p>Here are some common flu Ayurvedic guidelines on what to eat and what to avoid during
                                    a
                                    cold:</p><h4><strong>What to eat:</strong></h4>
                                <ul>
                                    <li>Warm soups and stews: Soups and stews made with warming spices like ginger,
                                        garlic,
                                        and
                                        cumin can help alleviate congestion and provide nourishment.
                                    </li>
                                    <li>Cooked vegetables: Vegetables like carrots, beets, and sweet potatoes are easy
                                        to
                                        digest
                                        and provide essential nutrients and antioxidants.
                                    </li>
                                    <li>Grains: Grains like rice, quinoa, and millet are easy to digest and provide
                                        energy
                                        and
                                        nourishment.
                                    </li>
                                    <li>Herbal teas: Drinking warm herbal teas like ginger, chamomile, and tulsi can
                                        help
                                        soothe
                                        a sore throat and alleviate congestion.
                                    </li>
                                    <li>Warm milk with spices: Drinking warm milk with spices like turmeric, cinnamon,
                                        and
                                        ginger can help boost the immune system and promote restful sleep.
                                    </li>
                                </ul>
                                <h4><strong>What to avoid:</strong></h4>
                                <ul>
                                    <li>Cold and raw foods: Cold and raw foods like salads and raw fruits can aggravate
                                        the
                                        Kapha dosha and increase <a
                                            href="https://dictionary.cambridge.org/dictionary/english/congestion"
                                            rel="nofollow"> congestion</a>.
                                    </li>
                                    <li>Heavy and fried foods: Foods that are heavy, fried, or greasy can be hard to
                                        digest
                                        and
                                        weaken the digestive fire.
                                    </li>
                                    <li>Dairy products: Dairy products like cheese and ice cream can increase mucus
                                        production
                                        and aggravate congestion.
                                    </li>
                                    <li>Sugary foods: Sugary foods and drinks can weaken the immune system and make it
                                        more
                                        difficult for the body to fight off infection.
                                    </li>
                                    <li>Caffeinated and alcoholic drinks: Caffeinated and alcoholic drinks can dehydrate
                                        the
                                        body and weaken the immune system, making it more difficult for the body to
                                        fight
                                        off
                                        infection.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Common Cold</strong></h4>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/anu-oil">Anu taila</a> (nasal oil)
                                    </li>
                                    <li>Pippali rasayana</li>
                                    <li>Agastya haritaki avaleha</li>
                                    <li><a href="https://www.draxico.com/products/lavangadi-vati">Lavangadi vati</a>
                                    </li>
                                    <li>Swasari ras</li>
                                    <li><a href="https://www.draxico.com/products/naradiya-mahalakshmi-vilas-ras">Mahalaxmivilas
                                        ras</a></li>
                                    <li>Sitopaladi churna</li>
                                    <li>Talisadi churna</li>
                                    <li>Mahasudarshan churna</li>
                                    <li>Ashwagandha</li>
                                    <li>Shatavari</li>
                                    <li>Yashtimadhu</li>
                                    <li>Haridra</li>
                                    <li>Amalaki (Indian Gooseberry)</li>
                                    <li>Guduchi (Tinospora cordifolia)</li>
                                </ul>
                                <p>NOTE: It's important to consult with an Ayurvedic practitioner before starting any
                                    new
                                    herbal
                                    remedies for common cold prevention and treatment.</p><h4><strong>A Short Note from
                                Dr.
                                Axico’s&nbsp;</strong></h4>
                                <p>In Ayurveda, the common cold is referred to as Pratishyaya, and one tip for managing
                                    its
                                    symptoms is to stay hydrated with warm liquids, such as ginger tea or hot water with
                                    honey
                                    and lemon, which can help to reduce inflammation and clear congestion. Ayurvedic
                                    medicine
                                    for the common cold may include herbal supplements, such as Tulsi, ginger, and
                                    turmeric,
                                    which can help to boost the immune system and reduce inflammation.&nbsp;</p>
                                <p>Steam inhalation with herbs such as eucalyptus, peppermint, or camphor can also help
                                    to
                                    loosen congestion and ease breathing. Additionally, getting adequate rest and
                                    avoiding
                                    cold
                                    foods and drinks, such as ice cream and cold beverages, can be beneficial. It is
                                    advised
                                    for
                                    an Ayurvedic doctor online consultation before starting any new herbal remedies or
                                    treatments. With the best common cold Ayurvedic treatment, you can buy Ayurvedic
                                    medicine
                                    too from Dr. Axico.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>What are the symptoms of the common cold in
                                            newborns?</strong></h4>
                                        <p>Symptoms of cold in babies include a runny or stuffy nose, cough, fever, sore throat,
                                            irritability, and loss of appetite. It's important to monitor your baby's symptoms
                                            and
                                            seek
                                            medical attention if their condition worsens or if they develop other symptoms. It's
                                            also
                                            important to ensure that your baby is getting enough fluids and is comfortable
                                            during
                                            their
                                            illness.</p><h4><strong>Does having a cold affect pregnancy?</strong></h4>
                                        <p>While having a cold during pregnancy is usually considered to be a mild illness that
                                            does
                                            not
                                            pose a major risk to the mother or baby, it can be uncomfortable and make it
                                            challenging
                                            for
                                            pregnant women to rest and stay hydrated. Severe respiratory infections like the
                                            flu,
                                            while
                                            rare, can increase the risk of complications like premature birth or low birth
                                            weight.</p>
                                        <h4><strong>Why do colds occur in the winter?</strong></h4>
                                        <p>Colds are more prevalent in the winter months due to several factors. The cold
                                            weather
                                            can
                                            weaken the immune system, making it more vulnerable to viral infections. People also
                                            tend to
                                            spend more time indoors and close to one another during the winter, which increases
                                            the
                                            likelihood of the virus spreading.&nbsp;</p>
                                        <p>Additionally, the cold and dry air of winter can dry out the mucous membranes in the
                                            nose
                                            and
                                            throat, making them more susceptible to infection. Certain types of cold viruses
                                            also
                                            thrive
                                            in colder temperatures and low humidity, making them more common during the
                                            winter.</p>
                                        <h4><strong>Common cold vs. Covid — what’s the difference?</strong></h4>
                                        <p>The common cold and COVID-19 are respiratory illnesses caused by different viruses,
                                            with
                                            some
                                            similarities and differences in their symptoms. The common cold typically results in
                                            milder
                                            symptoms, such as a runny nose, sore throat, and cough, while COVID-19 symptoms can
                                            be
                                            more
                                            severe and include fever, cough, shortness of breath, fatigue, body aches, and loss
                                            of
                                            taste
                                            or smell.&nbsp;</p>
                                        <p>While both illnesses are spread through contact with infected individuals, COVID-19
                                            is
                                            generally more contagious and carries a higher risk of severe illness and
                                            complications,
                                            particularly in older adults and those with underlying health conditions.</p>
                                        <h4><strong>Common cold vs. flu — what’s the difference?</strong></h4>
                                        <p>The common cold and flu (influenza) are both respiratory illnesses with similar
                                            symptoms,
                                            but
                                            they are caused by different viruses. The common cold symptoms of fever are usually
                                            milder
                                            and include a runny or stuffy nose, sore throat, cough, and occasional fever. In
                                            contrast,
                                            flu symptoms are generally more severe and can include fever, cough, sore throat,
                                            body
                                            aches, chills, and fatigue.&nbsp;</p>
                                        <p>The flu also carries a higher risk of complications such as pneumonia, which can be
                                            life-threatening. While both illnesses are spread through contact with infected
                                            individuals,
                                            the flu is generally more contagious and has a shorter incubation period than the
                                            common
                                            cold</p>
                                        <h4><strong>Common cold vs. chest cold — what’s the difference?</strong></h4>
                                        <p>The common cold and chest cold are both respiratory illnesses, but they affect
                                            different
                                            parts of the respiratory system and have distinct symptoms. The common cold
                                            typically
                                            affects the upper respiratory tract and may produce symptoms such as a runny or
                                            stuffy
                                            nose,
                                            sore throat, and cough. In contrast, a chest cold or acute bronchitis primarily
                                            affects
                                            the
                                            lower respiratory tract, specifically the bronchial tubes that carry air to the
                                            lungs.&nbsp;</p>
                                        <p>This can cause symptoms such as a productive cough with mucus, chest discomfort, and
                                            wheezing. A chest cold may also be accompanied by symptoms such as fatigue, fever,
                                            and
                                            body
                                            aches. It's important to distinguish between the two types of illnesses and seek
                                            appropriate
                                            treatment.&nbsp;</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'cough'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Cough: Chronic Causes, Symptoms & Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <h3><strong>What is Cough?</strong></h3>
                                <p>Coughing is a reflex that helps remove mucus, irritants, and extraneous substances
                                    from
                                    the
                                    airways. The irritation of the sensory nerves in the respiratory tract, which
                                    stimulates
                                    the
                                    cough reflex, is the typical cause. Coughs can be acute or chronic, with acute
                                    coughs
                                    typically resulting from respiratory infections and chronic coughs being linked to a
                                    variety
                                    of underlying medical conditions. Cough is not a big issue and an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a> is
                                    enough
                                    to
                                    get rid of this infection.&nbsp;</p>
                                <p>Depending on whether mucus or sputum is produced, the cough's intensity and duration
                                    can
                                    vary, and it may be productive or non-productive. If the cough persists or is
                                    accompanied by
                                    other symptoms, it may be necessary to seek medical attention. Coughing is a reflex
                                    that
                                    helps remove mucus, irritants, and extraneous substances from the airways. The
                                    irritation of
                                    the sensory nerves in the respiratory tract, which stimulates the cough reflex, is
                                    the
                                    typical cause.&nbsp;</p>
                                <p>Coughs can be acute or chronic, with acute coughs typically resulting from
                                    respiratory
                                    infections and chronic coughs being linked to a variety of underlying medical
                                    conditions.
                                    Depending on whether mucus or sputum is produced, the cough's intensity and duration
                                    can
                                    vary, and it may be productive or non-productive. If the cough persists or is
                                    accompanied by
                                    other symptoms, it may be necessary to seek medical attention.</p>
                                <h4><strong>What are the Different Types of Cough?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cough/Image-1.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Dry cough: A dry cough is an unproductive cough that does not cause the
                                        production
                                        of
                                        mucus or phlegm.
                                    </li>
                                    <li>Wet cough: A productive cough that generates mucus or cough with phlegm is
                                        referred
                                        to
                                        as wet cough.
                                    </li>
                                    <li>Chronic cough: A persistent cough persists for more than eight weeks.</li>
                                    <li>Acute cough: A cough that lasts less than eight weeks and is typically brought
                                        on by
                                        a
                                        respiratory illness is referred to as an acute cough.
                                    </li>
                                    <li><a href="https://www.webmd.com/allergies/postnasal-drip" rel="nofollow">Postnasal
                                        drip
                                        cough</a>: This kind of cough is brought on by mucus dropping from the nasal
                                        passages down the back of the throat.
                                    </li>
                                    <li>Whooping cough: A whooping sound is made when someone inhales due to the
                                        extremely
                                        infectious bacterial illness that produces violent coughing fits.
                                    </li>
                                    <li>Croup cough: Croup is an upper respiratory viral illness that affects children
                                        more
                                        than
                                        adults and results in a barking cough.
                                    </li>
                                    <li>A smoker's cough is a persistent cough brought on by smoking and is frequently
                                        accompanied by phlegm production.
                                    </li>
                                    <li>A psychogenic cough is a persistent cough that has no known medical explanation
                                        but
                                        is
                                        thought to be brought on by psychological influences.
                                    </li>
                                    <li>Cough brought on by exercise: Some persons may suffer a cough while exercising
                                        or
                                        just
                                        thereafter as a result of breathing in cold, dry air or as a result of their
                                        body's
                                        reaction to the increased breathing rate.
                                    </li>
                                </ul>
                                <h3><strong>How to Identify Early Signs and Causes of Severe Cough?</strong></h3>
                                <p>There are several warning indicators and potential causes of a severe cough that you
                                    should
                                    be aware of. Here are several of the most prevalent:</p><h4><strong>Early
                                signs:</strong>
                            </h4>
                                <ul>
                                    <li>Persistent or worsening cough lasting longer than a week</li>
                                    <li>Chest discomfort or agony when wheezing</li>
                                    <li>Breathlessness or difficulty inhaling</li>
                                    <li>Breathing with a whistling or wheezing sound</li>
                                    <li>Weakness or weariness</li>
                                    <li>Fever or shivering</li>
                                </ul>
                                <h4><strong>Causes:</strong></h4>
                                <ul>
                                    <li>Respiratory infections such as the common cold, influenza, bronchitis, and
                                        pneumonia.
                                    </li>
                                    <li>Allergic reactions or asthma</li>
                                    <li>GERD refers to gastroesophageal reflux disease.</li>
                                    <li>Smoking or second-hand smoke exposure</li>
                                    <li>Dust, pollution, or chemicals are examples of environmental irritants.</li>
                                    <li>COPD stands for chronic obstructive pulmonary disease.</li>
                                </ul>
                                <h4><strong>Noticeable Changes in Body?</strong></h4>
                                <ul>
                                    <li>Pain or discomfort in the chest area might result from coughing, which strains
                                        the
                                        chest
                                    </li>
                                    <li>Breathlessness: Prolonged or frequent coughing can make it difficult to catch
                                        your
                                        breath, which can cause breathlessness.
                                    </li>
                                    <li>Feelings of weakness or exhaustion might result from coughing, which can be
                                        physically
                                        draining.
                                    </li>
                                    <li>Repeated coughing can irritate the throat, which can result in a painful or
                                        scratchy
                                        throat, neck, and head.
                                    </li>
                                </ul>
                                <p>If you have any of the above symptomps, you can&nbsp;<a
                                    href="https://www.draxico.com/appointment">book a free online consultation with Dr
                                    Axico</a>.
                                </p>
                                <h3><strong>Diagnosis and Tests of Cough?</strong></h3>
                                <p>Numerous illnesses, such as infections, allergies, or underlying medical disorders,
                                    might
                                    result in a cough. Your healthcare practitioner may do a physical examination and
                                    inquire
                                    about your medical history, way of life, and any drugs you're taking if you have a
                                    chronic
                                    or severe cough. Additionally, they could suggest diagnostic exams like:</p>
                                <ul>
                                    <li>Chest X-ray: A chest X-ray can assist your doctor examine your lungs for any
                                        abnormalities, such as swelling or fluid accumulation.
                                    </li>
                                    <li>Sputum culture: If you're coughing up phlegm or mucus, your doctor may collect a
                                        sample
                                        to check for viruses or bacteria that may be the source of your cough.
                                    </li>
                                    <li>Pulmonary function test: This test measures how well your lungs are working and
                                        can
                                        help
                                        diagnose conditions like&nbsp;<a
                                            href="https://www.draxico.com/diseases/asthma">asthma</a> or chronic
                                        obstructive
                                        pulmonary disease (COPD).
                                    </li>
                                    <li>Blood testing: Blood tests can assist your healthcare practitioner in examining
                                        your
                                        body for indications of infection or inflammation that could be the source of
                                        your
                                        cough.
                                    </li>
                                    <li>Allergy tests: If your healthcare provider suspects that allergies may be
                                        causing
                                        your
                                        cough, they may recommend allergy tests to identify any allergens that may be
                                        triggering
                                        your symptoms.
                                    </li>
                                </ul>
                                <h4><strong>What Types of coughs have distinct sounds and are related to specific
                                    conditions?</strong></h4>
                                <p>Several types of coughs have distinct sounds. These include:</p>
                                <ul>
                                    <li>Wet or productive cough: This type of cough produces phlegm or mucus and often
                                        sounds
                                        "wet" or "rattly." It's often associated with respiratory infections
                                        like&nbsp;<a
                                            href="https://www.draxico.com/diseases/bronchitis">bronchitis</a>,
                                        pneumonia, or
                                        the
                                        flu.
                                    </li>
                                    <li>Dry cough: A dry cough does not produce mucus and often sounds "scratchy" or
                                        "irritating." It can be caused by a variety of factors, including allergies,
                                        asthma,
                                        or
                                        viral infections like the&nbsp;<a
                                            href="https://www.draxico.com/diseases/common-cold">common
                                            cold</a>.
                                    </li>
                                    <li>Barking cough: This type of cough sounds like a seal barking and is often
                                        associated
                                        with croup, which is a viral infection that affects the upper airway in young
                                        children.
                                    </li>
                                    <li>Whooping cough: Also known as pertussis, this type of cough produces a
                                        high-pitched
                                        "whoop" sound and is caused by a bacterial infection. It's highly contagious and
                                        can
                                        be
                                        prevented by vaccination.
                                    </li>
                                    <li>Wheezing cough: This type of cough is often accompanied by a wheezing or
                                        whistling
                                        sound
                                        when you breathe and is often associated with asthma or other respiratory
                                        conditions.
                                    </li>
                                </ul>
                                <h3><strong>Ayurvedic Say on Cough and Risk Factors?</strong></h3>
                                <p>Cough is known as kasa in Ayurveda, and it is thought to be brought on by the
                                    aggravation
                                    of
                                    the Vata, Pitta, or Kapha doshas, or a combination of these.</p>
                                <p>The pathophysiology of kasa, according to Ayurvedic principles, includes the buildup
                                    of
                                    ama
                                    (toxins) and exacerbated doshas in the respiratory system, which causes inflammation
                                    and
                                    irritation of the bronchial tubes. This may cause an excessive amount of mucus to be
                                    produced, which causes coughing.</p>
                                <p>The different types of kasa in Ayurveda are based on the specific doshas involved.
                                    For
                                    example, Vata-type kasa is characterised by a dry cough, while Pitta-type kasa is
                                    associated
                                    with yellow or green phlegm and a burning sensation in the chest. Kapha-type kasa,
                                    on
                                    the
                                    other hand, is characterised by a wet cough with excessive mucus production.&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic treatment for mucus</a> in lungs can
                                    be
                                    ignored by a balanced diet and lifestyle.&nbsp;&nbsp;</p><h4><strong>The risk
                                factors
                                for
                                kasa in Ayurveda include:</strong></h4>
                                <ul>
                                    <li>Imbalanced Doshas: The aggravation of one or more doshas, such as Vata, Pitta,
                                        or
                                        Kapha,
                                        can increase the risk of kasa. Each dosha has a unique role in the
                                        pathophysiology
                                        of
                                        kasa, and an imbalance in any of them can lead to the development of cough.
                                    </li>
                                    <li>Weak Immunity: According to Ayurveda, a weak immune system can increase the risk
                                        of
                                        respiratory infections and other health conditions, including kasa.
                                    </li>
                                    <li>Exposure to Environmental Irritants: Exposure to environmental irritants such as
                                        pollution, dust, and allergens can trigger kasa in susceptible individuals.
                                    </li>
                                    <li>Dietary Factors: Consumption of foods that are heavy, oily, or difficult to
                                        digest
                                        can
                                        aggravate kasa. Similarly, consuming cold or stale food and drinks can also
                                        worsen
                                        the
                                        condition.
                                    </li>
                                    <li>Poor Lifestyle Habits: Lack of sleep, excessive physical or mental exertion, and
                                        emotional stress can all weaken the immune system and increase the risk of kasa.
                                    </li>
                                </ul>
                                <h4><strong>How Ayurveda Can Help in Cough Prevention?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cough/Image-2.webp"
                                    >
                                </div>
                                <p>Ayurveda can help in the prevention of cough by promoting overall respiratory health,
                                    strengthening the immune system, and addressing any underlying imbalances in the
                                    body.
                                    Here
                                    are some ways Ayurveda can help in cough prevention:</p>
                                <ol>
                                    <li><strong>Maintain a Healthy Diet:</strong> Consuming a healthy diet that is rich
                                        in
                                        fresh
                                        fruits, vegetables, whole grains, and lean proteins can boost immunity and
                                        promote
                                        respiratory health. It is also important to avoid heavy, oily, or
                                        difficult-to-digest
                                        foods that can aggravate kapha and weaken digestion.
                                    </li>
                                    <li><strong>Practice Pranayama:</strong> Pranayama, or breathing exercises, can help
                                        improve
                                        respiratory health by strengthening the lungs and enhancing lung capacity. Some
                                        pranayama techniques that can be beneficial for cough prevention include
                                        kapalbhati,
                                        anulom-vilom, and bhastrika.
                                    </li>
                                    <li><strong>Use Herbal Remedies:</strong> Ayurvedic herbs such as ginger, turmeric,
                                        tulsi
                                        (holy basil), and licorice have expectorant, anti-inflammatory, and
                                        antimicrobial
                                        properties that can help prevent cough and strengthen the respiratory system.
                                    </li>
                                    <li><strong>Avoid Environmental Irritants:</strong> Exposure to environmental
                                        irritants
                                        such
                                        as pollution, dust, and allergens can trigger cough in susceptible individuals.
                                        Avoiding
                                        these irritants or wearing a mask when exposed can help prevent cough.
                                    </li>
                                    <li><strong>Manage Stress:</strong> Emotional stress can weaken the immune system
                                        and
                                        increase the risk of cough. Practicing stress management techniques such as
                                        yoga,
                                        meditation, and deep breathing exercises can help prevent cough and promote
                                        overall
                                        health and well-being.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Remedies for Cough?</strong></h4>
                                <p>Ayurveda offers a variety of remedies for cough, including herbs, spices, and other
                                    natural
                                    remedies. Here are some of the most effective Ayurvedic remedies for cough:</p>
                                <ol>
                                    <li><strong>Ginger:</strong> Ginger is a natural expectorant and has
                                        anti-inflammatory
                                        properties and best medicine for sore throat and cough. Boil some fresh ginger
                                        in
                                        water
                                        and drink the tea two to three times a day.
                                    </li>
                                    <li><strong>Turmeric:</strong> Turmeric is another potent anti-inflammatory herb
                                        that
                                        can
                                        help relieve cough and sore throat. Mix a teaspoon of turmeric powder in a glass
                                        of
                                        warm
                                        milk and drink it before bedtime.
                                    </li>
                                    <li><strong>Tulsi (Holy Basil):</strong> Tulsi is a powerful Ayurvedic herb that has
                                        antimicrobial and anti-inflammatory properties. Boil some fresh Tulsi leaves in
                                        water
                                        and drink the tea two to three times a day.
                                    </li>
                                    <li><strong>Honey:</strong> Honey is a natural cough suppressant and can help soothe
                                        the
                                        throat. Mix a tablespoon of honey in a glass of warm water and drink it before
                                        bedtime.
                                    </li>
                                    <li><strong>Black Pepper:</strong> Black pepper is a natural expectorant and can
                                        help
                                        relieve cough and congestion. Mix a pinch of black pepper powder with honey and
                                        take
                                        it
                                        two to three times a day.
                                    </li>
                                    <li><strong>Cardamom:</strong> Cardamom has antimicrobial properties and can help
                                        relieve
                                        cough and sore throat. Boil some cardamom pods in water and drink the tea two to
                                        three
                                        times a day.
                                    </li>
                                </ol>
                                <h4>&nbsp;<strong>What to Eat and Not?</strong></h4>
                                <p>According to Ayurveda, diet plays an important role in preventing and managing cough.
                                    Here
                                    are some general dietary guidelines for cough:</p><h4><strong>Foods to eat:</strong>
                            </h4>
                                <ol>
                                    <li>Warm and nourishing foods such as soups, stews, and porridges are good for
                                        cough.
                                    </li>
                                    <li>Cooked vegetables such as carrots, beets, and leafy greens can help strengthen
                                        the
                                        immune system and promote healthy digestion.
                                    </li>
                                    <li>Spices such as ginger, turmeric, black pepper, and cardamom can help relieve
                                        cough
                                        and
                                        congestion.
                                    </li>
                                    <li>Healthy fats such as ghee, coconut oil, and olive oil can help lubricate the
                                        respiratory
                                        system and reduce cough.
                                    </li>
                                    <li>Warm liquids such as herbal teas, warm water, and warm milk with honey can help
                                        soothe
                                        the throat and relieve the cough.
                                    </li>
                                </ol>
                                <h4><strong>Foods to avoid:</strong></h4>
                                <ol>
                                    <li>Cold and raw foods such as salads, smoothies, and raw vegetables can aggravate
                                        cough.
                                    </li>
                                    <li>Dairy products such as milk, cheese, and ice cream can increase mucus production
                                        and
                                        worsen cough.
                                    </li>
                                    <li>Sugary and processed foods such as cakes, cookies, and soda can weaken the
                                        immune
                                        system
                                        and worsen the cough.
                                    </li>
                                    <li>Spicy and fried foods can irritate the throat and worsen the cough.</li>
                                    <li>Alcohol and caffeine can dehydrate the body and worsen the cough.</li>
                                </ol>
                                <h4><strong>Here are some Ayurvedic medications that are commonly recommended for
                                    cough:</strong></h4>
                                <ol>
                                    <li><strong>Talisadi Churna:</strong> Talisadi Churna is a best Ayurvedic medicine
                                        for
                                        dry
                                        cough that contains herbs such as Talisa, Vanshlochan, and Ela. It is a natural
                                        expectorant and can help relieve cough and congestion.
                                    </li>
                                    <li><strong>Shwas Chintamani Ras:</strong> Shwas Chintamani Ras is an Ayurvedic
                                        medicine
                                        for
                                        cough that contains herbs such as Swarna Bhasma, Mukta Bhasma, and Tankan
                                        Bhasma. It
                                        has
                                        a bronchodilator and expectorant properties and can help relieve cough and
                                        improve
                                        breathing.
                                    </li>
                                    <li><a
                                        href="https://www.draxico.com/products/kanakasava"><strong>Kanakasava</strong></a><strong>:</strong>
                                        Kanakasava is an Ayurvedic medication that contains herbs such as Vasaka,
                                        Yashtimadhu,
                                        and Kantakari. It has expectorant and anti-inflammatory properties and can help
                                        relieve
                                        cough and improve respiratory health.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/mahasudarshan-churna"><strong>Mahasudarshan
                                        Churna</strong></a><strong>:</strong> Mahasudarshan Churna is an Ayurvedic
                                        formulation
                                        that contains herbs such as Guduchi, Haritaki, and Musta. It has antimicrobial
                                        and
                                        anti-inflammatory properties and can help boost the immune system and relieve
                                        cough.
                                    </li>
                                    <li><strong>Chyawanprash:</strong> Chyawanprash is an Ayurvedic cough syrup that
                                        contains
                                        herbs such as Amla, Ashwagandha, and Ghee. It is a natural immunomodulator and
                                        can
                                        help
                                        boost the immune system and relieve cough.
                                    </li>
                                </ol>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>Coughing frequently indicates nothing serious. In some circumstances, coughing is
                                    acceptable
                                    (and beneficial). You can get rid of substances in your throat and airways that are
                                    irritating or making breathing difficult by coughing. Call your doctor for guidance
                                    if
                                    you
                                    experience any further symptoms, such as coughing up blood or colored sputum or
                                    having
                                    difficulty breathing, eating, sleeping, or feeling feverish.</p>
                                <p>With Dr. Axico's assistance, the majority of patients successfully live healthy life
                                    with
                                    the
                                    essence of Ayurveda. You can buy Ayurvedic medicine online along with Ayurvedic
                                    doctor
                                    consultations.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How do I know if my cough is serious?</strong>
                                        </h4>
                                        <p>It's important to note that if you're concerned about your health or experiencing
                                            severe
                                            symptoms, it's always best to consult with a healthcare professional for a proper
                                            evaluation
                                            and diagnosis.</p>
                                        <p>Here are some signs that may indicate a more serious cough:</p>
                                        <ol>
                                            <li>Duration: If your cough lasts for more than two weeks or persists despite home
                                                remedies,
                                                it's advisable to seek medical attention.
                                            </li>
                                            <li>Severity: A severe or persistent cough that interferes with your daily
                                                activities,
                                                such
                                                as sleeping, eating, or breathing, should be evaluated by a doctor.
                                            </li>
                                            <li>Coughing up blood: If you cough up blood or notice blood in your mucus, it could
                                                indicate a potentially serious underlying condition, and you should seek
                                                immediate
                                                medical attention.
                                            </li>
                                            <li>Difficulty breathing: If your cough is accompanied by shortness of breath,
                                                wheezing,
                                                or
                                                chest pain, it could be a sign of a respiratory problem that requires medical
                                                evaluation.
                                            </li>
                                            <li>High fever: A persistent cough accompanied by a high-grade fever could indicate
                                                an
                                                infection, such as pneumonia, bronchitis, or COVID-19. It's important to consult
                                                a
                                                healthcare professional for proper diagnosis and ayurvedic cough treatment.
                                            </li>
                                            <li>Unexplained weight loss or night sweats: If your cough is accompanied by
                                                unexplained
                                                weight loss, uncontrollable coughing at night, or fatigue, it's recommended to
                                                seek
                                                medical advice as these symptoms could be indicative of an underlying condition
                                                that
                                                requires attention.
                                            </li>
                                        </ol>
                                        <h4><strong>Can pregnancy make you cough?</strong></h4>
                                        <p>Pregnancy may cause coughing. Pregnancy-related coughing has numerous causes:</p>
                                        <ol>
                                            <li>Hormonal changes: Pregnancy might increase mucus production and respiratory
                                                tract
                                                discomfort. Coughing may ensue.
                                            </li>
                                            <li>Acid reflux: Hormonal changes and stomach pressure from the expanding uterus
                                                cause
                                                heartburn in many pregnant women. Acid reflux irritates the throat and causes
                                                coughing.
                                            </li>
                                            <li>Increased blood flow during pregnancy might enlarge respiratory tract mucous
                                                membranes.
                                                Swelling causes congestion, postnasal drip, and coughing.
                                            </li>
                                            <li>Allergic responses and airway inflammation may cause coughing in pregnant women.
                                            </li>
                                        </ol>
                                        <h4><strong>What does it mean if I cough after eating?</strong></h4>
                                        <p>The cause of coughing after eating depends on the conditions and symptoms. Coughing
                                            after
                                            eating may have several causes:</p>
                                        <p>Acid reflux causes gastroesophageal reflux disease (GERD). After a big meal, laying
                                            down
                                            right after, or eating hot or fatty meals, this might happen. Stomach acid reaching
                                            the
                                            throat might induce coughing.</p>
                                        <p>Food allergies may cause coughing. Itching, hives, edoema, and trouble breathing may
                                            accompany coughing. A doctor should evaluate and diagnose dietary allergies and
                                            sensitivities.</p>
                                        <p>Dysphagia causes post-meal coughing. If you have problems swallowing, food or liquids
                                            may
                                            enter the airway and cause a cough.</p>
                                        <p>Irritation or Aspiration: Certain meals or drinks may irritate the airway or induce
                                            aspiration (food or liquid entering the lungs instead of the stomach). The body
                                            coughs
                                            to
                                            cleanse the airway.</p>
                                        <p>Respiratory Conditions: Eating may worsen asthma or COPD, causing coughing fits.</p>
                                        <h4><strong>What should I know about COVID-19 and cough?</strong></h4>
                                        <p>SARS-CoV-2 causes COVID-19, a respiratory ailment. COVID-19 symptoms often include
                                            coughing.
                                            COVID-19 cough information:</p>
                                        <p>Dry cough is a common COVID-19 symptom. Persistent and worsening. Coughing is
                                            prevalent
                                            with
                                            COVID-19.</p>
                                        <p>Other symptoms: COVID-19 causes fever, exhaustion, sore throat, shortness of breath,
                                            loss
                                            of
                                            taste or smell, bodily pains, and headaches. Coughing and other symptoms may
                                            indicate
                                            COVID-19 infection.</p>
                                        <p>SARS-CoV-2 spreads by respiratory droplets when infected people cough, sneeze, speak,
                                            or
                                            breathe forcefully. Coughing releases more respiratory droplets, increasing the
                                            chance
                                            of
                                            transmission.</p>
                                        <p>Variants: SARS-CoV-2 virus variants may have various symptoms. Keep up with trusted
                                            health
                                            experts' variant and symptom information.</p>
                                        <p>Testing and Isolation: If you have a cough or other COVID-19 symptoms, get tested. To
                                            avoid
                                            viral transmission, follow local health authorities' testing, isolation, and contact
                                            tracking procedures.</p>
                                        <p>Seek Medical Attention: Severe symptoms including trouble breathing, prolonged chest
                                            discomfort, disorientation, or pale lips or face may suggest a more severe form of
                                            COVID-19.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'diabetes'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Diabetes: Early Signs, Causes & How Ayurveda Can Permanently Stop Insulin Pump?</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Unhealthy eating habits and lifestyles lead us to various health issues. One of the
                                    most
                                    prevalent and concerning&nbsp;<a href="https://www.draxico.com/diseases">health
                                        issues</a>
                                    that people deal with is diabetes. There is no doubt that Diabetes can be very
                                    vicious
                                    but&nbsp;<strong>Ayurvedic
                                        doctor online consultation</strong> would be a good choice to fight back
                                    diabetes.
                                </p>
                                <p>According to studies, diabetic ketoacidosis affects roughly 11% of the world's
                                    population.
                                    And certainly, managing it is difficult. Not impossible, though. The ancient medical&nbsp;<a
                                        href="https://www.draxico.com/about/ayurveda">science of Ayurveda</a> has advice
                                    for
                                    controlling diabetes and suggested several health care ayurvedic diabetes medicines.
                                </p>
                                <p>Diabetes is a chronic health condition that happens when your blood sugar (glucose)
                                    level
                                    is
                                    too high. It develops when your body doesn't process insulin effectively or when
                                    your
                                    pancreas doesn't produce any insulin at all. It affects people of all ages. There
                                    are
                                    various types of diabetes such as Type 1, Type 2, Gestational, and prediabetes
                                    divided
                                    into
                                    several stages.</p>
                                <h3><strong>What is Diabetes and Why is Diabetes also known as Sugar?</strong></h3>
                                <p>When blood glucose, commonly known as blood sugar, is too high, a disease occurs
                                    called
                                    diabetes. Our primary energy source is blood glucose, which is obtained from the
                                    food we
                                    eat. The pancreas produces the hormone insulin, which facilitates the entry of
                                    food-derived
                                    glucose into our cells for energy production.</p>
                                <p>Our body sometimes produces insufficient or no insulin, or it uses insulin poorly.
                                    Following
                                    that, glucose does not reach your cells and instead stays in your bloodstream.
                                    Having
                                    too
                                    much glucose in the blood over time may cause health problems. It increases the risk
                                    of&nbsp;<a
                                        href="https://www.draxico.com/diseases-details/heart-disease">heart failure</a>.
                                </p>
                                <p>Due to the high level of glucose in the body, the taste of urine becomes sweet and
                                    known
                                    sugar. Major health problems including heart disease,&nbsp;<a
                                        href="https://www.draxico.com/diseases-details/kidney-infection">kidney
                                        disease</a>,
                                    and
                                    vision loss may eventually result from it. Diabetes does not yet have a cure,
                                    although
                                    losing weight, eating healthily, and exercising can all be very helpful.</p>
                                <h4><strong>What are the Major Causes of Sugar?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/diabetes/Image-2.webp"
                                    >
                                </div>
                                <p><strong>Diabetes Causes</strong> - Diabetes, irrespective of its kind, is caused by
                                    an
                                    excess
                                    of glucose in the blood. Yet, depending on the kind of diabetes you have, there are
                                    several
                                    causes for elevated blood glucose levels.</p>
                                <p>1.<strong>Insulin Resistance:</strong> The major cause of type 2 diabetes
                                    is insulin resistance. When your muscles, fat, and liver cells don't react to
                                    insulin as
                                    they should, you have insulin resistance. Insulin resistance is caused by a variety
                                    of
                                    illnesses and circumstances, including obesity, laziness, nutrition, hormone
                                    imbalances,
                                    heredity, and some drugs.</p>
                                <p>2.<strong>Autoimmune disease:</strong> When the Immune system destroys
                                    the pancreatic cells, Type 1 diabetes and&nbsp;
                                    <a href="https://www.ncbi.nlm.nih.gov/books/NBK557897/#:~:text=Latent%20autoimmune%20diabetes%20of%20adults,2%20diabetes%20mellitus%20(DM)."
                                       rel="nofollow">LADA</a>
                                    are autoimmune diseases developed.</p>
                                <p>3.<strong>Unbalanced hormones:</strong> The placenta produces hormones
                                    throughout pregnancy that lead to insulin resistance. If your pancreas is unable to
                                    generate
                                    enough insulin to overcome insulin resistance, you may develop gestational diabetes.
                                    Type 2
                                    diabetes can also be brought on by other hormonal related disorders such as Growth
                                    hormone
                                    excess and Cushing syndrome.</p>
                                <p>4.<strong>Pancreatic Damage:</strong> Type 3c diabetes can arise from
                                    physical damage to your pancreas.</p>
                                <p>5.<strong>Genetic Mutations:</strong> MODY and neonatal diabetes may be
                                    caused by certain genetic mutations.</p>
                                <p>Apart from these causes, Long-term use of certain medicines can also lead to
                                    sugar.</p>
                                <h3><strong>What are the Types of Diabetes?</strong></h3>
                                <p>There are mainly four types of diabetes: Prediabetes, Type 1, Type 2, and Gestational
                                    Diabetes. They are divided into four stages along with a sign of diabetes.</p><h4>
                                <strong>What
                                    is Prediabetic?</strong></h4>
                                <p>While your blood sugar levels are higher than typical, they are not yet considered to
                                    be
                                    diabetic. Those who already have some insulin resistance or whose pancreatic beta
                                    cells
                                    aren't producing enough insulin. To keep blood sugar levels within the normal range
                                    are
                                    more
                                    likely to develop prediabetes.</p>
                                <p>More glucose cannot enter your cells because of insufficient insulin, which keeps it
                                    in
                                    your
                                    bloodstream. One can develop type 2 diabetes.</p>
                                <p><strong>Cause:&nbsp;</strong>Pancreas produces insulin, and acts as a carrier for
                                    blood
                                    sugar
                                    to enter cells where it is used as fuel. If you have prediabetes, your body's cells
                                    don't
                                    respond to insulin as they should. Your pancreas produces extra insulin to attempt
                                    to
                                    get
                                    cells to react. Pancreas failure keeps your blood sugar rising, which can lead to
                                    type 2
                                    diabetes and prediabetes in the long run.</p><h4><strong>Sign of being Prediabetic
                                includes:</strong></h4>
                                <ul>
                                    <li>Overweight</li>
                                    <li>Physically less active</li>
                                    <li>Having polycystic ovary syndrome</li>
                                    <li>Biological siblings or Parents with Diabetes</li>
                                    <li>High Cholesterol, heart disease or high blood pressure, or stroke</li>
                                </ul>
                                <h4><strong>Type 1 Diabetes</strong></h4>
                                <p>The condition in which blood sugar is too high is known as blood glucose. Your
                                    primary
                                    source
                                    of energy is blood glucose, which is mostly obtained from the food we eat. The
                                    immune
                                    systems of type 1 diabetics attack and kill the cells in the pancreas that produce
                                    insulin.</p>
                                <p>As a result, your pancreas quits making insulin. Lack of insulin causes glucose to be
                                    unable
                                    to enter your cells, raising blood sugar levels. People with type 1 diabetes should
                                    complete&nbsp;<a
                                        href="https://www.todayshospitalist.com/tips-for-calculating-a-total-daily-dose-of-insulin/#:~:text=0.4%20units%2Fkg%2Fday%20for,dose%20steroids%20or%20insulin%2Dresistant."
                                        rel="nofollow">insulin
                                        requirements every day</a>.</p><h4><strong>Cause of Type 1 Diabetes</strong>:&nbsp;
                            </h4>
                                <p>Genes and potential environmental triggers for the illness, such as viruses, can
                                    cause
                                    type 1
                                    diabetes.</p><h4><strong>Sign of being Type 1 includes:</strong></h4>
                                <ul>
                                    <li>Unexplained weight loss</li>
                                    <li>Increased hunger</li>
                                    <li>Blurred vision</li>
                                    <li>Fatigue</li>
                                    <li>Increased thirst and urination</li>
                                    <li>Dry or flushed skin</li>
                                </ul>
                                <h4><strong>Type 2 Diabetes</strong></h4>
                                <p>High blood glucose levels, sometimes referred to as blood sugar, is what causes type
                                    2
                                    diabetes, the most common kind. Your primary source of energy is blood glucose,
                                    which is
                                    mostly obtained from meals. A hormone made in the pancreas facilitates the entry of
                                    glucose
                                    into your cells for use as fuel. As a result, too little glucose enters your cells
                                    and
                                    accumulates in your circulation.</p>
                                <p><strong>Cause</strong>: Insulin resistance, overweight and obesity, genes, and
                                    physical
                                    laziness are the major causes of Type 2 Diabetes.</p><h4><strong>Sign of being Type
                                2
                                includes:</strong></h4>
                                <ul>
                                    <li>Blurred vision</li>
                                    <li>Increased hunger</li>
                                    <li>Unexplained weight loss</li>
                                    <li>Increased thirst and urination</li>
                                    <li>Tingling or Numbness in the hands and feet</li>
                                    <li>Feeling tired</li>
                                    <li>Sores that do not heal</li>
                                </ul>
                                <h4><strong>Gestational Diabetes</strong></h4>
                                <p>One kind of diabetes that exclusively develops during pregnancy is gestational
                                    diabetes.
                                    Obstetrical hyperglycemia can harm both the mother and the fetus. You and your
                                    unborn
                                    child
                                    can be protected if you manage your diabetes.</p><h4><strong>Cause and
                                Symptoms</strong>:&nbsp;
                            </h4>
                                <p>The symptoms of gestational diabetes are either nonexistent or minor, such as
                                    increased
                                    thirst or frequent urination. The hormonal changes that occur during pregnancy that
                                    reduce
                                    your body's ability to utilize insulin are occasionally linked to gestational
                                    diabetes.
                                    Genes and excess weight could also be important.</p>
                                <h3><strong>How to Identify Early Signs and Causes of Diabetes or Sugar</strong></h3>
                                <p>It is important to diagnose Diabetes or sugar at early stages before it’s too late.
                                    The
                                    warning signs might occasionally be so subtle that you miss them. Some people don't
                                    have
                                    exact symptoms until the disease effects start to cause them various problems.</p>
                                <p>After a few days or weeks, type 1 diabetes symptoms frequently surface. They are also
                                    much
                                    more severe.</p><h4><strong>Early Signs of Diabetes:</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/diabetes/Image-1.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Hunger and fatigue</li>
                                    <li>Peeing more often and being thirstier</li>
                                    <li>Dry mouth and itchy skin</li>
                                    <li>Blurred vision</li>
                                    <li>Unexpected weight loss</li>
                                </ul>
                                <h4><strong>Diabetes Mellitus Causes
                                    -&nbsp;</strong></h4>
                                <p>No matter the kind, diabetes is brought on by too much blood glucose. Yet, there are
                                    several
                                    reasons for high blood glucose levels, depending on the kind of diabetes you
                                    have.</p>
                                <ul>
                                    <li>Insulin Resistance</li>
                                    <li>Autoimmune Diseases</li>
                                    <li>Hormonal Imbalance</li>
                                    <li>Pancreatic Damage</li>
                                    <li>Genetic Mutation</li>
                                </ul>
                                <h4><strong>Noticeable Changes in Body</strong>
                                </h4>
                                <p>The small blood vessels can be affected by diabetes resulting in various skin and
                                    body
                                    changes. Some of the early noticeable changes included:</p>
                                <ul>
                                    <li>Weakness</li>
                                    <li>Dry skin</li>
                                    <li>Unclear or blurred vision</li>
                                    <li>Unexpected weight loss</li>
                                    <li>Sweet-smelling breath</li>
                                    <li>Getting infected easily</li>
                                    <li>High blood pressure</li>
                                    <li>Slow-healing sores</li>
                                </ul>
                                <h3><strong>Ayurvedic Say on Diabetes or Sugar and Risk Factors?</strong></h3>
                                <p>Ayurveda and diabetes care have a therapeutic connection. It is a medical system that
                                    places
                                    more emphasis on holistic well-being than it does on particular ailments. According
                                    to
                                    Ayurveda, diabetes is related to and is known as a urine deformity.</p>
                                <p>All-in-all, there are twenty categories of diabetic issues. Diabetes mellitus is the
                                    most
                                    prevalent kind of diabetes out of those twenty.</p>
                                <p>In Ayurveda, diabetes is referred to as "Madhumeha". Phlegm (Kapha), heat (Pitta),
                                    and
                                    toxic
                                    mucus (Aama) referring to Ayurveda, cause obstructions in the pancreas and halt
                                    insulin
                                    production. Known as insulin resistance. Confused about&nbsp;<a
                                        href="https://www.draxico.com/blog/did-you-know-about-or-follow-the-ayurvedic-Clock">Kapha,
                                        Pitta and Aama</a>, here’s the answer.</p>
                                <p>The blood or urine sugar levels rise as a result In Ayurveda, diabetes is known as
                                    Madhumeha
                                    (literally meaning sweet urine). Diabetes mellitus is referred to as vata prameha
                                    and
                                    appears as the result of imbalance. Diabetes insipidus is referred to in medicine as
                                    Kapha
                                    Prameha. This is brought on by an imbalance in the Kapha Dosha.</p><h4><strong>Risk
                                Factors:&nbsp;</strong>
                            </h4>
                                <p>Risk factors may vary as per on the type of diabetes. In all sorts, family history
                                    could
                                    be
                                    important. Regional location and environmental variables can increase the incidence
                                    of
                                    type
                                    1 diabetes.</p>
                                <p>Testing for diabetic immune system cells in relatives of persons with type 1 diabetes
                                    occurs
                                    sometimes. If you have been diagnosed with certain antibodies, a higher chance of
                                    getting
                                    type 1 diabetes occurs. Nevertheless, not everybody with these antibodies goes on to
                                    acquire
                                    diabetes.</p>
                                <p>Type 2/ American diabetes risk may also increase depending on your ethnicity. Certain
                                    people
                                    are more at risk than others, including those who are Black, Hispanic, American
                                    Indian,
                                    and
                                    Asian American. Among those who are overweight or obese, type 2 diabetes,
                                    gestational
                                    diabetes, and prediabetes are more prevalent.</p><h4><strong>Complications</strong>
                            </h4>
                                <ul>
                                    <li><strong>Hyperosmolar hyperglycemic state&nbsp;</strong>- Mainly people with Type
                                        2
                                        are
                                        affected by HHS. High blood sugar symptoms appear for a long time leading to&nbsp;<a
                                            href="https://www.draxico.com/diseases-details/dehydration">severe
                                            dehydration</a>
                                        and confusion.
                                    </li>
                                    <li><strong>Hypoglycemia&nbsp;</strong>- When low blood sugar symptoms appear, it is
                                        called
                                        Hypoglycemia. In Hypoglycemia, the sugar level drops down below the range. It
                                        mainly
                                        affects people who use Insulin, blurred or double vision is the major symptom.
                                    </li>
                                    <li><strong>Diabetes related ketoacidosis (DKA):&nbsp;</strong>The majority of those
                                        who
                                        have this consequence have Type 1 diabetes or undiagnosed T1D. When the body is
                                        not
                                        able
                                        to produce enough insulin, it occurs. Vomiting, breathing difficulties, and
                                        unconsciousness are the results of this.
                                    </li>
                                </ul>
                                <h3><strong>How Ayurveda Can Help in Diabetes Prevention?</strong></h3>
                                <p>Diabetes and the majority of health problems may be treated using the holistic
                                    approach
                                    employed in Ayurveda. A strategy is recommended by Ayurveda. Herbal drugs,
                                    Panchakarma
                                    therapy, its numerous procedures (<a
                                        href="https://www.draxico.com/panchakarma-treatment/vamana">Vamana</a>,&nbsp;<a
                                        href="https://www.draxico.com/panchakarma-treatment/virechana">Virechana</a>,&nbsp;<a
                                        href="https://www.draxico.com/panchakarma-treatment/basti">Basti</a>, etc.), and
                                    many
                                    more Ayurvedic therapies are used to treat diabetes.</p>
                                <p>The method chosen for therapy, however, is heavily influenced by how serious the
                                    patient's
                                    disease is. The purpose of these therapies is to increase the sensitivity of the
                                    type 4
                                    glucose receptors to insulin. The outcome is a decrease in insulin resistance and an
                                    increase in insulin production, which also encourages beta-cell rebirth. The
                                    management
                                    procedure consists of:</p>
                                <ul>
                                    <li>Ayurvedic herbal remedies.</li>
                                    <li>Many treatments support the body's restoration detox process.</li>
                                    <li>Diabetes can be managed with adjustments in diet.</li>
                                    <li>Diabetes can be managed with a change in lifestyle.</li>
                                </ul>
                                <p>In addition, it's important to lead a healthy lifestyle. Add regular exercise and a
                                    gym
                                    regimen to your daily routine. Having a healthy diet, and adding bitter fruits can
                                    be
                                    beneficial. Astringent fruits and vegetables such as gourd, bitter gourd, and others
                                    can
                                    assist in controlling blood sugar levels.</p>
                                <h3><strong>Which Stages are Curable in Ayurveda?</strong></h3>
                                <p>Ayurveda places a heavy emphasis on the mind-body connection, diet, exercise,
                                    lifestyle
                                    changes, and herbal usage. It has the potential to significantly affect both
                                    diabetes
                                    patients and the healthcare system.</p>
                                <p><a
                                    href="https://www.draxico.com/blog/Ayurvedic-home-remedies-to-control-sugar-levels-naturally">Diabetes
                                    home remedies</a> are effective as in its early stage it can be healed at home.</p>
                                <p>Modifying one's lifestyle is frequently the best way to cure chronic diseases such as
                                    sugar
                                    or diabetes. Diabetes can go into remission, but there is presently no known
                                    treatment.
                                    Although the illness is still technically present, when diabetes enters remission,
                                    the
                                    body
                                    stops exhibiting any symptoms.</p>
                                <h3><strong>Ayurvedic Remedies for Sugar?</strong></h3>
                                <p>Here we are suggesting some best ayurvedic diabetes treatment remedies.</p>
                                <p><strong>Vijaysar&nbsp;</strong>- Vijaysar, often referred to as Pterocarpus
                                    Marsupium, is
                                    reported to regulate blood sugar levels and effectively manage diabetes. This herb's
                                    anti
                                    hyperlipidemic characteristics aid in lowering the body's levels of blood
                                    triglycerides,
                                    low-density lipoprotein, and total cholesterol. Also, it aids in easing the symptoms
                                    of
                                    diabetes, such as frequent urination, binge eating, and limb burning. Moreover, it
                                    appears
                                    to enhance digestion and increase pancreatic insulin production.</p>
                                <p>How to Use: Tumblers from Vijaysar are widely available. The tumbler only needs water
                                    added,
                                    then you leave it overnight. First thing in the morning, drink it. Vijaysar is also
                                    available as a powder that may be consumed.</p>
                                <p><strong>Sadabahar</strong>: In India, periwinkle, also known as sadabahar, is a
                                    common
                                    plant.
                                    The glossy leaves of this plant have a dark green color and are said to treat type 2
                                    diabetes medications.</p>
                                <p>How to use: To naturally control blood sugar levels, simply chew a few fresh leaves.
                                    Another
                                    method of using it is to boil some of the sadabahar plant's pink blooms in a cup of
                                    water.
                                    Every morning, strain the water and sip it on an empty stomach.</p>
                                <p><strong>Giloy:</strong> Giloy, often known as amrita, is a plant with the scientific
                                    name
                                    Tinospora cordifolia. The plant's leaves are essential for treating diabetes and
                                    controlling
                                    blood sugar levels. It is an excellent plant for improving immunity since it
                                    contains
                                    antioxidants that fight dangerous free radicals.</p>
                                <p>It is a natural anti diabetic medication that lessens the desire for sugar. Moreover,
                                    it
                                    boosts the pancreas's ability to produce beta cells. As a consequence, blood glucose
                                    and
                                    insulin levels are consistently controlled. Moreover, giloy helps with digestion,
                                    which
                                    is
                                    crucial for controlling blood sugar levels.</p>
                                <p>How to Use: To use, add giloy powder, leaves, or bark to water and drink it first
                                    thing
                                    in
                                    the morning.&nbsp;<a
                                        href="https://www.draxico.com/product-detail/giloy-plus-capsule">Giloy
                                        tablets</a> are also available for easy use.</p>
                                <p><strong>Gurmar</strong>: Gymnema Sylvestre known as gurmar, is a perennial woody vine
                                    that
                                    develops in the tropics of Australia, Africa, and India. It includes several
                                    substances,
                                    such as gurmarin and flavonols, which are beneficial for diabetics. Did you know
                                    that
                                    gurmar
                                    means "the destroyer of sugar"?</p>
                                <p>How to Use: Half an hour after lunch and supper, take a spoonful of powdered gurmar
                                    leaves
                                    with water. This could control how well the body absorbs carbs.</p>
                                <h3><strong>What to Eat and Not Eat in Diabetes?</strong></h3>
                                <p>Those who have diabetes can control their blood sugar levels by eating specific meals
                                    and
                                    avoiding others. A diet completed with vegetables, fruits, and lean proteins can
                                    provide
                                    several advantages.</p>
                                <p>Divide these in DO and DON’TS</p>
                                <ul>
                                    <li>Consume less oil, and warm foods while avoiding fatty, and heavy items.</li>
                                    <li>Fried meals, tuber vegetables, fizzy beverages, and fruits including bananas,
                                        mangos,
                                        custard apples, and dates should all be avoided. Wine and cakes should be
                                        avoided.
                                    </li>
                                    <li>Less use of Milk products or used in moderation as they worsen the Kapha Dosha.
                                        Choose
                                        milk products with low fat. Ghee serves to strengthen the digestive fire; thus,
                                        it
                                        may
                                        be taken in moderation.
                                    </li>
                                    <li>Eatable fruits include Anaar, berries, and apples.</li>
                                    <li>Go with millet and maize instead of Wheat and rice.</li>
                                    <li>According to Ayurveda, meats cause inflammation and should be avoided.</li>
                                    <li>Food must be consumed warmly to manage blood sugar through diet.</li>
                                    <li>Legumes and beans are required in the Kapha diet. For people with diabetes,
                                        moong
                                        beans
                                        in particular are highly beneficial.
                                    </li>
                                    <li>Bitter foods are good for controlling sugar.</li>
                                    <li>As spices are beneficial for Kapha Dosha, they should be utilized when cooking.
                                        The
                                        diet
                                        must contain ginger, pepper, garlic, mustard, etc. Digestion is helped by
                                        drinking
                                        ginger tea. Yet salt ought to be avoided or used sparingly.
                                    </li>
                                </ul>
                                <h3><strong>Recommended Ayurvedic Herbs and Medication for Diabetes</strong></h3>
                                <p>These are a few plants that are thought to aid in the control of diabetes. Get a&nbsp;<a
                                    href="https://www.draxico.com/blog/ayurvedic-diabetes-treatment-how-a-ayurvedic-medicine-can-help-you-reverse-diabetes">list
                                    of Ayurveda herbs for diabetes</a> in this area. They could aid in keeping blood
                                    sugar
                                    levels stable and controlling diabetes.</p>
                                <p>Dr. Axico’s ayurvedic diabetes medicine&nbsp;<a
                                    href="https://www.draxico.com/product-detail/diabetes-manager">Diabetes Management
                                    (DMY-3)</a> is most recommended among many other medicines. Some of the most
                                    recommended
                                    Herbs and medications for diabetes ayurvedic treatment are:</p>
                                <ul>
                                    <li>Ginseng</li>
                                    <li>Giloy (one of the best type 2 diabetes medication)</li>
                                    <li><a
                                        href="https://www.draxico.com/blog/amazing-health-benefits-of-golden-spice-turmeric">Turmeric</a>
                                    </li>
                                    <li>Fenugreek</li>
                                    <li>Cinnamon</li>
                                    <li>Bitter gourd (karela)</li>
                                    <li>Aloe vera</li>
                                    <li>Bitter melon</li>
                                    <li>Milk thistle</li>
                                    <li>Psyllium</li>
                                </ul>
                                <p><strong>A note from Dr. Axico</strong></p>
                                <p>Although receiving a diabetes diagnosis is a significant life change, it should not
                                    preclude
                                    you from leading a fulfilled and healthy life. Consistent vigilance and care are
                                    required to
                                    manage diabetes. Even while it'll probably be rather overwhelming at first, you'll
                                    eventually gain a better understanding of regulating the condition and tuning into
                                    your
                                    body.</p>
                                <p>Diabetes can be reversed with Dr. Axico’s&nbsp; Ayurvedic doctor online consultation.
                                    Here,
                                    you will be able to have a complete checkup and a diet plan to live a healthy life.
                                    In
                                    Addition, you can buy ayurvedic medicine online or offline to lead a healthy
                                    lifestyle.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Can Ayurveda Reverse Diabetes?</strong>
                                        </h4>
                                        <p>Yes, we can, indeed. Diabetes can be reversed with proper lifestyle management,
                                            pacifying
                                            the
                                            effects of affected doshas, improving digestion, and detoxifying the body. Reverse
                                            prediabetes with ayurveda by altering one's lifestyle, eating routine, and level of
                                            physical
                                            activity.</p><h4><strong>What Color is Urine in diabetes?</strong></h4>
                                        <p>Although you typically would only urinate in tiny volumes of concentrated, dark
                                            yellow
                                            urine.
                                            Diabetes insipidus causes you to continue to urinate huge amounts of watery
                                            (dilute),
                                            light-colored urine.</p><h4><strong>How to Permanently Cure Diabetes in
                                        Ayurveda?</strong>
                                    </h4>
                                        <p>There is no complete cure for diabetes. But it may be controlled and prevented from
                                            getting
                                            worse by following a healthy diet and lifestyle. These are some foods that might
                                            support
                                            that: Beans, eggs, kale, fish, cranberries, olive oil, oats, cinnamon, and
                                            grapes.</p>
                                        <h4>
                                            <strong>What Should Diabetics Not Eat in Ayurveda?</strong></h4>
                                        <p>Juice, sweet tea, sports drinks, and fried food like chips, microwave popcorn, and
                                            processed
                                            meat should be avoided. Partly fried foods, dairy-free coffee creamers, and
                                            vegetable
                                            shortening are the root cause of diabetes.</p><h4><strong>What herbs Lower Sugar
                                        Level?</strong></h4>
                                        <p>The condition of diabetes responds well to herbs like giloy, vijaysar, gumar,
                                            sadabahar,
                                            cinnamon, turmeric, etc.</p><h4><strong>Does Stress Cause Diabetes?</strong></h4>
                                        <p>Although stress may not directly cause diabetes, it can affect your blood sugar
                                            levels
                                            and
                                            how you handle the condition. Diabetes management may be difficult on its own, in
                                            addition
                                            to the ups and downs of daily living. It's not always easy to live with it, and it
                                            could
                                            appear more challenging when many others don't understand it.</p><h4><strong>Sugar
                                        ki
                                        ayurvedic dawa kha se le?</strong></h4>
                                        <p>Aap sugar ki ayurvedic medicine online khareed sakte hai. Aap Dr. Axico se bhi kharid
                                            sakte
                                            hai jaha aap doctors se free consultation bhi book kar sakte hai.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'heart-disease'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Heart Diseases: Early Signs, Stages & Non Operating Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Heart disease is a major public health concern globally, and it is the leading cause
                                    of
                                    death
                                    worldwide. According to the World Health Organization, an estimated 17.9 million
                                    people
                                    died
                                    from cardiovascular disease in 2019, accounting for 32% of all global deaths. There
                                    is
                                    no
                                    denying that heart disease can be extremely severe, an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a> would
                                    be
                                    wise
                                    to combat heart disease.</p>
                                <p>In India, heart disease is also a significant health problem. According to a report
                                    published
                                    in The Lancet in 2018, the prevalence of&nbsp;<a
                                        href="https://www.draxico.com/blogs/why-young-indians-dying-of-heart-attack-and-ayurvedic-take-on-it-dr-axico">cardiovascular
                                        disease in India</a> has risen sharply in recent decades, and it is now the
                                    leading
                                    cause of death in the country. The report estimated that over 61 million people in
                                    India
                                    have cardiovascular disease, and this number is expected to rise to 100 million by
                                    2030.
                                </p>
                                <h2>WHAT IS A HEART DISEASE?</h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/heart-disease/Image.webp"
                                    >
                                </div>
                                <p>Heart disease is a broad term that refers to any condition that affects the heart. It
                                    includes a range of disorders that affect the heart's structure, function or both.
                                    The
                                    heart
                                    is a crucial organ that circulates blood throughout the body, feeding cells and
                                    organs
                                    with
                                    nutrition and oxygen.&nbsp;</p>
                                <p>When the heart is not functioning properly due to disease, it can lead to serious
                                    health
                                    problems. The most common type of heart disease is&nbsp;<a
                                        href="https://www.draxico.com/blogs/ayurvedic-treatment-and-medicine-to-open-the-blockage-of-veins-dr-axico">coronary
                                        artery disease</a>, which occurs when the arteries that supply blood to the
                                    heart
                                    become
                                    narrow or blocked, leading to chest pain or a heart attack.</p>
                                <h3>What are the causes of heart disease?
                                </h3>
                                <p>Heart disease can have multiple causes, including lifestyle factors, medical
                                    conditions,
                                    and
                                    genetic factors. Some of the common causes of heart disease are:</p>
                                <ul>
                                    <li>High blood pressure: High blood pressure can damage the arteries and increase
                                        the
                                        risk
                                        of developing heart disease.
                                    </li>
                                    <li>High cholesterol: High levels of cholesterol in the blood can contribute to the
                                        symptoms
                                        of blocked arteries, which can lead to heart disease.
                                    </li>
                                    <li>Smoking: Smoking is a significant risk factor for heart disease, as it damages
                                        the
                                        blood
                                        vessels and increases the risk of developing atherosclerosis.
                                    </li>
                                    <li>Diabetes: People with diabetes are at an increased risk of developing heart
                                        disease,
                                        as
                                        high blood sugar levels can damage the blood vessels and nerves that control the
                                        heart.
                                    </li>
                                    <li>Obesity: Being overweight or obese can increase the risk of developing heart
                                        disease
                                        by
                                        putting extra strain on the heart and increasing the risk of other risk factors,
                                        such as
                                        high blood pressure and diabetes.
                                    </li>
                                    <li>Family history: A family history of heart disease may make a person more likely
                                        to
                                        get
                                        the illness.
                                    </li>
                                    <li>Physical inactivity: Lack of exercise or physical activity can increase the risk
                                        of
                                        developing heart disease.
                                    </li>
                                    <li>Age: The likelihood of acquiring heart disease rises with age.</li>
                                </ul>
                                <p>Other factors that can contribute to the development of heart disease include stress,
                                    poor
                                    diet, excessive alcohol consumption, and certain medical conditions such as
                                    autoimmune
                                    diseases and infections.</p>
                                <h3> What are the early signs and symptoms of heart disease?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/heart-disease/Image-2.webp"
                                    >
                                </div>
                                <p>Depending on the kind of heart disease and the severity of the condition, the signs
                                    and
                                    symptoms of heart disease might change. Some common early signs of heart disease
                                    include:</p>
                                <ol>
                                    <li>Chest pain or discomfort: This is one of the most common symptoms of heart
                                        disease.
                                        It
                                        may feel like tightness, pressure, or a squeezing sensation in the chest.
                                    </li>
                                    <li>Shortness of breath: Feeling short of breath, especially during physical
                                        activity,
                                        can
                                        be an early sign of heart disease.
                                    </li>
                                    <li>Fatigue: Feeling tired or weak, especially with exertion or activity, can be an
                                        early
                                        sign of heart disease.
                                    </li>
                                    <li>Swelling: Swelling in the legs, ankles, or feet can be a sign of heart disease,
                                        especially if it is accompanied by shortness of breath or fatigue.
                                    </li>
                                    <li>Irregular heartbeat: An irregular heartbeat, or arrhythmia, can be an early sign
                                        of
                                        heart disease.
                                    </li>
                                    <li>Dizziness or light-headedness: Feeling dizzy or lightheaded, especially with
                                        exertion,
                                        can be an early sign of heart disease.
                                    </li>
                                    <li>Nausea or vomiting: Some people with heart disease may experience nausea or
                                        vomiting,
                                        especially if the condition is causing reduced blood flow to the digestive
                                        system.
                                    </li>
                                </ol>
                                <p>Fatigue, Dizziness, Abdominal Pain, Sweating, swelling in legs, ankles, and feet, and
                                    heart
                                    palpitations are the 6 signs of heart attack a month before. It is important to note
                                    that
                                    not everyone with heart disease experiences symptoms, and some people may have a
                                    heart
                                    attack or other serious complications without any warning signs.&nbsp;</p>
                                <p>Therefore, it is crucial to take steps to prevent heart disease, such as maintaining
                                    a
                                    healthy lifestyle, managing underlying medical conditions, and getting regular
                                    check-ups
                                    with a healthcare provider. Apart from the above mentioned, the signs of a heart
                                    attack
                                    in
                                    women are light-headed, Feeling nauseous, or unusually tired.</p>
                                <p>If you are facing pain you can take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/general-health-assessment">free self
                                    assessment test online for Heart</a>.</p>
                                <h2> What are the major causes and Different Types of Heart Diseases?
                                </h2>
                                <p>There are several types of heart disease, a few of which are described below:</p>
                                <ol>
                                    <li>Coronary artery disease (CAD): This is the most common type of heart disease,
                                        where
                                        plaque builds up inside the arteries that supply blood to the heart muscle,
                                        reducing
                                        blood flow and oxygen to the heart.
                                    </li>
                                    <li>Heart failure: When the heart cannot pump enough blood to meet the body's
                                        demands,
                                        heart
                                        failure develops.
                                    </li>
                                    <li>Arrhythmia: This is an irregular heartbeat, which can be too fast or too slow.
                                    </li>
                                    <li>Heart valve disease: This is a condition where one or more of the heart valves
                                        don't
                                        function properly, either not closing tightly enough or not opening fully.
                                    </li>
                                    <li>Congenital heart disease: This is a type of heart disease that a person is born
                                        with,
                                        often involving a structural abnormality in the heart or blood vessels.
                                    </li>
                                    <li>Cardiomyopathy: This is a disease of the heart muscle, causing it to become
                                        enlarged,
                                        thickened, or rigid.
                                    </li>
                                    <li>Pericardial disease: This is a condition that affects the sac around the heart,
                                        causing
                                        inflammation or fluid build-up.
                                    </li>
                                    <li>Aortic aneurysm and dissection: These are a condition where the wall of the
                                        aorta,
                                        the
                                        main blood vessel that carries blood from the heart to the rest of the body,
                                        weakens
                                        and
                                        bulges outwards, and can rupture or tear.
                                    </li>
                                    <li>Hypertensive heart disease: This is a condition where high blood pressure causes
                                        damage
                                        to the heart muscle and blood vessels.
                                    </li>
                                    <li>Myocarditis: This is an inflammation of the heart muscle, often caused by a
                                        viral
                                        infection.
                                    </li>
                                </ol>
                                <h3> NOTICEABLE CHANGES IN BODY
                                </h3>
                                <p>Some of the noticeable changes that may occur before the onset of heart disease
                                    include:</p>
                                <ul>
                                    <li>High blood pressure: High blood pressure, also known as hypertension, is a
                                        common
                                        risk
                                        factor for heart disease. It often has no noticeable symptoms, which is why it's
                                        important to have your blood pressure checked regularly.
                                    </li>
                                    <li>High cholesterol levels: The risk of heart disease can rise when blood
                                        cholesterol
                                        levels are high. There may be no noticeable silent heart attack symptoms, so
                                        it's
                                        important to have your cholesterol levels checked regularly.
                                    </li>
                                    <li>Chest pain: Chest pain or discomfort, especially during physical activity, could
                                        be
                                        a
                                        sign of angina, which is caused by reduced blood flow to the heart muscle.
                                        Angina
                                        treatment in Ayurveda is possible.&nbsp;
                                    </li>
                                    <li>Shortness of breath: Feeling out of breath, especially during physical activity
                                        or
                                        while
                                        lying down, could be a sign of heart failure, a condition where the heart is
                                        unable
                                        to
                                        circulate the body's necessary amount of blood.
                                    </li>
                                    <li>Fatigue: Feeling excessively tired or weak, especially during physical activity,
                                        could
                                        be a sign of heart failure.
                                    </li>
                                    <li>Swelling: Swelling in the legs, ankles, or feet, especially if it is accompanied
                                        by
                                        shortness of breath, could be a sign of heart failure.
                                    </li>
                                </ul>
                                <p>It is important to pay attention to symptoms of clogged arteries and seek medical
                                    attention
                                    if you experience any of them, as they could indicate an increased risk for heart
                                    disease.
                                    Making healthy lifestyle choices, such as eating a balanced diet, exercising
                                    regularly,
                                    not
                                    smoking, and managing stress, can also help reduce the risk of developing heart
                                    disease.</p>
                                <h2>DIAGNOSIS AND TESTS RELATED TO HEART DISEASE
                                </h2>
                                <p>Several tests and procedures can be used to diagnose and assess heart diseases. Some
                                    of
                                    the
                                    common ones include:&nbsp;</p>
                                <ol>
                                    <li>Electrocardiogram (ECG or EKG): This is a non-invasive test that measures the
                                        electrical
                                        activity of the heart to check for any irregular heart rhythms or damage to the
                                        heart
                                        muscle.
                                    </li>
                                    <li>Stress test: This is a test that involves exercising on a treadmill or
                                        stationary
                                        bike
                                        while being monitored with an ECG to assess the heart's response to physical
                                        activity.
                                    </li>
                                    <li>Echocardiogram: This non-invasive examination employs sound waves to produce
                                        images
                                        of
                                        the heart to evaluate its composition and operation.
                                    </li>
                                    <li>Cardiac catheterization: This is an invasive test that involves threading a thin
                                        tube
                                        called a catheter through a blood vessel to the heart to check for blockages in
                                        the
                                        coronary arteries.
                                    </li>
                                    <li>Angiography: This is an X-ray test that uses a special dye to visualise the
                                        coronary
                                        arteries to assess for blockages.
                                    </li>
                                    <li>Blood tests: These tests can be used to check for markers of heart disease, such
                                        as
                                        high
                                        levels of cholesterol or inflammation.
                                    </li>
                                    <li>CT scan or MRI: These imaging tests can be used to create detailed images of the
                                        heart
                                        and blood vessels to assess their structure and function.
                                    </li>
                                    <li>Holter monitor: This is a portable device that records the heart's electrical
                                        activity
                                        over 24-48 hours to assess for any irregular heart rhythms.
                                    </li>
                                    <li>Event monitor: This is a portable device that is worn for a longer period (weeks
                                        to
                                        months) to record the heart's electrical activity when symptoms occur.
                                    </li>
                                </ol>
                                <p>If you want to consult your about your health condition you can take a&nbsp;<a
                                    href="https://www.draxico.com/appointment">heart consultation with ayurvedic
                                    doctor</a>
                                </p>
                                <h2>LIFESTYLE AND HEART ATTACK</h2>
                                <p>There is a well-established link between lifestyle factors and the risk of heart
                                    attack.
                                    A
                                    heart attack occurs when the blood flow to the heart is blocked, often by a build-up
                                    of
                                    plaque in the arteries. Lifestyle factors such as diet, exercise, smoking, and
                                    stress
                                    can
                                    all contribute to the development of this build-up of plaque and increase the risk
                                    of
                                    heart
                                    attack.</p>
                                <ul>
                                    <li>Diet is an important factor in preventing heart attack. Heart disease risk can
                                        be
                                        raised
                                        by consuming a diet heavy in cholesterol, salt, and saturated and trans fats. On
                                        the
                                        other hand, a diet rich in fruits, vegetables, whole grains,&nbsp;<a
                                            href="https://www.foodnetwork.com/healthy/photos/9-lean-proteins-you-should-be-eating"
                                            rel="nofollow">lean
                                            protein</a>, and healthy fats can lower the risk of heart attack.
                                    </li>
                                </ul>
                                <p>It is important to limit the intake of processed and fast foods, sugary drinks, and
                                    alcohol,
                                    as they can contribute to an unhealthy diet and increase the risk of heart
                                    disease.</p>
                                <ul>
                                    <li>Regular exercise is also crucial for heart health. Exercise helps to improve
                                        blood
                                        flow,
                                        lower blood pressure, and reduce the risk of heart disease. Experts recommend at
                                        least
                                        150 minutes of moderate-intensity exercise or 75 minutes of vigorous-intensity
                                        exercise
                                        per week, along with muscle-strengthening activities at least two days per week.
                                    </li>
                                    <li>Smoking should be avoided as it poses a significant risk of developing heart
                                        disease.
                                        Nicotine in cigarettes can cause the blood vessels to narrow, which can increase
                                        the
                                        risk of blood clots and heart attacks. Quitting smoking can significantly lower
                                        the
                                        risk
                                        of heart attack and other health problems.
                                    </li>
                                </ul>
                                <p>&nbsp;Finally, managing stress is important for heart health. Chronic stress can lead
                                    to
                                    the
                                    release of hormones that can increase blood pressure and contribute to the
                                    development
                                    of
                                    plaque build-up in the arteries. Techniques such as deep breathing, yoga, and
                                    meditation,
                                    can help to reduce stress and promote relaxation.</p>
                                <h2>Ayurvedic Say on Heart Diseases and Risk Factors?</h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/heart-disease/Image-3.webp"
                                    >
                                </div>
                                <p>According to Ayurveda, there are five types of heart disease described in authentic
                                    texts
                                    according to Dosha namely; Vataja, Pittaja, Kaphaja, Sannipataja, and Krimija.
                                    Respectively
                                    and predominantly involving pain, burning, phlegm, a combination of those three, and
                                    bacterial infections.&nbsp;</p>
                                <p>Etiological factors of Hrud Roga are well described in Ayurveda; Sorrow (Shoka),
                                    fasting
                                    (Upavasa), excessive exercises (Vyayama), foods with excessive dryness, unwholesome
                                    food
                                    in
                                    less quality and quantity leads to vitiation of vata dosha involving heart functions
                                    results
                                    in hrud roga.&nbsp;</p>
                                <p>Excessive intake of hot and heavy food, astringent and bitter taste, intake of food
                                    before
                                    digestion of a previous meal, physical strain, emotional feelings, and suppression
                                    of
                                    natural urges results in vitiation of Doshas (Vata, Pitta, and Kapha) thereby
                                    abnormality of
                                    Rasa dhatu (mostly correlated to plasma) which afflicts in the heart.</p>
                                <h3> How Ayurveda Can Help in Heart Disease Prevention?
                                </h3>
                                <p>Ayurvedic medicine for heart disease from India offers a holistic approach to
                                    healthcare
                                    and
                                    can help prevent heart disease. Here are some ways Ayurveda can help in heart
                                    disease
                                    prevention:</p>
                                <ol>
                                    <li><strong>Dietary modifications:&nbsp;</strong>Ayurveda recommends a diet that is
                                        tailored
                                        to an individual's constitution, or dosha, and emphasises whole foods, fruits,
                                        vegetables, and whole grains. It also advises avoiding processed foods, fried
                                        foods,
                                        and
                                        foods high in saturated and trans fats, which can contribute to heart disease.
                                    </li>
                                    <li><strong>Herbal remedies:</strong> Ayurveda uses a variety of herbs and spices
                                        that
                                        can
                                        have cardiovascular benefits. For example, garlic can help lower blood pressure
                                        and
                                        cholesterol levels, while turmeric has anti-inflammatory properties that may
                                        reduce
                                        the
                                        risk of heart disease.
                                    </li>
                                    <li><strong>Stress reduction:</strong> Ayurveda recognizes the impact of stress on
                                        heart
                                        health and offers various techniques for stress reduction, such as meditation,
                                        yoga,
                                        and
                                        pranayama (breathing exercises).
                                    </li>
                                    <li><strong>Lifestyle modifications:</strong> Ayurveda recommends regular exercise,
                                        adequate
                                        sleep, and avoiding smoking and excessive alcohol consumption, all of which can
                                        contribute to heart disease.
                                    </li>
                                </ol>
                                <p><a
                                    href="https://www.draxico.com/panchakarma-treatment/"><strong>Panchakarma:</strong></a>
                                    Panchakarma is a detoxification and rejuvenation therapy in
                                    Ayurveda that can help remove toxins from the body and improve overall health,
                                    including
                                    heart health.</p>
                                <h3>Which Stages of Heart Diseases are Curable in Ayurveda?
                                </h3>
                                <p>Ayurvedic treatment for coronary artery disease can help manage symptoms, reducing
                                    the
                                    risk
                                    of complications, and improving overall heart health.</p>
                                <p>In the early stages of heart disease, Ayurvedic treatment for the heart can be
                                    particularly
                                    effective. For example, lifestyle modifications such as diet and exercise can help
                                    to
                                    reduce
                                    high blood pressure, <a href="https://www.draxico.com/diseases/cholesterol-level">
                                        high
                                        cholesterol</a>, and other risk factors for heart disease. Herbal
                                    remedies, such as arjuna (Terminalia arjuna), may also help to strengthen the heart
                                    and
                                    improve blood flow.&nbsp;</p>
                                <p>Angina treatment in Ayurveda can also help manage complications of heart disease,
                                    such as
                                    congestive heart failure. For example, diuretic herbs such as punarnava (Boerhavia
                                    diffusa)
                                    can help to reduce swelling and fluid buildup in the body, while hawthorn (Crataegus
                                    spp.)
                                    may improve heart function.</p>
                                <p>In Ayurveda, heart disease is viewed as a result of imbalances in the body, such as
                                    high
                                    levels of toxins, inflammation, and poor digestion. Therefore, Ayurvedic treatments
                                    aim
                                    to
                                    restore balance and improve overall health, which can potentially improve heart
                                    health
                                    and
                                    reduce the risk of further complications.</p>
                                <h3>Ayurvedic Remedies for Heart Disease?
                                </h3>
                                <p>Many home remedies can help manage the symptoms and risk factors associated with
                                    heart
                                    disease. Here are some preparations for home remedies that you can try:</p>
                                <ul>
                                    <li>Lemon water: Squeeze the juice of half a lemon into a glass of warm water and
                                        drink
                                        it
                                        first thing in the morning. Lemon water can help improve digestion, reduce
                                        inflammation,
                                        and may help lower blood pressure.
                                    </li>
                                    <li>Garlic and honey tonic: Crush 2-3 garlic cloves and mix them with a tablespoon
                                        of
                                        honey.
                                        Take this mixture daily to help lower cholesterol levels and improve heart
                                        health.
                                    </li>
                                    <li>Turmeric milk: Heat a cup of milk with a teaspoon of turmeric powder, a pinch of
                                        black
                                        pepper, and a teaspoon of honey. Drink this warm milk at night to help reduce
                                        inflammation and improve heart health.
                                    </li>
                                    <li>Cinnamon tea: Boil a cinnamon stick in a cup of water for a few minutes, then
                                        strain
                                        and
                                        drink. Cinnamon tea can help improve circulation and may help lower blood
                                        pressure.
                                    </li>
                                    <li>Apple cider vinegar and honey tonic: Mix a tablespoon of apple cider vinegar and
                                        a
                                        tablespoon of honey in a glass of warm water. Drink this mixture daily to help
                                        reduce
                                        inflammation, lower cholesterol levels, and improve heart health.
                                    </li>
                                </ul>
                                <h3>What to Eat and not eat?
                                </h3>
                                <p>Our eating habits and a proper diet plan can be helpful in heart diseases ayurvedic
                                    treatment.&nbsp;</p>
                                <p><strong>What to eat:</strong></p>
                                <ul>
                                    <li>Whole grains: Whole grains such as brown rice, quinoa, and barley are rich in
                                        fiber,
                                        which can help lower cholesterol levels and reduce the risk of heart disease.
                                    </li>
                                    <li>Fresh fruits and vegetables: Fruits and vegetables are rich in antioxidants,
                                        vitamins,
                                        and minerals, which can help reduce inflammation and improve heart health.
                                    </li>
                                    <li>Legumes: Legumes such as lentils, chickpeas, and beans are rich in protein,
                                        fiber,
                                        and
                                        vitamins, and can help lower cholesterol levels and reduce the risk of heart
                                        disease.
                                    </li>
                                    <li>Nuts and seeds: Rich in heart-healthy fats, fibre, and antioxidants, nuts and
                                        seeds
                                        including almonds, walnuts, chia seeds, and flaxseeds can help to enhance heart
                                        health.
                                    </li>
                                    <li>Spices: Spices such as turmeric, ginger, garlic, and cinnamon have
                                        anti-inflammatory
                                        and
                                        antioxidant properties that can help reduce the risk of heart disease.
                                    </li>
                                </ul>
                                <h3>What Not to Eat:
                                </h3>
                                <ul>
                                    <li>Processed foods: Processed foods such as fast food, snack foods, and frozen
                                        meals
                                        are
                                        often high in salt, sugar, and unhealthy fats, which can contribute to
                                        inflammation
                                        and
                                        increase the risk of heart disease.
                                    </li>
                                    <li>Red meat: Red meat is high in saturated fats, which can increase cholesterol
                                        levels
                                        and
                                        contribute to heart disease.
                                    </li>
                                    <li>Fried foods: Fried foods such as French fries, fried chicken, and fried fish are
                                        often
                                        high in unhealthy fats and calories, which can contribute to inflammation and
                                        increase
                                        the risk of heart disease.
                                    </li>
                                    <li>Refined carbohydrates: Refined carbohydrates such as white bread, white rice,
                                        and
                                        sugary
                                        snacks can cause spikes in blood sugar and contribute to inflammation, which can
                                        increase the risk of heart disease.
                                    </li>
                                    <li>Excessive salt: Excessive salt intake can contribute to high blood pressure,
                                        which
                                        is a
                                        risk factor for heart disease.
                                    </li>
                                </ul>
                                <h3>Ayurvedic herbs and medications that are recommended for heart disease:
                                </h3>
                                <ul>
                                    <li>Arjuna (Terminalia arjuna)</li>
                                    <li>Ashwagandha (Withania somnifera)</li>
                                    <li>Brahmi (Bacopa monnieri)</li>
                                    <li>Guggulu (Commiphora mukul)</li>
                                    <li>Triphala (combination of amla, haritaki, and bibhitaki)</li>
                                    <li>Dashamoola (combination of ten roots)</li>
                                    <li>Hridayamrit Vati</li>
                                    <li>Arjuna Capsules</li>
                                    <li>Mukta Vati</li>
                                    <li>Triphala Guggulu</li>
                                    <li>Punarnavadi Guggulu</li>
                                    <li>Sarpagandha</li>
                                    <li>Shankhapushpi</li>
                                </ul>
                                <h4>A Short Note from Dr. Axico
                                </h4>
                                <p>Heart and blood vessel disorders are referred to as cardiovascular illnesses. Heart
                                    illness
                                    can cause heart attacks or strokes if not properly treated. You can make lifestyle
                                    changes
                                    or take medications to manage cardiovascular disease. An early diagnosis can lead to
                                    more
                                    effective treatment. Cardiovascular disease affects many people, many of whom lead
                                    busy,
                                    happy lives.</p>
                                <p>With the help of Dr. Axico's online Ayurvedic doctor consultation, heart disease can
                                    be
                                    cured. Here, you can receive a thorough examination and a nutrition plan for leading
                                    a
                                    healthy life. To further support a healthy lifestyle, you can buy Ayurvedic medicine
                                    online
                                    and offline.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4>What is a mild heart attack?
                                        </h4>
                                        <p>A mild heart attack is when one of the blood vessels that supply the heart gets
                                            partially
                                            blocked. This can cause damage to the heart muscle, but not completely stop blood
                                            flow.
                                            Mini
                                            heart attack symptoms can include chest pain, shortness of breath, feeling sick,
                                            feeling
                                            dizzy, or pain in the arms, back, neck, jaw, or stomach.&nbsp;</p>
                                        <p>Treatment can include medicine, making lifestyle changes, and sometimes a procedure
                                            to
                                            open
                                            the blocked blood vessel. It's important to get medical help right away to prevent
                                            further
                                            problems with the heart.</p>
                                        <h4>What does a heart attack start like?</h4>
                                        <ul>
                                            <li>A heart attack can start with various symptoms.</li>
                                            <li>Chest pain or discomfort is the most common symptom.</li>
                                            <li>Shortness of breath, nausea, vomiting, indigestion, light-headedness or
                                                dizziness,
                                                and
                                                cold sweat can also be symptoms of a heart attack.
                                            </li>
                                            <li>Some people may experience atypical silent heart attack symptoms or no symptoms
                                                at
                                                all.
                                            </li>
                                        </ul>
                                        <h4>How can I test myself for a heart attack?
                                        </h4>
                                        <p>If you suspect that you or someone else is having a heart attack, you should call
                                            your
                                            local
                                            emergency services or go to the nearest emergency room immediately. A heart attack
                                            is a
                                            medical emergency, and time is critical to prevent damage to the heart muscle.</p>
                                        <p>While you cannot diagnose a heart attack yourself, there are some warning signs and
                                            symptoms
                                            given above that you should be aware of.</p>
                                        <h4>Can stress cause a heart attack?
                                        </h4>
                                        <p>Yes, stress can contribute to the development of heart disease and increase the risk
                                            of
                                            heart
                                            attack. When you experience stress, your body produces hormones like adrenaline and
                                            cortisol
                                            that can cause your heart rate and blood pressure to increase, which can put extra
                                            strain on
                                            your heart.
                                        </p>
                                        <h4>How to control heart attacks at home?
                                        </h4>
                                        <p>It's important to note that a heart attack is a medical emergency, and if you suspect
                                            that
                                            you or someone else is having a heart attack, you should call for emergency medical
                                            help
                                            immediately. While waiting for medical help to arrive, there are a few things that
                                            you
                                            can
                                            do to manage the situation:</p>
                                        <ul>
                                            <li>Sit down and rest: If you are experiencing symptoms of a heart attack, it's
                                                important to
                                                sit down and rest. Try to remain calm and avoid any physical exertion.
                                            </li>
                                            <li>Chew and swallow an aspirin: If you have been prescribed aspirin by your doctor,
                                                or
                                                if
                                                you have a bottle of aspirin at home, chew and swallow one tablet (325 mg) as
                                                soon
                                                as
                                                possible. This can help to prevent blood clots from forming and worsening the
                                                heart
                                                attack.
                                            </li>
                                            <li>Loosen tight clothing: If you are wearing tight clothing, such as a tie or a
                                                bra,
                                                loosen
                                                it to make breathing easier.
                                            </li>
                                            <li>Use nitroglycerin: If you have been prescribed nitroglycerin by your doctor,
                                                follow
                                                their instructions for taking it during a heart attack.
                                            </li>
                                            <li>Stay calm and relaxed: Stress and anxiety can make a heart attack worse, so try
                                                to
                                                stay
                                                calm and relaxed. If you are with someone who is having a heart attack, offer
                                                words
                                                of
                                                reassurance and comfort.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'food-poisoning'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Food Poisoning: Preventions, Causes & Ayurvedic Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Food poisoning, also known as foodborne illness, is a common problem that occurs when
                                    people
                                    consume contaminated food or drinks. The contamination can occur at any point during
                                    the
                                    production, processing, handling, or preparation of food. We are the pioneers of the
                                    promising Ayurvedic medical method. You may connect with an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online</a> in a few easy steps
                                    to
                                    pursue a disease free life.&nbsp;&nbsp;&nbsp;</p>
                                <p>Food poisoning can be caused by bacteria, viruses, parasites, toxins, or chemicals,
                                    and
                                    can
                                    lead to a range of symptoms. Mild stomach discomfort, severe illness and even death.
                                    While
                                    anyone can get food poisoning, it is particularly dangerous for young children,
                                    elderly
                                    people, pregnant women, and people with weakened immune systems.</p>
                                <h3><strong>What is Food Poisoning?</strong></h3>
                                <p>Food poisoning is caused by the ingestion of contaminated food or beverages. It can
                                    be
                                    caused
                                    by various harmful agents, including bacteria, viruses, parasites, or toxins. The
                                    prevalence
                                    of food poisoning is high, and it affects millions of people worldwide each
                                    year.&nbsp;</p>

                                <p>According to the&nbsp;<a href="https://www.who.int/" rel="nofollow">World Health
                                    Organization</a> (WHO), an
                                    estimated 600 million people suffer from foodborne illnesses annually, with 420,000
                                    deaths
                                    reported each year. In the United States alone, it is estimated that 48 million
                                    people
                                    get
                                    sick from foodborne illnesses, leading to 128,000 hospitalizations and 3,000 deaths
                                    annually. The risk of food poisoning can be reduced by practising good food safety
                                    habits,
                                    such as proper storage, handling, and cooking of food.</p>

                                <h4><strong>What is the difference between food poisoning and stomach flu?</strong>
                                    <div class="text-center">
                                        <img alt="" class="my-2 img-fluid"
                                             src="assets/img/diseases/diseasesImages/food-poisoning/Image.webp"
                                        >
                                    </div>
                                </h4>
                                <p>Food poisoning and stomach flu are both conditions that can cause similar food
                                    infection
                                    symptoms, such as nausea, vomiting, diarrhoea, and&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>. However, they are caused by different agents and have distinct
                                    differences.
                                </p>
                                <p>Food poisoning is caused by consuming food or beverages that are contaminated with
                                    harmful
                                    bacteria, viruses, parasites, or toxins. Symptoms usually begin within hours to a
                                    few
                                    days
                                    after consuming the contaminated food or drink.</p>
                                <p>Stomach flu, also known as&nbsp;<a
                                    href="https://www.draxico.com/diseases/gastroenteritis">viral
                                    gastroenteritis</a>, is a viral infection of the gastrointestinal tract. It is not
                                    related
                                    to the consumption of contaminated food or drink. The virus can be spread through
                                    contact
                                    with infected individuals, contaminated surfaces, or contaminated food or drink.
                                    Symptoms
                                    usually begin within one to three days after exposure to the virus.</p>
                                <p>While both conditions can cause similar symptoms, stomach flu may also cause symptoms
                                    such as
                                    muscle aches, fatigue, and&nbsp;<a
                                        href="https://www.draxico.com/diseases/headaches">headache</a>,
                                    which are not typically associated with food poisoning. Additionally, stomach flu
                                    tends
                                    to
                                    be more contagious and can easily spread from person to person, whereas food
                                    poisoning
                                    is
                                    usually limited to individuals who consumed contaminated food or drink.</p>
                                <h4><strong>What is the main cause of Food Poisoning?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/food-poisoning/Image-2.webp"
                                    >
                                </div>
                                <p>The main food poisoning causes is the consumption of contaminated food or drink. This
                                    contamination can occur due to various factors, including:</p>
                                <ul>
                                    <li>Bacteria: Bacteria such as Salmonella, E. coli, and Listeria can contaminate
                                        food
                                        during
                                        production, processing, or handling.
                                    </li>
                                    <li>Viruses: Viruses such as norovirus and&nbsp;<a
                                        href="https://www.draxico.com/diseases/hepatitis-a">hepatitis A</a> can be
                                        transmitted
                                        through contaminated food or water.
                                    </li>
                                    <li>Parasites: Parasites such as Giardia and Cryptosporidium can contaminate food
                                        and
                                        water.
                                    </li>
                                    <li>Toxins: Food poisoning can be brought on by the toxins generated by bacteria
                                        like
                                        Staphylococcus aureus and Bacillus cereus.
                                    </li>
                                    <li>Chemicals: Certain chemicals such as pesticides and heavy metals can contaminate
                                        food
                                        and cause food poisoning.
                                    </li>
                                </ul>
                                <h3><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h3>
                                <p>The early signs and noticeable changes in the body of a person with food poisoning
                                    may
                                    vary
                                    depending on the type of contaminant and the severity of the illness. However, some
                                    common
                                    signs of food poisoning and noticeable changes include:</p>
                                <ul>
                                    <li>Nausea and vomiting</li>
                                    <li>Abdominal pain and cramping</li>
                                    <li>Diarrhoea</li>
                                    <li>Loss of appetite</li>
                                    <li>Headache</li>
                                    <li>Fatigue</li>
                                    <li>Fever</li>
                                    <li>Muscle aches</li>
                                </ul>
                                <p>These symptoms may develop within hours to a few days after consuming contaminated
                                    food
                                    or
                                    drink. In severe cases, food poisoning can lead to&nbsp;<a
                                        href="https://www.draxico.com/diseases/dehydration">dehydration</a>, electrolyte
                                    imbalances, and other complications. If you suspect that you have food poisoning, it
                                    is
                                    important to seek medical attention, especially if you experience severe symptoms or
                                    have
                                    underlying medical conditions that could be affected by
                                    dehydration<strong>.</strong>
                                </p>
                                <h4><strong>Diagnosis and Tests of Food Poisoning</strong></h4>
                                <p>The&nbsp;<a href="https://www.draxico.com/self-assessment">diagnosis of food
                                    poisoning</a>
                                    usually involves a medical evaluation by a healthcare provider and may include
                                    several
                                    tests
                                    to determine the specific cause of the illness.</p>
                                <ul>
                                    <li>Medical evaluation: The healthcare provider will take a medical history and
                                        conduct
                                        a
                                        physical exam to assess the symptoms and signs of the illness.
                                    </li>
                                    <li>Stool tests: Stool tests can help identify the specific bacteria, virus, or
                                        parasite
                                        that caused the food poisoning. These tests may include culturing the stool
                                        sample,
                                        looking for the presence of certain antigens or genetic material, or testing for
                                        toxins.
                                    </li>
                                    <li>Blood tests: Blood tests may be used to detect the presence of certain
                                        antibodies or
                                        other indicators of infection.
                                    </li>
                                    <li>Imaging tests: In some cases, imaging tests such as an abdominal X-ray or CT
                                        scan
                                        may be
                                        used to evaluate the severity of the illness and rule out other conditions.
                                    </li>
                                </ul>

                                <h3><strong>Ayurvedic Say on Food Poisoning and Risk Factors?</strong></h3>
                                <p>In Ayurveda, food poisoning is known as Ajeerna, which means indigestion. According
                                    to
                                    Ayurveda, food poisoning can occur due to several factors, including improper food
                                    combining, eating contaminated or stale food, consuming excessive amounts of spicy
                                    or
                                    oily
                                    food, and poor digestion.</p><h4><strong>Some common risk factors for food poisoning
                                in
                                Ayurveda include:</strong></h4>
                                <ul>
                                    <li>Aggravation of the doshas: When one or more of the&nbsp;<a
                                        href="https://www.draxico.com/blogs/prakriti-and-its-ayurvedic-importance-for-health-dr-axico">three
                                        doshas</a> (Vata, Pitta, and Kapha) are imbalanced, it can lead to poor
                                        digestion
                                        and an
                                        increased risk of food poisoning.
                                    </li>
                                    <li>Poor digestive fire: In Ayurveda, digestive fire or Agni is considered essential
                                        for
                                        proper digestion and assimilation of food. When Agni is weak, it can lead to
                                        indigestion
                                        and an increased risk of food poisoning.
                                    </li>
                                    <li>Improper food habits: Eating too quickly, eating late at night, and eating in a
                                        stressful or chaotic environment can all contribute to poor digestion and an
                                        increased
                                        risk of food poisoning.
                                    </li>
                                    <li>Consuming incompatible foods: According to Ayurveda, certain food combinations
                                        can
                                        be
                                        difficult to digest and can lead to indigestion and food poisoning.
                                    </li>
                                </ul>
                                <h4><strong>How Ayurveda Can Help in Food Poisoning Prevention?</strong></h4>
                                <p>Ayurveda offers several natural remedies to prevent food poisoning and improve
                                    digestion.
                                    Here are some ways Ayurveda can help in food poisoning prevention:</p>
                                <ul>
                                    <li>Use of digestive herbs: Ayurveda recommends using digestive herbs such as
                                        ginger,
                                        cumin,
                                        coriander, fennel, and turmeric to improve digestion and reduce the risk of food
                                        poisoning.
                                    </li>
                                    <li>Mindful eating: Ayurveda emphasises the importance of mindful eating, which
                                        means
                                        being
                                        present and aware of the food you are eating, and avoiding distractions during
                                        meals.
                                        This can help improve digestion and reduce the risk of food poisoning.
                                    </li>
                                    <li>Avoiding incompatible food combinations: According to Ayurveda, certain food
                                        combinations can be difficult to digest and can lead to food poisoning. For
                                        example,
                                        consuming milk with fish or fruit with dairy products is not recommended.
                                        Ayurveda
                                        advises consuming foods in compatible combinations to aid digestion and prevent
                                        food
                                        poisoning.
                                    </li>
                                    <li>Lifestyle changes: Ayurveda recommends making lifestyle changes such as getting
                                        enough
                                        sleep, managing stress, and practising regular exercise to support healthy
                                        digestion
                                        and
                                        reduce the risk of food poisoning.
                                    </li>
                                </ul>
                                <h4><strong>Ayurvedic Remedies for Food Poisoning</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/food-poisoning/Image-3.webp"
                                    >
                                </div>
                                <p>Here are some Ayurvedic remedies that can help with food poisoning ayurvedic
                                    treatment:</p>
                                <ul>
                                    <li><h4><strong>Ginger:</strong></h4> Ginger is a natural digestive aid and has
                                        antimicrobial
                                        properties that can help fight off harmful bacteria in the gut. You can consume
                                        ginger
                                        tea, and fresh ginger juice, or add grated ginger to your meals to help
                                        alleviate
                                        symptoms of food poisoning.
                                    </li>
                                    <li><h4><strong>Coriander:</strong></h4> Coriander has cooling properties and can
                                        help
                                        reduce
                                        inflammation in the gut. You can make coriander tea by boiling coriander seeds
                                        in
                                        water
                                        or adding fresh coriander leaves to your meals to help improve digestion and
                                        reduce
                                        the
                                        risk of food poisoning.
                                    </li>
                                    <li><h4><strong>Cumin:</strong></h4> Cumin seeds have digestive properties and can
                                        help
                                        reduce
                                        inflammation in the gut. You can add cumin seeds to your meals or drink cumin
                                        tea to
                                        help alleviate symptoms of food poisoning.
                                    </li>
                                    <li><h4><strong>Fennel:</strong></h4> Fennel seeds have cooling properties and can
                                        help
                                        improve
                                        digestion. You can chew on fennel seeds or drink fennel tea to help alleviate
                                        symptoms
                                        of food poisoning.
                                    </li>
                                    <li><h4><strong>Turmeric:</strong></h4> Turmeric has anti-inflammatory and
                                        antimicrobial
                                        properties that can help fight off harmful bacteria in the gut. You can add
                                        turmeric
                                        powder to your meals or drink turmeric tea to help reduce inflammation and
                                        promote
                                        healing.
                                    </li>
                                    <li><h4><strong>Triphala:</strong></h4> Triphala is an Ayurvedic herbal formula that
                                        can
                                        help
                                        improve digestion and eliminate toxins from the body. You can take Triphala
                                        supplements
                                        or drink triphala tea to help alleviate symptoms of food poisoning.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not for Food Poisoning Relieve</strong></h4>
                                <p>What to eat:</p>
                                <ul>
                                    <li>Clear broths</li>
                                    <li>Coconut water</li>
                                    <li>Herbal teas</li>
                                    <li>Bananas</li>
                                    <li>Cooked vegetables</li>
                                    <li>Lean proteins such as boiled chicken or fish</li>
                                    <li>Probiotic-rich foods like yogurt</li>
                                </ul>
                                <h4><strong>What not to eat: Some common food poisoning foods</strong></h4>
                                <ul>
                                    <li>&nbsp;&nbsp;Foods that are high in fat, sugar, or fiber</li>
                                    <li>&nbsp;&nbsp;Dairy products</li>
                                    <li>&nbsp;&nbsp;Caffeine</li>
                                    <li>&nbsp;&nbsp;Alcohol</li>
                                    <li>&nbsp;&nbsp;Spicy, acidic, and fried foods</li>
                                </ul>
                                <p>It is important to stay hydrated and nourished while avoiding foods that can irritate
                                    the
                                    digestive system.</p><h4><strong>Recommended Ayurvedic Herbs and Medication for Food
                                Poisoning</strong></h4>
                                <p>Here is a list of food poisoning ayurvedic medicine that can be helpful in food
                                    poisoning
                                    treatment:</p>
                                <ul>
                                    <li>Kutajarishta</li>
                                    <li>Mustakarishtha</li>
                                    <li>Bilwadi Churna</li>
                                    <li>Hingvastak Churna</li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala Churna</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/sanjeevani-vati">Sanjeevani Vati</a>
                                    </li>
                                    <li>Shankha Vati</li>
                                    <li><a href="https://www.draxico.com/products/arogyavardhini-vati">Arogyavardhini
                                        Vati</a>
                                    </li>
                                    <li>Chitrakadi Vati</li>
                                    <li>Kutajghan Vati</li>
                                    <li>Ginger (Zingiber officinale)</li>
                                    <li>Turmeric (Curcuma longa)</li>
                                    <li>Neem (Azadirachta indica)</li>
                                    <li>Coriander (Coriandrum sativum)</li>
                                    <li>Fennel (Foeniculum vulgare)</li>
                                    <li>Cumin (Cuminum cyminum)</li>
                                    <li>Holy Basil (Ocimum sanctum)</li>
                                    <li>Amla (Emblica officinalis)</li>
                                    <li>Licorice (Glycyrrhiza glabra)</li>
                                    <li>Haritaki (Terminalia chebula)</li>
                                </ul>
                                <p><strong>NOTE:</strong> Always consult with an Ayurvedic practitioner before taking
                                    any of
                                    the
                                    mentioned herbs/formulations.</p><h4><strong>A Short Note from Dr. Axico</strong>
                            </h4>
                                <p>Food poisoning still happens frequently even in contemporary, developed countries
                                    with
                                    highly
                                    hygienic food handling procedures. If you visit abroad, your risk may increase. Our
                                    immune
                                    systems are generally capable of fighting off the occasional virus.</p>
                                <p>However, some diseases can have severe adverse effects, particularly in the more
                                    vulnerable
                                    disposed of. Do not wait to see your healthcare practitioner for testing and food
                                    poisoning
                                    treatment if you are worried, immunocompromised, or if you have severe or unusual
                                    symptoms.</p>
                                <p>Along with Ayurveda doctor online consultations, Dr. Axico also provides holistic
                                    health
                                    services. Patients can also buy Ayurvedic medicine from Dr. Axico's clinics or
                                    websites,
                                    both online and offline.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How can you tell if it’s food poisoning or
                                            something else?</strong></h4>
                                        <p>Food poisoning symptoms can be similar to other illnesses such as viral infections or
                                            stomach
                                            flu. However, if symptoms such as vomiting, diarrhea after eating salmon, fever, and
                                            stomach
                                            cramps occur within a few hours of eating a specific food, it's likely to be food
                                            poisoning.
                                            A doctor can diagnose food poisoning by reviewing symptoms and conducting a physical
                                            examination or laboratory tests.</p>
                                        <h4><strong>How long does food poisoning last?</strong></h4>
                                        <p>Food poisoning can last for a few days to a week, depending on the pathogen and the
                                            amount of
                                            contaminated food ingested. Symptoms usually start to improve after the first 24-48
                                            hours,
                                            but in some cases, they can last for several weeks or even months. Staying hydrated
                                            and
                                            resting is important, and medical attention may be necessary if symptoms worsen or
                                            do
                                            not
                                            improve.</p>
                                        <h4><strong>What is the fastest way to resolve food poisoning?</strong></h4>
                                        <p>There is no one "fastest" way to resolve food poisoning, as the duration and severity
                                            of
                                            the
                                            illness can vary depending on the individual case. However, some steps that may help
                                            speed
                                            up the recovery process include staying hydrated by drinking plenty of fluids,
                                            resting
                                            as
                                            much as possible, and avoiding solid foods until symptoms subside.&nbsp;</p>
                                        <p>Over-the-counter medications like anti-diarrheal or anti-nausea drugs may also be
                                            used,
                                            but
                                            it is important to consult with a healthcare professional before taking any
                                            medication.</p>
                                        <h4><strong>Best home food for food poisoning patient</strong>
                                        </h4>
                                        <p>When recovering from food poisoning, it's best to stick to a bland, easy-to-digest
                                            diet
                                            that
                                            won't irritate your digestive system further. Clear broths and soups can help
                                            replenish
                                            fluids and electrolytes lost through vomiting and diarrhoea, while toast and
                                            crackers
                                            provide simple carbohydrates.&nbsp;</p>
                                        <p>Bananas are a good source of potassium, which can help regulate fluid balance, and
                                            boiled
                                            potatoes provide easily digestible carbohydrates. Steamed vegetables, such as
                                            carrots or
                                            green beans, can help replenish important vitamins and minerals. It's important to
                                            avoid
                                            spicy, fatty, or acidic foods that can further irritate the stomach and worsen
                                            symptoms.</p>
                                        <h4><strong>Ayurvedic Home remedies for food poisoning?</strong></h4>
                                        <ul>
                                            <li>Ginger: Ginger is a natural digestive aid and has antimicrobial properties that
                                                can
                                                help
                                                fight off harmful bacteria in the gut. You can consume ginger tea, and fresh
                                                ginger
                                                juice, or add grated ginger to your meals to help alleviate symptoms of food
                                                poisoning.
                                            </li>
                                            <li>Coriander: Coriander has cooling properties and can help reduce inflammation in
                                                the
                                                gut.
                                                You can make coriander tea by boiling coriander seeds in water or adding fresh
                                                coriander
                                                leaves to your meals to help improve digestion and reduce the risk of food
                                                poisoning.
                                            </li>
                                            <li>Cumin: Cumin seeds have digestive properties and can help reduce inflammation in
                                                the
                                                gut. You can add cumin seeds to your meals or drink cumin tea to help alleviate
                                                symptoms
                                                of food poisoning.
                                            </li>
                                            <li>Fennel: Fennel seeds have cooling properties and can help improve digestion. You
                                                can
                                                chew on fennel seeds or drink fennel tea to help alleviate symptoms of food
                                                poisoning.
                                            </li>
                                            <li>Turmeric: Turmeric has anti-inflammatory and antimicrobial properties that can
                                                help
                                                fight off harmful bacteria in the gut. You can add turmeric powder to your meals
                                                or
                                                drink turmeric tea to help reduce inflammation and promote healing.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'gout'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Gout: Causes, early Sign & Treatment in Ayurveda</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>A painful variety of arthritis is gout. Sharp crystals can develop in your joints,
                                    most
                                    commonly in your big toe, when your body has a high uric acid symptom. Gout attacks
                                    are
                                    periodic flare-ups of symptoms like pain and edema. Treatment often consists of
                                    symptom
                                    management along with food modification.&nbsp;<a href="https://www.draxico.com/">Ayurvedic
                                        doctor online consultation</a> would be an appropriate step toward a healthy
                                    lifestyle.
                                </p>
                                <p>Here, we have enlightened about the Gout causes, signs and Gout symptoms, uric acid
                                    treatment
                                    in Ayurveda along with&nbsp;<a href="https://www.draxico.com/products">Ayurvedic
                                        medicines</a> and remedies.&nbsp;&nbsp;</p>
                                <h3><strong>What is Gout?</strong></h3>
                                <p>The build-up of uric acid crystals in the joints causes gout, a kind of&nbsp;<a
                                    href="https://www.draxico.com/diseases/arthritis">inflammatory arthritis</a>.
                                    Purines,
                                    which
                                    may be found in many foods and the body's cells, are broken down into uric acid as a
                                    consequence. Under typical conditions, the kidneys remove extra uric acid, which is
                                    then
                                    eliminated in the urine. However, uric acid can build up in the blood and form
                                    crystals
                                    in
                                    the joints if the body produces too much of it or if the kidneys are unable to
                                    remove it
                                    effectively.&nbsp;</p>
                                <p>These crystals induce swelling, discomfort, and stiffness in the afflicted joint as a
                                    result
                                    of the inflammation they generate. The big toe joint is frequently affected by gout,
                                    but
                                    other joints, including the ankle, knee, wrist, and elbow, may also be affected. It
                                    is
                                    more
                                    prevalent in men and frequently co-occurs with other health issues such as&nbsp;<a
                                        href="https://www.draxico.com/diseases/diabetes">diabetes</a>, high blood
                                    pressure,
                                    and
                                    obesity.</p><h4><strong>Is Gout different from Joint Pains?</strong></h4>
                                <p>Yes, gout is a type of joint pain, but it is different from other types of joint
                                    pain.</p>
                                <p>Gout is a specific type of inflammatory arthritis caused by the build-up of uric acid
                                    crystals in the joints. It typically affects one joint at a time, and the pain can
                                    be
                                    intense and sudden, often occurring at night or early in the morning. The joint may
                                    also
                                    become red, swollen, and tender to the touch.</p>
                                <p>The reasons for joint discomfort, on the other hand, might range from injury to
                                    misuse to
                                    wear and tear. In addition, various medical disorders like osteoarthritis or&nbsp;<a
                                        href="https://www.draxico.com/diseases/rheumatoid-arthritis">rheumatoid
                                        arthritis</a>
                                    can cause joint discomfort as a symptom. One or more joints may be affected by joint
                                    pain,
                                    which may also be accompanied by additional symptoms including stiffness, oedema,
                                    and
                                    decreased range of motion.</p>
                                <h4><strong>What are the main factors that cause Gout?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/gout/Image-1.webp"
                                    >
                                </div>
                                <p>The main factors that cause gout include:</p>
                                <ol>
                                    <li>Diet: Eating foods that are high in purines, such as red meat, organ meats, and
                                        seafood,
                                        can increase uric acid levels in the body and contribute to gout.
                                    </li>
                                    <li>Genetics: Some people may have a genetic predisposition to gout, making them
                                        more
                                        likely
                                        to develop the condition.
                                    </li>
                                    <li>Obesity: Being overweight or obese can increase the risk of gout, as excess
                                        weight
                                        can
                                        lead to higher levels of uric acid in the blood.&nbsp;<a
                                            href="https://www.draxico.com/bmi-calculator">Check your BMI for free</a> to
                                        see
                                        if
                                        you are obese or not?
                                    </li>
                                    <li>Medications: Certain medications, such as diuretics, can increase the risk of
                                        gout
                                        by
                                        interfering with the body's ability to excrete uric acid.
                                    </li>
                                    <li>Medical conditions: Certain medical conditions, such as high blood pressure,
                                        diabetes,
                                        and kidney disease, can increase the risk of gout.
                                    </li>
                                    <li>Alcohol consumption: Drinking alcohol, particularly beer, can increase the risk
                                        of
                                        gout
                                        by increasing uric acid production and impairing the body's ability to excrete
                                        uric
                                        acid.
                                    </li>
                                    <li>Dehydration:&nbsp;<a
                                        href="https://www.draxico.com/diseases/dehydration">Dehydration</a>
                                        can lead to higher concentrations of uric acid in the blood, which can increase
                                        the
                                        risk
                                        of gout.
                                    </li>
                                </ol>
                                <h4><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/gout/Image-2.webp"
                                    >
                                </div>
                                <ol>
                                    <li>Sudden and intense joint pain: The first sign of gout is often sudden and
                                        intense
                                        joint
                                        pain, typically in the joint at the base of the big toe. The pain can be so
                                        severe
                                        that
                                        even the weight of a bedsheet or blanket can cause discomfort.
                                    </li>
                                    <li>Swelling: The affected joint may become swollen, red, and tender to the touch.
                                    </li>
                                    <li>Warmth: The affected joint may feel warm to the touch.</li>
                                    <li>Stiffness: The joint may feel stiff, making it difficult to move.</li>
                                    <li>Reduced range of motion: As the condition progresses, the joint may become more
                                        difficult to move, leading to a reduced range of motion.
                                    </li>
                                    <li>Other joint involvement: Gout can also affect other joints in the body, such as
                                        the
                                        ankle, knee, wrist, and elbow.
                                    </li>
                                    <li>Tophi: Over time, gout can lead to the formation of tophi, which are small, hard
                                        nodules
                                        that can form under the skin around the affected joint.
                                    </li>
                                </ol>
                                <p>If you have any of the above symptoms, take a&nbsp;<a
                                    href="https://www.draxico.com/appointment">free online consultation with a
                                    doctor</a>.
                                </p>
                                <h3><strong>Ayurvedic Say on Gout? &nbsp;&nbsp;</strong></h3>
                                <p>Gout is viewed in Ayurveda as a condition of the blood tissue and Vata Dosha.
                                    According
                                    to
                                    Ayurvedic principles, an imbalance in the Vata Dosha causes the body to accumulate
                                    toxins
                                    (Ama), which can lead to joint discomfort and inflammation, including gout.</p>
                                <p>Gout may be treated holistically using Ayurveda, which emphasizes balancing the
                                    doshas,
                                    enhancing digestion, getting rid of impurities, and lowering inflammation in the
                                    afflicted
                                    joints. This is accomplished by combining food adjustments, lifestyle adjustments,
                                    and
                                    herbal therapies.</p>
                                <p>Some Ayurvedic herbs that are commonly used in the treatment of gout include guggulu,
                                    triphala, ginger, turmeric, and ashwagandha. These herbs are believed to help reduce
                                    inflammation and pain in the joints, while also supporting overall health and
                                    well-being.</p>
                                <p>Ayurvedic dietary recommendations for gout include avoiding foods that are high in
                                    purines,
                                    such as red meat, organ meats, and seafood, as well as consuming foods that are
                                    anti-inflammatory, such as fruits, vegetables, and whole grains. Additionally,
                                    regular
                                    exercise, <a
                                        href="https://www.who.int/publications/i/item/9789240003927?gclid=Cj0KCQjwmtGjBhDhARIsAEqfDEf8kNLAKViDvGWmNOMztnMUmU2rnJxEAldGnxV4Tk38qPo9OW11f5IaAr2nEALw_wcB"
                                        rel="nofollow">stress management techniques</a>, and adequate sleep are also
                                    important
                                    components of
                                    an Ayurvedic approach to gout.</p><h4><strong>What Food Causes Gout</strong></h4>
                                <p>Gout is caused by uric acid crystals in the joints, causing inflammation and severe
                                    discomfort. Bad Diet is one of the uric acid causes. Foods may cause gout or raise
                                    uric
                                    acid
                                    levels. Gout-related foods and drinks include:</p>
                                <p>Purine-rich foods: Foods contain purines. Uric acid comes from purine breakdown.
                                    Purine-rich
                                    diets raise uric acid levels. Organ meats, red meat, seafood (anchovies, sardines,
                                    mussels),
                                    and certain game meats contain purines.</p>
                                <p>Alcohol: Beer, particularly, may cause gout episodes. Alcohol impairs uric acid
                                    removal
                                    and
                                    raises levels. Alcohol dehydrates, causing gout episodes.</p>
                                <p>Sugary drinks: Soda and fruit juices with added sugars increase gout risk. Many
                                    processed
                                    meals and drinks include high-fructose corn syrup, which raises uric acid levels and
                                    causes
                                    gout flares.</p>
                                <p>Fruits and sweets with fructose may increase uric acid production. Sugary fruits,
                                    fruit
                                    juices, and foods containing high-fructose corn syrup contain fructose.</p>
                                <p>Processed meals are heavy in refined carbs, harmful fats, and additives. These foods
                                    may
                                    increase weight and insulin resistance, gout risk factors. Fast meals, packaged
                                    snacks,
                                    sweet desserts, and processed carbohydrates.</p><h4><strong>How Ayurveda Can Help to
                                Control
                                Gout?</strong></h4>
                                <p>Ayurveda can help control gout in several ways. Here are some of the ways Ayurveda
                                    can
                                    help:</p>
                                <ol>
                                    <li>Reducing inflammation: Ayurveda focuses on reducing inflammation in the body,
                                        which
                                        is a
                                        key factor in the development of gout. Ayurvedic herbs like turmeric, ginger,
                                        and
                                        guggulu have anti-inflammatory properties that can help reduce inflammation and
                                        pain
                                        in
                                        the joints.
                                    </li>
                                    <li>Eliminating toxins: Ayurveda emphasizes the importance of eliminating toxins
                                        from
                                        the
                                        body to prevent and manage health conditions. Ayurvedic detoxification therapies
                                        like
                                        <a href="https://www.draxico.com/panchakarma-treatment">Panchakarma</a> can help
                                        eliminate excess uric acid and other toxins from the body, which
                                        can help prevent gout attacks.
                                    </li>
                                    <li>Improving digestion: Ayurveda places great importance on maintaining good
                                        digestive
                                        health. Poor digestion can lead to the accumulation of toxins in the body, which
                                        can
                                        contribute to gout. Ayurvedic herbs like triphala and ginger can help improve
                                        digestion
                                        and reduce the risk of gout attacks.
                                    </li>
                                    <li>Strengthening the immune system: Ayurveda focuses on strengthening the immune
                                        system
                                        to
                                        prevent and manage health conditions. A strong immune system can help prevent
                                        gout
                                        attacks and reduce inflammation in the body.
                                    </li>
                                    <li>Balancing doshas: According to Ayurveda, imbalances in the three doshas - Vata,
                                        Pitta,
                                        and Kapha - can contribute to the development of health conditions like gout.
                                        Ayurvedic
                                        treatments aim to balance the doshas to promote overall health and well-being.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Treatment Options for Gout?</strong></h4>
                                <p>Ayurvedic treatment for uric acid&nbsp;aims to reduce the accumulation of uric acid
                                    in
                                    the
                                    body and improve joint health. Here are some&nbsp;gout Ayurvedic treatment
                                    options:</p>
                                <ol>
                                    <li>Diet modifications: Ayurvedic treatment for gout involves making dietary
                                        changes,
                                        including avoiding foods that are high in purines, such as red meat, shellfish,
                                        and
                                        organ meats. Instead, focus on consuming a diet rich in vegetables, whole
                                        grains,
                                        and
                                        fruits, which can help reduce inflammation in the body.
                                    </li>
                                    <li>Herbal remedies: Several Ayurvedic herbal remedies can be used to treat gout.
                                        Some
                                        of
                                        the most commonly used herbs include ginger, turmeric, guggulu, and ashwagandha.
                                        These
                                        herbs have anti-inflammatory properties that can help reduce pain and
                                        inflammation
                                        in
                                        the joints.
                                    </li>
                                    <li>Lifestyle modifications: Ayurveda emphasizes the importance of leading a healthy
                                        lifestyle to prevent and manage health conditions. Exercise regularly, practice
                                        stress-reducing techniques like yoga and meditation, and avoid alcohol and
                                        smoking.
                                    </li>
                                    <li>Panchakarma: Panchakarma is an Ayurvedic detoxification therapy that involves a
                                        series
                                        of massages and treatments to remove toxins from the body. Panchakarma can be
                                        beneficial
                                        for people with gout as it can help eliminate excess uric acid from the body.
                                    </li>
                                    <li>Ayurvedic oils: Ayurvedic oils like mahamasha taila or pinda taila can be
                                        applied
                                        topically to the affected joint to reduce pain and inflammation.
                                    </li>
                                    <li>Yoga: Yoga is a mind-body practice that can help reduce stress and improve joint
                                        flexibility and strength. Certain yoga poses as the warrior pose, triangle pose,
                                        and
                                        downward-facing dog can be beneficial for people with gout.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Home Remedies for Gout</strong></h4>
                                <p>Traditional Indian medicine, Ayurveda, has several home treatments for gout. These
                                    medicines
                                    should be used with medical advice by a skilled Ayurvedic practitioner. Ayurvedic
                                    home
                                    treatments for gout include:</p>
                                <ul>
                                    <li><strong>Ginger and Turmeric</strong>: Anti-inflammatory ginger and turmeric help
                                        lessen
                                        gout pain and inflammation. Add fresh ginger and turmeric to your cooking or
                                        make a
                                        turmeric-ginger tea.
                                    </li>
                                    <li><strong>Giloy (Tinospora cordifolia</strong>): An immune-modulating and
                                        anti-inflammatory Ayurvedic herb. It reduces inflammation and gout symptoms.
                                        Giloy
                                        is
                                        available as capsules, pills, and decoctions.
                                    </li>
                                    <li><strong>Cherries</strong>: Cherries reduce uric acid and inflammation. Cherry
                                        juice
                                        or
                                        fresh cherries may alleviate gout and uric acid symptoms.
                                    </li>
                                    <li><strong>Castor oil</strong>: Ayurveda uses castor oil for joint discomfort. Warm
                                        castor
                                        oil massages relieve gout pain and inflammation.
                                    </li>
                                    <li><strong>Triphala:</strong> Triphala contains amalaki, bibhitaki, and haritaki.
                                        It
                                        detoxifies and regulates digestion. Ayurvedic practitioners may recommend it for
                                        gout
                                        treatment.
                                    </li>
                                    <li><strong>Avoiding purine-rich foods</strong>: Organ meats, red meat, seafood, and
                                        alcohol
                                        may cause gout attacks. Limit these trigger foods and eat more fruits,
                                        vegetables,
                                        healthy grains, and lean meats.
                                    </li>
                                    <li><strong>Hydration:</strong> Water flushes uric acid and maintains hydration.
                                        Drink
                                        8–10
                                        glasses of water every day.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not?</strong></h4>
                                <p>Diet plays an essential role in managing gout. Here are some dietary guidelines for
                                    managing
                                    gout through Ayurveda:</p><h4><strong>Foods to eat:</strong></h4>
                                <ol>
                                    <li>Vegetables: Eat plenty of fresh vegetables, especially leafy greens, as they are
                                        low
                                        in
                                        purines and high in nutrients.
                                    </li>
                                    <li>Whole grains: Choose whole grains like brown rice, quinoa, and oats, which are
                                        high
                                        in
                                        fiber and low in purines.
                                    </li>
                                    <li>Fruits: Eat fruits like cherries, strawberries, blueberries, and citrus fruits,
                                        which
                                        are low in purines and high in antioxidants.
                                    </li>
                                    <li>Herbal teas: Drink herbal teas like ginger tea, turmeric tea, and dandelion tea,
                                        which
                                        can help reduce inflammation and flush out toxins from the body.
                                    </li>
                                    <li>Plant-based protein: Choose plant-based sources of protein like beans, lentils,
                                        and
                                        tofu, which are low in purines.
                                    </li>
                                </ol>
                                <h4><strong>Foods to avoid:</strong></h4>
                                <ol>
                                    <li>Red meat: Avoid red meat, including beef, pork, and lamb, as they are high in
                                        purines.
                                    </li>
                                    <li>Seafood: Avoid seafood like anchovies, sardines, mussels, and scallops, as they
                                        are
                                        high
                                        in purines.
                                    </li>
                                    <li>Alcohol: Avoid alcohol, especially beer, as it can increase uric acid levels in
                                        the
                                        body.
                                    </li>
                                    <li>Sugary foods: Avoid sugary foods like candy, soda, and pastries, as they can
                                        contribute
                                        to inflammation in the body.
                                    </li>
                                    <li>Fried foods: Avoid fried foods like French fries and fried chicken, as they are
                                        high
                                        in
                                        saturated fats and can contribute to inflammation in the body.
                                    </li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Medication for Gout?</strong></h4>
                                <ol>
                                    <li><a href="https://www.draxico.com/products/mahayograj-guggulu">Mahayograj
                                        Guggulu</a>:
                                        This is a traditional&nbsp;best Ayurvedic medicine for uric acid&nbsp;that
                                        contains
                                        guggulu, triphala, and other herbs. It is known for its anti-inflammatory and
                                        pain-relieving properties and can be effective in managing&nbsp;uric acid
                                        symptoms
                                        and
                                        cure.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/punarnavadi-guggulu">Punarnavadi
                                        Guggulu</a>:
                                        This is another Ayurvedic medicine that contains guggulu, punarnava, and other
                                        herbs. It
                                        has diuretic properties that can help eliminate excess uric acid from the body
                                        and
                                        reduce the risk of gout attacks.
                                    </li>
                                    <li>Yograj Guggulu: This is a traditional Ayurvedic formulation that contains
                                        guggulu,
                                        ginger, black pepper, and other herbs. It is used to manage joint pain,
                                        inflammation,
                                        and stiffness and can be effective in managing gout symptoms.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/chandraprabha-vati">Chandraprabha
                                        Vati</a>:
                                        This is an Ayurvedic medicine that contains guggulu, triphala, and other herbs.
                                        It
                                        can
                                        help improve digestion, reduce inflammation, and eliminate excess uric acid from
                                        the
                                        body, making it an effective remedy for managing gout.
                                    </li>
                                    <li>Kaishore Guggulu: This is an Ayurvedic medicine that contains guggulu, triphala,
                                        and
                                        other herbs. It can help improve blood circulation and reduce inflammation,
                                        making
                                        it an
                                        effective remedy for managing gout symptoms.
                                    </li>
                                </ol>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>A painful variety of arthritis is gout. Sharp crystals made by extra uric acid in
                                    your
                                    body
                                    build up in your joints, causing pain, swelling, and other symptoms. Consult with
                                    your
                                    healthcare practitioner about medication and dietary modifications that can help you
                                    manage
                                    your symptoms and lessen the frequency of future bouts.</p>
                                <p>Book your Ayurvedic Doctor Online Consultation with Dr. Axico and get rid of such
                                    chronic
                                    diseases. Additionally, you can buy Ayurvedic medicine online or offline
                                    too.&nbsp;&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How can I manage a gout attack?</strong></h4>
                                        <p>Managing a gout attack involves a combination of self-care strategies and medical
                                            interventions. Here are some tips to help you manage a gout attack:</p>
                                        <ol>
                                            <li>Rest the Affected Joint: During a gout attack, it's important to rest the
                                                affected
                                                joint
                                                and avoid putting weight on it. Limit movement to reduce pain and inflammation.
                                            </li>
                                            <li>Apply Ice: Applying ice to the affected joint can help reduce pain and
                                                inflammation.
                                                Use
                                                an ice pack or wrap a bag of ice cubes in a towel and apply it to the area for
                                                15-20
                                                minutes at a time, several times a day.
                                            </li>
                                            <li>Stay Hydrated: Drink plenty of water to help flush out uric acid and prevent
                                                crystal
                                                formation. Adequate hydration also supports kidney function, which is important
                                                for
                                                gout
                                                management.
                                            </li>
                                            <li>Elevate the Joint: Elevate the affected joint to help reduce swelling and
                                                promote
                                                blood
                                                circulation. Prop the joint up on a pillow or cushion to achieve a comfortable
                                                position.
                                            </li>
                                        </ol>
                                        <h4><strong>What are the first signs of gout?</strong></h4>
                                        <p>Gout usually starts with severe joint pain near the base of the big toe. Gout may
                                            also
                                            affect
                                            ankles, knees, elbows, wrists, and fingers. Gout symptoms:</p>
                                        <ul>
                                            <li>Sudden Joint Pain: Gout usually causes significant joint pain. Night-time or
                                                morning
                                                discomfort might be terrible. The joint may hurt and swell.
                                            </li>
                                            <li>Redness and Swelling: The afflicted joint may become inflamed. Joint skin may be
                                                glossy
                                                and heated.
                                            </li>
                                            <li>Pain and edoema may impede joint mobility. Joint movement is excruciating.</li>
                                            <li>Touching the joint may cause pain. The smallest touch might cause severe
                                                discomfort.
                                            </li>
                                            <li>Recurrent Attacks: Gout commonly occurs in "attacks" of joint inflammation.
                                                After
                                                the
                                                first episode, symptoms may subside, followed by flare-ups.
                                            </li>
                                        </ul>
                                        <h4><strong>What causes gout in feet</strong></h4>
                                        <p>Symptoms of gout in feet that contribute to the development of gout include:</p>
                                        <ol>
                                            <li>Foods that cause gout: Consuming foods that are high in purines can increase the
                                                level
                                                of uric acid in the body. Foods rich in purines include red meat, organ meats
                                                (liver,
                                                kidney), shellfish, certain fish (such as sardines and anchovies), and some
                                                types of
                                                legumes (like lentils and beans).
                                            </li>
                                            <li>Lifestyle Factors: Certain lifestyle choices can contribute to the development
                                                of
                                                gout.
                                                Excessive alcohol consumption, particularly beer, can raise uric acid levels.
                                                Additionally, being overweight or obese increases the risk of developing gout.
                                            </li>
                                            <li>Genetic Predisposition: Some individuals may have a genetic predisposition to
                                                elevated
                                                uric acid levels, making them more susceptible to developing gout.
                                            </li>
                                            <li>Medical Conditions: Certain medical conditions can increase the risk of gout.
                                                These
                                                include kidney disease, high blood pressure, diabetes, and metabolic syndrome.
                                            </li>
                                            <li>Medications: Certain medications, such as diuretics (used to treat high blood
                                                pressure
                                                and edema) and low-dose aspirin, can interfere with the elimination of uric acid
                                                from
                                                the body, leading to higher levels of uric acid and an increased risk of gout.
                                            </li>
                                        </ol>
                                        <h4><strong>What causes gout in females</strong></h4>
                                        <p>Hormonal factors may alter uric acid levels in women. During menopause, oestrogen
                                            levels
                                            drop, raising uric acid levels and rendering women more prone to gout. HRT and some
                                            contraceptives may also influence uric acid levels.</p>
                                        <ul>
                                            <li>Menopause: Hormonal changes during menopause might impair uric acid elimination,
                                                increasing women's gout risk.
                                            </li>
                                            <li>Gout may be inherited. Women with a family history of gout may be at risk.</li>
                                            <li>Obesity and weight gain may raise uric acid levels and gout risk. Gout risk
                                                increases
                                                with age and pregnancy weight gain in women.
                                            </li>
                                            <li>Medications: Diuretics, a popular female prescription, might raise uric acid
                                                levels
                                                and
                                                gout risk.
                                            </li>
                                            <li>High blood pressure, diabetes, metabolic syndrome, and renal disease may raise
                                                gout
                                                risk
                                                in both men and women.
                                            </li>
                                            <li>Diet: Purine-rich meals and alcohol may raise uric acid levels and increase gout
                                                risk in
                                                women.
                                            </li>
                                        </ul>
                                        <h4><strong>Home remedies for gout</strong></h4>
                                        <ol>
                                            <li>Ginger and Cumin Tea: Prepare tea by boiling one teaspoon of ginger powder and
                                                half
                                                a
                                                teaspoon of cumin seeds in two cups of water. Strain the tea and drink it twice
                                                a
                                                day to
                                                help reduce inflammation and pain associated with gout.
                                            </li>
                                            <li>Triphala Powder: Triphala is an Ayurvedic herbal formulation that consists of
                                                three
                                                fruits: Amalaki (Indian gooseberry), Bibhitaki, and Haritaki. Mix one teaspoon
                                                of
                                                Triphala powder in a glass of warm water and consume it before bedtime. Triphala
                                                helps
                                                in detoxification and supports overall digestion.
                                            </li>
                                            <li>Turmeric Milk: Turmeric has anti-inflammatory properties that may help reduce
                                                pain
                                                and
                                                inflammation in gout. Add one teaspoon of turmeric powder to a glass of warm
                                                milk
                                                and
                                                drink it before bedtime.
                                            </li>
                                            <li>Ajwain (Carom Seeds) Water: Boil one teaspoon of ajwain seeds in two cups of
                                                water.
                                                Strain the mixture and let it cool. Drink this water throughout the day to help
                                                relieve
                                                gout symptoms.
                                            </li>
                                            <li>Castor Oil: Warm some castor oil and massage it gently over the affected joint.
                                                This
                                                can
                                                help reduce pain and inflammation. You can also apply a castor oil pack by
                                                soaking a
                                                cloth in warm castor oil and placing it on the joint for 15-20 minutes.
                                            </li>
                                            <li>Herbal Decoction: Prepare a decoction by boiling equal parts of giloy (Tinospora
                                                cordifolia), neem (Azadirachta indica), and guggulu (Commiphora mukul) in water.
                                                Strain
                                                the decoction and drink it twice a day to help detoxify the body and reduce
                                                inflammation.
                                            </li>
                                            <li>Dietary Modifications: Follow a low-purine diet by avoiding high-purine foods
                                                such
                                                as
                                                organ meats, shellfish, and certain legumes. Focus on consuming fresh fruits,
                                                vegetables, whole grains, and lean proteins.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'hepatitis-a'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Hepatitis</h4>
                            </div>
                            <div class="card-body text-left">
                                <p class="card-text">
                                    Hepatitis is a liver infection caused by a virus that's spread in the poo of an
                                    infected
                                    person. The symptoms of hepatitis develop, on average, around 4 weeks after becoming
                                    infected, although not everyone will
                                    experience them.
                                </p>
                                <h6>Symptoms Can Include:-</h6>
                                <p class="card-text">feeling tired and generally unwell</p>
                                <p class="card-text">joint and muscle pain</p>
                                <p class="card-text">a high temperature (fever)</p>
                                <p class="card-text">loss of appetite</p>
                                <p class="card-text">feeling or being sick</p>
                                <p class="card-text">pain in the upper-right part of your tummy</p>
                                <p class="card-text">yellowing of the skin and eyes (jaundice)</p>
                                <p class="card-text">dark urine and pale stools</p>
                                <p class="card-text">itchy skin</p>
                                <p>The symptoms will usually pass within a couple of months.</p>
                            </div>
                        </div>
<!--                        <div class="card mt-5">-->
<!--                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
                <div *ngIf="diseasesName === 'hepatitis-c'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Hepatitis C: What are Causes, Prevention & Treatment </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>A viral illness called hepatitis C results in liver inflammation. Hepatitis C virus
                                    (HCV),
                                    which spreads by blood-to-blood contact, is to blame for its development. Acute and
                                    chronic
                                    hepatitis can both be caused by the virus, and chronic hepatitis C is a key
                                    contributor
                                    to
                                    cirrhosis and liver cancer. Hepatitis C can be treated with an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a>, book
                                    your
                                    free
                                    consultation now and get a fruitful health treatment.&nbsp;</p>
                                <p>Fever, exhaustion, appetite loss, nausea, vomiting, stomach discomfort, dark urine,
                                    and
                                    jaundice are some of the symptoms of acute hepatitis C. Many sufferers of acute
                                    hepatitis C,
                                    however, show no symptoms and might not even be aware that they are afflicted.&nbsp;Long-term
                                    liver damage and other consequences can result from chronic hepatitis C, even though
                                    it
                                    may
                                    go years without showing any signs.</p>
                                <h3><strong>What is</strong>&nbsp;<strong>Hepatitis C?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/hepatitis-c/Image-1.webp"
                                    >
                                </div>
                                <p>The Hepatitis C virus gradually harms the liver by creating persistent inflammation.
                                    Needles
                                    with infection are a common means of transfer. New antiviral drugs can treat HCV,
                                    but
                                    many
                                    patients are unaware they have the disease. Before liver failure has occurred as a
                                    result of
                                    liver disease symptoms.&nbsp;</p>
                                <h4><strong>What’s the difference between Hepatitis A, B
                                    &amp; C?</strong></h4>
                                <p>Hepatitis A, B, and C are three different types of viral infections that affect the
                                    liver,
                                    but they are caused by different viruses and have different modes of transmission,
                                    symptoms,
                                    treatments, and outcomes.</p>
                                <p><a href="https://www.draxico.com/diseases/hepatitis-a">Hepatitis A</a> is a highly
                                    contagious
                                    viral infection that is transmitted through the fecal-oral route, such as by eating
                                    or
                                    drinking contaminated food or water.&nbsp;Non-alcoholic fatty liver symptoms&nbsp;can
                                    include fever, fatigue, nausea, vomiting, and abdominal pain, but most people
                                    recover
                                    without any long-term complications. Vaccination can prevent hepatitis A infection.
                                </p>
                                <p><a href="https://www.draxico.com/diseases/hepatitis-b">Hepatitis B</a> is a serious
                                    viral
                                    infection that is transmitted through blood-to-blood contact, unprotected sex, or
                                    from
                                    an
                                    infected mother to her newborn during childbirth. Symptoms can include fever,
                                    fatigue,
                                    joint
                                    pain, and jaundice, and some people may develop chronic hepatitis B, which can lead
                                    to
                                    liver
                                    damage and liver cancer. Treatment can include antiviral medications and vaccination
                                    can
                                    prevent hepatitis B infection.</p>
                                <p><a href="https://www.draxico.com/diseases/hepatitis-c">Hepatitis C</a> is a viral
                                    infection
                                    that is transmitted through blood-to-blood contact, such as sharing needles or
                                    receiving
                                    a
                                    blood transfusion before 1992. Symptoms can include fatigue, fever, and abdominal
                                    pain,
                                    and
                                    chronic hepatitis C can lead to liver damage and liver cancer. Antiviral medications
                                    can
                                    cure most cases of hepatitis C, but there is currently no vaccine for hepatitis C.
                                </p>
                                <h4>
                                    <strong>How common is hepatitis C?</strong></h4>
                                <p>The majority of hepatitis C infections result from exposure to blood from unsafe
                                    injection
                                    techniques, unsafe medical procedures, unscreened blood transfusions, injectable
                                    drug
                                    usage,
                                    and sexual behaviours that result in <a
                                        href="https://www.cdc.gov/oralhealth/infectioncontrol/faqs/occupational-exposure.html"
                                        rel="nofollow">blood exposure</a>.</p>
                                <p>Hepatitis C virus cases are expected to be 1.5 million new cases per year, with 58
                                    million
                                    people worldwide harbouring the infection. According to estimates, 3.2 million
                                    children
                                    and
                                    adolescents worldwide have chronic hepatitis C infection.</p>
                                <p>According to the WHO, 2,90,000 persons died from hepatitis C in 2019, primarily due
                                    to&nbsp;<a href="https://www.draxico.com/diseases/cirrhosis">cirrhosis</a> and
                                    hepatocellular carcinoma (primary liver cancer).</p><h4><strong>What is Acute and
                                Chronic
                                Hepatitis C?</strong></h4>
                                <p>within the first 6 months after exposure to the virus. Many people with acute
                                    hepatitis C
                                    do
                                    not have any symptoms, or they may experience mild symptoms such as fever, fatigue,
                                    and&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>. However, some people may develop more severe symptoms such as jaundice
                                    (yellowing of the skin and eyes), dark urine, and pale stools. Acute hepatitis C can
                                    be
                                    diagnosed with blood tests that detect antibodies or the genetic material of the
                                    virus.
                                </p>
                                <p>Chronic hepatitis C occurs when the HCV infection persists for more than 6 months
                                    after
                                    the
                                    initial exposure. Chronic hepatitis C can cause long-term damage to the liver,
                                    including
                                    inflammation, scarring, and cirrhosis. Many people with chronic hepatitis C do not
                                    have
                                    any
                                    symptoms, or they may experience mild symptoms such as fatigue and abdominal
                                    discomfort.
                                    However, over time, chronic hepatitis C can lead to more severe symptoms such as
                                    jaundice,
                                    ascites (fluid build-up in the abdomen), and bleeding disorders. Chronic hepatitis C
                                    can
                                    also increase the risk of developing liver cancer.</p>
                                <h3><strong>What are the main factors that cause Hepatitis C?</strong></h3>
                                <p>Hepatitis C is caused by the hepatitis C virus (HCV), which is a blood-borne virus
                                    that
                                    primarily affects the liver. Many&nbsp;liver diseases are caused by alcohol
                                    including
                                    HCV.
                                    The main factors that can cause hepatitis C include:</p>
                                <ol>
                                    <li>Injection drug use: Sharing needles or other equipment for injecting drugs is
                                        the
                                        most
                                        common way that hepatitis C is spread in many parts of the world.
                                    </li>
                                    <li>Blood transfusions and organ transplants: Before 1992, blood transfusions and
                                        organ
                                        transplants were not routinely screened for hepatitis C, which led to many
                                        people
                                        being
                                        infected with the virus through these procedures.
                                    </li>
                                    <li>Healthcare procedures: People can also become infected with hepatitis C through
                                        medical
                                        procedures, such as hemodialysis, in which blood is filtered through a machine
                                        to
                                        remove
                                        waste products.
                                    </li>
                                    <li>Mother-to-child transmission: In rare cases, hepatitis C can be passed from a
                                        mother
                                        to
                                        her newborn during childbirth.
                                    </li>
                                    <li>Unprotected sex: Although the risk of hepatitis C transmission through sexual
                                        contact is
                                        generally low, people who have multiple sexual partners or engage in rough sex
                                        may
                                        be at
                                        higher risk.
                                    </li>
                                    <li>Occupational exposure: Healthcare workers and others who are exposed to blood or
                                        blood
                                        products may be at increased risk of hepatitis C infection.
                                    </li>
                                </ol>
                                <h4><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/hepatitis-c/Image-2.webp"
                                    >
                                </div>
                                <p>Many people with hepatitis C do not have any symptoms during the early stages of the
                                    infection, which can make it difficult to diagnose. However, some people may
                                    experience
                                    the
                                    following&nbsp;signs liver disease and noticeable changes in the body during the
                                    early
                                    stages of hepatitis C infection:</p>
                                <ol>
                                    <li><strong>Fatigue:&nbsp;</strong>Many people with hepatitis C report feeling tired
                                        or
                                        fatigued, even if they are getting enough sleep.
                                    </li>
                                    <li><strong>Flu-like symptoms:</strong> Some people may experience flu-like
                                        symptoms,
                                        such
                                        as fever, chills, muscle aches, and&nbsp;<a
                                            href="https://www.draxico.com/diseases/headaches">headache</a>.
                                    </li>
                                    <li><strong>Abdominal pain:</strong> Some people with hepatitis C may experience
                                        abdominal
                                        pain or discomfort, especially in the area of the liver.
                                    </li>
                                    <li><strong>Loss of appetite:</strong> Some people may experience a loss of appetite
                                        or
                                        feel
                                        nauseous, which can lead to weight loss.
                                    </li>
                                    <li><strong>Joint pain:</strong> Some people with hepatitis C may experience joint
                                        pain
                                        or
                                        stiffness.
                                    </li>
                                    <li><strong>Jaundice liver Failure:</strong> In some cases, hepatitis C can cause
                                        jaundice,
                                        which is a yellowing of the skin and eyes.
                                    </li>
                                </ol>
                                <h3><strong>Ayurvedic Say on Hepatitis C?</strong></h3>
                                <p>Ayurveda, an ancient Indian medical system, discusses hepatitis C. Ayurveda has a
                                    lengthy
                                    history and is still practised, although its methods may not be as scientifically
                                    validated
                                    as contemporary medicine. To understand and treat hepatitis C, visit a skilled
                                    Ayurvedic
                                    practitioner and a healthcare expert. Hepatitis C-related Ayurvedic practices:</p>
                                <p><strong>Dosha Imbalance:</strong> Ayurveda recommends balancing Vata, Pitta, and
                                    Kapha to
                                    be
                                    healthy. A dosha imbalance may cause hepatitis C. Ayurvedic practitioners use food,
                                    herbal,
                                    and lifestyle adjustments to restore equilibrium.</p>
                                <p><strong>Ayurvedic Treatment Options for Hepatitis C?</strong></p>
                                <p>If you think you have hepatitis C, see a doctor. Hepatitis C patients should visit a
                                    doctor
                                    when:</p>
                                <p><strong>Positive Hepatitis C Test:</strong> See a doctor if your HCV test was
                                    positive.
                                    They
                                    can test, assess&nbsp;<a href="https://www.draxico.com/diseases/liver-disease">liver
                                        disease</a>, and recommend therapy.</p>
                                <p><strong>Symptoms:</strong> Hepatitis C may cause tiredness, jaundice, stomach
                                    discomfort,
                                    dark urine, and pale faeces. If you experience any of these symptoms, see a doctor.
                                </p>
                                <p>Ayurveda offers a variety of treatment options for hepatitis C, which focus on
                                    supporting
                                    liver function and boosting the immune system to help the body fight off the virus.
                                    Some
                                    of
                                    the Ayurvedic treatment options for hepatitis C include:</p>
                                <ol>
                                    <li>Dietary changes: Ayurvedic practitioners recommend following a diet that is rich
                                        in
                                        whole foods, such as fruits, vegetables, whole grains, and legumes, and avoiding
                                        processed foods, fried foods, and alcohol, which can all contribute to liver
                                        damage.
                                    </li>
                                    <li>Lifestyle changes: Ayurveda emphasises the importance of maintaining a healthy
                                        lifestyle, which includes getting enough sleep, managing stress, and engaging in
                                        regular
                                        exercise.
                                    </li>
                                    <li>Herbs and supplements: Ayurvedic herbs and supplements can be used to support
                                        liver
                                        function and boost the immune system. Some of the herbs commonly used in
                                        Ayurvedic
                                        treatment for hepatitis C include kutki, guduchi, bhumyamalaki, amla, and
                                        triphala.
                                    </li>
                                    <li><a href="https://www.draxico.com/panchakarma-treatment/eye-treatments">Panchakarma
                                        therapy</a>: This is a detoxification therapy that involves a series of
                                        cleansing treatments, such as massage, steam therapy, and enemas, which help to
                                        remove
                                        toxins from the body and support liver function.
                                    </li>
                                    <li>Yoga and meditation: Ayurveda recommends the practice of yoga and meditation to
                                        help
                                        manage stress and promote overall well-being.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Remedies for Hepatitis C?</strong></h4>
                                <p>Ayurveda, an ancient Indian medical system, offers various remedies for hepatitis C.
                                    Here
                                    are
                                    some Ayurvedic remedies that can help manage hepatitis C:</p>
                                <ol>
                                    <li>Kutki (Picrorhiza kurroa): Kutki is a bitter herb that is known for its
                                        antiviral
                                        properties. It is an effective remedy for hepatitis C as it helps to protect the
                                        liver
                                        and enhance liver function.
                                    </li>
                                    <li>Amalaki (Emblica officinalis): Amalaki, also known as Indian gooseberry, is a
                                        powerful
                                        antioxidant and immune modulator. It is useful in the treatment of hepatitis C
                                        as it
                                        helps to boost the immune system and protect the liver from damage.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/guduchi-ghana-vati">Guduchi</a>
                                        (Tinospora
                                        cordifolia): Guduchi is an immunomodulatory herb that helps to boost the immune
                                        system
                                        and reduce inflammation. It is beneficial in the treatment of hepatitis C as it
                                        helps to
                                        reduce liver inflammation and enhance liver function.
                                    </li>
                                    <li>Bhumi amla (Phyllanthus niruri): Bhumi amla is a herb that has been
                                        traditionally
                                        used
                                        in Ayurveda for the treatment of liver diseases. It is useful in the treatment
                                        of
                                        hepatitis C as it helps to protect the liver from damage and reduce liver
                                        inflammation.
                                    </li>
                                    <li>Licorice (Glycyrrhiza glabra): Licorice is a sweet root that has antiviral and
                                        anti-inflammatory properties. It is beneficial in the treatment of hepatitis C
                                        as it
                                        helps to reduce liver inflammation and enhance liver function.
                                    </li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala</a>: Triphala
                                        is
                                        a
                                        combination of three fruits - Amalaki, Haritaki, and Bibhitaki. It is a powerful
                                        antioxidant and immune modulator. It is useful in the treatment of hepatitis C
                                        as it
                                        helps to boost the immune system and protect the liver from damage.
                                    </li>
                                    <li>Punarnava (Boerhavia diffusa): Punarnava is a herb that is used in Ayurveda for
                                        the
                                        treatment of liver diseases. It is beneficial in the treatment of hepatitis C as
                                        it
                                        helps to reduce liver inflammation and enhance liver function.
                                    </li>
                                </ol>
                                <h4><strong>What to Eat and Not to Eat?</strong></h4><h4><strong>What to Eat:</strong>
                            </h4>
                                <ol>
                                    <li>Fresh fruits and vegetables: Eating plenty of fresh fruits and vegetables can
                                        help
                                        to
                                        provide essential nutrients and antioxidants that support liver function.
                                    </li>
                                    <li>Whole grains: Whole grains such as brown rice, quinoa, and oats are good sources
                                        of
                                        fiber and essential nutrients that can support liver function.
                                    </li>
                                    <li>Lean protein: Choosing lean protein sources such as chicken, fish, and tofu can
                                        provide
                                        the necessary protein without putting a strain on the liver.
                                    </li>
                                    <li>Healthy fats: Consuming healthy fats such as avocados, nuts, and olive oil can
                                        help
                                        to
                                        reduce inflammation and support liver function.
                                    </li>
                                    <li>Herbal teas: Drinking herbal teas such as dandelion root tea, milk thistle tea,
                                        and
                                        licorice tea can support liver function and reduce inflammation.
                                    </li>
                                </ol>
                                <h4><strong>What to Avoid:</strong></h4>
                                <ol>
                                    <li>Alcohol: Consuming alcohol can damage the liver and worsen hepatitis C symptoms.
                                    </li>
                                    <li>Processed foods: Processed foods such as fast food, sugary snacks, and fried
                                        foods
                                        can
                                        be high in unhealthy fats and sugar, which can contribute to liver damage.
                                    </li>
                                    <li>High-fat meats: Eating high-fat meats such as beef, pork, and lamb can put a
                                        strain
                                        on
                                        the liver and worsen hepatitis C symptoms.
                                    </li>
                                    <li>Caffeine: Consuming too much caffeine can be dehydrating and put a strain on the
                                        liver.
                                    </li>
                                    <li>Raw or undercooked seafood: Raw or undercooked seafood can contain harmful
                                        bacteria
                                        and
                                        viruses that can worsen hepatitis C symptoms.
                                    </li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Hepatitis C</strong></h4>
                                <p>Individual demands and symptoms determine hepatitis C ayurvedic treatments. A trained
                                    Ayurvedic practitioner may diagnose and provide therapies. I can list some common
                                    Ayurvedic
                                    hepatitis C treatments, but a doctor should prescribe them. Ayurvedic treatments for
                                    hepatitis C include:</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/medicinal-kits/liver-savior-kit"><strong>Dr.
                                        Axico
                                        liver savior kit</strong></a><strong>:</strong> It supports liver function,
                                        cleansing,
                                        and regeneration.&nbsp;
                                    </li>
                                    <li><strong>Arogyavardhini Vati:</strong> This Ayurvedic herbal pill promotes liver
                                        function
                                        and manages liver problems. It helps digestion, detoxification, and liver
                                        health.
                                    </li>
                                    <li>Ayurvedic Punarnavadi Mandur aids liver cleansing and toxin removal. Balances
                                        doshas
                                        and
                                        supports liver health.
                                    </li>
                                    <li><strong>Kalmegh capsules:</strong> This plant is antiviral and hepatoprotective.
                                        Andrographis paniculata pills enhance liver function and hepatitis C treatment.
                                    </li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>Hepatitis C infection should be checked on everyone at least once. A straightforward
                                    blood
                                    test could reveal vital details about your health. You are more likely to experience
                                    risky
                                    behaviors, such as intravenous drug usage. Hepatitis C is treatable, which is
                                    wonderful
                                    news, and can be cured with Dr. Axico Ayurvedic doctor consultation. Moreover, you
                                    can
                                    buy
                                    Ayurvedic medicine online or offline from official websites or stores.&nbsp;</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How does hepatitis C infection affect
                                            me?</strong>
                                        </h4>
                                        <p>Hepatitis C causes liver illness. Hepatitis C may harm your health.</p>
                                        <p>Hepatitis C may initially cause no symptoms. Infection may cause chronic liver
                                            disease.
                                            Hepatitis C is a leading cause of liver cirrhosis, which impairs liver function by
                                            replacing
                                            good liver tissue with scar tissue. Fatigue, jaundice, stomach discomfort, and leg
                                            and
                                            abdominal edoema might follow.</p>
                                        <p>Chronic hepatitis symptoms raise the chance of catastrophic consequences including
                                            liver
                                            cancer. These problems are more likely in long-term HCV carriers.</p>
                                        <p>Hepatitis C may also lower your quality of life. Chronic hepatitis C may cause
                                            fatigue
                                            and
                                            malaise, which can impair everyday life.</p>
                                        <h4><strong>Who is most commonly affected by hepatitis C infection?</strong></h4>
                                        <p>Hepatitis C transmission is increased by injection drug users. Injecting medicines,
                                            even
                                            once, increases the risk of infection. Sharing needles, syringes, and other drug
                                            paraphernalia spreads the infection.</p>
                                        <p>Patients who received blood transfusions or organ transplants before HCV screening,
                                            healthcare workers who may be exposed to infected blood, and high-risk sexual
                                            partners
                                            are
                                            also at risk of hepatitis C infection.</p>
                                        <p>Poor infection control may potentially transmit hepatitis C. Contaminated medical
                                            equipment
                                            may spread the infection.</p>
                                        <p>These groups are more likely to get hepatitis C if they come into touch with
                                            contaminated
                                            blood or body fluids. Hepatitis C prevention and testing are crucial.</p>
                                        <h4><strong>What is the prognosis for someone with chronic hepatitis C?</strong>
                                        </h4>
                                        <p>Ayurveda prescribes chest infection treatment based on symptoms, doshic imbalance,
                                            and
                                            general health. An expert Ayurvedic practitioner may examine your health and offer
                                            the
                                            best
                                            treatments. Ayurvedic chest infection treatments include these:</p>
                                        <p>Sitopaladi Churna: This herbal powder contains plants, Vamshalochana (bamboo manna),
                                            and
                                            Pippali (Long Pepper). It treats coughs, congestion, and respiratory infections.
                                            Sitopaladi
                                            Churna reduces throat irritation and promotes expectoration.</p>
                                        <p>Talisadi Churna: This herbal powder contains Talispatra (Abies webbiana), Pippali
                                            (Long
                                            Pepper), Ela (Cardamom), and other plants. It treats cough, bronchitis, and
                                            congestion.
                                            Talisadi Churna loosens mucous, improves breathing, and supports respiratory
                                            health.</p>
                                        <p>Licorice root powder (Yashtimadhu) has expectorant, anti-inflammatory, and
                                            immune-boosting
                                            effects. It reduces lung inflammation, cough, and respiratory system irritation.
                                            Yashtimadhu
                                            Churna may be swallowed or gargled with warm water.</p>
                                        <p>Ayurvedic Vasarishta contains Vasaka (Adhatoda vasica) and other herbs. It treats
                                            asthma
                                            and
                                            bronchitis. Vasarishta relieves cough, congestion, and lung function.</p>
                                        <p>Ayurvedic mineral-based Kasthuri Bhairava Rasa blends herbs and minerals. It treats
                                            respiratory infections, fever, and cough. This drug reduces congestion, and
                                            irritation,
                                            and
                                            promotes respiratory health.</p>
                                        <h4><strong>Ayurveda Can treat hepatitis C?</strong></h4>
                                        <p>Ayurveda may help hepatitis C patients, but it is not a cure. Conventional medicine
                                            treats
                                            hepatitis C with antiviral drugs.</p>
                                        <p>However, Ayurveda may promote liver health, immune system, and hepatitis C symptoms.
                                            Ayurveda
                                            benefits:</p>
                                        <p>Liver Support: Bhumi Amla (Phyllanthus niruri), Kutki (Picrorhiza kurroa), and
                                            Punarnava
                                            (Boerhavia diffusa) are hepatoprotective Ayurvedic medicines. These herbs may aid
                                            liver
                                            regeneration, cleansing, and inflammation.</p>
                                        <p>Ayurveda boosts immunity to combat illnesses. Amalaki, Tulsi, and Guduchi are used to
                                            boost
                                            immunity and prevent viral infections.</p>
                                        <p>cleansing: Ayurveda uses Panchakarma for cleansing and regeneration. Virechana
                                            (therapeutic
                                            purgation) and Basti (enema) may eliminate poisons and improve health.</p>
                                        <p>Ayurveda recommends a balanced, individualized diet for liver health. Avoid hot or
                                            oily
                                            meals, eat readily digested foods, and use hepatoprotective herbs and spices. To
                                            promote
                                            health, stress management, exercise, and sleep are also recommended.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'kidney-stone'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Kidney Stone: Causes, Signs & No Surgery Medication
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Kidney stones are most common in people between the ages of 40 and 60, but anyone,
                                    even
                                    those
                                    in their early 20s, has the potential to develop them. Nephrolithiasis is another
                                    name
                                    for
                                    the disease that occurs when kidney stones are present. However, the patients are
                                    usually
                                    advised to have surgery but kidney stones can be removed without surgery through
                                    Ayurveda.
                                    An&nbsp;<a href="https://www.draxico.com/">Ayurvedic doctor online consultation</a>
                                    would
                                    lead to a healthy life.&nbsp;&nbsp;</p>
                                <p>It is a condition in which pieces of material that are solid form become lodged in
                                    the
                                    kidneys or the urinary tract. (stones). Calcium, uric acid, and struvite are a few
                                    of
                                    the
                                    components that can contribute to the formation of these stones. Here, you will
                                    learn
                                    about
                                    Kidney stone causes, signs and how to prevent kidney stones.&nbsp;</p>
                                <h2>What is kidney stone disease?</h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/kidney-stone/Image.webp"
                                    >
                                </div>
                                <p>A kidney stone is a solid mass made up of mineral and salt deposits that have built
                                    up in
                                    the
                                    ureter and bladder of the kidney. If kidney stones are not removed quickly, they
                                    might
                                    prevent the excretion of toxins, which can result in more serious consequences.</p>
                                <p>Smaller stones pass through the body through urine without blocking the urinary
                                    channels.
                                    However, as the deposits multiply and develop into sizable, solid stones, the
                                    patient
                                    feels
                                    excruciating agony, bleeding, and the need to urinate frequently.</p>
                                <p>Smaller kidney stones can be removed from the body by following a specific diet,
                                    getting
                                    enough exercise, staying hydrated, and using natural medicines. Large stones might
                                    need
                                    risky procedures or prolonged flushing treatments. In 80% of instances, calcium
                                    oxalate
                                    kidney stones are the most prevalent. Cystine stones, struvite stones, and uric acid
                                    stones
                                    are among the uncommon varieties.</p>
                                <h3> Kidney stone pain location</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/kidney-stone/Image-2.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Severe pain that starts and stops suddenly.</li>
                                    <li>Pain may be felt in the belly area or flanks.</li>
                                    <li>Radiating pain from the back to the groin area (groin pain).</li>
                                    <li>sharp pain from the side back to below the ribs.</li>
                                </ul>
                                <h3>What are the Different Types of Kidneys Stone and Stages?
                                </h3>
                                <p>The following are the various types of kidney stones based on their composition:</p>
                                <p>According to<strong> their composition,</strong> the various kinds of kidney stones
                                    are
                                    as
                                    follows:</p>
                                <ul>
                                    <li>The most typical kidney stone type: calcium stones are composed of either
                                        calcium
                                        oxalate or&nbsp;<a
                                            href="https://kidneystones.uchicago.edu/idiopathic-calcium-phosphate-stone-formers/"
                                            rel="nofollow">calcium
                                            phosphate</a>.
                                    </li>
                                    <li>Uric acid stones: These stones are caused by excessively acidic urine and are
                                        frequently
                                        linked to gout or chemotherapy.
                                    </li>
                                    <li>Struvite stones are composed of magnesium, ammonium, and phosphate, and they are
                                        caused
                                        by a urinary tract infection.&nbsp;
                                    </li>
                                    <li>Cystine stones: These uncommon stones are brought on by an inherited condition
                                        that
                                        interferes with the body's ability to process amino acids.
                                    </li>
                                </ul>
                                <h3> Types according to size
                                </h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/kidney-stone/Image-3.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Small kidney stones: less than 5 mm –</li>
                                    <li>Medium kidney stones: 5-10 mm –</li>
                                    <li>Large kidney stones: greater than 10 mm (greater than 10 mm). –</li>
                                </ul>
                                <p>The location of the stone within the urinary tract is a factor in determining the
                                    treatment
                                    approach and severity of symptoms.</p>
                                <h2>What leads to the formation of kidney stones
                                </h2>
                                <p>In our body, any abnormality happens due to an imbalance of doshas, namely vata,
                                    pitta,
                                    and
                                    kapha.</p>
                                <p>It can lead to the accumulation of excess calcium, oxalate, and minerals in the
                                    kidney
                                    and
                                    form stones.</p>
                                <h3>Major kidney stone causes</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/kidney-stone/Image-4.webp"
                                    >
                                </div>
                                <p>Kidney stones are often the consequence of a mix of genetic, environmental, and
                                    lifestyle
                                    variables that increase the risk of mineral build-up in the kidneys. However, the
                                    precise
                                    origin of kidney stones might vary based on individual circumstances, therefore it
                                    is
                                    impossible to generalise about what causes them.</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/diseases/dehydration">Dehydration</a> Drinking
                                        less
                                        water
                                    </li>
                                    <li>Exercise (too much or too little)</li>
                                    <li>Overweight or obesity</li>
                                    <li>Unhealthy diet and daily regimen</li>
                                    <li>Too much fast food, salty, and extra sugary items.</li>
                                    <li>Hyperparathyroidism and gout</li>
                                </ul>
                                <p>· The use of certain drugs and supplements, such as diuretics,
                                    calcium supplements</p>
                                <h3>Kidney stone symptoms stages
                                </h3>
                                <p>There are four stages of Kidney stones:</p>
                                <ol>
                                    <li>When you first start feeling pain, that is the first stage. A mild aching or a
                                        severe,
                                        throbbing pain may be present.
                                    </li>
                                    <li>The second stage is when the pain worsens and you could also feel sick to your
                                        stomach,
                                        throw up, and have a fever.
                                    </li>
                                    <li>The stone reaches your ureter, the tube that links your kidney to your bladder
                                        to
                                        excrete urine, in the third stage.
                                    </li>
                                    <li>The stone exits your body through your urine in the final and fourth steps.</li>
                                </ol>
                                <p>You can also take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/kidney-assessment">Free
                                    self assessment disease test for Kidney Stone</a> at Dr Axico, and on the basis of
                                    data
                                    provided by you, our system will recommend some best ayurvedic medicine for Kidney
                                    Stone.
                                </p>
                                <h2>What are the Early Signs of Kidney Stones and Noticeable Changes in the Body
                                </h2>
                                <p>In some cases, kidney stones don't cause any symptoms and are found by accident
                                    during
                                    routine imaging tests. But when signs of kidney stones do show up, they can be mild
                                    to
                                    severe and include:</p>
                                <ul>
                                    <li>Pain: Pain in the back, side, or lower abdomen is the most common sign of kidney
                                        stones.
                                        The pain may come and go, and you may also feel sick, throw up, or sweat.
                                    </li>
                                    <li>Changes in urination: Kidney stones can change how often and how quickly you
                                        have to
                                        urinate. They can also cause pain or discomfort when you urinate.
                                    </li>
                                    <li>Blood in the urine: Kidney stones can cause blood to show up in the urine, which
                                        can
                                        be
                                        seen with the naked eye or found by looking at it under a microscope.
                                    </li>
                                    <li>Nausea and vomiting. The pain from kidney stones can make some people feel sick
                                        and
                                        make
                                        them throw up.
                                    </li>
                                    <li>Fever and chills: If the kidney stone causes an infection, a person may get a
                                        fever,
                                        chills, and other signs of an infection.
                                    </li>
                                    <li>Pain that makes it hard to sit or stand: Kidney stones can cause pain that is so
                                        bad
                                        that it can be hard to sit or stand comfortably.
                                    </li>
                                    <li>Pain when urinating: If the stone is in the urinary tract, it can make urinating
                                        painful
                                        or uncomfortable.
                                    </li>
                                    <li>Groin or testicular pain: Kidney stones can hurt the groin or testicles in
                                        men.
                                    </li>
                                    <li>Urinary tract infections: Having kidney stones can make you more likely to get a
                                        urinary
                                        tract infection, which can cause other symptoms like a burning feeling when you
                                        urinate
                                        and a stronger need to go.
                                    </li>
                                </ul>
                                <h3>Kidney Stone Diagnosis and Tests
                                </h3>
                                <p>The following diagnostic tests may be used to corroborate the presence of kidney
                                    stones:</p>
                                <ul>
                                    <li>These procedures can provide a detailed image of the urinary tract and any
                                        possible
                                        stones. X-rays, ultrasounds, CT scans, and MRIs are typical imaging procedures.
                                    </li>
                                    <li>The urine test can detect abnormalities in the urine, such as elevated levels of
                                        minerals or blood, which may indicate the presence of kidney stones.
                                    </li>
                                    <li>Blood tests: Blood tests can be used to detect elevated levels of calcium or
                                        uric
                                        acid,
                                        which can contribute to the development of kidney stones.
                                    </li>
                                </ul>
                                <p>Get&nbsp;<a href="https://www.draxico.com/">Free Doctor Consultation</a> for your
                                    symptoms at
                                    Dr Axico</p>
                                <h2>Ayurvedic Say on Kidney Stones and Risk Factors?
                                </h2>
                                <p>According to Ayurveda, the body's Kapha composition may become drier when there is an
                                    imbalance
                                    in the Vata Dosha levels. The result may be kidney stones. Kidney stones are known
                                    as
                                    "Ashmari"
                                    in Ayurveda. This word is derived from the Sanskrit words "Ashma" and "Ari," where
                                    Ashma
                                    denotes
                                    stone and Ari denotes an adversary.</p>
                                <p>Kidney stone prevention and treatment in Ayurveda is comprehensive. It can be removed
                                    with a
                                    proper diet, drinking a lot of water, and kidney stone ayurvedic medicines. The
                                    conditions
                                    for
                                    kidney stones to form are favourable if your urine lacks chemicals that keep
                                    crystals
                                    from
                                    adhering to one another.</p>
                                <h3>Risk Factors:
                                </h3>
                                <ul>
                                    <li>Obesity - High body mass index (BMI) and weight growth are directly related to
                                        an
                                        elevated
                                        risk of kidney stones in people who are obese. You can&nbsp;<a
                                            href="https://www.draxico.com/bmi-calculator">check your BMI online</a> at
                                        Dr
                                        Axico.
                                    </li>
                                    <li>Family history - If kidney stones run in your family, you are more likely to
                                        develop
                                        them
                                        yourself.
                                    </li>
                                    <li>Dehydration - Kidney stones are more likely to form if you don't drink enough
                                        water
                                        and
                                        live
                                        in a warm region.
                                    </li>
                                    <li>Dietary variables - Eating a diet high in protein, sodium (salt), and sugar may
                                        make
                                        you
                                        more likely to develop kidney stones.
                                    </li>
                                    <li>Digestive conditions and surgery - Certain conditions, such as persistent
                                        diarrhoea
                                        and&nbsp;<a
                                            href="https://www.draxico.com/diseases/gastroenteritis">gastric</a> bypass
                                        surgery,
                                        might
                                        alter calcium absorption and raise the risk of stone development. If you are
                                        facing
                                        digestion issues, we recommend&nbsp;<a
                                            href="https://www.draxico.com/medicinal-kits/acidity-uprooter">Dr Axico’s
                                            Acidity
                                            Uprooter</a>.
                                    </li>
                                </ul>
                                <h3>How to avoid kidney stones with the help of Ayurveda?
                                </h3>
                                <ul>
                                    <li>Ayurveda balances doshas, improves digestion, and promotes kidney health to
                                        prevent
                                        kidney
                                        stones. Kidney stone prevention with Ayurveda includes:
                                    </li>
                                    <li>Ayurveda recommends a balanced diet of fruits, vegetables, whole grains, and
                                        lean
                                        proteins.
                                        Avoid spinach, rhubarb, and almonds, which are high in oxalates. Reduce salt and
                                        animal
                                        protein too.
                                    </li>
                                    <li>Ayurvedic herbs like gokshura,&nbsp;<a
                                        href="https://www.draxico.com/products/varunadi-kwath">varuna</a>, and&nbsp;<a
                                        href="https://www.draxico.com/products/punarnavadi-guggulu">punarnava</a> may
                                        prevent
                                        kidney
                                        stones and improve kidney function. These herbs are available in Capsules,
                                        powders,
                                        and
                                        tea
                                        form.&nbsp;
                                    </li>
                                    <li>Lifestyle changes: Ayurveda recommends yoga, meditation, and other stress
                                        reducers
                                        for
                                        overall health. Avoid smoking, get enough sleep, and limit alcohol.&nbsp;
                                    </li>
                                    <li>Panchakarma: Ayurvedic detoxification using massages, herbal steam baths, and
                                        enemas.
                                        Panchakarma may improve kidney function and remove toxins.
                                    </li>
                                </ul>
                                <h2>Which Stages of Kidney Stones are Curable in Ayurveda?</h2>
                                <p>Ayurveda can be helpful in the prevention and management of kidney stones; however,
                                    the
                                    curability of kidney stone stages in Ayurveda depends on several factors, including
                                    the
                                    size
                                    and
                                    location of the stone, the individual's overall health and medical history, and the
                                    severity
                                    of
                                    the symptoms. Ayurveda can help prevent and manage kidney stones.</p>
                                <p>In general,&nbsp;<a href="https://www.draxico.com/about/ayurveda">Ayurveda
                                    treatment</a>
                                    may
                                    be
                                    more effective for treating tiny stones that have not caused major obstruction or
                                    damage
                                    to
                                    the
                                    kidneys or urinary tract. These stones are eligible for treatment. Alterations to
                                    one's
                                    diet, as
                                    well as one's way of life, and the use of herbal supplements, are three examples of
                                    Ayurvedic
                                    treatments that may help reduce the risk of problems and the creation of new stones.
                                </p>
                                <p>However, larger stones or stones that have caused significant urinary tract
                                    obstruction
                                    or
                                    damage
                                    may require medical intervention, such as lithotripsy or surgery.</p>
                                <p>It is important to note that Ayurvedic treatments should be used in conjunction with
                                    conventional
                                    medicine, not in place of it. Before using Ayurvedic remedies for kidney stones or
                                    any
                                    other
                                    health condition, it is also important to consult with a qualified Ayurvedic
                                    practitioner.</p>
                                <h2>How Ayurveda Helps in No Operation Kidney Stone Removal?
                                </h2>
                                <p>The holistic approach provided by Ayurveda, kidney stone removal without surgery or
                                    other
                                    invasive procedures. Here are some methods of kidney stone removal using Ayurveda
                                    without
                                    surgery:</p>
                                <ul>
                                    <li>Herbal treatments: Ayurvedic herbs are traditionally used to promote kidney
                                        stone
                                        elimination and support kidney health. To aid in the dissolution of the stones
                                        and
                                        facilitate their removal, these herbs may be consumed as dietary supplements or
                                        made
                                        into
                                        teas.&nbsp;
                                    </li>
                                    <li>Drinking plenty of water and other fluids is crucial for kidney stone removal
                                        without
                                        surgery because it helps flush out the stones and lowers the chance of
                                        complications.
                                        Drinking warm water infused with herbs like coriander, cumin, and fennel is
                                        advised
                                        by
                                        Ayurveda to support healthy kidney function and make the removal of stones
                                        easier.
                                    </li>
                                    <li>Dietary and lifestyle changes: According to Ayurveda, changing one's diet and
                                        way of
                                        living
                                        can help the kidneys function normally and make kidney stone removal easier.
                                        This
                                        could
                                        entail eating a diet high in fibre and water content and low in salt and animal
                                        protein,
                                        using stress-reduction methods like yoga and meditation, getting enough sleep,
                                        and
                                        exercising frequently.&nbsp;
                                    </li>
                                </ul>
                                <p>While Ayurvedic methods may be useful for kidney stone removal without surgery, it's
                                    crucial
                                    to
                                    speak with a healthcare professional for an accurate diagnosis and course of
                                    treatment.
                                    It's
                                    crucial to seek prompt medical attention if you experience kidney stone symptoms or
                                    have
                                    concerns about the health of your kidneys because kidney stones can sometimes result
                                    in
                                    complications and require medical intervention. Dr Axico approaches disease with
                                    ayurveda
                                    and
                                    can help you bypass the surgery,&nbsp;<a href="https://www.draxico.com/appointment">consult
                                        Doctor online</a>.</p>
                                <h3> Remedies For Kidney Stone
                                </h3>
                                <ul>
                                    <li>Drink plenty of water at least 4 litres a day</li>
                                    <li>Sugarcane juice Sugarcane is associated with a wide variety of positive effects
                                        on
                                        one's
                                        health. According to our Ayurvedic textbook, it possesses properties that make
                                        it a
                                        diuretic. It is said that eating sugarcane regularly can assist in the process
                                        of
                                        eliminating smaller kidney stones.&nbsp;
                                    </li>
                                    <li>Honey and lemon juice to drink. The kidney stones can be effectively treated
                                        with
                                        the
                                        juice
                                        of half a lemon mixed with either room temperature or warm water. Honey or salt
                                        are
                                        two
                                        other options for making the mixture drinkable. Honey acts as a lubricant for
                                        kidney
                                        stones,
                                        allowing them to pass through the urine without any difficulty, while lemon
                                        juice
                                        assists in
                                        the process of breaking up the stones.
                                    </li>
                                    <li>Enjoying the taste of watermelon fruit, the fruit of watermelon is made up of 92
                                        percent
                                        water and also contains nutrients and antioxidants. If you have kidney stones,
                                        eating
                                        watermelon at least once a day may help you better manage them and facilitate
                                        their
                                        more
                                        natural elimination from your body.
                                    </li>
                                </ul>
                                <h3>What to Eat and Not
                                </h3>
                                <ul>
                                    <li>Staying hydrated flushes the kidneys and prevents new stones. Drink 8–10 glasses
                                        of
                                        water
                                        daily.
                                    </li>
                                    <li>Limit animal protein: Too much meat, fish, and poultry might raise urine uric
                                        acid
                                        and
                                        cause
                                        kidney stones. Replace animal protein with plant-based protein.&nbsp;
                                    </li>
                                    <li>Limit salt: Excess sodium might raise urine calcium and cause kidney stones.
                                        Limit
                                        processed, high-sodium foods.
                                    </li>
                                    <li>Contrary to common opinion, calcium may prevent kidney stones. In the digestive
                                        system,
                                        calcium binds with oxalate to limit absorption and excretion. Milk, yoghourt,
                                        cheese,
                                        and
                                        leafy greens are calcium-rich.
                                    </li>
                                    <li>Oxalate-rich meals may cause kidney stones. Avoid spinach, rhubarb, chocolate,
                                        and
                                        nuts
                                    </li>
                                    <li>Eat a balanced diet: Fruits, vegetables, whole grains, and lean protein may help
                                        avoid
                                        kidney stones and keep your kidneys healthy
                                    </li>
                                    <li>Increase citric acid consumption to avoid kidney stones by binding calcium and
                                        reducing
                                        crystallisation. Tomatoes, lemons, oranges, and grapefruits contain citric acid.&nbsp;
                                    </li>
                                    <li>Limit sugar and sweetened drinks: Sugar increases urine calcium and oxalate,
                                        which
                                        may
                                        cause
                                        kidney stones. Limit soda, sports drinks, sweets, and candy.
                                    </li>
                                </ul>
                                <h3>Recommended Ayurvedic Herbs and Medication for Kidney Stone
                                    Examples of some single herbs beneficial in kidney stone management
                                </h3>
                                <ul>
                                    <li>Punarnava</li>
                                    <li>Shigru</li>
                                    <li>Varuna</li>
                                    <li>Kantkari</li>
                                    <li>Kushmanda seeds</li>
                                    <li>Pashanabheda</li>
                                    <li>Gokhru</li>
                                </ul>
                                <h3>Example of some classical medicine for kidney stones
                                </h3>
                                <ul>
                                    <li>Varunadi Vati</li>
                                    <li>Mutrakrichantak Churna</li>
                                    <li>Punarnava Mandur</li>
                                    <li>Chandraprabha Vati</li>
                                    <li>Punarnava asava</li>
                                    <li>Shweta parpati</li>
                                </ul>
                                <h4>A short note from Dr. Axico
                                </h4>
                                <p>Kidney stones can be annoying at best and terribly painful at worst. You should be
                                    evaluated
                                    by
                                    an Ayurvedic doctor online consultation as soon as possible to prevent your
                                    condition
                                    from
                                    growing worse. Do not forget to take your medication as prescribed,&nbsp;drink
                                    plenty of
                                    water,
                                    and&nbsp;follow any dietary advice.&nbsp;</p>
                                <p>Remember that kidney stones are a temporary condition as well. They won't continue to
                                    bother
                                    you.
                                    Apart from having a kidney stone ayurvedic treatment, you can buy ayurvedic medicine
                                    online
                                    or
                                    offline from Acixo Ayurveda for better results.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4>What does kidney stone pain feel like?
                                        </h4>
                                        <p>Depending on the size and position of the stone, the pain from a kidney stone can
                                            vary in
                                            both
                                            location and intensity.</p>
                                        <ul>
                                            <li>The pain may come in waves and is frequently characterised as being severe and
                                                acute.
                                            </li>
                                            <li>Under the ribs, in the side or back, is where the discomfort is typically
                                                felt.
                                            </li>
                                            <li>The groyne or lower abdomen may also be affected by the discomfort.</li>
                                            <li>Other symptoms including fever, vomiting, and blood in the urine can also occur
                                                in
                                                some
                                                persons.
                                            </li>
                                            <li>A medical intervention could be necessary to assist manage the pain and aid in
                                                the
                                                passage
                                                of the stone because passing a kidney stone can be a very unpleasant event.
                                            </li>
                                        </ul>
                                        <h4>Which foods cause kidney stones
                                        </h4>
                                        <p>There are different kinds of kidney stones and the things that can cause them depend
                                            on
                                            the
                                            kind.
                                            But there are some kinds of foods that cause kidney stones.</p>
                                        <p>Oxalate-rich foods: Oxalate is a natural substance that can be found in some foods.
                                            It
                                            can
                                            cause
                                            calcium oxalate kidney stones to form. Spinach, rhubarb, beets, okra, chocolate,
                                            nuts,
                                            and
                                            tea
                                            are all high in oxalate.</p>
                                        <p>Animal protein: Foods like red meat, chicken, and fish that are high in animal
                                            protein
                                            can
                                            raise
                                            the amount of uric acid in the body, which can lead to uric acid kidney stones.</p>
                                        <p>Foods that are high in salt, like processed foods, canned soups and veggies, and fast
                                            food,
                                            can
                                            raise the amount of calcium in the urine, which can make it easier for calcium
                                            kidney
                                            stones
                                            to
                                            form.</p>
                                        <p>Sugar and sugary foods: Eating a lot of sugary foods, like soda and candy, can raise
                                            the
                                            amount
                                            of oxalate in your pee, which can lead to kidney stones.</p>
                                        <h4>What is the fastest way to dissolve kidney stone
                                        </h4>
                                        <p>Increased fluid consumption can clear kidney stones and prevent new ones.
                                        </p>
                                        <h4>Ayurveda may help cure kidney stones:</h4>
                                        <p>Ayurveda recommends food and lifestyle changes to prevent kidney stones. This
                                            involves
                                            drinking
                                            lots of water, avoiding oxalates (spinach, rhubarb, and chocolate), limiting salt
                                            consumption,
                                            and eating diuretic foods like cucumbers, watermelon, and celery.</p>
                                        <p>Ayurvedic kidney stone remedies include Varuna, Gokshura, and Punarnava. These plants
                                            are
                                            anti-inflammatory, diuretic, and detoxifying.</p>
                                        <p>Panchakarma: A five-step detoxification therapy. <a
                                            href="https://www.draxico.com/panchakarma-treatment/vamana"> Virechana</a>
                                            (therapeutic
                                            purgation) is the best
                                            <a href="https://www.draxico.com/panchakarma-treatment"> Panchakarma treatment for
                                                kidney
                                                stones </a> since it cleanses the liver and digestive tract of Pitta
                                            (heat).</p>
                                        <h4>Surgical intervention:
                                        </h4>
                                        <ul>
                                            <li>Percutaneous nephrolithotomy (PCNL): This minimally invasive treatment removes
                                                bigger
                                                kidney
                                                stones using a tiny scope and equipment.
                                            </li>
                                            <li>Ureteroscopy: This minimally invasive treatment uses a tiny scope to find and
                                                remove
                                                kidney
                                                stones in the ureter or kidney.
                                            </li>
                                        </ul>
                                        <h4>What removes kidney stones naturally?
                                        </h4>
                                        <p>Kidney stones are removed without surgery naturally by maintaining a healthy diet
                                            plan.
                                            Add
                                            sugarcane, watermelon, Amla juice or powder, and other fluid-based fruits and
                                            vegetables.
                                            Drink
                                            a lot of water to purify the toxins.
                                        </p>
                                        <h4> Can Ayurveda treat kidney stones without an operation?
                                        </h4>
                                        <p>The holistic approach provided by Ayurveda to the treatment of kidney stones may make
                                            it
                                            possible
                                            to remove small stones without the use of surgery or other invasive procedures. Here
                                            are
                                            some
                                            methods of kidney stone removal without operation by Ayurveda:</p>
                                        <ul>
                                            <li>Herbal treatments: Ayurvedic herbs are traditionally used to promote kidney
                                                stone
                                                elimination and support kidney health. To aid in the dissolution of the stones
                                                and
                                                facilitate their removal, these herbs may be consumed as dietary supplements or
                                                made
                                                into
                                                teas.&nbsp;
                                            </li>
                                            <li>Drinking plenty of water and other fluids is crucial for kidney stone removal
                                                without
                                                surgery because it helps flush out the stones and lowers the chance of
                                                complications.
                                                Drinking warm water infused with herbs like coriander, cumin, and fennel is
                                                advised
                                                by
                                                Ayurveda to support healthy kidney function and make the removal of stones
                                                easier.
                                            </li>
                                            <li>Dietary and lifestyle changes: According to Ayurveda, changing one's diet and
                                                way of
                                                living
                                                can help the kidneys function normally and make kidney stone removal easier.
                                                This
                                                could
                                                entail eating a diet high in fibre and water content and low in salt and animal
                                                protein,
                                                using stress-reduction methods like yoga and meditation, getting enough sleep,
                                                and
                                                exercising frequently.
                                            </li>
                                        </ul>
                                        <p>While Ayurvedic methods may be useful for kidney stone removal without surgery, it's
                                            crucial
                                            to
                                            speak with a healthcare professional for an accurate diagnosis and course of
                                            treatment.
                                            It's
                                            crucial to seek prompt medical attention if you experience kidney stone symptoms or
                                            have
                                            concerns about the health of your kidneys because kidney stones can sometimes result
                                            in
                                            complications and require medical intervention.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'malaria'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Malaria: Causes, Signs & Ayurvedic Medicines</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Malaria is a potentially life-threatening mosquito-borne infectious disease caused by
                                    the&nbsp;<a
                                        href="https://en.wikipedia.org/wiki/Plasmodium">Plasmodium parasite</a>. It is
                                    prevalent
                                    in
                                    many tropical and subtropical regions, including sub-Saharan Africa, Southeast Asia,
                                    and
                                    South America.&nbsp;</p>
                                <p>The disease is transmitted through the bites of infected female Anopheles mosquitoes,
                                    which
                                    inject the parasites into the bloodstream of the host.&nbsp;<a
                                        href="https://www.draxico.com/">Dr. Axico Ayurvedic doctor online
                                        consultation</a>
                                    would
                                    be a good option to have a healthy life.&nbsp;</p>
                                <h3><strong>What are the causes and symptoms of Malaria?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/malaria/Image.webp"
                                    >
                                </div>
                                <p>Plasmodium parasites, which are potentially fatal illnesses, infect individuals by
                                    biting
                                    them with their female Anopheles mosquito hosts. Here are some common causes and
                                    symptoms of
                                    malaria:</p><h4><strong>Causes:</strong></h4>
                                <ul>
                                    <li>A parasite of the genus Plasmodium, which causes malaria, infected female
                                        Anopheles
                                        mosquitoes and spread to people through their bites.
                                    </li>
                                    <li>The disease can also be spread through blood transfusions, organ transplants,
                                        and
                                        shared
                                        needles or syringes contaminated with infected blood.
                                    </li>
                                </ul>
                                <h4><strong>Major symptoms of malaria:</strong></h4>
                                <ul>
                                    <li>Symptoms of malaria typically appear 10-15 days after the mosquito bite and
                                        include
                                        fever, chills,&nbsp;<a
                                            href="https://www.draxico.com/diseases/headaches">headache</a>,
                                        muscle aches, and fatigue.
                                    </li>
                                    <li>Nausea, vomiting, diarrhoea, and cough are some other signs and symptoms of
                                        malaria.
                                    </li>
                                    <li>In severe cases, malaria can cause seizures, organ failure, and even death.</li>
                                    <li>Malaria causes anaemia, a condition in which the body doesn't have enough red
                                        blood
                                        cells, which can cause fatigue and weakness.
                                    </li>
                                    <li>In some cases, malaria can lead to complications in pregnant women, including
                                        low
                                        birth
                                        weight, premature birth, and stillbirth.
                                    </li>
                                </ul>
                                <p>If you have any of the symptoms of malaria, especially if you have recently travelled
                                    to
                                    an
                                    area where malaria is common, it is important to seek medical attention immediately.
                                    Early
                                    diagnosis, treatment and prevention of malaria can help prevent serious
                                    complications
                                    and
                                    improve the chances of a full recovery.</p>
                                <p>Malaria and&nbsp;<a href="https://www.draxico.com/diseases/dengue">Dengue</a> are
                                    both
                                    vector-borne diseases caused by different types of viruses and parasites and
                                    transmitted
                                    by
                                    different species of mosquitoes. Here are some of the key differences between the
                                    two:
                                </p>
                                <h3><strong>What’s the difference between Malaria and Dengue?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/malaria/Image-2.webp"
                                    >
                                </div>
                                <p><strong> Causes:</strong> Malaria is caused by a parasite called Plasmodium, while
                                    Dengue
                                    is caused by a virus called Dengue virus.</p>
                                <p><strong>&nbsp;Mosquito Vectors:</strong> The mosquito species that transmit Malaria
                                    are
                                    primarily of the genus Anopheles, while the mosquitoes that transmit Dengue are
                                    primarily of
                                    the genus Aedes.</p>
                                <p><strong>&nbsp;Symptoms:</strong> Symptoms of Malaria include fever, chills, headache,
                                    muscle aches, fatigue,<a
                                        href="https://www.webmd.com/digestive-disorders/digestive-diseases-nausea-vomiting"
                                        rel="nofollow"> nausea</a>, and vomiting. Symptoms of Dengue include high fever,
                                    severe
                                    headache, joint and muscle pain, rash, and in severe cases, bleeding.</p>
                                <p><strong>&nbsp;Treatment:</strong> Malaria is treated with antimalarial drugs such as
                                    chloroquine, <a
                                        href="https://www.malariaconsortium.org/pages/112.htm#:~:text=Fast%20acting%20artemisinin%2Dbased%20compounds,include%20dihydroartemisinin%2C%20artesunate%20and%20artemether."
                                        rel="nofollow"> artemisinin-based combination therapy</a> (ACT), or other
                                    medications,
                                    while Dengue
                                    is primarily managed with supportive care, such as pain relief and hydration.</p>
                                <p><strong>&nbsp;Malaria Prevention:</strong> Preventive measures for Malaria include
                                    using
                                    insect repellent, sleeping under mosquito nets, and taking antimalarial medication
                                    if
                                    travelling to a high-risk area. Preventive measures for Dengue include reducing
                                    mosquito
                                    breeding sites, using insect repellent, and wearing protective clothing.</p>
                                <h3><strong>What are the main factors that cause Malaria?</strong></h3>
                                <ul>
                                    <li><strong>&nbsp;Mosquito breeding and behaviour:</strong> Malaria is spread by
                                        mosquitoes that breed in stagnant water, such as swamps, lakes, and puddles.
                                        Mosquitoes
                                        are most active during the evening and early morning hours, and are more likely
                                        to
                                        bite
                                        humans during this time.
                                    </li>
                                    <li><strong>&nbsp;Climate and environment:</strong> Malaria is most prevalent in
                                        tropical
                                        and subtropical regions, where warm temperatures and high humidity provide ideal
                                        conditions for mosquito breeding. Deforestation, irrigation projects, and other
                                        land-use
                                        changes can also increase mosquito breeding sites and the risk of malaria
                                        transmission.
                                    </li>
                                    <li><strong>&nbsp;Human behaviour and travel:</strong> Malaria is more common in
                                        areas
                                        with poor sanitation and limited access to healthcare. Travellers to
                                        malaria-endemic
                                        areas who do not take preventive measures such as using insect repellent and
                                        taking
                                        antimalarial medication are also at increased risk.
                                    </li>
                                    <li><strong>&nbsp;Immunity:</strong> People who live in malaria-endemic regions for
                                        extended periods may develop some level of immunity to the disease, but this
                                        immunity
                                        can wane if they move to a non-endemic area or if they are not exposed to the
                                        parasite
                                        for a long period.
                                    </li>
                                    <li><strong>&nbsp;Drug resistance:</strong> The&nbsp;<a
                                        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7792015/" rel="nofollow">Plasmodium
                                        parasite</a> has
                                        developed resistance to many antimalarial drugs, which can make treatment more
                                        difficult
                                        and contribute to the spread of the disease.
                                    </li>
                                </ul>
                                <p>Preventive measures such as using insecticide-treated bed nets, spraying
                                    insecticides,
                                    and
                                    taking antimalarial medication can help reduce the risk of malaria transmission. It
                                    is
                                    important to seek medical attention if you develop symptoms of malaria, especially
                                    if
                                    you
                                    have recently travelled to a malaria-endemic area.</p>
                                <h3><strong>What are the Early Signs and Noticeable Changes in the Body?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/malaria/Image-3.webp"
                                    >
                                </div>
                                <p>Some common early signs and changes that may be observed:</p>
                                <ul>
                                    <li>Fever: A fever is a common symptom of many illnesses, and it may be one of the
                                        earliest
                                        signs that something is wrong.
                                    </li>
                                    <li>Fatigue: Feeling exhausted, even after getting enough rest, can be an early sign
                                        of
                                        an
                                        illness.
                                    </li>
                                    <li>Headache: Headaches can be caused by many factors, including stress,
                                        dehydration,
                                        and
                                        viral infections.
                                    </li>
                                    <li>Body aches: Muscles and joints may feel sore or achy, which can be a sign of an
                                        infection or inflammation.
                                    </li>
                                    <li>Nausea and vomiting: These symptoms can be caused by a variety of conditions,
                                        including
                                        infections, food poisoning, and motion sickness.
                                    </li>
                                    <li>Respiratory symptoms:&nbsp;<a
                                        href="https://www.draxico.com/diseases/cough">Coughing</a>,
                                        sneezing, and shortness of breath can be early signs of respiratory infections
                                        or
                                        allergies.
                                    </li>
                                    <li>Skin changes: Rashes, hives, or other skin changes can be a sign of an allergic
                                        reaction
                                        or skin condition.
                                    </li>
                                    <li>Changes in bowel habits: Diarrhoea or constipation can be early signs of&nbsp;<a
                                        href="https://www.draxico.com/diseases/gastroenteritis">gastrointestinal
                                        disorders
                                        or
                                        infections</a>.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Malaria?</strong></h4>
                                <p>If you have travelled to or live in an area where malaria is common and you
                                    experience
                                    symptoms such as fever, chills, headache, muscle aches, fatigue, nausea, and
                                    vomiting,
                                    you
                                    should see a doctor as soon as possible. Malaria can be a life-threatening disease
                                    if
                                    left
                                    untreated, and early diagnosis and treatment can greatly improve the chances of a
                                    full
                                    recovery.&nbsp;</p>
                                <p>In some cases, symptoms of malaria may not appear for several weeks or even months
                                    after
                                    exposure to the parasite, so it is important to inform your doctor if you have
                                    recently
                                    travelled to a high-risk area, even if you do not currently have symptoms.</p>
                                <h3><strong>Ayurveda says on Malaria</strong></h3>
                                <p>Malaria is categorised in&nbsp;<a
                                    href="https://www.draxico.com/about/ayurveda">Ayurveda</a>
                                    under the broad term Vishama Jwara. Vishamajvara (intermittent fever) is the term
                                    used
                                    to
                                    describe any condition that exhibits irregular onset (Vishama arambha) and remission
                                    (Visarga). Malaria's clinical symptoms include fever with irregular start and
                                    remission,
                                    excessive thirst, heaviness throughout the body, generalised body pains, headache,
                                    rigours,
                                    nausea, and vomiting, among others.</p><h4><strong>How Ayurveda Can Help to Control
                                Malaria?</strong></h4>
                                <p>Ayurveda can help to control malaria in several ways. Here are some of the ways
                                    Ayurveda
                                    can
                                    be used to prevent and manage malaria:</p>
                                <ul>
                                    <li><strong>&nbsp;Strengthening the immune system:</strong> Ayurvedic herbs and
                                        remedies
                                        such as neem,&nbsp;<a
                                            href="https://www.draxico.com/products/giloy-plus-capsule">giloy</a>, and
                                        tulsi
                                        are
                                        believed to have immune-boosting properties and can help the body fight off
                                        infections
                                        such as malaria.
                                    </li>
                                    <li><strong>&nbsp;Detoxification:</strong> Ayurveda recommends detoxification
                                        therapies
                                        such as <a href="https://www.draxico.com/panchakarma-treatment/">
                                            Panchakarma</a> to
                                        eliminate toxins from the body and improve overall health, which
                                        can help prevent and manage malaria. Or you can try&nbsp;<a
                                            href="https://www.draxico.com/medicinal-kits/detox-management">Dr Axico’s
                                            Detox
                                            Management Kit</a>.
                                    </li>
                                    <li><strong>&nbsp;Lifestyle modifications:</strong> Ayurveda recommends making
                                        lifestyle
                                        modifications such as maintaining good hygiene, avoiding mosquito bites, and
                                        eating
                                        a
                                        healthy diet as the best way to prevent malaria.
                                    </li>
                                    <li><strong>&nbsp;Herbal remedies:</strong> Ayurvedic herbs such as papaya leaf,
                                        ginger,
                                        and turmeric are believed to have antimalarial properties and can be used to
                                        manage
                                        malaria symptoms.
                                    </li>
                                    <li><strong>&nbsp;Prevention:</strong> Ayurveda emphasises the importance of
                                        prevention
                                        and control of malaria. Using mosquito nets, and insect repellents, and wearing
                                        protective clothing can help prevent malaria.
                                    </li>
                                </ul>
                                <h4><strong>Ayurvedic Remedies for Malaria</strong></h4>
                                <p>Here are some Ayurvedic home remedies that can help manage the symptoms of
                                    malaria:</p>
                                <ul>
                                    <li><strong>&nbsp;Tulsi (Holy Basil) Leaves:</strong>&nbsp; Boil a handful of fresh
                                        Tulsi
                                        leaves in water, strain, and drink the liquid several times a day.
                                    </li>
                                    <li><strong>&nbsp;Ginger Tea:</strong>&nbsp; Boil fresh ginger in water, strain, and
                                        add
                                        honey to taste. Drink this tea several times a day.
                                    </li>
                                    <li><strong>&nbsp;Turmeric Milk:</strong>&nbsp; Add a teaspoon of turmeric powder to
                                        a
                                        glass of warm milk and drink it before going to bed, and&nbsp;<a
                                            href="https://www.draxico.com/products/haldi-tulsi-plus">Haldi Tulsi
                                            Plus</a> is
                                        also a good&nbsp; alternative.
                                    </li>
                                    <li><strong>&nbsp;Papaya Leaves:</strong>&nbsp; Crush a few fresh papayas leaves to
                                        extract the juice and drink it twice a day.
                                    </li>
                                    <li><strong>&nbsp;Giloy:</strong> Boil a few giloy leaves in water, strain, and
                                        drink
                                        the
                                        liquid twice a day. You can also try&nbsp;<a
                                            href="https://www.draxico.com/products/giloy-plus-capsule">Giloy Tablets</a>
                                        if
                                        you
                                        have less time to make Giloy water.
                                    </li>
                                </ul>
                                <h4><strong>What to eat and not?</strong></h4>
                                <p>According to Ayurveda, the following dietary recommendations can help prevent and
                                    treat
                                    malaria:</p>
                                <ul>
                                    <li>Eat warm, cooked, and easily digestible foods. Avoid raw and cold foods as they
                                        can
                                        weaken the digestive fire (agni) and make it difficult for the body to digest
                                        and
                                        absorb
                                        nutrients.
                                    </li>
                                    <li>Include bitter and astringent foods in your diet, such as bitter gourd, neem
                                        leaves,
                                        turmeric, and fenugreek, as they are known to have antimalarial properties.
                                    </li>
                                    <li>Avoid sweet, sour, and salty foods as they can aggravate the Pitta dosha, which
                                        is
                                        associated with inflammation and fever.
                                    </li>
                                    <li>Drink plenty of fluids, especially warm water, to help flush out toxins and keep
                                        the
                                        body hydrated.
                                    </li>
                                    <li>Avoid alcohol, caffeine, and sugary drinks, as they can weaken the immune system
                                        and
                                        make it harder for the body to fight off infections.
                                    </li>
                                    <li>Include immune-boosting herbs and spices in your diet, such as ginger, garlic,
                                        tulsi
                                        (holy basil), and turmeric.
                                    </li>
                                </ul>
                                <p>It is important to note that dietary recommendations may vary depending on an
                                    individual's
                                    dosha balance and specific health needs. It is best to consult with an Ayurvedic
                                    practitioner to get personalized dietary recommendations.</p><h4><strong>Ayurvedic
                                Medicine
                                for Malaria Fever and Herbs:</strong></h4>
                                <ul>
                                    <li>Tab.Ayush 64</li>
                                    <li>Neem</li>
                                    <li>Tulsi</li>
                                    <li>Giloy</li>
                                    <li>Ashwagandha</li>
                                    <li>Turmeric</li>
                                    <li>Ginger</li>
                                    <li>Papaya Leaves</li>
                                    <li>Cinchona Bark</li>
                                    <li>Swertia Chirata</li>
                                    <li>Sudarshan Churna</li>
                                    <li>Chirayta Ghrita</li>
                                    <li>Swarna Vasant Malini Vati</li>
                                    <li>Mahasudarshan Churna</li>
                                    <li>Vasa Avaleha</li>
                                    <li>Amritarishta</li>
                                    <li>Maha Jwarankush Ras</li>
                                </ul>

                                <p><strong>Note -</strong> It is essential to seek the guidance of a qualified Ayurvedic
                                    practitioner or healthcare professional before using any of these formulations.</p>
                                <h4>
                                    <strong>&nbsp;</strong>&nbsp;<strong>A Short Note from Dr. Axico</strong></h4>
                                <p>Although malaria is a deadly infection, there are things you can do to prevent it. By
                                    using
                                    preventative drugs and taking precautions to avoid mosquito bites, you can reduce
                                    your
                                    risk
                                    of contracting an infection. Consult your doctor several weeks before your trip if
                                    you're
                                    going somewhere where malaria is a widespread problem. If you're pregnant, this is
                                    very
                                    crucial.</p>
                                <p>Your Ayurvedic medical expert will assess your symptoms and recommend the best course
                                    of
                                    malaria ayurvedic treatment for you. With Dr. Axico's online consultation, the
                                    majority
                                    of
                                    patients may successfully manage their diseases and carry on with their favorite
                                    activities.
                                    They can also buy Ayurvedic medicine online or offline.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>&nbsp;When do symptoms begin if you’re
                                            infected
                                            with malaria?</strong></h4>
                                        <p>The symptoms of malaria typically appear between 7 to 30 days after a person is
                                            infected
                                            with
                                            the malaria parasite. However, in some cases, symptoms may not appear for several
                                            months
                                            or
                                            even years after the initial infection. The symptoms usually start with flu-like
                                            symptoms,
                                            such as fever, chills, headache, muscle aches, and fatigue. As the disease
                                            progresses,
                                            symptoms may include vomiting, diarrhea, abdominal pain, and anemia.</p><h4><strong>&nbsp;What
                                        are the side effects of medications to treat malaria?</strong></h4>
                                        <p>The medications used to treat malaria cause a range of side effects, including
                                            nausea,
                                            vomiting, diarrhea, headache, dizziness, insomnia, muscle pain, skin rash, and loss
                                            of
                                            appetite. More severe side effects such as anemia, liver and kidney damage, and
                                            neurological
                                            problems are possible but less common. It is important to discuss the potential side
                                            effects
                                            of malaria medications with a healthcare professional and report any adverse
                                            reactions
                                            promptly.</p><h4><strong>&nbsp;How is sickle cell trait related to malaria?</strong>
                                    </h4>
                                        <p>Sickle cell trait (SCT) is related to malaria because it offers some protection
                                            against
                                            malaria. SCT is a genetic condition in which a person inherits one copy of the
                                            sickle
                                            cell
                                            gene from one parent and one normal gene from the other parent. Individuals with SCT
                                            do
                                            not
                                            have sickle cell disease, but their red blood cells can change shape under certain
                                            conditions.&nbsp;</p>
                                        <p>This change in shape makes it more difficult for the malaria parasite to invade the
                                            red
                                            blood
                                            cells, reducing the severity of malaria symptoms. As a result, SCT is more common in
                                            populations where malaria is prevalent, and it is considered an evolutionary
                                            adaptation
                                            to
                                            protect against the disease. However, having SCT does not provide complete
                                            protection
                                            against malaria, and individuals with SCT can still contract and transmit the
                                            disease.</p>
                                        <h4><strong>&nbsp;Is there an Ayurvedic vaccine against malaria?</strong></h4>
                                        <p>There is currently no Ayurvedic vaccine available for malaria. However, ayurvedic
                                            remedies
                                            can be used as a complementary therapy in the management of malaria along with
                                            conventional
                                            antimalarial medications. These remedies may help alleviate some of the symptoms
                                            associated
                                            with malaria and boost the body's immunity to fight off the infection.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'stomach-ache-and-abdominal-pain'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Stomach Ache And Abdominal Pain</h4>
                            </div>
                            <div class="card-body text-left">
                                <p class="card-text">A stomach ache is a term often used to refer to cramps or a dull
                                    ache
                                    in
                                    the tummy (abdomen). It's usually short-lived and is often not serious.</p>
                                <p class="card-text">
                                    Severe abdominal pain is a greater cause for concern. If it starts suddenly and
                                    unexpectedly, it should be regarded as a medical emergency, especially if the pain
                                    is
                                    concentrated in a particular area.
                                </p>
                            </div>
                        </div>
<!--                        <div class="card mt-5">-->
<!--                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
                <div *ngIf="diseasesName === 'allergies'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Allergies</h4>
                            </div>
                            <div class="card-body text-left">
                                <p class="card-text">
                                    An allergy is a reaction the body has to a particular food or substance. Having an
                                    allergy
                                    can be a nuisance and affect your everyday activities, but most allergic reactions
                                    are
                                    mild
                                    and can be largely kept under
                                    control.
                                </p>
                                <p class="card-text">Allergic reactions usually happen quickly within a few minutes of
                                    exposure
                                    to an allergen.</p>
                                <h6>They Can Cause:-</h6>
                                <p class="card-text">sneezing</p>
                                <p class="card-text">a runny or blocked nose</p>
                                <p class="card-text">red, itchy, watery eyes</p>
                                <p class="card-text">wheezing and coughing</p>
                                <p class="card-text">a red, itchy rash</p>
                                <p class="card-text">worsening of asthma or eczema symptoms</p>
                                <p>Most allergic reactions are mild, but occasionally a severe reaction called
                                    anaphylaxis
                                    or
                                    anaphylactic shock can occur. This is a medical emergency and needs urgent
                                    treatment.</p>
                                <p class="card-text">Allergies occur when the body's immune system reacts to a
                                    particular
                                    substance as though it's harmful.</p>
                                <p class="card-text">It's not clear why this happens, but most people affected have a
                                    family
                                    history of allergies or have closely related conditions such as asthma or
                                    eczema.</p>
                                <p class="card-text">
                                    The number of people with allergies is increasing every year. The reasons for this
                                    are
                                    not
                                    understood, but one of the main theories is it's the result of living in a cleaner,
                                    germ-free environment, which reduces
                                    the number of germs our immune system has to deal with.
                                </p>
                            </div>
                        </div>
<!--                        <div class="card mt-5">-->
<!--                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
                <div *ngIf="diseasesName === 'arthritis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Arthritis: Causes, Joint Pain, & Ayurvedic Treatment | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Arthritis is a common chronic condition of the joints&nbsp;that causes pain,
                                    inflammation,
                                    and stiffness affecting millions of people worldwide. The prevalence of arthritis
                                    varies
                                    depending on the type of arthritis, age, and geographic location. It can be cured
                                    with
                                    an&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a> easily
                                    and
                                    effectively.&nbsp;</p>
                                <p>In India, the prevalence of arthritis is estimated to be around 15% to 20% of the
                                    population.
                                    According to a study published in the Journal of Family Medicine and Primary Care,
                                    the
                                    prevalence of osteoarthritis in India is 22.7%, while the prevalence of rheumatoid
                                    arthritis
                                    is 0.75%.</p>
                                <p>Globally, it is estimated that around 350 million people suffer from arthritis, with
                                    the
                                    most
                                    common types being osteoarthritis and rheumatoid arthritis. The prevalence of
                                    arthritis
                                    increases with age, and it is more common in women than in men. In developed
                                    countries,
                                    the
                                    prevalence of arthritis is higher due to the aging population and increased rates of
                                    obesity
                                    and sedentary lifestyles</p>
                                <h2> What is Arthritis?
                                </h2>
                                <p>Arthritis is a medical condition that affects the joints in the body. Joints are the
                                    places
                                    in the body where two bones meet, and they allow us to move our bodies. Arthritis
                                    can
                                    cause
                                    pain, swelling, and stiffness in the joints, which can make it difficult to move
                                    them.
                                    Arthritis can affect people of all ages, genders, and backgrounds, and it can cause
                                    varying
                                    degrees of pain and disability.
                                </p>
                                <h3>What are the affected areas in Arthritis?</h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/arthritis/Image.webp"
                                    >
                                </div>
                                <p>Arthritis can affect different areas of the body, including:</p>
                                <ul>
                                    <li>Hands and fingers</li>
                                    <li>Wrists</li>
                                    <li>Elbows</li>
                                    <li>Shoulders</li>
                                    <li>Hips</li>
                                    <li>Knees</li>
                                    <li>Ankles</li>
                                    <li>Feet and toes</li>
                                </ul>
                                <p>In some cases, arthritis can also affect other parts of the body, such as the spine,
                                    eyes,
                                    and skin. The specific areas affected by arthritis can vary depending on the type of
                                    arthritis and the individual.</p>
                                <p>If you are facing pain in any of these body parts you can take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/joints-assessment">free self
                                    assessment
                                    test
                                    online for Arthritis</a>.</p>
                                <h2>What are the Different Types of Arthritis?
                                </h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/arthritis/Image-2.webp"
                                    >
                                </div>
                                <p>Although there are many different types of arthritis, osteoarthritis,&nbsp;and
                                    rheumatoid
                                    arthritis are the two most prevalent types. A summary of a few additional forms of
                                    arthritis
                                    is provided below:</p>
                                <ul>
                                    <li>Osteoarthritis: The most prevalent kind of arthritis, osteoarthritis is brought
                                        on
                                        by
                                        the gradual deterioration of the joints.
                                    </li>
                                    <li>Rheumatoid arthritis: An autoimmune condition known as rheumatoid arthritis
                                        makes
                                        the
                                        body's immune system attack the joints.
                                    </li>
                                    <li>Psoriatic arthritis: This type of arthritis occurs in some people who have
                                        psoriasis, a
                                        skin condition that causes red, scaly patches.
                                    </li>
                                    <li>Gout: Gout is caused by a build-up of uric acid crystals in the joints, which
                                        can
                                        cause
                                        severe pain and swelling.
                                    </li>
                                    <li>Lupus arthritis: Lupus is an autoimmune disease that can cause inflammation in
                                        the
                                        joints, as well as other parts of the body.
                                    </li>
                                    <li>Ankylosing spondylitis: This type of arthritis primarily affects the spine, but
                                        it
                                        can
                                        also affect other joints in the body.
                                    </li>
                                    <li>Juvenile idiopathic arthritis: In children under the age of 16, juvenile
                                        idiopathic
                                        arthritis is a kind of arthritis that can be debilitating.
                                    </li>
                                    <li>Reactive arthritis: Reactive arthritis is caused by an infection in another part
                                        of
                                        the
                                        body, such as the urinary tract or gastrointestinal tract.
                                    </li>
                                    <li>Infectious arthritis: Arthritis caused by an infection in the joint is referred
                                        to
                                        as
                                        infectious arthritis.
                                    </li>
                                </ul>
                                <p>Get a&nbsp;<a href="https://www.draxico.com/">free doctor appointment for Arthritis
                                    online</a> if you are facing any of the issues.</p>
                                <p>Out of the above all types the most common two types are described below­-</p>
                                <p>The most prevalent kind of arthritis,&nbsp;<strong>osteoarthritis</strong>, develops
                                    as
                                    the
                                    protective cartilage that cushions the ends of bones deteriorates over time. Any
                                    joint
                                    in
                                    the body can develop osteoarthritis, but the hands, knees, hips, and spine are the
                                    most
                                    frequently affected<strong>.</strong>The symptoms of osteoarthritis can include:</p>
                                <ul>
                                    <li>Pain: Osteoarthritis can cause pain in the affected joint, which may be worse
                                        during
                                        or
                                        after activity.
                                    </li>
                                    <li>Stiffness: You may experience stiffness in the affected joint, particularly in
                                        the
                                        morning or after sitting or standing for long periods.
                                    </li>
                                    <li>Swelling: Osteoarthritis can cause swelling and tenderness in the affected
                                        joint.
                                    </li>
                                    <li>Limited range of motion: You may find it difficult to move the affected joint
                                        through
                                        its full range of motion.
                                    </li>
                                    <li>Cracking or popping sounds: If you move the afflicted joint, you might hear
                                        cracking
                                        or
                                        popping noises.
                                    </li>
                                </ul>
                                <p><strong>Rheumatoid arthritis (RA)</strong> is an autoimmune disease that occurs when
                                    the
                                    body's immune system mistakenly attacks the lining of the joints, causing
                                    inflammation
                                    and
                                    damage to the joint tissue. Any joint in the body can be impacted by RA, signs of
                                    arthritis
                                    in hands, wrists, and feet are the most frequently affected.</p>
                                <p>The symptoms of RA can vary from person to person, but they typically include:</p>
                                <ul>
                                    <li>Pain: RA can cause pain in the affected joint, which may be worse in the morning
                                        or
                                        after periods of inactivity.
                                    </li>
                                    <li>Swelling: RA can cause swelling and tenderness in the affected joint.</li>
                                    <li>Stiffness: You may experience stiffness in the affected joint, particularly in
                                        the
                                        morning or after sitting or standing for long periods.
                                    </li>
                                    <li>Fatigue: RA can cause fatigue and a general feeling of being unwell.</li>
                                    <li>Fever: RA can occasionally result in a low-grade fever.</li>
                                    <li>Deformity: Over time, RA can cause the affected joint to become deformed, making
                                        it
                                        difficult to use.
                                    </li>
                                </ul>
                                <p>The symptoms of RA can be mild or severe, and they can come and go over time.</p>
                                <h2>CAUSES OF ARTHRITIS
                                </h2>
                                <p>There are no single arthritis causes, as it can develop from a combination of
                                    factors.
                                    Here
                                    are some of the factors that can contribute to the development of arthritis:</p>
                                <ul>
                                    <li>Age: As we age, the wear and tear on our joints can contribute to the
                                        development of
                                        arthritis.
                                    </li>
                                    <li>Genetics: Some types of arthritis, such as rheumatoid arthritis, can run in
                                        families.
                                    </li>
                                    <li>Injury: Injuries to the joint, such as a fracture or dislocation, can increase
                                        the
                                        risk
                                        of developing arthritis.
                                    </li>
                                    <li>Obesity: Being overweight can put extra stress on the joints, increasing the
                                        risk of
                                        developing arthritis. Check your&nbsp;<a
                                            href="https://www.draxico.com/bmi-calculator">Body
                                            Mass Index</a> online to know if you fall in the obesity category.
                                    </li>
                                    <li>Infection: Certain infections, such as Lyme disease, can lead to the development
                                        of
                                        arthritis.
                                    </li>
                                    <li>Autoimmune disorders: Autoimmune disorders, such as lupus, can cause the immune
                                        system
                                        to attack the joints, leading to the development of arthritis.
                                    </li>
                                    <li>Occupational hazards: Certain occupations that involve repetitive motions or
                                        heavy
                                        lifting can increase the risk of developing arthritis.
                                    </li>
                                </ul>
                                <p>It's important to note that not everyone who has one or more of these risk factors
                                    will
                                    develop arthritis, and some people may develop arthritis without any known risk
                                    factors.</p>
                                <h3>How to Identify Early Signs
                                </h3>
                                <p>The early signs of arthritis can be subtle and may vary depending on the type of
                                    arthritis
                                    you have. However, here are some common early signs of arthritis to look out
                                    for:</p>
                                <ul>
                                    <li>Joint pain: The most common early signs of arthritis in fingers and pain in the
                                        affected
                                        joint. The pain may be mild at first and may come and go.
                                    </li>
                                    <li>Stiffness: You may experience stiffness in the affected joint, particularly in
                                        the
                                        morning or after sitting or standing for long periods.
                                    </li>
                                    <li>Swelling: Swelling and discomfort in the affected joint might be brought on by
                                        arthritis.
                                    </li>
                                    <li>Reduced range of motion: You may find it difficult to move the affected joint
                                        through
                                        its full range of motion.
                                    </li>
                                    <li>Fatigue: Arthritis can cause fatigue and a general feeling of being unwell.</li>
                                    <li>Numbness or tingling: In some cases, arthritis can cause numbness or tingling in
                                        the
                                        affected joint
                                    </li>
                                    <li>Early treatment can help slow the progression of arthritis and improve quality
                                        of
                                        life.
                                    </li>
                                </ul>
                                <p>If you are facing any of these issues and confused about what to do next you can&nbsp;<a
                                    href="https://www.draxico.com/">take a free online consultation with an Ayurvedic
                                    Doctor</a>.
                                </p>
                                <h3>Diagnosis and tests for Arthritis
                                </h3>
                                <p>The diagnosis of arthritis typically involves a combination of medical history,
                                    physical
                                    examination, and imaging or laboratory tests. Here are some of the tests and methods
                                    used to
                                    diagnose arthritis:</p>
                                <p>Medical history and physical examination: A healthcare provider will ask about the
                                    patient's
                                    symptoms, medical history, and family history of arthritis. They will also perform a
                                    physical examination to assess joint tenderness, swelling, range of motion, and
                                    overall
                                    function.</p>
                                <p>Blood tests: Blood tests can be used to look for markers of inflammation or to
                                    identify
                                    specific types of arthritis. For example, rheumatoid arthritis can be diagnosed with
                                    a
                                    blood
                                    test for rheumatoid factor or anti-citrullinated protein antibodies (ACPA).</p>
                                <p>Imaging tests: X-rays, CT scans, and MRIs can help identify joint damage or other
                                    abnormalities that suggest arthritis.</p>
                                <p>Joint fluid analysis: In some cases, a doctor may use a needle to remove fluid from a
                                    swollen
                                    joint and analyze it for signs of inflammation, infection, or crystals (in the case
                                    of
                                    gout).</p>
                                <p>Musculoskeletal ultrasound: An ultrasound can help visualize joint inflammation and
                                    other
                                    changes that suggest arthritis.</p>
                                <p>It is important to note that the diagnostic process for arthritis can vary depending
                                    on
                                    the
                                    type and severity of the condition. A healthcare provider will work with a patient
                                    to
                                    determine the most appropriate tests and treatment plan based on their individual
                                    needs.</p>
                                <h2>Ayurvedic Say on Arthritis and Risk Factors?
                                </h2>
                                <p>According to Ayurveda, arthritis is a Vata disorder that affects the joints and
                                    causes
                                    pain
                                    and stiffness. There are two main types of arthritis in Ayurveda: Sandhivata
                                    (osteoarthritis) and Amavata (rheumatoid arthritis).</p>
                                <p>Sandhivata is caused by the accumulation of Vata dosha in the joints, leading to
                                    dryness,
                                    cracking, and pain. Amavata, on the other hand, is caused by the accumulation of Ama
                                    (toxins) in the joints, leading to inflammation and pain.</p>
                                <p>Ayurveda believes that poor digestion and metabolism lead to the accumulation of
                                    toxins
                                    in
                                    the body, which can cause arthritis. The accumulation of Ama in the joints can cause
                                    inflammation and pain, while the accumulation of Vata in the joints can cause
                                    stiffness
                                    and
                                    reduced mobility.</p>
                                <p>Arthritis Ayurvedic treatments aim to balance the doshas and eliminate toxins from
                                    the
                                    body.
                                    This may involve a combination of dietary changes, herbal remedies, massage, and
                                    lifestyle
                                    modifications.</p>
                                <h3>How Ayurveda Can Help in Arthritis Prevention?</h3>
                                <p>Ayurveda can help in arthritis prevention by promoting overall health and balance in
                                    the
                                    body. Here are some ways Ayurveda can help prevent arthritis:</p>
                                <ol>
                                    <li>Diet: Ayurveda emphasises the importance of a healthy and balanced diet in
                                        preventing
                                        arthritis. A diet that is high in fresh fruits and vegetables, whole grains, and
                                        lean
                                        protein can help maintain a healthy weight and reduce the risk of arthritis.
                                        Additionally, avoiding processed and fried foods, as well as foods that are
                                        difficult to
                                        digest, can help prevent inflammation and the accumulation of toxins in the
                                        body.
                                    </li>
                                    <li>Lifestyle: Ayurveda recommends engaging in regular exercises, such as yoga or
                                        walking,
                                        to maintain joint flexibility and prevent stiffness. It also recommends
                                        practising
                                        stress-reducing techniques, such as meditation or deep breathing, to prevent the
                                        accumulation of stress and tension in the body.
                                    </li>
                                    <li>Herbal remedies: Ayurvedic herbs, such as ginger, turmeric, and Boswellia, have
                                        anti-inflammatory properties that can help reduce the risk of arthritis. These
                                        herbs
                                        can
                                        be consumed in supplement form or added to meals as spices.
                                    </li>
                                    <li>Regular detoxification: Ayurveda recommends regular detoxification to remove
                                        toxins
                                        from
                                        the body and prevent the accumulation of Ama, which can cause inflammation and
                                        arthritis. This may involve practices such as <a
                                            href="https://www.draxico.com/panchakarma-treatment/"> Panchakarma</a>,
                                        which is
                                        a
                                        series of
                                        cleansing therapies that remove toxins from the body.
                                    </li>
                                </ol>
                                <p>Balancing the doshas: Ayurveda teaches that balancing the three doshas (Vata, Pitta,
                                    and
                                    Kapha) is essential for preventing disease and maintaining health.&nbsp;<a
                                        href="https://www.draxico.com/doctor">Ayurvedic practitioners</a> can help
                                    identify
                                    any
                                    imbalances in the doshas and recommend treatments to restore balance and prevent
                                    arthritis.&nbsp;
                                </p>
                                <h3>Which Stages are Curable in Ayurveda?
                                </h3>
                                <p>Ayurveda can help manage arthritis symptoms, slow down the progression of the
                                    disease,
                                    and
                                    prevent further damage to the joints. The earlier the treatment is started, the more
                                    effective it is likely to be in slowing down the progression of the disease and
                                    reducing
                                    pain and stiffness.
                                </p>
                                <h3>Can Ayurveda Help in Arthritis Treatment with Operation?
                                </h3>
                                <p>Ayurvedic treatment for arthritis focuses on reducing inflammation, strengthening the
                                    joints,
                                    and eliminating toxins from the body. The wholesome approach may reduce your chances
                                    of
                                    surgery. The following are some common Arthritis Ayurvedic treatments and Ayurvedic
                                    medicine
                                    for joint and muscle pain.&nbsp;</p>
                                <ul>
                                    <li>Ayurvedic herbs: Ayurvedic herbs, such as ginger, turmeric, Boswellia, and
                                        Ashwagandha,
                                        have anti-inflammatory properties that can help reduce inflammation and pain
                                        associated
                                        with arthritis. These herbs can be taken as supplements or as spices in food.
                                    </li>
                                    <li>Panchakarma is a series of cleansing therapies that remove toxins from the body
                                        and
                                        promote overall health. It may involve treatments such as
                                    </li>
                                </ul>
                                <ol>
                                    <li>Abhyanga: This is a full-body oil massage that uses warm herbal oil to nourish
                                        and
                                        revitalize the body.&nbsp;<a
                                            href="https://www.draxico.com/panchakarma-treatment/abhyanga">Abhyanga</a>
                                        can help reduce inflammation and pain associated with arthritis, improve joint
                                        flexibility, and promote relaxation.
                                    </li>
                                    <li>Swedana: This is a steam therapy that involves sweating the body in a steam
                                        chamber
                                        or
                                        through the application of moist heat. Swedana can help reduce stiffness and
                                        pain
                                        associated with arthritis, improve circulation, and promote the elimination of
                                        toxins
                                        from the body.
                                    </li>
                                    <li>Virechana: This is a purgation therapy that involves the use of Ayurvedic herbs
                                        and
                                        laxatives to eliminate toxins from the body. Virechana can help reduce
                                        inflammation,
                                        improve digestion, and promote overall health.
                                    </li>
                                    <li>Basti: This is an enema therapy that involves the introduction of herbal oils or
                                        decoctions into the colon.&nbsp;<a
                                            href="https://www.draxico.com/panchakarma-treatment/basti">Basti</a>
                                        can help reduce inflammation and pain associated with arthritis, improve joint
                                        flexibility, and promote the elimination of toxins from the body.
                                    </li>
                                    <li>Nasya: This is a nasal therapy that involves the application of herbal oils or
                                        powders
                                        to the nasal passages.&nbsp;<a
                                            href="https://www.draxico.com/panchakarma-treatment/nasyam">Nasya</a>
                                        can help reduce inflammation and pain associated with arthritis, improve
                                        respiratory
                                        function, and promote overall health.
                                    </li>
                                </ol>
                                <ul>
                                    <li>Ayurvedic diet: Following a healthy and balanced diet according to Ayurvedic
                                        principles
                                        can help manage the symptoms of arthritis. This may include consuming foods that
                                        are
                                        easy to digest, avoiding processed and fried foods, and incorporating
                                        anti-inflammatory
                                        foods such as fresh fruits and vegetables, whole grains, and lean protein.
                                    </li>
                                    <li>Yoga and meditation: Engaging in regular exercises, such as yoga, can help
                                        maintain
                                        joint flexibility and reduce stiffness associated with arthritis. Additionally,
                                        practicing stress-reducing techniques, such as meditation or deep breathing, can
                                        help
                                        reduce inflammation and promote overall well-being.
                                    </li>
                                    <li>Ayurvedic oils: Applying warm Ayurvedic oils to the joints can help reduce
                                        inflammation
                                        and pain associated with arthritis. Massaging these oils onto the joints can
                                        help
                                        increase blood flow and promote healing.
                                    </li>
                                </ul>
                                <h3>Ayurvedic Remedies for Arthritis?</h3>
                                <ul>
                                    <li>Ginger tea: Boil 1-2 inches of fresh ginger root in 2 cups of water for 10-15
                                        minutes.
                                        Strain and add honey or lemon to taste. Drink 2-3 cups daily.
                                    </li>
                                    <li>Turmeric milk: Heat 1 cup of milk with 1 tsp of turmeric powder and 1 tsp of
                                        honey.
                                        Drink before bedtime for better sleep and pain relief.&nbsp;<a
                                            href="https://www.draxico.com/blogs/amazing-health-benefits-of-golden-spice-turmeric-dr-axico">Full
                                            recipe here</a>.
                                    </li>
                                    <li>Herbal oil massage: Warm sesame or coconut oil and massage gently onto the
                                        affected
                                        joints for 10-15 minutes. 30 minutes of application are followed by a warm water
                                        rinse.
                                    </li>
                                </ul>
                                <h4>Do’s:
                                </h4>
                                <ol>
                                    <li>Follow a Sattvic diet, which is easy to digest, low in fat, and rich in fresh
                                        fruits,
                                        vegetables, and whole grains.
                                    </li>
                                    <li>Drink warm water and herbal teas to maintain Agni (digestive fire) and balance
                                        the
                                        Doshas (energies in the body).
                                    </li>
                                    <li>Practice gentle exercises, such as yoga, to improve Sthira (stability) and Sukha
                                        (ease)
                                        in the joints.
                                    </li>
                                    <li>Use warm herbal oils, such as sesame or coconut oil, for Abhyanga (massage) to
                                        reduce
                                        Vata (air element) in the joints and promote circulation.
                                    </li>
                                    <li>Get adequate sleep to promote overall health and reduce Ama (toxins) in the
                                        body.
                                    </li>
                                    <li>Practice Pranayama (breathing exercises) and Dhyana (meditation) to reduce
                                        stress
                                        and
                                        balance the mind.
                                    </li>
                                </ol>
                                <p><a href="https://www.draxico.com/">Consult an Ayurvedic Vaidya</a> (practitioner) for
                                    the
                                    appropriate use of herbs and supplements, such as Ashwagandha, Guggulu, and Shallaki
                                </p>
                                <h4>Don'ts:
                                </h4>
                                <ol>
                                    <li><strong>Avoid Tamasic (heavy and processed) and Rajasic (stimulating) foods,
                                        which
                                        can
                                        increase Ama and worsen inflammation.</strong></li>
                                    <li><strong>Limit your consumption of Mamsa (red meat), Dugdha (dairy products), and
                                        Vidahi
                                        (spicy) foods.</strong></li>
                                    <li><strong>Avoid Viruddha Vyayama (incompatible exercise) or activities that may
                                        aggravate
                                        (joint) pain.</strong></li>
                                    <li>Don't skip meals or eat irregularly, which can disrupt Jatharagni (digestive
                                        fire)
                                        and
                                        cause inflammation.
                                    </li>
                                    <li>Avoid excessive Madhya (alcohol) consumption, which can increase Ama and worsen
                                        inflammation.
                                    </li>
                                    <li>Don't smoke, as it can worsen Vata and increase the risk of other health
                                        problems.
                                    </li>
                                    <li>Avoid stress and emotional turmoil, which can increase Vata and trigger or
                                        exacerbate
                                        arthritis symptoms
                                    </li>
                                </ol>
                                <h3>Recommended Arthritis Ayurvedic medicines:
                                </h3>
                                <p>Some of the Ayurvedic formulations for arthritis:</p>
                                <ul>
                                    <li>Kaishore Guggulu</li>
                                    <li>Simhanada Guggulu</li>
                                    <li>Triphala Guggulu</li>
                                    <li>Maharasnadi Kwath</li>
                                    <li>Dashmool Kwath</li>
                                    <li>Punarnavadi Guggulu</li>
                                    <li>Panchatikta Ghrita Guggulu</li>
                                    <li>Shallaki Capsules</li>
                                    <li>Eranda Oil</li>
                                    <li>Mahanarayana Taila</li>
                                </ul>
                                <p><strong>Note:</strong> It's important to consult with a qualified Ayurvedic
                                    practitioner
                                    before using any herbs or remedies to ensure they are safe and effective for your
                                    specific
                                    condition.</p>
                                <h4>A Short Note from Dr.Axico
                                </h4>
                                <p>A condition that affects the joints is arthritis. There are many different types of
                                    arthritis, and each one can be uncomfortable and restrict movement. Some forms of
                                    arthritis
                                    are brought on by everyday wear and tear. Other types result from inflammatory or
                                    autoimmune
                                    diseases.&nbsp;</p>
                                <p>Arthritis can be treated in several ways, from physical or occupational therapy to
                                    joint
                                    surgery. Your Ayurvedic healthcare professional will evaluate your symptoms and
                                    suggest
                                    the
                                    best course of action for you. Most people can successfully manage their arthritis
                                    with
                                    Dr.
                                    Axico and continue to participate in their favorite hobbies. In Addition, they can
                                    buy
                                    Ayurvedic medicine online or offline.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4>Can arthritis be cured?
                                        </h4>
                                        <p>Yes, Arthritis can be cured. The best treatment for arthritis is Ayurvedic, which
                                            combines
                                            palliative care and purifying therapy (Shomana and Shamana). The basic goals of
                                            Ayurvedic
                                            arthritis treatment are to relieve metabolic imbalances and lessen joint pain,
                                            swelling,
                                            and
                                            inflammation.
                                        </p>
                                        <h4>At what age does arthritis start?</h4>
                                        <p>Arthritis can start at any age, but it is more commonly diagnosed in adults over the
                                            age
                                            of
                                            40. The risk of developing arthritis increases with age, but younger people can also
                                            develop
                                            the condition. There are many different types of arthritis, each with its own set of
                                            risk
                                            factors and potential triggers.
                                        </p>
                                        <h4>Can knee arthritis be treated without operation?
                                        </h4>
                                        <p>Ayurvedic treatments are better than joint replacement surgery if osteoarthritis is
                                            in
                                            primary, secondary, or even some tertiary cases. In the case of rheumatoid
                                            arthritis,
                                            every
                                            medical professional, irrespective of medical systems, unanimously agrees that
                                            Ayurveda
                                            for
                                            arthritis is the best way forward than the highly dangerous steroids and their side
                                            effects.
                                            Ayurvedic treatment for rheumatoid arthritis would be effective from the Ayurvedic
                                            doctor
                                            and can buy Ayurvedic medicine for knee grease from Dr. Axico.
                                        </p>
                                        <h4>Can arthritis be cancerous?
                                        </h4>
                                        <p>While arthritis itself is not cancerous, some forms of arthritis, such as rheumatoid
                                            arthritis (RA), have been associated with an increased risk of certain types of
                                            cancer,
                                            such
                                            as lymphoma. This is thought to be due to the chronic inflammation and immune system
                                            dysfunction that can occur in these conditions, which can contribute to the
                                            development
                                            of
                                            cancer.
                                        </p>
                                        <h4> What food is not good for arthritis?
                                        </h4>
                                        <p>There are certain foods that people with arthritis may want to limit or avoid, as
                                            they
                                            can
                                            exacerbate symptoms and inflammation in the body. Some of these foods include:</p>
                                        <p>1. Processed and fried foods: These foods are often high in unhealthy fats and
                                            can contribute to inflammation in the body, which can worsen arthritis symptoms.</p>
                                        <p>2. Sugar and refined carbohydrates: Consuming too much sugar and refined carbs
                                            can lead to spikes in blood sugar levels, which can contribute to inflammation and
                                            joint
                                            pain.</p>
                                        <p>3. Red meat: Red meat is high in saturated fats, which can increase
                                            inflammation and worsen arthritis symptoms.</p>
                                        <p>4. Dairy products: Some people with arthritis may be sensitive to dairy
                                            products, which can cause inflammation in the body.</p>
                                        <p>5. Alcohol and caffeine: These substances can dehydrate the body, which can
                                            worsen joint pain and stiffness.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'bronchitis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Bronchitis: Causes of Lung Disease, Signs and Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Bronchitis is a respiratory condition that can cause inflammation of the bronchial
                                    tubes,
                                    leading to coughing, wheezing, and other symptoms. It can be caused by various
                                    factors,
                                    including viral or bacterial infections, smoking, and exposure to air
                                    pollutants.&nbsp;</p>

                                <p>Bronchitis can be acute or chronic, with chronic bronchitis being a more severe and
                                    long-lasting condition. However, Bronchitis can be a dangerous disease but&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor consultation</a> would be a
                                    great
                                    choice to stop this disorder. It's important to understand the causes, bacterial
                                    bronchitis
                                    symptoms, and treatments of bronchitis to manage the condition effectively and
                                    prevent
                                    complications.&nbsp;</p>

                                <p>We will explore the different types of bronchitis, their causes, and their symptoms.
                                    We
                                    will
                                    also discuss some tips on how to prevent bronchitis and how to manage it when it
                                    occurs.
                                    Additionally, we will provide information on the importance of seeking medical
                                    attention
                                    and
                                    following a proper treatment plan to manage bronchitis.</p>

                                <h3><strong>What is Bronchitis?</strong></h3>
                                <p>As the airways that connect the&nbsp;<a
                                    href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/trachea#:~:text=(TRAY%2Dkee%2Duh),their%20lobes%2C%20and%20the%20bronchi."
                                    rel="nofollow">trachea</a>
                                    (windpipe) to the lungs, or bronchial tubes, become inflamed, bronchitis is a
                                    respiratory
                                    illness. Inflammation can lead to&nbsp;<a
                                        href="https://www.draxico.com/diseases/cough">coughing</a>,
                                    wheezing, and breathing problems. Bronchitis is a common condition and can affect
                                    people
                                    of
                                    all ages. It is more prevalent during the winter months and is more common in people
                                    who
                                    smoke or are exposed to air pollution.&nbsp;</p>
                                <h4><strong>Acute and Chronic Bronchitis</strong></h4>
                                <p>Acute bronchitis and chronic bronchitis are two different types of bronchitis. Acute
                                    bronchitis symptoms include short-term inflammation of the bronchial tubes that
                                    lasts
                                    for a
                                    few weeks and is often caused by a viral infection. Symptoms of acute bronchitis
                                    include
                                    coughing, wheezing, shortness of breath, and chest discomfort.</p>

                                <p>Chronic bronchitis is a more serious condition that lasts for several months or more.
                                    It
                                    is
                                    characterised by persistent inflammation of the bronchial tubes and is often
                                    associated
                                    with
                                    smoking or exposure to environmental irritants. Bronchitis symptoms include
                                    coughing,
                                    mucus
                                    production, shortness of breath, and chest discomfort.</p>

                                <p>Chronic bronchitis is a type of chronic obstructive pulmonary disease (COPD) and can
                                    lead
                                    to
                                    complications such as respiratory infections and heart problems. Treatment for both
                                    acute
                                    and chronic bronchitis may include bronchodilators, anti-inflammatory medication,
                                    and
                                    lifestyle changes such as quitting smoking and avoiding environmental irritants.</p>
                                <h4><strong>How common is Bronchitis?</strong></h4>
                                <p>Bronchitis is a fairly common condition and can affect people of all ages. Acute
                                    bronchitis
                                    is a common reason for doctor visits and is responsible for a significant number of
                                    missed
                                    work days. The prevalence of acute bronchitis is highest during the winter months
                                    when
                                    viral
                                    infections are more common.&nbsp;</p>

                                <p>Chronic bronchitis is less common than acute bronchitis, but it is a serious
                                    condition
                                    that
                                    affects millions of people worldwide. Chronic bronchitis is often associated with
                                    smoking or
                                    exposure to environmental irritants and is a type of&nbsp;<a
                                        href="https://www.who.int/news-room/fact-sheets/detail/chronic-obstructive-pulmonary-disease-(copd)#:~:text=Chronic%20obstructive%20pulmonary%20disease%20(COPD)%20is%20a%20common%20lung%20disease,damaged%20or%20clogged%20with%20phlegm.">chronic
                                        obstructive pulmonary disease</a> (COPD).</p>

                                <h3>&nbsp;<strong>What are the main factors that cause Bronchitis?</strong></h3>
                                <p>The main factor that causes bronchitis is inflammation of the bronchial tubes, which
                                    can
                                    be
                                    caused by a variety of factors.</p>
                                <ul>
                                    <li>Viral infections such as the common cold or flu</li>
                                    <li>Bacterial infections</li>
                                    <li>Exposure to environmental irritants such as smoke or pollution</li>
                                    <li>Allergies to substances such as pollen, mould, or dust</li>
                                    <li>Cigarette smoking or exposure to second-hand smoke</li>
                                    <li>Exposure to chemical fumes, dust, and other occupational irritants</li>
                                    <li>Repeated lung infections</li>
                                    <li>Genetic factors that make some people more susceptible to bronchitis</li>
                                    <li>Weakened immune system due to factors such as HIV/AIDS, chemotherapy, or organ
                                        transplant.
                                    </li>
                                </ul>
                                <h4><strong>What are the noticeable changes in the body of a bronchitis-affected
                                    person?</strong></h4>
                                <p>Some of the common changes that may be observed:</p>
                                <ul>
                                    <li>A persistent cough that may produce mucus or phlegm</li>
                                    <li>Shortness of breath or wheezing</li>
                                    <li>Chest discomfort or tightness</li>
                                    <li>Fatigue or weakness</li>
                                    <li>Fever or chills in some cases</li>
                                    <li>Increased heart rate or breathing rate</li>
                                    <li>Bluish tint to the lips or fingernail beds due to low oxygen levels in severe
                                        cases
                                    </li>
                                </ul>
                                <p>In chronic bronchitis symptoms may persist for several months or recur over time,
                                    leading
                                    to
                                    damage to the bronchial tubes and lungs.</p>
                                <h4><strong>When to see a Doctor for Bronchitis?</strong></h4>
                                <p>It is recommended to see a doctor if you experience symptoms of bronchitis,
                                    especially if
                                    they last longer than a few days or if they interfere with your daily activities.
                                    Here
                                    are
                                    some signs that indicate the need for medical attention:</p>
                                <ul>
                                    <li>Shortness of breath or difficulty breathing</li>
                                    <li>High fever or chills</li>
                                    <li>Bronchitis chest pain or tightness</li>
                                    <li>Coughing up blood</li>
                                    <li>Wheezing or noisy breathing</li>
                                    <li>Ongoing cough that lasts more than a few weeks</li>
                                    <li>Existing lung or heart disease</li>
                                    <li>Weak immune system due to chronic illness, cancer treatment, or other factors
                                    </li>
                                </ul>
                                <p>If you have any of these symptoms or conditions, you should seek medical advice
                                    promptly
                                    to
                                    receive appropriate treatment and avoid complications.</p>

                                <h3><strong>Ayurvedic Say on Bronchitis?</strong></h3>
                                <p>According to Ayurveda, bronchitis is known as "Shwasa Pranali Shoth" and is caused by
                                    an
                                    imbalance in the Kapha dosha, which results in the accumulation of mucus in the
                                    respiratory
                                    tract, leading to inflammation and narrowing of the airways.&nbsp;</p>

                                <p>COPD causes difficulty in breathing, coughing, and wheezing.&nbsp;<a
                                    href="https://www.draxico.com/appointment">Ayurvedic treatment for bronchitis</a>
                                    focuses on
                                    balancing the Kapha dosha through a combination of dietary and lifestyle
                                    modifications,
                                    along with the use of specific herbs and Ayurvedic therapies such as steam
                                    inhalation,
                                    <a href="https://www.draxico.com/panchakarma-treatment/vamana">Vamana</a>, and <a
                                        href="https://www.draxico.com/panchakarma-treatment/virechana">Virechana</a>.
                                </p>

                                <h4><strong>How Ayurveda Can Help to Control Bronchitis and its Treatment?</strong>
                                    <div class="text-center">
                                        <img alt="" class="my-2 img-fluid"
                                             src="assets/img/diseases/diseasesImages/bronchitis/Image-1.webp"
                                        >
                                    </div>
                                </h4>
                                <p>Ayurveda offers a holistic approach to the treatment and management of bronchitis.
                                    Allergic
                                    bronchitis ayurvedic treatment aims to balance the Kapha dosha, which is responsible
                                    for
                                    the
                                    accumulation of mucus in the respiratory tract. Here are some ways Ayurveda can help
                                    to
                                    control bronchitis:</p>

                                <p><strong>Herbal remedies:</strong> Ayurvedic herbs such as Tulsi, Ginger, and Licorice
                                    have
                                    natural anti-inflammatory and expectorant properties, which help to reduce
                                    inflammation
                                    and
                                    promote the removal of mucus from the respiratory tract.</p>
                                <p><strong>Lifestyle modifications:</strong> Ayurveda emphasises the importance of a
                                    healthy
                                    lifestyle in the prevention and management of bronchitis. This includes regular
                                    exercise, a
                                    balanced diet, and adequate rest.</p>
                                <p><strong>Ayurvedic therapies</strong>: Therapies such as steam inhalation, Vamana, and
                                    Virechana can help to remove excess mucus from the respiratory tract and balance the
                                    Kapha
                                    dosha.</p>
                                <p><strong>Dietary modifications:</strong> Ayurveda recommends avoiding cold and heavy
                                    foods,
                                    dairy products, and <a
                                        href="https://www.webmd.com/diet/what-are-processed-foods#:~:text=Processed%20foods%20refer%20to%20any,salts%2C%20sugars%2C%20or%20fats."
                                        rel="nofollow"> processed foods</a>, which can worsen the symptoms of
                                    bronchitis.
                                </p>

                                <p>It is important to consult with an Ayurvedic practitioner before starting any
                                    treatment
                                    to
                                    ensure that the treatment is safe and effective for your individual needs.</p>
                                <h4><strong>Ayurvedic Home Remedies for Bronchitis</strong></h4>
                                <ul>
                                    <li><strong>Turmeric:</strong> It has anti-inflammatory and antibacterial properties
                                        that
                                        can help reduce inflammation in the bronchial tubes. It can be consumed in the
                                        form
                                        of
                                        turmeric tea or mixed with milk.
                                    </li>
                                    <li><strong>Ginger:</strong> It has anti-inflammatory properties and can help soothe
                                        irritated airways. It can be consumed in the form of ginger tea or mixed with
                                        honey.
                                    </li>
                                    <li><strong>Holy Basil:</strong> It has antibacterial and anti-inflammatory
                                        properties
                                        that
                                        can help reduce inflammation in the bronchial tubes. It can be consumed in the
                                        form
                                        of
                                        holy basil tea or mixed with honey.
                                    </li>
                                    <li><strong>Licorice:</strong> It has anti-inflammatory properties that can help
                                        reduce
                                        inflammation in the bronchial tubes. It can be consumed in the form of licorice
                                        tea
                                        or
                                        mixed with honey.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not</strong></h4><h4><strong>Foods to eat:</strong>
                            </h4>
                                <ol>
                                    <li>Warm water with honey</li>
                                    <li>Herbal teas like ginger, Tulsi, and licorice root tea</li>
                                    <li>Steamed vegetables like broccoli, cauliflower, and carrots</li>
                                    <li>Soups made with easily digestible ingredients like lentils and vegetables</li>
                                    <li>Grains like quinoa and brown rice</li>
                                    <li>Fruits like pomegranates, apples, and berries</li>
                                </ol>
                                <h4><strong>Foods to avoid:</strong></h4>
                                <ol>
                                    <li>Cold drinks and food</li>
                                    <li>Heavy meats like beef and pork</li>
                                    <li>Fried and processed foods</li>
                                    <li>Dairy products like milk, cheese, and yogurt</li>
                                    <li>Spicy and sour foods</li>
                                    <li>Caffeine and alcohol</li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Bronchitis</strong></h4>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/sitopaladi-churna">Sitopaladi
                                        Churna</a>
                                    </li>
                                    <li>Talisadi Churna</li>
                                    <li>Yashtimadhu Churna</li>
                                    <li><a href="https://www.draxico.com/products/kanakasava">Kanakasava</a></li>
                                    <li>Vasarishta</li>
                                    <li><a href="https://www.draxico.com/products/dashamoola-rishta">Dashmoola Kadha</a>
                                    </li>
                                    <li>Pippali Churna</li>
                                    <li>Haridrakhand</li>
                                    <li><a href="https://www.draxico.com/products/shwas-kuthar-ras">Shwas Kuthar Ras</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/sanjeevani-vati">Sanjeevani Vati</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/navayasa-loh">Yashtimadhu</a></li>
                                    <li>Tulsi</li>
                                    <li>Pippali</li>
                                    <li>Haridra</li>
                                    <li>Vasaka</li>
                                    <li>Kantakari</li>
                                    <li>Shirish</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>At best, having bronchitis can be frustrating. The cough seems to persist forever,
                                    even
                                    after
                                    the physical aches and runny nose have subsided. Until you feel better,
                                    over-the-counter
                                    best ayurvedic medicine for allergic bronchitis and even natural cures like honey
                                    can
                                    assist. Have a chat with an Ayurvedic doctor online consultation if you need help
                                    managing
                                    your symptoms. Moreover, you can buy Ayurvedic medicine online or offline too.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Is bronchitis a side effect of
                                            COVID-19?</strong>
                                        </h4>
                                        <p>Yes, bronchitis can be a side effect of COVID-19. COVID-19 is a respiratory illness
                                            caused by
                                            the SARS-CoV-2 virus, which can lead to inflammation and damage to the airways and
                                            lungs.
                                            This can result in bronchitis, which is the inflammation of the bronchial tubes in
                                            the
                                            lungs.&nbsp;</p>

                                        <p>However, not everyone who contracts COVID-19 will develop bronchitis, and it is more
                                            commonly
                                            seen in individuals with pre-existing respiratory conditions or who have severe
                                            COVID-19
                                            symptoms. It is important to seek medical attention if you experience any symptoms
                                            of
                                            COVID-19 or bronchitis.</p>
                                        <h4><strong>How long are you contagious if you have acute bronchitis?</strong></h4>
                                        <p>Acute bronchitis is contagious, especially during the first few days when the
                                            symptoms
                                            are
                                            most severe. The virus or bacteria that caused the bronchitis can be spread through
                                            the
                                            air
                                            by coughing or sneezing, or by coming into contact with contaminated
                                            surfaces.&nbsp;</p>

                                        <p>It is recommended to avoid close contact with others, cover your mouth and nose when
                                            coughing
                                            or sneezing, and frequently wash your hands to prevent the spread of the infection.
                                            Generally, the contagious period for acute bronchitis lasts for about 2 weeks, but
                                            it
                                            may
                                            vary depending on the severity of the infection and the person's immune system.</p>
                                        <h4><strong>How can I reduce my risk of bronchitis?</strong></h4>
                                        <p>You can reduce your risk of bronchitis by taking several preventative measures such
                                            as:</p>
                                        <ul>
                                            <li>Quit smoking or avoid exposure to second-hand smoke.</li>
                                            <li>Wash your hands frequently, especially during the cold and flu season.</li>
                                            <li>Avoid exposure to air pollution, chemical fumes, and other irritants.</li>
                                            <li>Maintain good hygiene by covering your mouth and nose while coughing or
                                                sneezing.
                                            </li>
                                            <li>Stay hydrated and drink plenty of fluids.</li>
                                            <li>Get a flu vaccine every year to prevent flu-related bronchitis.</li>
                                            <li>Practice healthy habits such as exercising regularly, getting enough sleep, and
                                                eating a
                                                well-balanced diet.
                                            </li>
                                        </ul>
                                        <h4><strong>What’s the difference between bronchitis and pneumonia?</strong></h4>
                                        <p>Bronchitis and pneumonia are both respiratory conditions, but they differ in their
                                            severity
                                            and the part of the respiratory system that they affect. Bronchitis is an
                                            inflammation
                                            of
                                            the bronchial tubes, which are the air passages that connect the trachea to the
                                            lungs,
                                            while
                                            pneumonia is an infection that causes inflammation in the air sacs of the
                                            lungs.&nbsp;</p>

                                        <p>The symptoms of bronchitis usually include coughing, wheezing, and shortness of
                                            breath,
                                            while
                                            the symptoms of pneumonia typically include coughing, fever, and chest pain.
                                            Pneumonia
                                            can
                                            be more severe than bronchitis and may require hospitalization, especially in
                                            vulnerable
                                            populations such as the elderly or those with weakened immune systems.</p>
                                        <h4><strong>What’s the difference between bronchitis and bronchiolitis?</strong>
                                        </h4>
                                        <p>Bronchitis and bronchiolitis are respiratory conditions that affect the airways, but
                                            they
                                            differ in the location and severity of the inflammation. Bronchitis affects the
                                            larger
                                            airways, or bronchi, causing cough, chest discomfort, and production of phlegm.
                                            Bronchiolitis, on the other hand, affects the smaller airways, or bronchioles, and
                                            is
                                            usually caused by viral infections that cause inflammation and narrowing of these
                                            airways.</p>

                                        <p>It most commonly affects infants and young children and can cause wheezing,
                                            difficulty
                                            breathing, and fever. While bronchitis can be acute or chronic and affect people of
                                            all
                                            ages, bronchiolitis is typically an acute illness that occurs in young children and
                                            infants.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'chest-infection'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Chest Infection: Various Causes, Signs & Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>A chest infection, also called an infection of the lower respiratory system, affects
                                    the
                                    lungs or the tubes that lead to the lungs. It includes a wide range of lung diseases
                                    caused
                                    by viruses, bacteria, or fungi. Bronchitis, pneumonia, and tuberculosis are all
                                    common
                                    types. Chest Infection can be treated with an Ayurvedic doctor online consultation,&nbsp;<a
                                        href="https://www.draxico.com/">book your free consultation now</a> and get an
                                    effective
                                    health treatment.</p>
                                <p>From mild to serious, chest infections can cause signs like a chronic cough,
                                    shortness of
                                    breath, chest pain, and fever. Treatment depends on what's causing it and may
                                    include
                                    rest,
                                    medicine, or, in serious cases, a stay in the hospital.</p>
                                <h3><strong>What is Chest Infection?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chest-infection/Image-1.webp"
                                    >
                                </div>
                                <p>A chest infection, also known as a lower respiratory tract infection, is an infection
                                    that
                                    affects the lungs or airways leading to the lungs. It can involve various parts of
                                    the
                                    respiratory system, such as the bronchi, bronchioles, and alveoli. Chest infections
                                    can
                                    be
                                    caused by viruses, bacteria, or fungi, and they can range in severity from mild to
                                    severe.</p><h4><strong>Common types of chest infections include:</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chest-infection/Image-2.webp"
                                    >
                                </div>
                                <ol>
                                    <li><a
                                        href="https://www.draxico.com/diseases/bronchitis"><strong>Bronchitis</strong></a><strong>:</strong>
                                        Inflammation of the bronchial tubes, which carry air to the lungs. It is often
                                        caused by
                                        a viral infection, but bacterial infections can also be responsible.
                                    </li>
                                    <li><strong>Pneumonia Chest Pain:</strong> Infection of the lung tissue, usually
                                        caused
                                        by
                                        bacteria, viruses, or fungi. Pneumonia can affect one or both lungs and can vary
                                        in
                                        severity.
                                    </li>
                                    <li><strong>Tuberculosis (TB):</strong> A bacterial infection caused by
                                        Mycobacterium
                                        tuberculosis. TB primarily affects the lungs but can also affect other parts of
                                        the
                                        body. It is a contagious disease that spreads through the air.
                                    </li>
                                </ol>
                                <h4><strong>Symptoms of a chest infection may include:</strong></h4>
                                <ul>
                                    <li>Persistent cough, often with phlegm or mucus production</li>
                                    <li>Shortness of breath or difficulty breathing</li>
                                    <li><a href="https://www.draxico.com/diseases/chest-pain">Chest pain</a> or
                                        discomfort
                                    </li>
                                    <li>Fatigue or weakness</li>
                                    <li>Fever and chills</li>
                                    <li>Wheezing or noisy breathing</li>
                                    <li>Rapid breathing</li>
                                    <li>Bluish discoloration of the lips or nails (in severe cases)&nbsp;</li>
                                </ul>
                                <h4><strong>What is upper and lower respiratory infection?</strong></h4>
                                <p>Upper respiratory infection (URI) and lower respiratory infection (LRI) are two
                                    different
                                    types of respiratory illnesses that affect different parts of the respiratory
                                    system.</p>
                                <h4><strong>Upper respiratory tract (URI) infection:</strong></h4>
                                <p>An upper respiratory infection is an illness that mostly affects the nose, lungs,
                                    throat,
                                    and
                                    larynx (voice box). Most of the time, viruses like rhinoviruses, coronaviruses, or
                                    influenza
                                    viruses are to blame. The&nbsp;<a
                                        href="https://www.draxico.com/diseases/common-cold">common
                                        cold</a>, sinusitis, and pharyngitis (sore throat) are all common types of upper
                                    respiratory illnesses. Most people with an upper respiratory infection (URI) have a
                                    stuffy
                                    nose, coughing, a runny nose, a sore throat, and a cough.</p>
                                <p><i>LRI stands for Lower Respiratory Infection.</i></p>
                                <p>A lower respiratory illness, on the other hand, affects the lower respiratory system,
                                    which
                                    includes the trachea (windpipe), bronchi (large airways), bronchioles (smaller
                                    airways),
                                    and
                                    the lungs. Different agents, such as viruses, bacteria, or fungi, can cause LRIs.
                                    Bronchitis, pneumonia, and tuberculosis are all examples of lower lung diseases. An
                                    LRI
                                    is
                                    usually marked by a cough that brings up phlegm or mucus, shortness of breath, chest
                                    pain,
                                    fever, and tiredness.</p>
                                <p>It is important to be able to tell the difference between upper and lower lung
                                    illnesses
                                    so
                                    that the right medicine can be given. Upper respiratory infections tend to be weaker
                                    and
                                    go
                                    away on their own, but lower respiratory infections can be more serious and need
                                    medical
                                    help, especially if they affect the lungs.</p>
                                <h3><strong>Different types of Chest Infection</strong></h3>
                                <p>Chest infections may impact the lungs and respiratory system in several different
                                    ways.
                                    Here
                                    are a few such examples:</p>
                                <p>Inflammation of the bronchial tubes, the airways that deliver air to the lungs, is
                                    known
                                    as
                                    bronchitis. Both acute and chronic conditions are possible. While chronic bronchitis
                                    is
                                    often linked to smoking or irritating exposure, acute bronchitis is frequently
                                    brought
                                    on by
                                    viral infections.</p>
                                <p><strong>Symptoms of pneumonia in elderly:&nbsp;</strong>A lung infection known as
                                    pneumonia
                                    results in inflammation of the air sacs in one or both lungs. It may be brought on
                                    by
                                    fungus, viruses, or <a href="https://en.wikipedia.org/wiki/Bacteria"
                                                           rel="nofollow">bacteria</a>. Pneumonia may be fatal and vary
                                    in
                                    severity, particularly in
                                    susceptible people like the elderly or those with compromised immune systems.</p>
                                <p>Mycobacterium tuberculosis, a bacterium, is the cause of tuberculosis (TB), a
                                    bacterial
                                    illness. Although it mostly affects the lungs, it may extend to other body regions.
                                    If
                                    left
                                    untreated, <a
                                        href="https://www.cdc.gov/tb/topic/basics/default.htm#:~:text=Tuberculosis%20(TB)%20is%20caused%20by,with%20TB%20bacteria%20becomes%20sick."
                                        rel="nofollow">TB</a> is a dangerous illness that needs medical attention and
                                    may
                                    spread
                                    to other
                                    people.</p>
                                <p><strong>Lung abscess:</strong> A localised pus accumulation inside the lung tissue is
                                    referred to as a lung abscess. When food, drink, or vomit is aspirated into the
                                    lungs or
                                    when an illness already spreads to the lungs, it often results in a bacterial
                                    infection.
                                </p>
                                <p><strong>Pleural infection:</strong> Also referred to as pleurisy or pleuritis, this
                                    condition
                                    affects the pleura, the thin membrane that borders the inside of the chest cavity
                                    and
                                    surrounds the lungs. It may be brought on by fungus, viruses, or bacteria. Chest
                                    discomfort
                                    and breathing problems may be brought on by pleural infections.</p>
                                <p>Lung infections brought on by fungus: Particularly in those with compromised immune
                                    systems,
                                    fungi may bring on lung infections. Examples include&nbsp;<a
                                        href="https://www.cdc.gov/fungal/diseases/coccidioidomycosis/index.html#:~:text=Valley%20fever%2C%20also%20called%20coccidioidomycosis,found%20in%20south%2Dcentral%20Washington.">coccidioidomycosis</a>,
                                    histoplasmosis, and&nbsp;<a
                                        href="https://www.cdc.gov/fungal/diseases/aspergillosis/index.html#:~:text=Aspergillosis%20is%20an%20infection%20caused,every%20day%20without%20getting%20sick.">aspergillosis</a>.
                                </p><h4><strong>What is Acute and Chronic Chest Infection</strong></h4>
                                <p>Chest infections may be either acute or chronic depending on how long they have
                                    persisted
                                    in
                                    the respiratory system. Here is an explanation of each:</p>
                                <p>An acute chest infection is a short-term illness that appears out of the blue and
                                    lasts
                                    for
                                    just a short time. Numerous infections, such as viruses, bacteria, or fungi, may
                                    cause
                                    it.
                                    Inflammation of the airways and lung tissues is a common factor in acute chest
                                    infections,
                                    which may cause TB symptoms in chest including coughing up mucus or phlegm, chest
                                    pain,
                                    shortness of breath, and fever. Acute bronchitis and acute pneumonia are typical
                                    illustrations of acute chest infections.</p>
                                <p><strong>Acute bronchitis:</strong> A bronchial tube inflammation often brought on by
                                    viral
                                    infections. It causes a chronic cough that often includes the production of phlegm
                                    or
                                    mucus.
                                </p>
                                <p>Pneumonia is an infection of the lungs, and it is referred to as acute pneumonia when
                                    it
                                    appears abruptly and spreads quickly. It could need immediate medical care and
                                    treatment
                                    and
                                    can be brought on by bacteria, viruses, or fungus.</p>
                                <p>A chronic chest infection is an ongoing or recurrent illness that lasts for a
                                    protracted
                                    length of time, usually more than a few weeks. The underlying health disorders of
                                    chronic
                                    obstructive pulmonary disease (COPD), bronchiectasis, or a weakened immune system
                                    are
                                    often
                                    linked to persistent chest infections. These infections may wax and wane over time
                                    and
                                    often
                                    begin more gradually.&nbsp;</p>
                                <p>Chronic coughing, recurring chest infections, increased sputum production, and
                                    breathing
                                    problems are a few symptoms that might occur.</p>
                                <p><strong>Chronic bronchitis:</strong> A kind of chronic chest illness known as chronic
                                    bronchitis is characterised by ongoing bronchial tube inflammation. Usually, smoking
                                    or
                                    prolonged contact with irritants is the culprit. A persistent cough that produces an
                                    excessive amount of mucus for at least three months in two consecutive years is one
                                    of
                                    the
                                    symptoms.</p>
                                <p>Chronic pneumonia: A persistent or recurring lung infection is referred to as chronic
                                    pneumonia. People who have underlying lung diseases with compromised immune systems,
                                    or
                                    as a
                                    consequence of recurrent infections may get it. To avoid future complications,
                                    chronic
                                    pneumonia often needs specialized therapy.</p><h4><strong>What are the main factors
                                that
                                cause Chest Infections in adults and children?</strong></h4>
                                <p>Several variables, including the following, may lead to chest infections in both
                                    adults
                                    and
                                    children:</p>
                                <p><strong>Virus-related Infections:</strong> Both adults and children may have chest
                                    infections
                                    from viruses. Upper respiratory tract infections (URIs) may develop into lower
                                    respiratory
                                    tract infections (LRIs), such as bronchitis or pneumonia, as a result of respiratory
                                    viruses
                                    including the rhinovirus, influenza virus, respiratory syncytial virus (RSV), and
                                    coronavirus.</p>
                                <p>Chest infections in both adults and children may be brought on by bacteria. Among the
                                    typical
                                    bacterial pathogens linked to pneumonia and bronchitis are Streptococcus pneumoniae,
                                    Haemophilus influenzae, and Staphylococcus aureus.</p>
                                <p><strong>Fungal infections:</strong> Particularly in those with compromised immune
                                    systems
                                    or
                                    those who have been exposed to fungal spores in certain conditions, some forms of
                                    fungus,
                                    such as Aspergillus species, may cause fungal lung infections.</p>
                                <p><strong>Environmental Factors:</strong> Chest infections are more likely to occur if
                                    you
                                    are
                                    exposed to environmental irritants and toxins. Smoke from cigarettes, air pollution,
                                    allergens, and workplace dangers (such as dust, chemicals, or gases) may irritate
                                    the
                                    respiratory system and increase susceptibility to illnesses in people.</p>
                                <p><strong>Immune system weakness:</strong> People are more susceptible to chest
                                    infections
                                    if
                                    their immune systems are compromised owing to old age, certain medical illnesses
                                    (including
                                    HIV/AIDS), immunosuppressive drugs, or chemotherapy.</p>
                                <p><strong>Pre-existing Lung Problems:</strong> People with pre-existing lung problems,
                                    such
                                    as
                                    cystic fibrosis, bronchiectasis, asthma, or chronic obstructive pulmonary disease
                                    (COPD),
                                    may be more susceptible to recurring chest infections.</p>
                                <p><strong>Close Contact and Transmission:</strong> Chest infections, particularly those
                                    brought
                                    on by infectious agents like the flu or TB, may be transferred by being close to
                                    persons
                                    who
                                    are sick. This is especially important in busy environments like offices, schools,
                                    or
                                    healthcare institutions.</p>
                                <p><strong>Poor Hygiene Practises:</strong> Sharing contaminated things and not covering
                                    the
                                    mouth and nose while coughing or sneezing are some examples of poor hygiene
                                    practices
                                    that
                                    might spread respiratory illnesses.</p><h4><strong>What are the noticeable changes
                                in
                                the
                                body, of the Chest Infection affected person</strong></h4>
                                <ul>
                                    <li>A person affected by a chest infection may experience various noticeable changes
                                        in
                                        the
                                        body due to the infection and the body's response to it. Here are some common
                                        changes
                                        that may occur:
                                    </li>
                                    <li><a href="https://www.draxico.com/diseases/cough">Cough</a>: A persistent cough
                                        is a
                                        hallmark symptom of chest infections. It may be dry or productive, meaning it
                                        produces
                                        phlegm or mucus. The cough is often a mechanism for the body to clear the
                                        airways of
                                        irritants or infectious agents.
                                    </li>
                                    <li>Increased Mucus Production: Chest infections can lead to increased production of
                                        mucus
                                        or phlegm in the airways. The mucus may be thick and discolored, indicating an
                                        inflammatory response and the presence of infection.
                                    </li>
                                    <li>Shortness of Breath: Inflammation and infection in the airways and lungs can
                                        cause
                                        difficulty in breathing. The person may experience shortness of breath,
                                        particularly
                                        with exertion, due to reduced lung function and obstruction of air passages.
                                    </li>
                                    <li>Chest Discomfort or Pain: Chest infections can cause discomfort or pain in the
                                        chest
                                        region. The pain may be sharp or dull and may worsen with deep breathing or
                                        coughing. It
                                        can be a result of inflammation, irritation, or strained muscles from frequent
                                        coughing.
                                    </li>
                                    <li>Fever: Many chest infections are accompanied by a fever, which is the body's
                                        response to
                                        infection. The presence of fever indicates an immune response and the body's
                                        attempt
                                        to
                                        fight off the infection.
                                    </li>
                                    <li>Fatigue and Weakness: Infections, including chest infections, can cause
                                        generalized
                                        fatigue and weakness. The immune system's response to the infection, coupled
                                        with
                                        the
                                        body's effort to heal and recover, can lead to feelings of exhaustion.
                                    </li>
                                    <li>Reduced Appetite: Loss of appetite is a common occurrence during chest
                                        infections.
                                        The
                                        infection, discomfort, and associated symptoms can lead to a decreased desire to
                                        eat,
                                        resulting in reduced food intake.
                                    </li>
                                    <li>Other Symptoms: Depending on the specific type and severity of the chest
                                        infection,
                                        other symptoms may also be present. These can include headache, muscle aches,
                                        sore
                                        throat, nasal congestion, wheezing, and in severe cases, bluish discoloration of
                                        the
                                        lips or nails due to inadequate oxygenation.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Chest Infection?</strong></h4>
                                <p>It is advisable to see a doctor for a chest infection if you experience the following
                                    symptoms or situations:</p>
                                <ol>
                                    <li><strong>Persistent or Worsening Symptoms:</strong> If your symptoms, such as
                                        cough,
                                        chest pain, shortness of breath, or fever, persist for more than a few days or
                                        worsen
                                        despite home remedies or over-the-counter medications, it is recommended to seek
                                        medical
                                        attention.
                                    </li>
                                    <li><strong>Difficulty Breathing:</strong> If you are having severe difficulty
                                        breathing,
                                        rapid breathing, or wheezing, it is important to see a doctor immediately or
                                        seek
                                        emergency medical care. These symptoms may indicate a severe chest infection or
                                        a
                                        complication that requires urgent evaluation and treatment.
                                    </li>
                                    <li><strong>High Fever:</strong> If you have a high fever, particularly if it is
                                        accompanied
                                        by other concerning symptoms, such as chest pain, confusion, or rapid heartbeat,
                                        it
                                        is
                                        advisable to consult a doctor promptly. A high fever can be an early sign of a
                                        chest
                                        infection or potential complications.
                                    </li>
                                    <li><strong>Chest Pain:</strong> If you experience persistent or severe chest pain,
                                        it
                                        is
                                        crucial to seek medical attention. Chest pain can have various causes, including
                                        infections, but it can also be a symptom of more serious conditions like a heart
                                        attack.
                                        It is important to rule out any potential chest infection causes.
                                    </li>
                                    <li><strong>Vulnerable Populations:</strong> Certain populations are more
                                        susceptible to
                                        complications from chest infections. This includes infants, young children,
                                        older
                                        adults, pregnant women, individuals with pre-existing lung conditions, weakened
                                        immune
                                        systems, or chronic medical conditions. If you or a loved one falls into these
                                        high-risk
                                        groups and develops symptoms of a chest infection, it is recommended to consult
                                        a
                                        doctor
                                        for appropriate evaluation and management.
                                    </li>
                                    <li><strong>Recurrent Infections:</strong> If you have a history of recurrent chest
                                        infections or if you frequently experience chest infections, it is advisable to
                                        see
                                        a
                                        doctor for a comprehensive evaluation. They can assess underlying causes,
                                        identify
                                        potential risk factors, and develop a management plan to prevent future
                                        infections
                                        or
                                        complications.
                                    </li>
                                    <li><strong>Concern for Contagious Infections:</strong> If you suspect you have been
                                        exposed
                                        to a contagious respiratory infection, such as tuberculosis or influenza, it is
                                        important to seek medical attention to undergo appropriate testing and receive
                                        guidance
                                        on infection control measures.
                                    </li>
                                </ol>
                                <h3><strong>Ayurvedic Say on Chest Infection?&nbsp;</strong></h3>
                                <p>Chest infections are seen holistically by the Indian traditional medical system of
                                    Ayurveda,
                                    which takes into account the harmony of the body, mind, and spirit. Chest infections
                                    are
                                    often referred to in Ayurveda as "Kasa Roga/ swasa roga," which is another name for
                                    cough or
                                    respiratory issues. Here is a quick summary of the Ayurvedic viewpoint on the
                                    pathogenesis
                                    and diagnosis of chest infections:</p>
                                <p><a href="https://www.draxico.com/appointment">Ayurvedic diagnosis of chest
                                    infections</a>
                                    entails evaluating the patient's general constitution (prakriti), present state of
                                    imbalance
                                    (vikriti), and particular symptoms. The Ayurvedic doctor looks at several things,
                                    including
                                    the kind of cough, the color and consistency of the sputum, the existence of any
                                    accompanying symptoms, and any aggravating or reliving causes. It is also possible
                                    to
                                    use
                                    tongue analysis and pulse diagnosis (Nadi Pariksha) to learn more about the body's
                                    imbalances.&nbsp;</p>
                                <p><strong>Ayurvedic pathophysiology:</strong> According to Ayurveda, chest infections
                                    are
                                    generally brought on by an imbalance in the doshas, which are the body's three
                                    primary
                                    energies (Vata, Pitta, and Kapha). Individual differences exist in the precise
                                    doshas
                                    implicated and the underlying imbalance. Here is a broad summary:</p>
                                <p><strong>Vata Imbalance:</strong> A heightened Vata dosha may cause dryness,
                                    irritability,
                                    and
                                    respiratory spasms. It might cause chest pain, a dry cough, trouble breathing, and
                                    tightness
                                    in the chest.</p>
                                <p><strong>Pitta Imbalance:</strong> When the Pitta dosha is out of balance, it may lead
                                    to
                                    respiratory system irritation, fever, and an excessive amount of mucus production.
                                    This
                                    may
                                    present as chest pain, fever, and productive cough with yellow or green sputum.</p>
                                <p>An imbalance of the Kapha dosha may cause chest congestion, heaviness, and excessive
                                    mucus
                                    production. It may manifest as chest congestion, a wet or productive cough with
                                    white or
                                    clear sputum, and a heavy sensation in the lungs.</p><h4><strong>How Ayurveda Can
                                Help
                                to
                                Control Chest Infection and its Treatment?</strong></h4>
                                <p>Ayurveda offers a holistic approach to controlling chest infections and promoting
                                    respiratory
                                    health. The treatment in Ayurveda aims to balance the doshas (Vata, Pitta, and
                                    Kapha)
                                    and
                                    strengthen the respiratory system. Here are some ways Ayurveda can help in
                                    controlling
                                    chest
                                    infections and their treatment:</p>
                                <p><strong>Herbal Remedies:</strong> Ayurveda utilizes various herbs and herbal
                                    formulations
                                    with expectorant, antimicrobial, anti-inflammatory, and immune-enhancing properties.
                                    These
                                    herbs can help in alleviating cough, reducing inflammation, and supporting the
                                    body's
                                    natural defense mechanisms.&nbsp;</p>
                                <p>Some commonly used herbs include Tulsi (Holy Basil), Yashtimadhu (Licorice), Vasaka,
                                    Haridra
                                    (Turmeric), and Pippali (Long Pepper). These herbs can be consumed in the form of
                                    teas,
                                    decoctions, powders, or capsules under the guidance of an Ayurvedic
                                    practitioner.</p>
                                <p><strong>Ayurvedic Dietary Recommendations:</strong> A balanced and appropriate diet
                                    plays
                                    a
                                    crucial role in Ayurvedic treatment for chest infections. Foods that are warm,
                                    light,
                                    and
                                    easily digestible are generally recommended. Warm herbal teas, soups, steamed
                                    vegetables,
                                    freshly cooked grains, and spices such as ginger, garlic, and black pepper can be
                                    beneficial. Avoiding colds, heavy chest infections, and greasy foods, as well as
                                    processed
                                    and junk foods, is advisable.</p>
                                <p><strong>Lifestyle Modifications:</strong> Ayurveda emphasizes lifestyle modifications
                                    to
                                    support respiratory health. It is recommended to avoid exposure to cold and damp
                                    environments, quit smoking, and minimize exposure to environmental pollutants.
                                    Maintaining a
                                    regular daily routine, including adequate rest, stress management techniques, and
                                    moderate
                                    exercise, can also help strengthen the immune system and enhance overall well-being.
                                </p>
                                <p><strong>Pranayama and Yoga:</strong> Breathing exercises (pranayama) and specific
                                    yoga
                                    asanas
                                    can be beneficial for strengthening the respiratory system and improving lung
                                    capacity.&nbsp;
                                </p>
                                <p>Practices like Anulom Vilom (alternate nostril breathing), Kapalabhati (skull-shining
                                    breath), and Bhramari (bee breath) can help clear the airways, reduce congestion,
                                    and
                                    enhance lung function. Yoga asanas like Bhujangasana (cobra pose), Matsyasana (fish
                                    pose),
                                    and Dhanurasana (bow pose) can also aid in promoting respiratory health.</p>
                                <p><strong>Panchakarma Therapies:</strong> In severe or chronic cases of chest
                                    infections,
                                    <a href="https://www.draxico.com/panchakarma-treatment/">Ayurvedic therapies</a>
                                    like <a
                                        href="https://www.draxico.com/panchakarma-treatment/nasyam">Nasya</a> (nasal
                                    administration of medicated oils),
                                    <a href="https://www.draxico.com/panchakarma-treatment/vamana">Vamana</a>
                                    (therapeutic
                                    vomiting), or <a
                                        href="https://www.draxico.com/panchakarma-treatment/virechna">Virechana</a>
                                    (therapeutic purgation) may be recommended to remove toxins, restore
                                    doshic balance, and strengthen the respiratory system. These therapies should be
                                    performed
                                    under the guidance of a qualified Ayurvedic practitioner.</p><h4><strong>Ayurvedic
                                Home
                                Remedies for Chest Infection</strong></h4>
                                <p>Ayurveda offers several home remedies that can help alleviate symptoms and support
                                    recovery
                                    from chest infections. Here are a few Ayurvedic home remedies for chest
                                    infections:</p>
                                <ol>
                                    <li>Tulsi (Holy Basil) Tea: Tulsi has antimicrobial and expectorant properties,
                                        making
                                        it
                                        beneficial for chest infections. Boil a few fresh tulsi leaves in water for a
                                        few
                                        minutes, strain, and drink the tea. You can add a teaspoon of honey and a
                                        squeeze of
                                        lemon for added benefits. Drink this tea 2-3 times a day.
                                    </li>
                                    <li>Ginger and Honey: Ginger has anti-inflammatory and antimicrobial properties,
                                        while
                                        honey
                                        soothes the throat and acts as an expectorant. Mix one teaspoon of freshly
                                        grated
                                        ginger
                                        with one teaspoon of honey. Consume this mixture 2-3 times a day to reduce cough
                                        and
                                        congestion.
                                    </li>
                                    <li>Turmeric Milk: Turmeric has anti-inflammatory and antimicrobial properties that
                                        can
                                        help
                                        with chest infections. Mix half a teaspoon of turmeric powder with a glass of
                                        warm
                                        milk.
                                        Drink this before bedtime to soothe the respiratory system and promote healing.
                                    </li>
                                    <li>Steam Inhalation: Steam inhalation can help relieve congestion and open up the
                                        airways.
                                        Boil water in a pot and add a few drops of eucalyptus oil or a handful of fresh
                                        mint
                                        leaves. Cover your head with a towel and inhale the steam for about 10 minutes.
                                        Be
                                        cautious not to burn yourself.
                                    </li>
                                    <li>Saltwater Gargle: Gargling with warm salt water can help reduce throat
                                        irritation
                                        and
                                        kill bacteria. Mix half a teaspoon of salt in a glass of warm water and gargle
                                        for
                                        30
                                        seconds. Repeat several times a day to alleviate sore throat and reduce
                                        inflammation.
                                    </li>
                                    <li>Licorice (Yashtimadhu) Tea: Licorice has expectorant properties and can help
                                        soothe
                                        the
                                        respiratory tract. Boil a teaspoon of licorice root powder in a cup of water for
                                        a
                                        few
                                        minutes. Strain and drink the tea 2-3 times a day.
                                    </li>
                                </ol>
                                <h4><strong>What to Eat and Not</strong></h4>
                                <p>A well-balanced diet helps cure a chest infection. General dietary guidelines:</p>
                                <h4>
                                    <strong>What to eat:</strong></h4>
                                <ul>
                                    <li><strong>Warm and Nourishing Foods:</strong> Eat freshly prepared, easy-to-digest
                                        meals.
                                        Choose soups, stews, cooked veggies, and healthful grains like rice and quinoa.
                                    </li>
                                    <li><strong>Ginger and turmeric</strong>: Anti-inflammatory and antibacterial. Make
                                        ginger
                                        tea or turmeric milk with them.
                                    </li>
                                    <li><strong>Garlic and onion</strong>: Antimicrobial garlic and onion may combat
                                        illnesses.
                                        For immunity, eat them.
                                    </li>
                                    <li><strong>Fresh Fruits and Vegetables</strong>: Eat a variety to acquire vitamins,
                                        minerals, and antioxidants. Vitamin C in oranges and lemons boosts immunity.
                                    </li>
                                    <li>Drink chamomile, peppermint, or licorice tea. These calm the throat, decrease
                                        inflammation and improve breathing.
                                    </li>
                                    <li><strong>Warm Liquids</strong>: Drink warm herbal teas, water, or lemon water to
                                        stay
                                        hydrated. Thins mucus and relieves congestion.
                                    </li>
                                    <li><strong>Omega-3 Fatty Acids</strong>: Eat salmon, mackerel, flaxseeds, chia
                                        seeds,
                                        and
                                        walnuts. Anti-inflammatory omega-3 fatty acids enhance respiratory health.
                                    </li>
                                </ul>
                                <h4><strong>Do not:</strong></h4>
                                <ul>
                                    <li>Cold and Refrigerated meals: Cold and refrigerated meals may worsen congestion
                                        and
                                        mucous. Avoid cold foods and beverages.
                                    </li>
                                    <li>Avoid heavy and fried meals, which are tougher to digest and may cause pain.
                                        Deep-fried
                                        appetizers, rich gravies, and fatty meats are examples.
                                    </li>
                                    <li>Avoid spicy meals since they irritate the throat and make coughing worse. Spicy
                                        curries,
                                        chili peppers, and excessive cayenne pepper or chili powder usage are examples.
                                    </li>
                                    <li>Avoid processed and sugary meals. These foods may lower immunity and cause
                                        inflammation.
                                    </li>
                                    <li>Dairy products may promote mucus production and congestion. Avoid dairy if it
                                        worsens
                                        your chest illness.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Medicine for Chest Infection?</strong></h4>
                                <p>Ayurveda prescribes chest infection treatment based on symptoms, doshic imbalance,
                                    and
                                    general health. An expert Ayurvedic practitioner may examine your health and offer
                                    the
                                    best
                                    treatments. Ayurvedic chest infection treatments include these:</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/sitopaladi-churna"><strong>Sitopaladi
                                        Churna</strong></a>: This herbal powder contains plants, Vamshalochana (bamboo
                                        manna),
                                        and Pippali (Long Pepper). It treats coughs, congestion, and respiratory
                                        infections.
                                        Sitopaladi Churna reduces throat irritation and promotes expectoration.
                                    </li>
                                    <li><strong>Talisadi Churna:</strong> This herbal powder contains Talispatra (Abies
                                        webbiana), Pippali (Long Pepper), Ela (Cardamom), and other plants. It treats
                                        cough,
                                        bronchitis, and congestion. Talisadi Churna loosens mucous, improves breathing,
                                        and
                                        supports respiratory health.
                                    </li>
                                    <li><strong>Licorice root powder</strong>: Licorice root powder (Yashtimadhu) has
                                        expectorant, anti-inflammatory, and immune-boosting effects. It reduces lung
                                        inflammation, cough, and respiratory system irritation. Yashtimadhu Churna may
                                        be
                                        swallowed or gargled with warm water.
                                    </li>
                                    <li><strong>Vasarishta:</strong> Ayurvedic Vasarishta contains Vasaka (Adhatoda
                                        vasica)
                                        and
                                        other herbs. It treats asthma and bronchitis. Vasarishta relieves cough,
                                        congestion,
                                        and
                                        lung function.
                                    </li>
                                    <li><strong>Kasthuri Bhairava Rasa:</strong> Ayurvedic mineral-based Kasthuri
                                        Bhairava
                                        Rasa
                                        blends herbs and minerals. It treats respiratory infections, fever, and cough.
                                        This
                                        drug
                                        reduces congestion, and irritation, and promotes respiratory health.
                                    </li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>A chest infection is an illness that affects both your lungs and your lower big
                                    airways
                                    (bronchi). The most typical chest infections include bronchitis and pneumonitis.
                                    Typically,
                                    a viral infection is what causes bronchitis. Typically, a bacterial infection causes
                                    pneumonia. A hospital stay may be required if the pneumonia is severe. Chest
                                    Infection
                                    is
                                    curable with Dr. Axico Ayurvedic doctor consultation. Moreover, you can buy
                                    Ayurvedic
                                    medicine online or offline from official websites or stores.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>What are common respiratory infections in
                                            children?</strong></h4>
                                        <ul>
                                            <li>The Common Cold Chest Pain: it is a viral upper respiratory tract illness that
                                                causes
                                                runny nose, congestion, cough, and slight fever. Children's respiratory
                                                illnesses
                                                include it.
                                            </li>
                                            <li>Influenza (flu): Influenza is a highly infectious respiratory virus. High
                                                temperature,
                                                sore throat, cough, muscular pains, lethargy, vomiting, and diarrhoea are
                                                symptoms.
                                            </li>
                                            <li>RSV infection: RSV is a frequent viral illness in newborns and young children. A
                                                runny
                                                nose, cough, and fever are common symptoms, but they may also cause wheezing and
                                                breathing problems.
                                            </li>
                                            <li>Croup: A viral illness that swells the upper airway producing a barking cough,
                                                hoarse
                                                voice, and trouble breathing. It's most frequent among 6- to 3-year-olds.
                                            </li>
                                            <li>Bronchiolitis: Infants and young children are most affected by RSV-induced
                                                bronchiolitis. Coughing, wheezing, fast breathing, and trouble feeding result
                                                from
                                                lung
                                                inflammation and obstruction.
                                            </li>
                                            <li>Bacteria or viruses cause pneumonia, a lung illness. It may cause fever, cough,
                                                chest
                                                discomfort, fast or difficult breathing, and weariness. Infants and small
                                                toddlers
                                                may
                                                have different chest infection symptoms than adults with pneumonia.
                                            </li>
                                            <li>Strep throat is caused by group A Streptococcus bacterium. It causes a painful
                                                throat,
                                                trouble swallowing, fever, headache, and rash. Strep throat is more frequent
                                                among
                                                schoolchildren.
                                            </li>
                                        </ul>
                                        <h4><strong>Is the flu an upper respiratory infection?</strong></h4>
                                        <p>Yes, the flu, also known as influenza, is considered an upper respiratory infection.
                                            It
                                            primarily affects the respiratory system, specifically the nose, throat, and lungs.
                                            The
                                            flu
                                            is caused by the influenza virus and can cause symptoms such as sore throat, runny
                                            or
                                            stuffy
                                            nose, cough, congestion, fever, muscle aches, fatigue, and sometimes
                                            gastrointestinal
                                            symptoms like vomiting and diarrhea.&nbsp;</p>
                                        <p>While the flu primarily affects the upper respiratory tract, it can sometimes
                                            progress to
                                            lower respiratory complications like bronchitis or pneumonia, especially in young
                                            children
                                            or individuals with weakened immune systems.</p>
                                        <h4><strong>Are chest infections contagious?</strong></h4>
                                        <p>Chest infections can be contagious depending on the underlying cause. If the chest
                                            infection
                                            is caused by a virus or bacteria that can spread from person to person, then it can
                                            be
                                            contagious. For example:</p>
                                        <ol>
                                            <li>Viral chest infections: Respiratory viruses like the common cold virus,
                                                influenza
                                                virus,
                                                or respiratory syncytial virus (RSV) can cause chest infections and are highly
                                                contagious. They can spread through respiratory droplets when an infected person
                                                coughs,
                                                sneezes, or talks. Close contact with an infected individual or touching
                                                contaminated
                                                surfaces can also lead to transmission.
                                            </li>
                                            <li>Viral or Bacterial chest infections: Some bacterial infections, such as
                                                streptococcal
                                                pneumonia or pertussis (whooping cough), can cause chest infections and are also
                                                contagious. Bacterial infections are often transmitted through respiratory
                                                droplets
                                                or
                                                direct contact with respiratory secretions.
                                            </li>
                                        </ol>
                                        <h4><strong>Ayurvedic Home Remedies for Chest Infection?</strong></h4>
                                        <ol>
                                            <li>Tulsi (Holy Basil) Tea: Tulsi has antimicrobial and expectorant properties,
                                                making
                                                it
                                                beneficial for chest infections. Boil a few fresh tulsi leaves in water for a
                                                few
                                                minutes, strain, and drink the tea. You can add a teaspoon of honey and a
                                                squeeze of
                                                lemon for added benefits. Drink this tea 2-3 times a day.
                                            </li>
                                            <li>Ginger and Honey: Ginger has anti-inflammatory and antimicrobial properties,
                                                while
                                                honey
                                                soothes the throat and acts as an expectorant. Mix one teaspoon of freshly
                                                grated
                                                ginger
                                                with one teaspoon of honey. Consume this mixture 2-3 times a day to reduce cough
                                                and
                                                congestion.
                                            </li>
                                            <li>Turmeric Milk: Turmeric has anti-inflammatory and antimicrobial properties that
                                                can
                                                help
                                                with chest infections. Mix half a teaspoon of turmeric powder with a glass of
                                                warm
                                                milk.
                                                Drink this before bedtime to soothe the respiratory system and promote healing.
                                            </li>
                                            <li>Steam Inhalation: Steam inhalation can help relieve congestion and open up the
                                                airways.
                                                Boil water in a pot and add a few drops of eucalyptus oil or a handful of fresh
                                                mint
                                                leaves. Cover your head with a towel and inhale the steam for about 10 minutes.
                                                Be
                                                cautious not to burn yourself.
                                            </li>
                                            <li>Saltwater Gargle: Gargling with warm saltwater can help reduce throat irritation
                                                and
                                                kill bacteria. Mix half a teaspoon of salt in a glass of warm water and gargle
                                                for
                                                30
                                                seconds. Repeat several times a day to alleviate sore throat and reduce
                                                inflammation.
                                            </li>
                                            <li>Licorice (Yashtimadhu) Tea: Licorice has expectorant properties and can help
                                                soothe
                                                the
                                                respiratory tract. Boil a teaspoon of licorice root powder in a cup of water for
                                                a
                                                few
                                                minutes. Strain and drink the tea 2-3 times a day.
                                            </li>
                                        </ol>
                                        <h4><strong>What are viral chest infection symptoms?</strong></h4>
                                        <ol>
                                            <li>Cough: A persistent cough is a hallmark symptom of a viral chest infection. It
                                                can
                                                be
                                                dry or produce phlegm and may be accompanied by wheezing or chest tightness.
                                            </li>
                                            <li>Sore throat: Many viral chest infections can start with a sore throat or a
                                                scratchy,
                                                irritated feeling in the throat.
                                            </li>
                                            <li>Congestion: Nasal congestion, runny nose, or a blocked nose are common symptoms.
                                                This
                                                can make breathing difficult, especially in young children.
                                            </li>
                                            <li>Shortness of breath: Some viral chest infections can cause shortness of breath,
                                                particularly in severe cases or in individuals with pre-existing respiratory
                                                conditions.
                                            </li>
                                            <li>Fatigue: Viral infections often lead to fatigue and general feelings of weakness
                                                or
                                                tiredness.
                                            </li>
                                            <li>Fever: Fever is a common symptom of viral infections, including chest
                                                infections. It
                                                may
                                                range from a mild chest infection to a high grade, depending on the virus and
                                                the
                                                individual's response.
                                            </li>
                                            <li>Body aches: Muscle aches and body pains are common with many viral infections,
                                                including
                                                those affecting the chest.
                                            </li>
                                            <li>Headache: Headaches can occur as a result of the viral infection or as a symptom
                                                of
                                                sinus congestion.
                                            </li>
                                            <li>Sputum production: Some viral chest infections can lead to the production of
                                                phlegm
                                                or
                                                mucus that is coughed up.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'chronic-kidney-diseases'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Chronic Kidney Diseases: Pain Area, Causes & Treatment | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>A disorder known as chronic kidney disease (CKD) affects millions of people all over
                                    the
                                    world. In fact, according to the World Health Organization (WHO), CKD is responsible
                                    for
                                    approximately 1.5 million deaths per year.&nbsp; Despite its prevalence, many people
                                    are
                                    not
                                    aware of what CKD is, what causes kidney failure, and how it can be
                                    managed.&nbsp;</p>
                                <p>In this blog, we will explore these topics and provide you with a comprehensive
                                    understanding
                                    of CKD. To end dialysis treatment for kidney stones, Ayurveda provides holistic
                                    treatment.
                                    Book your&nbsp;<a href="https://www.draxico.com/">Ayurvedic doctor consultation
                                        online</a>
                                    with Dr. Axico.&nbsp;</p>
                                <h2> What is chronic kidney disease?
                                </h2>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chronic-kidney-diseases/Image.webp"
                                    >
                                </div>
                                <p>Chronic kidney disease (CKD) is a condition in which the kidneys become damaged and
                                    are
                                    unable to filter waste products from the blood effectively. Over time, the kidneys
                                    may
                                    become so damaged that they can no longer perform their essential functions, such as
                                    removing excess fluids and waste products from the body. CKD is a progressive
                                    disease
                                    that
                                    often develops over several years or even decades.
                                </p>
                                <h3>Symptoms of Chronic Kidney Disease
                                </h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/chronic-kidney-diseases/Image-2.webp"
                                    >
                                </div>
                                <p>In the early stages of CKD, many people may not experience any kidney failure
                                    symptoms.
                                    However, as the disease progresses, the following signs of kidney failure may
                                    occur:</p>
                                <ul>
                                    <li>Fatigue and weakness</li>
                                    <li>Difficulty concentrating</li>
                                    <li>Loss of appetite</li>
                                    <li>Swelling of the hands and feet</li>
                                    <li>Increased need to urinate</li>
                                    <li>Changes in the colour and frequency of urine</li>
                                    <li>High blood pressure</li>
                                    <li>Itchy skin</li>
                                    <li>Nausea and vomiting</li>
                                    <li>kidney problems symptoms back pain</li>
                                    <li>Causes of Chronic Kidney Disease</li>
                                </ul>
                                <p>&nbsp;If you are facing any of the above symptoms you can take a&nbsp;<a
                                    href="https://www.draxico.com/appointment">free online doctor consultation</a> with
                                    Dr
                                    Axico.</p>
                                <h3>CKD can be caused by a variety of factors, including:
                                </h3>
                                <ol>
                                    <li>Diabetes: Diabetes is the leading cause of CKD. The&nbsp;<a
                                        href="https://training.seer.cancer.gov/anatomy/cardiovascular/blood/classification.html"
                                        rel="nofollow">blood
                                        arteries</a> in the kidneys can become damaged by high blood sugar levels,
                                        making it
                                        more difficult for the kidneys to filter waste from the blood.
                                    </li>
                                    <li>High blood pressure: High blood pressure can damage the blood vessels in the
                                        kidneys,
                                        reducing their ability to filter waste products effectively.
                                    </li>
                                    <li>Infections: Infections can cause inflammation and damage to the kidneys.</li>
                                    <li><a href="https://www.kidney.org/atoz/content/glomerul"
                                           rel="nofollow">Glomerulonephritis</a>: This is a
                                        condition in which the small blood vessels in the kidneys become inflamed and
                                        damaged.
                                    </li>
                                    <li>Polycystic kidney disease: This is an inherited condition in which cysts form in
                                        the
                                        kidneys, causing damage over time.
                                    </li>
                                </ol>
                                <h2> Diagnosis of Chronic Kidney Disease
                                </h2>
                                <p>If you are experiencing any of the symptoms listed above, it is essential to see a
                                    doctor
                                    for
                                    a diagnosis. Your doctor may perform the following tests:</p>
                                <ul>
                                    <li>Blood tests: Blood tests can measure the levels of waste products, such as
                                        creatinine,
                                        in your blood. Elevated levels may indicate kidney damage.
                                    </li>
                                    <li>Urine tests: Urine tests can measure the amount of protein and other waste
                                        products
                                        in
                                        your urine. Elevated levels may indicate kidney damage.
                                    </li>
                                    <li>Imaging tests: Imaging tests, such as an ultrasound or CT scan, can provide a
                                        picture of
                                        your kidneys and help your doctor identify any abnormalities.
                                    </li>
                                </ul>
                                <p>IF you are hesitating to visit a doctor you can also take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/kidney-assessment">Free Self Health
                                    Assessment
                                    test for kidney online</a> at Dr Axico.</p>
                                <h3>Treatment of Chronic Kidney Disease
                                </h3>
                                <p>The treatment of CKD depends on the underlying cause of the disease and the severity
                                    of
                                    the
                                    damage to the kidneys. In some cases, lifestyle changes may be sufficient to slow
                                    the
                                    progression of the disease. These may include:</p>
                                <ul>
                                    <li>Eating a healthy diet that is low in sodium and rich in fruits, vegetables, and
                                        whole
                                        grains
                                    </li>
                                    <li>Exercising regularly</li>
                                    <li>Quitting smoking</li>
                                    <li>Limiting alcohol consumption</li>
                                    <li>Managing diabetes and high blood pressure</li>
                                </ul>
                                <p>In more severe cases of CKD, medication or dialysis may be necessary to manage the
                                    kidney
                                    infection symptoms of the disease. Dialysis is a medical procedure that uses a
                                    machine
                                    to
                                    filter waste products from the blood when the kidneys are no longer able to do so
                                    effectively. In some cases, a kidney transplant may be necessary.</p>
                                <h2>Prevention of Chronic Kidney Disease
                                </h2>
                                <p>There are several steps you can take to reduce your risk of developing CKD:</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/medicinal-kits/diabetes-manager">Manage
                                        diabetes</a>
                                        and high blood pressure: These conditions are the leading causes of CKD, so it
                                        is
                                        essential to manage them properly.
                                    </li>
                                    <li>Quit smoking: Smoking can cause damage to the blood vessels in the kidneys, so
                                        quitting
                                        smoking can help reduce the risk of CKD.
                                    </li>
                                    <li>Maintain a healthy weight: Being overweight or obese can increase the risk of
                                        developing
                                        CKD, so maintaining a healthy weight through a healthy diet and exercise can
                                        help
                                        reduce
                                        the risk. You can check with the&nbsp;<a
                                            href="https://www.draxico.com/bmi-calculator">Body
                                            Mass Index checker online</a> if you fall in obese or overweight category..
                                    </li>
                                    <li>Stay hydrated: Drinking enough water can help the kidneys function properly and
                                        reduce
                                        the risk of developing kidney stones or kidney stone symptoms, which can lead to
                                        CKD.
                                        Know&nbsp;<a href="https://www.draxico.com/diseases/dehydration">what
                                            Dehydration
                                            means</a>.
                                    </li>
                                    <li>Avoid over-the-counter painkillers: Some over-the-counter painkillers, such as
                                        ibuprofen
                                        and naproxen, can damage the kidneys if taken in high doses over a long period.
                                    </li>
                                </ul>
                                <p>Get regular check-ups: Regular check-ups with your doctor can help identify any
                                    potential
                                    health problems, including CKD, early on. You can take a&nbsp;<a
                                        href="https://www.draxico.com/appointment">free online doctor checkup</a> with
                                    Dr
                                    Axico.
                                </p>
                                <h3> Living with Chronic Kidney Disease
                                </h3>
                                <p>Living with CKD can be challenging, but there are several things you can do to manage
                                    the
                                    condition and maintain a good quality of life:</p>
                                <ul>
                                    <li>Follow your treatment plan: If you have been diagnosed with CKD, it is important
                                        to
                                        follow your doctor's treatment plan to manage the symptoms and slow the
                                        progression
                                        of
                                        the disease.
                                    </li>
                                    <li>Make lifestyle changes: Making lifestyle changes, such as eating a healthy diet
                                        and
                                        exercising regularly, can help improve your overall health and reduce the risk
                                        of
                                        complications associated with CKD.
                                    </li>
                                    <li>Manage stress: Stress can harm your health, so it is important to find ways to
                                        manage
                                        stress, such as meditation or yoga.
                                    </li>
                                    <li>Join a support group: Joining a support group for people with CKD can provide
                                        emotional
                                        support and practical advice on living with the condition.
                                    </li>
                                </ul>
                                <h2>When to see a Doctor for CKD?
                                </h2>
                                <p>If you have been diagnosed with chronic kidney disease (CKD), it's important to work
                                    closely
                                    with your healthcare provider and follow your treatment plan to manage your
                                    condition.
                                    You
                                    should see a doctor for CKD if:</p>
                                <ul>
                                    <li>You experience new or worsening symptoms such as fatigue, swelling, shortness of
                                        breath,
                                        or changes in urination.
                                    </li>
                                    <li>Your blood pressure or blood sugar levels are difficult to control.</li>
                                    <li>You have signs of an infection or fever.</li>
                                    <li>You are experiencing side effects from medications used to treat CKD.</li>
                                    <li>You have questions or concerns about your CKD treatment plan or management of
                                        your
                                        condition.
                                    </li>
                                </ul>
                                <p>You can book an&nbsp;<a href="https://www.draxico.com">online free doctor
                                    appointment</a>
                                    with Dr Axico Experienced doctors.</p>
                                <h2>AYURVEDIC SAY ON CKD
                                </h2>
                                <p>According to Ayurveda, Chronic Renal Failure is a disease of Mutravaha Srotas. Though
                                    all&nbsp;<a
                                        href="https://www.draxico.com/blogs/prakriti-and-its-ayurvedic-importance-for-health-dr-axico">three
                                        Doshas</a> as well as all the Dushyas are involved in the disease, Kapha is
                                    responsible
                                    for
                                    blocking micro-vessels and developing microangiopathy.&nbsp;</p>
                                <p>The kidney's structural deterioration is brought on by vata. According to Ayurvedic
                                    principles of management of the disease, tissue damage can be prevented and repaired
                                    by
                                    Rasayana drugs because they can improve the qualities of tissues and hence increase
                                    the
                                    resistance of the tissues.&nbsp;</p>
                                <p>By inducing Rakta bhar vridhi, it improves muttrakrichha and works as shothaghna,
                                    increasing
                                    urine filtration. On the other hand, the blockage can be removed by Lekhana drugs
                                    having
                                    a
                                    scraping effect on blocked channels.</p>
                                <p>&nbsp;Chronic kidney disease is not treated by focusing on an organ. The tridosha,
                                    saptadhatu, and agni vichara are considered. During the first phase of the treatment
                                    utmost
                                    care has to be given in controlling the blood sugar level as well as the blood
                                    pressure
                                    level if associated.&nbsp;</p>
                                <p>The line of treatment for the management of chronic kidney disease as per Ayurveda is
                                    virechana, swedana, mutrapravartaka, raktaprasadaka, agni deepana and sarva dhathu
                                    poshaka,
                                    also with strict pathya- apathyas. Under the heading of Rasayana Chikitsa, which
                                    offers
                                    a
                                    chance to regenerate nephrons in chronic kidney diseases, Ayurveda also promotes
                                    regenerative treatment.</p>
                                <h3>Permanent and No Operation Treatment for CKD in Ayurveda?
                                </h3>
                                <p>Chronic kidney disease (CKD) is a serious condition in which the kidneys gradually
                                    lose
                                    their
                                    function over time. Ayurveda, a traditional system of medicine from India, offers a
                                    holistic
                                    approach to the management of CKD that includes diet and lifestyle modifications,
                                    herbal
                                    remedies, and other therapies. Here are some of the Ayurvedic approaches to the
                                    management
                                    of CKD:</p>
                                <ol>
                                    <li>Diet: A low-protein, low-sodium diet is recommended for people with CKD. Foods
                                        that
                                        are
                                        high in potassium and phosphorus should also be limited. Ayurvedic practitioners
                                        may
                                        recommend a diet that includes whole grains, fruits, vegetables, and legumes. In
                                        addition, they may recommend specific herbs and spices, such as cumin,
                                        coriander,
                                        and
                                        fennel, which are believed to help support kidney function.
                                    </li>
                                    <li>Herbal remedies: Ayurvedic herbs such as&nbsp;<a
                                        href="https://www.draxico.com/products/punarnavadi-guggulu">Punarnava</a>,
                                        Gokshura,&nbsp;<a
                                            href="https://www.draxico.com/products/varunadi-kwath">Varuna</a>, and
                                        Shatavari are
                                        believed to have properties that can help support kidney function and reduce
                                        inflammation.&nbsp;
                                    </li>
                                    <li>These herbs may be taken in the form of capsules, teas, or tinctures.</li>
                                    <li>Lifestyle modifications: Regular exercise, stress reduction techniques, and
                                        getting
                                        enough sleep is important for maintaining kidney health. Ayurvedic practitioners
                                        may
                                        also recommend specific yoga poses or pranayama (breathing exercises) that can
                                        help
                                        support kidney function.
                                    </li>
                                    <li>Panchakarma: <a href="https://www.draxico.com/panchakarma-treatment/">
                                        Panchakarma </a>
                                        is a series of Ayurvedic therapies that are designed to
                                        detoxify the body and promote overall health. These therapies may include
                                        massages,
                                        enemas, and other treatments that are tailored to the individual's specific
                                        needs.
                                    </li>
                                    <li>Acupuncture: Acupuncture, a traditional Chinese medicine practice, is sometimes
                                        used
                                        in
                                        conjunction with Ayurvedic treatments for CKD. Acupuncture is believed to
                                        stimulate
                                        the
                                        flow of energy in the body and promote healing.
                                    </li>
                                </ol>
                                <h3>Ayurvedic Remedies for CKD
                                </h3>
                                <ol>
                                    <li>Coriander seeds: Coriander seeds are believed to have a cooling effect on the
                                        body
                                        and
                                        can help reduce inflammation in the kidneys. Soak 1-2 tablespoons of coriander
                                        seeds
                                        in
                                        water overnight, strain the water in the morning, and drink it on an empty
                                        stomach.
                                    </li>
                                    <li>Coconut water: Coconut water is a natural diuretic and is believed to help flush
                                        out
                                        toxins and waste products from the body. Drinking coconut water regularly may
                                        help
                                        improve kidney function and reduce inflammation.
                                    </li>
                                    <li>Turmeric: Turmeric is a powerful anti-inflammatory spice that is commonly used
                                        as
                                        kidney
                                        ayurvedic medicine. Adding turmeric to your diet or taking turmeric supplements
                                        may
                                        help
                                        reduce inflammation in the kidneys and improve overall kidney function.
                                    </li>
                                    <li>Amla: Amla, also known as Indian gooseberry, is a rich source of vitamin C and
                                        antioxidants. It is believed to have diuretic and anti-inflammatory properties
                                        that
                                        can
                                        help improve kidney function. Drinking amla juice or taking amla supplements may
                                        be
                                        beneficial for people with kidney disease.
                                    </li>
                                    <li>Ginger: Ginger is another powerful anti-inflammatory herb that is commonly used
                                        in
                                        Ayurvedic medicine. Adding ginger to your diet or taking ginger supplements may
                                        help
                                        reduce inflammation in the kidneys and improve overall kidney function.
                                    </li>
                                </ol>
                                <h3> What to Eat and Not
                                </h3>
                                <p>It is important to follow a kidney-friendly diet to help manage your symptoms and
                                    slow
                                    the
                                    progression of the disease.
                                </p>
                                <h4>Foods to Eat:
                                </h4>
                                <ul>
                                    <li>Low-phosphorus foods: Phosphorus is a mineral that can build up in the blood of
                                        people
                                        with CKD, leading to bone and heart problems. Choose low-phosphorus foods such
                                        as
                                        fresh
                                        fruits, vegetables, grains, and lean protein sources like chicken and fish.
                                    </li>
                                    <li>Low-potassium foods: Potassium is a mineral that is essential for nerve and
                                        muscle
                                        function, but too much can be harmful to people with CKD. Choose low-potassium
                                        foods
                                        like apples, berries, cabbage, green beans, and white rice.
                                    </li>
                                    <li>Low-sodium foods: Sodium can increase blood pressure and cause fluid retention,
                                        which
                                        can be harmful for people with CKD. Choose low-sodium foods like fresh fruits
                                        and
                                        vegetables, whole grains, and lean protein sources like chicken and fish.
                                    </li>
                                    <li>Healthy fats: Choose healthy fats like olive oil, avocados, nuts, and seeds to
                                        help
                                        reduce inflammation and protect your heart health.
                                    </li>
                                    <li>High-quality protein: Choose high-quality protein sources like eggs, chicken,
                                        fish,
                                        and
                                        low-fat dairy products to help maintain muscle mass and support kidney function.
                                    </li>
                                </ul>
                                <h4> Foods to Avoid:
                                </h4>
                                <ul>
                                    <li>High-phosphorus foods: Avoid high-phosphorus foods like processed meats, cheese,
                                        milk,
                                        and yogurt, as well as whole grains and bran cereals.
                                    </li>
                                    <li>High-potassium foods: Avoid high-potassium foods like bananas, oranges,
                                        potatoes,
                                        tomatoes, and spinach.
                                    </li>
                                    <li>Processed foods: Processed foods are often high in sodium, phosphorus, and other
                                        harmful
                                        additives. Avoid processed foods like canned soups, frozen meals, and snack
                                        foods.
                                    </li>
                                    <li>Saturated and trans fats: Avoid saturated and trans fats found in red meat,
                                        fried
                                        foods,
                                        and processed foods, as they can increase inflammation and harm your heart
                                        health.
                                    </li>
                                    <li>Sugary drinks: Sugary drinks like soda and sports drinks can increase blood
                                        sugar
                                        levels
                                        and lead to weight gain, which can be harmful for people with CKD.
                                    </li>
                                </ul>
                                <h3>Ayurvedic formulations and herbs used in CKD</h3>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/goksharadi-guggulu">Gokshuradi
                                        guggul</a>
                                    </li>
                                    <li>Punarnava kwath</li>
                                    <li><a href="https://www.draxico.com/products/varunadi-kwath">Varunadi</a>
                                        kwath/kashayam
                                    </li>
                                    <li><a href="https://www.draxico.com/products/chandraprabha-vati">Chandraprabha
                                        Vati</a>
                                    </li>
                                    <li>Dashamoola Kwath</li>
                                    <li>Punarnava (Boerhavia diffusa)</li>
                                    <li>Gokshura (Tribulus terrestris)</li>
                                    <li><a href="https://www.draxico.com/products/shilajitvadi-vati">Shilajit</a>
                                        (Asphaltum
                                        Punjabianum)
                                    </li>
                                    <li>Bhumyamlaki (Phyllanthus niruri)</li>
                                    <li>Pashanabheda (Bergenia ligulata)</li>
                                    <li>Daruharidra (Berberis aristata)</li>
                                    <li>Yava Kshara (Potassium carbonate)</li>
                                    <li>Sariva (Hemidesmus indicus)</li>
                                    <li>Kutki (Picrorhiza kurroa)</li>
                                    <li>Shankhpushpi (Convolvulus pluricaulis)</li>
                                </ul>
                                <p><strong>Note:</strong> It's important to consult with a qualified Ayurvedic
                                    practitioner
                                    before using any herbs or remedies to ensure they are safe and effective for your
                                    specific
                                    condition.</p>
                                <h4> A Short Note from Dr.Axico</h4>
                                <p>Physical or occupational therapy are two options for treating CKD. Your Ayurvedic
                                    medical
                                    expert will assess your symptoms and recommend the best course of kidney Ayurvedic
                                    treatment
                                    for you. With Dr. Axico's assistance, the majority of patients may successfully
                                    control
                                    their CKD and carry on with their chosen preferences. Additionally, they buy
                                    Ayurvedic
                                    medicine online along with Ayurvedic doctor consultations.
                                </p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <h4> What are the 3 early warning signs of kidney disease?
                                        </h4>
                                        <p>The three early warning signs of kidney disease are changes in urination, swelling,
                                            and
                                            fatigue. These symptoms can also be caused by other conditions, so it's important to
                                            see
                                            a
                                            healthcare professional for an accurate diagnosis and appropriate treatment. Regular
                                            kidney
                                            function tests can also help detect kidney disease in its early CKD stages.</p>
                                        <h4> Can chronic kidney disease be cured?
                                        </h4>
                                        <p>Chronic kidney disease (CKD) is a progressive condition that cannot be cured.
                                            However, it
                                            can
                                            be managed and slowed down with appropriate treatment, lifestyle changes, and
                                            management
                                            of
                                            underlying health conditions. In some cases, kidney function may improve with early
                                            detection and intervention, but once the kidney damage is irreversible, the goal of
                                            treatment is to no more dialysis or delay the need for dialysis or a kidney
                                            transplant.
                                        </p>
                                        <h4>How long can a CKD patient live?
                                        </h4>
                                        <p>The life expectancy of a person with chronic kidney disease (CKD) varies depending on
                                            several
                                            factors, including the stage of the disease, age, overall health, and access to
                                            treatment.
                                            In general, people with CKD have a higher risk of premature death compared to those
                                            without
                                            the condition. However, with appropriate treatment, lifestyle changes, and
                                            management of
                                            underlying health conditions, people with CKD can live for many years. In the later
                                            stages
                                            of CKD, dialysis or a kidney transplant may be needed to replace kidney function and
                                            improve
                                            life expectancy.
                                        </p>
                                        <h4> Kidney disease causes in females
                                        </h4>
                                        <p>Kidney disease in females can be caused by a variety of factors, including diabetes,
                                            high
                                            blood pressure, autoimmune disorders, polycystic kidney disease, urinary tract
                                            infections,
                                            and certain medications. Early detection and treatment of these underlying
                                            conditions
                                            can
                                            help prevent or slow the progression of kidney disease in females.
                                        </p>
                                        <h4> Does drinking beer help in kidney treatment?
                                        </h4>
                                        <p>No, drinking beer does not help in kidney treatment. Alcohol consumption can be
                                            harmful
                                            to
                                            the kidneys, especially in excessive amounts. Alcohol can cause dehydration, high
                                            blood
                                            pressure, and damage to the kidneys over time. It can also worsen existing kidney
                                            problems
                                            and interact with certain medications used to treat kidney disease. It's important
                                            to
                                            follow
                                            a healthy diet and lifestyle, limit alcohol intake, and follow the treatment plan
                                            recommended by a healthcare professional to manage kidney disease.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'constipation'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Constipation: Causes, signs and Ayurvedic medicines | Dr. Axico
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>When faeces are difficult to pass and bowel movements become less frequent,
                                    constipation
                                    occurs. The most prevalent causes are dietary or routine adjustments, an inadequate
                                    intake
                                    of fibre, or both. If you experience significant pain, blood in your stools, or
                                    constipation
                                    that persists for more than three weeks, you should contact your doctor. Ayurveda
                                    can
                                    help
                                    you to lead a healthy life,&nbsp;<a href="https://www.draxico.com/">book your
                                        Ayurvedic
                                        doctor online consultation now</a>!</p>
                                <h3><strong>What is Constipation?</strong></h3>
                                <p>Constipation is a digestive disorder marked by infrequent or difficult bowel
                                    movements. A
                                    low-fiber diet,&nbsp;<a
                                        href="https://www.draxico.com/diseases/dehydration">dehydration</a>,
                                    inactivity, certain drugs, and pre-existing illnesses are only some of the many
                                    potential
                                    triggers. Constipation can cause stomach pain, a sense of incomplete evacuation,
                                    straining
                                    during bowel motions, and firm stools.&nbsp;</p>
                                <p>Over-the-counter drugs like laxatives or stool softeners may be used in addition to
                                    making
                                    lifestyle modifications including eating more fiber, drinking more water, and
                                    getting
                                    frequent exercise. Seek medical counsel to rule out underlying illnesses or consider
                                    prescription therapies for constipation if it is chronic or severe.</p><h4><strong>What
                                Causes Constipation?</strong></h4>
                                <p>Reasons for constipation - Constipation occurs when releasing stools is problematic
                                    or
                                    when
                                    bowel movements are infrequent. There are numerous potential causes of constipation,
                                    such
                                    as:</p>
                                <ul>
                                    <li>A dietary regimen that is inadequate in fibre can cause constipation because
                                        fibre
                                        adds
                                        mass to faeces and aids in their passage through the intestines.
                                    </li>
                                    <li>Dehydration can cause difficult-to-pass faeces that are dry, firm, and difficult
                                        to
                                        pass.
                                    </li>
                                    <li>Physical activity helps stimulate the muscles in the intestines, which can
                                        facilitate
                                        gastrointestinal movements.
                                    </li>
                                    <li>Certain medications can cause constipation, including <a
                                        href="https://www.drugs.com/drug-class/analgesics.html"
                                        rel="nofollow">analgesics</a>,
                                        antacids, and certain
                                        antidepressants.
                                    </li>
                                    <li>Constipation can be caused by medical conditions such as irritable bowel
                                        syndrome,
                                        hypothyroidism, and neurological disorders.
                                    </li>
                                    <li>Ignoring the urge to defecate: Ignoring the urge to defecate can lead to
                                        constipation
                                        because it can cause excrement to solidify and become more difficult to pass.
                                    </li>
                                </ul>
                                <p>If you have constipation&nbsp;<a href="https://www.draxico.com/appointment">take a
                                    free
                                    online consultation</a></p><h4><strong>How common is Constipation</strong></h4>
                                <p>Constipation is a common problem that affects people of all ages, but older adults
                                    tend
                                    to
                                    have it more often. Some figures say that up to 20% of people will have constipation
                                    at
                                    some
                                    point in their lives. But the number of people who have constipation can change
                                    based on
                                    how
                                    studies define constipation and who they look at.</p>
                                <p>Constipation can also be a long-term problem for some people. Between 2% and 7% of
                                    people
                                    have chronic constipation. Constipation is more likely to happen to women, older
                                    adults,
                                    and
                                    people with medical conditions like irritable bowel syndrome.</p>
                                <p>It's important to remember that people have bowel movements at different times, and
                                    what
                                    one
                                    person thinks is normal may not be normal for another. But if you have serious or
                                    long-lasting constipation, you should talk to your doctor to find out what's going
                                    on
                                    and
                                    make a treatment plan.</p><h4><strong>Does constipation lead to internal damage or
                                constipation causes other health problems?</strong></h4>
                                <p>If constipation is not properly treated or if it becomes chronic, it can cause
                                    several
                                    health
                                    issues. Among the potential constipation side effects are the following:</p>
                                <ul>
                                    <li><strong>Haemorrhoids:</strong> Haemorrhoids, which are swollen vessels in the
                                        rectal
                                        region, can be caused by straining during gastrointestinal movements.
                                    </li>
                                    <li><strong>Anal fissures:</strong> Stools that are hard and dry can cause tiny
                                        perforations
                                        in the anal membrane, resulting in painful fissures.
                                    </li>
                                    <li><strong>Rectal prolapse:</strong> Chronic constipation can weaken and deform the
                                        rectal
                                        walls, resulting in rectal prolapse.
                                    </li>
                                    <li><strong>Faecal impaction:</strong> In extreme instances, constipation can lead
                                        to
                                        faecal
                                        impaction, which occurs when hard faeces become lodged in the rectum and are
                                        unable
                                        to
                                        exit.
                                    </li>
                                    <li><strong>Diverticulitis:</strong> Constipation can result in the formation of
                                        diverticula, or tiny pockets in the colon, which can become inflamed and
                                        diseased,
                                        resulting in diverticulitis.
                                    </li>
                                    <li><strong>Bowel obstruction:</strong> In uncommon instances, severe constipation
                                        can
                                        cause
                                        a complete blockage of the bowel, which requires immediate medical attention.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Constipation</strong></h4>
                                <p>Drinking more water, eating more fibre, exercising, and taking over-the-counter <a
                                    href="https://www.nhs.uk/conditions/laxatives/" rel="nofollow">laxatives</a> can
                                    help constipation.</p>
                                <p>If your constipation lasts more than a few days or is accompanied by severe stomach
                                    discomfort, blood in your stool, or vomiting, consult a doctor.</p>
                                <h4><strong>When to make a doctor's appointment</strong></h4>

                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/constipation/Image-1.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Three days without a bowel movement.</li>
                                    <li>Bowel motions hurt.</li>
                                    <li>Hard, dry stools are hard to pass.</li>
                                    <li>Blood appears in your stool or toilet paper after wiping.</li>
                                    <li>You lose weight or appetite unexpectedly.</li>
                                    <li>Colon cancer or digestive issues run in your family.</li>
                                    <li>You failed constipation home remedies</li>
                                </ul>
                                <h3><strong>Ayurvedic Say on Constipation?</strong></h3>
                                <p>According to Ayurveda, constipation indicates impaired digestion and elimination.
                                    Constipation is considered a primary cause of numerous diseases because the
                                    accumulation
                                    of
                                    impurities in the body due to constipation can result in a variety of health
                                    issues.</p>
                                <p>Constipation ayurvedic treatment includes dietary modifications, herbal remedies, and
                                    lifestyle adjustments. Examples include:</p>
                                <ul>
                                    <li>A high-fiber diet that includes fruits, vegetables, and whole cereals.</li>
                                    <li>Morning consumption of tepid water with citrus to stimulate digestion.</li>
                                    <li>Herbs and seasonings such as Triphala, ginger, and fennel can aid digestion and
                                        relieve
                                        constipation when consumed.
                                    </li>
                                    <li>Yoga positions that stimulate digestion and promote elimination, such as the
                                        spinal
                                        twist and forward fold, should be practiced.
                                    </li>
                                </ul>
                                <p>Avoid processed, fried, and dairy foods, which can aggravate Vata dosha and cause
                                    constipation.</p>
                                <p>Importantly, if you have chronic constipation or if your constipation is accompanied
                                    by
                                    other
                                    symptoms, you should consult a medical professional for an accurate diagnosis and
                                    appropriate treatment.</p><h4><strong>Ayurvedic Remedies for Constipation</strong>
                            </h4>
                                <p><strong>Ayurveda provides numerous treatments for constipation. Here are some helpful
                                    Ayurvedic remedies:</strong></p>
                                <ul>
                                    <li>Castor oil: Castor oil is an additional Ayurvedic remedy for constipation. It
                                        functions
                                        as a natural laxative and promotes bowel movements. Castor oil can be consumed
                                        orally or
                                        applied to the abdomen topically.
                                    </li>
                                    <li>Psyllium husk is a natural fiber that can facilitate gastrointestinal movements
                                        and
                                        alleviate constipation. It is available as a powder that can be mixed with water
                                        or
                                        beverage.
                                    </li>
                                    <li>Ginger is a natural digestive aid that can ease constipation. It can be ingested
                                        as
                                        a
                                        supplement or in tea.
                                    </li>
                                    <li>Fennel is an additional digestive aid that can relieve constipation. It can be
                                        ingested
                                        as a supplement or in tea.
                                    </li>
                                    <li>Licorice (Yashtimadhu): Licorice root has mild laxative effects and can help
                                        relieve
                                        constipation. It also has a soothing effect on the digestive system and can help
                                        reduce
                                        inflammation in the intestines. Ayurvedic laxative tablets also have good
                                        results.&nbsp;
                                    </li>
                                    <li>Haritaki (Terminalia chebula): Haritaki is a potent herb used in Ayurveda for
                                        various
                                        digestive issues. It has mild laxative properties and supports regular bowel
                                        movements.
                                        It is often used as an ingredient in Ayurvedic formulations for constipation.
                                    </li>
                                    <li><a href="https://www.draxico.com/panchakarma-treatment/">Abhyanga</a> is an
                                        Ayurvedic
                                        massage technique that stimulates digestion and promotes
                                        digestive functions. Warm oil, such as sesame oil, is utilized to massage the
                                        abdomen.
                                    </li>
                                </ul>
                                <h4><strong>What to eat and not to eat</strong></h4>
                                <p>It is important to eat a healthy diet that is high in fiber and water to avoid
                                    constipation.
                                    Here are some things you should and shouldn't eat:</p><h4><strong>What to
                                eat:</strong>
                            </h4>
                                <ol>
                                    <li>Fruits that are good sources of fiber include apples, bananas, berries, oranges,
                                        pears,
                                        prunes, and raisins.
                                    </li>
                                    <li>Broccoli, carrots, peas, leafy greens, and sweet potatoes are all good sources
                                        of
                                        fiber.
                                    </li>
                                    <li>Whole grains are good sources of fiber. Brown rice, oatmeal, quinoa, and whole
                                        wheat
                                        bread are all examples.
                                    </li>
                                    <li>Beans, lentils, and beans are all legumes that are high in fiber.</li>
                                    <li>Nuts and seeds are good forms of fiber. Almonds, chia seeds, flaxseeds, and
                                        pumpkin
                                        seeds are all good examples.
                                    </li>
                                    <li>Fluids: If you are constipated, drinking lots of water, herbal teas, and other
                                        fluids
                                        can help.
                                    </li>
                                </ol>
                                <h4><strong>Avoid these foods:</strong></h4>
                                <ol>
                                    <li>Processed foods: Foods with a lot of sugar and chemicals that have been changed
                                        can
                                        cause constipation.
                                    </li>
                                    <li>Fried foods have a lot of fat, which can slow down digestion and cause bloating.
                                    </li>
                                    <li>Dairy products: Some people have trouble digesting dairy products, which can
                                        cause
                                        them
                                        to have trouble going to the toilet.
                                    </li>
                                    <li>Red meat: It can be hard to handle red meat, which can slow down your bowel
                                        movements.
                                    </li>
                                    <li>Alcohol and caffeine: Both weaken the body, which can make it hard to go to the
                                        toilet.
                                    </li>
                                    <li>It's important to remember that if you have a health problem or are taking
                                        medicine,
                                        you
                                        should talk to your doctor before making any big changes to your diet.
                                    </li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Constipation</strong></h4>
                                <p>The best Ayurvedic medicine for constipation and good bowel motions is given below.
                                    Constipation Ayurvedic medicine and remedies include:</p>
                                <p><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala</a> Churna:
                                    Three
                                    fruits—Amalaki (Emblica officinalis), Bibhitaki (Terminalia bellirica), and Haritaki
                                    (Terminalia chebula)—make up triphala. Powdered triphala is called churna. It aids
                                    digestion, bowel motions, and constipation.</p>
                                <p>Avipattikar Churna: Ayurvedic avipattikar churna comprises Triphala, Long Pepper
                                    (Pippali),
                                    and Indian Senna (Sonnathu). It aids digestion, doshas, and constipation.</p>
                                <p>Sat Isabgol (psyllium husk) is a natural fiber supplement used to treat constipation.
                                    It
                                    softens faeces, absorbs water in the intestines, and improves regularity.</p>
                                <p>Ayurvedic herbal tonic Abhayarishta includes Haritaki (Terminalia chebula), Vidanga
                                    (Embelia
                                    ribes), and Musta (Cyperus rotundus). It aids digestion, constipation, and
                                    gastrointestinal
                                    health.</p>
                                <p>Erandbhrisht Haritaki contains castor oil and Haritaki (Terminalia chebula). Laxative
                                    characteristics ease constipation.</p>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>Remember to share all of your questions and concerns regarding your bowel motions
                                    with
                                    your
                                    doctor directly and honestly. We should all be urinating regularly. Constipation can
                                    be
                                    a
                                    short-term issue or a chronic one, or a symptom of a more serious ailment. If you
                                    are
                                    facing
                                    such an issue then connect with Dr. Axico Ayurvedic doctor online and get relief
                                    from
                                    such
                                    diseases. In Addition, you can buy Ayurvedic Medicine online or offline
                                    too.&nbsp;&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <p><strong>How do I relieve Constipation at home?</strong></p>
                                        <p>Home remedies and lifestyle changes may cure constipation. Some approaches:</p>
                                        <p>Fibre: Eat more fruits, vegetables, whole grains, legumes, and nuts. Fibre bulks
                                            stool
                                            and
                                            improves regularity. To avoid bloating and gas, add fiber gradually.</p>
                                        <p>Hydrate: Drink water throughout the day. Hydration makes faeces easier to pass.</p>
                                        <p>Regular exercise promotes digestion and bowel motions. Walking or jogging may
                                            help.</p>
                                        <p>Set a daily toilet schedule, ideally after meals. This might teach your body to poop
                                            regularly.</p>
                                        <p>Consider natural laxatives. Prunes, figs, flaxseeds, and aloe vera juice ease
                                            constipation.</p>
                                        <p>Herbal drinks including peppermint, ginger, and dandelion root tea help encourage
                                            bowel
                                            motions.</p>
                                        <p>Relax: Stress may cause constipation. Deep breathing, meditation, and yoga relieve
                                            stress
                                            and
                                            improve digestion.</p>
                                        <p>Avoid postponing bowel movements: Respond quickly to bowel movements. Delaying might
                                            make
                                            faeces tougher to pass.</p>
                                        <h4><strong>कब्ज&nbsp;होने&nbsp;का&nbsp;मुख्य&nbsp;कारण&nbsp;क्या&nbsp;है?</strong>
                                        </h4>
                                        <p>कब्ज&nbsp;कई&nbsp;कारकों&nbsp;से&nbsp;हो&nbsp;सकता&nbsp;है।&nbsp;कुछ&nbsp;मुख्य&nbsp;कारण&nbsp;निम्नलिखित&nbsp;हैं:</p>
                                        <ol>
                                            <li>कम&nbsp;फाइबर&nbsp;आहार:&nbsp;फाइबर&nbsp;अपारदर्शी&nbsp;माल&nbsp;में&nbsp;बल&nbsp;जोड़ता&nbsp;है&nbsp;और&nbsp;नियमित&nbsp;दस्ताने&nbsp;प्रोत्साहित&nbsp;करता&nbsp;है।&nbsp;फल,&nbsp;सब्जियां,&nbsp;पूरे&nbsp;अनाज,&nbsp;लेग्यूम्स&nbsp;और&nbsp;अखरोट&nbsp;जैसे&nbsp;फाइबर-युक्त&nbsp;खाद्य&nbsp;पदार्थों&nbsp;की&nbsp;कमी&nbsp;कब्ज&nbsp;के&nbsp;लिए&nbsp;प्रमुख&nbsp;कारण&nbsp;हो&nbsp;सकती&nbsp;है।</li>
                                            <li>पीने&nbsp;की&nbsp;कमी:&nbsp;पर्याप्त&nbsp;पानी&nbsp;पीना&nbsp;शरीर&nbsp;को&nbsp;हाइड्रेटेड&nbsp;रखने&nbsp;में&nbsp;मदद&nbsp;करता&nbsp;है।&nbsp;पानी&nbsp;की&nbsp;कमी&nbsp;कब्ज&nbsp;का&nbsp;कारण&nbsp;बन&nbsp;सकती&nbsp;है,&nbsp;क्योंकि&nbsp;यह&nbsp;स्टूल&nbsp;को&nbsp;कठोर&nbsp;और&nbsp;पास&nbsp;करने&nbsp;के&nbsp;लिए&nbsp;मुश्किल&nbsp;बना&nbsp;सकती&nbsp;है।</li>
                                            <li>अपर्याप्त&nbsp;शारीरिक&nbsp;गतिविधि:&nbsp;नियमित&nbsp;शारीरिक&nbsp;गतिविधि&nbsp;की&nbsp;कमी&nbsp;भी&nbsp;कब्ज&nbsp;का&nbsp;कारण&nbsp;बन&nbsp;सकती&nbsp;है।&nbsp;शारीरिक&nbsp;गतिविधि,&nbsp;जैसे&nbsp;कि&nbsp;योग&nbsp;या&nbsp;साधना,&nbsp;पाचन&nbsp;प्रणाली&nbsp;को&nbsp;प्रोत्साहित&nbsp;कर&nbsp;सकती&nbsp;है&nbsp;और&nbsp;नियमित&nbsp;दस्ताने&nbsp;प्रदान&nbsp;कर&nbsp;सकती&nbsp;है।</li>
                                            <li>दवाओं&nbsp;का&nbsp;सेवन:&nbsp;कुछ&nbsp;दवाएं&nbsp;और&nbsp;औषधियाँ,&nbsp;जैसे&nbsp;कि&nbsp;पेनकिल,&nbsp;एंटासिड्स,&nbsp;नाइट्रेट्स,&nbsp;और&nbsp;दर्द&nbsp;निवारक&nbsp;दवाएं,</li>
                                        </ol>
                                        <h4><strong>What are the 10 most constipating foods?</strong></h4>
                                        <p>Some foods are more likely to cause constipation in some persons than others, but
                                            there
                                            is no
                                            official list of the 10 most constipating foods. Constipation-causing foods:</p>
                                        <ol>
                                            <li>Dairy products: If lactose intolerant, dairy products may cause constipation.
                                            </li>
                                            <li>Fast food, frozen meals, and packaged snacks are high-fat, low-fiber items that
                                                may
                                                cause constipation.
                                            </li>
                                            <li>Red meat: High-fat, low-fiber red meat may cause constipation.</li>
                                            <li>Fried and fatty meals impede digestion and cause constipation.</li>
                                            <li>Bananas: Although bananas are nutritious, eating too many unripe bananas might
                                                induce
                                                constipation owing to their high carbohydrate content.
                                            </li>
                                            <li>White rice and refined grains: White rice, white bread, and pasta lack
                                                fiber-rich
                                                bran
                                                and germ, which may cause constipation.
                                            </li>
                                            <li>Processed sweets: High-sugar, low-fiber foods like cookies, cakes, and candies
                                                may
                                                cause
                                                constipation.
                                            </li>
                                            <li>Alcohol and caffeine: Drinking too much coffee, tea, or energy drinks may
                                                dehydrate
                                                and
                                                cause constipation.
                                            </li>
                                            <li>Unripe or undercooked fruits and vegetables may be difficult to digest and cause
                                                constipation.
                                            </li>
                                            <li>Low fluid intake might cause constipation. To make bowel movements simpler,
                                                drink
                                                adequate water throughout the day.
                                            </li>
                                        </ol>
                                        <h4><strong>Is constipation a serious problem?</strong></h4>
                                        <p>Constipation is seldom severe. Many individuals have it. Simple lifestyle changes and
                                            home
                                            treatments may cure most constipation problems.</p>
                                        <p>However, prolonged or severe constipation may need medical treatment. Chronic
                                            constipation
                                            may be caused by a medical condition or a medicine.</p>
                                        <p>Monitor your bowel motions and constipation symptoms. Signs of medical assessment
                                            include:</p>
                                        <ul>
                                            <li>Constipation lasts weeks.</li>
                                            <li>Extreme abdominal discomfort.</li>
                                            <li>Stool or rectal bleeding.</li>
                                            <li>Weight loss.</li>
                                            <li>Constipation-diarrhea cycles.</li>
                                            <li>Over-50 bowel changes.</li>
                                        </ul>
                                        <p>Bowel blockage symptoms include severe discomfort, vomiting, and difficulty to pass
                                            gas
                                            or
                                            stool.</p>
                                        <p>Consult a doctor if you have any of these symptoms or persistent constipation. They
                                            can
                                            diagnose and cure constipation</p>
                                        <h4><strong>Is Milk good for constipation?</strong></h4>
                                        <p>Milk's constipation effects differ. Milk and other dairy products may increase
                                            constipation
                                            or digestive pain in lactose-intolerant people. Lactose intolerance prevents milk
                                            sugar
                                            digestion. Undigested lactose may cause bloating, gas, and constipation.</p>
                                        <p>Milk does not relieve constipation in non-lactose intolerant people. Regular bowel
                                            motions
                                            lack fiber. Milk alone may not relieve constipation.</p>
                                        <p>However, a balanced diet that supports digestive health may include milk. It gives
                                            the
                                            body
                                            calcium and protein. Milk may be improved for constipation alleviation. Combining
                                            milk
                                            with
                                            fiber-rich foods like oat bran or ground flaxseeds helps improve intestinal
                                            regularity.</p>
                                        <p>Milk's impact on constipation might vary. Milk may alleviate or hurt constipation.
                                            Consider
                                            your body's responses and any underlying diseases or sensitivities.</p>
                                        <p>Consult a doctor for persistent or severe constipation. They might recommend food and
                                            lifestyle changes to relieve constipation depending on your condition.</p>
                                        <h4><strong>Is Banana good for constipation?</strong></h4>
                                        <p>Bananas help constipation. They contain soluble fiber, which promotes regular bowel
                                            motions
                                            and relieves constipation.</p>
                                        <p>Bananas' fiber bulks faeces, making them simpler to digest. Bananas also contain
                                            fructose,
                                            which stimulates the bowels and promotes regularity. Ripe bananas with brown spots
                                            are
                                            simpler to digest and contain more soluble fiber, making them better for
                                            constipation.
                                            Unripe bananas contain more starch and may bind, worsening constipation in certain
                                            people.</p>
                                        <p>Bananas may help regulate bowel motions, but other variables affect digestive health
                                            too.
                                            Maintaining regular bowel movements and avoiding constipation requires drinking
                                            enough
                                            water, eating a varied fiber-rich diet, and exercising regularly.</p>
                                        <p>Consult a doctor if you have persistent or severe constipation to find the reason and
                                            get
                                            treatment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'dehydration'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Dehydration: Causes, Signs & Ayurvedic Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Dehydration happens when the body loses more fluids than it takes in, and it is a
                                    frequent
                                    condition. It can be caused by various factors such as intense physical activity,
                                    hot
                                    weather, vomiting, diarrhoea, and certain medical conditions. Ayurveda is the best
                                    way
                                    to
                                    treat such diseases,&nbsp;<a href="https://www.draxico.com/">Ayurvedic doctor online
                                        consultation</a> should be the first step to a healthy life.</p>
                                <p>Dehydration can lead to serious health complications, including kidney problems,
                                    seizures,
                                    and even death. It's important to understand the symptoms and dehydration causes to
                                    prevent
                                    it from happening and to seek medical attention if necessary.&nbsp;</p>
                                <p>We will explore the different types of dehydration, their causes, and their symptoms.
                                    We
                                    will
                                    also discuss some tips on how to prevent dehydration and how to manage it when it
                                    occurs.
                                    Additionally, we will provide information on the importance of staying hydrated and
                                    the
                                    benefits of drinking enough water for overall health and well-being.</p>
                                <h3><strong>What is Dehydration?</strong></h3>
                                <p>When the body loses more fluids than it takes in, dehydration develops. This can
                                    happen
                                    as a
                                    result of sweating, urination, diarrhoea, <a
                                        href="https://en.wikipedia.org/wiki/Vomiting"
                                        rel="nofollow"> vomiting</a>, or not drinking
                                    enough fluids. When the
                                    body becomes dehydrated, it doesn't have enough water to carry out its normal
                                    functions,
                                    which can lead to a range of dehydration symptoms.</p>
                                <h4><strong>How does Dehydration occur?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/dehydration/Image.webp"
                                    >
                                </div>
                                <p>When the body expels more fluids than it takes in, dehydration can result. There are
                                    several
                                    causes for this, including:</p>
                                <ul>
                                    <li><strong>Not drinking enough fluids:</strong> If a person is not drinking enough
                                        fluids,
                                        especially during hot weather or when engaging in physical activity, they are at
                                        risk of
                                        becoming dehydrated.
                                    </li>
                                    <li><strong>Sweating:</strong> If a person sweats excessively and does not drink
                                        enough
                                        fluids to replace the lost water, they can become dehydrated.
                                    </li>
                                    <li><strong>Urination:</strong> The body eliminates excess fluids through urine. If
                                        a
                                        person
                                        is urinating more than usual due to certain medical conditions or medications,
                                        they
                                        may
                                        need to drink more fluids to prevent dehydration.
                                    </li>
                                    <li><strong>Diarrhoea and vomiting:</strong> Diarrhoea causes rapid fluid loss,
                                        leading
                                        to
                                        dehydration. It's important to drink plenty of fluids when experiencing
                                        diarrhoea or
                                        vomiting to replace the lost fluids.
                                    </li>
                                    <li><strong>High altitude:</strong> At higher altitudes, the air is less humid and
                                        people
                                        tend to breathe faster, causing more water loss through respiration. This can
                                        lead
                                        to
                                        dehydration if a person does not drink enough fluids.
                                    </li>
                                    <li><strong>Medical conditions:</strong> Dehydration risk can be raised by several
                                        illnesses, including diabetes, kidney failure, and cystic fibrosis.
                                    </li>
                                </ul>
                                <h3><strong>What are the main factors that cause Dehydration and how it looks?</strong>
                                </h3>
                                <ul>
                                    <li><strong>Not drinking enough fluids:</strong> The body needs a certain amount of
                                        water to
                                        function properly, and not drinking enough fluids can lead to dehydration.
                                    </li>
                                    <li><strong>Sweating:</strong> When the body sweats, it loses water, and if this
                                        water
                                        is
                                        not replaced by drinking fluids, it can lead to dehydration.
                                    </li>
                                    <li><strong>Urination:</strong> If a person is urinating more than usual, such as in
                                        the
                                        case of uncontrolled&nbsp;<a
                                            href="https://www.draxico.com/diseases/diabetes">diabetes</a> or some
                                        medications,
                                        they may need to drink more fluids to prevent dehydration.
                                    </li>
                                    <li><strong>Diarrhoea and vomiting:</strong> The rapid&nbsp;fluid loss brought on by
                                        these
                                        circumstances can result in dehydration. It's important to drink plenty of
                                        fluids
                                        when
                                        experiencing diarrhoea or vomiting to replace the lost fluids.
                                    </li>
                                    <li><strong>High altitude:</strong> At higher altitudes, the air is less humid and
                                        people
                                        tend to breathe faster, causing more water loss through respiration. This can
                                        lead
                                        to
                                        dehydration if a person does not drink enough fluids.
                                    </li>
                                </ul>
                                <p>Depending on how severe the dehydration is, the symptoms can change. Mild dehydration
                                    may
                                    cause thirst, dry mouth, and dark urine, while more severe dehydration can cause
                                    symptoms
                                    such as dizziness, rapid heartbeat, confusion, and even unconsciousness. Other&nbsp;dehydration
                                    symptoms may include headache, fatigue, dry skin, and irritability. In extreme
                                    cases,
                                    dehydration can be life-threatening and requires urgent medical attention.</p>
                                <h3><strong>What are the noticeable changes in the body of a dehydrated person?</strong>
                                </h3>
                                <p>Dehydration can cause noticeable changes in the body, and the severity of the
                                    symptoms
                                    depends on the level of dehydration. Some of the common signs of dehydration and
                                    symptoms of
                                    dehydration include:</p>
                                <ul>
                                    <li>Thirst: Feeling thirsty is often the first sign of dehydration. This occurs when
                                        the
                                        body is signalling that it needs more fluids.
                                    </li>
                                    <li>Dry mouth and throat: Dehydration can cause a dry mouth and throat, making it
                                        difficult
                                        to swallow or speak.
                                    </li>
                                    <li>Dark yellow urine: When the body is dehydrated, the urine becomes more
                                        concentrated
                                        and
                                        appears darker in colour.
                                    </li>
                                    <li>Fatigue and weakness: Dehydration can cause fatigue and weakness, making it
                                        difficult to
                                        perform everyday tasks.
                                    </li>
                                    <li>Dizziness and light-headedness: When the body is dehydrated, blood pressure can
                                        drop,
                                        causing dizziness and light-headness.
                                    </li>
                                    <li>Headache: Dehydration can cause&nbsp;<a
                                        href="https://www.draxico.com/diseases/headaches">headaches</a>, which may be
                                        mild
                                        or
                                        severe.
                                    </li>
                                    <li>Dry skin: Dehydration can cause the skin to become dry and flaky.</li>
                                    <li>Rapid heartbeat: Dehydration can cause the heart rate to increase as the body
                                        tries
                                        to
                                        compensate for the lack of fluids.
                                    </li>
                                    <li>Confusion and irritability: Severe dehydration can cause confusion,
                                        irritability,
                                        and
                                        even delirium.
                                    </li>
                                </ul>
                                <h3><strong>How does dehydration affect the body parts?</strong></h3>
                                <p>Dehydration can affect various body parts and systems in the following ways:</p>
                                <ul>
                                    <li>Brain: Dehydration can cause the brain to shrink and reduce cognitive function,
                                        leading
                                        to confusion, dizziness, and in severe cases, seizures.
                                    </li>
                                    <li>Kidneys: The kidneys play a vital role in regulating the body's fluid levels.
                                        When
                                        the
                                        body is dehydrated, the kidneys work harder to conserve fluids, which can lead
                                        to
                                        kidney
                                        damage and kidney stones.
                                    </li>
                                    <li>Digestive system: Dehydration can cause constipation and other digestive
                                        problems as
                                        the
                                        body conserves fluids by extracting more water from the stool.
                                    </li>
                                    <li>Cardiovascular system: Dehydration can cause a decrease in blood volume, leading
                                        to
                                        low
                                        blood pressure and a rapid heartbeat. This can put a strain on the heart and
                                        increase
                                        the risk of heart attack and stroke.
                                    </li>
                                    <li>Muscles and joints: Dehydration can cause muscle cramps and&nbsp;<a
                                        href="https://www.draxico.com/diseases/arthritis">joint pain</a> as the body's
                                        electrolyte balance is disrupted.
                                    </li>
                                    <li>Skin: Dehydration can cause dry skin, as the body conserves fluids by reducing
                                        sweat
                                        production.
                                    </li>
                                    <li>Eyes: Dehydration can cause dry eyes, leading to irritation and discomfort.</li>
                                </ul>
                                <h3><strong>Ayurvedic Prevention on Dehydration?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/dehydration/Image-2.webp"
                                    >
                                </div>
                                <p>Ayurveda provides several tips to prevent dehydration:</p>
                                <ol>
                                    <li>Drink sufficient water: Ayurveda recommends drinking warm water in small sips
                                        throughout
                                        the day. The amount of water needed depends on your constitution, activity
                                        level,
                                        and
                                        climate.
                                    </li>
                                    <li>Eat hydrating foods:&nbsp;<a
                                        href="https://www.draxico.com/about/ayurveda">Ayurveda</a>
                                        recommends eating foods with high water content, such as watermelon, cucumber,
                                        coconut
                                        water, and juicy fruits.
                                    </li>
                                    <li>Avoid diuretics: Diuretics, such as caffeine and alcohol, can cause dehydration
                                        by
                                        increasing urine production. Ayurveda recommends limiting or avoiding these
                                        substances.
                                    </li>
                                    <li>Practice moderation: Ayurveda recommends practising moderation in all things,
                                        including
                                        physical activity, exposure to the sun, and consumption of salty or spicy foods.
                                    </li>
                                    <li>Balance your doshas: According to Ayurveda, imbalances in your&nbsp;<a
                                        href="https://www.draxico.com/blogs/prakriti-and-its-ayurvedic-importance-for-health-dr-axico">doshas</a>
                                        can cause dehydration. Practising Ayurvedic lifestyle habits and dehydration
                                        Ayurvedic
                                        treatment can help balance your doshas and prevent dehydration.
                                    </li>
                                </ol>
                                <h4><strong>How Ayurveda Can Help to Control Dehydration and its
                                    Treatment?</strong></h4>
                                <p>Ayurveda offers several remedies to help control dehydration and its treatment. Here
                                    are
                                    some
                                    examples:</p>
                                <ul>
                                    <li>Herbal remedies: Ayurveda recommends several herbs that can help control
                                        dehydration,
                                        such as coriander, fennel, licorice, and aloe vera.&nbsp;
                                    </li>
                                    <li>Electrolyte-rich foods: Ayurveda recommends consuming foods that are rich in
                                        electrolytes, such as coconut water, cucumber, and watermelon. These foods help
                                        replenish the body's electrolytes lost during dehydration.
                                    </li>
                                    <li>Rehydration therapies: Ayurveda recommends several rehydration therapies, such
                                        as
                                        drinking warm water with ginger or adding rock salt to your water. These
                                        therapies
                                        can
                                        help restore balance to the body's fluids and electrolytes.
                                    </li>
                                    <li>Ayurvedic massages: Ayurveda recommends massages with herbal oils to balance the
                                        body's
                                        doshas and promote hydration.
                                    </li>
                                    <li>Panchakarma therapies: Ayurveda's detoxification therapies, such as <a
                                        href="https://www.draxico.com/panchakarma-treatment/">Panchakarma treatment</a>,
                                        can
                                        help eliminate toxins from the body, restore balance to the doshas, and improve
                                        hydration.
                                    </li>
                                </ul>
                                <h4><strong>How long does it take to stop dehydration after water?</strong></h4>
                                <p>The time it takes to rehydrate the body after dehydration with water can vary
                                    depending
                                    on
                                    several factors, including the severity of the dehydration and the individual's
                                    overall
                                    health.</p>

                                <p>In mild cases of dehydration, drinking water can start to alleviate symptoms such as
                                    thirst,
                                    dry mouth, and fatigue relatively quickly, within 30 minutes to an hour. However, it
                                    may
                                    take longer for the body to fully rehydrate, and individuals may need to continue
                                    drinking
                                    water throughout the day to replenish lost fluids.</p>

                                <p>In more severe cases of dehydration, it may take longer to rehydrate the body, and
                                    simply
                                    drinking water may not be enough to restore the body's fluid balance. In these
                                    cases,
                                    medical attention may be necessary, and intravenous fluids may be required to
                                    rehydrate
                                    the
                                    body more quickly.</p>
                                <h4><strong>Ayurvedic home remedies for dehydration:</strong></h4>
                                <ul>
                                    <li>Coconut Water: Coconut water is a natural electrolyte-rich drink that can help
                                        replenish
                                        lost fluids and minerals. Drink fresh coconut water regularly to keep your body
                                        hydrated.
                                    </li>
                                    <li>Amla Juice: Amla or Indian gooseberry is a rich source of Vitamin C and
                                        antioxidants
                                        that can help reduce the risk of dehydration. Drinking a glass of fresh amla
                                        juice
                                        with
                                        honey can help restore the body's fluid balance.
                                    </li>
                                    <li>Fennel Seeds: Chew on a handful of fennel seeds or drink fennel seed tea
                                        regularly
                                        to
                                        keep your body hydrated.
                                    </li>
                                    <li>Buttermilk: Buttermilk or chhachh is a traditional Indian drink that can help
                                        replenish
                                        lost fluids and minerals. It is rich in probiotics and can help improve
                                        digestion as
                                        well. Drink a glass of buttermilk regularly to keep your body hydrated.
                                    </li>
                                    <li>Watermelon: Eat fresh watermelon regularly or drink watermelon juice to stay
                                        hydrated.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat:</strong></h4>
                                <ul>
                                    <li>Fruits and vegetables with high water content such as watermelon, strawberries,
                                        cucumbers, lettuce, and celery
                                    </li>
                                    <li>Soups and broths made with vegetables and lean proteins such as chicken or
                                        fish
                                    </li>
                                    <li>Electrolyte-rich foods such as coconut water, bananas, and avocados</li>
                                    <li>Whole grains, nuts, and seeds provide sustained energy and nutrients</li>
                                </ul>
                                <h4><strong>What to Avoid:</strong></h4>
                                <ul>
                                    <li>Sugary drinks and sodas, which can increase urine output and lead to dehydration
                                    </li>
                                    <li>Alcohol and caffeine, which are diuretics and can cause dehydration</li>
                                    <li>Processed and fried foods, which can be high in salt and contribute to
                                        dehydration
                                    </li>
                                    <li>Spicy or salty foods, which can increase thirst and cause dehydration</li>
                                </ul>
                                <h4><strong>Ayurvedic herbs and medications for
                                    dehydration:</strong></h4>
                                <p>The best dehydration ayurvedic medicine is given below:</p>
                                <ul>
                                    <li>Amla or Indian Gooseberry</li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala</a></li>
                                    <li><a href="https://www.draxico.com/products/guduchi-ghana-vati">Guduchi</a> or&nbsp;<a
                                        href="https://www.draxico.com/products/giloy-plus-capsule">Giloy</a></li>
                                    <li>Chandan or Sandalwood</li>
                                    <li>Shankhapushpi</li>
                                    <li>Jivanti</li>
                                    <li><a href="https://www.draxico.com/products/punarnavadi-guggulu">Punarnava</a>
                                    </li>
                                    <li>Yashtimadhu</li>
                                    <li><a
                                        href="https://www.draxico.com/products/dashamoola-rishta">Dasamoolarishtam</a>
                                    </li>
                                    <li>Amritarishta</li>
                                    <li><a href="https://www.draxico.com/products/chandrakala-ras">Chandrakala Ras</a>
                                    </li>
                                    <li>Jwarari Ras</li>
                                    <li>Pippalyasavam</li>
                                    <li>Aravindasavam</li>
                                    <li>Praval Pishti</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>When your body fails to contain enough water, the condition is known as dehydration.
                                    Drinking
                                    before you become thirsty is the best defense against dehydration. You are already
                                    mildly
                                    dehydrated if you are thirsty, which can result in symptoms including headache,
                                    lethargy,
                                    dizziness, and more.&nbsp;</p>
                                <p>With the help of Dr. Axico Ayurvedic doctor consultation, many patients can properly
                                    manage
                                    their health conditions and carry on with their favorite activities. You can also
                                    buy
                                    Ayurvedic medicine online or offline.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Does dehydration cause cramping?</strong>
                                        </h4>
                                        <p>Dehydration can cause muscle cramps because the body loses fluids and electrolytes
                                            essential
                                            for proper muscle function. Electrolyte imbalances can lead to involuntary muscle
                                            contractions or spasms, commonly known as cramps.</p>
                                        <h4><strong>How does dehydration affect the kidneys?</strong></h4>
                                        <p>Dehydration can lead to decreased blood flow to the kidneys, resulting in a reduced
                                            ability
                                            to filter waste and maintain the proper balance of electrolytes in the body. This
                                            can
                                            lead
                                            to a range of symptoms, including increased thirst, dark urine, fatigue, dizziness,
                                            and
                                            confusion. In severe cases, dehydration can lead to kidney damage or even kidney
                                            failure.</p>
                                        <h4><strong>Can dehydration cause shortness of breath?</strong></h4>
                                        <p>Dehydration can potentially cause shortness of breath, particularly in cases where it
                                            is
                                            severe and prolonged. When the body is dehydrated, it may reduce the amount of fluid
                                            in
                                            the
                                            lungs, making it harder to breathe. Additionally, dehydration can cause the heart to
                                            work
                                            harder to pump blood, which can lead to shortness of breath. Other factors, such as
                                            low
                                            blood pressure, can also contribute to shortness of breath in cases of
                                            dehydration.</p>
                                        <h4><strong>Which beverages hydrate the body, and which dehydrate?</strong></h4>
                                        <p>Water is the best beverage for hydrating the body, as it is easily absorbed and does
                                            not
                                            contain any additives that can cause dehydration. Other beverages that can help with
                                            hydration include sports drinks, coconut water, and herbal tea. However, beverages
                                            that
                                            contain caffeine, such as coffee, tea, and soda, can have a diuretic effect and
                                            increase
                                            fluid loss through urination, leading to dehydration if consumed in
                                            excess.&nbsp;</p>

                                        <p>Similarly, alcoholic beverages can also cause dehydration by increasing urination and
                                            impairing the body's ability to regulate fluid balance. It is important to moderate
                                            the
                                            consumption of caffeinated and alcoholic beverages and to balance them with adequate
                                            water
                                            intake to maintain proper hydration levels.</p>
                                        <h4><strong>How much water should I drink to avoid Dehydration?</strong></h4>
                                        <p>According to Ayurveda, the amount of water you need to drink to avoid dehydration
                                            depends
                                            on
                                            your constitution (dosha) and the specific imbalances in your body. Ayurveda
                                            recommends
                                            drinking water at room temperature, rather than ice-cold or boiling water. In
                                            general,
                                            Ayurveda recommends drinking water in small sips throughout the day, rather than
                                            large
                                            quantities at once.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'flu'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Flu</h4>
                            </div>
                            <div class="card-body text-left">
                                <p class="card-text">Flu is a common infectious viral illness spread by coughs and
                                    sneezes.
                                    It
                                    can be very unpleasant, but you'll usually begin to feel better within about a
                                    week.</p>
                                <p class="card-text">he flu virus is contained in the millions of tiny droplets that
                                    come
                                    out of
                                    the nose and mouth when someone who is infected coughs or sneezes.</p>
                                <p class="card-text">These droplets typically spread about one metre. They hang
                                    suspended in
                                    the
                                    air for a while before landing on surfaces, where the virus can survive for up to 24
                                    hours.</p>
                                <p class="card-text">
                                    Anyone who breathes in the droplets can catch flu. You can also catch the virus by
                                    touching
                                    the surfaces that the droplets have landed on if you pick up the virus on your hands
                                    and
                                    then touch your nose or mouth.
                                </p>
                            </div>
                        </div>
<!--                        <div class="card mt-5">-->
<!--                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
                <div *ngIf="diseasesName === 'hair-loss-and-balding'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Hair Loss & Balding: Early Signs, Causes & Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Hair loss and balding are common conditions that affect millions of people around the
                                    world.
                                    While it's natural to lose some hair every day, excessive hair loss and balding can
                                    be
                                    distressing and affect a person's self-confidence. Hair loss can be caused by a
                                    variety
                                    of
                                    factors, including genetics, hormonal changes, stress, poor nutrition, and certain
                                    medical
                                    conditions. It can be cured with an&nbsp;<a href="https://www.draxico.com/">Ayurvedic
                                        doctor
                                        online consultation</a>.&nbsp;</p>
                                <p>It's important to understand the causes of hair loss and balding to determine the
                                    best
                                    course
                                    of treatment. Here, I will explore the different types of hair loss, their causes,
                                    and
                                    their
                                    symptoms. Discuss some natural remedies and medical treatments that can help prevent
                                    or
                                    treat hair loss and promote hair growth. Additionally, provide tips on how to care
                                    for
                                    your
                                    hair to maintain its health and prevent further hair loss.</p>
                                <h3><strong>What is Hair Loss &amp; Balding?</strong></h3>
                                <p>Alopecia, another name for hair loss, is a disorder in which hair thinning occurs on
                                    the
                                    scalp or in other areas of the body. It can be a temporary or permanent condition,
                                    and
                                    it
                                    can affect people of all ages and genders. Hair loss can occur gradually or
                                    suddenly,
                                    and it
                                    can be caused by a variety of factors, including genetics, hormonal changes, certain
                                    medications, medical conditions, and environmental factors.&nbsp;</p>
                                <p>Balding, also known as male-pattern baldness or androgenetic alopecia, is a common
                                    type
                                    of
                                    hair loss that occurs in men as they age. A receding hairline and thinning hair on
                                    the
                                    crown
                                    of the head are its defining features. Balding can be a distressing condition, but
                                    there
                                    are
                                    various treatments available to help manage and sometimes even reverse the effects
                                    of
                                    hair
                                    loss.</p>
                                <h4><strong>What are the stages of hair loss?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/hair-loss-and-balding/Image.webp"
                                    >
                                </div>
                                <p>Hair loss can progress through several stages, and the severity of the condition can
                                    vary
                                    depending on the individual. The most commonly used classification system for hair
                                    loss
                                    is
                                    the Norwood-Hamilton scale, which is used to describe male-pattern baldness. The
                                    stages
                                    of
                                    hair loss according to this scale are:</p>
                                <ul>
                                    <li><strong>Stage 1:&nbsp;</strong>No significant hair loss or recession of the
                                        hairline.
                                    </li>
                                    <li><strong>Stage 2:</strong> Mild hair loss, with slight recession of the hairline
                                        at
                                        the
                                        temples.
                                    </li>
                                    <li><strong>Stage 3:</strong> Moderate hair loss, with a deeper recession of the
                                        hairline at
                                        the temples and thinning on the crown of the head.
                                    </li>
                                    <li><strong>Stage 4:</strong> Advanced hair loss, with a more significant recession
                                        of
                                        the
                                        hairline and thinning on the crown. The hairline may resemble an "M" shape.
                                    </li>
                                    <li><strong>Stage 5:</strong> Severe hair loss, with a prominent "M" shape hairline
                                        and
                                        significant thinning on the crown.
                                    </li>
                                    <li><strong>Stage 6:</strong> Extensive hair loss, with only a band of hair
                                        remaining on
                                        the
                                        sides and a bald spot in the back of head.
                                    </li>
                                    <li><strong>Stage 7:</strong> Complete hair loss, with only a horseshoe-shaped band
                                        of
                                        hair
                                        remaining on the sides and back of the head.
                                    </li>
                                </ul>
                                <p>It is important to note that the Norwood-Hamilton scale is specific to male-pattern
                                    baldness
                                    and may not apply to other types of hair loss in men or women.</p><h4><strong>What
                                are
                                the
                                main factors that cause hair loss?</strong></h4>
                                <p><strong>what causes alopecia or what causes hair loss</strong> - Hair loss can have
                                    multiple
                                    causes, and the exact factors that contribute to hair loss can vary from person to
                                    person.
                                    Some of the main factors that may cause hair loss include:</p>
                                <ul>
                                    <li><strong>Genetics:</strong> Androgenetic alopecia, or male/female pattern
                                        baldness,
                                        is
                                        the most common cause of hair loss and is often hereditary.
                                    </li>
                                    <li><strong>Hormonal changes:</strong> Hormonal imbalances, such as those caused by
                                        pregnancy, menopause, thyroid disorders, and certain medications, can trigger
                                        hair
                                        loss.
                                    </li>
                                    <li><strong>Medical conditions:</strong> Certain medical conditions, such as
                                        autoimmune
                                        diseases, scalp infections, and alopecia areata, can cause hair loss.
                                    </li>
                                    <li><strong>Nutritional deficiencies:</strong> A lack of essential vitamins and
                                        minerals,
                                        such as iron, zinc, and biotin, can contribute to hair loss.
                                    </li>
                                    <li><strong>Physical and emotional stress:</strong> Physical trauma or emotional
                                        stress
                                        can
                                        disrupt hair growth cycles and lead to hair loss.
                                    </li>
                                    <li><strong>Hairstyling and treatments:</strong> Certain hairstyles, hair
                                        treatments,
                                        and
                                        chemical products can damage hair and contribute to hair loss.
                                    </li>
                                    <li><strong>Age:</strong> As we age, hair growth slows down, and hair may become
                                        thinner
                                        and
                                        more brittle, leading to hair loss.
                                    </li>
                                </ul>
                                <h4><strong>Is Hair Loss hierarchical?</strong></h4>
                                <p>No, hair loss is not hierarchical. Hair loss can affect people of all ages, genders,
                                    and
                                    ethnicities, and can be caused by a variety of factors. While some people may be
                                    more
                                    genetically predisposed to hair loss, it does not mean that they are more likely to
                                    experience it in a hierarchical sense.&nbsp;</p>

                                <p>Similarly, certain medical conditions or lifestyle factors may increase the risk of
                                    hair
                                    loss, but they do not necessarily affect one social class or demographic group more
                                    than
                                    others. Hair loss can affect anyone.</p>
                                <h4><strong>What are the symptoms of hair loss?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/hair-loss-and-balding/Image-2.webp"
                                    >
                                </div>
                                <p>The symptoms of hair loss can vary depending on the underlying cause and the stage of
                                    hair
                                    loss. Here are some common symptoms of hair loss:</p>
                                <ol>
                                    <li>Gradual thinning on the top of the head: This is the most common type of hair
                                        loss
                                        and
                                        often affects both men and women. It typically starts with a receding hairline
                                        or a
                                        bald
                                        spot on the crown of the head.
                                    </li>
                                    <li>Circular or patchy bald spots: This type of hair loss may occur on the scalp or
                                        other
                                        areas of the body. The bald spots are usually smooth and may be accompanied by
                                        itching
                                        or pain.
                                    </li>
                                    <li>Sudden loosening of hair: A physical or emotional shock may cause hair to loosen
                                        and
                                        fall out in clumps. This type of hair loss is usually temporary.
                                    </li>
                                    <li>Full-body hair loss: Some medical treatments or conditions, such as chemotherapy
                                        or
                                        <a href="https://www.aad.org/public/diseases/hair-loss/types/alopecia#:~:text=Alopecia%20areata%3A%20Alopecia%20is%20the,the%20scalp%20is%20completely%20bald."
                                           rel="nofollow"> alopecia </a> universalis, can cause hair loss all over the
                                        body.
                                    </li>
                                    <li>Thinning hair on the eyebrows or beard: Hair loss may also occur on the eyebrows
                                        or
                                        beard, and it may be patchy or gradual.
                                    </li>
                                </ol>
                                <h4><strong>When to see a Doctor for Hair Loss &amp; Balding?</strong></h4>
                                <p>Here are some specific situations in which you should consider seeing a doctor for
                                    hair
                                    loss:</p>
                                <ul>
                                    <li>Hair loss in patches: If you notice bald patches on your scalp or elsewhere on
                                        your
                                        body, it's important to get a medical evaluation to determine the cause.
                                    </li>
                                    <li>Itching, burning, or pain: If you experience itching, burning, or pain on your
                                        scalp, it
                                        could be a sign of an infection or other condition that requires medical
                                        attention.
                                    </li>
                                    <li>Hair loss after a medical procedure or treatment: Certain medical treatments,
                                        such
                                        as
                                        chemotherapy or radiation therapy, can cause hair loss.
                                    </li>
                                    <li>Hair loss at a young age: If you're experiencing hair loss at a young age, it
                                        could
                                        be a
                                        sign of an underlying medical condition or genetic predisposition to hair loss.
                                    </li>
                                </ul>
                                <h3><strong>Leech Therapy Treatment for Hair Loss</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/hair-loss-and-balding/Image-3.webp"
                                    >
                                </div>
                                <p>Leeches have been used in medicine since the time of the ancient Egyptians to cure
                                    infections, skin illnesses, nervous system anomalies, and dental issues.</p>
                                <p>They are primarily utilised in microsurgery and plastic surgery today. <a
                                    href="https://www.draxico.com/panchakarma-treatment/leech-therapy"> Panchakarma
                                    Leech
                                    Therapy </a> Leeches secrete
                                    peptides and proteins that prevent blood clots, which explains why. The term
                                    "anticoagulants" also applies to these secretions. This helps wounds heal by keeping
                                    blood
                                    flowing to them.</p>
                                <p>Blood circulation is increased through leech therapy. As a result, when leeches are
                                    placed on
                                    bald or thinning patches of the scalp, the increased blood flow helps to concentrate
                                    and
                                    supply the nutrients necessary to strengthen the hair follicles. The treatment may
                                    help
                                    to
                                    encourage hair growth. As with all therapies, each person will see different
                                    results.</p>
                                <h4><strong>Ayurvedic Prevention of Hair Loss &amp; Balding?</strong></h4>
                                <p>Ayurvedic tips for hair health:</p>
                                <ul>
                                    <li>Massage your scalp regularly with warm oil: Ayurveda recommends massaging the
                                        scalp
                                        with
                                        warm oil to improve circulation, nourish the hair follicles, and promote hair
                                        growth.
                                        Coconut oil, almond oil, and sesame oil are popular choices.
                                    </li>
                                    <li>Maintain a healthy diet: A balanced diet that includes plenty of fresh fruits,
                                        vegetables, whole grains, and healthy fats can provide essential vitamins and
                                        minerals
                                        that are important for hair health.
                                    </li>
                                    <li>Practice stress management: Stress can disrupt the hair growth cycle and
                                        contribute
                                        to
                                        reasons for hair loss. Ayurveda recommends practicing stress-management
                                        techniques
                                        such
                                        as meditation, yoga, and deep breathing exercises to promote relaxation and
                                        reduce
                                        stress.
                                    </li>
                                    <li>Use natural hair care products: Ayurvedic treatment for hair fall recommends
                                        using
                                        natural hair care products that are free of harsh chemicals and preservatives.
                                        Ingredients such as amla, shikakai, and neem are commonly used in Ayurvedic hair
                                        care
                                        products.
                                    </li>
                                    <li>Avoid harsh treatments: Harsh chemical treatments such as <a
                                        href="https://www.goodhousekeeping.com/beauty/hair/a27226401/perm-hair/#:~:text=Perms%20work%20by%20using%20chemicals,to%20halt%20the%20perming%20process."
                                        rel="nofollow"> perming</a>, straightening, and
                                        colouring can damage hair and contribute to reasons for hair loss. Ayurveda
                                        recommends
                                        avoiding these treatments and using gentle, natural methods to care for your
                                        hair.
                                    </li>
                                </ul>
                                <h4><strong>Ayurvedic Remedies for Hair Loss &amp; Balding</strong></h4>
                                <ol>
                                    <li><strong>Amla:</strong> Mix amla powder with water to form a paste and apply it
                                        to
                                        your
                                        scalp for 30 minutes before rinsing it off with water. Repeat this 2-3 times a
                                        week
                                        to
                                        promote hair growth. Amla would be the best ayurvedic medicine for hair growth
                                        on a
                                        bald
                                        head.&nbsp;
                                    </li>
                                    <li><strong>Coconut oil and curry leaves</strong> Heat coconut oil and curry leaves
                                        in a
                                        pan
                                        and let it cool. Apply the oil to your scalp and hair and leave it on for an
                                        hour
                                        before
                                        rinsing it off with water. Do this once a week to strengthen hair and prevent
                                        hair
                                        loss.
                                    </li>
                                    <li><strong>Fenugreek and coconut oil:</strong> Soak <a
                                        href="https://www.amazon.in/Fenugreek-Seeds/s?k=Fenugreek+Seeds" rel="nofollow">
                                        fenugreek seeds</a> overnight and grind
                                        them into a paste. Mix the paste with coconut oil and apply it to your scalp and
                                        hair
                                        for 30 minutes before rinsing it off with water. Do this once a week to promote
                                        hair
                                        growth.
                                    </li>
                                    <li><strong>Onion juice:</strong> Extract onion juice and apply it to your scalp for
                                        30
                                        minutes before rinsing it off with water. Do this once a week to stimulate hair
                                        growth.
                                    </li>
                                    <li><strong>Aloe vera:</strong> Apply fresh aloe vera gel to your scalp and hair for
                                        30
                                        minutes before rinsing it off with water. Do this once a week to strengthen hair
                                        and
                                        prevent hair loss.
                                    </li>
                                </ol>
                                <h4><strong>What to Eat and Not</strong></h4><h4><strong>What to eat:</strong></h4>
                                <ul>
                                    <li>Protein-rich foods: Good sources of protein include lean meats, fish, poultry,
                                        beans,
                                        lentils, and tofu.
                                    </li>
                                    <li>Foods rich in vitamins and minerals: Foods rich in these nutrients include leafy
                                        green
                                        vegetables, nuts and seeds, whole grains, eggs, and fatty fish.
                                    </li>
                                    <li>Foods with antioxidants: Good sources of antioxidants include fruits and
                                        vegetables,
                                        especially those with bright colours like berries, citrus fruits, tomatoes, and
                                        carrots.
                                    </li>
                                    <li>Healthy fats: Omega-3 fatty acids are found in fatty fish like salmon, sardines,
                                        and
                                        mackerel, as well as in flaxseeds, chia seeds, walnuts, etc
                                    </li>
                                </ul>
                                <h4><strong>What to avoid:</strong></h4>
                                <ul>
                                    <li>Processed and sugary foods: Consuming too many processed and sugary foods can
                                        lead
                                        to
                                        inflammation in the body, which may contribute to hair loss.
                                    </li>
                                    <li>High-glycemic-index foods: Such as white bread, pasta, and sugary drinks, may
                                        also
                                        contribute to inflammation and hair loss.
                                    </li>
                                    <li>Alcohol and caffeine: Excessive alcohol and caffeine intake can dehydrate the
                                        body
                                        and
                                        lead to nutrient deficiencies.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Hair Loss &amp;
                                    Balding</strong>
                                </h4>
                                <p>Here is some best ayurvedic medicine for hair fall:</p>
                                <ul>
                                    <li>Bhringraj</li>
                                    <li>Amla (Indian Gooseberry)</li>
                                    <li><a href="https://www.draxico.com/products/brahmi-vati">Brahmi</a> (Gotu Kola)
                                    </li>
                                    <li>Jatamansi (Spikenard)</li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala</a></li>
                                    <li>Neem</li>
                                    <li>Shikakai</li>
                                    <li><a href="https://www.draxico.com/products/bruhat-methi-pak">Methi</a> (Fenugreek
                                        seeds)
                                    </li>
                                    <li>Mahabhringraj oil</li>
                                    <li>Brahmi oil</li>
                                    <li>Neelibhringadi oil</li>
                                    <li>Ashwagandha churna</li>
                                    <li>Narasimha Rasayana</li>
                                    <li>Dhatri hair oil</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>As people age, the majority of those who were assigned male at birth have
                                    male-pattern
                                    baldness. Many men with male pattern baldness don't seek medical attention because
                                    they
                                    consider it to be a natural aspect of aging. Your mental health may be adversely
                                    affected,
                                    though. Male pattern baldness symptoms should be reported as soon as possible,
                                    especially if
                                    they lead to stress, anxiety, or sadness. There are several medications and
                                    ayurvedic
                                    treatments for hair fall that can halt or reverse it.</p>
                                <p>Consult Dr. Axico Ayurvedic Doctor online to treat baldness effectively and you can
                                    buy
                                    Ayurvedic medicine online or offline from the official websites or stores. Here, you
                                    will
                                    experience the best Ayurvedic medicine for hair loss and regrowth.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Why does a male have more hair loss than a
                                            female?</strong></h4>
                                        <p>The primary hair fall reason why males experience more hair loss than females is due
                                            to
                                            genetics. Androgenetic alopecia, or male pattern baldness, is inherited in a pattern
                                            that
                                            affects the hair follicles on the top and front of the head. The genetic
                                            predisposition
                                            to
                                            baldness is typically inherited from the mother's side of the family.&nbsp;</p>
                                        <p>In addition, males have higher levels of androgens, such as testosterone, which can
                                            contribute to hair loss. Hormonal changes, aging, and other factors can also
                                            contribute
                                            to
                                            hair loss in both males and females, but males tend to experience it more frequently
                                            and
                                            to
                                            a greater degree.</p><h4><strong>Does hair fall come from my mother's side?</strong>
                                    </h4>
                                        <p>The genetic predisposition to hair loss, particularly androgenetic alopecia or male
                                            pattern
                                            baldness, is often inherited from both the mother's and father's sides of the
                                            family.
                                            However, there is a common myth that hair loss is only inherited from the mother's
                                            side.
                                            The
                                            reality is that the genes responsible for hair loss can come from either parent or
                                            both.
                                            Studies have shown that genetic factors account for approximately 80% of cases of
                                            hair
                                            loss.</p><h4><strong>What’s the difference between telogen effluvium and male
                                        pattern
                                        baldness?</strong></h4>
                                        <p>Telogen effluvium (TE) and male pattern baldness (MPB) are two different types of
                                            hair
                                            loss
                                            conditions. TE is a temporary hair loss condition that occurs when there is a shift
                                            in
                                            the
                                            hair growth cycle. This can be triggered by factors such as hormonal changes,
                                            stress,
                                            illness, medications, or surgery.&nbsp;</p>
                                        <p>With TE, hair falls out evenly across the scalp, and the hair loss is generally
                                            reversible
                                            once the underlying cause is resolved. In contrast, MPB is a genetic condition that
                                            leads to
                                            permanent hair loss. It typically begins with a receding hairline, followed by
                                            thinning
                                            at
                                            the crown and eventually balding.</p><h4><strong>Ayurvedic home remedies for hair
                                        loss?</strong></h4>
                                        <ol>
                                            <li><strong>Amla:</strong> Mix amla powder with water to form a paste and apply it
                                                to
                                                your
                                                scalp for 30 minutes before rinsing it off with water. Repeat this 2-3 times a
                                                week
                                                to
                                                promote hair growth.
                                            </li>
                                            <li><strong>Coconut oil and curry leaves</strong> Heat coconut oil and curry leaves
                                                in a
                                                pan
                                                and let it cool. Apply the oil to your scalp and hair and leave it on for an
                                                hour
                                                before
                                                rinsing it off with water. Do this once a week to strengthen hair and prevent
                                                hair
                                                loss.
                                            </li>
                                            <li><strong>Fenugreek and coconut oil:</strong> Soak fenugreek seeds overnight and
                                                grind
                                                them into a paste. Mix the paste with coconut oil and apply it to your scalp and
                                                hair
                                                for 30 minutes before rinsing it off with water. Do this once a week to promote
                                                hair
                                                growth.
                                            </li>
                                            <li><strong>Onion juice:</strong> Extract onion juice and apply it to your scalp for
                                                30
                                                minutes before rinsing it off with water. Do this once a week to stimulate hair
                                                growth.
                                            </li>
                                            <li><strong>Aloe vera:</strong> Apply fresh aloe vera gel to your scalp and hair for
                                                30
                                                minutes before rinsing it off with water. Do this once a week to strengthen hair
                                                and
                                                prevent hair loss.
                                            </li>
                                        </ol>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'gastroenteritis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Gastroenteritis: Stomach Flu, Causes, & Symptoms</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Gastroenteritis, which is also called stomach flu, is a medical disease in which the
                                    lining
                                    of the stomach and intestines gets inflamed. Gastroenteritis is caused by a virus or
                                    bacteria, or it can happen when you eat or drink something that has been
                                    tainted.&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a> would
                                    be
                                    helpful to cure Gastroenteritis.&nbsp;</p>
                                <p>Some of the signs of gastroenteritis are feeling sick, throwing up, having diarrhoea,&nbsp;<a
                                    href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">stomach
                                    pain</a>,
                                    cramps, fever, and a&nbsp;<a
                                        href="https://www.draxico.com/diseases/headaches">headache</a>.
                                    The reason for the infection, the age and health of the person who has it, and other
                                    things
                                    can affect how bad the symptoms are.</p>
                                <p>The most common ways to treat dysentery are rest, staying hydrated, and taking
                                    over-the-counter medicines to treat symptoms like fever and diarrhoea. In serious
                                    cases,
                                    you
                                    may need to go to the hospital to treat dehydration or other problems. To keep
                                    gastroenteritis from spreading, it's important to have good cleanliness, like
                                    washing
                                    your
                                    hands often and staying away from people who are sick.</p>
                                <h3><strong>What is Gastroenteritis (Stomach flu)</strong></h3>
                                <p>If you are experiencing both diarrhoea and vomiting, you might describe your symptoms
                                    as
                                    "stomach flu." These signs of gastrointestinal infection are frequently seen. When
                                    you
                                    have
                                    gastroenteritis, your stomach and intestines become irritable and inflamed. Usually,
                                    a
                                    viral
                                    or bacterial illness is to blame. Gastroenteritis, which is also called stomach flu,
                                    is
                                    a
                                    medical disease in which the lining of the stomach and intestines gets inflamed.</p>
                                <h4>
                                    <strong>What are the main factors that cause Gastroenteritis (Stomach flu)</strong>
                                </h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/gastroenteritis/Image-1.webp"
                                    >
                                </div>
                                <p>Gastroenteritis, or the stomach flu, can be caused by several things, such as a virus
                                    or
                                    bacteria, or by eating or drinking something that is contaminated. Here are a few of
                                    the
                                    most common things that cause gastroenteritis:</p>
                                <ul>
                                    <li>Infections with viruses: Viruses like&nbsp;<a
                                        href="https://www.cdc.gov/norovirus/index.html#:~:text=Norovirus%20is%20a%20very%20contagious,is%20caused%20by%20influenza%20virus."
                                        rel="nofollow">norovirus</a>,&nbsp;<a
                                        href="https://www.webmd.com/children/guide/what-is-rotavirus"
                                        rel="nofollow">rotavirus</a>, and
                                        adenovirus often cause diarrhoea. You can get these viruses by eating or
                                        drinking
                                        something that has been tainted or by being close to someone who has them.
                                    </li>
                                    <li>Infections caused by bacteria: Salmonella, E. coli, and Campylobacter are all
                                        types
                                        of
                                        bacteria that can cause diarrhoea. Most of the time, these bacteria are spread
                                        by
                                        contaminated food or water, or by coming into touch with a sick person or
                                        animal.
                                    </li>
                                    <li>Parasites: Giardia and Cryptosporidium are two parasites that can cause
                                        gastroenteritis.
                                        They usually spread through dirty water or food.
                                    </li>
                                    <li>Food or water that has been tainted: Eating or drinking something that has been
                                        tainted
                                        with bacteria, viruses, or parasites can cause gastroenteritis.
                                    </li>
                                    <li>Poor hygiene: Not washing your hands after going to the toilet or before
                                        handling
                                        food
                                        are both bad hygiene habits that can help spread gastroenteritis.
                                    </li>
                                </ul>
                                <p>People who have a weak immune system, like those with HIV/AIDS or who are getting
                                    chemotherapy, are more likely to get diarrhoea.</p><h4><strong>Who gets stomach flu
                                (gastroenteritis)?</strong></h4>
                                <p>No matter what age or gender you are, you can get gastroenteritis. But some groups of
                                    people
                                    may be more likely to get the disease or may have worse signs. Among these groups
                                    are:</p>
                                <ul>
                                    <li>Children under 5 are more likely to get gastroenteritis because their immune
                                        systems
                                        are
                                        still growing and they may not have built up immunity to some viruses or
                                        bacteria.
                                    </li>
                                    <li>Gastro symptoms adults: Because their immune systems may not be as strong as
                                        they
                                        used
                                        to be, older people are also more likely to get diarrhoea.
                                    </li>
                                    <li>People whose immune systems aren't as strong: People whose immune systems aren't
                                        as
                                        strong, like those with HIV/AIDS or who are getting treatment, are more likely
                                        to
                                        get
                                        gastroenteritis.
                                    </li>
                                    <li>People who live in compact places: People who live in close quarters, like in
                                        nursing
                                        homes, dorms, or military bases, are more likely to get gastroenteritis
                                        outbreaks.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Gastroenteritis (Stomach flu)?</strong></h4>
                                <p>Most stomach flu, or gastroenteritis, can be treated at home with rest and fluids.
                                    But
                                    there
                                    are times when it's best to see a doctor:</p>
                                <ul>
                                    <li>Major symptoms: If you are dehydrated, have bloody stools, have significant&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>, or have a high fever, you should see a doctor.
                                    </li>
                                    <li>Gastro symptoms that last longer than a few days or don't get better should be
                                        checked
                                        out by a doctor. This could be an early sign of gastro or a more serious problem
                                        that
                                        needs to be treated.
                                    </li>
                                    <li>Other health problems: If you get gastroenteritis and have a weak immune system
                                        or
                                        other
                                        health problems, like&nbsp;<a
                                            href="https://www.draxico.com/diseases/diabetes">diabetes</a> or&nbsp;<a
                                            href="https://www.draxico.com/diseases/heart-disease">heart disease</a>, you
                                        should
                                        see a doctor.
                                    </li>
                                    <li>Recent travel: If you have gastroenteritis symptoms and have just been to a
                                        developing
                                        country, you should see a doctor because you may have been exposed to a more
                                        dangerous
                                        illness.
                                    </li>
                                    <li>Babies and young children: If your child is under 6 months old or has serious
                                        symptoms
                                        like vomiting or diarrhoea that won't go away, you should take them to the
                                        doctor
                                        right
                                        away.
                                    </li>
                                </ul>
                                <h3><strong>Ayurvedic Prevention of Gastroenteritis (Stomach Flu)?</strong></h3>
                                <p>Ayurveda is an ancient form of medicine that comes from India. It uses natural
                                    treatments
                                    and
                                    ways of living to promote health and keep people from getting sick like <a
                                        href="https://www.draxico.com/panchakarma-treatment/eye-treatments">panchakarma</a>.
                                    Here are some tips from
                                    Ayurveda on how to avoid gastroenteritis:</p>
                                <ul>
                                    <li>Eat a balanced diet. This means that you should eat a range of fresh fruits and
                                        vegetables, whole grains, and lean proteins. Stay away from prepared and fast
                                        foods.
                                    </li>
                                    <li>Don't eat too much. Only eat until you are full, and don't eat more than you
                                        need
                                        to. If
                                        you eat too much, you could get indigestion or other stomach problems.
                                    </li>
                                    <li>Do yoga and meditation. Doing yoga and meditation regularly can help you feel
                                        less
                                        stressed and more relaxed, which is good for your digestive system.
                                    </li>
                                    <li>Ayurveda says to use natural remedies like ginger, turmeric, cumin, and
                                        coriander to
                                        keep your digestive system healthy and avoid gastroenteritis.
                                    </li>
                                    <li>Maintain a healthy way of life by getting regular exercise, keeping a healthy
                                        weight,
                                        and not smoking or drinking too much alcohol.
                                    </li>
                                </ul>
                                <h4><strong>Ayurvedic Remedies for Gastroenteritis?</strong></h4>
                                <p>Ayurveda recommends natural remedies for the treatment of gastroenteritis (stomach
                                    flu).
                                    Here
                                    are some&nbsp;<a href="https://www.draxico.com/">gas Ayurvedic treatments</a>:</p>
                                <ul>
                                    <li><strong>Ginger:</strong> Ginger is a natural anti-inflammatory and can help
                                        relieve
                                        nausea, vomiting, and diarrhea. Add fresh ginger to your food or drink ginger
                                        tea.
                                    </li>
                                    <li><strong>Turmeric:</strong> Turmeric has antibacterial and anti-inflammatory
                                        properties
                                        and can help relieve inflammation in the digestive system. Add turmeric to your
                                        food
                                        or
                                        drink turmeric milk.
                                    </li>
                                    <li><strong>Cumin:</strong> Cumin seeds can help reduce inflammation in the
                                        digestive
                                        system
                                        and relieve symptoms of gastroenteritis. Add cumin seeds to your food or drink
                                        cumin
                                        water.
                                    </li>
                                    <li><strong>Coriander:</strong> Coriander seeds can help reduce inflammation in the
                                        digestive system and relieve symptoms of gastroenteritis. Add coriander seeds to
                                        your
                                        food or drink coriander water.
                                    </li>
                                    <li><strong>Lemon:</strong> Lemon juice can help neutralize stomach acid and relieve
                                        symptoms of indigestion. Drink lemon water or add lemon juice to your food.
                                    </li>
                                    <li><strong>Peppermint:</strong> Peppermint can help relieve nausea, vomiting, and
                                        diarrhea.
                                        Drink peppermint tea or add fresh peppermint to your food.
                                    </li>
                                    <li><strong>Coconut water:</strong> Coconut water is a natural electrolyte and can
                                        help
                                        prevent dehydration caused by vomiting and diarrhea. Drink coconut water
                                        regularly.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not?</strong></h4>
                                <p>If you have gastroenteritis, you should eat foods that are easy to digest and stay
                                    away
                                    from
                                    foods that can make your symptoms worse. Here are some broad rules about what you
                                    should
                                    eat
                                    and what you shouldn't:</p><h4><strong>What to eat:</strong></h4>
                                <ul>
                                    <li>Clear drinks: Stay hydrated by drinking a lot of clear liquids like water,
                                        coconut
                                        water, clear broths, and herbal teas.
                                    </li>
                                    <li>Bananas: Bananas are a good source of potassium and can help replace fluids lost
                                        through
                                        diarrhoea and vomiting.
                                    </li>
                                    <li>White rice is easy to stomach and can help stop diarrhoea by making the stools
                                        stick
                                        together.
                                    </li>
                                    <li>Toast: Plain toast or crackers can help settle your stomach and give you some
                                        energy.
                                    </li>
                                    <li>Potatoes that have been boiled or baked are a good source of energy and are easy
                                        to
                                        digest.
                                    </li>
                                </ul>
                                <h4><strong>What not to have:</strong></h4>
                                <ul>
                                    <li>Spicy, fatty, or greasy foods: These can upset the stomach and make
                                        gastroenteritis
                                        symptoms worse.
                                    </li>
                                    <li>Dairy products: Some people find it hard to digest dairy products, which can
                                        make
                                        diarrhoea and other symptoms worse.
                                    </li>
                                    <li>Caffeine and alcohol: Both can make the digestive system upset and make symptoms
                                        worse.
                                    </li>
                                    <li>Raw vegetables and fruits: It can be hard to digest raw veggies and fruits, and
                                        they
                                        can
                                        make diarrhoea worse.
                                    </li>
                                    <li>Fried foods are high in fat and can be hard to stomach, both of which can make
                                        symptoms
                                        worse.
                                    </li>
                                    <li>It's important to remember that what works for one person might not work for
                                        another. If
                                        you have gastroenteritis and don't know what to eat or what to avoid, it's best
                                        to
                                        talk
                                        to a doctor or a trained dietitian.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic medications for Gastroenteritis?</strong></h4>
                                <p><a href="https://www.draxico.com/medicinal-kits/acidity-uprooter">Ayurvedic medicine
                                    for
                                    gas
                                    and acidity</a> has several benefits. Here are some natural treatments for
                                    gastroenteritis
                                    (stomach flu) that can help ease symptoms and speed healing. But it's important to
                                    remember
                                    that these treatments should only be used with the help of an&nbsp;<a
                                        href="https://www.draxico.com/doctor">Ayurvedic expert</a>. Here are some
                                    possible
                                    Ayurvedic treatments for gastroenteritis:</p>
                                <p>Triphala is an Ayurvedic herbal formula that can help regulate digestion and ease
                                    constipation, diarrhoea, and other digestive problems. It has three fruits—amla,
                                    haritaki,
                                    and bibhitaki—that help the digestive system work well.</p>
                                <p>Kutajghan Vati is an Ayurvedic tablet for gas and acidity that can help with
                                    diarrhoea
                                    and
                                    other gastroenteritis symptoms. It has an herb called kutaj in it that can help
                                    lower
                                    inflammation in the digestive system. Kutaj is a natural anti-inflammatory and
                                    antibacterial
                                    herb.</p>
                                <p>Dhaniya Satva is made from coriander seeds and is an Ayurvedic plant powder. It can
                                    help
                                    relieve symptoms of gastroenteritis and lower inflammation in the digestive
                                    system.</p>
                                <p><a href="https://www.draxico.com/products/hingwashtaka-churna">Hingwashtak Churna</a>
                                    is
                                    an
                                    Ayurvedic plant powder that can help with gas, bloating, and other stomach issues.
                                    It
                                    has
                                    hing (asafoetida) and other herbs that can help lower inflammation in the digestive
                                    system.
                                </p>
                                <p>Bilva Avaleha is made from the bilva fruit and is an Ayurvedic plant jam. It can help
                                    stop
                                    diarrhoea and other symptoms of gastroenteritis, and it also helps keep the
                                    digestive
                                    system
                                    healthy generally.</p>
                                <p>It's important to remember that you should only use the best Ayurvedic medicine for
                                    gastric
                                    problems under the supervision of a trained Ayurvedic practitioner, as they can
                                    interact
                                    with other medicines and may not be right for everyone. It's also important to see a
                                    doctor
                                    if your gastroenteritis symptoms are serious or last for a long time.</p><h4>
                                <strong>A
                                    Short
                                    Note from Dr. Axico</strong></h4>
                                <p>It is customary to refer to gastroenteritis as "stomach flu." However, influenza, the
                                    respiratory virus that causes the flu, is not the primary cause. Diarrhoea, stomach
                                    pain,
                                    and feeling sick to your stomach are typically caused by several stomach bugs
                                    (germs).
                                    It
                                    can be cured easily with an Ayurvedic doctor online consultation and you can buy
                                    Ayurvedic
                                    medicine online or offline too.&nbsp;</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Can stomach flu cause a fever?</strong></h4>
                                        <p>Stomach flu (gastroenteritis) can induce fever. Gastroenteritis causes fever. Fever
                                            is
                                            usually low-grade, below 100.4°F (38°C), but can be higher. The body's immunological
                                            response to the virus or bacteria causes the fever. Gastroenteritis symptoms include
                                            diarrhoea, vomiting, stomach cramps, nausea, and dehydration. Hydrate and rest if
                                            you
                                            have
                                            gastroenteritis and a fever. If you have a high or persistent fever or other
                                            symptoms,
                                            visit
                                            a doctor.</p><h4><strong>Does gas cause stomach flu?</strong></h4>
                                        <p>Gas does not cause gastroenteritis. Viruses or bacteria inflame the stomach and
                                            intestines to
                                            induce stomach flu. Nausea, vomiting, diarrhoea, stomach cramps, and fever can
                                            result.
                                            Gas
                                            is not a common gastroenteritis symptom. Gas comes from big intestine microorganisms
                                            fermenting undigested food. Swallowing air while eating, drinking, smoking, or
                                            chewing
                                            gum
                                            might produce gas. For an accurate diagnosis and treatment of gastroenteritis, see a
                                            doctor.</p><h4><strong>Why does stomach flu hit at night?</strong></h4>
                                        <p>Stomach flu, also known as gastroenteritis, can hit at any time of the day or night.
                                            However,
                                            some people may experience more severe symptoms at night, which can disrupt sleep
                                            and
                                            make
                                            them feel worse. The reasons for this are not completely clear, but there are a few
                                            possible
                                            explanations:</p>
                                        <ul>
                                            <li>Increased relaxation: When we sleep, our bodies relax, including the muscles in
                                                our
                                                digestive system. This can cause food and waste to move more slowly through the
                                                intestines, which can exacerbate symptoms such as diarrhea and nausea.
                                            </li>
                                            <li>Hormonal changes: Our bodies produce different hormones at night that can affect
                                                digestion and bowel movements. For example, the hormone melatonin, which is
                                                produced
                                                during sleep, can slow down digestive activity.
                                            </li>
                                            <li>Dehydration: Dehydration is a common complication of gastroenteritis, and it can
                                                cause
                                                symptoms such as dry mouth, fatigue, and headaches. These symptoms may be more
                                                pronounced at night when we are not drinking fluids.
                                            </li>
                                            <li>Lowered immune function: Our immune system is generally weaker at night, which
                                                can
                                                make
                                                it harder for the body to fight off the virus or bacteria causing
                                                gastroenteritis.&nbsp;
                                            </li>
                                        </ul>
                                        <h4><strong>Is stomach flu (gastroenteritis) contagious?</strong></h4>
                                        <p>Gastroenteritis—stomach flu—is contagious. It spreads through infected faeces or
                                            vomit.
                                            Shaking hands, kissing, sharing utensils, or touching contaminated surfaces or items
                                            and
                                            then contacting your mouth or nose might spread gastroenteritis. Gastroenteritis is
                                            communicable from the outset and for several days afterward.</p>
                                        <p>To avoid gastroenteritis, wash your hands frequently with soap and water, especially
                                            after
                                            using the restroom, changing diapers, or caring for someone who is sick. Disinfect
                                            countertops, doorknobs, and shared utensils and glasses.</p>
                                        <p>Avoid spreading gastroenteritis if you or a family member gets it. Stay home from
                                            work or
                                            school, avoid close contact, and sanitize contaminated surfaces and objects.</p><h4>
                                        <strong>How
                                            long does stomach flu last?</strong></h4>
                                        <p>The cause and severity of stomach flu, commonly known as gastroenteritis, determine
                                            its
                                            length. Stomach flu often lasts 3–7 days. Diarrhoea, nausea, vomiting, stomach
                                            cramps,
                                            and
                                            fever can occur within hours to days of virus or bacteria contact.</p>
                                        <p>The most frequent cause of stomach flu, viral gastroenteritis, usually starts 1–3
                                            days
                                            after
                                            exposure and lasts 1–3 days. Some may have symptoms for 10 days. The severity and
                                            kind
                                            of
                                            bacteria causing bacterial gastroenteritis determine the duration of symptoms.
                                            Symptoms
                                            may
                                            last days or weeks.</p>
                                        <p>Gastroenteritis recovery requires rest and hydration. Water, broth, and sports drinks
                                            can
                                            replenish fluids lost through diarrhoea and vomiting. Small, bland meals like rice,
                                            bananas,
                                            or bread may also help. If your symptoms intensify or you have health concerns,
                                            visit a
                                            doctor.</p><h4><strong>Ayurvedic Home remedies for stomach flu
                                        (gastroenteritis)?</strong>
                                    </h4>
                                        <p>Ayurveda recommends several natural remedies for the treatment of gastroenteritis
                                            (stomach
                                            flu). Here are some Ayurvedic remedies for gastroenteritis:</p>
                                        <ul>
                                            <li>Ginger: Ginger is a natural anti-inflammatory and can help relieve nausea,
                                                vomiting,
                                                and
                                                diarrhea. Add fresh ginger to your food or drink ginger tea.
                                            </li>
                                            <li>Turmeric: Turmeric has antibacterial and anti-inflammatory properties and can
                                                help
                                                relieve inflammation in the digestive system. Add turmeric to your food or drink
                                                turmeric milk.
                                            </li>
                                            <li>Cumin: Cumin seeds can help reduce inflammation in the digestive system and
                                                relieve
                                                symptoms of gastroenteritis. Add cumin seeds to your food or drink cumin water.
                                            </li>
                                            <li>Coriander: Coriander seeds can help reduce inflammation in the digestive system
                                                and
                                                relieve symptoms of gastroenteritis. Add coriander seeds to your food or drink
                                                coriander
                                                water.
                                            </li>
                                            <li>Lemon: Lemon juice can help neutralize stomach acid and relieve symptoms of
                                                indigestion.
                                                Drink lemon water or add lemon juice to your food.
                                            </li>
                                            <li>Peppermint: Peppermint can help relieve nausea, vomiting, and diarrhea. Drink
                                                peppermint
                                                tea or add fresh peppermint to your food.
                                            </li>
                                            <li>Coconut water: Coconut water is a natural electrolyte and can help prevent
                                                dehydration
                                                caused by vomiting and diarrhea. Drink coconut water regularly.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'headaches'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Headache: Causes, Signs & Migraine Ayurvedic Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>There are millions of people suffering from headaches globally. They can range from
                                    mild
                                    to
                                    severe and can be caused by a variety of factors, including stress, tension,
                                    dehydration,
                                    lack of sleep, and underlying medical conditions. Headaches can be disruptive to
                                    daily
                                    life
                                    and can affect a person's ability to concentrate, work, and perform daily
                                    activities.
                                    Ayurveda is the best way to treat such diseases,&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic
                                        doctor online consultation</a> should be the first step to a healthy life.</p>
                                <p>It's important to understand the different types of headaches, their causes, and how
                                    to
                                    manage them to improve quality of life and then choose the best way to treat
                                    headache.&nbsp;</p>
                                <h3><strong>What is a Headache?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/headaches/Image-1.webp"
                                    >
                                </div>
                                <p>A headache is a common condition characterised by pain or discomfort in the head,
                                    scalp,
                                    or
                                    neck. Headaches can range in intensity and duration and can be caused by a variety
                                    of
                                    factors such as tension, stress, dehydration, hormonal changes, sinus infections,
                                    migraines,
                                    or head injuries.&nbsp;</p>
                                <p>The pain of a headache can be described as dull, throbbing, sharp, or piercing, and
                                    may
                                    be
                                    accompanied by other symptoms such as sensitivity to light and sound, nausea,
                                    vomiting,
                                    or
                                    dizziness.</p>
                                <p>Headaches are a common health problem, with a high prevalence globally. According to
                                    the&nbsp;<a
                                        href="https://www.who.int/" rel="nofollow">World Health Organization (WHO)</a>,
                                    almost
                                    half
                                    of the adult
                                    population has experienced a headache in the past year.</p>
                                <h4><strong>What’s the difference between a headache and a migraine?</strong></h4>
                                <p>Headaches and&nbsp;<a href="https://www.draxico.com/diseases/migraine">migraines</a>
                                    are
                                    two
                                    different types of conditions that can cause pain in the head and neck.</p>
                                <p>A headache is a general term that refers to any pain or discomfort in the head or
                                    neck
                                    area.
                                    Tension headaches, for example, are the most common type of headache and are often
                                    described
                                    as a band-like pain around the head. They can be caused by stress, poor posture, or
                                    eye
                                    strain.</p>
                                <p>In contrast, a migraine is a specific type of headache that is often more severe and
                                    can
                                    be
                                    accompanied by other symptoms such as nausea, vomiting, and sensitivity to light and
                                    sound.
                                    Migraines are often described as throbbing or pulsating pain that is typically felt
                                    on
                                    one
                                    side of the head, and they can last for several hours or even days. Migraines are
                                    often
                                    preceded by an "aura," which can include visual disturbances, such as flashing
                                    lights or
                                    zigzag lines.</p>
                                <p>If you are confused about your headache condition you can take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/general-health-assessment">free Self
                                    assessment test online</a>.</p>
                                <h4><strong>What are the main factors that cause Headaches?</strong></h4>
                                <p>Headaches can be caused by a variety of factors, including:</p>
                                <ul>
                                    <li>Tension and stress: Tension headaches are the most common type of headache, and
                                        they
                                        are
                                        often caused by stress, anxiety, or tension in the neck and shoulders.
                                    </li>
                                    <li>Dehydration:&nbsp;<a
                                        href="https://www.draxico.com/diseases/dehydration">Dehydration</a>
                                        can cause headaches, particularly if a person is not drinking enough fluids.
                                    </li>
                                    <li>Hormonal changes: Hormonal changes, such as those that occur during
                                        menstruation,
                                        pregnancy, or menopause, can trigger headaches.
                                    </li>
                                    <li>Sinus infections: Sinus infections or allergies can cause headaches,
                                        particularly if
                                        there is pressure or congestion in the sinuses.
                                    </li>
                                    <li>Environmental factors: Environmental factors such as bright lights, loud noises,
                                        or
                                        strong smells can trigger headaches in some people.
                                    </li>
                                    <li>Medical conditions: Certain medical conditions such as high blood pressure,
                                        concussion,
                                        or brain tumours can cause headaches.
                                    </li>
                                    <li>Medications: Some medications, including those for high blood pressure, can
                                        cause
                                        headaches as a side effect.
                                    </li>
                                    <li>Poor sleep: Poor sleep quality or lack of sleep can cause headaches.</li>
                                    <li>Diet: Certain foods or drinks, such as alcohol or caffeine, can trigger
                                        headaches in
                                        some people.
                                    </li>
                                </ul>
                                <h3><strong>What are the types of headaches?</strong></h3>

                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/headaches/Image-2.webp"
                                    >
                                </div>
                                <p>There are several different types of headaches, each with its specific
                                    characteristics
                                    and
                                    tension headache causes. Some of the most common types of headaches include:</p>
                                <ul>
                                    <li><strong>Tension headaches:</strong> These are the most common type of headache,
                                        and
                                        are
                                        often caused by stress, anxiety, or tension in the neck and shoulders. Tension
                                        headaches
                                        are typically described as a band-like pressure around the head.
                                    </li>
                                    <li><strong>Migraines:</strong> Migraines are a specific type of headache that is
                                        often
                                        more
                                        severe and can be accompanied by other symptoms such as nausea, vomiting, and
                                        sensitivity to light and sound. Migraines are often described as throbbing or
                                        pulsating
                                        pain that is typically felt on one side of the head.
                                    </li>
                                    <li><strong>Cluster headaches:</strong> Cluster headaches are rare but extremely
                                        painful
                                        headaches that occur in cycles or clusters, typically over several weeks or
                                        months.
                                        They
                                        are often described as a stabbing or burning pain that is felt on one side of
                                        the
                                        head.
                                    </li>
                                    <li><strong>Sinus headaches:</strong> Sinus headaches are caused by inflammation or
                                        pressure
                                        in the sinuses and are often accompanied by other symptoms such as congestion, a
                                        runny
                                        nose, or facial pain.
                                    </li>
                                    <li><strong>Hormone headaches:</strong> These headaches are often related to
                                        hormonal
                                        changes such as those that occur during menstruation, pregnancy, or menopause.
                                    </li>
                                    <li><strong>Rebound headaches:</strong> These headaches are caused by overuse of
                                        pain
                                        medications, such as aspirin or ibuprofen, and can occur when a person uses
                                        these
                                        medications too frequently or in high doses.
                                    </li>
                                    <li><strong>Thunderclap headaches:</strong> These are sudden and severe headaches
                                        that
                                        can
                                        be a sign of a serious underlying condition such as a brain haemorrhage or
                                        stroke.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Headaches?</strong></h4>
                                <p>While most headaches are not a cause for concern and can be treated with
                                    over-the-counter
                                    pain medications, there are certain situations where it is important to seek medical
                                    attention. Here are some signs that you should see a doctor for your headaches:</p>
                                <ul>
                                    <li>Sudden, severe headache: If you experience a sudden, severe headache, especially
                                        if
                                        it
                                        is accompanied by other symptoms such as confusion, difficulty speaking, or loss
                                        of
                                        consciousness, seek medical attention immediately.
                                    </li>
                                    <li>Changes in headache pattern: If your headache pattern changes significantly,
                                        such as
                                        becoming more frequent or more severe, this could be a sign of an underlying
                                        medical
                                        condition.
                                    </li>
                                    <li>Headache after a head injury: If you experience a headache after a head injury,
                                        such
                                        as
                                        a concussion, seek medical attention.
                                    </li>
                                    <li>Headache with fever: If you have a headache and a fever, this could be a sign of
                                        an
                                        underlying infection.
                                    </li>
                                    <li>Headache with neurological symptoms: If you experience neurological symptoms
                                        such as
                                        blurred vision, difficulty speaking, or weakness on one side of your body, seek
                                        medical
                                        attention immediately.
                                    </li>
                                    <li>Headache after age 50: If you are over age 50 and experience a new headache or a
                                        change
                                        in the pattern of your headaches, see your healthcare provider.
                                    </li>
                                    <li>Headache during pregnancy: If you are pregnant and experience a headache,
                                        especially
                                        if
                                        it is severe or accompanied by other symptoms, consult with your healthcare
                                        provider.
                                    </li>
                                </ul>
                                <p>You can take a&nbsp;<a href="https://www.draxico.com/appointment">free online
                                    consultation
                                    with an Ayurvedic doctor online</a> with Dr Axico.</p>
                                <h3><strong>Ayurvedic Prevention of Headaches</strong></h3>
                                <p>According to Ayurveda, headaches are caused by an imbalance in the doshas, or the
                                    fundamental
                                    energies that govern the body and mind. Different types of headaches are associated
                                    with
                                    different doshas. Here are some Ayurvedic prevention strategies based on each
                                    dosha:</p>
                                <ul>
                                    <li>Vata headaches: Vata headaches are often characterised by throbbing pain and
                                        sensitivity
                                        to light and sound. To prevent Vata headaches, it is important to maintain a
                                        routine,
                                        avoid stress, and keep the head warm and protected.
                                    </li>
                                    <li>Pitta headaches: Pitta headaches are often characterised by intense pain and
                                        sensitivity
                                        to light. To prevent Pitta headaches, it is important to avoid spicy, sour, or
                                        salty
                                        foods, limit exposure to the sun and heat, and practice stress reduction
                                        techniques.
                                    </li>
                                    <li>Kapha headaches: Kapha headaches are often characterised by dull, heavy pain and
                                        congestion in the sinuses. To prevent Kapha headaches, it is important to stay
                                        active
                                        and exercise regularly, avoid dairy and other heavy foods, and&nbsp;<a
                                            href="https://www.draxico.com/">choose sinus ayurvedic treatment</a>.
                                    </li>
                                </ul>
                                <p>In addition to these dosha-specific prevention strategies, Ayurveda also recommends
                                    general
                                    strategies for preventing headaches, such as maintaining a healthy diet, staying
                                    hydrated,
                                    practising good sleep hygiene, and managing stress. Migraine treatment in Ayurveda
                                    may
                                    also
                                    recommend specific herbal remedies or treatments, such as&nbsp;<a
                                        href="https://www.draxico.com/panchakarma-treatment/abhyanga">abhyanga oil
                                        massages</a>,&nbsp;<a
                                        href="https://www.draxico.com/panchakarma-treatment/shirodhara">Shirodhara</a>,&nbsp;<a
                                        href="https://www.draxico.com/panchakarma-treatment/nasyam">Nasya</a>,
                                    or nasal irrigation, to help prevent headaches based on an individual's specific
                                    needs
                                    and
                                    dosha imbalance.</p><h4><strong>Ayurvedic Remedies for headache</strong></h4>
                                <ul>
                                    <li>Ginger: You can drink ginger tea or chew on a piece of fresh ginger to help ease
                                        your
                                        symptoms.
                                    </li>
                                    <li>Peppermint oil: Peppermint oil has a cooling effect that can help relieve
                                        tension
                                        headaches. You can apply peppermint oil to your temples and massage it gently.
                                    </li>
                                    <li>Lavender oil: Lavender oil has a calming effect that can help relieve
                                        stress-related
                                        headaches. You can apply lavender oil to your temples or add a few drops to a
                                        warm
                                        bath
                                        to help you relax.
                                    </li>
                                    <li>Chamomile tea: Chamomile tea has a calming effect that can help relieve tension
                                        headaches. You can drink chamomile tea or apply a chamomile tea bag to your
                                        forehead.
                                    </li>
                                    <li>Cinnamon: You can make a paste by mixing the cinnamon powder with water and
                                        applying
                                        it
                                        to your forehead.
                                    </li>
                                    <li>Coriander seeds You can boil coriander seeds in water, strain the liquid and
                                        drink
                                        it to
                                        help relieve your symptoms.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not</strong></h4><h4><strong>What to eat:</strong></h4>
                                <ul>
                                    <li>Magnesium-rich foods: Leafy green vegetables, nuts, seeds, beans, and whole
                                        grains.
                                    </li>
                                    <li>Foods are high in B vitamins: Eggs, dairy products, lean meats, leafy green
                                        vegetables,
                                        and whole grains.
                                    </li>
                                    <li>Hydrating foods: Fruits and vegetables.</li>
                                    <li>Omega-3 fatty acids: Fatty fish such as salmon, sardines, and tuna, as well as
                                        flaxseeds, chia seeds, and walnuts.
                                    </li>
                                </ul>
                                <h4><strong>What to avoid:</strong></h4>
                                <ul>
                                    <li>Trigger foods: Aged cheeses, processed meats, chocolate, alcohol, and foods that
                                        contain
                                        monosodium glutamate (MSG).
                                    </li>
                                    <li>Caffeine: Limit your intake.</li>
                                    <li>Artificial sweeteners: Some artificial sweeteners, such as aspartame, have been
                                        shown to
                                        trigger headaches in some people.
                                    </li>
                                    <li>Processed foods: Limit your intake and choose whole, nutrient-dense foods
                                        instead.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for&nbsp;Headache</strong></h4>
                                <p>Here is the best&nbsp;headache ayurvedic medicine:</p>
                                <ul>
                                    <li>Sudarshan Ghan Vati</li>
                                    <li><a href="https://www.draxico.com/products/chandrakala-ras">Chandrakala Ras</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/kamadudha-ras">Kamdudha Ras</a></li>
                                    <li>Shirashuladi Vajra Ras</li>
                                    <li>Godanti Bhasma</li>
                                    <li>Sarpagandha Ghan Vati</li>
                                    <li><a href="https://www.draxico.com/products/mahashankh-vati">Maha Shankh Vati</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala
                                        Guggulu</a>
                                    </li>
                                    <li>Brahmi Ghrita</li>
                                    <li><a href="https://www.draxico.com/products/dashamoola-rishta">Dashmularishta</a>
                                    </li>
                                    <li><a href="https://www.draxico.com/products/brahmi-vati">Brahmi</a></li>
                                    <li>Ashwagandha</li>
                                    <li>Shankhapushpi Dashamoola</li>
                                    <li>Shatavari</li>
                                    <li>Amalaki</li>
                                    <li><a href="https://www.draxico.com/products/guduchi-ghana-vati">Guduchi</a></li>
                                    <li>Jatamansi</li>
                                    <li>Yashtimadhu</li>
                                    <li>Chandan</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>It's crucial to speak with your healthcare professional if your headaches are making
                                    it
                                    difficult for you to operate normally or are impacting your mood. The key to making
                                    a
                                    correct diagnosis is to be as detailed as possible when describing your headaches to
                                    your
                                    healthcare professional.&nbsp;</p>
                                <p>You can increase your chances of receiving an accurate diagnosis and a course of
                                    therapy
                                    that
                                    will make you feel better by providing your doctor with as much information as you
                                    can
                                    about
                                    your headaches. With the help of Dr. Axico Ayurvedic doctor consultation, many
                                    patients
                                    properly manage their health conditions and carry on with their favourite
                                    activities.
                                    You
                                    can also buy Ayurvedic medicine online or offline.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Tension Headache treatment at home?</strong>
                                        </h4>
                                        <ol>
                                            <li>Apply a cold or hot compress to your forehead.</li>
                                            <li>Practice relaxation techniques, such as deep breathing or meditation.</li>
                                            <li>Get enough rest and sleep.</li>
                                            <li>Stay hydrated by drinking plenty of water.</li>
                                            <li>Massage your temples or neck with essential oils like lavender or peppermint.
                                            </li>
                                            <li>Drink herbal tea, such as ginger or chamomile tea.</li>
                                            <li>Take a warm bath or shower.</li>
                                            <li>Practise gentle stretches or yoga.</li>
                                            <li>Avoid triggering foods and drinks.</li>
                                        </ol>
                                        <h4><strong>Can headaches or migraines be cured with Ayurveda?</strong></h4>
                                        <p>Ayurveda offers natural remedies, herbal medicines, and therapies like Shirodhara
                                            that
                                            can
                                            help with headaches and migraine treatment effectively. Shirodhara involves pouring
                                            warm
                                            herbal oil on the forehead to calm and balance the mind and body. Ayurveda aims to
                                            address
                                            the root cause of the condition and provide holistic healing. With proper diagnosis,
                                            personalised treatment plans, and consistent implementation of lifestyle changes,
                                            Ayurveda
                                            can significantly improve the quality of life for individuals suffering from
                                            headaches
                                            and
                                            migraines.</p><h4><strong>What to do when a headache stays for long?</strong></h4>
                                        <p>Here are some things you can do:</p>
                                        <ol>
                                            <li>Try to identify the trigger and avoid it.</li>
                                            <li>Practice relaxation techniques such as deep breathing or meditation.</li>
                                            <li>Get enough rest and sleep.</li>
                                            <li>Drink plenty of water to stay hydrated.</li>
                                            <li>Take over-the-counter pain relief medications as directed.</li>
                                            <li>Apply a cold or hot compress to your forehead.</li>
                                            <li>Consult with a healthcare provider for proper diagnosis and treatment.</li>
                                        </ol>
                                        <h4><strong>&nbsp;Home remedies for headaches?</strong></h4>
                                        <p><strong>&nbsp;</strong>Some of the effective remedies include:</p>
                                        <ol>
                                            <li>Apply a cold or hot compress to the forehead.</li>
                                            <li>Drink plenty of water to stay hydrated.</li>
                                            <li>Rest and get enough sleep.</li>
                                            <li>Practice relaxation techniques like deep meditation or breathing.</li>
                                            <li>Apply peppermint oil to the temples.</li>
                                            <li>Drink ginger tea or apply the ginger paste to the forehead.</li>
                                            <li>Massaging the temples or neck with warm oil.</li>
                                            <li>Take a warm bath or shower.</li>
                                        </ol>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'hepatitis-b'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Hepatitis B</h4>
                            </div>
                            <div class="card-body text-left">
                                <p class="card-text">
                                    Hepatitis B is an infection of the liver caused by a virus that's spread through
                                    blood
                                    and
                                    body fluids. It often doesn't cause any obvious symptoms in adults and typically
                                    passes
                                    in a
                                    few months without
                                    treatment, but in children it often persists for years and may eventually cause
                                    serious
                                    liver damage.
                                </p>
                                <p class="card-text">Many people with hepatitis B won't experience any symptoms and may
                                    fight
                                    off the virus without realising they had it.</p>
                                <p class="card-text">If symptoms do develop, they tend to occur 2 or 3 months after
                                    exposure
                                    to
                                    the hepatitis B virus.</p>
                                <h6>Symptoms Of Hepatitis B Include:-</h6>
                                <p class="card-text">flu-like symptoms, including tiredness, a fever, and general aches
                                    and
                                    pains</p>
                                <p class="card-text">loss of appetite</p>
                                <p class="card-text">Feeling and being sick</p>
                                <p class="card-text">Diarrhoea</p>
                                <p class="card-text">Abdominal pain</p>
                                <p class="card-text">yellowing of the skin and eyes (jaundice)</p>
                                <p>
                                    These symptoms will usually pass within one to three months (acute hepatitis B),
                                    although
                                    occasionally the infection can last for six months or more (chronic hepatitis B).
                                </p>
                            </div>
                        </div>
<!--                        <div class="card mt-5">-->
<!--                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
                <div *ngIf="diseasesName === 'kidney-infection'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Kidney Infections: Early Signs & Non Surgical Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>When it comes to discussing kidney infections, it is important to first understand
                                    the
                                    background of the condition before diving into specific details. Kidney infections,
                                    also
                                    known as pyelonephritis, are a serious type of urinary tract infection that can lead
                                    to
                                    severe complications if left untreated. Kidney infections can cause a range of
                                    symptoms,
                                    including fever, chills, back or abdominal pain, and frequent urination. It can be
                                    cured
                                    by
                                    an&nbsp;<a href="https://www.draxico.com/">Ayurvedic doctor online consultation</a>.&nbsp;
                                </p>
                                <p>They can also lead to serious complications if left untreated, such as kidney damage
                                    and
                                    sepsis. It's important to understand the causes, symptoms, and treatments of kidney
                                    infections to manage the condition effectively and prevent complications.&nbsp;</p>
                                <p>Highlight some tips on how to prevent kidney infections and how to manage them when
                                    they
                                    occur. Additionally, provide information on the importance of seeking medical
                                    attention
                                    and
                                    following a proper&nbsp;<a href="https://www.draxico.com/diseases/kidney-stone">kidney
                                        stone
                                        treatment plan</a> to manage kidney infections.</p>
                                <h3><strong>What is Kidney Infection (Pyelonephritis)?</strong></h3>
                                <p>Pyelonephritis, commonly referred to as a kidney infection, is a serious type of
                                    urinary
                                    tract infection (UTI) that occurs when bacteria spread from the bladder into one or
                                    both
                                    kidneys. The infection can cause inflammation and damage to the kidney tissue, which
                                    can
                                    result in pain, fever, and other kidney infections symptoms.</p><h4><strong>What is
                                the
                                difference between Kidney Infection and CKD?</strong></h4>
                                <p>Kidney infection (pyelonephritis) is a type of urinary tract infection that affects
                                    the
                                    kidneys and can lead to complications if left untreated. It is typically acute and
                                    can
                                    be
                                    treated with antibiotics.&nbsp;<a
                                        href="https://www.draxico.com/diseases/chronic-kidney-diseases">Chronic kidney
                                        disease
                                        (CKD)</a>, on the other hand, is a progressive condition that occurs when the
                                    kidneys
                                    are damaged and can no longer filter blood properly. It can be caused by a variety
                                    of
                                    factors and is a long-term condition. While both conditions affect the kidneys, they
                                    differ
                                    in terms of their causes, duration, and treatment.</p><h4><strong>What are the main
                                factors
                                that cause and symptoms of Kidney Infection?</strong></h4><h4><strong>Factors that can
                                cause
                                kidney infections:</strong></h4>
                                <ul>
                                    <li>Bacteria entering the urinary tract and travelling to the kidneys</li>
                                    <li>Weakened immune system</li>
                                    <li>Urinary tract abnormalities</li>
                                    <li>Obstruction of the urinary tract</li>
                                    <li>History of frequent UTIs</li>
                                    <li>Women are more susceptible due to their shorter urethra.</li>
                                </ul>
                                <h4><strong>Kidney Infections Symptoms:</strong></h4>
                                <ul>
                                    <li>Fever</li>
                                    <li>Chills</li>
                                    <li>Back or flank pain</li>
                                    <li>Abdominal pain</li>
                                    <li>Nausea</li>
                                    <li>Vomiting</li>
                                    <li>Frequent urination</li>
                                    <li>Cloudy or strong-smelling urine</li>
                                    <li>Blood in the urine</li>
                                    <li>Pain or discomfort while urinating</li>
                                    <li>If left untreated, more severe symptoms can occur such as high fever, confusion,
                                        and
                                        kidney damage.
                                    </li>
                                </ul>
                                <p>If you are facing any of the above symptoms, then you can take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/kidney-assessment">free Self
                                    Assessment
                                    Test
                                    for Kidney Infection</a>.</p>
                                <h4><strong>What are the Noticeable signs of kidney infection?</strong></h4>
                                <p><strong>Some of the most common signs and kidney infection pain locations
                                    include:</strong>
                                </p>
                                <ul>
                                    <li><strong>High fever:</strong> A fever is a common sign of an infection, and a
                                        high
                                        fever
                                        may indicate a kidney infection.
                                    </li>
                                    <li><strong>Chills:</strong> Feeling cold or experiencing shivering can be a sign of
                                        a
                                        kidney infection.
                                    </li>
                                    <li><strong>Flank pain:</strong> Pain in the back or sides of the body, particularly
                                        in
                                        the
                                        area of the kidneys, is a common symptom of a kidney infection.
                                    </li>
                                    <li><strong>Abdominal pain:</strong> Some people may experience&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a> or discomfort with a kidney infection.
                                    </li>
                                    <li><strong>Nausea and vomiting:</strong> These symptoms can occur with a kidney
                                        infection,
                                        especially if the infection is severe.
                                    </li>
                                    <li><strong>Frequent urination:</strong> Needing to urinate more often than usual,
                                        or
                                        feeling a strong urge to urinate, can be a sign of a kidney infection.
                                    </li>
                                    <li><strong>Painful urination:</strong> Some people may experience pain or a burning
                                        sensation while urinating with a kidney infection.
                                    </li>
                                    <li><strong>Cloudy or foul-smelling urine:</strong> Urine that appears cloudy or has
                                        a
                                        strong odour can be a sign of a kidney infection.
                                    </li>
                                </ul>
                                <h3><strong>What are the complications and risk factors of Kidney infection?</strong>
                                </h3>
                                <h4>
                                    <strong>Some of the complications that can occur include:</strong></h4>
                                <ol>
                                    <li><strong>Sepsis:</strong> This occurs when the infection spreads throughout the
                                        body
                                        and
                                        can lead to life-threatening complications.
                                    </li>
                                    <li><strong>Kidney damage:</strong> If left untreated, a kidney infection can cause
                                        damage
                                        to the kidneys, which can lead to chronic kidney disease.
                                    </li>
                                    <li><strong>Pregnancy complications:</strong> Pregnant women with kidney infections
                                        are
                                        at
                                        risk of premature labour and low birth weight babies.
                                    </li>
                                    <li><strong>Urinary tract obstruction:</strong> Kidney infections can cause swelling
                                        that
                                        may obstruct the urinary tract, leading to further complications.
                                    </li>
                                </ol>
                                <h4><strong>Some of the risk factors for kidney infections include:</strong></h4>
                                <ol>
                                    <li><strong>Female gender:</strong> Women are more susceptible to kidney infections
                                        due
                                        to
                                        their shorter urethra, which makes it easier for bacteria to travel to the
                                        bladder
                                        and
                                        kidneys.
                                    </li>
                                    <li><strong>Age:</strong> Kidney infections are more common in young children and
                                        older
                                        adults.
                                    </li>
                                    <li><strong>Urinary tract abnormalities:</strong> Structural abnormalities in the
                                        urinary
                                        tract can increase the risk of developing a kidney infection.
                                    </li>
                                    <li><strong>Immune system disorders:</strong> People with weakened immune systems
                                        are
                                        more
                                        susceptible to infections, including kidney infections.
                                    </li>
                                    <li><strong>Obstruction of the urinary tract:</strong> Anything that obstructs the
                                        flow
                                        of
                                        urine, such as a kidney stone, can increase the risk of developing a kidney
                                        infection.
                                    </li>
                                    <li><strong>History of frequent UTIs:</strong> People who have had frequent urinary
                                        tract
                                        infections in the past are at a higher risk of developing a kidney infection.
                                    </li>
                                </ol>
                                <h3><strong>When to see a Doctor for Kidney Infection?</strong></h3>
                                <p>Here are some signs and symptoms that should prompt you to see a doctor:</p>
                                <ul>
                                    <li><strong>Fever:</strong> If you have a fever, particularly a high fever, it may
                                        be a
                                        sign
                                        of a kidney infection.
                                    </li>
                                    <li><strong>Flank pain:</strong> Pain in the back or sides of the body, particularly
                                        in
                                        the
                                        area of the kidneys, can be a symptom of a kidney infection.
                                    </li>

                                    <div class="text-center">
                                        <img alt="" class="my-2 img-fluid"
                                             src="assets/img/diseases/diseasesImages/kidney-infection/Image.webp"
                                        >
                                    </div>
                                    <li><strong>Abdominal pain:</strong> Some people may experience abdominal pain or
                                        discomfort
                                        with a kidney infection.
                                    </li>
                                    <li>
                                        <strong>Nausea and vomiting:</strong> These symptoms can occur with a kidney
                                        infection,
                                        especially if the infection is severe.
                                    </li>
                                    <li><strong>Frequent urination:&nbsp;</strong>Needing to urinate more often than
                                        usual,
                                        or
                                        feeling a strong urge to urinate, can be a sign of a kidney infection.
                                    </li>
                                    <li><strong>Painful urination:</strong> Some people may experience pain or a burning
                                        sensation while urinating with a kidney infection.
                                    </li>
                                    <li><strong>Cloudy or foul-smelling urine:</strong> Urine that appears cloudy or has
                                        a
                                        strong odour can be a sign of a kidney infection.
                                    </li>
                                </ul>
                                <p>If you experience any of these symptoms, you should&nbsp;<a
                                    href="https://www.draxico.com/">see
                                    a doctor</a> as soon as possible to receive a proper diagnosis and kidney stone
                                    treatment.
                                    It is important to treat a kidney infection promptly to prevent complications such
                                    as
                                    kidney
                                    damage or sepsis.</p>
                                <h3><strong>Ayurvedic Prevention of Kidney Infection</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/kidney-infection/Image-2.webp"
                                    >
                                </div>
                                <p>Ayurveda, an ancient Indian system of medicine, offers several preventive measures
                                    for
                                    kidney
                                    infections. Here are some Ayurvedic tips for preventing kidney infection:</p>
                                <ol>
                                    <li><strong>Stay hydrated:</strong> Drinking plenty of fluids helps to flush out
                                        toxins
                                        and
                                        bacteria from the urinary tract and kidneys, reducing the risk of infection.
                                    </li>
                                    <li><strong>Maintain good hygiene:</strong> Practising good hygiene habits such as
                                        washing
                                        your hands regularly, wiping front to back after using the bathroom, and
                                        showering
                                        regularly can help prevent the spread of bacteria.
                                    </li>
                                    <li><strong>Eat a balanced diet:</strong> Eating a balanced diet that is rich in
                                        fruits,
                                        vegetables, whole grains, and lean proteins can help to boost the immune system
                                        and
                                        reduce the risk of infection.
                                    </li>
                                    <li><strong>Avoid spicy, sour, and fermented foods:</strong> These types of foods
                                        can
                                        aggravate the urinary tract and increase the risk of infection.
                                    </li>
                                    <li><strong>Avoid holding urine for too long:</strong> Holding urine for too long
                                        can
                                        increase the risk of infection as it allows bacteria to multiply in the urinary
                                        tract.
                                    </li>
                                    <li><strong>Practice yoga and meditation:</strong> Yoga and meditation can help to
                                        reduce
                                        stress and promote overall wellness, which can help to prevent infections.
                                    </li>
                                    <li><strong>Use Ayurvedic herbs:</strong> Certain Ayurvedic herbs such as gokshura,
                                        punarnava, and kaasni can help to maintain healthy kidney function and reduce
                                        the
                                        risk
                                        of infection.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Remedies for Kidney Infection</strong></h4>
                                <p>Some Kidney Stone Remedy in Ayurveda for treating kidney infection:</p>
                                <ul>
                                    <li>Coriander seeds: Boil one tablespoon of <a
                                        href="https://www.amazon.in/Minimal-Whole-Organic-Coriander-Seeds/dp/B07T3SQHDC"
                                        rel="nofollow"> coriander seeds </a> in two cups of water until the
                                        quantity is reduced by half. Strain the liquid and drink it once a day for a few
                                        days.
                                    </li>
                                    <li>Cranberry juice: Drinking cranberry juice can help to prevent and treat kidney
                                        infections as it contains compounds that prevent bacteria from sticking to the
                                        walls
                                        of
                                        the urinary tract.
                                    </li>
                                    <li>Ginger tea: Drinking ginger tea can help to reduce inflammation and relieve pain
                                        associated with kidney infection.
                                    </li>
                                    <li>Aloe vera juice: Drinking aloe vera juice can help to flush out toxins from the
                                        body
                                        and
                                        reduce inflammation in the urinary tract.
                                    </li>
                                    <li>Holy basil leaves: Chewing on holy basil leaves can help to reduce the frequency
                                        of
                                        urinary tract infections as it has antimicrobial properties.
                                    </li>
                                    <li>Cinnamon: Boil a cinnamon stick in a cup of water and drink it once a day for a
                                        few
                                        days
                                        to help reduce inflammation and pain associated with kidney infection.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not</strong></h4>
                                <p>The following dietary recommendations are suggested to support kidney health and
                                    prevent
                                    kidney infections:</p><h4><strong>Foods to eat:</strong></h4>
                                <ul>
                                    <li>Warm water and herbal teas to stay hydrated</li>
                                    <li>Fresh, seasonal fruits and vegetables, preferably cooked</li>
                                    <li>Whole grains such as brown rice, quinoa, and barley</li>
                                    <li>Lean proteins such as chicken, fish, and lentils</li>
                                    <li>Low-fat dairy products such as yogurt and paneer</li>
                                    <li>Digestive spices such as cumin, coriander, and fennel</li>
                                </ul>
                                <h4><strong>Foods to avoid:</strong></h4>
                                <ul>
                                    <li>Excessively cold or ice-cold foods and drinks</li>
                                    <li>Heavy and oily foods such as fried foods and fatty meats</li>
                                    <li>Acidic and sour foods such as citrus fruits and pickles</li>
                                    <li>Excessive salt and salty foods</li>
                                    <li>Red meat and other non-vegetarian foods</li>
                                    <li>Stimulants such as alcohol, caffeine, and tobacco</li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Best Medicine for Kidney Stone or
                                    Infections</strong></h4>
                                <p>Here are some Ayurvedic medicine for kidney stones:</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/punarnavadi-guggulu">Punarnava</a>
                                        (Boerhavia
                                        diffusa)
                                    </li>
                                    <li>Gokshura (Tribulus terrestris)</li>
                                    <li><a href="https://www.draxico.com/products/varunadi-kwath">Varuna</a> (Crataeva
                                        nurvala)
                                    </li>
                                    <li>Kutki</li>
                                    <li>Shatavari</li>
                                    <li><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala</a></li>
                                    <li>Chandraprabha vati</li>
                                    <li>Gokshuradi guggulu</li>
                                    <li><a href="https://www.draxico.com/products/dashamoola-rishta">Dashamoola</a></li>
                                    <li>Chandanasava</li>
                                    <li>Varunadi kwath</li>
                                </ul>
                                <p>These herbs may be taken in various forms such as capsules, powders, or teas, but it
                                    is
                                    important to consult with an Ayurvedic practitioner before taking any herbs.</p><h4>
                                <strong>A
                                    Short Note from Dr. Axico</strong></h4>
                                <p>When treated quickly, kidney infections are rarely dangerous. Maintaining proper
                                    cleanliness
                                    and completely emptying your bladder can help stop UTIs from spreading to your
                                    kidneys.
                                    Ask
                                    Dr. Axcio, Ayurvedic doctor online, about preventing infections if you have a health
                                    issue
                                    that increases your risk of contracting an infection. You can buy Ayurvedic medicine
                                    online
                                    or offline too.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How long does a kidney infection
                                            last?</strong>
                                        </h4>
                                        <p>The duration of a kidney infection can vary depending on the severity of the
                                            infection
                                            and
                                            the individual's response to treatment. With appropriate medical treatment, most
                                            uncomplicated kidney infections can be treated within 7-10 days. However, if the
                                            infection
                                            is more severe or has spread to other parts of the body, it may take longer to treat
                                            and
                                            may
                                            require hospitalization.</p><h4><strong>What can I do at home for a kidney
                                        infection?</strong></h4>
                                        <p>While medical treatment is necessary for a kidney infection, there are some things
                                            you
                                            can do
                                            at home to help alleviate symptoms and promote healing. These include:</p>
                                        <ul>
                                            <li>Stay hydrated by drinking plenty of water and other fluids.</li>
                                            <li>Get plenty of rest to help your body fight the infection.</li>
                                            <li>Apply a heating pad or warm compress to the affected area to help relieve pain
                                                and
                                                discomfort.
                                            </li>
                                            <li>Avoid caffeine, alcohol, and spicy or acidic foods that can irritate the bladder
                                                and
                                                worsen symptoms.
                                            </li>
                                            <li>Practice good hygiene and avoid sexual activity until the infection has cleared
                                                to
                                                prevent the spread of bacteria.
                                            </li>
                                        </ul>
                                        <h4><strong>When should I go to the ER for a kidney infection?</strong></h4>
                                        <p>You should consider going to the emergency room if you experience any of the
                                            following
                                            symptoms:</p>
                                        <ul>
                                            <li>High fever (over 101°F) that is not responding to medication.</li>
                                            <li>Severe pain in the side, back, or abdomen that is not relieved by
                                                over-the-counter
                                                pain
                                                relievers.
                                            </li>
                                            <li>Nausea, vomiting, or diarrhea that is severe or persistent.</li>
                                            <li>Confusion, dizziness, or fainting.</li>
                                            <li>Difficulty breathing or shortness of breath.</li>
                                            <li>Blood in the urine or difficulty urinating.</li>
                                            <li>Rapid heart rate or low blood pressure.</li>
                                        </ul>
                                        <h4><strong>Kidney infection symptoms in women?</strong></h4>
                                        <p>Kidney infection symptoms in women can include:</p>
                                        <ul>
                                            <li>Pain or discomfort in the side, back, or abdomen</li>
                                            <li>Frequent and painful urination</li>
                                            <li>Cloudy or foul-smelling urine</li>
                                            <li>Blood in the urine</li>
                                            <li>Nausea and vomiting</li>
                                            <li>Fever and chills</li>
                                            <li>Fatigue and weakness</li>
                                            <li>Loss of appetite</li>
                                            <li>Women may also experience additional symptoms such as pelvic pain and discomfort
                                                during
                                                sexual intercourse.
                                            </li>
                                        </ul>
                                        <h4><strong>Does kidney infection occur due to kidney stones?</strong></h4>
                                        <p>While kidney stones and kidney infections cause similar symptoms, they are not
                                            directly
                                            related. However, kidney stones can increase the risk of developing a kidney
                                            infection
                                            if
                                            they block the urinary tract and prevent urine from flowing properly, leading to the
                                            build-up of bacteria.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'liver-disease'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4> Liver Diseases: Swollen Liver Illness & Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Liver disease is any condition or illness that affects the liver, which is an
                                    important
                                    organ
                                    that does a lot of important things for the body. The liver cleans the blood of
                                    toxins,
                                    makes bile to help digest fats, stores glucose for energy, and controls how the
                                    blood
                                    clots.
                                    There are many mild to serious types of liver disease, such as viral hepatitis,
                                    fatty
                                    liver
                                    disease, cirrhosis, and liver cancer. Ayurveda has a vast history of curing such
                                    types
                                    of
                                    diseases.&nbsp;<a href="https://www.draxico.com/">Book your Ayurvedic doctor online
                                        consultation</a> now and lead a healthy life.&nbsp;</p>
                                <p>Liver disease is often caused by drinking too much alcohol, getting a virus, being
                                    overweight, having an autoimmune disease, or being born with it. Depending on what
                                    kind
                                    of
                                    liver disease you have and how bad it is, you may have jaundice, tiredness, nausea,
                                    vomiting,&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>, or swelling. Blood tests, imaging studies, or a liver sample may be
                                    used
                                    to
                                    figure out what's wrong.&nbsp;</p>
                                <p>Depending on the type and intensity of the condition, treatment may include
                                    medicines,
                                    changes to the way you live, or, in severe cases, surgery. If you have any signs of
                                    liver
                                    disease, you should see a doctor as soon as possible. Early identification and
                                    treatment
                                    can
                                    improve outcomes.</p>
                                <h3><strong>What is Liver Disease?</strong></h3>
                                <p>Infections, genetic problems, obesity, and alcohol abuse are a few of the many causes
                                    of
                                    liver disease. Over time, liver disease may cause scarring and more serious
                                    consequences.
                                    With early treatment, the damage can be healed and liver failure can be prevented.
                                    Check
                                    if
                                    you fall under the obesity category, by&nbsp;<a
                                        href="https://www.draxico.com/bmi-calculator">checking BMI</a>.&nbsp;</p><h4>
                                <strong>What
                                    is the difference between Liver Disease and Hepatitis?</strong></h4>
                                <p>Hepatitis is a type of liver disease that refers to inflammation of the liver, which
                                    can
                                    be
                                    caused by a variety of factors including viral infections (hepatitis A, B, C, D, and
                                    E),
                                    autoimmune disorders, and exposure to certain pollutants.</p>
                                <p>In contrast, liver disease is a broader term that incorporates any condition or
                                    disorder
                                    affecting the liver. It may also include fatty liver disease,&nbsp;<a
                                        href="https://www.draxico.com/diseases/cirrhosis">cirrhosis</a>, and liver
                                    malignancy.
                                </p>
                                <p>The primary distinction between&nbsp;<a
                                    href="https://www.draxico.com/diseases/hepatitis-a">hepatitis</a>
                                    and liver disease is that hepatitis refers specifically to inflammation of the
                                    liver,
                                    whereas liver disease refers to any condition that affects the liver.</p>
                                <p>Hepatitis symptoms and liver disease symptoms, such as fatigue, nausea, vomiting,
                                    abdominal
                                    discomfort, and jaundice, can be similar. However, depending on the specific form of
                                    liver
                                    disease, the causes and treatments for these conditions can vary.</p>
                                <p>Other varieties of liver disease can be caused by factors such as alcohol abuse,
                                    obesity,
                                    genetics, or exposure to certain medications or toxins, whereas hepatitis is
                                    typically
                                    caused by viral infections. In severe cases, liver disease may necessitate surgery.
                                    Alternatively, lifestyle modifications and/or medication may be used to treat the
                                    condition.
                                    If you experience any liver problem symptoms, you should seek medical attention as
                                    early
                                    diagnosis and treatment can improve outcomes.</p>
                                <p>Take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/liver-health-assessment">free
                                    online self assessment test for liver health</a>.</p>
                                <h3><strong>What are the main factors that cause and symptoms of Liver Disease?</strong>
                                </h3>

                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/liver-disease/Image-1.webp"
                                    >
                                </div>
                                <p>Many factors can cause liver disease, including:</p>
                                <ol>
                                    <li><strong>Alcohol:</strong> Long-term excessive alcohol consumption is a major
                                        cause
                                        of
                                        liver disease, including alcoholic hepatitis and cirrhosis.
                                    </li>
                                    <li><strong>Viral hepatitis</strong>: Viral infections, such as&nbsp;<a
                                        href="https://www.draxico.com/diseases/hepatitis-a">hepatitis A</a>,&nbsp;<a
                                        href="https://www.draxico.com/diseases/hepatitis-b">B</a>, and&nbsp;<a
                                        href="https://www.draxico.com/diseases/hepatitis-c">C</a>, can cause
                                        inflammation of
                                        the
                                        liver and lead to liver disease.
                                    </li>
                                    <li><strong>Non-alcoholic fatty liver disease (NAFLD):</strong> This is a condition
                                        in
                                        which
                                        fat builds up in the liver, and it can be caused by obesity,&nbsp;<a
                                            href="https://www.draxico.com/diseases/cholesterol-level">high
                                            cholesterol</a>,
                                        or&nbsp;<a
                                            href="https://www.draxico.com/diseases/diabetes">diabetes</a>.
                                    </li>
                                    <li><strong>Autoimmune disorders:</strong> In some cases, the immune system
                                        mistakenly
                                        attacks the liver, causing liver disease.
                                    </li>
                                    <li><strong>Genetics:</strong> Inherited liver diseases, such as hemochromatosis and
                                        Wilson's disease, can cause liver damage.
                                    </li>
                                </ol>
                                <p>The symptoms of liver disease can vary depending on the underlying cause and severity
                                    of
                                    the
                                    disease. Some common symptoms of liver disease include:</p>
                                <ol>
                                    <li>Fatigue</li>
                                    <li><a href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">Abdominal
                                        pain</a> or swelling
                                    </li>
                                    <li>Jaundice (yellowing of the skin and eyes)</li>
                                    <li>Nausea and vomiting</li>
                                    <li>Loss of appetite</li>
                                    <li>Dark urine</li>
                                    <li>Pale stool</li>
                                    <li>Itching</li>
                                    <li>Spider-like blood vessels on the skin</li>
                                    <li>Mental confusion</li>
                                </ol>
                                <p>If you experience any of these symptoms, it is important to see a doctor for a
                                    diagnosis
                                    and
                                    treatment. Early detection and management of liver disease can help prevent
                                    complications
                                    and improve outcomes.</p>
                                <h4><strong>What are the Noticeable signs of Liver Disease?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/liver-disease/Image-2.webp"
                                    >
                                </div>
                                <p>Liver disease can have various signs and symptoms, some of which can be subtle and
                                    easily
                                    overlooked, while others can be more noticeable. Here are some noticeable signs of
                                    liver
                                    disease:</p>
                                <ol>
                                    <li><strong>Jaundice:</strong> Yellowing of the skin and eyes is one of the most
                                        common
                                        signs of liver disease. It occurs when there is a buildup of bilirubin, a yellow
                                        pigment, in the blood.
                                    </li>
                                    <li><strong>Fatigue:</strong> Feeling tired and weak even after getting enough sleep
                                        is
                                        another common symptom of liver disease.
                                    </li>
                                    <li><strong>Abdominal pain and swelling:</strong> Pain or discomfort in the upper
                                        right
                                        abdomen, as well as swelling or bloating, can be signs of liver disease.
                                    </li>
                                    <li><strong>Itchy skin:</strong> Itchiness can occur due to a buildup of bile
                                        products
                                        in
                                        the blood that are not being properly processed by the liver.
                                    </li>
                                    <li><strong>Nausea and vomiting:</strong> Nausea and vomiting can be symptoms of
                                        liver
                                        disease, especially if they are accompanied by other symptoms like abdominal
                                        pain or
                                        fatigue.
                                    </li>
                                    <li><strong>Dark urine:</strong> Dark urine can be a sign that your liver is not
                                        functioning
                                        properly, as it can indicate the presence of bilirubin in the urine.
                                    </li>
                                    <li><strong>Pale stools:</strong> Stools that are pale or clay-coloured can also be
                                        a
                                        sign
                                        of liver disease, as the liver produces bile that gives stool its characteristic
                                        brown
                                        colour.
                                    </li>
                                </ol>
                                <p>If you are experiencing any of these symptoms, it is important to see your healthcare
                                    provider for an evaluation, as liver disease can be a serious condition that
                                    requires
                                    medical attention.</p>
                                <h3><strong>What causes Fatty Liver and different types of Liver Disease?</strong></h3>
                                <p>Processed food and Alcohol consumption causes fatty liver. There are numerous liver
                                    disease
                                    causes, and various varieties of liver disease may have distinct causes. The
                                    following
                                    are
                                    some prevalent causes of liver disease:</p>
                                <ul>
                                    <li>Infections with viruses, such as hepatitis A, B, and C, can cause liver
                                        inflammation
                                        and
                                        injury.
                                    </li>
                                    <li>Consumption of alcohol: Excessive and prolonged alcohol consumption can cause
                                        liver
                                        injury and&nbsp;<a
                                            href="https://www.draxico.com/diseases/cirrhosis">cirrhosis</a>.
                                    </li>
                                    <li>Nonalcoholic fatty liver disease (NAFLD): NAFLD is a condition in which fat
                                        accumulates
                                        in the liver, and it can be caused by obesity, insulin resistance, and
                                        hypertension.
                                    </li>
                                    <li>Autoimmune diseases such as autoimmune hepatitis,&nbsp;<a
                                        href="https://www.niddk.nih.gov/health-information/liver-disease/primary-biliary-cholangitis"
                                        rel="nofollow">primary
                                        biliary cholangitis</a>, and primary sclerosing cholangitis can cause the immune
                                        system
                                        to attack the liver, resulting in inflammation and injury.
                                    </li>
                                    <li>Due to the accumulation of certain substances in the liver, genetic conditions
                                        such
                                        as
                                        hemochromatosis, Wilson's disease, and&nbsp;<a
                                            href="https://www.nhlbi.nih.gov/health/alpha-1-antitrypsin-deficiency#:~:text=Alpha%2D1%20antitrypsin%20(AAT),This%20can%20lead%20to%20COPD."
                                            rel="nofollow">alpha-1
                                            antitrypsin deficiency</a> can cause liver injury.
                                    </li>
                                    <li>Certain medications and herbal supplements can show liver damage symptoms,
                                        particularly
                                        when consumed in large quantities or for an extended period.
                                    </li>
                                    <li>Cancer: Chronic liver disease, such as cirrhosis, or other factors, such as
                                        viral
                                        infections or exposure to pollutants, can lead to the development of liver
                                        cancer.
                                    </li>
                                </ul>
                                <p>In some circumstances, the cause of liver disease may be unknown.&nbsp;<a
                                    href="https://www.draxico.com/appointment">Consult a healthcare provider</a> for an
                                    evaluation and appropriate treatment if you suspect that you may have liver disease.
                                </p>
                                <h4>
                                    <strong>What are the complications and risk factors of Liver Disease?</strong></h4>
                                <p>Liver disease encompasses a range of conditions that can affect the functioning and
                                    health of
                                    the liver. The complications and risk factors associated with liver disease depend
                                    on
                                    the
                                    specific type and severity of the condition. Here are some of the common
                                    complications
                                    and
                                    risk factors:</p><h4><strong>Complications:</strong></h4>
                                <ol>
                                    <li>Cirrhosis: Scarring of the liver tissue can occur due to chronic liver disease,
                                        leading
                                        to reduced liver function and blood flow.
                                    </li>
                                    <li>Hepatic encephalopathy: The buildup of toxins in the blood can cause brain
                                        dysfunction
                                        and lead to confusion, memory problems, and coma.
                                    </li>
                                    <li>Portal hypertension: High blood pressure in the portal vein can cause varices
                                        (swollen
                                        veins) in the oesophagus and stomach, which can rupture and cause bleeding.
                                    </li>
                                    <li>Liver cancer: Chronic liver disease can increase the risk of developing liver
                                        cancer.
                                    </li>
                                    <li>Ascites: A buildup of fluid in the abdomen can occur due to liver disease and
                                        cause
                                        discomfort, swelling, and infection.
                                    </li>
                                </ol>
                                <h4><strong>Risk Factors:</strong></h4>
                                <ol>
                                    <li>Heavy alcohol consumption: Drinking excessive amounts of alcohol over a long
                                        period
                                        can
                                        damage liver cells and cause liver disease.
                                    </li>
                                    <li>Obesity: Excess weight can cause nonalcoholic fatty liver disease (NAFLD) and
                                        increase
                                        the risk of developing liver cirrhosis symptoms and liver cancer. Check if you
                                        are
                                        obese
                                        or not, with a&nbsp;<a href="https://www.draxico.com/bmi-calculator">free BMI
                                            checker</a>.
                                    </li>
                                    <li>Viral hepatitis: Hepatitis B and C viruses can cause inflammation and damage to
                                        liver
                                        cells, leading to chronic liver disease.
                                    </li>
                                    <li>Genetics: Certain genetic mutations can increase the risk of developing liver
                                        disease.
                                    </li>
                                    <li>Medications and toxins: Certain medications, supplements, and environmental
                                        toxins
                                        can
                                        cause liver damage if taken in high doses or over a prolonged period.
                                    </li>
                                </ol>
                                <h4><strong>When to see a Doctor for Liver Disease and how it is diagnosed?</strong>
                                </h4>
                                <p>Consult a physician if you experience abdominal pain, jaundice (yellowing of the skin
                                    and
                                    eyes), dark urine, fatigue, unexplained weight loss, or puffiness of the legs and
                                    ankles.
                                    These symptoms may be indicative of liver disease; therefore, prompt medical
                                    attention
                                    is
                                    necessary for a proper diagnosis and treatment.&nbsp;</p>
                                <p><strong>To diagnose liver disease, your physician may conduct a physical examination,
                                    examine
                                    your medical history, and order diagnostic tests including:</strong></p>
                                <ul>
                                    <li>Blood tests can measure liver enzymes and other indicators of liver function, as
                                        well as
                                        detect viral hepatitis and other liver-related conditions.
                                    </li>
                                    <li>Imaging tests: Imaging tests such as ultrasound, computed tomography (CT), and
                                        magnetic
                                        resonance imaging (MRI) can assist in visualising the liver and identifying
                                        abnormalities such as tumours and cirrhosis.
                                    </li>
                                    <li>A liver biopsy is performed by removing a small sample of liver tissue with a
                                        needle
                                        and
                                        analysing it under a microscope to detect inflammation, fibrosis, or other
                                        abnormalities.
                                    </li>
                                    <li>Endoscopy involves inserting a flexible tube equipped with a camera down the
                                        throat
                                        to
                                        examine the oesophagus and stomach for indications of bleeding or varices.
                                    </li>
                                    <li>In some instances, a combination of these tests may be required to accurately
                                        diagnose
                                        liver disease and determine the most effective treatment.
                                    </li>
                                </ul>
                                <h3><strong>Ayurvedic Prevention of Liver Disease</strong>&nbsp;</h3>
                                <p>Ayurveda recommends several actions that may be taken to keep the liver healthy and
                                    cut
                                    down
                                    on the likelihood of developing liver problems. Practises from the Ayurvedic
                                    tradition
                                    that
                                    may enhance liver health include the following:</p>
                                <p>Diet: To maintain a healthy weight, it is important to eat a diet that is
                                    well-balanced
                                    and
                                    consists of fresh fruits, vegetables, lean meats, whole grains, and healthy fats.
                                    Steer
                                    clear of meals that have been processed, since they tend to be high in sugar and
                                    refined
                                    carbs and contain bad fats.</p>
                                <p>Herbs That Are Beneficial to the Liver Include Turmeric, Milk Thistle, Licorice Root,
                                    Dandelion Root, Kutki, and Bhumi Amla among other Ayurvedic herbs that are
                                    well-known
                                    for
                                    their liver-protecting capabilities they possess. These herbs have been shown to
                                    assist
                                    promote liver function while also protecting against harm to the liver.</p>
                                <p><a href="https://www.draxico.com/diseases/dehydration">Dehydration</a> is also a
                                    major
                                    cause,
                                    so maintaining proper hydration is essential for supporting the liver's natural
                                    detoxifying
                                    processes and ensuring that it continues to operate at its full potential.</p>
                                <p>Following Ayurvedic principles for good digestion, such as eating thoughtfully,
                                    chewing
                                    food
                                    completely, avoiding overeating, and enjoying warm, prepared meals, will help ensure
                                    that
                                    food is properly broken down and absorbed by the body. Ineffective digestion may
                                    result
                                    in
                                    the buildup of toxins throughout the body, which places additional stress on the
                                    liver.</p>
                                <p>Steer clear of alcohol and cigarettes if you want to protect your liver from the
                                    severe
                                    harm
                                    that may be caused by excessive use of these substances. If you want your liver to
                                    function
                                    at its best, you should steer clear of or at least cut down on these chemicals.</p>
                                <p>Engage in regular physical activity to support your overall health, including the
                                    function of
                                    your liver. This includes regular exercise. Regular exercise may assist increase
                                    blood
                                    circulation as well as metabolism and the processes of detoxification.</p>
                                <p>Managing Stress Prolonged stress may hurt the health of the liver. You may reduce
                                    your
                                    stress
                                    by engaging in activities that promote relaxation, such as yoga, meditation, or deep
                                    breathing exercises, or by participating in hobbies that you like.</p>
                                <p>Detoxification and Liver Cleaning: Regular liver detoxification or cleaning might be
                                    advantageous. It is best to seek the advice of an Ayurvedic practitioner to
                                    establish
                                    which
                                    liver cleansing procedures are most suitable for you, given your unique
                                    characteristics
                                    and
                                    requirements.</p>
                                <p>Adopting Ayurvedic Lifestyle Practises One should adopt Ayurvedic lifestyle practices
                                    such as
                                    adhering to a regular daily regimen, receiving an appropriate amount of sleep, and
                                    staying
                                    away from excessive exposure to environmental pollutants.</p><h4><strong>Ayurvedic
                                Remedies
                                for Liver Disease?</strong></h4>
                                <p>Ayurveda, a traditional Indian medical system, offers a variety of liver-supporting
                                    and
                                    liver-disease-treating remedies. It is essential to note, however, that these
                                    remedies
                                    should only be used under the supervision of a qualified Ayurvedic practitioner and
                                    not
                                    as a
                                    replacement for medical treatment. Here are some&nbsp;<a
                                        href="https://www.draxico.com/appointment">liver diseases Ayurvedic
                                        treatments</a>:
                                </p>
                                <ul>
                                    <li>Curcuma longa: Curcuma longa is a potent anti-inflammatory herb that can support
                                        liver
                                        health. In Ayurvedic medicine, it is frequently used to treat liver disease and
                                        enhance
                                        liver function.
                                    </li>
                                    <li>Milk thistle: Milk thistle is a herb with hepatoprotective properties that can
                                        aid
                                        in
                                        protecting the liver from injury.
                                    </li>
                                    <li>Amla (Indian gooseberry) is a nutrient-dense source of antioxidants that can
                                        support
                                        liver health and function.
                                    </li>
                                    <li>Kutki is a common herb used for liver Ayurvedic medicine to treat liver disease
                                        and
                                        enhance liver function. It has been demonstrated to possess hepatoprotective
                                        properties
                                        and can aid in preventing liver injury.
                                    </li>
                                    <li>Licorice is a herb with anti-inflammatory and hepatoprotective properties that
                                        can
                                        help
                                        maintain liver health.
                                    </li>
                                    <li>Triphala is an Ayurvedic botanical combination that can support liver health and
                                        function. It contains three fruits: amla, haritaki, and bibhitaki, and
                                        hepatoprotective
                                        properties have been demonstrated.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat and Not</strong></h4>
                                <p>Maintaining a healthy diet is important for supporting liver health. Here are some
                                    dietary
                                    guidelines for what to eat and what to avoid:&nbsp;</p><h4><strong>What to
                                eat:</strong>
                            </h4>
                                <ul>
                                    <li><strong>Fruits and vegetables:</strong> Fruits and vegetables are high in fiber,
                                        antioxidants, and nutrients that can help support liver health. Some good
                                        options
                                        include leafy greens, broccoli, carrots, and berries.
                                    </li>
                                    <li><strong>Whole grains:</strong> Whole grains are a good source of fiber and can
                                        help
                                        reduce the risk of fatty liver disease symptoms. Some good options include whole
                                        wheat
                                        bread, brown rice, and quinoa.
                                    </li>
                                    <li><strong>Lean protein:</strong> Lean protein sources such as chicken, fish, and
                                        tofu
                                        can
                                        help support liver function.
                                    </li>
                                    <li><strong>Healthy fats:</strong> Healthy fats such as those found in nuts, seeds,
                                        and
                                        olive oil can help reduce inflammation and support liver health.
                                    </li>
                                    <li><strong>Water:</strong> Drinking plenty of water can help flush toxins out of
                                        the
                                        body
                                        and support liver function.
                                    </li>
                                </ul>
                                <h4><strong>What to avoid:</strong></h4>
                                <ul>
                                    <li><strong>Alcohol:</strong> Alcohol is a major contributor to liver disease and
                                        should
                                        be
                                        avoided or consumed in moderation.
                                    </li>
                                    <li><strong>Processed foods:</strong> Processed foods are often high in unhealthy
                                        fats,
                                        salt, and sugar, which can contribute to fatty liver disease and other liver
                                        problems.
                                    </li>
                                    <li><strong>Refined sugars:</strong> Refined sugars can contribute to fatty liver
                                        disease
                                        and other liver problems, so it's best to avoid them.
                                    </li>
                                    <li><strong>Saturated and trans fats:</strong> Saturated and trans fats can
                                        contribute
                                        to
                                        liver disease and should be avoided or consumed in moderation.
                                    </li>
                                    <li><strong>Excess salt:</strong> Excess salt can contribute to fluid build-up in
                                        the
                                        body
                                        and liver problems, so it's best to avoid high-salt foods.&nbsp;
                                    </li>
                                </ul>
                                <p>Overall, a balanced diet that includes plenty of fruits, vegetables, whole grains,
                                    lean
                                    protein sources, and healthy fats can help support liver health. It's also important
                                    to
                                    avoid or limit alcohol consumption and processed foods, refined sugars, saturated
                                    and
                                    trans
                                    fats, and excess salt.</p><h4><strong>Recommended Ayurvedic Herbs and Medication for
                                Liver
                                Disease?</strong></h4>
                                <p>There are several Ayurvedic herbs and medications that are commonly recommended for
                                    liver
                                    disease. However, it's important to note that these remedies should be used under
                                    the
                                    guidance of a qualified Ayurvedic practitioner and should not be used as a
                                    substitute
                                    for
                                    medical treatment. Here are some Ayurvedic herbs and medications that may be
                                    recommended
                                    for
                                    liver disease:</p>
                                <ol>
                                    <li><strong>Arogyavardhini Vati:</strong>&nbsp;<a
                                        href="https://www.draxico.com/products/arogyavardhini-vati">Arogyavardhini
                                        Vati</a>
                                        is
                                        an Ayurvedic herbal medication that is often used to treat liver disease and
                                        improve
                                        liver function.
                                    </li>
                                    <li><strong>Kutki:</strong> Kutki is an Ayurvedic herb that has been shown to have
                                        hepatoprotective properties and can help protect the liver from damage.
                                    </li>
                                    <li><strong>Punarnava:</strong> Punarnava is an Ayurvedic herb that can help reduce
                                        inflammation and support liver health.
                                    </li>
                                    <li><strong>Bhumi Amla:</strong> Bhumi Amla is an Ayurvedic herb that can help
                                        support
                                        liver
                                        function and protect the liver from damage.
                                    </li>
                                </ol>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>Liver infection symptoms, hereditary conditions, cancer, or an excess of toxic
                                    substances
                                    can
                                    all cause liver disease. With the help of medicine or dietary modifications,
                                    healthcare
                                    professionals can successfully cure a variety of liver diseases. An Ayurvedic doctor
                                    consultation may improve your health and lengthen your life if you have liver
                                    disease.
                                    In
                                    Addition, you can buy Ayurvedic medicine online or offline too.&nbsp;</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>What are the first signs of liver
                                            disease?</strong>
                                        </h4>
                                        <p>In the early stages of liver disease, symptoms may be mild and may not be noticeable.
                                            However, as the disease progresses, symptoms may become more noticeable. Here are
                                            some
                                            of
                                            the early signs and symptoms of liver disease:</p>
                                        <ol>
                                            <li>Fatigue: Feeling tired or weak is a common early symptom of liver disease.</li>
                                            <li>Loss of appetite: A decrease in appetite or feeling full after eating a small
                                                amount
                                                can
                                                be a sign of liver disease.
                                            </li>
                                            <li>Nausea and vomiting: Feeling sick to your stomach or vomiting can be a sign of
                                                liver
                                                disease.
                                            </li>
                                            <li>Abdominal pain and swelling: Pain or swelling in the abdomen can be a sign of
                                                liver
                                                disease.
                                            </li>
                                            <li>Jaundice: Jaundice is a condition where the skin and eyes turn yellow. It is
                                                caused
                                                by a
                                                buildup of bilirubin, a waste product in the blood that is normally processed by
                                                the
                                                liver.
                                            </li>
                                            <li>Dark urine: Urine may be darker than usual due to the buildup of bilirubin.</li>
                                        </ol>
                                        <h4><strong>Liver disease symptoms in women?</strong></h4>
                                        <p>The symptoms of liver disease can be similar in both men and women. However, some
                                            symptoms
                                            may be more common in women. These include:</p>
                                        <ol>
                                            <li>Menstrual irregularities: Women with liver disease may experience changes in
                                                their
                                                menstrual cycles, such as heavier or lighter periods, missed periods, or
                                                irregular
                                                bleeding.
                                            </li>
                                            <li>Hormonal imbalances: The liver plays a role in hormone regulation, so liver
                                                disease
                                                can
                                                lead to hormonal imbalances in women. This can cause symptoms such as acne, hair
                                                loss,
                                                or abnormal hair growth.
                                            </li>
                                            <li>Fatigue: Women with liver disease may experience fatigue or weakness more
                                                frequently
                                                than men.
                                            </li>
                                            <li>Abdominal pain and swelling: Pain or swelling in the abdomen can be a sign of
                                                liver
                                                disease in both men and women.
                                            </li>
                                            <li>Jaundice: Women with liver disease may experience jaundice, which is a condition
                                                where
                                                the skin and eyes turn yellow.
                                            </li>
                                            <li>Itching: Women with liver disease may experience itching, especially on the
                                                palms of
                                                the
                                                hands or soles of the feet.
                                            </li>
                                            <li>Spider angiomas: Spider angiomas are small, spider-like blood vessels that can
                                                appear on
                                                the skin, especially on the face, neck, or chest. They are more common in women
                                                with
                                                liver disease than in men.
                                            </li>
                                        </ol>
                                        <p>It's vital to keep in mind that these symptoms can also be brought on by other
                                            illnesses,
                                            so
                                            it's crucial to see a doctor for a precise diagnosis. It's crucial to get medical
                                            help
                                            if
                                            you have any worries about your liver's health or if you are exhibiting symptoms of
                                            liver
                                            disease.</p><h4><strong>Best Ayurvedic medicine for liver&nbsp;</strong></h4>
                                        <ol>
                                            <li>Arogyavardhini Vati: Arogyavardhini Vati is an Ayurvedic herbal medication that
                                                is
                                                often
                                                used to treat liver disease and improve liver function.
                                            </li>
                                            <li>Kutki: Kutki is an Ayurvedic herb that has been shown to have hepatoprotective
                                                properties and can help protect the liver from damage.
                                            </li>
                                            <li>Punarnava: Punarnava is an Ayurvedic herb that can help reduce inflammation and
                                                support
                                                liver health.
                                            </li>
                                            <li>Bhumi Amla: Bhumi Amla is an Ayurvedic herb that can help support liver function
                                                and
                                                protect the liver from damage.
                                            </li>
                                        </ol>
                                        <h4><strong>Signs of liver struggles?</strong></h4>
                                        <p>Liver struggles can manifest in different ways depending on the underlying cause and
                                            severity
                                            of the condition. Here are some common signs of liver struggles:</p>
                                        <ol>
                                            <li>Fatigue and weakness: The liver plays a vital role in producing energy for the
                                                body.
                                                If
                                                the liver is struggling, you may feel tired and weak.
                                            </li>
                                            <li>Loss of appetite and weight loss: When the liver struggles, it may affect your
                                                ability
                                                to digest food and absorb nutrients, leading to a loss of appetite and weight
                                                loss.
                                            </li>
                                            <li>Nausea, vomiting, and diarrhoea: These symptoms may occur due to impaired
                                                digestion
                                                and
                                                elimination of waste products.
                                            </li>
                                            <li>Abdominal pain and swelling: Pain or discomfort in the upper right quadrant of
                                                the
                                                abdomen, as well as swelling or distention of the abdomen, can be a sign of
                                                liver
                                                struggles.
                                            </li>
                                            <li>Jaundice: Jaundice is a condition where the skin and whites of the eyes turn
                                                yellow.
                                                It
                                                is caused by a build-up of bilirubin, a waste product in the blood that is
                                                normally
                                                processed by the liver.
                                            </li>
                                            <li>Itching: Itching can be a sign of liver struggles, especially on the palms of
                                                the
                                                hands
                                                or soles of the feet.
                                            </li>
                                            <li>Dark urine and pale stools: Dark urine and pale stools can occur due to the
                                                buildup
                                                of
                                                bilirubin in the blood.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'migraine'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Migraine: Side Effects, Signs, Causes & Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Migraines are a common and often debilitating condition that affects millions of
                                    people
                                    around the world. For those who suffer from migraines, the pain, nausea, and other
                                    symptoms
                                    can significantly impact their daily lives and make it difficult to perform even
                                    simple
                                    tasks.&nbsp;<a href="https://www.draxico.com/">Ayurvedic doctor online
                                        consultation</a>
                                    would be helpful to cure such types of diseases.&nbsp;</p>
                                <p>Look at some of the common triggers for migraines and discuss lifestyle changes that
                                    can
                                    help
                                    manage symptoms. Whether you are someone who suffers from migraines or simply want
                                    to
                                    learn
                                    more about this condition, will provide valuable insights and information.</p>
                                <h3><strong>What are Migraines and how does it feel?</strong></h3>
                                <p>Migraines are a&nbsp;<a href="https://www.draxico.com/diseases/headaches">type of
                                    headache</a> that is typically characterised by intense, throbbing pain on one or
                                    both
                                    sides
                                    of the head. Migraines can also cause a range of other symptoms, including nausea,
                                    vomiting,
                                    sensitivity to light and sound, and visual disturbances such as flashing lights or
                                    blind
                                    spots.</p>
                                <p>Migraines are believed to be caused by changes in the brain and surrounding blood
                                    vessels,
                                    which can trigger the release of inflammatory substances and cause pain signals to
                                    be
                                    sent
                                    to the brain. Migraine triggers can vary widely between individuals but may include
                                    stress,
                                    hormonal changes, certain foods or drinks, and changes in sleep patterns or
                                    weather.</p>
                                <p>Migraine pain can be severe and debilitating, often lasting for several hours or even
                                    days.
                                    It can also interfere with daily activities and significantly impact the quality of
                                    life.</p>
                                <h4><strong>What is the difference between Migraines and headaches?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/migraine/Image.webp"
                                    >
                                </div>
                                <p>Migraines and headaches are both types of pain that affect the head, but there are
                                    some
                                    key
                                    differences between them.</p>
                                <p>Headaches are a common condition that can be caused by a variety of factors,
                                    including
                                    tension, sinus problems,&nbsp;<a
                                        href="https://www.draxico.com/diseases/dehydration">dehydration</a>,
                                    and even certain medication. Headaches are typically characterised by a dull, aching
                                    pain
                                    that can be felt on both sides of the head and may also be accompanied by
                                    sensitivity to
                                    light and sound.</p>
                                <p>Migraines, on the other hand, are a specific type of headache that is often more
                                    severe
                                    and
                                    can be accompanied by a range of other symptoms, including nausea, vomiting,
                                    sensitivity
                                    to
                                    light and sound, and visual disturbances. Migraines can also be triggered by
                                    specific
                                    factors, such as stress, hormonal changes, or certain foods or drinks.</p>
                                <p>Another key difference between migraines and headaches is that migraines tend to be
                                    more
                                    disruptive to daily life and can last for hours or even days, while headaches are
                                    often
                                    shorter in duration and less severe.</p>
                                <h3><strong>What are the main factors that cause and symptoms of Migraine?</strong></h3>
                                <h4>
                                    <strong>Migraine Causes -</strong></h4>
                                <p>&nbsp;Migraine headaches are a complex and poorly understood origin. It is because
                                    certain
                                    nerves in your blood vessels deliver pain messages to your brain that you get
                                    headaches.
                                    This causes the nerves and blood vessels in your head to become inflamed. Why your
                                    nerves do
                                    that is unknown.</p><h4><strong>Migraine Symptoms -</strong></h4>
                                <p>Headache is the primary symptom of a migraine. Some people describe their pain as
                                    throbbing
                                    or thumping. A dull discomfort may develop into mild, moderate, or severe pulsing
                                    pain
                                    over
                                    time. If you don't receive treatment, your headache suffering will get worse. Pain
                                    may
                                    travel from one side of your head to the other in addition to affecting the front
                                    and
                                    back
                                    or making you feel like your entire head is being affected. Some people have pain in
                                    their
                                    jaw, neck, sinuses, face, temples, or around their eyes.</p><h4><strong>Other
                                symptoms
                                of
                                migraine headaches include:</strong></h4>
                                <ul>
                                    <li>Nausea and vomiting, upset stomach, and abdominal pain</li>
                                    <li>Sensitivity to light, noise, and odors</li>
                                    <li>Loss of appetite</li>
                                    <li>Fever (rare)</li>
                                    <li>Pale skin color (pallor)</li>
                                    <li>Feeling very warm (sweating) or cold (chills)</li>
                                    <li>Dizziness and blurred vision</li>
                                    <li>Feeling tired</li>
                                    <li>Tender scalp</li>
                                    <li>Diarrhea (rare)</li>
                                </ul>
                                <p>If you are facing any of the above symptoms, then you can take a&nbsp;
                                    <a href="https://www.draxico.com/self-assessment/general-health-assessment"> free
                                        self
                                        assessment test for migraine</a>.
                                </p><h4><strong>What are the Noticeable signs of Migraine?</strong></h4>
                                <ul>
                                    <li>Headache that gets worse with exertion or physical exercise.</li>
                                    <li>A pain-related inability to carry out routine tasks.</li>
                                    <li>Enhanced light and sound sensitivity that might occasionally be relieved by
                                        lying
                                        still
                                        in a gloomy space.
                                    </li>
                                </ul>
                                <h3><strong>What are the types of headaches? What type of headache is a
                                    migraine?</strong>
                                </h3>
                                <p>There are several types of headaches, each with its specific symptoms and causes.
                                    Some of
                                    the
                                    most common types of headaches include tension headaches, cluster headaches, sinus
                                    headaches, and migraine headaches.</p>
                                <p>Migraines are a specific type of headache that is typically characterised by a
                                    pulsating
                                    or
                                    throbbing pain on one side of the head, although it can also occur on both sides.
                                    Migraines
                                    can also be accompanied by a range of other symptoms, such as sensitivity to light
                                    and
                                    sound, <a href="https://en.wikipedia.org/wiki/Nausea" rel="nofollow"> nausea</a>,
                                    and
                                    vomiting. Migraines can be triggered by a variety of factors, including
                                    stress, hormonal changes, certain foods and drinks, and environmental factors.</p>
                                <p>It's worth noting that while migraines are a type of headache, not all headaches are
                                    migraines. Tension headaches, for example, are a more common type of headache that
                                    is
                                    typically characterized by mild to moderate pain that can be felt on both sides of
                                    the
                                    head.
                                    Sinus headaches, on the other hand, are caused by inflammation in the sinus passages
                                    and
                                    are
                                    often characterized by pain in the forehead, cheekbones, and bridge of the nose.</p>
                                <h4>
                                    <strong>What is an aura according to Ayurveda?</strong></h4>
                                <p>According to&nbsp;<a href="https://www.draxico.com/about/ayurveda">Ayurveda</a>, an
                                    aura
                                    is a
                                    subtle energy field that surrounds the body. It is believed that the aura is made up
                                    of
                                    different layers or sheaths, each with its unique energy and vibration. In Ayurveda,
                                    the
                                    aura is considered to be an important aspect of overall health and well-being, as it
                                    is
                                    believed to reflect the state of a person's physical, emotional, and spiritual
                                    health.
                                </p>
                                <p>Ayurvedic practitioners may use a variety of techniques to help balance and
                                    strengthen
                                    the
                                    aura, including the use of herbs, meditation, and other holistic therapies. The goal
                                    of
                                    these migraine headache treatments is to help restore balance and harmony to the
                                    aura,
                                    which
                                    can have a positive impact on a person's overall health and well-being.</p>
                                <h3><strong>What are the four stages or phases of a migraine?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/migraine/Image-2.webp"
                                    >
                                </div>
                                <p>Migraines are often described as having four stages or phases, although not everyone
                                    experiences all four. These phases are:</p>
                                <ol>
                                    <li>Prodromal phase: This is the first phase of a migraine, which can occur several
                                        hours or
                                        even days before the onset of the headache itself. Symptoms during this phase
                                        can
                                        include mood changes, fatigue, food cravings, and neck stiffness.
                                    </li>
                                    <li>Aura phase: Not all migraines have an aura, but for those who do, this phase
                                        occurs
                                        just
                                        before the headache itself. Auras can manifest as visual disturbances such as
                                        flashes of
                                        light, blind spots, or zigzag lines, but can also include other sensory symptoms
                                        like
                                        tingling in the face or hands.
                                    </li>
                                    <li>Headache phase: This is the most well-known phase of a migraine and typically
                                        involves
                                        severe head pain on one or both sides of the head, along with other symptoms
                                        like
                                        nausea, vomiting, and sensitivity to light and sound.
                                    </li>
                                    <li>Postdrome phase: This final phase occurs after the headache has subsided and can
                                        last
                                        for several hours or even days. Symptoms during this phase can include fatigue,
                                        mood
                                        changes, and difficulty concentrating.
                                    </li>
                                </ol>
                                <h4><strong>When to see a Doctor for Migraine and how it is
                                    diagnosed?</strong></h4>
                                <p>It is recommended to see a doctor if you experience frequent or severe migraines,
                                    especially
                                    if they interfere with your daily life and activities. You should also seek medical
                                    attention if your migraines are accompanied by other symptoms such as numbness,
                                    confusion,
                                    or difficulty speaking.</p>
                                <p>To diagnose a migraine, your doctor will typically perform a physical exam and review
                                    your
                                    medical history. They may also order imaging tests like an MRI or CT scan to rule
                                    out
                                    other
                                    conditions that can cause similar symptoms.</p>
                                <p>Keeping a migraine diary, in which you record the frequency and severity of your
                                    headaches as
                                    well as any triggers or other symptoms, can help identify patterns and diagnose
                                    migraines.</p>

                                <p>You can also book a&nbsp;<a href="https://www.draxico.com/appointment">free online
                                    appointment with an Ayurvedic Doctor</a>.</p>
                                <h3><strong>Ayurvedic Prevention of Migraine</strong></h3>
                                <p>Ayurveda offers several preventative measures to reduce the frequency and severity of
                                    migraines. These include:</p>
                                <p>Following a regular daily routine, including waking up and going to bed at the same
                                    time
                                    each
                                    day</p>
                                <ul>
                                    <li>Avoiding triggers such as stress, bright lights, strong smells, and certain
                                        foods
                                    </li>
                                    <li>Practising relaxation techniques like yoga, meditation, or deep breathing
                                        exercises
                                    </li>
                                    <li>Maintaining a balanced diet and staying hydrated</li>
                                    <li>Taking herbal supplements like brahmi, shankhapushpi, and jatamansi, which have
                                        been
                                        shown to have a calming effect on the nervous system
                                    </li>
                                    <li>Massaging the scalp and forehead with warm sesame oil or herbal oils like
                                        bhringraj
                                        or
                                        jatamansi
                                    </li>
                                    <li>Practising nasal irrigation with warm saline water to clear the sinuses and
                                        reduce
                                        congestion.
                                    </li>
                                </ul>
                                <p>It is important to consult with a qualified practitioner before starting any new
                                    migraine
                                    ayurvedic treatment or supplement regimen.</p><h4><strong>Ayurvedic home remedies
                                for
                                migraine</strong></h4>
                                <ul>
                                    <li><strong>Ginger tea:</strong> Ginger is known for its anti-inflammatory
                                        properties,
                                        which
                                        can help alleviate migraine symptoms. Make tea by boiling 1 inch of grated
                                        ginger in
                                        2
                                        cups of water for 10 minutes. Strain the tea and add honey and lemon juice to
                                        taste.
                                    </li>
                                    <li><strong>Peppermint oil:</strong> Peppermint oil has a cooling effect and can
                                        help
                                        relieve headaches. Mix 3-4 drops of peppermint oil in a tablespoon of carrier
                                        oil
                                        such
                                        as coconut or olive oil. Apply the mixture to your temples, forehead, and the
                                        back
                                        of
                                        your neck.
                                    </li>
                                    <li><strong>Cinnamon powder:</strong> Cinnamon is a natural anti-inflammatory and
                                        can
                                        help
                                        relieve migraine symptoms. Mix 1 teaspoon of cinnamon powder in 1 cup of hot
                                        water.
                                        Drink this tea twice a day for relief.
                                    </li>
                                    <li><strong>Lemon water:</strong> Dehydration can trigger migraines, so it's
                                        important
                                        to
                                        stay hydrated. Drink a glass of water with half a lemon in it first thing in the
                                        morning. This will not only hydrate you but also help detoxify your body.
                                    </li>
                                    <li><strong>Yoga and meditation:</strong> Migraines are frequently brought on by
                                        stress.
                                        Yoga and meditation are two practices that can ease stress and encourage
                                        relaxation.
                                        Try
                                        practising yoga poses such as child's pose, forward fold, and seated twists.
                                    </li>
                                </ul>
                                <h4><strong>What to Eat:</strong></h4>
                                <ol>
                                    <li>Fresh, whole foods: Ayurveda recommends eating fresh, whole foods that are
                                        cooked
                                        from
                                        scratch. This includes a variety of fruits, vegetables, grains, legumes, nuts,
                                        and
                                        seeds.
                                    </li>
                                    <li>Seasonal foods: Ayurveda recommends eating foods that are in season, as they are
                                        more
                                        easily digested and provide optimal nutrition.
                                    </li>
                                    <li>Balanced meals: Ayurveda recommends eating balanced meals that contain all six
                                        tastes -
                                        sweet, sour, salty, pungent, bitter, and astringent. This helps ensure that all
                                        of
                                        the
                                        body's nutritional needs are met.
                                    </li>
                                    <li>Herbal teas: Ayurveda recommends drinking herbal teas such as ginger tea,
                                        chamomile
                                        tea,
                                        and peppermint tea. These teas can help promote digestion and provide a range of
                                        health
                                        benefits.
                                    </li>
                                    <li>Ghee: Ayurveda recommends using ghee, a type of clarified butter, in cooking.
                                        Ghee
                                        is
                                        believed to have numerous health benefits and is considered a sattvic food,
                                        which
                                        promotes clarity of mind and spiritual awareness.
                                    </li>
                                </ol>
                                <h4><strong>What to Avoid:</strong></h4>
                                <ol>
                                    <li>Processed foods: Ayurveda recommends avoiding processed foods, which are often
                                        high
                                        in
                                        preservatives, additives, and other chemicals that can be harmful to health.
                                    </li>
                                    <li>Heavy foods: Ayurveda recommends avoiding heavy, greasy foods that are difficult
                                        to
                                        digest, such as deep-fried foods, red meat, and dairy products.
                                    </li>
                                    <li>Cold foods: Ayurveda recommends avoiding cold foods and drinks, as they can
                                        disrupt
                                        digestion and weaken the digestive fire.
                                    </li>
                                    <li>Sugary foods: Ayurveda recommends avoiding sugary foods, which can lead to
                                        imbalances in
                                        blood sugar levels and contribute to a range of health problems.
                                    </li>
                                </ol>

                                <p>Incompatible food combinations: Ayurveda recommends avoiding incompatible food
                                    combinations,
                                    such as mixing milk and sour fruits, which can lead to digestive problems and other
                                    health
                                    issues.</p>
                                <h4><strong>Recommended Ayurvedic Herbs and Migraine Ayurvedic Medicine</strong>
                                </h4>
                                <p>Here are some best medicines for migraine:</p>
                                <h4><strong>Single Herbs:</strong></h4>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/ashwagandha-rishta">Ashwagandha</a>
                                    </li>
                                    <li>Turmeric</li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala</a></li>
                                    <li><a href="https://www.draxico.com/products/brahmi-vati">Brahmi</a></li>
                                    <li><a href="https://www.draxico.com/products/vathar-guggulu">Guggulu</a></li>
                                </ul>
                                <h4><strong>Formulations:</strong></h4>
                                <ul>
                                    <li>Chyawanprash</li>
                                    <li>Trikatu</li>
                                    <li>Mahasudarshan Churna</li>
                                    <li>Ashwagandharishta</li>
                                    <li>Amalaki Rasayana</li>
                                    <li>Shatavari</li>
                                    <li>Yashtimadhu churna</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>Debilitating migraine headaches can make it difficult for sufferers to carry out
                                    daily
                                    tasks
                                    like attending work or school. Thankfully, there are some techniques to potentially
                                    avoid
                                    getting a migraine as well as other strategies to assist you cope with and survive
                                    the
                                    symptoms.&nbsp;</p>
                                <p>Speak with Dr. Axico Ayurvedic doctor online about infection prevention if you have a
                                    health
                                    issue that increases your risk of contracting one. Moreover, you can buy Ayurvedic
                                    medicine
                                    online or offline too.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>What causes migraine in females?</strong>
                                        </h4>
                                        <p>Some common triggers for migraines in females:</p>
                                        <ul>
                                            <li>Hormonal changes, such as fluctuations in estrogen levels during menstrual
                                                cycles,
                                                pregnancy, and menopause
                                            </li>
                                            <li>Stress and anxiety</li>
                                            <li>Changes in sleep patterns or less sleep</li>
                                            <li>Certain foods and beverages, such as alcohol, caffeine, aged cheese, chocolate,
                                                and
                                                processed foods
                                            </li>
                                            <li>Environmental factors, such as bright lights, strong odors, and changes in
                                                weather
                                                or
                                                barometric pressure
                                            </li>
                                            <li>Skipping meals or fasting</li>
                                            <li>Physical exertion or overexertion</li>
                                            <li>Certain migraine medications, such as hormonal contraceptives or vasodilators
                                            </li>
                                            <li>Genetics and family history of migraines</li>
                                            <li>Head or neck trauma.</li>
                                        </ul>
                                        <h4><strong>What causes migraine in kids?</strong></h4>
                                        <p>Some common triggers for migraines in kids:</p>
                                        <ul>
                                            <li>Stress and anxiety</li>
                                            <li>Changes in sleep patterns or less sleep</li>
                                            <li>Certain foods and beverages, such as chocolate, aged cheese, and processed foods
                                            </li>
                                            <li>Dehydration or not drinking enough fluids</li>
                                            <li>Changes in weather or barometric pressure</li>
                                            <li>Sensory overloads, such as exposure to bright lights, loud noises, or strong
                                                smells
                                            </li>
                                            <li>Skipping meals or not eating enough</li>
                                            <li>Physical activity or overexertion</li>
                                            <li>Genetics and family history of migraines</li>
                                            <li>Certain medications or medical conditions, such as ADHD or depression.</li>
                                        </ul>
                                        <h4><strong>How to cure migraine naturally?</strong></h4>
                                        <p>Some natural ways to manage migraines:</p>
                                        <ul>
                                            <li>Avoid triggers, such as certain foods, stress, and lack of sleep</li>
                                            <li>Practice stress-reduction techniques, such as deep breathing exercises and
                                                yoga
                                            </li>
                                            <li>Good sleep and maintain a regular sleep schedule</li>
                                            <li>Stay hydrated by drinking enough water and avoiding alcohol and caffeine</li>
                                            <li>Apply heat or cold compresses to the affected area</li>
                                            <li>Massage the neck and temples to relieve tension and muscle stiffness</li>
                                            <li>Try herbal remedies, such as ginger, feverfew, and butterbur, which have
                                                anti-inflammatory and pain-relieving properties
                                            </li>
                                            <li>
                                                <a href="https://www.draxico.com/panchakarma-treatment/shirodhara">Shirodhara</a>,
                                                <a
                                                    href="https://www.draxico.com/panchakarma-treatment/nasyam">Nasya</a>,
                                                Acupuncture, etc
                                                therapies may also help alleviate migraine
                                                symptoms.
                                            </li>
                                        </ul>
                                        <h4><strong>Migraine attack home remedies</strong></h4>
                                        <p>Here are some home remedies for migraine attacks:</p>
                                        <ul>
                                            <li>Lie down in a quiet, dark room and rest</li>
                                            <li>Apply a cold or hot compress to the affected area</li>
                                            <li>Massage the temples and neck to relieve tension and muscle stiffness</li>
                                            <li>Drink plenty of water to stay hydrated</li>
                                            <li>Use aromatherapy, such as lavender oil, which has calming and soothing effects
                                            </li>
                                            <li>Try herbal remedies, such as ginger or peppermint tea, which have
                                                anti-inflammatory
                                                and
                                                pain-relieving properties
                                            </li>
                                            <li>Take a warm bath or shower to relax and reduce stress</li>
                                            <li>Practice deep breathing exercises and meditation to calm the mind and reduce
                                                anxiety.
                                            </li>
                                        </ul>
                                        <h4><strong>How to identify Migraines?</strong></h4>
                                        <p>Migraine with aura is a type of migraine that is characterized by sensory
                                            disturbances or
                                            visual disturbances that usually occur before or during a migraine attack. Aura can
                                            include
                                            a range of symptoms, such as flashing lights, zigzag lines, blind spots, tingling or
                                            numbness in the face or hands, and difficulty speaking. These symptoms usually last
                                            for
                                            less
                                            than an hour and are followed by a headache or migraine attack.&nbsp;</p>
                                        <p>Not all people with migraines experience aura and the symptoms and severity of aura
                                            can
                                            vary
                                            from person to person. It is important to talk to a healthcare provider if you
                                            experience
                                            migraines with aura or other symptoms to get an accurate diagnosis and appropriate
                                            migraine
                                            treatment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="diseasesName === 'cervical-radiculopathy'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Cervical: Neck Pain, Causes, Signs & Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Cervical spondylosis is a degenerative condition that affects the neck and is
                                    commonly
                                    seen
                                    in older adults. It results from the natural wear and tear of the bones, cartilage,
                                    and
                                    ligaments in the cervical spine, which can lead to a range of symptoms. These
                                    symptoms
                                    can
                                    include neck pain, stiffness, and headaches, as well as tingling or numbness in the
                                    arms
                                    or
                                    hands, and weakness in the muscles of the arms and shoulders.&nbsp;Cervical can be
                                    treated
                                    with an Ayurvedic doctor online consultation, book your free consultation now and
                                    get
                                    rid of
                                    cervical pain.&nbsp;</p>
                                <p>Treatment options for cervical spondylosis depend on the severity of the condition
                                    and
                                    may
                                    include medications to manage pain and inflammation, physical therapy to improve
                                    range
                                    of
                                    motion and strength, and surgery in some cases. Early diagnosis and appropriate
                                    treatment
                                    can help manage symptoms and improve overall quality of life.</p>
                                <h3><strong>What is a cervical spine?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cervical-radiculopathy/Image-1.webp"
                                    >
                                </div>
                                <p>The cervical spine is the upper portion of the spinal column that is located in the
                                    neck.
                                    It
                                    consists of seven vertebrae labeled C1 through C7 and extends from the base of the
                                    skull
                                    to
                                    the apex of the thoracic spine. The cervical spine is responsible for supporting the
                                    head's
                                    weight and allowing for an extensive range of motion and flexibility in the
                                    neck.&nbsp;</p>
                                <p>It also protects the spinal cord, which passes through the center of the vertebrae
                                    and is
                                    an
                                    essential component of the <a href="https://en.wikipedia.org/wiki/Nervous_system"
                                                                  rel="nofollow">nervous system</a>, transmitting
                                    signals
                                    from
                                    the brain to the rest
                                    of the body. Injuries or conditions affecting the cervical vertebrae can have a
                                    profound
                                    effect on neck mobility and overall mobility, as well as the function of the nervous
                                    system.</p>
                                <h4><strong>What are the main factors that cause and symptoms of Cervical?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/cervical-radiculopathy/Image-2.webp"
                                    >
                                </div>
                                <p>The main factors that can contribute to the development of cervical spondylosis
                                    include:</p>
                                <ol>
                                    <li>Age: As we age, the bones and cartilage in the neck can naturally begin to break
                                        down,
                                        leading to the development of cervical spondylosis.
                                    </li>
                                    <li>Genetics: Certain genetic factors may increase the risk of developing cervical
                                        spondylosis.
                                    </li>
                                    <li>Injury: Trauma to the neck, such as whiplash from a car accident or a
                                        sports-related
                                        injury, can damage the structures of the cervical spine and increase the risk of
                                        cervical spondylosis.
                                    </li>
                                    <li>Occupation: Certain occupations that require repetitive neck movements or
                                        prolonged
                                        sitting or standing can increase the risk of cervical spondylosis.
                                    </li>
                                </ol>
                                <h4><strong>The symptoms of cervical spondylosis can vary from person to person and may
                                    include:</strong></h4>
                                <ol>
                                    <li>Neck pain and stiffness, which may worsen with movement.</li>
                                    <li>Headaches, particularly in the back of the head.</li>
                                    <li>Numbness or tingling in the arms, hands, legs, or feet.</li>
                                    <li>Weakness in the muscles of the arms or shoulders.</li>
                                    <li>Reduced range of motion in the neck.</li>
                                    <li>Difficulty with balance and coordination.</li>
                                    <li><a
                                        href="https://www.niddk.nih.gov/health-information/urologic-diseases/bladder-control-problems/symptoms-causes"
                                        rel="nofollow">Loss of bladder</a> or bowel control in severe cases.
                                    </li>
                                </ol>
                                <p>It is important to seek medical attention if you experience any of these symptoms, as
                                    early
                                    diagnosis and treatment can help manage the condition and improve your quality of
                                    life.</p>
                                <h3><strong>What does cervical radiculopathy pain feel like?</strong></h3>
                                <p>Cervical radiculopathy is a condition that occurs when a nerve root in the cervical
                                    spine
                                    (neck region) becomes compressed or irritated, causing pain and other symptoms that
                                    radiate
                                    along the path of the affected nerve. The pain associated with cervical
                                    radiculopathy
                                    can
                                    vary depending on the severity of the condition, but typically it is described as
                                    sharp,
                                    shooting, or burning pain that radiates from the neck down into the arm, shoulder,
                                    and/or
                                    hand on one side of the body. The pain may also be accompanied by tingling,
                                    numbness, or
                                    weakness in the affected arm or hand.</p>
                                <p>Some people with cervical radiculopathy may experience constant pain, while others
                                    may
                                    experience pain that comes and goes. The pain may be aggravated by certain neck
                                    movements or
                                    positions, such as turning the head or looking up, and may be alleviated by rest or
                                    lying
                                    down.</p>
                                <p>It is important to seek medical attention if you experience any of these symptoms, as
                                    cervical radiculopathy can be a sign of a more serious underlying condition and may
                                    require&nbsp;cervical
                                    radiculopathy treatment to manage the pain and prevent further nerve
                                    damage.&nbsp;</p>
                                <h4>
                                    <strong>What is Cervical spondylosis and cervical radiculopathy?</strong></h4>
                                <p>Cervical spondylosis and cervical radiculopathy are two related conditions that
                                    affect
                                    the
                                    cervical spine (neck region).</p>
                                <p>Cervical spondylosis is a degenerative condition that typically develops over time
                                    due to
                                    age-related changes in the cervical spine. It involves the natural wear and tear of
                                    the
                                    bones, cartilage, and ligaments in the neck, which can lead to a range of symptoms,
                                    including neck pain, stiffness, and headaches, as well as tingling or numbness in
                                    the
                                    arms
                                    or hands, and weakness in the muscles of the arms and shoulders.</p>
                                <p>Cervical radiculopathy, on the other hand, is a condition that occurs when a nerve
                                    root
                                    in
                                    the cervical spine becomes compressed or irritated, causing pain and other symptoms
                                    that
                                    radiate along the path of the affected nerve. This can result in sharp, shooting, or
                                    burning
                                    pain that radiates from the neck down into the arm, shoulder, and/or hand on one
                                    side of
                                    the
                                    body, as well as tingling, numbness, or weakness in the affected arm or hand.
                                    Cervical
                                    radiculopathy can be caused by a variety of factors, including herniated discs, bone
                                    spurs,
                                    and spinal stenosis.</p>
                                <p>While cervical spondylosis and cervical radiculopathy are separate conditions, they
                                    can
                                    often
                                    occur together, as the degenerative changes associated with cervical spondylosis can
                                    contribute to the development of cervical radiculopathy. Treatment for both
                                    conditions
                                    may
                                    involve medications to manage pain and inflammation, physical therapy to improve
                                    range
                                    of
                                    motion and strength, and in some cases, surgery to relieve pressure on the affected
                                    nerves.</p><h4><strong>When to see a Doctor for Cervical and how it is
                                diagnosed?</strong>
                            </h4>
                                <p>It is recommended to see a doctor for cervical pain if you experience any of the
                                    following
                                    symptoms:</p>
                                <ul>
                                    <li>Severe neck pain or stiffness that does not improve with rest or
                                        over-the-counter
                                        pain
                                        medication
                                    </li>
                                    <li>Pain that radiates down the arms or legs</li>
                                    <li>Weakness, numbness, or tingling in the arms or legs</li>
                                    <li>Loss of bladder or bowel control</li>
                                    <li>Any other symptoms that are concerning or interfere with daily activities</li>
                                </ul>
                                <p>To diagnose cervical spondylosis or cervical radiculopathy, your doctor will
                                    typically
                                    perform a physical exam and take a medical history. They may also order imaging
                                    tests
                                    such
                                    as an X-ray, CT scan, or MRI to get a more detailed view of the neck structures and
                                    assess
                                    the severity of any damage. Electromyography (EMG) and nerve conduction studies may
                                    also
                                    be
                                    used to evaluate nerve function.</p>

                                <h3><strong>Is Cervical Radiculopathy Curable?</strong></h3>
                                <p>Cervical radiculopathy is treatable, but whether it is curable depends on the
                                    underlying
                                    cause and severity of the condition. In some cases, the&nbsp;Cervical nerve root
                                    compression
                                    or irritation of the affected nerve root can be relieved with nonsurgical treatments
                                    such as
                                    medications, physical therapy, and rest, and the symptoms may resolve on their own
                                    over
                                    time.</p>
                                <p>However, if the condition is caused by a structural problem such as a herniated disc
                                    or
                                    bone
                                    spur, or if the symptoms are severe or do not improve with conservative treatments,
                                    surgery
                                    may be necessary to relieve the pressure on the affected nerve root and prevent
                                    further
                                    damage.</p>
                                <p>several non-surgical options can be effective in treating cervical radiculopathy,
                                    depending
                                    on the underlying cause and severity of the condition. These may include:</p>
                                <ol>
                                    <li>Medications: Over-the-counter pain relievers such as acetaminophen or
                                        nonsteroidal
                                        anti-inflammatory drugs (NSAIDs) may help to relieve pain and reduce
                                        inflammation.
                                        If
                                        these are not effective, your doctor may prescribe stronger pain medication or
                                        muscle
                                        relaxants.
                                    </li>
                                    <li>Panchakarma Therapy: A <a href="https://www.draxico.com/panchakarma-treatment/">Panchakarma
                                        therapist</a> can develop an exercise program to help
                                        strengthen the muscles in the neck and improve the range of motion. They may
                                        also
                                        use
                                        techniques such as massage, heat, or electrical stimulation to relieve pain and
                                        muscle
                                        tension.
                                    </li>
                                    <li>Rest and Activity Modification: Resting the neck and avoiding activities that
                                        aggravate
                                        the symptoms can help to reduce pain and inflammation. However, it is important
                                        to
                                        maintain some level of activity to prevent muscle weakness and stiffness.
                                    </li>
                                    <li>Traction: Traction involves the use of weights or a pulley system to gently
                                        stretch
                                        the
                                        neck, which can help to relieve pressure on the affected nerve root.&nbsp;
                                    </li>
                                </ol>
                                <h3><strong>Ayurvedic Prevention of Cervical?</strong></h3>
                                <p>Cervical spondylosis is a condition that affects the joints and discs in the neck,
                                    resulting
                                    in pain, stiffness, and reduced mobility. Ayurveda offers some preventative measures
                                    that
                                    can help reduce the risk of developing cervical spondylosis.</p>
                                <ol>
                                    <li>Maintain Good Posture: Poor posture can place undue stress on the neck and
                                        spine,
                                        leading to the development of cervical spondylosis. Ayurveda recommends
                                        maintaining
                                        good
                                        posture while sitting, standing, and sleeping to prevent neck and back pain.
                                    </li>
                                    <li>Practice Yoga: Certain yoga postures, such as the cat-cow pose and the
                                        downward-facing
                                        dog pose, can help improve neck and spine flexibility, reducing the risk of
                                        developing
                                        cervical spondylosis.
                                    </li>
                                    <li>Massage Therapy: Ayurvedic massage therapy, known as <a
                                        href="https://www.draxico.com/panchakarma-treatment-abhyanga">Abhyanga</a>, can
                                        help
                                        improve blood
                                        circulation and reduce inflammation in the neck and spine, reducing the risk of
                                        developing cervical spondylosis.
                                    </li>
                                    <li>Eat a Balanced Diet: A balanced diet can help reduce inflammation in the body,
                                        which
                                        can
                                        reduce the risk of developing cervical spondylosis. Ayurveda recommends
                                        consuming
                                        foods
                                        that are rich in anti-inflammatory compounds, such as turmeric, ginger, and
                                        garlic.
                                    </li>
                                    <li>Manage Stress: Chronic stress can lead to muscle tension in the neck and
                                        shoulders,
                                        increasing the risk of developing cervical spondylosis. Ayurveda recommends
                                        practicing
                                        stress-management techniques like yoga, meditation, and deep breathing to help
                                        reduce
                                        stress levels and improve overall health.
                                    </li>
                                </ol>
                                <h4><strong>Home remedies for cervical radiculopathy?</strong></h4>
                                <ol>
                                    <li>Ashwagandha: Ashwagandha is an adaptogenic herb that can help reduce stress and
                                        inflammation associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or
                                        added to food in its powder form.
                                    </li>
                                    <li>Turmeric: Turmeric is a potent anti-inflammatory herb that can help reduce pain
                                        and
                                        inflammation associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or
                                        added to food in its powder form.
                                    </li>
                                    <li>Ginger: Ginger is a natural anti-inflammatory agent that can help reduce pain
                                        and
                                        inflammation associated with cervical spondylosis. It can be consumed in various
                                        forms,
                                        such as ginger tea, fresh ginger juice, or as a spice in cooking.
                                    </li>
                                    <li>Shallaki: Shallaki is an Ayurvedic herb that has been traditionally used to
                                        reduce
                                        pain
                                        and inflammation associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or added to food in its powder form.
                                    </li>
                                    <li>Guggulu: Guggulu is a resin extract that has potent anti-inflammatory properties
                                        and
                                        can
                                        help reduce pain associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or added to food in its powder form.
                                    </li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Cervical
                                    Spondylosis?</strong>
                                </h4>
                                <p>It is important to remember that while these Ayurvedic practices can be beneficial.
                                    Individuals with cervical spondylosis should always consult with their healthcare
                                    provider
                                    for appropriate screening and&nbsp;cervical spondylosis ayurvedic treatments. Here
                                    is
                                    some
                                    best&nbsp;ayurvedic medicine for spondylitis:</p>
                                <ol>
                                    <li>Ashwagandha: Ashwagandha is an adaptogenic herb that can help reduce stress and
                                        inflammation associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or
                                        added to food in its powder form.
                                    </li>
                                    <li>Turmeric: Turmeric is a potent anti-inflammatory herb that can help reduce pain
                                        and
                                        inflammation associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or
                                        added to food in its powder form.
                                    </li>
                                    <li>Ginger: Ginger is a natural anti-inflammatory agent that can help reduce pain
                                        and
                                        inflammation associated with cervical spondylosis. It can be consumed in various
                                        forms,
                                        such as ginger tea, fresh ginger juice, or as a spice in cooking.
                                    </li>
                                    <li>Shallaki: Shallaki is an Ayurvedic herb that has been traditionally used to
                                        reduce
                                        pain
                                        and inflammation associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or added to food in its powder form.
                                    </li>
                                    <li>Guggulu: Guggulu is a resin extract that has potent anti-inflammatory properties
                                        and
                                        can
                                        help reduce pain associated with cervical spondylosis. It can be taken as a
                                        supplement
                                        or added to food in its powder form.
                                    </li>
                                </ol>
                                <p>It is important to remember that these natural remedies should not replace
                                    conventional
                                    medical treatment for cervical spondylosis. Individuals with cervical spondylosis
                                    should
                                    always consult with their healthcare provider before taking any supplements or
                                    natural
                                    remedies.</p><h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>It can be unpleasant and annoying to have cervical radiculopathy (a "pinched nerve").
                                    The
                                    good news is that most people with this disease gradually recover with relaxation at
                                    home.
                                    Visit your healthcare professional if your symptoms last longer than a week.
                                    Cervical
                                    radiculopathy may be treated with several nonsurgical treatments. Dr. Axico
                                    Ayurvedic
                                    doctor
                                    consultation can help to treat Cervical. Moreover, you can buy Ayurvedic medicine
                                    for
                                    cervical spondylosis online or offline from official websites or stores.</p>

                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How to sleep with cervical
                                            radiculopathy?</strong>
                                        </h4>
                                        <p>Cervical radiculopathy is a condition that occurs when a nerve in the neck is
                                            compressed
                                            or
                                            irritated, causing pain, numbness, and weakness that radiates down the arm. Sleeping
                                            with
                                            cervical radiculopathy can be challenging, as it can be difficult to find a
                                            comfortable
                                            sleeping position that does not exacerbate the symptoms. Here are some tips that may
                                            help:</p>
                                        <ol>
                                            <li>Sleep on your back: Sleeping on your back can help maintain the natural curve of
                                                your
                                                spine and reduce pressure on the nerves in the neck. Place a small pillow or
                                                rolled-up
                                                towel under your neck to support the natural curve of your spine.
                                            </li>
                                            <li>Use a cervical pillow: A cervical pillow is designed to support the natural
                                                curve of
                                                the
                                                neck and reduce pressure on the nerves. It can help reduce pain and discomfort
                                                associated with cervical radiculopathy.
                                            </li>
                                            <li>Avoid sleeping on your stomach: Sleeping on your stomach can place undue stress
                                                on
                                                the
                                                neck and spine, exacerbating the symptoms of cervical radiculopathy. It is best
                                                to
                                                avoid
                                                this sleeping position.
                                            </li>
                                            <li>Use a firm mattress: A firm mattress can provide adequate support to the spine
                                                and
                                                neck,
                                                reducing pressure on the nerves in the neck.
                                            </li>
                                            <li>Use a warm compress: Placing a warm compress on the neck before bedtime can help
                                                relax
                                                the muscles and reduce pain associated with cervical radiculopathy.
                                            </li>
                                        </ol>
                                        <h4><strong>What worse cervical radiculopathy?</strong></h4>
                                        <p>Cervical radiculopathy occurs when a nerve root in the neck is compressed or
                                            irritated,
                                            causing pain, numbness, and weakness that radiates down the arm. Several factors can
                                            worsen
                                            cervical radiculopathy, including:</p>
                                        <ol>
                                            <li>Poor posture: Poor posture can put undue stress on the neck and spine, leading
                                                to
                                                the
                                                compression or irritation of the nerve roots.
                                            </li>
                                            <li>Repetitive movements: Repetitive movements of the neck, such as looking down at
                                                a
                                                phone
                                                or computer for long periods, can exacerbate the symptoms of cervical
                                                radiculopathy.
                                            </li>
                                            <li>Inactivity: Prolonged periods of inactivity can weaken the muscles that support
                                                the
                                                neck
                                                and spine, increasing the risk of nerve compression or irritation.
                                            </li>
                                            <li>Overuse or strain: Overuse or strain of the neck and shoulder muscles can lead
                                                to
                                                inflammation and swelling, increasing the pressure on the nerve roots.
                                            </li>
                                            <li>Trauma: Trauma to the neck and spine, such as whiplash from a car accident or a
                                                fall,
                                                can damage the nerve roots and worsen the symptoms of cervical radiculopathy.
                                            </li>
                                        </ol>
                                        <h4><strong>Home remedies for cervical radiculopathy?</strong></h4>
                                        <p>spondylosis treatment in Ayurveda:</p>
                                        <ol>
                                            <li>Ashwagandha: Ashwagandha is an adaptogenic herb that can help reduce stress and
                                                inflammation associated with cervical spondylosis. It can be taken as a
                                                supplement
                                                or
                                                added to food in its powder form.
                                            </li>
                                            <li>Turmeric: Turmeric is a potent anti-inflammatory herb that can help reduce pain
                                                and
                                                inflammation associated with cervical spondylosis. It can be taken as a
                                                supplement
                                                or
                                                added to food in its powder form.
                                            </li>
                                            <li>Ginger: Ginger is a natural anti-inflammatory agent that can help reduce pain
                                                and
                                                inflammation associated with cervical spondylosis. It can be consumed in various
                                                forms,
                                                such as ginger tea, fresh ginger juice, or as a spice in cooking.
                                            </li>
                                            <li>Shallaki: Shallaki is an Ayurvedic herb that has been traditionally used to
                                                reduce
                                                pain
                                                and inflammation associated with cervical spondylosis. It can be taken as a
                                                supplement
                                                or added to food in its powder form.
                                            </li>
                                            <li>Guggulu: Guggulu is a resin extract that has potent anti-inflammatory properties
                                                and
                                                can
                                                help reduce pain associated with cervical spondylosis. It can be taken as a
                                                supplement
                                                or added to food in its powder form.
                                            </li>
                                        </ol>
                                        <h4><strong>Herniated disks and cervical radiculopathy?</strong></h4>
                                        <p>Herniated discs can lead to cervical radiculopathy. A herniated disc occurs when the
                                            gel-like
                                            center of a spinal disc ruptures through a weak area in the outer layer of the disc,
                                            compressing or irritating the adjacent nerve roots. When a herniated disc occurs in
                                            the
                                            cervical spine, it can cause cervical radiculopathy, which is characterized by pain,
                                            numbness, and weakness that radiates down the arm.</p>
                                        <p>The symptoms of&nbsp;Cervical radiculopathy caused&nbsp;by a herniated disc can vary
                                            depending on the severity of the herniation and the degree of nerve compression. In
                                            some
                                            cases, individuals may experience mild symptoms, such as numbness or tingling in the
                                            fingers, while in others, they may experience severe pain and weakness in the
                                            arm.</p>
                                        <p>cervical radiculopathy treatment&nbsp;caused by a herniated disc may include rest,
                                            physical
                                            therapy, pain medication, and in severe cases, surgery. In some cases, a healthcare
                                            provider
                                            may recommend a cervical collar or other orthotic device to help support the neck
                                            and
                                            reduce
                                            pressure on the herniated disc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'dengue'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Dengue: Ayurvedic Treatment, Causes & Signs | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Dengue is a disease that affects millions of people every year, particularly in
                                    tropical
                                    and
                                    subtropical regions. It is a viral infection that is transmitted by mosquitoes, and
                                    it
                                    can
                                    cause a range of symptoms from mild to severe. While most cases of dengue can be
                                    managed
                                    with rest and fluids, severe cases can lead to complications that require
                                    hospitalisation
                                    and can even be life-threatening.&nbsp;</p>
                                <p>Despite ongoing efforts to control the spread of dengue, the disease continues to
                                    pose a
                                    significant public health threat in many parts of the world. One can take help from
                                    an&nbsp;<a
                                        href="https://www.draxico.com">Ayurvedic doctor online consultation</a> to get
                                    rid
                                    of
                                    dengue.</p>
                                <h3><strong>What is Dengue?</strong></h3>
                                <p>Dengue is a viral infection that is transmitted by mosquitoes, particularly the Aedes
                                    mosquito species. The virus that dengue causes is called the dengue virus, and there
                                    are
                                    four different serotypes or strains of the virus. Dengue is most commonly found in
                                    tropical
                                    and subtropical regions, but cases have been reported in many parts of the
                                    world.</p>
                                <h4>
                                    <strong>What are the main factors that cause and symptoms of Dengue?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/dengue/Image.webp"
                                    >
                                </div>
                                <p>The main cause of dengue is infection with the dengue virus, which is transmitted by
                                    mosquitoes, particularly the&nbsp;<a
                                        href="https://en.wikipedia.org/wiki/Aedes_aegypti"
                                        rel="nofollow">Aedes
                                        mosquito species</a>. The virus can be transmitted when a mosquito bites an
                                    infected
                                    person and then bites another person. There are four different serotypes or strains
                                    of
                                    the
                                    dengue virus and infection with one strain does not provide immunity against the
                                    other
                                    strains.</p>
                                <p>The dengue symptoms can vary from mild to severe and typically appear within 4-7 days
                                    after
                                    infection. The most common symptoms of dengue include:</p>
                                <ul>
                                    <li>High fever (up to 105°F or 40.5°C)</li>
                                    <li>Severe headache</li>
                                    <li>Joint and muscle pain</li>
                                    <li>Pain behind the eyes</li>
                                    <li>Nausea and vomiting</li>
                                    <li>Rash</li>
                                    <li>Mild bleeding from the nose or gums</li>
                                </ul>
                                <p>In severe cases, dengue can lead to dengue hemorrhagic fever, which can cause dengue
                                    fever
                                    symptoms such as:</p>
                                <ul>
                                    <li>Severe abdominal pain</li>
                                    <li>Persistent vomiting</li>
                                    <li>Rapid breathing</li>
                                    <li>Bleeding under the skin or from the nose or gums</li>
                                    <li>Fatigue</li>
                                    <li>Restlessness</li>
                                    <li>Hemorrhagic dengue fever can be fatal, necessitating rapid medical care.</li>
                                </ul>
                                <p>While anyone can get dengue, certain factors can increase the risk of infection or
                                    severe
                                    long term effects of dengue fever, including:</p>
                                <ul>
                                    <li>Living or travelling to an area with high dengue transmission</li>
                                    <li>Prior infection with a different serotype of the&nbsp;<a
                                        href="https://www.who.int/news-room/fact-sheets/detail/dengue-and-severe-dengue#:~:text=Dengue%20is%20a%20viral%20infection,million%20infections%20occurring%20each%20year.">dengue
                                        virus</a></li>
                                    <li>Being under the age of 12 or over the age of 65</li>
                                    <li>Having a weakened immune system</li>
                                    <li>Pregnancy</li>
                                    <li>Medical conditions such as heart disease or diabetes.</li>
                                </ul>
                                <h4><strong>What are the Noticeable signs of Dengue?</strong></h4>
                                <p>The noticeable signs of dengue include a sudden onset of fever, often accompanied by
                                    severe
                                    headache, joint and muscle pain, and pain behind the eyes. People with dengue may
                                    also
                                    experience nausea, vomiting, and a rash. In severe cases, dengue can lead to dengue
                                    hemorrhagic fever, which can cause bleeding and shock. If you suspect you or someone
                                    you
                                    know may have dengue, it's important to seek medical attention right away. You can
                                    also
                                    take
                                    an&nbsp;<a href="https://www.draxico.com/appointment">online consultation with
                                        Doctor</a>.
                                </p>
                                <h3><strong>How does dengue fever spread?</strong></h3>
                                <p>Dengue fever is spread by the bite of infected mosquitoes. When a mosquito bites a
                                    person
                                    infected with the dengue virus, it can then transmit the virus to other people it
                                    bites.</p>
                                <p>The&nbsp;<a href="https://www.cdc.gov/mosquitoes/about/life-cycles/aedes.html">Aedes
                                    mosquitoes</a> that spread dengue typically bite during the day, especially in the
                                    early
                                    morning and late afternoon. They are most commonly found in tropical and subtropical
                                    regions, but can also be found in other areas with suitable environmental
                                    conditions.
                                </p>
                                <p>Dengue cannot be spread directly from person to person, but infected blood products
                                    and
                                    mother-to-child transmission during pregnancy or childbirth have been reported in
                                    rare
                                    cases. It's important to take <a
                                        href="https://www.epa.gov/insect-repellents/tips-prevent-mosquito-bites#:~:text=Keep%20mosquitoes%20away%20from%20exposed,borne%20disease%20warning%20in%20effect."
                                        rel="nofollow">measures to prevent mosquito bites</a> and eliminate breeding
                                    sites, such as standing water, to control the spread of dengue.</p>
                                <h4><strong>Dengue is caused by Which Mosquito?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/dengue/Image-2.webp"
                                    >
                                </div>
                                <p>Dengue fever is caused by the Aedes mosquito, particularly the Aedes aegypti and
                                    Aedes
                                    albopictus species. These mosquitoes become infected with the dengue virus when they
                                    bite a
                                    person who is already infected with the virus. They can then transmit the virus to
                                    other
                                    people they bite.</p>
                                <h3><strong>Ayurvedic Remedies for Dengue</strong></h3>
                                <p>Ayurveda offers several remedies that can help alleviate the dengue fever symptoms
                                    and
                                    recovery signs of dengue. Here are some&nbsp;<a href="https://www.draxico.com/">dengue
                                        Ayurvedic treatments and medicine</a>:</p>
                                <ul>
                                    <li><strong>Giloy</strong>: Giloy is an herb that has been used as the Ayurvedic
                                        medicine
                                        for dengue fever for centuries to boost immunity and fight infections. It can be
                                        consumed in the form of juice or as a supplement.
                                    </li>
                                    <li><strong>Papaya Leaf:</strong> Papaya leaf extract is believed to help increase
                                        platelet
                                        count, which can be beneficial for people with dengue. It can be consumed as a
                                        juice
                                        or
                                        in the form of capsules.
                                    </li>
                                    <li><strong>Tulsi</strong>: Tulsi, also known as holy basil, is another herb that is
                                        known
                                        for its immune-boosting properties. It can be consumed as a tea or in the form
                                        of a
                                        supplement.
                                    </li>
                                    <li><strong>Neem</strong>: Neem is a natural mosquito repellent and can also help
                                        boost
                                        immunity. It can be consumed in the form of a capsule or applied topically in
                                        the
                                        form
                                        of oil or paste.
                                    </li>
                                    <li><strong>Turmeric</strong>: Turmeric is a powerful anti-inflammatory and can help
                                        reduce
                                        inflammation and fever associated with dengue. It can be consumed in the form of
                                        a
                                        supplement or added to food.
                                    </li>
                                </ul>
                                <h4><strong>What to eat and not eat</strong></h4><h4><strong>What to eat:</strong></h4>
                                <ul>
                                    <li><strong>Plenty of fluids:</strong> It's important to stay hydrated during
                                        dengue, so
                                        make sure to drink plenty of fluids such as water, coconut water, herbal tea,
                                        and
                                        fresh
                                        fruit juices.
                                    </li>
                                    <li><strong>Foods high in vitamin C:</strong> Vitamin C can help boost immunity and
                                        increase
                                        platelet count, so include plenty of foods rich in vitamin C such as citrus
                                        fruits,
                                        guava, kiwi, papaya, and strawberries.
                                    </li>
                                    <li><strong>Protein-rich foods:</strong> Protein is essential for the repair and
                                        recovery of
                                        tissues, so include plenty of protein-rich foods such as lean meats, fish, eggs,
                                        legumes, and nuts.
                                    </li>
                                    <li><strong>Soups and broths:</strong> These can be easy to digest and provide
                                        nourishment.
                                    </li>
                                </ul>
                                <h4><strong>What not to eat:</strong></h4>
                                <ul>
                                    <li><strong>Spicy and oily foods:</strong> These can be difficult to digest and may
                                        worsen
                                        symptoms such as nausea and vomiting.
                                    </li>
                                    <li><strong>Junk food and processed foods:</strong> These can be low in nutrients
                                        and
                                        may
                                        weaken the immune system.
                                    </li>
                                    <li><strong>Alcohol:</strong> Alcohol can dehydrate the body and weaken the immune
                                        system,
                                        so it's best to avoid it during dengue.
                                    </li>
                                    <li><strong>Caffeine:</strong> Caffeine can dehydrate the body, so it's best to
                                        limit or
                                        avoid it during dengue.
                                    </li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Dengue</strong></h4>
                                <p>Here are some Ayurvedic herbs and medications that may be recommended for dengue:</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/giloy-plus-capsule">Giloy</a>
                                        (Tinospora
                                        cordifolia)
                                    </li>
                                    <li><a href="https://www.draxico.com/products/haldi-tulsi-plus">Tulsi</a> (Holy
                                        Basil)
                                    </li>
                                    <li>Neem (Azadirachta indica)</li>
                                    <li>Amla (Indian Gooseberry)</li>
                                    <li>Turmeric (Curcuma longa)</li>
                                    <li><a href="https://www.draxico.com/products/ashwagandha-rishta">Ashwagandha</a>
                                        (Withania
                                        somnifera)
                                    </li>
                                    <li><a href="https://www.draxico.com/products/sodhi-triphala">Triphala</a> (a blend
                                        of
                                        three
                                        fruits: Amla, Haritaki, and Bibhitaki)
                                    </li>
                                    <li>Chirata (Swertia chirata)</li>
                                    <li><a href="https://www.draxico.com/products/guduchi-ghana-vati">Guduchi</a>
                                        (Tinospora
                                        cordifolia)
                                    </li>
                                    <li>Papaya leaf extract</li>
                                    <li>Sudarshan Churna</li>
                                    <li><a href="https://www.draxico.com/products/mahasudarshan-churna">Mahasudarshan
                                        Churna</a>
                                    </li>
                                    <li>Amritarishta</li>
                                    <li>Giloy Ghan Vati</li>
                                    <li>Tulsi Ghan Vati</li>
                                    <li>Neem Capsules</li>
                                    <li>Amla Powder</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>You can take efforts to lower your risk of mosquito bites and keep a watch out for
                                    the
                                    warning signs of severe dengue. Make sure you are aware of where to go for emergency
                                    medical
                                    attention if you become ill while traveling. If you acquire dengue and become
                                    seriously
                                    ill,
                                    you will probably recover as long as you receive immediate medical care.</p>
                                <p>If you have a medical condition that makes you more susceptible to infection, consult
                                    Dr.
                                    Axico, Ayurvedic doctor online, about infection prevention. Moreover, you can buy
                                    Ayurvedic
                                    medicine online or offline too.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Can you be immune to dengue fever?</strong>
                                        </h4>
                                        <p>After recovering from dengue infection, a person's immune system may develop immunity
                                            to
                                            the
                                            virus. This implies that the person's immune system will be better able to fend off
                                            the
                                            virus if they are exposed to it again in the future.&nbsp;</p>
                                        <p>However, there are four different strains of dengue virus, and immunity to one strain
                                            does
                                            not protect the others. Additionally, immunity to dengue is not lifelong and may
                                            wane
                                            over
                                            time, so it is still possible to get infected with dengue again in the future.</p>
                                        <h4><strong>Is dengue fever contagious?</strong></h4>
                                        <p>No, dengue fever is not contagious and cannot be spread directly from person to
                                            person.
                                            It is
                                            transmitted through the bite of infected Aedes mosquitoes, which acquire the virus
                                            when
                                            they
                                            feed on the blood of an infected person. Once infected, the mosquito can then
                                            transmit
                                            the
                                            virus to other people through their bites.&nbsp;</p>
                                        <p>However, it is possible for a person with dengue fever to indirectly spread the virus
                                            by
                                            providing a source of blood for uninfected mosquitoes to feed on, increasing the
                                            risk of
                                            further transmission.</p><h4><strong>How can I reduce my risk of dengue
                                        fever?</strong>
                                    </h4>
                                        <p>There are several steps you can take to reduce your risk of dengue fever:</p>
                                        <ul>
                                            <li>Avoid mosquito bites by wearing long-sleeved shirts and pants, using mosquito
                                                repellent,
                                                and staying in air-conditioned or screened areas.
                                            </li>
                                            <li>Remove or minimize potential mosquito breeding sites around your home by
                                                emptying
                                                standing water from containers, such as flowerpots, buckets, and gutters.
                                            </li>
                                            <li>Support community efforts to control mosquito populations, such as through
                                                targeted
                                                pesticide use and mosquito monitoring programs.
                                            </li>
                                            <li>Stay informed about the risk of dengue fever in your area, and seek medical
                                                attention if
                                                you develop symptoms such as fever, headache, and muscle pain. Early diagnosis
                                                and
                                                dengue fever treatment can help prevent serious complications.
                                            </li>
                                        </ul>
                                        <h4><strong>Ayurvedic home remedies for dengue</strong></h4>
                                        <ul>
                                            <li>Consuming fruits like Custard apple, also known as sugar apple, is a tropical
                                                fruit
                                                that
                                                has been traditionally used in Ayurveda to treat various ailments, including
                                                fever.
                                            </li>
                                            <li>Drinking papaya leaf juice can help increase platelet count.</li>
                                            <li>Taking neem leaves or neem capsules can help boost immunity and fight off the
                                                virus.
                                            </li>
                                            <li>Drinking ginger tea or adding ginger to your diet can help reduce fever and
                                                relieve
                                                muscle pain.
                                            </li>
                                            <li>Drinking giloy decoction empty stomach in the morning as it has antipyretic,
                                                immunomodulatory, and anti-inflammatory properties that can help reduce fever,
                                                boost
                                                the
                                                immune system, and reduce inflammation associated with dengue.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'eczema'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Eczema: Dry & Itchy Skin, Causes, & Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>A chronic skin condition characterized by inflammation and irritation; eczema is also
                                    known
                                    as atopic dermatitis. It can affect individuals of all ages, but infants and young
                                    children
                                    are most commonly affected. Eczema is a non-contagious condition that can manifest
                                    on
                                    any
                                    part of the body, though it is most commonly observed on the face, hands, feet, and
                                    the
                                    insides of the elbows and knees. Eczema can be cured with an Ayurvedic doctor online
                                    consultation effectively within a few days.&nbsp;</p>
                                <p>Although the precise dermatitis causes are unknown, genetic and environmental factors
                                    are
                                    believed to play a role. Although there is no cure for eczema, there are treatments
                                    that
                                    can
                                    help manage symptoms and prevent flare-ups. Managing eczema requires proper skin
                                    care,
                                    avoidance of irritants, and medication prescribed by a healthcare professional.</p>
                                <h3><strong>What is Eczema?</strong></h3>
                                <p>Eczema is a skin ailment that results in dry, itchy skin patches. It is a typical
                                    condition
                                    that is not spread by others. If you come into contact with an allergen or irritant,
                                    your
                                    eczema symptoms may worsen. There is no cure for this condition, however, there are
                                    therapies that can help you manage the symptoms.</p>
                                <h4><strong>What are the different types of Eczema?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/eczema/Image-1.webp"
                                    >
                                </div>
                                <p>Eczema is a skin condition characterized by inflammation, redness, itching, and
                                    sometimes
                                    oozing and crusting. There are several different types of eczema, including:</p>
                                <ol>
                                    <li><strong>Atopic dermatitis:</strong> This is the most common type of eczema and
                                        usually
                                        begins in childhood. It often affects people who have a family history of
                                        allergic
                                        conditions such as asthma or hay fever.
                                    </li>
                                    <li><strong>Contact dermatitis:</strong> This type of eczema occurs when the skin
                                        comes
                                        into
                                        contact with an irritant or allergen, such as certain soaps, detergents, or
                                        metals
                                        like
                                        nickel.
                                    </li>
                                    <li><strong>Nummular eczema:</strong> This type of eczema causes round, coin-shaped
                                        patches
                                        of irritated skin, usually on the arms and legs.
                                    </li>
                                    <li><strong>Dyshidrotic eczema:</strong> This type of eczema causes small, itchy
                                        blisters on
                                        the hands and feet.
                                    </li>
                                    <li>Seborrheic dermatitis: This type of eczema affects the scalp and causes scaly
                                        patches,
                                        redness, and itching.
                                    </li>
                                    <li><strong>Stasis dermatitis:</strong> This type of eczema occurs on the lower legs
                                        and
                                        is
                                        usually caused by poor circulation or problems with the veins.&nbsp;
                                    </li>
                                </ol>
                                <h4><strong>What are the primary causes of Eczema?</strong></h4>
                                <p>Several factors are known to contribute to the development of eczema, although its
                                    precise
                                    cause is not completely understood. These elements include:</p>
                                <p>There may be a genetic component to eczema, as the condition often runs in
                                    families.</p>
                                <ul>
                                    <li>Immune system dysfunction: It is believed that eczema is partially caused by an
                                        overactive immune response to certain stimuli, which can result in inflammation
                                        and
                                        skin
                                        irritation.
                                    </li>
                                    <li>Environmental factors: Chemicals, detergents, and fragrances can provoke or
                                        exacerbate
                                        eczema symptoms.
                                    </li>
                                    <li>People with eczema are frequently allergic to certain foods, airborne allergens
                                        such
                                        as
                                        pollen, and other substances that can exacerbate eczema symptoms.
                                    </li>
                                    <li>Stress: Stress can impair the immune system and induce an inflammatory response,
                                        thereby
                                        exacerbating eczema symptoms.
                                    </li>
                                </ul>
                                <p>Eczema is frequently associated with a dysfunction in the skin barrier, which allows
                                    moisture
                                    to escape and irritants to more easily penetrate the skin.</p>
                                <h4><strong>What are the Noticeable signs and symptoms of Eczema?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/eczema/Image-2.webp"
                                    >
                                </div>
                                <p>The signs and symptoms of eczema can vary depending on the type of eczema and the
                                    severity of
                                    the condition, but some common atopic dermatitis symptoms include:</p>
                                <ul>
                                    <li>Itching: One of the most common symptoms of eczema is intense itching, which can
                                        be
                                        persistent and difficult to relieve.
                                    </li>
                                    <li>Rash: Eczema typically causes a red, inflamed rash that may be dry, scaly, or
                                        crusty.
                                    </li>
                                    <li>Dry skin: People with eczema often have dry skin, which can lead to cracking and
                                        bleeding.
                                    </li>
                                    <li>Swelling: Eczema can cause swelling in the affected areas, which can make the
                                        skin
                                        look
                                        puffy or swollen.
                                    </li>
                                    <li>Blistering: In some cases, eczema can cause blisters to form on the skin, which
                                        may
                                        ooze
                                        or crust over.
                                    </li>
                                    <li>Skin discoloration: Over time, eczema can cause the affected skin to become
                                        discolored,
                                        thickened, or scaly.
                                    </li>
                                    <li>Infection: Scratching the affected skin can lead to bacterial or viral
                                        infections,
                                        which
                                        can worsen eczema symptoms and require medical treatment.
                                    </li>
                                </ul>
                                <h4><strong>Who does eczema affect?</strong></h4>
                                <p>Eczema can affect individuals of all ages and races, although it is most prevalent in
                                    young
                                    children. According to the <a href="https://nationaleczema.org/" rel="nofollow">National
                                        Eczema Association</a>, more than thirty million Americans
                                    have eczema.</p>
                                <p>If someone in your family has eczema or other allergic conditions, such as asthma or
                                    hay
                                    fever, your risk of developing eczema may increase. Other risk factors for eczema
                                    include
                                    living in an arid climate, being exposed to certain irritants or allergens, and
                                    having a
                                    compromised immune system.</p>
                                <p>Although anyone can develop eczema, it is more prevalent in the following
                                    populations:</p>
                                <ul>
                                    <li>Infants and young children are most frequently diagnosed with eczema, with many
                                        children
                                        outgrowing the condition as they age.
                                    </li>
                                    <li>Women are marginally more likely to develop eczema than males.</li>
                                    <li>People with allergies or asthma: People with allergies or asthma are more likely
                                        to
                                        develop eczema, as the two conditions are frequently linked.
                                    </li>
                                    <li>People with dry skin: Because their skin barrier is already compromised, people
                                        with
                                        dry
                                        skin may be more susceptible to developing eczema.
                                    </li>
                                    <li>People who work in certain occupations that expose them to irritants or
                                        chemicals,
                                        such
                                        as healthcare workers or hairdressers, may be more likely to develop dermatitis.
                                    </li>
                                </ul>
                                <h4><strong>What triggers eczema to flare up?</strong></h4>
                                <p>Eczema can be triggered by a variety of factors, and triggers can vary from person to
                                    person.
                                    Here are some common triggers that can cause eczema to flare up:</p>
                                <ol>
                                    <li><strong>Irritants:</strong> Exposure to irritants such as soaps, detergents,
                                        perfumes,
                                        or cleaning products can irritate the skin and trigger an eczema flare-up.
                                    </li>
                                    <li><strong>Allergens:</strong> Exposure to allergens such as dust mites, pet
                                        dander, or
                                        pollen can trigger eczema symptoms in some people.
                                    </li>
                                    <li><strong>Extreme temperatures:</strong> Hot or cold temperatures, as well as
                                        changes
                                        in
                                        humidity, can dry out the skin and trigger eczema symptoms.
                                    </li>
                                    <li><strong>Stress:</strong> Emotional stress can trigger an eczema flare-up in some
                                        people.
                                    </li>
                                    <li><strong>Hormones:</strong> Hormonal changes, such as those that occur during
                                        pregnancy
                                        or menstruation, can trigger eczema symptoms in some women.
                                    </li>
                                    <li><strong>Certain foods:</strong> Certain foods, such as dairy, eggs, nuts, and
                                        soy,
                                        can
                                        trigger eczema symptoms in some people.
                                    </li>
                                    <li><strong>Scratching:</strong> Scratching or rubbing the affected skin can further
                                        irritate the skin and trigger an eczema flare-up.&nbsp;
                                    </li>
                                </ol>
                                <h3><strong>Where do symptoms of eczema appear on my body?</strong></h3>
                                <p>Any portion of the body might have eczema symptoms, but the following are the most
                                    frequently
                                    affected areas:</p>
                                <ul>
                                    <li><strong>Face:</strong> Because it is more noticeable, eczema on the face can be
                                        particularly upsetting. It can show up around the eyes, on the forehead, and the
                                        cheeks.
                                    </li>
                                    <li><strong>Hands:&nbsp;</strong>Because hands are frequently in contact with
                                        allergens
                                        and
                                        are frequently washed, eczema on the hands can be particularly difficult to
                                        treat.
                                    </li>
                                    <li>Foot Eczema can be very unpleasant and make it challenging to wear shoes.</li>
                                    <li><strong>Knees and elbows:</strong> Eczema can result in the development of
                                        thick,
                                        scaly
                                        patches on the knees and elbows.
                                    </li>
                                    <li><strong>Scalp:</strong> Eczema can cause dry, itchy spots on the scalp.</li>
                                    <li><strong>Neck and upper chest:</strong> Eczema can cause red, itchy, and scaly
                                        patches on
                                        the neck and upper chest.
                                    </li>
                                    <li>Behind the knees and inside the elbows: Eczema frequently develops in the folds
                                        of
                                        the
                                        skin, such as behind the knees and inside the elbows.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for Eczema and how it is diagnosed?</strong></h4>
                                <p>If you think you might have eczema, you should see a doctor to get an accurate
                                    diagnosis
                                    and
                                    the right treatment plan. Here are some reasons why you might need to see a
                                    doctor:</p>
                                <ul>
                                    <li>If your eczema symptoms are getting worse, even though you are trying to treat
                                        them
                                        at
                                        home, you may need to see a doctor.
                                    </li>
                                    <li>Your skin is infected. If you see signs of illness on your skin, like pus or
                                        oozing,
                                        you
                                        should go to the doctor right away.
                                    </li>
                                    <li>Your skin hurts or bleeds: If your eczema makes your skin hurt or bleed, you
                                        should
                                        see
                                        a doctor to get help.
                                    </li>
                                    <li>Your eczema is making your life less good: If your eczema is giving you a lot of
                                        pain,
                                        keeping you from sleeping, or getting in the way of your daily life, it may be
                                        time
                                        to
                                        see a doctor.
                                    </li>
                                </ul>
                                <p>An Ayurvedic physician will look at your skin and ask about your medical background
                                    and
                                    symptoms to figure out if you have eczema. They may also do a patch test to see if
                                    you
                                    are
                                    allergic to anything that could be causing your eczema. In some cases, a diagnosis
                                    may
                                    need
                                    to be confirmed with a skin test.</p>
                                <p>Once a diagnosis is made, your dermatologist will work with you to make a
                                    personalized&nbsp;Ayurvedic
                                    treatment for pigmentation on your face. This plan may include medicines, topical
                                    creams,
                                    lifestyle changes, and staying away from things that can cause flare-ups. Most
                                    people
                                    with
                                    eczema can get rid of their symptoms and improve their quality of life with the
                                    right
                                    care
                                    and treatment.</p>
                                <h3><strong>Ayurvedic Say on Eczema?</strong></h3>
                                <p>Ayurveda says that eczema is caused by a change in the blood and cells of the skin,
                                    as
                                    well
                                    as a change in the digestive fire, or Agni. This can cause toxins, or Ama, to build
                                    up
                                    in
                                    the body, which can cause the skin to become red and itchy.</p>
                                <p>Ayurveda treats eczema by addressing the body's underlying imbalances and supporting
                                    health
                                    and wellness as a whole. Changes to your food and way of life, herbal remedies, and
                                    body
                                    therapies like <a href="https://www.draxico.com/panchakarma-treatment/kizhi">Ayurvedic
                                        massage or Panchakarma</a> can all be used as eczema ayurvedic
                                    treatment.</p><h4><strong>What to eat and not eat</strong></h4><h4><strong>Foods to
                                Eat:</strong></h4>
                                <ol>
                                    <li>Anti-inflammatory foods: Include foods rich in omega-3 fatty acids, such as
                                        fatty
                                        fish
                                        (salmon, mackerel, sardines), flaxseeds, chia seeds, and walnuts. These foods
                                        help
                                        reduce inflammation in the body.
                                    </li>
                                    <li>Fruits and vegetables: Consume a variety of fruits and vegetables that are high
                                        in
                                        antioxidants and phytochemicals. Opt for colorful options like berries, leafy
                                        greens,
                                        broccoli, carrots, and sweet potatoes.
                                    </li>
                                    <li>Probiotic-rich foods: Incorporate probiotic foods like yogurt, kefir,
                                        sauerkraut,
                                        kimchi, and kombucha. Probiotics promote a healthy gut microbiome, which can
                                        have a
                                        positive impact on eczema symptoms.
                                    </li>
                                    <li>Hydrating foods: Stay well-hydrated by consuming water-rich foods like
                                        cucumbers,
                                        watermelon, oranges, and tomatoes. Proper hydration is important for maintaining
                                        healthy
                                        skin.
                                    </li>
                                    <li>Healthy fats: Include sources of healthy fats, such as avocados, olive oil,
                                        coconut
                                        oil,
                                        and nuts. These fats support skin health and moisturization.
                                    </li>
                                </ol>
                                <h4><strong>Foods to Avoid or Limit:</strong></h4>
                                <ol>
                                    <li>Allergenic foods: Identify and avoid any specific food triggers that may worsen
                                        eczema
                                        symptoms. Common triggers include dairy products, eggs, soy, wheat, peanuts, and
                                        shellfish. It's important to work with an allergist or healthcare professional
                                        to
                                        determine your specific food sensitivities.
                                    </li>
                                    <li>Processed foods: Minimize the consumption of processed foods, as they often
                                        contain
                                        additives, preservatives, and artificial ingredients that can potentially
                                        trigger
                                        inflammation and worsen eczema.
                                    </li>
                                    <li>Sugary foods: Limit intake of sugary foods and beverages, as they can contribute
                                        to
                                        inflammation and may negatively affect skin health.
                                    </li>
                                    <li>Potential irritants: Some individuals with eczema may be sensitive to certain
                                        spices,
                                        acidic foods (like citrus fruits and tomatoes), and hot beverages. Pay attention
                                        to
                                        any
                                        personal triggers and adjust your diet accordingly.
                                    </li>
                                    <li>Alcohol and caffeine: Limit or avoid alcohol and caffeine, as they can have a
                                        dehydrating effect on the body and potentially exacerbate eczema symptoms.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Remedies for Eczema?</strong></h4>
                                <p>There are several Ayurvedic remedies and Ayurvedic medicine for eczema that may be
                                    helpful
                                    including:</p>
                                <ol>
                                    <li>Neem: Neem is a bitter-tasting herb that is traditionally used in Ayurveda to
                                        support
                                        healthy skin. It has antibacterial and anti-inflammatory properties that can
                                        help
                                        soothe
                                        irritated skin. Neem can be applied topically to the affected area or taken
                                        internally
                                        as a supplement.
                                    </li>
                                    <li>Turmeric: Turmeric is a spice with anti-inflammatory properties that can help
                                        reduce
                                        inflammation and itching in the skin. It can be applied topically or taken
                                        internally as
                                        a supplement. Turmeric is an Ayurvedic medicine for itching.&nbsp;
                                    </li>
                                    <li>Aloe vera: Aloe vera is a cooling and soothing herb that can help reduce skin
                                        inflammation and itching. It can be applied topically to the affected area.
                                    </li>
                                    <li>Ghee: Ghee, or clarified butter, is a traditional Ayurvedic remedy for skin
                                        conditions.
                                        It is believed to nourish the skin and support healthy digestion, which can help
                                        reduce
                                        the accumulation of toxins in the body.
                                    </li>
                                    <li>Triphala: Triphala is a combination of three fruits - Amalaki, Bibhitaki, and
                                        Haritaki -
                                        that is traditionally used in Ayurveda to support healthy digestion and
                                        elimination.
                                        Triphala can help detoxify the body and reduce the accumulation of toxins, which
                                        can
                                        contribute to eczema. It can be taken internally as a supplement.
                                    </li>
                                </ol>
                                <p>It's important to note that Ayurvedic remedies should be used under the guidance of a
                                    qualified Ayurvedic practitioner. Eczema is a complex condition that requires a
                                    comprehensive approach to treatment, and it's important to work with a healthcare
                                    provider
                                    to develop a dermatitis Ayurvedic treatment for skin rashes and itching.</p><h4>
                                <strong>A
                                    Short Note from Dr. Axico&nbsp;</strong></h4>
                                <p>A very prevalent and unpleasant skin ailment is eczema. It might lower your standard
                                    of
                                    living. At worst, it can prevent you from falling asleep, keep you awake, and cause
                                    you
                                    to
                                    become self-conscious in public. Consult a dermatologist or other healthcare
                                    professional as
                                    soon as you notice any eczema symptoms.</p>
                                <p>Look into both over-the-counter and prescription skin Ayurvedic treatments to
                                    determine
                                    which
                                    is best for you. Ayurvedic doctor online consultation would be a good choice to
                                    treat
                                    Eczema. Additionally, you can buy Ayurvedic medicine online or offline to upgrade
                                    your
                                    living standards.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Is eczema contagious?</strong>&nbsp;</h4>
                                        <p>No, eczema doesn't spread. Eczema is a skin disease that is caused by a combination
                                            of
                                            genetic and environmental factors, such as a weaker skin barrier, a broken immune
                                            system,
                                            and exposure to irritants or allergens. It isn't caused by a virus or bacteria, so
                                            it
                                            can't
                                            be passed from person to person through direct contact or by sharing personal items
                                            like
                                            towels or clothes.</p>
                                        <p>But people with eczema may be more likely to get skin diseases because they scratch
                                            and
                                            rub
                                            their skin so much that it breaks and leaves sores. People with eczema should take
                                            good
                                            care
                                            of their skin and go to the doctor if they get one of these illnesses because they
                                            can
                                            spread.</p><h4><strong>Which foods trigger eczema?</strong></h4>
                                        <p>Each person's body can react differently to certain foods, so the foods that cause
                                            eczema
                                            can
                                            be different for each person. But some popular foods can make eczema symptoms worse
                                            for
                                            some
                                            people:</p>
                                        <ul>
                                            <li>Milk, cheese, and other dairy products can cause eczema in some people because
                                                they
                                                contain proteins that can cause an allergic response or inflammation.
                                            </li>
                                            <li>Eggs: Eggs are a popular food allergen that can make some people get eczema.
                                            </li>
                                            <li>Nuts: Some people can get eczema from peanuts, tree nuts, and other kinds of
                                                nuts.
                                            </li>
                                            <li>Soya products: Some people with eczema may get it from soy products like soy
                                                milk
                                                and
                                                tofu.
                                            </li>
                                            <li>Wheat and meals with gluten: People who are sensitive to gluten may get eczema
                                                from
                                                wheat and other gluten-containing grains like barley.
                                            </li>
                                            <li>Spices: Cinnamon and cloves are two spices that may make eczema symptoms worse
                                                for
                                                some
                                                people.&nbsp;
                                            </li>
                                        </ul>
                                        <h4><strong>How do you treat eczema in children?</strong></h4>
                                        <ol>
                                            <li>The treatment for eczema in children typically involves a combination of
                                                self-care
                                                measures and medical interventions. Here are some steps that can help manage
                                                eczema
                                                in
                                                children:
                                            </li>
                                            <li>Keep the skin moisturized: Moisturizing the skin is one of the most important
                                                self-care
                                                measures for managing eczema in children. Use a gentle, fragrance-free
                                                moisturizer
                                                at
                                                least twice a day, especially after bathing.
                                            </li>
                                            <li>Avoid irritants: Avoid exposing the child to known irritants that can trigger
                                                eczemas,
                                                such as certain soaps, detergents, and fabrics.
                                            </li>
                                            <li>Use mild soaps: Use mild, fragrance-free soap when bathing the child. Avoid hot
                                                water
                                                and keep baths and showers short.
                                            </li>
                                            <li>Dress in comfortable clothing: Dress the child in loose-fitting, breathable
                                                clothing
                                                made from natural materials such as cotton.
                                            </li>
                                            <li>Manage itching: Itching can worsen eczema, so it's important to keep the child's
                                                nails
                                                trimmed short and to discourage scratching. Over-the-counter antihistamines or
                                                prescription medications may be used to manage itching.
                                            </li>
                                            <li>Use prescription medications: In severe cases, a healthcare provider may
                                                prescribe
                                                topical or oral medications to reduce inflammation and relieve itching.
                                            </li>
                                            <li>Allergy testing: If the child's eczema appears to be triggered by a particular
                                                allergen,
                                                allergy testing may be recommended to help identify the trigger and to guide
                                                treatment.
                                            </li>
                                        </ol>
                                        <h4><strong>Ayurvedic cream for eczema?</strong></h4>
                                        <p>Several Ayurvedic creams say that they can help with eczema. But it's important to
                                            remember
                                            that scientists haven't done a lot of study on how well or safely these creams work
                                            or
                                            if
                                            they are safe.</p>
                                        <p>Some of the most popular Ayurvedic creams for eczema are:</p>
                                        <ul>
                                            <li>Neem cream is made from a tree that grows in India. Its leaves and oil are used
                                                in
                                                Ayurveda to treat acne and other skin problems. Neem cream is said to have
                                                anti-inflammatory, antibacterial, and antifungal properties that can help lessen
                                                the
                                                inflammation and itching caused by eczema.
                                            </li>
                                            <li>Turmeric cream: Turmeric is a spice that has qualities that help fight
                                                inflammation
                                                and
                                                free radicals. People say that putting turmeric cream on eczema lesions can help
                                                reduce
                                                inflammation and speed up the mending process.
                                            </li>
                                            <li>Aloe vera cream: Aloe vera is a succulent plant that has been used in Ayurveda
                                                for
                                                hundreds of years to treat different skin problems. People say that aloe vera
                                                cream
                                                has
                                                anti-inflammatory and moisturizing qualities that can help soothe and heal skin
                                                that
                                                has
                                                eczema.
                                            </li>
                                        </ul>
                                        <h4><strong>Does the weather make eczema worse?</strong></h4>
                                        <p>Yes, weather can make eczema worse for some people. Changes in temperature and
                                            humidity
                                            can
                                            affect the skin's natural moisture balance and trigger eczema symptoms. Here are
                                            some
                                            ways
                                            that weather can impact eczema:</p>
                                        <ul>
                                            <li>Cold weather: Cold, dry air can cause the skin to become dry, itchy, and
                                                irritated.
                                                This
                                                can worsen eczema symptoms, especially in areas such as the hands, feet, and
                                                face.
                                            </li>
                                            <li>Hot weather: Hot and humid weather can cause the skin to sweat and become
                                                irritated,
                                                which can trigger eczema flare-ups.
                                            </li>
                                            <li>Low humidity: Dry air can cause the skin to lose moisture, leading to dryness,
                                                itching,
                                                and flakiness.
                                            </li>
                                            <li>High humidity: High humidity can cause the skin to sweat and become more prone
                                                to
                                                bacterial and skin fungal infections, which can worsen eczema symptoms.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'irritable-bowel-syndrome'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>IBS: Causes, Pain Areas, & Treatment in Ayurveda | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>An enormous number of people throughout the world suffer from gastrointestinal
                                    illnesses
                                    known as irritable bowel syndrome (IBS). It is a chronic condition that can cause a
                                    range of
                                    uncomfortable symptoms, such as abdominal pain, bloating, constipation, and
                                    diarrhoea.
                                    IBS
                                    can be frustrating and debilitating, and it can have a significant impact on a
                                    person's
                                    quality of life, affecting their ability to work, socialise, and engage in everyday
                                    activities.&nbsp;</p>
                                <p>The exact cause of IBS is unknown, but it is believed to be related to a combination
                                    of
                                    factors, including genetics, diet, stress, and changes in gut bacteria. While there
                                    is
                                    no
                                    cure for IBS, there are various&nbsp;<a href="https://www.draxico.com/">best
                                        ayurvedic
                                        treatments for ibs</a> options available that can help manage symptoms and
                                    improve
                                    quality of life.</p>
                                <h3><strong>What is Irritable bowel syndrome?</strong></h3>
                                <p>Irritable Bowel Syndrome (IBS) is a gastrointestinal disorder that affects the large
                                    intestine (colon) and is characterised by a group of symptoms, including&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>, bloating, constipation, and diarrhoea.</p>
                                <p>IBS is a prevalent condition, with an estimated 10-15% of the population affected
                                    worldwide.
                                    It is more common in women than men and often begins in early adulthood.</p>
                                <h4><strong>What are the different types of IBS?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/irritable-bowel-syndrome/Image-1.webp"
                                    >
                                </div>
                                <p>There are three different types of irritable bowel syndrome (IBS), which are based on
                                    the
                                    dominant symptom pattern experienced by the individual. These types are:</p>
                                <ul>
                                    <li><strong>IBS Constipation (IBS-C):</strong> Individuals with IBS-C typically
                                        experience
                                        constipation as the predominant symptom, with less frequent bowel movements than
                                        usual,
                                        hard stools, and straining during bowel movements.
                                    </li>
                                    <li><strong>IBS with diarrhoea (IBS-D):</strong> Individuals with IBS-D typically
                                        experience
                                        <a href="https://www.nhsinform.scot/illnesses-and-conditions/stomach-liver-and-gastrointestinal-tract/diarrhoea#:~:text=Diarrhoea%20is%20passing%20looser%2C%20watery,few%20days%20to%20a%20week."
                                           rel="nofollow">diarrhoea</a> as the predominant symptom, with loose, watery
                                        stools,
                                        and a frequent need to
                                        use the bathroom.
                                    </li>
                                    <li><strong>IBS with mixed bowel habits (IBS-M):</strong> Individuals with IBS-M
                                        experience
                                        a mix of both constipation and diarrhoea, with alternating periods of each.
                                    </li>
                                </ul>
                                <p>It is important to note that while these types of IBS are based on predominant
                                    symptoms,
                                    many
                                    people with IBS may experience a combination of symptoms or have varying symptoms
                                    over
                                    time.
                                    Additionally, some individuals may experience symptoms that do not fit into one of
                                    these
                                    categories and may be classified as having "unclassified" IBS.</p>

                                <h3><strong>What are the main factors that cause IBS?</strong></h3>
                                <p>The exact cause of irritable bowel syndrome (IBS) is unknown, but several factors may
                                    contribute to its development. These factors include:</p>
                                <ul>
                                    <li>Abnormal muscle contractions in the colon: In individuals with IBS, the muscles
                                        in
                                        the
                                        colon may contract more forcefully and for longer periods than usual, leading to
                                        abdominal pain and changes in bowel habits.
                                    </li>
                                    <li>Nervous system abnormalities: There may be abnormalities in the nerves that
                                        control
                                        the
                                        digestive system, leading to hypersensitivity to certain foods, stress, or other
                                        triggers.
                                    </li>
                                    <li>Gut bacteria: Changes in the types and amounts of bacteria in the gut may
                                        contribute
                                        to
                                        the development of IBS.
                                    </li>
                                    <li>Food sensitivities: Some individuals with IBS may have food sensitivities or
                                        intolerances that trigger symptoms.
                                    </li>
                                    <li>Psychological factors: Stress, anxiety, and depression can all contribute to the
                                        development and worsening of irritable bowel syndrome symptoms.
                                    </li>
                                    <li>Genetics: There may be a genetic component to the development of IBS, as it
                                        tends to
                                        run
                                        in families.
                                    </li>
                                </ul>
                                <h4><strong>How does IBS affect your body?</strong></h4>
                                <p>Irritable Bowel Syndrome (IBS) can affect the body in several ways, leading to a
                                    range of
                                    uncomfortable symptoms. The main effects of IBS on the body include:</p>
                                <ol>
                                    <li>Abdominal pain and discomfort: IBS can cause <a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>, cramping, bloating, and
                                        discomfort, which can be mild to severe and may be intermittent or persistent.
                                    </li>
                                    <li>Changes in bowel habits: IBS can cause changes in bowel habits, including
                                        constipation,
                                        diarrhoea, or a mix of both. This can lead to straining during bowel movements,
                                        urgency,
                                        or a feeling of incomplete evacuation.
                                    </li>
                                    <li>Fatigue: Many people with IBS experience fatigue, which may be related to poor
                                        sleep,
                                        stress, or the body's response to chronic inflammation.
                                    </li>
                                    <li>Anxiety and depression: IBS can lead to anxiety and depression, which may be
                                        related
                                        to
                                        the chronic nature of the condition and the impact it can have on a person's
                                        quality
                                        of
                                        life.
                                    </li>
                                    <li>Malnutrition: In severe cases of IBS, malnutrition may occur due to the body's
                                        inability
                                        to absorb nutrients properly.
                                    </li>
                                </ol>
                                <h4><strong>Where do symptoms of IBS appear on my body?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/irritable-bowel-syndrome/Image-2.webp"
                                    >
                                </div>
                                ;
                                <p>The symptoms of irritable bowel syndrome (IBS) typically appear in the abdominal
                                    region,
                                    although they can also affect other parts of the body. The most common symptoms of
                                    IBS
                                    include:</p>
                                <ul>
                                    <li><strong>Abdominal pain or discomfort:</strong> This can be a dull ache or a
                                        sharp
                                        pain
                                        that may be relieved by a bowel movement.
                                    </li>
                                    <li><strong>Bloating and distension:</strong> This can cause a feeling of fullness
                                        or
                                        tightness in the abdomen, and the stomach may appear swollen or distended.
                                    </li>
                                    <li><strong>Changes in bowel habits:</strong> This can include constipation,
                                        diarrhoea,
                                        or
                                        alternating periods of both.
                                    </li>
                                    <li><strong>Excessive gas</strong>: This can cause abdominal discomfort, bloating,
                                        and
                                        flatulence.
                                    </li>
                                    <li><strong>Nausea and vomiting:</strong> Some people with IBS may experience nausea
                                        and
                                        vomiting, particularly if they have severe abdominal pain.
                                    </li>
                                    <li><strong>Fatigue:</strong> Many people with IBS experience fatigue, which may be
                                        related
                                        to poor sleep, stress, or the body's response to chronic inflammation.
                                    </li>
                                    <li><strong>Headaches:</strong> Some people with IBS may experience&nbsp;<a
                                        href="https://www.draxico.com/diseases/headaches">headaches</a>, which may be
                                        related to
                                        stress or changes in gut bacteria.
                                    </li>
                                </ul>
                                <h4><strong>When to see a Doctor for IBS and how it is diagnosed?</strong></h4>
                                <p>It is recommended to&nbsp;<a href="https://www.draxico.com/appointment">see a
                                    doctor</a>
                                    if
                                    you experience symptoms of irritable bowel syndrome (IBS) that persist for more than
                                    a
                                    few
                                    weeks, or if your symptoms are interfering with your daily life and activities.</p>
                                <p>To diagnose IBS, your doctor will typically perform a physical exam and review your
                                    medical
                                    history and symptoms. They may also recommend certain tests, such as stool tests,
                                    blood
                                    tests, or imaging tests, to rule out other conditions that may be causing your
                                    symptoms.
                                    There is no single test that can definitively diagnose IBS, but doctors may use
                                    certain
                                    criteria, such as the Rome criteria, to make a diagnosis based on symptoms.</p>
                                <p>If your doctor suspects that your symptoms may be related to food intolerance or
                                    sensitivity,
                                    they may recommend keeping a food diary to track your symptoms and identify any
                                    triggers. In
                                    some cases, they may recommend an elimination diet or refer you to a dietitian for
                                    further
                                    evaluation.</p>
                                <h3><strong>Ayurvedic Say on IBS</strong></h3>
                                <p>Grahani is a term used in Ayurveda to describe a group of disorders related to
                                    digestion
                                    and
                                    metabolism. It is believed to be caused by an imbalance of the doshas, or energetic
                                    forces,
                                    in the body, particularly the pitta dosha, which governs digestion and
                                    metabolism.</p>
                                <p>The term grahani disease is commonly used in Ayurveda to describe conditions that
                                    affect
                                    the
                                    grahani, or small intestine, such as irritable bowel syndrome (IBS), Inflammatory
                                    Bowel
                                    Disease (IBD), and other digestive disorders.</p>
                                <p>In Ayurveda, the treatment of grahani disease typically involves restoring balance to
                                    the
                                    doshas, particularly the pitta dosha, through a combination of dietary changes,
                                    herbal
                                    remedies, and lifestyle modifications. This may involve avoiding certain foods and
                                    spices
                                    that aggravate the pitta dosha, such as spicy and oily foods, and incorporating
                                    cooling
                                    and
                                    calming foods and herbs into the diet, such as coconut water, mint, and
                                    coriander.</p>
                                <h4>
                                    <strong>Ayurvedic Remedies for IBS</strong></h4>
                                <ol>
                                    <li><strong>Ginger tea:</strong> Ginger is a warming herb that can help stimulate
                                        digestion
                                        and reduce inflammation in the gut. Drinking ginger tea 2-3 times a day can help
                                        manage
                                        symptoms of IBS.
                                    </li>
                                    <li><strong>Triphala powder:</strong> Triphala is an Ayurvedic herbal blend that is
                                        commonly
                                        used to promote digestive health and balance the doshas. Taking 1-2 teaspoons of
                                        triphala powder with warm water before bed may help improve digestion and
                                        relieve
                                        constipation.
                                    </li>
                                    <li><strong>Fennel tea:</strong> Fennel is a cooling herb that can help soothe the
                                        digestive
                                        system and reduce bloating and gas. Drinking fennel tea 2-3 times a day may help
                                        manage
                                        symptoms of IBS.
                                    </li>
                                    <li><strong>Aloe vera juice:</strong> Aloe vera is a cooling and soothing herb that
                                        can
                                        help
                                        reduce inflammation in the gut. Drinking 1/4-1/2 cups of aloe vera juice daily
                                        can
                                        help
                                        manage symptoms of IBS.
                                    </li>
                                    <li><strong>Yoga and meditation:</strong> Practising yoga and meditation can help
                                        reduce
                                        stress and promote relaxation, which can be beneficial for managing symptoms of
                                        IBS.
                                        Certain yoga postures, such as the seated forward bend and the spinal twist, can
                                        be
                                        particularly helpful for promoting digestive health.&nbsp;
                                    </li>
                                </ol>
                                <h4><strong>What to eat and not eat</strong></h4><h4><strong>What to eat:</strong></h4>
                                <ol>
                                    <li><strong>Low-FODMAP foods:</strong> A low-FODMAP diet is a dietary approach that
                                        involves
                                        avoiding certain types of carbohydrates that can trigger symptoms of IBS.
                                        Examples
                                        of
                                        low-FODMAP foods include rice, quinoa, oats, potatoes, carrots, cucumber,
                                        bananas,
                                        blueberries, grapes, and lactose-free dairy products.
                                    </li>
                                    <li><strong>Soluble fibre:</strong> Soluble fibre can help regulate bowel movements
                                        and
                                        reduce constipation. Good sources of soluble fibre include oats, bananas,
                                        apples,
                                        carrots, and psyllium husk.
                                    </li>
                                    <li><strong>Probiotic-rich foods:</strong> Probiotics are beneficial bacteria that
                                        can
                                        help
                                        improve gut health and reduce symptoms of IBS. Good sources of probiotics
                                        include
                                        kefir,
                                        sauerkraut, kimchi, yoghourt, and miso.
                                    </li>
                                    <li><strong>Herbal teas:</strong> Herbal teas can help soothe the digestive system
                                        and
                                        reduce inflammation in the gut. Good options for IBS include peppermint tea,
                                        chamomile
                                        tea, and ginger tea.
                                    </li>
                                </ol>
                                <h4><strong>What to avoid:</strong></h4>
                                <ol>
                                    <li><strong>High-FODMAP foods:</strong> High-FODMAP foods are those that are high in
                                        fermentable carbohydrates that can trigger symptoms of IBS. Examples of
                                        high-FODMAP
                                        foods include wheat, barley, rye, onions, garlic, beans, lentils, broccoli, and
                                        cabbage.
                                    </li>
                                    <li><strong>Dairy products:&nbsp;</strong>Dairy products can be difficult to digest
                                        for
                                        some
                                        people with IBS, particularly those who are lactose intolerant. Avoiding or
                                        limiting
                                        dairy products may help manage symptoms.
                                    </li>
                                    <li><strong>Spicy and acidic foods:</strong> Spicy and acidic foods can irritate the
                                        digestive system and exacerbate symptoms of IBS. Examples include hot peppers,
                                        citrus
                                        fruits, tomatoes, and vinegar.
                                    </li>
                                    <li><strong>Caffeine and alcohol:</strong> Caffeine and alcohol can stimulate the
                                        digestive
                                        system and increase symptoms of IBS. Limiting or avoiding these substances may
                                        help
                                        manage symptoms.
                                    </li>
                                </ol>
                                <p>It is important to note that while these general guidelines may help manage IBS,
                                    every
                                    individual is unique and may have different dietary needs and triggers.</p>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for IBS</strong></h4>
                                <p>Here is some best Ayurvedic medicine for ibs:</p>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala
                                        churna</a>
                                    </li>
                                    <li>Kutajarishta</li>
                                    <li>Bilva churna</li>
                                    <li>Dhanyaka Rishta</li>
                                    <li><a href="https://www.draxico.com/products/hingwashtaka-churna">Hingwashtak
                                        churna</a>
                                    </li>
                                    <li>Panchakola churna</li>
                                    <li>Dadimavaleha</li>
                                    <li>Avipattikar churna</li>
                                    <li>Aloe vera</li>
                                    <li>Licorice (Yashtimadhu)</li>
                                    <li>Ginger (Adrak)</li>
                                    <li>Peppermint (Pudina)</li>
                                    <li>Haritaki churna</li>
                                </ul>
                                <h4><strong>A Short Note from Dr. Axico</strong></h4>
                                <p>It is important to consult a qualified Ayurvedic doctor online before using any of
                                    these
                                    herbs or formulations for IBS. As the appropriate dosage and duration of Ayurvedic
                                    treatment
                                    for ibs may vary depending on the individual's constitution and specific symptoms.
                                    You
                                    can
                                    buy Ayurvedic medicine online or offline too.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Does IBS cause gastroenteritis?</strong></h4>
                                        <p>No, IBS does not cause gastroenteritis. Gastroenteritis is an infection of the
                                            digestive
                                            tract that is usually caused by a virus, bacteria, or parasite. The symptoms of
                                            gastroenteritis can be similar to those of IBS, such as diarrhoea, abdominal pain,
                                            and
                                            cramping, which can sometimes make it difficult to distinguish between the two
                                            conditions.&nbsp;</p>
                                        <p>However, the underlying causes of gastroenteritis and IBS are different, and while
                                            gastroenteritis is usually a temporary illness that resolves on its own, IBS is a
                                            chronic
                                            condition that requires ongoing management.</p>
                                        <h4><strong>Ayurvedic remedy for IBS</strong></h4>
                                        <p>Ayurvedic remedies for IBS include Triphala churna, Kutajarishta, Bilva churna,
                                            Hingwashtak
                                            churna, Aloe vera etc. These natural remedies can help regulate bowel movements,
                                            relieve
                                            constipation and diarrhoea, reduce inflammation, and improve digestion. However, it
                                            is
                                            important to consult with a qualified Ayurvedic practitioner for personalised
                                            recommendations and appropriate dosage.</p>
                                        <h4><strong>Best Ayurvedic diet to reduce IBS</strong></h4>
                                        <p>The best Ayurvedic diet for reducing IBS symptoms is personalised to an individual's
                                            needs
                                            and constitution. However, general guidelines include avoiding trigger foods like
                                            spicy
                                            or
                                            fried foods, dairy, caffeine, and alcohol, and focusing on whole, unprocessed foods
                                            such
                                            as
                                            vegetables, fruits, whole grains, and lean proteins.&nbsp;</p>
                                        <p>It is also important to eat mindfully and in a relaxed state and to incorporate
                                            digestive
                                            spices and herbs such as ginger, cumin, and fennel into meals. Consulting with an
                                            Ayurvedic
                                            practitioner can provide tailored dietary recommendations.</p>
                                        <h4><strong>Do I need to see a gastroenterologist for IBS?</strong></h4>
                                        <p>It is recommended to see a healthcare provider for a proper diagnosis and management
                                            plan
                                            for
                                            IBS. A gastroenterologist may be consulted if symptoms are severe or if there is a
                                            suspicion
                                            of other gastrointestinal conditions.</p>
                                        <p>However, a primary care physician or an Ayurvedic practitioner can also provide
                                            guidance
                                            and
                                            support in managing IBS symptoms. It is important to work with a healthcare provider
                                            to
                                            develop an individualised irritable bowel syndrome treatment plan.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'osteoarthritis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Osteoarthritis</h4>
                            </div>
                            <div class="card-body text-left">
                                <p class="card-text">
                                    Osteoarthritis (OA) is the most common form of arthritis in the world. Classically,
                                    OA
                                    presents with joint pain and loss of function; however, the disease is clinically
                                    very variable and can present merely as an
                                    asymptomatic incidental finding to a devastating and permanently disabling disorder.
                                </p>
                                <p class="card-text">
                                    The way that OA manifests and progresses differs substantially from person to
                                    person.
                                    The
                                    three hallmark signs of OA are joint discomfort, stiffness, and restriction of
                                    motion.
                                    Additionally, patients may
                                    experience balance problems and muscle weakness. Pain usually results from
                                    activities
                                    and
                                    goes away with rest. Patients whose illness worsens experience more persistent pain
                                    that
                                    starts to interfere with daily
                                    activities and finally severely restricts their ability to function. Additionally,
                                    patients
                                    may develop joint deformities, instability, and bone enlargement (patients may
                                    report
                                    that
                                    their joints are "giving way"
                                    or "buckling," which is an indication of weak muscles). Proximal and distal
                                    interphalangeal
                                    joints, first carpometacarpal (CMC), hip, knee, first metatarsophalangeal, and lower
                                    cervical and lumbar spine joints
                                    are all commonly affected by OA.
                                </p>
                            </div>
                        </div>
<!--                        <div class="card mt-5">-->
<!--                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>-->
<!--                            <div class="card-body">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
                <div *ngIf="diseasesName === 'peptic-ulcer-disease'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Peptic Ulcer: Gastric Ulcer, Signs, & Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Peptic ulcer disease is a common condition that affects the stomach or small
                                    intestine.
                                    It is
                                    characterised by the formation of open sores or ulcers in the lining of the
                                    digestive
                                    tract,
                                    which can cause a range of symptoms such as abdominal pain, bloating, nausea, and
                                    heartburn.
                                    Peptic ulcer disease can be cured by the&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">Ayurvedic
                                        doctor
                                        online consultation</a>.</p>
                                <p>Peptic ulcer disease can be caused by several factors, including bacterial
                                    infections,
                                    long-term use of certain medications, and lifestyle factors such as smoking and
                                    excessive
                                    alcohol consumption. While it can be a chronic condition, with proper diagnosis and
                                    peptic
                                    ulcer treatment, most people with peptic ulcer disease can manage their symptoms and
                                    prevent
                                    complications.</p>
                                <h3><strong>What is Peptic Ulcer Disease?</strong></h3>
                                <p>Peptic Ulcer Disease (PUD) is a condition in which painful sores or ulcers develop in
                                    the
                                    lining of the stomach, oesophagus, or small intestine. These ulcers occur when the
                                    protective lining of the digestive tract is damaged and the acid and digestive
                                    enzymes
                                    produced by the stomach and intestines begin to erode the tissues.</p>
                                <h4><strong>What are the main factors that cause Peptic
                                    Ulcer?</strong></h4>
                                <p>Several factors can contribute to the development of Peptic Ulcer Disease (PUD).
                                    These
                                    include:</p>
                                <ul>
                                    <li>Helicobacter pylori (H. pylori) infection: This bacterium is the most common
                                        cause
                                        of
                                        PUD. It can be transmitted through contaminated food and water and can live in
                                        the
                                        lining of the stomach and small intestine, causing inflammation and ulcers.
                                    </li>
                                    <li>Use of&nbsp;<a href="https://www.nhs.uk/conditions/nsaids/" rel="nofollow">nonsteroidal
                                        anti-inflammatory drugs</a> (NSAIDs): Long-term use of NSAIDs, such as aspirin,
                                        ibuprofen, and naproxen, can irritate the lining of the stomach and small
                                        intestine,
                                        leading to the formation of ulcers.
                                    </li>
                                    <li>Excessive alcohol consumption: Heavy alcohol use can damage the lining of the
                                        stomach
                                        and increase the production of stomach acid, leading to the development of
                                        ulcers.
                                    </li>
                                    <li>Smoking: Smoking can increase the production of stomach acid and delay the
                                        healing
                                        of
                                        ulcers, making them more likely to recur.
                                    </li>
                                    <li>Stress: Although stress does not directly cause PUD, it can increase the
                                        production
                                        of
                                        stomach acid and make existing ulcers worse.
                                    </li>
                                    <li>Other factors that may contribute to PUD include a family history of ulcers,
                                        being
                                        over
                                        the age of 50, and certain medical conditions, such as&nbsp;<a
                                            href="https://www.ncbi.nlm.nih.gov/books/NBK537344/#:~:text=Zollinger%2DEllison%20syndrome%20(ZES),acid%2Dsecreting%20cells%20of%20the"
                                            rel="nofollow">Zollinger-Ellison
                                            syndrome</a>.
                                    </li>
                                </ul>
                                <h3><strong>How does Peptic Ulcer affect your body?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/peptic-ulcer-disease/Image-2.webp"
                                    >
                                </div>
                                <p>Peptic Ulcer Disease (PUD) can affect the body in several ways:</p>
                                <ul>
                                    <li>Abdominal pain: The most common symptom of PUD is a burning or gnawing pain in
                                        the
                                        abdomen, which may occur between meals or during the night.
                                    </li>
                                    <li>Bleeding: PUD can lead to bleeding from the ulcers, which can cause black, tarry
                                        stools
                                        or vomiting of blood.
                                    </li>
                                    <li>Perforation: In some cases, the ulcers can perforate or create a hole in the
                                        lining
                                        of
                                        the stomach or intestine, which can cause severe abdominal pain, fever, and
                                        rapid
                                        heartbeat.
                                    </li>
                                    <li>Obstruction: PUD can also lead to obstruction or blockage in the digestive
                                        tract,
                                        which
                                        can cause nausea, vomiting, and abdominal distension.
                                    </li>
                                    <li>Malnutrition: If PUD is left untreated, it can interfere with the absorption of
                                        nutrients from the digestive tract, leading to malnutrition and weight loss.
                                    </li>
                                    <li>Increased risk of stomach cancer: Although rare, PUD can increase the risk of
                                        developing
                                        stomach cancer, especially in individuals with untreated H. pylori infection.&nbsp;
                                    </li>
                                </ul>
                                <h4><strong>Where do symptoms of Peptic Ulcer appear on my body?</strong></h4>
                                <p>The symptoms of Peptic Ulcer Disease (PUD) typically appear in the upper abdomen,
                                    between
                                    the
                                    navel and the breastbone. The most common symptom is a burning or gnawing pain in
                                    this
                                    area
                                    that may occur between meals or during the night.</p>
                                <p>If you are facing any issues mentioned above you can take a&nbsp;<a
                                    href="https://www.draxico.com/">free Ayurvedic Doctor Consultation at Dr Axico</a>.
                                </p>
                                <h3><strong>What are the risk factors for Peptic Ulcer?</strong></h3>
                                <p>Several risk factors can increase the likelihood of developing Peptic Ulcer Disease
                                    (PUD),
                                    including:</p>
                                <ul>
                                    <li>Helicobacter pylori infection: H. pylori is a type of bacteria that can infect
                                        the
                                        lining of the stomach and small intestine, leading to inflammation and ulcers.
                                    </li>
                                    <li>Regular use of nonsteroidal anti-inflammatory drugs (NSAIDs): NSAIDs, such as
                                        aspirin,
                                        ibuprofen, and naproxen, can irritate the lining of the stomach and increase the
                                        risk of
                                        developing ulcers.
                                    </li>
                                    <li>Smoking: Smoking can increase the production of stomach acid and decrease the
                                        amount
                                        of
                                        protective mucus in the stomach, making it easier for ulcers to form.
                                    </li>
                                    <li>Excessive alcohol consumption: Alcohol can irritate the lining of the stomach
                                        and
                                        increase the production of stomach acid, increasing the risk of developing
                                        ulcers.
                                    </li>
                                    <li>Family history of PUD: Genetics may play a role in the development of PUD, and
                                        individuals with a family history of the condition may be at higher risk.
                                    </li>
                                    <li>Age: PUD is more common in individuals over the age of 50.</li>
                                    <li>Stress: Although stress is not a direct cause of PUD, it can worsen existing
                                        symptoms.
                                    </li>
                                </ul>
                                <p>Other medical conditions: Certain medical conditions, such as&nbsp;<a
                                    href="https://www.draxico.com/diseases/liver-disease">liver disease</a>,&nbsp;<a
                                    href="https://www.draxico.com/diseases/kidney-infection">kidney disease</a>, and
                                    chronic
                                    obstructive pulmonary disease (COPD), may increase the risk of developing PUD.</p>
                                <h4><strong>When to see a Doctor for Peptic Ulcer and how it is diagnosed?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/peptic-ulcer-disease/Image.webp"
                                    >
                                </div>
                                <p>It's important to see a healthcare provider if you are experiencing symptoms of
                                    Peptic
                                    Ulcer
                                    Disease (PUD) or if you have any risk factors for the condition. Your healthcare
                                    provider
                                    can perform a thorough evaluation and determine the best course of treatment for
                                    your
                                    specific needs.</p>
                                <p>To diagnose PUD, your healthcare provider may recommend one or more of the following
                                    tests:</p>
                                <ul>
                                    <li>Upper endoscopy: This test involves inserting a small, flexible tube with a
                                        camera
                                        into
                                        your mouth and down your oesophagus and stomach to examine the lining for
                                        ulcers.
                                    </li>
                                    <li>Blood test: This test can detect the presence of H. pylori bacteria in the
                                        blood.
                                    </li>
                                    <li>Stool test: This test can detect the presence of H. pylori bacteria in a stool
                                        sample.
                                    </li>
                                    <li>Breath test: This test involves drinking a solution containing radioactive
                                        carbon
                                        and
                                        breathing it into a bag, which is then tested for the presence of H. pylori
                                        bacteria.
                                    </li>
                                    <li>X-ray: This test can help detect stomach ulcer and other abnormalities in the
                                        digestive
                                        tract.&nbsp;
                                    </li>
                                </ul>
                                <h3><strong>Ayurvedic Say on Peptic Ulcer</strong></h3>
                                <p><strong>&nbsp;</strong>In Ayurveda, Peptic Ulcers can be correlated to parinama shula
                                    and
                                    annadrava shula. They are the subtypes of Shoola. Shula is a term for pain or colic,
                                    specifically used to indicate abdominal pain.</p>
                                <p>Vata becomes excessively vitiated after ingesting or being exposed to the causes that
                                    aggravate it. These two doshas then obstruct this vitiated Vayu as a result of its
                                    relationship with morbid pitta and kapha. Severe shoola results from this vitiated
                                    Vata
                                    being combined with and inhibited by pitta and kapha. It's known as Parinama
                                    Shula.</p>
                                <h4>
                                    <strong>Ayurveda recommends several remedies for managing Parinamashula,
                                        including:</strong>
                                </h4>
                                <ol>
                                    <li>Following a Pitta-pacifying diet: A diet that is cooling, soothing, and
                                        Pitta-pacifying
                                        can help alleviate Parinamashula symptoms. This may include consuming plenty of
                                        fruits
                                        and vegetables, whole grains, and dairy products.
                                    </li>
                                    <li>Consuming herbs and spices: Certain herbs and spices, such as licorice, aloe
                                        vera,
                                        turmeric, and fennel, may have anti-inflammatory properties and can help soothe
                                        the
                                        digestive system.
                                    </li>
                                    <li>Practising stress-reducing techniques: Stress can exacerbate Parinamashula
                                        symptoms,
                                        so
                                        practising stress-reducing techniques such as yoga, meditation, or deep
                                        breathing
                                        can be
                                        helpful.
                                    </li>
                                    <li>Avoiding alcohol and tobacco: Alcohol and tobacco can irritate the digestive
                                        system
                                        and
                                        exacerbate Parinamashula symptoms, so avoiding these substances is recommended.
                                    </li>
                                    <li>Taking Ayurvedic formulations: Ayurvedic formulations such as Amalaki Churna,
                                        Avipattikar Churna, and Yashtimadhu Churna may be recommended by an Ayurvedic
                                        practitioner to help manage Parinamashula symptoms.
                                    </li>
                                </ol>
                                <h4><strong>Ayurvedic Remedies for Peptic Ulcer</strong></h4>
                                <p>Ayurveda offers several remedies for managing Peptic Ulcer Disease (PUD). Some of
                                    these
                                    remedies include:</p>
                                <ol>
                                    <li><strong>Licorice root:</strong> Licorice root has been used in Ayurvedic
                                        medicine
                                        for
                                        centuries to treat digestive disorders, including PUD and acidity. It has
                                        anti-inflammatory properties that can help soothe the lining of the stomach and
                                        intestines.
                                    </li>
                                    <li><strong>Aloe vera:</strong> Aloe vera is known for its soothing and healing
                                        properties
                                        and can help reduce inflammation and pain associated with PUD.
                                    </li>
                                    <li><strong>Turmeric:</strong> Turmeric has anti-inflammatory and antioxidant
                                        properties
                                        that can help soothe the digestive system and promote the healing of the stomach
                                        lining.
                                    </li>
                                    <li><strong>Fennel:</strong> Fennel has been traditionally used in Ayurveda to treat
                                        digestive disorders. Its anti-inflammatory and antispasmodic qualities can aid
                                        in
                                        easing
                                        PUD symptoms related to stomach ulcers.
                                    </li>
                                    <li><strong>Indian gooseberry (amla):</strong> Indian gooseberry is rich in vitamin
                                        C
                                        and
                                        has anti-inflammatory properties that can help soothe the digestive system and
                                        promote
                                        healing.
                                    </li>
                                    <li><strong>Ghee:</strong> Ghee is a type of clarified butter that is used in
                                        Ayurveda
                                        for
                                        its healing properties. It can help soothe the digestive system and promote
                                        healing
                                        of
                                        the stomach lining.
                                    </li>
                                    <li><strong>Triphala:</strong>&nbsp;<a
                                        href="https://www.draxico.com/products/sodhi-triphala">Triphala</a> is an
                                        Ayurvedic
                                        herbal formula made up of three fruits - amla, haritaki, and bibhitaki. It is
                                        known
                                        for
                                        its digestive properties and can help soothe the digestive system and promote
                                        the
                                        healing of the stomach lining.
                                    </li>
                                </ol>
                                <h4><strong>What to eat and not eat</strong></h4><h4><strong>Foods to eat:</strong></h4>
                                <ul>
                                    <li>Cooked vegetables, especially bitter and astringent ones like kale, broccoli,
                                        and
                                        Brussels sprouts
                                    </li>
                                    <li>Cooling fruits like sweet apples, ripe pears, and melons</li>
                                    <li>Whole grains like quinoa, barley, and brown rice</li>
                                    <li>Mung dal (split mung beans), a protein-rich and easily digestible pulse</li>
                                    <li>Ghee (clarified butter), which is cooling and helps lubricate the digestive
                                        tract
                                    </li>
                                    <li>Herbal teas like licorice, fennel, and chamomile</li>
                                </ul>
                                <h4><strong>Foods to avoid:</strong></h4>
                                <ul>
                                    <li>Spicy and acidic foods like chilli peppers, tomatoes, and vinegar</li>
                                    <li>Fermented foods like pickles, sauerkraut, and yoghourt</li>
                                    <li>Sour fruits like oranges, grapefruits, and lemons</li>
                                    <li>Fried and greasy foods</li>
                                    <li>Alcohol and caffeine</li>
                                    <li>Red meat and processed meats</li>
                                </ul>
                                <h4><strong>Recommended Ayurvedic single Herbs and medication for Peptic Ulcer</strong>
                                </h4>
                                <p>Here is some of the best peptic ulcer ayurvedic medicine:</p>
                                <ul>
                                    <li>Yashtimadhu</li>
                                    <li>Turmeric (Haridra)</li>
                                    <li><a href="https://www.draxico.com/products/brahmi-vati">Brahmi</a></li>
                                    <li><a href="https://www.draxico.com/products/guduchi-ghana-vati">Guduchi</a></li>
                                    <li>Shatavari</li>
                                    <li>Yashtimadhu churna</li>
                                    <li>Pippali</li>
                                    <li>Dhatri lauha</li>
                                    <li><a href="https://www.draxico.com/products/kamadudha-ras">Kamadudha rasa</a></li>
                                    <li><a href="https://www.draxico.com/products/hingwashtaka-churna">Hingvastak
                                        churna</a>
                                    </li>
                                    <li>Shankha Bhasma</li>
                                    <li>Kutajarishta</li>
                                    <li>Avipattikar Churna</li>
                                    <li><a href="https://www.draxico.com/products/triphaladi-guggulu">Triphala
                                        churna</a>
                                    </li>
                                </ul>
                                <p>It is important to note that these Ayurvedic remedies should be taken under the
                                    guidance
                                    of a
                                    qualified Ayurvedic practitioner.</p><h4><strong>A Short Note from Dr.
                                Axico</strong>
                            </h4>
                                <p>The greatest thing you can do for your health is talk to an Ayurvedic doctor online
                                    if
                                    you've
                                    been taking a lot of antacids. Lately, if you're continuously nibbling to relieve a
                                    gnawing
                                    discomfort in your stomach, or if you have any other stomach ulcer symptoms. An
                                    ulcer
                                    can be
                                    cured with Ayurvedic treatment in a few weeks and you can buy Ayurvedic medicine
                                    online
                                    or
                                    offline from Dr. Axico.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Can coffee and spicy foods cause
                                            ulcers?</strong>
                                        </h4>
                                        <p>While coffee and spicy foods do not directly cause ulcers, they can irritate the
                                            lining
                                            of
                                            the stomach and worsen symptoms in people who already have ulcers. It is recommended
                                            to
                                            limit the intake of coffee and spicy foods, especially if experiencing symptoms of
                                            peptic
                                            ulcers.</p>
                                        <h4><strong>Will drinking milk help an ulcer?</strong></h4>
                                        <p>While drinking milk may provide temporary relief from ulcer pain, it is not a
                                            recommended
                                            long-term solution. Milk can stimulate the stomach to produce more acid, which can
                                            exacerbate ulcers over time. It is better to follow a balanced and nutritious diet
                                            recommended by a healthcare professional to manage ulcers.</p>
                                        <h4><strong>Is it safe to take antacids in a peptic ulcer?</strong></h4>
                                        <p>Antacids are a common stomach ulcer treatment for peptic ulcers, as they can help to
                                            reduce
                                            symptoms such as heartburn and indigestion. Antacids work by neutralising stomach
                                            acid,
                                            which can provide relief from the burning sensation associated with ulcers. However,
                                            it
                                            is
                                            important to follow the dosage instructions and not overuse antacids, as excessive
                                            use
                                            can
                                            cause side effects such as constipation and diarrhoea.</p>
                                        <h4><strong>Ayurvedic home remedy for peptic ulcer</strong></h4>
                                        <ul>
                                            <li>Mulethi(Licorice)- ¼ spoon of licorice powder should be taken with 1 spoon of
                                                honey
                                                or 1
                                                spoon of melted ghee will heal the ulcers if taken for a few weeks regularly.
                                            </li>
                                            <li>Garlic- 6-8 cloves of garlic is smashed and boiled with 60 ml of milk and 240 ml
                                                of
                                                water and reduced until all the water evaporates and only milk (60 ml) is left
                                                over.
                                                The
                                                filtrate is consumed warmly.
                                            </li>
                                            <li>Coconut- coconut milk and coconut water have anti-ulcer properties. Drink a few
                                                cups
                                                of
                                                fresh coconut milk or tender coconut water daily.
                                            </li>
                                            <li>Honey- Mix raw honey and Indian gooseberry (amla) juice and consume on an empty
                                                stomach
                                                in the morning.
                                            </li>
                                        </ul>
                                        <p>It is important to note that these home remedies may not be suitable for everyone,
                                            and
                                            it's
                                            always best to consult with a qualified Ayurvedic practitioner before trying
                                            them.</p>
                                        <h4>
                                            <strong>Best food for a peptic ulcer patient</strong></h4>
                                        <p>According to Ayurveda, the best foods for peptic ulcer patients are those that are
                                            easy
                                            to
                                            digest and have a cooling effect on the body. Here are some recommended foods:</p>
                                        <ul>
                                            <li><strong>Rice:</strong> Rice is easy to digest and helps to soothe the digestive
                                                system.
                                            </li>
                                            <li><strong>Coconut water:</strong> Coconut water is cooling and helps to reduce
                                                inflammation in the stomach.
                                            </li>
                                            <li><strong>Vegetables:</strong> Cooked vegetables like pumpkin, sweet potato, and
                                                zucchini
                                                are easy to digest and provide essential nutrients.
                                            </li>
                                            <li><strong>Fruits:</strong> Ripe and sweet fruits like bananas, pears, and papayas
                                                are
                                                recommended.
                                            </li>
                                            <li><strong>Milk:</strong> Milk is a good source of calcium and can help to soothe
                                                the
                                                stomach. However, some people with peptic ulcers may be lactose intolerant, so
                                                it is
                                                best to check with a doctor before consuming milk.
                                            </li>
                                            <li><strong>Herbal teas:</strong> Herbal teas like chamomile and licorice can help
                                                to
                                                soothe
                                                the digestive system and reduce inflammation.
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'psoriasis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Psoriasis: Patchy Skin, Signs, Causes & Treatment | Dr. Axico
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Millions of individuals across the world struggle with the chronic autoimmune skin
                                    condition
                                    known as psoriasis. It is characterized by the fast growth of skin cells, which
                                    accumulate
                                    on the skin's surface and result in the development of thick, red, scaly patches.
                                    These
                                    patches may crack and bleed, and they may also itch and hurt. Book your Ayurvedic
                                    online
                                    consultation now and get rid of Psoriasis.&nbsp;&nbsp;</p>
                                <p>Although psoriasis is not communicable, it can persist for a lifetime and harm a
                                    person's
                                    quality of life. Psoriasis is thought to be caused by an overactive immune system
                                    that
                                    unintentionally destroys healthy skin cells, which causes them to develop too
                                    quickly.&nbsp;</p>
                                <p>The specific etiology of psoriasis is unknown. Plaque psoriasis, guttate psoriasis,
                                    inverse
                                    psoriasis, pustular psoriasis, and erythrodermic psoriasis are some of the several
                                    kinds
                                    of
                                    psoriasis. Most occurrences of psoriasis are of the plaque variety, which makes up
                                    roughly
                                    80% of all cases.</p>
                                <h3><strong>What are the types of psoriasis?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/psoriasis/Image-1.webp"
                                    >
                                </div>
                                <p>Psoriasis comes in a variety of forms, each with its distinct symptoms and traits.
                                    The
                                    most
                                    typical forms of psoriasis consist of:</p>
                                <ul>
                                    <li>Plaque psoriasis: Representing roughly 80% of all cases, this is the most
                                        prevalent
                                        type
                                        of psoriasis. Raised, crimson skin patches with silvery-white scales are its
                                        defining
                                        feature. These patches may crack and bleed, and they may also itch and hurt.
                                    </li>
                                    <li>Guttate psoriasis: Often on the trunk, arms, or legs, these tiny, red, scaly
                                        patches
                                        of
                                        skin are indicative of this kind of psoriasis. A bacterial infection, such as
                                        strep
                                        throat, is frequently the cause.
                                    </li>
                                    <li>An inverse psoriasis is a form of psoriasis that affects folds or creases on the
                                        skin,
                                        such as the armpits, groyne, and area under the breasts. It appears as smooth,
                                        itchy
                                        skin patches that are red and can be triggered by friction or perspiration.
                                    </li>
                                    <li>Pustular psoriasis: This uncommon type of psoriasis manifests as red, swollen,
                                        pus-filled blisters on the skin. It might only affect a small portion of the
                                        body or
                                        it
                                        might affect the entire body.
                                    </li>
                                    <li>The most severe type of psoriasis, erythrodermic psoriasis results in extensive
                                        skin
                                        redness and scaling. It might be fatal; therefore, you should get medical help
                                        right
                                        now.
                                    </li>
                                    <li>Less frequent forms of psoriasis include scalp psoriasis, which causes red,
                                        scaly
                                        areas
                                        on the scalp, and nail psoriasis, which affects the nails and surrounding skin.
                                    </li>
                                </ul>
                                <h4><strong>What are the main factors that cause Psoriasis?</strong></h4>
                                <p>Psoriasis is thought to be caused by a combination of genetic, immunological, and
                                    environmental factors, while the specific reason is yet unknown. The following are
                                    some
                                    of
                                    the key causes or aggravating factors for psoriasis:</p>
                                <ul>
                                    <li>Psoriasis has a substantial genetic component; up to one-third of those who have
                                        the
                                        disorder have a family history of it. When exposed to specific triggers, some
                                        genes
                                        may
                                        make a person more prone to getting psoriasis.
                                    </li>
                                    <li>Immune system: Psoriasis is regarded as an autoimmune disorder, which means that
                                        healthy
                                        skin cells are wrongly attacked by the body's immune system. This causes the
                                        skin
                                        cells
                                        to grow quickly and the formation of psoriasis plaques.
                                    </li>
                                    <li>Environmental triggers: Several environmental variables, such as stress, skin
                                        injuries,
                                        infections (such as strep throat), smoking, and alcohol intake, can cause or
                                        aggravate
                                        psoriasis.
                                    </li>
                                    <li>Medication: Some people's psoriasis may be brought on by or made worse by
                                        certain
                                        medications, including lithium, <a
                                            href="https://www.ncbi.nlm.nih.gov/books/NBK532906/"
                                            rel="nofollow">beta-blockers</a>, and antimalarials.
                                    </li>
                                    <li>Obesity: Being overweight or obese increases your risk of getting psoriasis and
                                        can
                                        make
                                        your current condition worse.
                                    </li>
                                </ul>
                                <h4><strong>What does psoriasis look like?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/psoriasis/Image-2.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Psoriasis is a chronic skin condition whose appearance varies based on its type
                                        and
                                        severity. Here are some shared attributes:
                                    </li>
                                    <li>Psoriasis typically manifests as inflamed, elevated red regions on the skin.
                                        These
                                        regions are typically reddish or purplish in hue and may be topped with a
                                        silvery-white
                                        scale.
                                    </li>
                                    <li>The affected epidermis may thicken and develop a scaly or gritty texture. Scales
                                        may
                                        be
                                        brittle and flaking, similar to dandruff.
                                    </li>
                                    <li>Itching and Discomfort or Irritation: Psoriasis lesions are frequently
                                        irritating
                                        and
                                        may cause discomfort or irritation. Scratching the afflicted areas can
                                        exacerbate
                                        the
                                        disease.
                                    </li>
                                    <li>Symmetrical Distribution: Psoriasis lesions typically appear symmetrically on
                                        both
                                        sides
                                        of the body. The cranium, elbows, knees, lower back, and palms are common
                                        locations.
                                    </li>
                                    <li>Changes to the Nails Psoriasis can cause pitting, discoloration, enlargement,
                                        and
                                        separation from the nail bed in the nails. The appearance of nails may be ridged
                                        or
                                        brittle.
                                    </li>
                                </ul>
                                <h4><strong>What is psoriatic arthritis?</strong></h4>
                                <p>&nbsp;Psoriatic arthritis is a type of inflammatory arthritis that affects some
                                    people
                                    with
                                    psoriasis. It's estimated that about 30% of people with psoriasis will develop
                                    psoriatic
                                    arthritis at some point in their lives.</p>
                                <p>Psoriatic arthritis can affect any joint in the body, causing pain, stiffness, and
                                    swelling.
                                    It can also cause inflammation in the tendons and ligaments, especially where they
                                    attach to
                                    the bones. In some cases, psoriatic arthritis can also affect the spine, causing a
                                    condition
                                    called spondylitis.</p>
                                <p>The symptoms of psoriatic arthritis can vary widely but may include joint pain,
                                    swelling,
                                    and
                                    stiffness, especially in the fingers and toes. Other symptoms may include fatigue,
                                    eye
                                    inflammation, and skin lesions.</p>
                                <h3><strong>What are the risk factors for Psoriasis?</strong></h3>
                                <p>There are several risk factors for psoriasis, including:</p>
                                <ol>
                                    <li>Family history: Having a family member with psoriasis increases the risk of
                                        developing
                                        the condition.
                                    </li>
                                    <li>Genetics: Certain genes are associated with psoriasis, although it's not yet
                                        clear
                                        exactly how they contribute to the development of the condition.
                                    </li>
                                    <li>Age: Psoriasis can develop at any age, but it most commonly appears between the
                                        ages
                                        of
                                        15 and 35.
                                    </li>
                                    <li>Gender: Psoriasis affects both men and women equally, but some studies suggest
                                        that
                                        men
                                        are more likely to develop psoriasis at a younger age, while women are more
                                        likely
                                        to
                                        develop it later in life.
                                    </li>
                                    <li>Obesity: Being overweight or obese is a risk factor for psoriasis, and may also
                                        make
                                        existing symptoms worse.
                                    </li>
                                    <li>Smoking: Smoking is associated with a higher risk of developing psoriasis, as
                                        well
                                        as
                                        more severe symptoms.
                                    </li>
                                    <li>Stress: Stress can trigger or worsen psoriasis symptoms in some people.</li>
                                </ol>
                                <h4><strong>When to see a Doctor for Psoriasis and how it is diagnosed?</strong></h4>
                                <p>If you suspect that you have psoriasis, or if you have a rash or skin condition that
                                    isn't
                                    improving with over-the-counter treatments, it's a good idea to see a healthcare
                                    provider
                                    for an accurate diagnosis and treatment plan.</p>
                                <p>To diagnose psoriasis, a healthcare provider will typically perform a physical exam
                                    and
                                    ask
                                    about your symptoms and medical history. They may also take a skin biopsy, in which
                                    a
                                    small
                                    sample of skin is removed and examined under a microscope, to confirm the
                                    diagnosis.</p>
                                <p>To rule out similar conditions, more tests may be done.</p>
                                <p>It's also important to see a healthcare provider if you have psoriasis and develop
                                    joint
                                    pain
                                    or swelling, as this may be a sign of psoriatic arthritis.</p>
                                <h3><strong>Ayurvedic Say on Psoriasis?</strong></h3>
                                <p>Psoriasis cure in Ayurveda, Pitta, and Vata imbalances induce psoriasis. Pitta dosha
                                    denotes
                                    fire and water and causes heat and irritation. Vata dosha is dry and unstable,
                                    representing
                                    air and space.</p>
                                <p>Ayurveda says psoriasis is caused by poor digestion and a weak immune system
                                    accumulating
                                    poisons (ama). Toxins obstruct bodily pathways, causing inflammation and psoriasis
                                    symptoms.</p>
                                <p>The causes of dosha imbalance and toxin accumulation differ by person. Spicy, sour,
                                    and
                                    fermented foods, harsh weather, stress, anxiety, and poor lifestyles are common
                                    causes.</p>
                                <p>Ayurvedic psoriasis diagnosis entails examining the patient's Prakriti and
                                    imbalances.
                                    Through comprehensive questioning, physical signs, and pulse and tongue testing,
                                    this is
                                    done.</p>
                                <p>Psoriasis Ayurvedic treatment balances doshas, eliminate poisons, and promotes
                                    health.
                                    Methods include:</p>
                                <p><a href="https://www.draxico.com/panchakarma-treatment">Panchakarma therapies</a>:
                                    These
                                    detoxification and purifying treatments are advised. Vamana
                                    (therapeutic vomiting), Virechana (purgation), Basti (medicated enema), and
                                    Raktamokshana
                                    (bloodletting) are examples.</p>
                                <p>Anti-inflammatory, detoxifying, and immune-modulating Ayurvedic medicines are
                                    employed.
                                    Neem,
                                    turmeric, aloe vera, manjistha, guduchi, and ashwagandha are advised for
                                    psoriasis.</p>
                                <p>Diet and lifestyle: Avoid spicy and fermented foods and follow a dosha-specific diet.
                                    Stress
                                    reduction, exercise, and a regular regimen are lifestyle changes.</p>
                                <p>A qualified Ayurvedic practitioner should administer psoriasis remedies. Ayurveda can
                                    help
                                    with psoriasis, but a doctor must diagnose and treat it.</p><h4><strong>Ayurvedic
                                Remedies
                                for Psoriasis</strong></h4>
                                <p>Several Ayurvedic remedies treat psoriasis. However, individual responses to these
                                    therapies
                                    may vary, so see a skilled Ayurvedic practitioner for personalized guidance.
                                    Ayurvedic
                                    psoriasis treatments include:</p>
                                <p>Ayurvedic skin remedies include neem (Azadirachta indica). Anti-inflammatory,
                                    antibacterial,
                                    and immune-modulating. Neem has internal and exterior uses. Neem leaves, oil, or
                                    capsules
                                    can be used topically or ingested.</p>
                                <p>Anti-inflammatory and antioxidant turmeric (Curcuma longa) is frequently utilized. It
                                    can
                                    be
                                    eaten in food or pills. The turmeric-water paste may also help.</p>
                                <p>Aloe vera (Aloe barbadensis) soothes and cools the skin. Psoriasis can be treated
                                    with
                                    aloe
                                    vera gel.</p>
                                <p>Manjistha (Rubia cordifolia) purifies and detoxifies the blood. It helps the skin in
                                    Ayurveda. Manjistha capsules or decoctions can be administered internally.</p>
                                <p>Guduchi, commonly known as Giloy, is an immunomodulatory plant. It boosts immunity
                                    and
                                    health. Guduchi capsules or decoctions can be administered internally.</p>
                                <p>Triphala: Triphala contains three fruits: amalaki (Emblica officinalis), bibhitaki
                                    (Terminalia bellirica), and haritaki (Terminalia chebula). It cleanses and digests.
                                    Triphala
                                    aids digestion and detoxifies.</p>
                                <p>Ayurveda also recommends a balanced diet, stress management, frequent exercise, and
                                    enough
                                    sleep.</p><h4><strong>What to eat and not eat?</strong></h4><h4><strong>Foods to
                                Eat:</strong></h4>
                                <ol>
                                    <li>Fresh, Whole Foods: Emphasize the consumption of fresh fruits, vegetables, whole
                                        grains,
                                        legumes, nuts, and seeds. These foods are generally considered nourishing and
                                        supportive
                                        of overall health.
                                    </li>
                                    <li>Cooling Foods: Include foods with cooling properties to help pacify Pitta dosha.
                                        These
                                        include cucumber, melons, coconut water, leafy greens, and coriander.
                                    </li>
                                    <li>Anti-Inflammatory Herbs and Spices: Incorporate herbs and spices with
                                        anti-inflammatory
                                        properties, such as turmeric, ginger, cumin, coriander, and fennel, into your
                                        meals.
                                    </li>
                                    <li>Omega-3 Fatty Acids: Include sources of omega-3 fatty acids, such as flaxseeds,
                                        chia
                                        seeds, walnuts, and fatty fish like salmon, to support skin health and reduce
                                        inflammation.
                                    </li>
                                    <li>Herbal Teas: Enjoy soothing herbal teas like chamomile, licorice, and rosehip,
                                        which
                                        can
                                        help promote relaxation and support the immune system.
                                    </li>
                                </ol>
                                <h4><strong>Foods to Avoid or Limit:</strong></h4>
                                <ol>
                                    <li>Spicy and Sour Foods: Minimize or avoid spicy, sour, and fermented foods, as
                                        they
                                        can
                                        aggravate Pitta dosha and potentially worsen inflammation.
                                    </li>
                                    <li>Red Meat: Limit the consumption of red meat, as it is believed to be heating and
                                        can
                                        increase inflammation in some individuals.
                                    </li>
                                    <li>Alcohol and Caffeine: Reduce or avoid alcohol and caffeine, as they can
                                        dehydrate
                                        the
                                        body and potentially trigger or exacerbate psoriasis symptoms.
                                    </li>
                                    <li>Processed and Packaged Foods: Minimize the intake of processed and packaged
                                        foods,
                                        as
                                        they often contain additives, preservatives, and unhealthy fats that can
                                        contribute
                                        to
                                        inflammation.
                                    </li>
                                    <li>Nightshade Vegetables: Some individuals with psoriasis may find that nightshade
                                        vegetables like tomatoes, eggplants, and peppers worsen their symptoms. It's
                                        advised
                                        to
                                        observe how your body reacts to these foods and make adjustments accordingly.&nbsp;
                                    </li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Medication for Psoriasis?</strong></h4>
                                <p>In Ayurveda, various formulations and medications are used to support the management
                                    of
                                    psoriasis. It's important to note that the selection and dosage of medication may
                                    vary
                                    depending on individual factors and the guidance of a qualified Ayurvedic
                                    practitioner.
                                    Here
                                    are some Psoriasis Ayurvedic medicine commonly recommended for psoriasis:</p>
                                <ol>
                                    <li>Kaishore Guggulu: Kaishore Guggulu is a classical Ayurvedic formulation known
                                        for
                                        its
                                        detoxifying and anti-inflammatory properties. It is often used to support
                                        healthy
                                        skin
                                        and manage skin conditions like psoriasis. It contains herbs like guggulu,
                                        triphala,
                                        trikatu, and vidanga.
                                    </li>
                                    <li>Mahamanjisthadi Kwath: Mahamanjisthadi Kwath is a decoction made from a
                                        combination
                                        of
                                        herbs, including manjistha, neem, and khadira. It is believed to have
                                        blood-purifying,
                                        detoxifying, and anti-inflammatory properties. It is commonly used in the
                                        management
                                        of
                                        skin disorders, including psoriasis.
                                    </li>
                                    <li>Panchatikta Ghrita Guggulu: Panchatikta Ghrita Guggulu is an herbal ghee-based
                                        formulation that combines various bitter herbs like neem, patola, guduchi, and
                                        kutki. It
                                        is known for its detoxifying, anti-inflammatory, and immune-modulating effects.
                                        It
                                        is
                                        often used to support the management of skin diseases, including psoriasis.
                                    </li>
                                    <li>Gandhaka Rasayana: Gandhaka Rasayana is a traditional Ayurvedic preparation that
                                        contains purified sulfur as the main ingredient. It is known for its
                                        antimicrobial
                                        and
                                        skin-healing properties. It may be used internally or applied topically to
                                        support
                                        the
                                        management of psoriasis.
                                    </li>
                                    <li>Arogyavardhini Vati: Arogyavardhini Vati is a classical Ayurvedic formulation
                                        that
                                        contains a blend of herbs and minerals. It is believed to have detoxifying,
                                        immune-modulating, and anti-inflammatory effects. It is often used in the
                                        management
                                        of
                                        skin disorders, including psoriasis.
                                    </li>
                                </ol>
                                <p>Remember, it is essential to consult with a qualified Ayurvedic practitioner for a
                                    proper
                                    diagnosis and personalized treatment plan. They will consider your individual needs,
                                    doshic
                                    constitution, and any other health conditions to determine the most suitable
                                    medications
                                    and
                                    dosages for your specific case.</p><h4><strong>A Short Note from Dr. Axico</strong>
                            </h4>
                                <p>A scratchy skin condition called psoriasis can appear and disappear throughout your
                                    life.
                                    It
                                    stems from an overactive immune system and is not spread by other people. Speak with
                                    your
                                    healthcare practitioner if you have skin changes that don't go away. Psoriasis
                                    doesn't
                                    have
                                    a cure, although treatments can lessen symptoms.&nbsp;</p>
                                <p>You can take the help of Ayurvedic doctor consultation that might advise using a
                                    certain
                                    moisturizer, lotion, or drug. If creams or medications are ineffective, there are
                                    other
                                    treatments available for Psoriasis in Ayurveda. Keeping up your general health will
                                    also
                                    aid
                                    in symptom improvement. Buy Ayurvedic medicine online with a few easy steps from Dr.
                                    Axico.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Is psoriasis the same as eczema?</strong></h4>
                                        <p>No, psoriasis and eczema (also known as atopic dermatitis) are two different skin
                                            conditions
                                            with distinct characteristics. While they may share some similarities in terms of
                                            skin
                                            symptoms, they have different underlying causes and require different treatment
                                            approaches.
                                            Here are some key differences between psoriasis and eczema:</p>
                                        <ol>
                                            <li>Causes: Psoriasis is an autoimmune condition in which the immune system
                                                mistakenly
                                                attacks healthy skin cells, causing an accelerated skin cell turnover. Eczema,
                                                on
                                                the
                                                other hand, is believed to be a combination of genetic and environmental
                                                factors,
                                                including a compromised skin barrier function and an overactive immune response.
                                            </li>
                                            <li>Appearance: Psoriasis typically presents as raised, red patches of skin with a
                                                silvery-white scale. The patches are well-defined and can be symmetrical.
                                                Eczema, on
                                                the
                                                other hand, is characterized by red, inflamed, and itchy skin that may appear
                                                dry,
                                                scaly, or oozing. Eczema patches are often more diffuse and may vary in
                                                appearance.
                                            </li>
                                            <li>Locations: Psoriasis commonly affects areas such as the scalp, elbows, knees,
                                                lower
                                                back, and palms. Eczema tends to occur in the creases of the skin, such as the
                                                folds
                                                of
                                                the elbows, behind the knees, and around the neck.
                                            </li>
                                            <li>Psoriasis Triggers: Psoriasis can be triggered by factors like stress, certain
                                                medications, infections, or skin injuries. Eczema flare-ups can be triggered by
                                                allergens, irritants (such as harsh soaps or detergents), dry weather, and
                                                emotional
                                                stress.
                                            </li>
                                            <li>Age of Onset: Psoriasis often develops between the ages of 15 and 35, although
                                                it
                                                can
                                                occur at any age. Eczema commonly appears in early childhood and may improve or
                                                resolve
                                                as a person gets older.
                                            </li>
                                        </ol>
                                        <h4><strong>Ayurvedic home remedies for psoriasis</strong></h4>
                                        <ol>
                                            <li>Aloe Vera: Apply fresh aloe vera gel directly to the affected areas of the skin.
                                                Aloe
                                                vera has soothing and anti-inflammatory properties that may help reduce redness,
                                                itching, and scaling.
                                            </li>
                                            <li>Turmeric: Mix turmeric powder with water or sesame oil to form a paste and apply
                                                it
                                                to
                                                the affected areas. Turmeric contains curcumin, which has anti-inflammatory and
                                                antioxidant properties that may help alleviate symptoms.
                                            </li>
                                            <li>Neem: Boil neem leaves in water and use the cooled solution to wash the affected
                                                areas.
                                                Neem has antibacterial and antifungal properties and may help relieve itching
                                                and
                                                prevent secondary infections.
                                            </li>
                                            <li>Coconut Oil: Gently massage organic coconut oil onto the affected skin. Coconut
                                                oil
                                                can
                                                help moisturize the skin and reduce dryness and scaling.
                                            </li>
                                            <li>Dead Sea Salt Soak: Add Dead Sea salt or Epsom salt to warm bathwater and soak
                                                in it
                                                for
                                                15-20 minutes. This can help soothe the skin, reduce inflammation, and promote
                                                healing.
                                            </li>
                                            <li>Herbal Tea: Drink herbal teas made from herbs like chamomile, licorice, and
                                                burdock
                                                root, which have anti-inflammatory and detoxifying properties. These teas may
                                                help
                                                support overall skin health.
                                            </li>
                                        </ol>
                                        <h4><strong>Is psoriasis a lifetime disease</strong></h4>
                                        <p>Psoriasis is a chronic condition, meaning that it typically lasts for years or even a
                                            lifetime. It is characterized by periods of exacerbation and remission. Although
                                            there
                                            is no
                                            known cure for psoriasis, numerous treatment options can help effectively manage and
                                            control
                                            its symptoms.</p>
                                        <p>The severity and progression of psoriasis can differ from individual to individual.
                                            Some
                                            individuals may experience transient, mild symptoms, while others may experience
                                            more
                                            severe, persistent symptoms. Genetics, immune system function, lifestyle, and
                                            environmental
                                            triggers can affect the frequency and severity of flare-ups.</p>
                                        <h4><strong>Is psoriasis contagious</strong></h4>
                                        <p>Psoriasis cannot spread. Psoriasis is non-contagious. The immune system assaults
                                            healthy
                                            skin
                                            cells in psoriasis, prompting the skin to create new cells quickly. It is not caused
                                            by
                                            bacteria, viruses, or other infectious agents.</p>
                                        <p>Psoriasis is inherited but not contagious. Stress, drugs, infections, and skin
                                            traumas
                                            may
                                            cause flare-ups or increase symptoms. Psoriasis sufferers should practice proper
                                            hygiene
                                            and
                                            prevent skin traumas and infections that might worsen their disease.</p>
                                        <p>Understanding that psoriasis is not communicable helps reduce stigma and
                                            misinformation.
                                            It
                                            is a chronic autoimmune illness that needs expert management and assistance to treat
                                            and
                                            control.</p>
                                        <h4><strong>Is coconut oil good for psoriasis?</strong></h4>
                                        <p>Coconut oil's moisturizing and anti-inflammatory qualities make it a common psoriasis
                                            treatment. Coconut oil may help certain psoriasis sufferers. Coconut oil may help
                                            psoriasis
                                            in these ways:</p>
                                        <p>Moisturizing Effects: Scalp psoriasis causes dry, flaky skin, therefore keeping it
                                            moisturized is crucial. Coconut oil moisturizes and reduces psoriasis-related
                                            dryness,
                                            itching, and scaling.</p>
                                        <p>Coconut oil includes anti-inflammatory chemicals such as lauric acid. Coconut oil on
                                            psoriasis areas may relieve redness, edema, and inflammation.</p>
                                        <p>Coconut oil's fatty acids enhance the skin's barrier. This protects psoriasis
                                            sufferers
                                            from
                                            external allergens and moisture loss.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'rheumatoid-arthritis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Rheumatoid Arthritis: Joint Pain, Signs, & Treatment </h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Rheumatoid arthritis is a chronic inflammatory condition that affects millions of
                                    people
                                    worldwide. It can cause joint pain, stiffness, swelling, and in severe cases,
                                    disability.
                                    Living with rheumatoid arthritis can be challenging, as it can affect not only
                                    physical
                                    health but also mental and emotional well-being. An&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic
                                        doctor online consultation</a> would be a saviour for RA.</p>
                                <p>Here, we will explore various aspects of rheumatoid arthritis, including its causes,
                                    RA
                                    symptoms,&nbsp;<a href="https://www.draxico.com/appointment">RA treatment in
                                        Ayurveda</a>,
                                    and tips for managing the condition. We hope to provide helpful insights and support
                                    for
                                    those living with rheumatoid arthritis and their loved ones.</p>
                                <h3><strong>What is Rheumatoid Arthritis?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/rheumatoid-arthritis/Image.webp"
                                    >
                                </div>
                                <p>Rheumatoid arthritis is a chronic autoimmune disorder that affects the joints,
                                    causing
                                    inflammation, pain, and stiffness, and potentially leading to joint deformities and
                                    loss
                                    of
                                    function. The immune system mistakenly attacks the synovial membrane that lines the
                                    joints,
                                    resulting in inflammation and damage to the cartilage, bones, tendons, and
                                    ligaments.&nbsp;</p>
                                <p>It commonly affects the hands, wrists, feet, ankles, and knees, and can also affect
                                    other
                                    organs such as the lungs, heart, and eyes. It is a progressive disease that can have
                                    a
                                    significant impact on the quality of life of those affected.&nbsp;<a
                                        href="https://www.draxico.com/diseases/arthritis">Joint pain</a> and swelling
                                    can be
                                    seen as the first signs of rheumatoid arthritis.</p><h4><strong>Who can get affected
                                from
                                Rheumatoid Arthritis?</strong></h4>
                                <p>Rheumatoid Arthritis can affect people of all ages, genders, and ethnicities.
                                    However, it
                                    is
                                    more common in women than men and is usually diagnosed between the ages of 30 and
                                    60. It
                                    is
                                    also more prevalent in those who have a family history of the disease, as well as
                                    those
                                    who
                                    smoke or have a history of certain infections.</p>
                                <h3><strong>What are the main factors that cause Rheumatoid Arthritis and its
                                    Symptoms?</strong>
                                </h3>
                                <p>The exact cause of Rheumatoid Arthritis (RA) is unknown, but it is believed to be a
                                    result of
                                    a combination of genetic, environmental, and lifestyle factors. Some of the known
                                    risk
                                    factors for RA include family history, gender (women are more likely to be
                                    affected),
                                    age,
                                    smoking, obesity, and exposure to certain viruses and bacteria.</p>
                                <h3><strong>Here are the early signs of rheumatoid arthritis listed in points:</strong>
                                </h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/rheumatoid-arthritis/Image-2.webp"
                                    >
                                </div>
                                <ul>
                                    <li>Joint pain, swelling, stiffness, and tenderness.</li>
                                    <li>Joint deformity or dislocation.</li>
                                    <li>Fatigue, weakness, and loss of energy.</li>
                                    <li>Loss of appetite and weight loss.</li>
                                    <li>Fever and inflammation.</li>
                                    <li>Rheumatoid nodules under the skin.</li>
                                    <li>Raynaud's phenomenon (fingers turning white or blue in cold temperatures).</li>
                                    <li>Dry eyes and mouth.</li>
                                    <li><a href="https://www.draxico.com/diseases/chest-pain">Chest pain</a> and
                                        difficulty
                                        breathing (in severe cases).
                                    </li>
                                    <li>Anaemia (low red blood cell count).</li>
                                </ul>
                                <p>It's important to note that not all people with Rheumatoid Arthritis will experience
                                    all
                                    of
                                    these symptoms, and the severity and frequency of symptoms can vary from person to
                                    person.
                                    And, if you are facing these issues you can&nbsp;<a
                                        href="https://www.draxico.com/appointment">take free consultation with an
                                        ayurvedic
                                        doctor</a>, or can take a&nbsp;<a
                                        href="https://www.draxico.com/self-assessment/joints-assessment">free self
                                        assessment
                                        test for rheumatoid arthritis</a>.</p><h4><strong>Rheumatoid Arthritis affects
                                which
                                part of the body?</strong></h4>
                                <p>Rheumatoid Arthritis can affect multiple parts of the body including the joints,
                                    muscles,
                                    and
                                    bones. It most commonly affects the joints of the hands, wrists, feet, and ankles.
                                    However,
                                    it can also affect other organs such as the heart, lungs, and eyes in more severe
                                    cases.</p>
                                <h4><strong>Rheumatoid Arthritis is Age-Dependent?</strong></h4>
                                <p>Rheumatoid Arthritis can affect people of all ages, including children and young
                                    adults.
                                    However, it is most commonly diagnosed in individuals between the ages of 40 and
                                    60.</p>
                                <h3><strong>What are the risk factors for Rheumatoid Arthritis?</strong></h3>
                                <p>There are several risk factors associated with Rheumatoid Arthritis, including:</p>
                                <ul>
                                    <li><strong>Gender:</strong> Women are more likely to develop Rheumatoid Arthritis
                                        than
                                        men.
                                    </li>
                                    <li><strong>Age:</strong> Although Rheumatoid Arthritis can affect people of all
                                        ages,
                                        it
                                        most commonly begins in middle age.
                                    </li>
                                    <li><strong>Genetics:</strong> Having a family history of Rheumatoid Arthritis
                                        increases
                                        your risk of developing the condition.
                                    </li>
                                    <li><strong>Environmental factors:</strong> Exposure to certain environmental
                                        factors,
                                        such
                                        as <a
                                            href="https://www.cdc.gov/globalhealth/infographics/tobacco/tobacco-lifecycle.html#:~:text=In%20a%20single%20year%2C%20cigarette,toxins%2C%20and%20greenhouse%20gases%20globally."
                                            rel="nofollow">smoking and pollution</a>, can increase the risk of
                                        developing
                                        Rheumatoid Arthritis.
                                    </li>
                                    <li><strong>Obesity:</strong> Being overweight or obese can increase the risk of
                                        developing
                                        Rheumatoid Arthritis.&nbsp;<a href="https://www.draxico.com/bmi-calculator">Take
                                            a
                                            free
                                            BMI test</a> to know if you are obese or not?
                                    </li>
                                    <li><strong>Infections:</strong> Certain infections can trigger Rheumatoid Arthritis
                                        in
                                        genetically susceptible people.
                                    </li>
                                    <li><strong>Hormonal changes:</strong> Changes in hormonal levels can also
                                        contribute to
                                        the
                                        development of Rheumatoid Arthritis.
                                    </li>
                                </ul>
                                <h4><strong>When to see a doctor and how it is diagnosed?</strong></h4>
                                <p>If you experience any persistent joint pain, stiffness, and swelling that lasts for
                                    several
                                    weeks, you should see a doctor. A rheumatologist is an expert in identifying and
                                    treating
                                    rheumatic disorders like&nbsp;<a
                                        href="https://www.draxico.com/diseases/arthritis">arthritis</a>.</p>
                                <p>To diagnose rheumatoid arthritis, a rheumatologist will perform a physical exam and
                                    may
                                    order
                                    blood tests, imaging tests, or joint fluid analysis to check for inflammation, joint
                                    damage,
                                    and the presence of antibodies associated with rheumatoid arthritis. The doctor may
                                    also
                                    consider your medical history, symptoms, and family history to make a diagnosis.</p>
                                <h3><strong>Ayurvedic Say on Rheumatoid Arthritis</strong></h3>
                                <p>In Ayurveda, Rheumatoid Arthritis is known as "Amavata." According to Ayurveda,
                                    Amavata
                                    is
                                    caused by an accumulation of toxins in the body, leading to an imbalance in the
                                    doshas,
                                    mainly Vata and Kapha.</p>
                                <p>The accumulation of toxins is known as "Ama" in Ayurveda, which leads to inflammation
                                    and
                                    pain in the joints.&nbsp;<a href="https://www.draxico.com/">Ayurvedic treatment for
                                        Rheumatoid Arthritis</a> involves detoxification of the body and restoring the
                                    balance
                                    of the doshas through diet, lifestyle changes, and herbal remedies.</p>
                                <p>Ayurvedic treatment for Amavata also involves following a specific diet and
                                    lifestyle.
                                    The
                                    diet should include warm, light, and easily digestible foods while avoiding cold,
                                    heavy,
                                    and
                                    hard-to-digest foods. Lifestyle changes such as regular exercise, stress reduction,
                                    and
                                    proper sleep are also important for managing Amavata.</p><h4><strong>Ayurvedic
                                Remedies
                                for
                                Rheumatoid Arthritis</strong></h4>
                                <p>Various Ayurvedic remedies can be used for the management of rheumatoid arthritis.
                                    Here
                                    are
                                    some of them:</p>
                                <ol>
                                    <li><a
                                        href="https://www.draxico.com/products/triphaladi-guggulu"><strong>Triphala</strong></a><strong>:</strong>
                                        A combination of three fruits - Amalaki, Haritaki, and Bibhitaki, Triphala helps
                                        in
                                        reducing inflammation and pain.
                                    </li>
                                    <li><a
                                        href="https://www.draxico.com/products/ashwagandha-rishta"><strong>Ashwagandha</strong></a><strong>:</strong>
                                        This herb is known for its anti-inflammatory properties and can help in reducing
                                        joint
                                        pain and stiffness.
                                    </li>
                                    <li><strong>Ginger:</strong> Ginger contains compounds that have anti-inflammatory
                                        properties, which can help in reducing pain and inflammation in the joints.
                                    </li>
                                    <li><strong>Turmeric:</strong> Curcumin, the active ingredient in turmeric, has
                                        strong
                                        anti-inflammatory properties that can help in reducing joint pain and
                                        inflammation.
                                    </li>
                                    <li><strong>Guggulu</strong>: Guggulu is a resin extract from the Commiphora mukul
                                        tree.
                                        It
                                        has anti-inflammatory properties and is effective in reducing joint pain and
                                        swelling.
                                    </li>
                                    <li><strong>Shallaki: Shallaki</strong>, also known as Boswellia serrata, is an
                                        Ayurvedic
                                        herb that has been traditionally used to treat joint pain and inflammation.
                                    </li>
                                    <li><a
                                        href="http://draxico.com/panchakarma"><strong>Panchakarma</strong></a><strong>:</strong>
                                        <a href="https://www.draxico.com/panchakarma-treatment/">Panchakarma</a> is an
                                        Ayurvedic
                                        detoxification procedure that involves massage, herbal
                                        steam
                                        baths, and other treatments. It can help in reducing inflammation and improve
                                        joint
                                        mobility.
                                    </li>
                                </ol>
                                <h4><strong>What to eat and not eat</strong></h4><h4><strong>What to eat:</strong></h4>
                                <ol>
                                    <li>Warm, cooked, and easily digestible foods such as soups, stews, and cooked
                                        vegetables
                                    </li>
                                    <li>Spices such as ginger, turmeric, cumin, coriander, and fennel</li>
                                    <li>Whole grains such as quinoa, millet, and brown rice</li>
                                    <li>Fresh fruits and vegetables</li>
                                    <li>Lean protein sources such as lentils, beans, and fish</li>
                                    <li>Herbal teas such as ginger and turmeric tea</li>
                                </ol>
                                <h4><strong>What to avoid:</strong></h4>
                                <ol>
                                    <li>Cold and raw foods such as salads and raw vegetables</li>
                                    <li>Fried and processed foods</li>
                                    <li>Dairy products, especially cheese</li>
                                    <li>Nightshade vegetables such as tomatoes, eggplants, and peppers</li>
                                    <li>Red meat and fatty meats</li>
                                    <li>Sugary and sweetened foods and beverages</li>
                                </ol>
                                <h4><strong>Recommended Ayurvedic Herbs and Medication for Rheumatoid Arthritis</strong>
                                </h4>
                                <p>Here are some best rheumatoid arthritis ayurvedic medicines:</p><h4><strong>Single
                                Herbs:</strong></h4>
                                <ul>
                                    <li>Guggulu (Commiphora mukul)</li>
                                    <li>Shallaki (Boswellia serrata)</li>
                                    <li>Turmeric (Curcuma longa)</li>
                                    <li>Ashwagandha (Withania somnifera)</li>
                                    <li>Ginger (Zingiber officinale)</li>
                                    <li>Triphala (A combination of three fruits: Amla, Haritaki, and Bibhitaki)</li>
                                </ul>
                                <h4><strong>Formulations:</strong></h4>
                                <ul>
                                    <li><a href="https://www.draxico.com/products/mahayograj-guggulu">Mahayograj
                                        Guggulu</a>
                                    </li>
                                    <li>Trayodashang Guggulu</li>
                                    <li>Yograj Guggulu</li>
                                    <li><a href="https://www.draxico.com/products/sudh-ashwagandha-capsule">Ashwagandha
                                        capsules</a> or churna
                                    </li>
                                    <li>Dashamoola Kwath</li>
                                    <li><a href="https://www.draxico.com/products/punarnavadi-mandoor">Punarnava
                                        Mandur</a>
                                    </li>
                                </ul>
                                <p>It is important to note that these herbs and formulations should only be taken under
                                    the
                                    guidance of a qualified practitioner and should not be used as a substitute for
                                    rheumatoid
                                    arthritis ayurvedic treatment.</p><h4><strong>A Short Note from Dr. Axico</strong>
                            </h4>
                                <p>If you have rheumatoid arthritis, you could feel that your level of pain and
                                    weariness is
                                    constantly fluctuating. Dr. Axico, an online Ayurvedic doctor online consultation
                                    will
                                    evaluate your symptoms and suggest the best course of action for you. You can buy
                                    Ayurvedic
                                    medicine online or offline. Most people can control their rheumatoid arthritis and
                                    continue
                                    with their favorite pastimes.</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>Does
                                            rheumatoid
                                            arthritis cause fatigue?</strong></h4>
                                        <p>Yes, fatigue is a common symptom of rheumatoid arthritis. The inflammation and pain
                                            caused by
                                            the condition can lead to feelings of exhaustion and weakness. Additionally, the
                                            body's
                                            immune system is working in overdrive to fight the disease, which can also
                                            contribute to
                                            fatigue. Individuals with rheumatoid arthritis need to manage their symptoms and
                                            find
                                            ways
                                            to conserve their energy throughout the day to avoid excessive fatigue.</p><h4>
                                        <strong>Is
                                            rheumatoid arthritis genetic?</strong></h4>
                                        <p>While the exact cause of rheumatoid arthritis (RA) is unknown, research suggests that
                                            genetics may play a role. It has been found that having a family member with RA
                                            increases
                                            the risk of developing the disease.&nbsp;</p>
                                        <p>However, having a genetic predisposition to RA does not necessarily mean that one
                                            will
                                            develop the condition. Environmental factors and lifestyle choices can also
                                            contribute
                                            to
                                            the development of RA. So, while genetics may be a risk factor, it is not the sole
                                            cause
                                            of
                                            RA.</p>
                                        <h4><strong>What causes rheumatoid arthritis in females?</strong></h4>
                                        <p>The exact cause of rheumatoid arthritis (RA) in females is unknown. However, studies
                                            suggest
                                            that hormonal factors may play a role. Women are more likely to develop RA than men,
                                            and
                                            the
                                            disease often begins or worsens during hormonal changes such as menopause.&nbsp;</p>
                                        <p>Some research suggests that estrogen, a female hormone, may contribute to
                                            inflammation
                                            and
                                            joint damage in RA. Additionally, certain genes that are associated with RA risk are
                                            more
                                            common in women than in men. Other risk factors for RA in women include smoking,
                                            obesity,
                                            and a family history of the disease.</p>
                                        <h4><strong>Ayurvedic home remedies for Rheumatoid Arthritis</strong></h4>
                                        <ul>
                                            <li>Massage the affected joint with warm sesame oil or mustard oil mixed with
                                                camphor or
                                                garlic oil.
                                            </li>
                                            <li>Consuming ginger tea or ginger juice mixed with honey may help reduce
                                                inflammation.
                                            </li>
                                            <li>Applying a paste made of turmeric and water to the affected area may also help
                                                reduce
                                                inflammation.
                                            </li>
                                            <li>Drinking warm water with lemon juice and honey may help reduce joint pain and
                                                inflammation.
                                            </li>
                                            <li>Consuming foods that have anti-inflammatory properties such as green leafy
                                                vegetables,
                                                fruits, nuts, and seeds may help alleviate symptoms.
                                            </li>
                                        </ul>
                                        <h4><strong>Can Rheumatoid Arthritis be treated without operation?</strong>
                                        </h4>
                                        <p>Yes, Rheumatoid Arthritis can be treated without surgery. Ayurveda offers a variety
                                            of
                                            natural and holistic treatments for Rheumatoid Arthritis. These include herbal
                                            remedies,
                                            dietary changes, lifestyle modifications, and various therapies such as massage,
                                            yoga,
                                            and
                                            meditation.&nbsp;</p>
                                        <p>Ayurvedic treatments aim to reduce inflammation, improve joint mobility, strengthen
                                            the
                                            immune system, and promote overall wellness. Ayurveda offers a non-invasive and
                                            natural
                                            approach to managing Rheumatoid Arthritis symptoms and improving quality of
                                            life.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'sciatica'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Sciatica: Lower Back Pain, Signs, & Treatment | Dr Axico
                                </h4>
                            </div>
                            <div class="card-body text-left">
                                <p><strong>Introduction</strong></p>
                                <p>Pain that starts in the lower back and travels down the leg is one of the symptoms of
                                    sciatica. The longest nerve in the body, the sciatic nerve, becomes compressed or
                                    inflamed, which causes it to manifest. A herniated disc in the spine, where the soft
                                    inner core of a spinal disc bulges and places pressure on the sciatic nerve, is the
                                    most
                                    frequent cause of sciatica. Sciatica can be cured effectively with Ayurveda, book
                                    your
                                    Ayurvedic doctor online consultation now and get rid of such kinds of
                                    disorders.&nbsp;</p>
                                <p>Sharp or shooting pain, tingling, numbness, and weakness in the leg or foot can all
                                    be
                                    indications of this. Usually, only one side of the body is affected by the pain.
                                    Spinal
                                    stenosis, degenerative disc degeneration, and muscle spasms are additional causes of
                                    sciatica. Painkillers, physical therapy, exercises, cold or hot therapy, and, in
                                    some
                                    circumstances, surgery are all possible treatments for sciatica. To correctly
                                    diagnose
                                    and treat sciatica, it is crucial to seek medical advice.</p>
                                <p><strong>What is Sciatica?</strong></p>
                                <p>Sciatica occurs when one or more of the nerves that go from your lower back down to
                                    your
                                    legs are irritated, inflamed, pinched, or compressed. Sciatica is often not a
                                    serious or
                                    hazardous ailment, and the majority of sufferers recover on their own over time and
                                    with
                                    self-care measures. Surgery, however, can be required in severe situations.</p>
                                <p><strong>What is the nerve root and pain area of Sciatica?</strong></p>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/sciatica/Image-1.webp"
                                    >
                                </div>
                                <p>Sciatica often develops when the nerve roots that make up the sciatic nerve are
                                    compressed or irritated. Several nerve roots that leave the spinal cord in the lower
                                    back come together to form the sciatic nerve. The intervertebral foramina, which are
                                    tiny openings on either side of the vertebrae, are where these nerve roots leave the
                                    spinal column.</p>
                                <p>L4 (fourth lumbar nerve), L5 (<a href="https://en.wikipedia.org/wiki/Lumbar_nerves"
                                                                    rel="nofollow">fifth lumbar nerve</a>), and S1
                                    (first
                                    sacral
                                    nerve) are the
                                    nerve roots that are most frequently impacted in sciatica. Sciatica pain symptoms
                                    might
                                    emerge as a result of compression or irritation of these nerve roots.</p>
                                <p>The sciatic nerve travels from the lower back through the buttocks and down the back
                                    of
                                    each leg, and the pain connected to it often follows this path. Depending on whether
                                    individual nerve roots are injured, the pain may radiate to the foot, calf, or
                                    thigh.
                                    The degree of the discomfort might vary, from a slight aching to a strong, shooting
                                    sensation. Other sciatica pain symptoms including numbness, tingling, or muscle
                                    weakness
                                    in the affected leg or foot could also be present.</p>
                                <p>It's crucial to remember that depending on the position and intensity of the nerve
                                    compression or irritation, a person's specific sciatic nerve pain location and
                                    symptom
                                    pattern may differ. Consult a healthcare provider for an accurate diagnosis and the
                                    best
                                    course of action if you think you may have sciatica. They can assess your symptoms,
                                    perform a comprehensive physical exam, and identify the particular nerve roots
                                    involved
                                    to inform focused treatment plans.</p>
                                <p><strong>What are the main factors that cause Sciatica?</strong></p>
                                <p>Sciatica is commonly caused by the compression or irritation of the sciatic nerve or
                                    its
                                    nerve roots. Several factors can contribute to the development of sciatica,
                                    including:</p>
                                <ol>
                                    <li><strong>Herniated Disc:</strong> A herniated or slipped disc occurs when the
                                        soft
                                        inner core of a spinal disc protrudes through the outer layer and presses
                                        against
                                        the nerve roots, including those of the sciatic nerve. This can result in nerve
                                        compression and the onset of sciatica symptoms.
                                    </li>
                                    <li><strong>Spinal Stenosis:</strong> Spinal stenosis refers to the narrowing of the
                                        spinal canal, which can exert pressure on the spinal cord and nerve roots. When
                                        the
                                        sciatic nerve or its nerve roots are affected by spinal stenosis, it can lead to
                                        the
                                        development of sciatica.
                                    </li>
                                    <li><strong>Degenerative Disc Disease:</strong> With age, the spinal discs may
                                        undergo
                                        degeneration, becoming less flexible and losing their ability to cushion the
                                        vertebrae. This can lead to disc bulges or osteoarthritis, causing compression
                                        of
                                        the sciatic nerve and resulting in sciatica.
                                    </li>
                                    <li><strong>Spondylolisthesis:</strong> <a
                                        href="https://orthoinfo.aaos.org/en/diseases--conditions/spondylolysis-and-spondylolisthesis"
                                        rel="nofollow">Spondylolisthesis</a> occurs when a vertebra slips
                                        forward over the vertebra below it. This misalignment can cause the narrowing of
                                        the
                                        spinal canal and compression of the nerve roots, including those of the sciatic
                                        nerve.
                                    </li>
                                    <li><strong>Piriformis Syndrome:</strong> The piriformis muscle is located in the
                                        buttocks, and the sciatic nerve runs beneath it. In some cases, the piriformis
                                        muscle can become tight or spasmodic, irritating or compressing the sciatic
                                        nerve
                                        and leading to symptoms similar to sciatica.
                                    </li>
                                    <li><strong>Trauma or Injury:</strong> Injury or trauma to the lower back, such as
                                        from
                                        a fall or accident, can result in damage to the spinal structures or cause disc
                                        herniation, leading to sciatica.
                                    </li>
                                    <li><strong>Lifestyle Factors:</strong> Factors such as poor posture, sedentary
                                        lifestyle, obesity, and improper lifting techniques can contribute to the
                                        development of sciatica by placing excessive strain on the spine and its
                                        supporting
                                        structures.
                                    </li>
                                </ol>
                                <p><strong>Sciatica Symptoms?</strong></p>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/sciatica/Image-2.webp"
                                    >
                                </div>
                                <p>Sciatica is characterized by a range of symptoms that typically affect one side of
                                    the
                                    body. The most common symptoms of sciatica include:</p>
                                <ol>
                                    <li><strong>Radiating Pain:&nbsp;</strong>The hallmark symptom of sciatica is pain
                                        that
                                        radiates along the path of the sciatic nerve. This pain often starts in the
                                        lower
                                        back or buttock area and then travels down the back of the thigh, calf, and
                                        sometimes even into the foot. The pain may vary in intensity, from a mild ache
                                        to a
                                        sharp, shooting sensation.
                                    </li>
                                    <li><strong>Lower Back Pain:</strong> Many individuals with sciatica experience
                                        lower
                                        back pain, which can be localized or more widespread. This pain may be dull or
                                        throbbing.
                                    </li>
                                    <li><strong>Numbness and Tingling</strong>: Along with pain, sciatica can cause
                                        numbness
                                        or a tingling sensation in the affected leg or foot. This sensation is often
                                        described as pins and needles or a "burning" feeling.
                                    </li>
                                    <li><strong>Muscle Weakness:</strong> Weakness in the leg or foot is another
                                        possible
                                        symptom of sciatica. It may affect the ability to lift the foot, causing
                                        difficulty
                                        with walking or performing certain movements.
                                    </li>
                                    <li><strong>Sensory Changes:</strong> Some individuals with sciatica may experience
                                        changes in sensation, such as a loss of feeling or reduced sensitivity to touch
                                        in
                                        the affected leg or foot.
                                    </li>
                                    <li><strong>Worsening Symptoms:</strong> Sciatica symptoms may worsen with certain
                                        activities that increase pressure on the sciatic nerve, such as prolonged
                                        sitting,
                                        bending forward, or coughing/sneezing.&nbsp;
                                    </li>
                                </ol>
                                <p><strong>Is Sciatica Age-Dependent?</strong></p>
                                <p>Sciatica can affect people of all ages, though it might be more common in specific
                                    age
                                    brackets due to certain causes. Age alone does not determine whether sciatica will
                                    develop, although specific changes to the spine that come with aging can make
                                    sciatica
                                    symptoms more likely to occur. These modifications include:</p>
                                <p>Degenerative Disc Disease: As we age, the water content in our spinal discs
                                    decreases,
                                    making them less flexible and more vulnerable to degeneration. Sciatica symptoms can
                                    be
                                    caused by compression of the sciatic nerve, which can arise from disc bulges,
                                    herniations, or spinal stenosis.</p>
                                <p>Osteoarthritis, a degenerative joint disease, is a typical complication of becoming
                                    older. When it affects the spinal joints, it might result in bone spur growth or
                                    spinal
                                    canal narrowing, which can contribute to sciatica.</p>
                                <p>Age-Related Disc Changes: Over time, the wear and tear on the spine's discs can cause
                                    them to lose height, become less cushiony, and lose structural integrity. These
                                    modifications may be a factor in the emergence of sciatica symptoms.</p>
                                <p>It's crucial to remember that younger people can also get sciatica owing to reasons
                                    including accidents, trauma, or genetic susceptibility. Age-related changes in the
                                    spine
                                    might raise the risk of sciatica.</p>
                                <p>It's important to note that the underlying causes of sciatica, such as spinal
                                    stenosis or
                                    a herniated disc, can happen at any age. Age, therefore, does not determine sciatica
                                    alone; however, it might be a contributing factor. Regardless of your age, it is
                                    advised
                                    to seek medical advice if you have symptoms that could be related to sciatica so
                                    that a
                                    proper diagnosis and course of treatment can be determined.</p>
                                <p><strong>What are the risk factors for Sciatica?</strong></p>
                                <p>Several factors can increase the risk of developing sciatica. These risk factors
                                    include:</p>
                                <ol>
                                    <li><strong>Age:</strong> As mentioned earlier, age-related changes in the spine,
                                        such
                                        as degenerative disc disease and spinal arthritis, can contribute to the
                                        development
                                        of sciatica. The risk of sciatica tends to increase with age.
                                    </li>
                                    <li><strong>Occupation and Lifestyle:</strong> Certain occupations and activities
                                        that
                                        involve repetitive movements, heavy lifting, or prolonged sitting can put added
                                        stress on the spine and increase the risk of developing sciatica. Additionally,
                                        a
                                        sedentary lifestyle, lack of regular exercise, and poor posture can also
                                        contribute
                                        to the risk.
                                    </li>
                                    <li><strong>Obesity:</strong> Excess weight puts additional strain on the spine,
                                        increasing the risk of disc herniation and sciatica. Obesity is associated with
                                        a
                                        higher incidence of sciatica.
                                    </li>
                                    <li><strong>Genetics:</strong> Some studies suggest that there may be a genetic
                                        component to the development of sciatica. Individuals with a family history of
                                        sciatica or certain spinal conditions may have an increased risk.
                                    </li>
                                    <li><strong>Occupation and Environment:</strong> Jobs that require heavy lifting,
                                        twisting, or prolonged sitting without proper back support can increase the risk
                                        of
                                        developing sciatica. Exposure to vibrations from machinery or vehicles can also
                                        contribute to spinal changes that lead to sciatica.
                                    </li>
                                    <li><strong>Diabetes:</strong> People with diabetes have a higher risk of nerve
                                        damage
                                        (neuropathy), which can increase the likelihood of developing sciatica.
                                    </li>
                                    <li><strong>Smoking:</strong> Smoking has been linked to increased disc degeneration
                                        and
                                        reduced blood flow to spinal tissues, which can contribute to the development of
                                        sciatica.
                                    </li>
                                </ol>
                                <p><strong>Can sciatica cause my leg and/or ankle to swell?</strong></p>
                                <p>Sciatica rarely swells legs or ankles. Sciatica causes lower back, buttock, and leg
                                    discomfort, numbness, tingling, and weakness. Sciatica rarely causes leg or ankle
                                    swelling.</p>
                                <p>However, sciatica-related diseases may induce leg or ankle swelling. Sciatica caused
                                    by a
                                    herniated disc or spinal stenosis may compress surrounding blood vessels or disrupt
                                    circulation, causing leg or ankle edoema. Sciatica can indirectly cause leg or ankle
                                    swelling if it limits mobility or walking patterns.</p>
                                <p><strong>When to see a doctor and how is it diagnosed?</strong></p>
                                <p>If you have severe sciatica, see a doctor. If your symptoms develop or you have
                                    trouble
                                    controlling your bowels or bladder, visit a doctor immediately.</p>
                                <p>Doctors usually do a comprehensive exam to diagnose sciatica. Sciatica is frequently
                                    diagnosed by:</p>
                                <p>Medical History: The doctor will ask about your symptoms, including the location,
                                    duration, and degree of pain and any aggravating or mitigating variables. They will
                                    ask
                                    about your medical history, injuries, and risk factors.</p>
                                <p>Physical Examination: The doctor will test your reflexes, range of motion, and muscle
                                    strength. They will also execute specialized maneuvers to assess nerve-related
                                    complaints and afflicted locations.</p>
                                <p>Diagnostic Tests: Medical history and physical examination usually diagnose sciatica.
                                    However, more tests may be performed to confirm the diagnosis or determine the
                                    reason.
                                    Tests may include:</p>
                                <p>Herniated discs, spinal stenosis, and bone spurs can be detected by X-rays, MRIs, or
                                    CT
                                    scans.</p>
                                <p>Electromyography (EMG) or nerve conduction investigations can examine nerve and
                                    muscle
                                    function to locate and assess nerve compression or injury.</p>
                                <p>These tests help the doctor diagnose and treat sciatica.</p>
                                <p><strong>Is radiculopathy the same as sciatica?</strong></p>
                                <p>No, radiculopathy and sciatica are not the same, although they are related
                                    conditions.
                                    Let's understand the difference between the two:</p>
                                <p>Radiculopathy: Radiculopathy refers to a condition where the nerve roots that exit
                                    the
                                    spinal cord become compressed, irritated, or inflamed. This can occur due to various
                                    factors, such as a herniated disc, spinal stenosis, or degenerative changes in the
                                    spine. Radiculopathy can affect any nerve root in the spine, resulting in symptoms
                                    such
                                    as pain, numbness, tingling, and weakness that radiate along the pathway of the
                                    affected
                                    nerve.</p>
                                <p>Sciatica: Sciatica is a specific type of radiculopathy that affects the sciatic
                                    nerve,
                                    which is the largest nerve in the body. The sciatic nerve originates from nerve
                                    roots in
                                    the lower back (lumbar region) and extends down the back of each leg. When the
                                    sciatic
                                    nerve becomes compressed or irritated, it leads to symptoms known as sciatica. These
                                    symptoms typically include pain that radiates from the lower back through the
                                    buttocks
                                    and down the leg, following the path of the sciatic nerve. Other symptoms may
                                    include
                                    numbness, tingling, and weakness in the leg or foot.</p>
                                <p><strong>Ayurvedic Say on Sciatica?</strong></p>
                                <p>Sciatica is referred to in Ayurveda as "Grudhrasi." According to Ayurvedic
                                    principles, an
                                    imbalance in the Vata dosha, which regulates the movement and operation of the
                                    nervous
                                    system, is the main contributor to sciatica. Vata can cause several symptoms, such
                                    as
                                    sciatica-related pain, inflammation, and nerve compression when it becomes
                                    exacerbated
                                    or unbalanced.</p>
                                <p>Ayurvedic diagnosis of sciatica entails determining the underlying reason,
                                    recognizing
                                    the precise form of the pain, and determining the individual's dosha imbalance. To
                                    identify the exact imbalances at play, the Ayurvedic practitioner will take into
                                    account
                                    elements including the location of the pain, aggravating or alleviating
                                    circumstances,
                                    and related symptoms.</p>
                                <p>According to Ayurveda, the vitiation of Vata dosha is a contributing factor in the
                                    pathophysiology of sciatica. This vitiated Vata causes the area to become dry,
                                    chilly,
                                    and more mobile, which results in discomfort, stiffness, and inflammation. Inflamed
                                    Vata
                                    can build up in the lower back, pelvic, and hip areas, which can irritate or
                                    compress
                                    the sciatic nerve and cause pain to radiate down the leg.</p>
                                <p>The goal of <a href="https://www.draxico.com/panchakarma-treatment">ayurvedic
                                    treatment
                                    for
                                    sciatica</a> is to balance the Vata dosha and lessen the
                                    symptoms that go along with it. This is often accomplished by combining dietary
                                    suggestions, lifestyle changes, herbal treatments, and specialized therapy.</p>
                                <p>A trained practitioner should be consulted for a full evaluation and individualized
                                    sciatica treatment in Ayurveda. To offer appropriate advice and therapies to address
                                    the
                                    underlying reasons and encourage recovery, they will take into account your
                                    particular
                                    constitution, dosha imbalances, and the particulars of your disease.</p>
                                <p><strong>Ayurvedic Remedies for Sciatica?</strong></p>
                                <p>Ayurvedic home treatments for sciatica are available. These remedies may help, but a
                                    competent Ayurvedic practitioner can diagnose and treat your ailment. Sciatica
                                    Ayurvedic
                                    home remedies:</p>
                                <ol>
                                    <li>Ginger Tea: Ginger reduces sciatica pain and inflammation. Boil fresh ginger
                                        slices
                                        for 10-15 minutes to make ginger tea. Strain, add honey, and drink warm. 2-3
                                        times a
                                        day, drink ginger tea.
                                    </li>
                                    <li>Turmeric Milk: Turmeric includes anti-inflammatory and analgesic curcumin. Milk
                                        with
                                        turmeric, black pepper, and honey. Reduce pain and inflammation with turmeric
                                        milk
                                        before night.
                                    </li>
                                    <li>Magnesium-rich Epsom salt baths can relax muscles and relieve discomfort. Soak
                                        in a
                                        warm bath with two cups of Epsom salt for 15-20 minutes. This relieves sciatica
                                        and
                                        relaxes.
                                    </li>
                                    <li>Garlic Oil Massage: Analgesic and anti-inflammatory garlic. Mix warm sesame oil
                                        with
                                        crushed garlic cloves. Apply garlic-infused oil gradually. Rinse after 30
                                        minutes.
                                        Garlic oil massage reduces pain and inflammation.
                                    </li>
                                    <li>Ayurvedic Herbal Poultice: Make a poultice of Nirgundi (Vitex negundo) and
                                        Erandamoola (Castor root). Make a paste with crushed herbs and warm water. Cover
                                        the
                                        damaged area with this paste and a clean towel. After 30 minutes, remove. This
                                        poultice reduces pain and inflammation.
                                    </li>
                                </ol>
                                <p><strong>What to eat and not eat</strong></p>
                                <p><strong>Foods to Eat:</strong>&nbsp;</p>
                                <ul>
                                    <li>Anti-inflammatory Foods: Include foods that have natural anti-inflammatory
                                        properties, such as fatty fish (salmon, mackerel, sardines), walnuts, flaxseeds,
                                        chia seeds, turmeric, ginger, and leafy green vegetables.
                                    </li>
                                    <li>Healthy Fats: Consume healthy fats from sources like avocados, olive oil,
                                        coconut
                                        oil, and nuts and seeds. These fats can help reduce inflammation and support
                                        nerve
                                        health.
                                    </li>
                                    <li>Fruits and Vegetables: Incorporate a variety of colorful fruits and vegetables
                                        into
                                        your diet. They are rich in antioxidants, vitamins, and minerals that support
                                        overall health and reduce inflammation.
                                    </li>
                                    <li>Whole Grains: Opt for whole grains like quinoa, brown rice, oats, and whole
                                        wheat
                                        bread instead of refined grains. Whole grains provide fiber and essential
                                        nutrients.
                                    </li>
                                    <li>Herbal Teas: Drink herbal teas such as chamomile, ginger, and turmeric tea.
                                        These
                                        can have anti-inflammatory and soothing effects.
                                    </li>
                                </ul>
                                <p><strong>Foods to Avoid:</strong></p>
                                <ul>
                                    <li>Processed and Fried Foods: Avoid processed and fried foods as they can
                                        contribute to
                                        inflammation and poor overall health.
                                    </li>
                                    <li>Refined Sugars: Minimize your intake of refined sugars found in candies, sodas,
                                        and
                                        processed snacks as they can promote inflammation.
                                    </li>
                                    <li>High-Fat Dairy Products: Limit high-fat dairy products like whole milk, cheese,
                                        and
                                        butter, as they may contribute to inflammation in some individuals.
                                    </li>
                                    <li>Red Meat: Reduce consumption of red meat, which can be pro-inflammatory. Choose
                                        leaner protein sources like poultry, fish, and plant-based alternatives.
                                    </li>
                                    <li>Artificial Additives: Avoid foods with artificial additives, preservatives, and
                                        artificial sweeteners, as they can have negative effects on overall health.
                                    </li>
                                </ul>
                                <p><strong>Recommended Ayurvedic Herbs and Medication for Sciatica?</strong></p>
                                <p>Best Ayurvedic medicine for sciatica pain that can help in sciatica symptoms.
                                    Sciatica
                                    treatment in Ayurveda includes:</p>
                                <ul>
                                    <li>Shallaki (Boswellia serrata): Indian frankincense is anti-inflammatory and
                                        analgesic. It reduces sciatica pain with Ayurvedic treatment, inflammation, and
                                        mobility. Ayurvedic practitioners recommend swallowing shallaki pills or powder.
                                    </li>
                                    <li>Anti-inflammatory resin extract Guggulu (Commiphora mukul). It reduces sciatica
                                        pain
                                        and inflammation. Guggulu capsules or sciatica ayurvedic tablets can be consumed
                                        as
                                        directed.
                                    </li>
                                    <li>Erandamoola (Castor root): Analgesic and sciatica-relieving. It can be applied
                                        externally as a paste or oil.
                                    </li>
                                    <li>Nirgundi (Vitex negundo): Analgesic and anti-inflammatory. It reduces sciatica
                                        pain
                                        and inflammation. Ayurvedic practitioners recommend nirgundi oil or pills.
                                    </li>
                                    <li>Dashmool (Ten Roots Formulation): Ten analgesic and anti-inflammatory Ayurvedic
                                        roots make up Dashmool. It works as the best Ayurvedic medicine for lower back
                                        pain
                                        relief and promotes recovery. Ayurvedic practitioners can prescribe dashmool
                                        powder
                                        or decoction.
                                    </li>
                                    <li>Mahanarayan Oil: Ayurvedic Mahanarayan oil reduces pain, inflammation, and
                                        movement.
                                        It relieves pain when gently massaged.
                                    </li>
                                </ul>
                                <p><strong>A Short Note from Dr. Axico</strong></p>
                                <p>Your life might be disrupted in a variety of ways by sciatica pain in your back,
                                    butt, or
                                    legs. Fortunately, you have a lot of options for aiding your recovery. You may
                                    frequently handle lesser cases on your own. Ayurveda can frequently be used to
                                    address
                                    more severe symptoms. Although it's not frequently necessary, surgery is an option
                                    if
                                    your symptoms are severe.&nbsp;</p>
                                <p>You may put sciatica behind you with an Ayurvedic doctor online consultation and get
                                    back
                                    to living your life as you choose. Additionally, you can buy Ayurvedic medicines and
                                    healthcare items online from Dr. Axico.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <p><strong>How to cure sciatica permanently</strong></p>
                                        <p>Ayurveda focuses on keeping the body, mind, and spirit in balance to improve health
                                            and
                                            well-being as a whole. Even though it might not cure sciatica permanently in all
                                            cases,
                                            Ayurvedic practices and remedies can help handle the condition and make it less
                                            likely
                                            to come back. Here are a few Ayurvedic methods that may help:</p>
                                        <p>Ayurvedic medicines: Ayurvedic doctors may give you certain plant mixtures or
                                            medicines
                                            based on your body type and the imbalances that are causing your sciatica. Shallaki
                                            (Boswellia serrata), Guggulu (Commiphora mukul), Erandamoola (castor root), and
                                            Nirgundi
                                            (Vitex negundo) are all plants that are often used. These herbs can help lessen
                                            pain,
                                            inflammation, and nerve damage because they have anti-inflammatory and analgesic
                                            properties. It's important to talk to an Ayurvedic practitioner about the right dose
                                            and
                                            how long to use it.</p>
                                        <p>Panchakarma treatment: Panchakarma is an Ayurvedic detoxification and rejuvenation
                                            treatment that aims to remove toxins from the body and bring it back into balance.
                                            Some
                                            Panchakarma treatments, like Abhyanga (herbal oil massage), Basti (medicated enema),
                                            and
                                            Nasya (nasal intake of medicated oils), may be suggested to fix the underlying
                                            imbalances that cause sciatica.</p>
                                        <p>Ayurvedic External treatments: Herbal oils and poultices can be used for lower back
                                            pain
                                            Ayurvedic treatments like Pinda Sweda or Patra Pinda Sweda, which are oil massages
                                            that
                                            focus on one area. These treatments help reduce pain and swelling and help the
                                            affected
                                            area heal.</p>
                                        <p>Dietary Changes: Eating a balanced diet that is right for your dosha can help your
                                            general health and ease sciatica symptoms. In Ayurvedic diet advice, foods that are
                                            warm, newly cooked, and easy to digest are often emphasized. Turmeric, ginger, and
                                            cinnamon are all anti-inflammatory spices that can help you feel better. It's best
                                            to
                                            talk to an Ayurvedic expert to find out what the best diet plan is for your
                                            situation.</p>
                                        <p>Lifestyle and Yoga: Managing sciatica can be helped by adopting a healthy lifestyle
                                            that
                                            includes ways to deal with stress, regular exercise, and yoga. Yoga poses like
                                            Bhujangasana (Cobra Pose), Makarasana (Crocodile Pose), and Ardha Matsyendrasana
                                            (Half
                                            Lord of the Fishes Pose) focus on relaxing and strengthening the back and core
                                            muscles
                                            can help improve flexibility and relieve pain.</p>
                                        <p>It's important to remember that Ayurvedic treatment should be tailored to your unique
                                            condition, body type, and imbalances at the root. It is important to talk to a
                                            qualified
                                            Ayurvedic practitioner to get a correct diagnosis, a personalized treatment plan,
                                            and
                                            advice on how to use herbs, therapies, food, and changes in lifestyle. They can keep
                                            track of your progress and make any changes that are needed to help you heal
                                            faster.</p>
                                        <p><strong>Home remedy to get Immediate relief from sciatica pain?</strong></p>
                                        <p><strong>Ginger Tea:</strong> Ginger reduces sciatica pain and inflammation. Boil
                                            fresh
                                            ginger slices for 10-15 minutes to make ginger tea. Strain, add honey, and drink
                                            warm.
                                            2-3 times a day, drink ginger tea.</p>
                                        <p><strong>Turmeric Milk:</strong> Turmeric includes anti-inflammatory and analgesic
                                            curcumin. Milk with turmeric, black pepper, and honey. Reduce pain and inflammation
                                            with
                                            turmeric milk before night.</p>
                                        <p>Magnesium-rich Epsom salt baths can relax muscles and relieve discomfort. Soak in a
                                            warm
                                            bath with two cups of Epsom salt for 15-20 minutes. This relieves sciatica and
                                            relaxes.</p>
                                        <p><strong>Garlic Oil Massage:</strong> Analgesic and anti-inflammatory garlic. Mix warm
                                            sesame oil with crushed garlic cloves. Apply garlic-infused oil gradually. Rinse
                                            after
                                            30 minutes. Garlic oil massage reduces pain and inflammation.</p>
                                        <p><strong>Ayurvedic Herbal Poultice:</strong> Make a poultice of Nirgundi (Vitex
                                            negundo)
                                            and Erandamoola (Castor root). Make a paste with crushed herbs and warm water. Cover
                                            the
                                            damaged area with this paste and a clean towel. After 30 minutes, remove. This
                                            poultice
                                            reduces pain and inflammation.</p>
                                        <p><strong>Can sciatica occur down both legs?</strong></p>
                                        <p>Yes, sciatica can occur down both legs, although it is more common for it to affect
                                            one
                                            leg. Sciatica refers to the pain that radiates along the path of the sciatic nerve,
                                            which is the longest nerve in the body. This nerve runs from the lower back, through
                                            the
                                            buttocks, down the back of each leg, and branches out into the feet.</p>
                                        <p>When the sciatic nerve or its roots become compressed or irritated, it can lead to
                                            symptoms such as pain, numbness, tingling, or weakness in the leg or foot. In some
                                            cases, the compression or irritation can affect both sides of the body, resulting in
                                            sciatica symptoms down both legs. This can occur due to conditions like spinal
                                            stenosis,
                                            a herniated disc on both sides or a condition affecting the nerve roots of the
                                            sciatic
                                            nerve bilaterally.</p>
                                        <p><strong>Is the weight of pregnancy the reason why so many pregnant women get
                                            sciatica?</strong></p>
                                        <p>Pregnancy weight can worsen sciatica in some women, but it is not the only cause.
                                            Pregnancy-related hypertension and changes in the lower back and pelvis induce
                                            sciatica.</p>
                                        <p>The expanding uterus compresses or irritates the sciatic nerve or roots during
                                            pregnancy.
                                            Hormonal changes like relaxin hormone release can loosen pelvic ligaments and
                                            joints,
                                            causing nerve compression or pain.</p>
                                        <p>Poor posture, muscle imbalances, pre-existing back issues, and the baby's position
                                            can
                                            contribute to sciatica, as does pregnant weight gain.</p>
                                        <p>Pregnant women with sciatica should see their doctor. They can offer pain management,
                                            exercises, and lifestyle changes to reduce discomfort and support a healthy
                                            pregnancy.</p>
                                        <p><strong>How long should I try self-care treatments for my sciatica before seeing a
                                            doctor?</strong></p>
                                        <p>If you are experiencing symptoms of sciatica, it is generally recommended to try
                                            self-care treatments for a few weeks before seeking medical attention. In many
                                            cases,
                                            sciatica symptoms tend to improve with conservative measures and time. However, if
                                            your
                                            symptoms persist, worsen, or are accompanied by severe pain, weakness, or loss of
                                            bladder or bowel control, it is important to consult a healthcare professional
                                            promptly.</p>
                                        <p><strong>What are the risks of spinal surgery?</strong></p>
                                        <ul>
                                            <li><strong>Spinal surgery, like any operation, has risks and problems. Before
                                                spinal
                                                surgery, know these dangers. Spinal surgery risks:</strong></li>
                                            <li>Infection: Surgical sites can get infected. Despite medicines and sterile
                                                surgery,
                                                this can happen.
                                            </li>
                                            <li>Bleeding: Incisions during surgery can cause bleeding. Rarely, severe bleeding
                                                requires a blood transfusion or surgery.
                                            </li>
                                            <li>Nerve Damage: Spinal surgery involves the sensitive spinal cord and surrounding
                                                nerves. Despite safeguards, nerve injury may occur during surgery. Nerve injury
                                                causes pain, weakness, numbness, and other neurological impairments.
                                            </li>
                                            <li>Blood Clots: Immobility during and after surgery increases the risk of deep vein
                                                thrombosis and pulmonary embolism. Early mobilization, compression stockings,
                                                and
                                                blood-thinning medicines can reduce this risk.
                                            </li>
                                            <li>Failed Fusion: Spinal fusion surgery aims to fuse two or more vertebrae. Failed
                                                bone
                                                fusion is possible. This may cause chronic discomfort or subsequent surgery.
                                            </li>
                                            <li>Hardware Complications: Spinal procedures may include screws, plates, or rods to
                                                stabilize the spine. Hardware might loosen, break, or irritate surrounding
                                                tissues.
                                            </li>
                                        </ul>
                                        <p>Anaesthesia concerns include allergic reactions, breathing issues, and drug
                                            responses. To
                                            reduce risks, anaesthesiologists monitor patients during surgery.</p>
                                        <p><strong>How to know if the pain in my hip is a hip issue or sciatica?</strong></p>
                                        <p>Differentiating between hip pain and sciatica can sometimes be challenging because
                                            the
                                            symptoms can overlap. However, some key differences can help you determine the
                                            source of
                                            your pain and sciatica symptoms in hip. Here are some factors to consider:</p>
                                        <ol>
                                            <li>Location of the Pain: Hip pain typically manifests in the groin area, on the
                                                outside
                                                of the hip, or in the buttocks. On the other hand, sciatica pain usually
                                                radiates
                                                from the lower back, through the buttocks, down the back of the thigh, and may
                                                extend into the calf or foot.
                                            </li>
                                            <li>Nature of the Pain: Hip pain is often described as a deep, aching sensation in
                                                the
                                                hip joint, and it may be aggravated by weight-bearing activities, such as
                                                walking or
                                                standing. Sciatica pain, on the other hand, is characterized by a shooting or
                                                electric-like pain that travels along the path of the sciatic nerve.
                                            </li>
                                            <li>Associated Symptoms: Hip issues may be accompanied by stiffness, decreased range
                                                of
                                                motion, and a feeling of instability in the hip joint. Sciatica, in addition to
                                                pain, can cause numbness, tingling, or weakness in the leg or foot.
                                            </li>
                                            <li>Aggravating Factors: Hip pain may worsen with specific movements that involve
                                                the
                                                hip joint, such as getting up from a seated position, walking upstairs, or
                                                crossing
                                                the legs. Sciatica pain can be triggered or worsened by activities that involve
                                                bending, twisting, or prolonged sitting or standing.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'ulcerative-colitis'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Ulcerative Colitis: Causes, Signs and Treatment</h4>
                            </div>
                            <div class="card-body text-left">
                                <p>Ulcerative colitis is a chronic inflammatory bowel disease that affects the large
                                    intestine
                                    or colon. It causes inflammation, swelling, and ulcers in the colon, leading to
                                    symptoms
                                    like abdominal pain, diarrhoea, and rectal bleeding.&nbsp;<a
                                        href="https://www.draxico.com/">Ayurvedic doctor online consultation</a> would
                                    help
                                    you
                                    to fight against these types of disorders.&nbsp;</p>
                                <p>The exact cause of ulcerative colitis is unknown, but it is believed to be an
                                    autoimmune
                                    disorder in which the immune system attacks the lining of the colon. Here, we will
                                    explore
                                    the symptoms, ulcerative colitis causes, and treatment options for ulcerative
                                    colitis.</p>
                                <h3><strong>What is Ulcerative Colitis?</strong></h3>
                                <p>Ulcerative colitis is a&nbsp;<a
                                    href="https://www.draxico.com/diseases/irritable-bowel-syndrome">chronic
                                    inflammatory
                                    bowel
                                    disease</a> that primarily affects the large intestine (colon) and the rectum. It
                                    causes
                                    inflammation, swelling, and ulcers in the lining of the colon, leading to symptoms
                                    such
                                    as&nbsp;<a
                                        href="https://www.draxico.com/diseases/stomach-ache-and-abdominal-pain">abdominal
                                        pain</a>, cramping, diarrhea, rectal bleeding, and urgency to defecate.
                                    Ulcerative
                                    colitis can be debilitating and can significantly affect the quality of life of
                                    those
                                    affected.</p>
                                <h4><strong>What’s the difference between colitis and ulcerative colitis?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/ulcerative-colitis/Image.webp"
                                    >
                                </div>
                                <p>Colitis is a general term used to describe inflammation of the colon. Ulcerative
                                    colitis,
                                    on
                                    the other hand, is a specific type of inflammatory bowel disease that affects the
                                    colon
                                    and
                                    rectum. While both conditions can cause similar symptoms such as diarrhoea,
                                    abdominal
                                    pain,
                                    and bloody stools.</p>
                                <p>Ulcerative colitis typically involves more severe inflammation that is limited to the
                                    innermost lining of the colon and rectum, while colitis can affect other layers of
                                    the
                                    colon
                                    as well. Additionally, colitis can be caused by a variety of factors such as
                                    infection
                                    or
                                    medication, while the exact cause of ulcerative colitis is unknown.</p>
                                <h3><strong>What are the main factors that cause Ulcerative Colitis?</strong></h3>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/ulcerative-colitis/Image-2.webp"
                                    >
                                </div>
                                <p>The exact cause of Ulcerative Colitis is unknown, but it is believed to be caused by
                                    a
                                    combination of genetic, environmental, and immune system factors. Some of the
                                    factors
                                    that
                                    are thought to contribute to the development of Ulcerative Colitis include</p>
                                <ul>
                                    <li><strong>Genetics:</strong> A family history of Ulcerative Colitis increases the
                                        risk
                                        of
                                        developing the disease.&nbsp;
                                    </li>
                                    <li><strong>Immune System:</strong> An abnormal immune response causes inflammation
                                        and
                                        damage to the lining of the colon and rectum.
                                    </li>
                                    <li><strong>Environmental Factors:</strong> Infection, stress, and diet may trigger
                                        Ulcerative Colitis in some individuals.
                                    </li>
                                    <li><strong>Age:</strong> Ulcerative Colitis usually affects people between the ages
                                        of
                                        15
                                        and 35, and those over 60 years old are also at risk.
                                    </li>
                                </ul>
                                <h4><strong>Ulcerative Colitis Symptoms?</strong></h4>
                                <p>The symptoms of ulcerative colitis can vary in severity and may include:</p>
                                <ul>
                                    <li>Abdominal pain and cramping</li>
                                    <li>Diarrhoea, often with blood or pus</li>
                                    <li>Rectal pain and bleeding</li>
                                    <li>The urgency to have a bowel movement</li>
                                    <li>Fatigue and weakness</li>
                                    <li>Loss of appetite and weight loss</li>
                                    <li>Fever</li>
                                    <li><a href="https://www.draxico.com/diseases/arthritis">Joint pain</a> and swelling
                                    </li>
                                    <li>Skin rashes</li>
                                    <li>Eye inflammation</li>
                                </ul>
                                <p>If you are still confused about your condition you can take a&nbsp;<a
                                    href="https://www.draxico.com/self-assessment/general-health-assessment">free self
                                    assessment test for Ulcerative Colitis</a> with Dr Axico.</p>
                                <p>It's important to note that some people with ulcerative colitis may experience mild
                                    symptoms,
                                    while others may have more severe symptoms that affect their daily life.</p>
                                <h4><strong>Is Ulcerative Colitis Age-Dependent?</strong></h4>
                                <p>Ulcerative colitis can affect people of any age, but it most commonly begins between
                                    the
                                    ages
                                    of 15 and 30, or after the age of 60. It can also occur in children.</p>

                                <h3><strong>What are the risk factors for Ulcerative Colitis?</strong></h3>
                                <p>The exact cause of Ulcerative Colitis is unknown, but several factors may increase
                                    the
                                    risk
                                    of developing this condition. These include:</p>
                                <ul>
                                    <li><strong>Genetics:</strong> Individuals with a family history of Ulcerative
                                        Colitis
                                        are
                                        at a higher risk of developing the condition.
                                    </li>
                                    <li><strong>Autoimmune disorders:</strong> Those who have other autoimmune
                                        disorders,
                                        such
                                        as&nbsp;<a href="https://www.draxico.com/diseases/rheumatoid-arthritis">rheumatoid
                                            arthritis</a> or lupus, are at an increased risk.
                                    </li>
                                    <li><strong>Age:</strong> While Ulcerative Colitis can occur at any age, it is most
                                        commonly
                                        diagnosed in individuals between the ages of 15 and 30.
                                    </li>
                                    <li><strong>Ethnicity:</strong> Caucasians and&nbsp;<a
                                        href="https://www.cancer.gov/publications/dictionaries/genetics-dictionary/def/ashkenazi-jews#:~:text=(ASH%2Dkeh%2DNAH%2D,Africa%2C%20and%20the%20Middle%20East."
                                        rel="nofollow">Ashkenazi
                                        Jews</a> have a higher risk of developing Ulcerative Colitis.
                                    </li>
                                    <li><strong>Environmental factors:</strong> Smoking, diet, and exposure to certain
                                        viruses
                                        or bacteria may increase the risk of developing Ulcerative Colitis.
                                    </li>
                                </ul>
                                <p>It's important to note that having one or more of these risk factors does not
                                    necessarily
                                    mean a person will develop Ulcerative Colitis and some individuals with the
                                    condition
                                    have
                                    no known risk factors.</p><h4><strong>What causes ulcerative colitis
                                flare-ups?</strong>
                            </h4>
                                <p>The exact cause of ulcerative colitis flare-ups is not fully understood. However,
                                    several
                                    factors can trigger or exacerbate symptoms, including stress, certain foods,
                                    infections,
                                    changes in UC medication, and hormonal changes.&nbsp;</p>

                                <p>Additionally, people with ulcerative colitis may experience flare-ups due to an
                                    overactive
                                    immune response, which causes the body to attack the intestinal lining, leading to
                                    inflammation and other symptoms. It's essential to work with a healthcare provider
                                    to
                                    identify triggers and develop a management plan to reduce the frequency and severity
                                    of
                                    flare-ups.</p>
                                <h4><strong>What is the pain area of ulcerative colitis?</strong></h4>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/ulcerative-colitis/Image-3.webp"
                                    >
                                </div>
                                <p>The pain area of ulcerative colitis can vary depending on the severity and location
                                    of
                                    inflammation in the&nbsp;<a
                                        href="https://training.seer.cancer.gov/colorectal/anatomy/"
                                        rel="nofollow">colon
                                        and rectum</a>. Ulcerative colitis pain location: The pain is typically felt in
                                    the
                                    lower abdomen, often on the left side, and can be accompanied by cramping, bloating,
                                    and
                                    discomfort.&nbsp;</p>

                                <p>It may also be associated with urgency and frequency of bowel movements, which may
                                    contain
                                    blood or mucus. In severe cases, ulcerative colitis can cause systemic symptoms such
                                    as
                                    fever, fatigue, and weight loss.</p>

                                <h3><strong>When to see a Doctor and how is it diagnosed?</strong></h3>
                                <p>It is recommended to see a doctor if you experience persistent symptoms of ulcerative
                                    colitis, such as abdominal pain, bloody diarrhoea, and weight loss.</p>
                                <p>To diagnose ulcerative colitis, the doctor may perform a physical exam and order
                                    certain
                                    tests, including blood tests, stool tests, colonoscopy, and biopsy. These tests can
                                    help
                                    confirm the diagnosis and determine the severity of the condition. It is important
                                    to
                                    receive an accurate diagnosis and proper medical treatment to manage symptoms and
                                    prevent
                                    complications.</p>

                                <p>If you are confused about what to do, you can&nbsp;<a
                                    href="https://www.draxico.com/appointment">book a free online doctor
                                    consultation</a>
                                    with
                                    Dr Axico.</p>

                                <h3><strong>Ayurvedic Say on Ulcerative Colitis</strong></h3>
                                <p>According to Ayurveda, Ulcerative Colitis is known as "Pittaj Grahani" and is caused
                                    due
                                    to
                                    an imbalance of the Pitta dosha in the body. The accumulation of toxins (ama) in the
                                    intestines due to weak digestion and an unhealthy diet can also aggravate this
                                    condition.</p>
                                <p>Ayurveda emphasizes the importance of maintaining a balance of the three doshas -
                                    Vata,
                                    Pitta, and Kapha - for overall health and well-being. Pitta-pacifying herbs and
                                    dietary
                                    modifications are recommended to manage the symptoms of Ulcerative
                                    Colitis.&nbsp;</p>
                                <p>Ayurvedic treatments for Ulcerative Colitis focus on restoring the digestive fire
                                    (Agni),
                                    eliminating toxins from the body, and reducing inflammation in the intestines.
                                    <a href="https://www.draxico.com/panchakarma-treatment">Panchakarma therapies</a>
                                    such
                                    as <a
                                        href="https://www.draxico.com/panchakarma-treatment/virechana">Virechana</a>
                                    (therapeutic purgation) and Basti (enema therapy) may also
                                    be
                                    recommended.</p>

                                <h3><strong>Ayurvedic Remedies for Ulcerative Colitis</strong></h3>
                                <p>Ayurveda offers several natural remedies for managing ulcerative colitis symptoms.
                                    Some
                                    of
                                    the commonly recommended remedies are:</p>
                                <ul>
                                    <li><strong>Kutaja (Holarrhena antidysenterica):</strong> It is a bitter herb that
                                        has
                                        anti-inflammatory and anti-diarrheal properties. Kutaja can help reduce
                                        inflammation
                                        in
                                        the colon and relieve diarrhea.
                                    </li>
                                    <li><strong>Bilva (Aegle marmelos):</strong> Bilva is known to have a soothing
                                        effect on
                                        the
                                        stomach and can help reduce inflammation. It also has anti-diarrheal properties
                                        and
                                        can
                                        help regulate bowel movements.
                                    </li>
                                    <li><strong>Aloe vera:</strong> Aloe vera has anti-inflammatory properties and can
                                        help
                                        reduce inflammation in the colon. It also has a soothing effect on the stomach
                                        and
                                        can
                                        help reduce UC symptoms such as abdominal pain and diarrhea.
                                    </li>
                                    <li><strong>Triphala:</strong> It is a combination of three fruits - Haritaki,
                                        Amalaki,
                                        and
                                        Bibhitaki. Triphala has anti-inflammatory and antioxidant properties that can
                                        help
                                        reduce inflammation and promote healing in the colon.
                                    </li>
                                    <li><strong>Turmeric (Curcuma longa):</strong> Turmeric is known for its
                                        anti-inflammatory
                                        properties. It can help reduce inflammation in the colon and promote healing.
                                    </li>
                                    <li><strong>Ghee:</strong> Ghee is a type of clarified butter that is known to have
                                        a
                                        soothing effect on the stomach. It can help reduce inflammation and promote
                                        healing
                                        in
                                        the colon.
                                    </li>
                                    <li><strong>Probiotics:</strong> Probiotics are live bacteria that can help maintain
                                        a
                                        healthy gut microbiome. They can help reduce inflammation in the colon and
                                        improve
                                        overall digestive health.
                                    </li>
                                </ul>
                                <h4><strong>What to eat and not eat</strong></h4>
                                <p><strong>Foods to Eat:</strong></p>
                                <ol>
                                    <li>Cooked vegetables such as pumpkin, squash, carrots, sweet potatoes, and
                                        zucchini.
                                    </li>
                                    <li>Ripe fruits such as bananas, papayas, and cooked apples.</li>
                                    <li>Whole grains such as rice, quinoa, and oatmeal.</li>
                                    <li>Lean protein sources such as chicken, turkey, and fish.</li>
                                    <li>Healthy fats such as ghee, coconut oil, and olive oil.</li>
                                    <li>Herbal teas such as ginger tea and chamomile tea.</li>
                                </ol>
                                <p><strong>Foods to Avoid:</strong></p>
                                <ol>
                                    <li>Spicy foods and hot peppers.</li>
                                    <li>Raw vegetables and fruits.</li>
                                    <li>Dairy products such as milk, cheese, and ice cream.</li>
                                    <li>Fried foods and processed foods.</li>
                                    <li>Carbonated drinks and alcohol.</li>
                                    <li>Coffee and other caffeinated drinks.</li>
                                    <li>Red meat.</li>
                                </ol>
                                <h4>&nbsp;<strong>Recommended Ayurvedic Herbs and Medication for Ulcerative
                                    Colitis</strong></h4>
                                <p>&nbsp;Here is the best ulcerative colitis ayurvedic medicine:</p>
                                <ul>
                                    <li>Kutajarishta</li>
                                    <li>Bilwadi Churna</li>
                                    <li>Pippali Churna</li>
                                    <li>Mustakarishta</li>
                                    <li><a href="https://www.draxico.com/products/lodhrasava">Lodhrasava</a></li>
                                    <li>Vatsakadi Churna</li>
                                    <li>Chandanadi Vati</li>
                                    <li>Dadimashtaka Churna</li>
                                    <li><a href="https://www.draxico.com/products/hingwashtaka-churna">Hingvastak
                                        Churna</a>
                                    </li>
                                    <li>Arjuna Ksheerapaka</li>
                                    <li>Kutki</li>
                                    <li>Bilva</li>
                                    <li>Yashtimadhu</li>
                                </ul>
                                <p>It's important to note that these herbs should be taken under the guidance of a
                                    qualified
                                    Ayurvedic doctor or health expert.</p>
                                <h4><strong>A Short Note from Dr.Axico</strong></h4>
                                <p>Collaboration with your medical team is crucial if you have ulcerative colitis. Even
                                    if
                                    you
                                    are symptom-free, take your Ayurvedic medications as directed. Skipping prescribed
                                    prescriptions might cause flare-ups and make the disease more difficult to
                                    control.&nbsp;</p>
                                <p>Following your ulcerative colitis Ayurvedic treatment plan and online interaction
                                    with
                                    your
                                    Ayurvedic doctor is your best bet for managing ulcerative colitis. Additionally, you
                                    can
                                    buy
                                    Ayurvedic medicine online or offline from Dr. Axico.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h4><strong>How often do I need a colonoscopy?</strong>
                                        </h4>
                                        <p>The frequency of colonoscopy depends on several factors, such as age, personal health
                                            history, family history, and the presence of risk factors for colorectal cancer or
                                            other
                                            gastrointestinal conditions. Generally, for individuals at average risk of
                                            colorectal
                                            cancer, screening colonoscopy is recommended every ten years starting at age
                                            50.&nbsp;</p>
                                        <p>However, if a person has a personal or family history of polyps or colorectal cancer,
                                            or
                                            has
                                            inflammatory bowel disease, the frequency and timing of colonoscopy may vary.</p>
                                        <h4><strong>What is the best diet for ulcerative colitis in Ayurveda?</strong>
                                        </h4>
                                        <p>In Ayurveda, the best diet for ulcerative colitis is one that is easy to digest,
                                            nourishing
                                            and promotes healing. It is recommended to eat warm, freshly cooked foods that are
                                            well-cooked and easily digestible, such as cooked vegetables, lentils, and grains.
                                            Avoiding
                                            spicy, oily, and fried foods is also recommended.&nbsp;</p>
                                        <p>Consuming foods that have a cooling effect on the body, such as fresh coconut water,
                                            cucumber, and buttermilk, can also be beneficial. In addition, drinking warm water
                                            with
                                            lemon juice can help to cleanse the digestive system and improve digestion.</p>
                                        <h4><strong>What is pediatric ulcerative colitis, and does it affect a child's
                                            mental or emotional health?</strong></h4>
                                        <p>Pediatric ulcerative colitis is a type of ulcerative colitis that affects children
                                            under
                                            the
                                            age of 18. It has similar symptoms and causes to ulcerative colitis in adults.
                                            However,
                                            it
                                            can have a significant impact on a child's mental and emotional health, as it may
                                            cause
                                            pain, discomfort, and disruptions to daily activities.&nbsp;</p>
                                        <p>Children with pediatric ulcerative colitis may also experience anxiety, depression,
                                            and
                                            social isolation due to their condition. Parents need to monitor their child's
                                            mental
                                            health
                                            and seek professional support, if necessary, in addition to treating their physical
                                            symptoms
                                            of ulcerative colitis.</p>
                                        <h4><strong>What are the symptoms of ulcerative colitis?</strong></h4>
                                        <p>The symptoms of ulcerative colitis can vary from person to person and may range from
                                            mild
                                            to
                                            severe. Some common symptoms include frequent and urgent bowel movements, diarrhea,
                                            rectal
                                            bleeding, abdominal pain and cramping, fatigue, weight loss, and loss of
                                            appetite.&nbsp;</p>
                                        <p>In severe cases, there may be fever, nausea, and dehydration. It is important to seek
                                            medical
                                            attention if you are experiencing any of these symptoms as they can significantly
                                            impact
                                            your quality of life.</p>
                                        <h4><strong>Ayurvedic home remedies for ulcerative colitis</strong></h4>
                                        <p>Here are some Ayurvedic home remedies for ulcerative colitis:</p>
                                        <ul>
                                            <li><strong>Aloe vera:</strong> Take 2 tablespoons of aloe vera gel and mix it in a
                                                glass of
                                                water. Drink it twice a day on an empty stomach.
                                            </li>
                                            <li><strong>Turmeric:</strong> Mix 1 teaspoon of turmeric powder in a glass of warm
                                                milk
                                                and
                                                drink it before going to bed.
                                            </li>
                                            <li><strong>Coconut water:&nbsp;</strong>Drink coconut water daily to help reduce
                                                inflammation in the gut.
                                            </li>
                                            <li><strong>Ginger:</strong> Boil 1 teaspoon of grated ginger in a cup of water for
                                                10-15
                                                minutes. Strain and drink this mixture 2-3 times a day.
                                            </li>
                                            <li><strong>Pomegranate juice:</strong> Drink fresh pomegranate juice twice a day to
                                                help
                                                reduce inflammation and promote healing.
                                            </li>
                                            <li><strong>Buttermilk:</strong> Mix 1 teaspoon of roasted cumin powder and a pinch
                                                of
                                                black
                                                pepper in a glass of buttermilk. Drink this mixture 2-3 times a day.
                                            </li>
                                            <li><strong>Banana:</strong> Eat ripe bananas daily to help soothe the gut and
                                                reduce
                                                inflammation.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="diseasesName === 'urticaria'">
                    <div class="text-left">
                        <div class="card">
                            <div class="card-header" style="color: #0b0b0b;">
                                <h4>Urticaria: Signs, Causes & Ayurvedic Treatment | Dr Axico</h4>
                            </div>
                            <div class="card-body text-left">
                                <p><strong>Introduction</strong></p>
                                <p>Urticaria, also called "hives," is a skin disease that causes itchy, raised, and
                                    often
                                    red bumps or welts. These welts can be different sizes and shapes, and they can show
                                    up
                                    and go away quickly in different places on the body. Urticaria happens when special
                                    skin
                                    cells called mast cells release histamine and other chemicals. Urticaria can be
                                    cured
                                    effectively with Ayurveda, book your Ayurvedic doctor online consultation now and
                                    get
                                    rid of such kinds of disorders.&nbsp;</p>
                                <p>Urticaria's main sign is itching, which can be mild or serious and can have a big
                                    effect
                                    on a person's quality of life. The welts or hives can be concentrated in one spot or
                                    spread over a bigger area. Angioedema can also cause swelling in the lower layers of
                                    the
                                    skin, especially around the eyes, lips, hands, and feet.</p>
                                <p><strong>What is Urticaria and what is it called in India?</strong></p>
                                <p>Urticaria is frequently referred to as "sheetapitta" in It in India, which means
                                    "cold
                                    rash." According to Ayurvedic principles, an imbalance in the body's doshas, notably
                                    Pitta and Vata, is what leads to sheetapitta. Through dietary adjustments, lifestyle
                                    changes, herbal medicines, and other therapeutic interventions, Ayurveda seeks to
                                    restore the balance of these doshas.</p>
                                <p>In India, urticaria management strategies may include Ayurvedic remedies like
                                    internal
                                    herbal medications, external herbal paste or oil applications, detoxification
                                    therapies,
                                    and suggestions for dietary and lifestyle changes. When prescribing remedies for
                                    sheetapitta, ayurvedic practitioners may additionally take into account elements
                                    including the patient's constitution (prakriti) and any underlying imbalances or
                                    diseases.</p>
                                <p><strong>What’s the difference between rash and urticaria/hives?</strong></p>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/urticaria/Image-1.webp"
                                    >
                                </div>
                                <p>A rash and urticaria (hives) are both skin conditions, but they have distinct
                                    characteristics that differentiate them:</p>
                                <p>1. Appearance: A rash is a broad term used to describe any abnormal change in the
                                    skin's
                                    color, texture, or appearance. It can manifest as redness, bumps, blisters, scales,
                                    or
                                    patches on the skin. Rashes can have various causes, including infections, allergic
                                    reactions, irritants, autoimmune conditions, or systemic diseases. They can be
                                    localized
                                    or widespread on the body, depending on the underlying cause.</p>
                                <p>On the other hand, urticaria or hives are characterized by raised, itchy welts on the
                                    skin that may be pink or red. Hives typically appear suddenly and can migrate or
                                    change
                                    location rapidly. They often have well-defined borders and can vary in size and
                                    shape.
                                    The appearance of hives is transient, with individual welts usually lasting for a
                                    few
                                    hours before fading away and new ones appearing.</p>
                                <p>2. Duration: Rashes can be acute or chronic. Acute rashes usually resolve within a
                                    few
                                    days or weeks and are often related to specific triggers, such as an allergen or
                                    irritant. Chronic rashes persist for longer than six weeks and may require a more
                                    thorough evaluation to determine the underlying cause.</p>
                                <p>Urticaria, or hives, can also be acute or chronic. Acute urticaria typically lasts
                                    for
                                    less than six weeks and is often triggered by allergens, medications, infections, or
                                    other factors. Chronic urticaria lasts for more than six weeks and can persist for
                                    months or even years, often without an identifiable cause.</p>
                                <p>3. Itchiness: Both rashes and urticaria can be itchy, but the itchiness associated
                                    with
                                    hives is typically more intense and bothersome. Itching is a hallmark symptom of
                                    urticaria and is often one of the primary reasons individuals seek treatment for
                                    hives.</p>
                                <p>It's important to note that rashes and hives can have overlapping causes, and in some
                                    cases, a rash can resemble hives or vice versa. If you have concerns about a skin
                                    condition, it is recommended to consult a healthcare professional or dermatologist
                                    for a
                                    proper diagnosis and appropriate treatment based on your specific symptoms and
                                    medical
                                    history.</p>
                                <p><strong>What are the main factors that cause Urticaria?</strong></p>
                                <div class="text-center">
                                    <img alt="" class="my-2 img-fluid"
                                         src="assets/img/diseases/diseasesImages/urticaria/Image-2.webp"
                                    >
                                </div>
                                <p>Hives, or urticaria, can be brought on by several things, such as:</p>
                                <p>Allergens: Urticaria is frequently brought on by allergic reactions to specific
                                    chemicals. Some foods (including nuts, shellfish, eggs, or dairy), medicines (such
                                    antibiotics or nonsteroidal anti-inflammatory drugs), insect bites or stings,
                                    pollen,
                                    latex, or pet dander can all be triggers for allergies.</p>
                                <p>Medication: As a negative reaction, some drugs might urticaria causes. This can
                                    include
                                    certain blood pressure drugs, painkillers (like aspirin or ibuprofen), antibiotics,
                                    or
                                    even herbal supplements. It's crucial to remember that not everyone who takes these
                                    drugs may have urticaria as a side effect.</p>
                                <p>Physical triggers: In those who are vulnerable, <a
                                    href="https://www.sciencedirect.com/topics/medicine-and-dentistry/physical-stimulation"
                                    rel="nofollow">specific physical stimulation</a> might cause
                                    urticaria. These factors can cause urticaria, such as exposure to cold or heat,
                                    excessive sweating, pressure on the skin (from tight clothes or rubbing), exposure
                                    to
                                    the sun (solar urticaria), or even urticaria brought on by strenuous activity.</p>
                                <p>Infections: Some infections, like bacterial or viral infections, might cause
                                    urticaria to
                                    appear. Examples that come to mind are viral illnesses like the flu or the common
                                    cold,
                                    urinary tract infections, or lung infections.</p>
                                <p>Autoimmune diseases: autoimmune problems including lupus, rheumatoid arthritis, or
                                    thyroid disease can also be linked to urticaria. In these situations, the immune
                                    system
                                    inadvertently targets healthy tissues, causing hives to appear.</p>
                                <p>Stress or emotional elements: For certain people, stress or emotional issues can
                                    cause or
                                    aggravate urticaria. The body can release specific molecules that aid in the
                                    formation
                                    of hives when under emotional stress, anxiety, or extreme emotional states.</p>
                                <p>Other causes: Other hives causes, such as exposure to specific chemicals, <a
                                    href="https://www.who.int/news-room/fact-sheets/detail/food-additives"
                                    rel="nofollow">food
                                    additives</a>,
                                    or environmental triggers, can also result in urticaria.</p>
                                <p><strong>What are the risk factors for Urticaria?</strong></p>
                                <p>Urticaria, or hives, can affect anyone, but certain risk factors may increase the
                                    risk.
                                    Risk factors include:</p>
                                <ul>
                                    <li>History of Allergies: Hay fever, asthma, and other allergic conditions increase
                                        the
                                        risk of urticaria. Allergies increase immune system sensitivity, which can lead
                                        to
                                        hives.
                                    </li>
                                    <li>Previous Urticaria Episodes: Recurrent or chronic urticaria may make you more
                                        susceptible to future episodes. Some people have recurring hives.
                                    </li>
                                    <li>Lupus, rheumatoid arthritis, and thyroid disorders increase urticaria risk.
                                        Hives
                                        are a symptom of autoimmune disorders.
                                    </li>
                                    <li>Stress, anxiety, and emotions can cause or worsen urticaria. Hives can result
                                        from
                                        emotional stress.
                                    </li>
                                    <li>Urticaria is more common in women. Hormones may explain this gender
                                        difference.
                                    </li>
                                    <li>Urticaria may run in families. If a close relative has hives, you may be at
                                        risk.
                                    </li>
                                    <li>Urticaria is most common in young adults. Urticaria can occur in children, but
                                        it's
                                        rarer in adults.
                                    </li>
                                </ul>
                                <p><strong>When to see a doctor and is it a self-diagnosed illness?</strong></p>
                                <p>If your hives symptoms are bothersome, persistent, or recurrent, you should visit a
                                    doctor. Even though urticaria occasionally goes away without treatment and may not
                                    need
                                    a diagnosis, going to the doctor can aid in a proper management plan and the
                                    identification of any underlying causes.</p>
                                <p>In the following circumstances, it is advised to see a doctor about urticaria:</p>
                                <p>Recurrent or Persistent Hives: It is advised to see a doctor if you experience
                                    frequent
                                    or protracted episodes of hives that last longer than six weeks. To identify the
                                    underlying cause and the best course of action for treating chronic urticaria, a
                                    thorough evaluation may be necessary.</p>
                                <p>Severe Symptoms: It is advised to seek medical attention if your hives are severe,
                                    causing significant discomfort, or interfering with your daily activities. This
                                    includes
                                    hives accompanied by excruciating itchiness, swelling, breathing difficulties, or
                                    other
                                    unsettling symptoms.</p>
                                <p>Seek immediate medical attention if your hives spread quickly to other parts of your
                                    body
                                    or if you experience swelling in your lips, face, or throat. This might indicate a
                                    more
                                    severe allergic reaction, like anaphylaxis, which necessitates immediate medical
                                    attention.</p>
                                <p>Impact on Quality of Life: It's critical to speak with a medical professional if your
                                    hives are interfering with your sleep, quality of life, or general health. They can
                                    aid
                                    in providing relief and creating a management strategy to lessen how urticaria
                                    affects
                                    your daily activities.</p>
                                <p>Since urticaria can have a variety of underlying causes and triggers, self-diagnosis
                                    can
                                    be difficult. While you might think that urticaria is the cause of your symptoms, a
                                    medical professional can perform a complete assessment, look over your medical
                                    history,
                                    run any necessary tests, and make a precise diagnosis. They can also advise you on
                                    the
                                    best courses of action, how to change your way of life, and how to spot any
                                    potential
                                    triggers or underlying conditions.</p>
                                <p><strong>Ayurvedic Say on Urticaria?</strong></p>
                                <p>Urticaria is referred to in Ayurveda as "Sheetapitta," which means "cold rash."
                                    Urticaria
                                    is thought to result from an imbalance in the body's doshas, particularly Pitta and
                                    Vata, according to Ayurvedic principles. When these doshas become vitiated, toxins
                                    (ama)
                                    build up in the body and can cause symptoms on the skin like hives.</p>
                                <p>The primary goal of ayurvedic urticaria treatment is to find and correct the body's
                                    imbalances and underlying causes. The approach to treatment aims to calm the Vata
                                    and
                                    Pitta doshas, cleanse the body, and return the doshas to their proper balance. Here
                                    are
                                    some suggestions from Ayurveda for treating urticaria:</p>
                                <p>Dietary and lifestyle changes can help reduce the symptoms of urticaria.
                                    Pitta-pacifying
                                    diets consist of calming and cooling foods. Avoiding hot, spicy, and acidic foods
                                    while
                                    emphasizing fresh fruit, vegetables, whole grains, and herbal teas may be one way to
                                    achieve this. Maintaining a regular daily schedule, controlling stress, and getting
                                    enough sound sleep are also essential.</p>
                                <p>Herbal Treatments: To treat urticaria, Ayurvedic herbs with detoxifying and cooling
                                    properties may be suggested. Neem, manjistha, turmeric, aloe vera, and azadirachta
                                    indica are some examples. Rubia cordifolia is another. These plants are thought to
                                    support the immune system, lower inflammation, and aid in blood purification.</p>
                                <p>Detoxification therapies: To remove toxins from the body, ayurvedic remedies like
                                    Panchakarma, a group of detoxification therapies, may be used. To help the body get
                                    clean and get back in balance, these treatments could include herbal enemas, steam
                                    therapy, and massages with herbal oils.</p>
                                <p>Ayurvedic medicines: Depending on the patient's constitution and symptoms, ayurvedic
                                    practitioners may recommend particular herbal formulations or combinations. These
                                    formulas might support the immune system, balance the doshas, and enhance general
                                    well-being.</p>
                                <p>It's crucial to remember that Ayurvedic therapies should be tailored to each
                                    patient's
                                    unique condition, constitution, and underlying imbalances. It is advised to seek the
                                    advice of a licensed Ayurvedic physician to receive a correct diagnosis and a
                                    customized
                                    treatment plan for urticaria.&nbsp;</p>
                                <p>They can evaluate your particular situation and offer advice on the best herbs,
                                    treatments, and lifestyle changes for controlling urticaria. It's also critical to
                                    seek
                                    the advice of a medical professional for a thorough assessment and to rule out any
                                    underlying medical conditions.</p>
                                <p><strong>Ayurvedic Remedies for Urticaria?</strong></p>
                                <p>Urticaria (Sheetapitta) is treated holistically in Ayurveda to address the underlying
                                    imbalances in the body. Here are some Ayurvedic remedies that may be useful for
                                    relieving urticaria symptoms:</p>
                                <p>Neem (Azadirachta indica) is a well-known Ayurvedic herb with powerful
                                    anti-inflammatory
                                    and cleansing properties. Consuming neem leaves or taking neem supplements may aid
                                    in
                                    blood purification and reduce urticaria-related inflammation.</p>
                                <p>Curcuma longa (turmeric) is widely employed in ayurvedic medicine for skin allergy
                                    itching due to its anti-inflammatory and immunomodulatory properties. To alleviate
                                    urticaria symptoms, you can consume turmeric powder mixed with warm milk or
                                    incorporate
                                    it into your cooking.</p>
                                <p>Aloe Vera: Aloe vera gel has a reputation for its calming and cooling effects on the
                                    skin. Fresh aloe vera gel applied topically to the affected areas may alleviate
                                    urticaria-related itching and inflammation.</p>
                                <p>Manjistha (Rubia cordifolia): Manjistha is a herb used in Ayurveda to purify the
                                    blood
                                    and promote skin health. It may aid in the treatment of urticaria by reducing
                                    inflammation and encouraging detoxification.</p>
                                <p>Guduchi (Tinospora cordifolia) is an Ayurvedic herb with immunomodulatory and
                                    anti-allergic properties. It may assist in boosting the immune system and decreasing
                                    the
                                    hypersensitivity that can contribute to urticaria.</p>
                                <p>A mixture of three fruits (amla, bibhitaki, and haritaki), Triphala is widely used in
                                    Ayurveda for its detoxifying and revitalizing properties. Consumption of Triphala
                                    powder
                                    or capsules may aid in digestion and body detoxification.</p>
                                <p><strong>What to eat and not eat in Urticaria?</strong></p>
                                <p>In urticaria, it is essential to consume a healthy, well-balanced diet that promotes
                                    overall health and reduces inflammation. While specific dietary triggers can differ
                                    from
                                    person to person, the following are general recommendations for what to consume and
                                    what
                                    to avoid in urticaria:</p>
                                <p><strong>What to Eat:</strong></p>
                                <p>Include foods with anti-inflammatory properties, such as fresh fruits (particularly
                                    berries), vegetables (particularly verdant greens), and whole cereals. These
                                    nutrients
                                    can aid in reducing inflammation throughout the body.</p>
                                <p>Include omega-3 fatty acid-rich foods in your diet, such as fatty fish (salmon,
                                    sardines), flaxseeds, chia seeds, and hazelnuts. Omega-3 fatty acids are
                                    anti-inflammatory and can aid in reducing inflammation.</p>
                                <p>Herbal infusions: Consume soothing and anti-inflammatory herbal infusions such as
                                    chamomile, peppermint, and green tea.</p>
                                <p>Eat probiotic-rich foods such as yoghurt, kefir, sauerkraut, and kimchi. Probiotics
                                    can
                                    support intestinal health and boost the immune system.</p>
                                <p>Include fiber-rich foods such as legumes, whole cereals, and vegetables in your diet.
                                    Fibre aids in digestion regulation and promotes overall digestive health.</p>
                                <p>Water: Maintain hydration by consuming enough water throughout the day. Hydration
                                    promotes epidermis health and general well-being.</p>
                                <p><strong>These items shall be avoided:</strong></p>
                                <p>Identify and avoid any specific allergenic foods that may provoke allergic reactions
                                    hives or exacerbate your symptoms. Nuts, crustaceans, eggs, dairy, soy, and gluten
                                    are
                                    common allergenic foods. Maintain a food journal to record any potential
                                    triggers.</p>
                                <p>Limit or avoid fiery foods, heated condiments, and acidic foods such as citrus
                                    fruits,
                                    tomatoes, and vinegar, as they may exacerbate inflammation.</p>
                                <p>Reduce your intake of processed and packaged foods, as they frequently contain
                                    additives,
                                    preservatives, and artificial ingredients that may cause inflammation or allergic
                                    reactions.</p>
                                <p>Alcohol and Caffeine: Limit or avoid alcohol and caffeine, as they may contribute to
                                    inflammation and exacerbate symptoms in some people.</p>
                                <p>Minimize your consumption of sugary foods and beverages, as they can cause
                                    inflammation
                                    and negatively affect your overall health.</p>
                                <p>It is essential to note that individual triggers and dietary restrictions</p>
                                <p><strong>Recommended Ayurvedic Herbs and Medication for Urticaria</strong></p>
                                <p>Several Ayurvedic medications are recommended for the treatment of Urticaria, which
                                    may
                                    include:</p>
                                <p>1.Haridrakhand: This is an Ayurvedic herbal formulation that contains
                                    Turmeric (Curcuma longa) as its main ingredient. It is known for its
                                    anti-inflammatory
                                    and antihistaminic properties and helps to reduce the inflammation and itching
                                    associated with Urticaria.</p>
                                <p>2.Arogyavardhini Vati: This is an Ayurvedic herbal tablet that
                                    contains a combination of herbs and minerals. It is known to improve liver function
                                    and
                                    helps to detoxify the body, which can be beneficial in treating Urticaria.</p>
                                <p>3.Triphala Guggulu: This is an Ayurvedic herbal tablet that contains
                                    a combination of Triphala (a blend of three fruits) and Guggulu (a resin). It is
                                    known
                                    for its anti-inflammatory and anti-allergic properties and can help treat
                                    Urticaria.</p>
                                <p>4.Neem (Azadirachta indica): Neem is a natural antihistamine and has
                                    anti-inflammatory properties. It can be used topically in the form of neem oil or
                                    taken
                                    orally in the form of capsules or tablets.</p>
                                <p>5.Giloy (Tinospora cordifolia): Giloy is a natural immunomodulator
                                    and can help treat Urticaria, which is often triggered by an overactive immune
                                    system.
                                    It can be taken orally in the form of tablets or capsules.</p>
                                <p>&nbsp;<strong>A Short Note from Dr. Axico</strong></p>
                                <p>Your body's response to an allergen (substance) to which it is susceptible is hives.
                                    Although uncomfortable, these reactions are frequently not serious. Hives, hives
                                    with
                                    swelling, or just swelling may appear. These symptoms typically subside after a day
                                    or
                                    two.&nbsp;</p>
                                <p>Talk to an Ayurvedic doctor online about getting examined for potential causes if you
                                    frequently experience these responses. You can take precautions to prevent your
                                    triggers
                                    and treat reactions once you are aware of what causes your allergies. Additionally,
                                    you
                                    can buy Ayurvedic medicines and healthcare items online from Dr. Axico.&nbsp;</p>
                            </div>
                        </div>
                        <div class="card mt-5">
                            <h5 class="card-header" style="font-size: 2rem;">FAQs</h5>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">

                                        <p><strong>What is the best urticaria ayurvedic treatment?</strong></p>
                                        <p>Ayurvedic therapy for urticaria balances the doshas (Vata, Pitta, and Kapha) and
                                            addresses the cause. The patient's constitution and imbalances determine the
                                            therapy.
                                            Urticaria therapies in Ayurveda include:</p>

                                        <p><strong>Herbal Remedies:</strong></p>
                                        <p>1.Aloe vera: Applying gel soothes and reduces irritation.</p>
                                        <p>2.Neem (Azadirachta indica) leaves or oil relieve itching and
                                            irritation.</p>
                                        <p>3.Manjistha (Rubia cordifolia) powder or decoction purifies the blood and
                                            supports skin health.</p>
                                        <p>4.Haridra (Turmeric): Turmeric may be eaten or used as a paste to reduce
                                            inflammation.</p>
                                        <p>5.Kutki (Picrorhiza kurroa) powder detoxifies the liver and boosts
                                            immunity.</p>

                                        <p><strong>Detoxification:</strong></p>
                                        <p>1.Panchakarma: Virechana (therapeutic purgation) and Raktamokshana
                                            (bloodletting) may be prescribed to remove poisons and balance doshas.</p>
                                        <p>2.Herbal Decoctions: Ayurvedic herbal decoctions like Triphala (three
                                            fruits) may cleanse and aid digestion.</p>

                                        <p><strong>Diet and Lifestyle:</strong></p>
                                        <p>1.Avoid urticaria triggers including meals, allergies, stress, and severe
                                            temperatures.</p>
                                        <p>2.Balanced diet: Eat fresh produce, healthy grains, and anti-inflammatory
                                            herbs.</p>
                                        <p>3.Manage stress: Stress hives may increase urticaria symptoms, therefore
                                            practice yoga, meditation, and pranayama.</p>

                                        <p><strong>Urticaria Ayurvedic Medicine:</strong></p>
                                        <p>Based on the patient's constitution and imbalances, ayurvedic practitioners may
                                            recommend
                                            various herbal compositions.&nbsp;</p>
                                        <p>Mahamanjisthadi Kwath, Gandhak Rasayan, and Haridrakhand are popular Ayurvedic
                                            urticaria
                                            treatments.</p>

                                        <p><strong>What are the symptoms of acute and chronic hives?</strong></p>
                                        <p>Acute and chronic hives have similar symptoms, but they differ in terms of duration.
                                            Acute hives typically last for less than six weeks, while chronic hives persist for
                                            more
                                            than six weeks. The&nbsp;urticaria signs and symptoms&nbsp;include:</p>
                                        <p>1.Raised, Red Welts: Hives appear as raised, itchy, and red welts on the
                                            skin. They can vary in size and shape and may change in location over time.</p>
                                        <p>2.Itching: Hives are often accompanied by intense itching, which can be a
                                            significant source of discomfort.</p>
                                        <p>3.Swelling: The welts may cause swelling in the affected areas, such as
                                            the face, lips, tongue, or throat. Severe swelling in the throat can potentially
                                            lead to
                                            breathing difficulties and requires immediate medical attention.</p>
                                        <p>4.Burning or Stinging Sensation: Some individuals may experience a burning
                                            or stinging sensation in the affected areas.</p>
                                        <p>5.Spontaneous Appearance and Disappearance: Hives can come and go
                                            suddenly, with individual welts lasting for a few minutes to several hours. They may
                                            disappear from one area and reappear in another.</p>
                                        <p>6.Triggers: Hives can be triggered by various factors, including allergens
                                            (such as food, medication, or insect bites), physical stimuli (such as pressure,
                                            heat,
                                            cold, or sunlight), infections, stress, or underlying medical conditions.</p>

                                        <p><strong>Are hives contagious</strong></p>
                                        <p>Hives don't spread. Urticaria, or hives, are not contagious. They are induced by
                                            allergies, physical stimulation, diseases, or underlying medical disorders.</p>
                                        <p>Certain substances produce histamine and other molecules in the body, causing raised,
                                            itchy welts on the skin. Immune system welts aren't communicable.</p>
                                        <p>However, viral or bacterial infections may induce hives. Hives are not communicable,
                                            although the illness may be. To treat hives, find the reason.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 mt-lg-0" style="position: sticky;">
                <div class="row sticky-top" style="top: 110px;">
                    <!--            Book your appointment-->
                    <div class="col-12">
                        <div class="appointment-item appointment-item-two p-4">
                            <h2>Book your appointment for {{ disease }}</h2>
                            <span class="mb-2">We will confirm your  appointment within 2 hours</span>
                            <div class="appointment-layer">
                                <div class="appointment-form">
                                    <form [formGroup]="appointmentForm" novalidate>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group mb-2" style="padding-left: 50px;">
                                                    <i class="icofont-business-man-alt-1" style="font-size: 35px;"></i>
                                                    <label>Name</label>
                                                    <input class="form-control" formControlName="name"
                                                           placeholder="Enter your name"
                                                           type="text">
                                                    <small
                                                        *ngIf="submitted && appointmentForm.get('name').hasError('required')"
                                                        class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group mb-2" style="padding-left: 50px;">
                                                    <i class="icofont-ui-call" style="font-size: 35px;"></i>
                                                    <label>Phone</label>
                                                    <input class="form-control" formControlName="contact"
                                                           placeholder="Enter your number"
                                                           type="number">
                                                    <small
                                                        *ngIf="submitted && appointmentForm.get('contact').hasError('required')"
                                                        class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group mb-2" style="padding-left: 50px;">
                                                    <i class="icofont-business-man" style="font-size: 35px;"></i>
                                                    <label>Age</label>
                                                    <input class="form-control" formControlName="age"
                                                           placeholder="Your Age"
                                                           type="number">
                                                    <small
                                                        *ngIf="submitted && appointmentForm.get('age').hasError('required')"
                                                        class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group mb-2" style="padding-left: 50px;">
                                                    <i class="icofont-user-alt-1" style="font-size: 35px;"></i>
                                                    <label>Gender</label>
                                                    <select class="form-control" formControlName="gender">
                                                        <option value="">Select gender</option>
                                                        <option value="MALE">Male</option>
                                                        <option value="FEMALE">Female</option>
                                                        <option value="OTHER">Transgender</option>
                                                    </select>
                                                    <small
                                                        *ngIf="submitted && appointmentForm.get('gender').hasError('required')"
                                                        class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group mb-2" style="padding-left: 50px;">
                                                    <i class="icofont-hospital" style="font-size: 35px;"></i>
                                                    <label>Symptoms</label>
                                                    <input class="form-control" formControlName="symptoms"
                                                           placeholder="Enter your symptoms"
                                                           type="text">
                                                    <small
                                                        *ngIf="submitted && appointmentForm.get('symptoms').hasError('required')"
                                                        class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-2">
                                                <div class="text-center">
                                                    <button (click)="submit(content)"
                                                            class="btn btn-outline-primary btn-lg"
                                                            type="submit"><b>
                                                        Submit</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    Related Blogs-->
                    <div class="col-12">
                        <h2 style="font-weight: 600;font-size: 24px;">Latest Blog:-</h2>
                        <div class="container">
                            <table class="table table-hover">
                                <tbody>
                                <tr *ngFor="let blog of blogList | slice:0:3" style="cursor: pointer">
                                    <td class="d-flex">
                                        <img *ngIf="blog?.blog_cover?.url;else imageNotFound" alt="Blog"
                                             class="img-fluid h-50"
                                             src="{{blog?.blog_cover?.url}}" style="width: 38%!important;"/>
                                        <ng-template #imageNotFound>
                                            <img alt="Blog" src="assets/img/blog/p.webp"/>
                                        </ng-template>
                                        <a (click)="goTo('blogs/'+blog?.title)"
                                           class="cursor-pointer text-decoration-none text-dark ms-2"
                                           style="font-size: 19px;">
                                            {{blog?.heading |uppercase}}
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--                    Social Media Pages-->
                    <div class="col-12">
                        <div class="mt-50px">
                            <h4 class=""></h4>
                            <h2 style="font-weight: 600;font-size: 24px;">Like us on facebook:-</h2>
                            <div class="custom-html-widget">
                                <iframe
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                    allowfullscreen="true" frameborder="0" height="500" scrolling="no"
                                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdoctoraxico&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1668781656896221"
                                    style="border:none;overflow:hidden"
                                    width="340"></iframe>
                            </div>
                        </div>
                        <div class="mt-50px">
                            <h2 style="font-weight: 600;font-size: 24px;">Latest Instagram Post:-</h2>
                            <div class="shadow p-3 mb-5 bg-white rounded">
                                <iframe allowtransparency="true" frameborder="0" height="400" scrolling="no"
                                        src="https://www.instagram.com/doctoraxico/embed" width="100%"></iframe>
                            </div>
                        </div>
                        <div class="mt-50px">
                            <h2 style="font-weight: 600;font-size: 24px;">Our Youtube Channel:-</h2>
                            <iframe
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                class="shadow p-3 mb-5 bg-white rounded" frameborder="0"
                                height="315"
                                src="https://www.youtube.com/embed/videoseries?list=PLzrY1HUyr3RW1ok2GJTI8C8iKRhhfMnbZ"
                                title="YouTube video player"
                                width="420px"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
