<div class="signup-area">
    <ng-template #content>
        <div id="modal">
            <div class="modal-header font-weight-bold">
                <h4 class="modal-title font-weight-bold">
                    <i class="fa-lock fas cursor-pointer mr-2"></i>
                    <span class="ml-10">Enter OTP sent to your Contact No.</span>
                </h4>
                <i (click)="this.modalService.dismissAll()"
                   class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"></i>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form [formGroup]="OtpForm" class="form mb--8px" novalidate>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <fieldset class="form-group">
                                        <input class="form-control" formControlName="otp" placeholder="Enter OTP"
                                               type="number">
                                    </fieldset>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                                    <small>Resend OTP in - </small>
                                    <small *ngIf="timerSixtySeconds > 0" class="text-danger mr-1">{{timerSixtySeconds}}
                                        sec.</small>
                                    <small (click)="resendOTP()" *ngIf="timerSixtySeconds === 0"
                                           class="text-primary cursor-pointer">Resend OTP ?</small>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="submitOtp()"
                        [disabled]="submitting"
                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Submit
                </button>
            </div>
        </div>
    </ng-template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 pl-0">
                <div class="signup-left">
                    <img alt="SignUp" src="assets/img/signup-bg.jpg">
                </div>
            </div>

            <div class="col-lg-6 ptb-100">
                <div class="signup-item">
                    <div class="signup-head">
                        <h2>Register Here</h2>
                        <p>Already have an account? <a routerLink="/login">Log In</a></p>
                    </div>

                    <div class="signup-form">
                        <form [formGroup]="registerForm" novalidate>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="name" placeholder="Full Name"
                                               required type="text"/>
                                        <small *ngIf="submitted && registerForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Name Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="contact"
                                               placeholder="Contact Number"
                                               type="text">
                                        <small *ngIf="submitted && registerForm.get('contact').hasError('required')"
                                               class="text-danger">
                                            Contact Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="email" placeholder="Your Email"
                                               type="email">
                                        <small *ngIf="submitted && registerForm.get('email').hasError('required')"
                                               class="text-danger">
                                            Email Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="password" placeholder="Password"
                                               type="password">
                                        <small *ngIf="submitted && registerForm.get('password').hasError('required')"
                                               class="text-danger">
                                            Password Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" id="gridCheck" type="checkbox">
                                            <label class="form-check-label" for="gridCheck">
                                                Yes, I agree with all <a href="#">Terms & Conditions</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <button (click)="submit(content)" class="btn signup-btn" type="submit">Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
