import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class Prakriti {
     static prakritiData = [

            "kapha", [
            {
                "questionText": "Whether your skin remains oily throughout the year in comparison to others?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
            },
            {
                "questionText": "Are your body-hairs & skin shiny, even when no oil or moisturizer is used?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
            },
            {
                "questionText": "Are you considered attractive among your friends?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "interfaces are typically used to list the properties and methods for an object"
            },
            {
                "questionText": "Do even mild or trivial injuries on your body make  you upset?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "number[] is another way of writing Array<number> in TypeScript"
            },
            {
                "questionText": "Among your family members, is your complexion considered fairer?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "a constructor is used by a class to take in parameters"
            },
            {
                "questionText": "Do you think you have intense sexual desire?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "async is not used as an access modifier type in TypeScript"
            },
            {
                "questionText": "Have you got well built muscles?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "the export keyword allows for the information to be transmitted between files"
            },
            {
                "questionText": "Do you change your body posture frequently? (You cannot manage yourself in a stable posture for a long duration.)",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "filter is a method used to conditionally create a new array"
            },
            {
                "questionText": "Do you have a well-nourished & normally developed body? (You are neither malnourished nor obese.)",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are you lazy and disinterested in activities like morning walk/ jogging, swimming or any type of outdoor games?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are you slow in consuming the food? (Even after all have left the dining hall, you are still consuming the same amount of food).",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "When you go to morning walk or college or office, do you walk slowly in comparison to others?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "If you are assigned any work, do you take some extra time to start it?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you get irritated easily? (For example, when you don’t get breakfast on time in your hostel or when the power goes off while watching a cricket match or your favourite movie on television).",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are you late to develop/suffer from symptoms after exposure to common causative factors? (For example, during seasonal changes, when your friends are easily caught up with flu etc., you are still healthy among them).",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Does your gait (style of walking) change with respect to speed or manner frequently?",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you feel hungry more frequently and do you consume more food in comparison to others?",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you tolerate heat easily?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you consume liquids in more quantity and frequency in comparison to others?",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you perspire less in comparison to others?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are sounds produced frequently in your joints on movement?",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Have you got a good/ attractive complexion?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Have you got sweet & pleasant voice?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            }
        ],
            "pitta", [
            {
                "questionText": "Are you more comfortable in winter than summer?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Among your family members, is your complexion considered fairer?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Does your temperature of oral cavity remain towards upper limit of normal range?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": ""
            },
            {
                "questionText": "Do you have excessive black moles, Freckles etc on your skin? \n Or \n Have you noticed new appearance of black moles\noften on your skin?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you feel excessive hunger & thirst in comparison to others?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Have you experienced premature graying, wrinkling of skin & early baldness?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you have soft, scanty, brown hair on your face, body & head?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you involve yourself in risky & heroic activities requiring physical strength often?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you have ability to digest large quantities of food easily?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you take large quantities of food & drink in comparison to others?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you get easily irritated for small/negligible problem in day-to-day life?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you consume food more frequently than others? (5-6 times/day)",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you have soft & loose muscle bulk especially around the joints?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "In comparison to others do you pass urine & stool in large quantities and do you perspire more?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do your friends complain of bad smell being emitted from your body & mouth?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you think you have intense sexual desire?",
                "options": [
                    {
                        "text": "Yes"
                    },
                    {
                        "text": "No",
                        "correct": true
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            }
        ],
            "vata", [
            {
                "questionText": "Whether your skin remains dry throughout the year in comparison to others?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Is your body undernourished/ emaciated?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Have you got rough, low, broken or obstructed voice?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Does Your sleep last less than 6 hours per day? Or Can your sleep be disturbed easily?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you change walking speed & style from time to time?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you keep changing your food habits from time to time?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you keep changing your walking / jogging habit from time to time?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you keep your joints, eyes, eyebrows, jaw, lips, tongue, head, Shoulder, hands & feet frequently moving?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are you considered a talkative among your friends?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you have prominent veins & tendons all over the body?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you generally start the work assigned to you immediately?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you get irritated easily? (E.g., when you do not get Shighra breakfast on time in your hostel or when the power goes off while watching a cricket match or your favourite movie over television)",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you get frightened easily?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you make friends easily & also lose them easily?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you generally learn things quickly?\nOr\nDo you have a good grasping power?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Is your long term memory weak? (E.g., you can\nremember only few names of your friends at your\nprimary school).",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are you more comfortable in summer?\nOr\nDo you prefer hot/warm drinks over cold drinks?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you generally develop symptoms immediately\nafter exposure to common causative factors?(You are\neasily caught by diseases like flu, allergy during\nseasonal changes).",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you shiver in winter season more than your\nfriends?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you often feel stiffness in your body after exercise,\ntravelling?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are your nails, teeth, hands, feet and hairs on your\nbody and face rough?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Do you have cracks on the body especially on the\nheels?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            },
            {
                "questionText": "Are some crackling sounds produced in your joints\nduring movements?",
                "options": [
                    {
                        "text": "Yes",
                        "correct": true
                    },
                    {
                        "text": "No"
                    }
                ],
                "explanation": "this.propertyName is the way to access a specific property within a class"
            }
        ]
        ]

}
