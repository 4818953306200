import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class AddToCartService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    getByProductIds(data): Observable<any> {
        return this.getRequest(AppUrl.ADD_TO_CART_PRODUCT_ID(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.ADD_TO_CART(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.ADD_TO_CART_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.ADD_TO_CART(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.ADD_TO_CART(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADD_TO_CART(id), data);
    }

    updateQuantity(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADD_TO_CART_UPDATE_QUANTITY(id), data);
    }

    delete(id): Observable<any> {
        return this.deleteRequest(AppUrl.ADD_TO_CART(id));
    }

    deleteAll(): Observable<any> {
        return this.deleteRequest(AppUrl.ADD_TO_CART_DETELE_ALL());
    }
}
