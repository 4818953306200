<div class="page-title-area page-title-one" style="background-image: url('assets/img/diseases/diseases.jpg');">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item"></div>
        </div>
    </div>
</div>
<div class="about-area py-4 py-lg-5">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'shirodhara'" class="font-bold" style="margin-bottom: 86px;">
                        Shirodhara
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'abhyanga'" class="font-bold" style="margin-bottom: 86px;">
                       Abhyanga
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'sthanik-basti'" class="font-bold" style="margin-bottom: 86px;">
                        Sthanik Basti
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'nasyam'" class="font-bold" style="margin-bottom: 86px;">
                        Nasyam
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'kizhi'" class="font-bold"
                          style="margin-bottom: 86px;">
                    Kizhi(Potli)
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'swedana'" class="font-bold" style="margin-bottom: 86px;">
                        Swedana
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'lepam'" class="font-bold" style="margin-bottom: 86px;">
                        Lepam
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'pizhichil'" class="font-bold" style="margin-bottom: 86px;">
                        Pizhichil
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'eye-treatments'" class="font-bold" style="margin-bottom: 86px;">
                       Eye Treatments
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'udavartana'" class="font-bold" style="margin-bottom: 86px;">
                        Udavartana
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'thalapothichil'" class="font-bold" style="margin-bottom: 86px;">
                        Thalapothichil
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'pichu'" class="font-bold" style="margin-bottom: 86px;">
                        Pichu
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'vamana'" class="font-bold" style="margin-bottom: 86px;">
                        Vamana
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'virechana'" class="font-bold" style="margin-bottom: 86px;">
                        Virechana
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'basti'" class="font-bold" style="margin-bottom: 86px;">
                        Basti
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'janu-basti'" class="font-bold" style="margin-bottom: 86px;">
                       Janu basti
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'kati-basti'" class="font-bold" style="margin-bottom: 86px;">
                       kati Basti
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'leech-therapy'" class="font-bold" style="margin-bottom: 86px;">
                        Leech Therapy
                    </span>
                </h1>
                <h1 class="mb-4">
                    <span *ngIf="panchkarmaName === 'urticaria'" class="font-bold" style="margin-bottom: 86px;">
                        Urticaria
                    </span>
                </h1>
                <div *ngIf="panchkarmaName === 'shirodhara'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Shirodhara</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>The Ayurvedic therapy known as Shirodhara is an integral aspect of the Panchakarma regimen,
                            which is also known as the purification process. Shirodhara Therapy is a technique that was
                            derived from the ancient Indian medical practice of Ayurveda. It consists of pouring a
                            steady stream of warm oil or another type of medicinal liquid onto the forehead.&nbsp;Book
                            your Panchkarma Therapy now and treat yourself with Shirodhara with Dr. Axico.&nbsp;</p>
                        <p>This slow and steady pouring produces a profound sense of relaxation and has a wide variety
                            of positive effects on one&apos;s mind, body, and spirit. A calming and healing experience
                            is created via the practice of Panchakarma Shirodhara, which is carried out by licensed
                            therapists with the assistance of specialized apparatus.</p>
                        <h2>Procedure:</h2>
                        <p>In the
                            Shirodhara Ayurvedic treatment preparation phase, the therapist creates a customized
                            concoction of a warm therapeutic liquid, which may include herbal oil, medicated milk,
                            buttermilk, or herbal decoctions, among other options. The liquid is warmed to a point where
                            it can be applied without causing discomfort.</p>
                        <h2>Positioning:</h2>
                        <p> The user is asked to lie down in a comfortable position on a Shirodhara table or
                            massage table specifically designed for the procedure. To prevent hair from falling into one&apos;s
                            eyes and to keep one&apos;s hair in place, one can use a towel or a headband.</p>
                        <p>The therapist will next suspend a pot or vessel that is filled with the warm therapeutic
                            liquid known as &ldquo;shirodhara pot&rdquo; over the individual&apos;s forehead to begin
                            the flow of liquid. The pot is set at a particular height to manage the flow of fluids onto
                            the forehead, with the typical target location being the region known as the &quot;third
                            eye.&quot;</p>
                        <h2>Pouring in a continuous motion:</h2>
                        <p> The therapist begins a slow and regular flow of the liquid,
                            allowing it to pour onto the client&apos;s forehead in a continuous motion. Individual
                            comfort and reaction are taken into account while making adjustments to the flow.</p>
                        <h2>Duration:</h2>
                        <p> The length of time that a Shirodhara session lasts normally runs anywhere from
                            thirty to sixty minutes, depending on the preferences of the patient and the requirements of
                            the treatment. In many cases, the treatment is accompanied by a soothing massage of the head
                            and scalp.</p>
                        <p>In the course of the treatment, the therapist will cultivate a tranquil atmosphere, during
                            which time they will encourage the patient to achieve a state of profound relaxation, let go
                            of stress, and meditate.</p>
                        <ul>
                            <li>
                                <p>Post-therapy: Following the Shirodhara treatment uses, the patient is allowed some
                                    time to relax and take in the healing effects of the modality. It is strongly
                                    advised that you stay away from direct contact with water or cold air.</p>
                            </li>
                        </ul>
                        <h2>Shirodhara offers the following benefits:</h2>
                        <p>Shirodhara Treatment Benefits - Shirodhara
                            is well-known for its capacity to relax the mind and lower levels of stress and anxiety. As
                            a result, it provides relief from stress. The consistent application of a warm liquid to the
                            forehead produces a state of profound relaxation, which contributes to improved mental and
                            emotional health and well-being.</p>
                        <ul>
                            <li>
                                <p>The shirodhara therapy benefits have a calming impact on the neurological system,
                                    which makes it good for people who suffer from sleep disorders or insomnia. This
                                    results in an improvement in the quality of sleep. The practice of shirodhara
                                    improves the overall quality of one&apos;s sleep and can facilitate the development
                                    of more consistent sleeping habits.</p>
                            </li>
                            <li>
                                <p>Improved mental clarity, focus, and memory are all outcomes of the treatment, which
                                    contributes to enhanced cognitive performance. Shirodhara benefits the brain as it
                                    restores and nourishes the brain and mind, which in turn helps the body as a whole
                                    perform better cognitively.</p>
                            </li>
                            <li>
                                <p>Shirodhara is effective in providing relief from headaches and migraines,
                                    particularly those brought on by tension. In addition to the therapeutic liquids,
                                    the soothing impact of the therapy also contributes to the reduction of pain and the
                                    promotion of headache relief.</p>
                            </li>
                            <li>
                                <p>Dosha balancing: According to Ayurveda, the practice of Shirodhara assists in
                                    balancing the three doshas (Vata, Pitta, and Kapha) in the body, which in turn helps
                                    to restore harmony and improve general health.</p>
                            </li>
                        </ul>
                        <h2>Shirodhara benefits for hair and scalp:</h2>
                        <p> The warm therapeutic liquids used in the Shirodhara
                            technique nourish the hair and scalp, which in turn promotes healthy hair growth, reduces
                            dryness on the scalp, and improve the scalp&apos;s general condition.<br><br></p>
                        <p>Shirodhara can improve the function of the sensory organs, including the eyes, ears, nose,
                            and skin. This is one of the many benefits of receiving this treatment. Shirodhara benefits
                            for eyes helps to alleviate eye strain, improves hearing, increases nasal health, and boosts
                            overall sensory perception in patients.</p>
                        <p>Shirodhara, which helps remove toxins and other impurities from the body, is one of the
                            practices that contribute to the detoxification process. It helps the body&apos;s natural
                            detoxification systems and makes the metabolic process more efficient overall.</p>
                        <h2>Hormonal harmony:</h2>
                        <p> The treatment has the potential to bring about hormonal harmony inside the
                            body and help restore hormonal balance. It may be especially helpful for people who are
                            struggling with hormone imbalances or diseases that are related to the endocrine system.<br><br>Shirodhara
                            has a relaxing impact on the nervous system, lowering nervous tension and irritability and
                            creating a sense of tranquility. This effect is because shirodhara is performed in a seated
                            position. Both the short-term and long-term impacts of chronic stress are reduced, and the
                            health of the nervous system as a whole is improved.</p>
                        <h2>Effects on rejuvenation and
                            anti-aging:</h2>
                        <p>The therapeutic liquids used in shirodhara nourish and revitalize the skin,
                            which in turn reduces the appearance of indicators of aging such as wrinkles and fine lines.
                            It enhances the skin&apos;s general texture and look, as well as imparting a more youthful
                            shine to the complexion.</p>
                        <h2>Shirodhara for depression:</h2>
                        <p>Emotional equilibrium is achieved
                            by the practice of Shirodhara, which also has the added benefit of elevating one&apos;s
                            mood. It can be especially helpful for people who are coping with depression, anxiety, or
                            emotional imbalances since it can promote a sense of emotional well-being and stability in
                            those people.</p>
                        <h2>Enhanced energy flow:</h2>
                        <p> The therapeutic liquid is massaged over the
                            forehead in a rhythmic pattern, which helps to eliminate energy blockages and promote the
                            smooth flow of vital energy (prana) throughout the entire body. This contributes to an
                            increase in both total vitality and energy levels.<br><br>Shirodhara has the potential to
                            provide relief from sinus congestion in addition to other respiratory ailments. The warm
                            therapeutic liquids serve to relax and clear the nasal passages, which in turn helps to
                            reduce congestion and promote the health of the respiratory system.<br><br>Shirodhara is
                            said to bring on a sense of profound relaxation as well as renewal for those who experience
                            it. It helps to alleviate physical and mental exhaustion, restore energy, and enhance
                            general well-being, all while providing a joyful experience for the user.<br><br>Enhanced
                            mental focus, attention, and clarity are all benefits that come from participating in this
                            treatment. It may be especially helpful for people who are suffering from mental fog or who
                            have trouble maintaining their concentration.<br><br>Shirodhara is effective in the
                            treatment of eye strain and tension, which can be brought on by staring at a screen for an
                            extended period or by other visual stressors. The therapy has a calming impact on the eyes,
                            which helps to relieve tiredness while also supporting healthy eyes.<br><br>Shirodhara is a
                            type of holistic therapy that treats the mind, body, and spirit to facilitate healing on all
                            levels. It supports the body&apos;s natural healing processes while also fostering a sense
                            of equilibrium, harmony, and overall well-being in the individual.<br><br>You must get the
                            advice of a skilled Ayurvedic practitioner or therapist to ascertain whether or not
                            Shirodhara is appropriate for your current state of health and specific requirements. They
                            will tailor the treatment to your specific needs and provide you with the proper information
                            to get the most out of it.</p>
                        <p><strong>Why Choose Dr. Axico - </strong>If you are in Delhi and looking for an affordable
                            Ayurvedic shirodhara
                            massage in Delhi. Dr. Axico&rsquo;s Ayurvedic body massage centres are available across the
                            city with the best client service.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'sthanik-basti'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Sthanik Basti</h4>
                    </div>
                    <div class="card-body">
                        <p>Greeva Basti, Kati Basti, and Janu Basti therapy are three different Ayurvedic remedies that
                            are included in the Panchakarma treatment. These treatments concentrate their attention on
                            particular regions of the body to provide alleviation and revitalization in those areas.&nbsp;Book
                            your Panchkarma Therapy now and treat yourself with Greeva Vasti, Janu Basti, and Kati Basti
                            with Dr. Axico.&nbsp;</p>
                        <p>The areas of the neck and upper back are the primary focus of the Greeva Vasti, whereas the
                            lower back is the primary focus of the Kati Basti, and the knee joints are the primary focus
                            of the panchakarma janu basti. These treatments entail the application of warm medicinal
                            oils or herbal decoctions in a reservoir or dam-like structure that has been specifically
                            designed to cover the affected area. These treatments offer a wide range of advantages, such
                            as reduction in joint pain and stiffness, enhancement of joint mobility, and general
                            relaxation.</p>
                        <h2>Greeva Basti Procedure</h2>
                        <p>In the first step of the process, the therapist creates a dough-like substance by combining
                            various medicinal powders and oils with herbal extracts.</p>
                        <ul>
                            <li><strong>Application:</strong> The prepared mixture is molded into a ring-shaped dam and
                                applied over the neck and upper back regions, enclosing the affected area. This
                                procedure is repeated until the desired effect is achieved.
                            </li>
                            <li>The reservoir is then filled with a heated therapeutic oil or herbal decoction, which
                                allows it to soak into and enter the damaged tissues. This process is known as "filling
                                the reservoir."
                            </li>
                            <li>The duration of the treatment is normally between thirty and forty minutes, during which
                                time the warm oil or concoction is replenished at regular intervals to maintain a
                                consistent temperature.
                            </li>
                            <li><strong>Massage:</strong> To maximize the benefits of the treatment, the therapist could
                                administer a light massage to the area around the client's neck and upper back at some
                                point throughout the session.
                            </li>
                            <li><strong>Dam removal:</strong> Once the treatment has been completed, the dam will be
                                removed safely, and any remaining oil will be wiped away from the treated area.
                            </li>
                            <li>After receiving Greeva Basti, you should try to get some rest for a bit and stay away
                                from draughts of chilly air as well as activities that put a strain on the area around
                                your neck and upper back.
                            </li>
                        </ul>
                        <h2>Kati Basti procedure</h2>
                        <p>In the process of preparation, a mixture that resembles dough is made by combining various
                            herbal powders with various medicinal oils.</p>
                        <ul>
                            <li><strong>Application:</strong> The substance is molded into a dam in the form of a ring
                                and then placed over the lower back area so that it completely encircles the problem
                                area.
                            </li>
                            <li>The reservoir is then filled with a heated therapeutic oil or herbal decoction, which
                                allows it to soak into and enter the damaged tissues. This process is known as "filling
                                the reservoir."
                            </li>
                            <li>The duration of the panchkarma kati basti treatment is normally between 30 and 40
                                minutes, during which time the warm oil or decoction is periodically topped off with
                                fresh supplies.
                            </li>
                            <li>During the treatment, the patient is instructed to relax and is free to lie down on
                                their stomach or sit in a posture that is most comfortable for them.
                            </li>
                            <li><strong>Massage:</strong> To maximize the beneficial benefits of the treatment, the
                                therapist could administer a light massage to the area of the lower back.
                            </li>
                            <li><strong>Dam removal:</strong> Once the treatment has been completed, the dam will be
                                removed safely, and any remaining oil will be wiped away from the treated area.
                            </li>
                            <li>Following treatment, it is recommended to lie down and get some rest for a bit.
                                Additionally, the region around the lower back should not be exposed to situations that
                                are cold or moist.
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h2>Janu Basti procedure</h2>
                        <p>In the process of preparation, a mixture that resembles dough is made by combining various
                            herbal powders with various medicinal oils.</p>
                        <ul>
                            <li>The application consists of shaping the mixture into a circular dam and positioning it
                                over the knee joint and the area that is afflicted around it.
                            </li>
                            <li>The reservoir is then filled with a heated therapeutic oil or herbal decoction, which is
                                then allowed to seep into and penetrate the knee joint. This process is referred to as
                                "filling the reservoir."
                            </li>
                            <li>The duration of the janu basti treatment is normally between 30 and 40 minutes, during
                                which time the warm oil or decoction is periodically topped off with fresh supplies.
                            </li>
                            <li>To achieve a state of relaxation, the individual receiving treatment may choose to sit
                                in a position that is most agreeable to them while the warm oil or concoction is applied
                                to the knee joint.
                            </li>
                            <li>Janu Basti massage: To maximize the health benefits of this treatment, the therapist may
                                do a light massage on the knee joint and the areas surrounding it along with janu basti
                                oil ingredients.&nbsp;
                            </li>
                        </ul>
                        <p>After the treatment, the dam is removed with care, and any excess oil or decoction is wiped
                            off the treated area. After this, the treated region is ready for the next step. 8.
                            Following treatment, it is advisable to refrain from engaging in an intense activity or
                            exposing the knee joint that was treated to cold draughts for the first several days after
                            receiving janu basti ayurvedic treatment. It is recommended that you get some rest and let
                            the joint soak up the beneficial effects of the treatment.</p>
                        <h2>Advantages of Using Greeva Basti</h2>
                        <ul>
                            <li>Pain relief for the neck: Greeva basti benefits from the pain, stiffness, and discomfort
                                in the neck that can be brought on by conditions such as cervical spondylosis, cervical
                                disc herniation, or muscle strain.
                            </li>
                            <li>Increased flexibility and range of motion in the neck region Regular Greeva Basti
                                treatments assist in enhancing flexibility and range of motion in the neck region, which
                                in turn helps reduce limitations and increase mobility in the neck region.
                            </li>
                            <li>The heated therapeutic oil or decoction that is used in the greeva vasti treatment,
                                relaxes the muscles of the neck, which in turn reduces the amount of tension in the
                                muscles and promotes a state of relaxation.
                            </li>
                            <li>Pain relief in the shoulder area greeva basti therapy has also been shown to be
                                effective in relieving pain and discomfort in the shoulder region, which may be the
                                result of difficulties with the neck or muscular imbalances.
                            </li>
                            <li>If you are in Delhi and looking to have a griva basti procedure near me, then connect
                                with Dr. Axico for greeva basti Therapy in Delhi at an Affordable cost.&nbsp;
                            </li>
                        </ul>
                        <h2>Advantages of utilizing Kati Basti</h2>
                        <p>Benefits of kati basti - kati basti ayurvedic treatment is helpful for persistent low back
                            pain, sciatica, slip disc, lumbar spondylosis, and muscular spasms in the lower back. Kati
                            Basti benefits from lower back discomfort.</p>
                        <ul>
                            <li>Increased spinal mobility and flexibility: The treatment assists in increasing the
                                flexibility and mobility of the spine, which in turn helps reduce stiffness and
                                encourages improved posture.
                            </li>
                            <li>Improved blood circulation: The practise of Kati Basti has been shown to enhance blood
                                circulation in the lower back region. This, in turn, makes it easier to transport
                                nutrients and oxygen to the affected area, which speeds up the recovery process.
                            </li>
                            <li>Relaxation of the muscles: The heated medicated oil or decoction that is utilized in
                                Kati Basti assists in relaxing the muscles of the lower back, which in turn reduces
                                tension and facilitates relaxation.
                            </li>
                            <li>If you are in Delhi and looking to have a kati basti treatment near me, then connect
                                with Dr. Axico for Kati basti Therapy in Delhi at an Affordable cost.
                            </li>
                        </ul>
                        <h2>Advantages of Using Janu Basti:</h2>
                        <p>Benefits of Janu Basti - Janu Basti is an excellent treatment for the pain, irritation, and
                            stiffness in the knee that can be brought on by disorders such as osteoarthritis, ligament
                            injuries, or meniscus tears.</p>
                        <ul>
                            <li>Increased joint mobility Consistent treatments with Janu Basti can help improve the
                                flexibility and range of motion in the knee joint, which in turn helps reduce
                                limitations and increase mobility.
                            </li>
                            <li>Lubrication of the joint: The warm medicated oil or decoction that is used in the
                                preparation of Janu Basti helps to lubricate the knee joint, which helps to reduce
                                friction and promote smooth movement.
                            </li>
                            <li>Janu basti benefits in reducing swelling and inflammation in the knee joint, which
                                provides relief from discomfort and promotes recovery. The reduction in swelling and
                                inflammation is one of the benefits of using this remedy.
                            </li>
                            <li>It is vital to speak with a trained Ayurvedic practitioner or therapist to establish
                                whether Greeva Basti, Kati Basti, or Janu Basti are appropriate for your health
                                situation and the specific demands you have in this regard. They will tailor the
                                treatment to your specific needs and provide you with the proper information to get the
                                most out of it.
                            </li>
                        </ul>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best Greeva Basti, Kati Basti, or Janu Basti treatment near me. Dr. Axico’s Ayurvedic
                            centres are available across the city with the best client service and affordable cost.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'abhyanga'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Abhyanga</h4>
                    </div>
                    <div class="card-body">
                        <p><strong>Sarvanga Abhyanga</strong> is a type of Ayurvedic oil massage therapy that involves
                            the use of heated herbal oils all over the body. Specifically, the treatment focuses on the
                            back, neck, and shoulders. This therapeutic massage, which has its roots in the ancient
                            Indian medical practice of Ayurveda, provides a wide range of advantages for the recipient's
                            mind, body, and spirit. Book your Panchakarma Therapy now and treat yourself with Sarvanga
                            Abhyanga with Dr. Axico&nbsp;</p>
                        <p>A holistic healing experience can be had through the practice of Abhyanga in Ayurveda, which
                            is carried out by qualified therapists using specialized procedures.</p>
                        <h2>Procedure</h2>
                        <p>The
                            therapist will begin by preparing a warm combination of herbal oils that is tailored to the
                            individual's physical make-up and the requirements of their health. When ready for
                            application, the oils are warmed to a bearable temperature.</p>
                        <ul>
                            <li><strong>Positioning:</strong> The person receiving the Abhyangam full body massage lies
                                face up on a massage table in a comfortable position, typically with a sheet or towel
                                draped over them. The therapist protects the client's confidentiality and works to keep
                                the atmosphere calm.
                            </li>
                            <li><strong>Application of oils:</strong> To begin, the therapist will apply the warm herbal
                                oils to the entire body methodically and rhythmically. This will include the therapist
                                massaging the warm oils into the scalp, face, neck, shoulders, arms, chest, belly, back,
                                legs, and feet.
                            </li>
                            <li><strong>Techniques:</strong> The masseuse works the oils into the skin and muscles by
                                applying light pressure and using a combination of soft strokes, circular motions, and
                                circling motions. Several different methods can be used, such as lengthy strokes,
                                kneading, tapping, and rhythmic movement patterns.
                            </li>
                        </ul>
                        <p>Relaxation occurs as a result of the panchakarma body massage therapist's ability to
                            cultivate a serene and comforting environment during the massage, which helps the recipient
                            relax, let go of tension, and shed stress.</p>
                        <p>Ayurvedic massage for body pain and other benefits, the recipient is given some time to relax
                            and take in the effects of the treatment before moving on to the post-massage phase. It is
                            advised to wait a certain amount of time after applying the oil before taking a
                            shower.</p>
                        <h2>Duration</h2>
                        <p>The duration of a session of Sarvanga Abhyanga
                            normally runs from sixty to ninety minutes, depending on the preferences and needs of the
                            individual receiving the treatment.</p>
                        <h2>Benefits of Sarvanga Abhyanga</h2>
                        <ul>
                            <li>The massage works to boost blood flow, which in turn increases the delivery of oxygen
                                and nutrients to the cells and tissues located throughout the body. This results in
                                improved circulation. It contributes to the overall health and sustenance of the body.
                            </li>
                            <li>Sarvanga Abhyanga helps relieve muscle tension, stiffness, and weariness, making it
                                beneficial for relaxing the muscles. The soothing strokes and kneading motions work to
                                relax the muscles, break up any knots that may be present, and soothe any discomfort
                                that may be present.
                            </li>
                            <li>Detoxification occurs as a result of the massage, which improves lymphatic drainage and
                                lends support to the body's naturally occurring detoxification processes. This has the
                                potential to improve one's health and well-being in general.
                            </li>
                            <li>Relieving tension, anxiety, and mental exhaustion Sarvanga Abhyangam's therapeutic touch
                                and rhythmic movements generate a deep sensation of relaxation, which helps relieve
                                stress, anxiety, and mental tiredness. It encourages a more relaxed state of mind as
                                well as an improvement in one's emotional well-being.
                            </li>
                            <li>Benefits to the health of the skin include an improved complexion as a result of the
                                herbal oils used in the massage, which nourish and hydrate the skin. It can assist in
                                the reduction of dryness, the improvement of skin tone, and the enhancement of the
                                overall health of the skin.
                            </li>
                            <li>Improved joint mobility: Sarvanga Abhyanga helps increase joint mobility by reducing
                                joint stiffness and promoting lubrication in the joints. This results in improved joint
                                flexibility as well. People who suffer from arthritis or other joint-related conditions
                                may gain some benefit from it.
                            </li>
                            <li>Better sleep quality can be achieved as a result of the Ayurvedic body massage’s ability
                                to generate relaxation and rejuvenation, which in turn can help improve sleep patterns
                                and foster restful sleep. People who struggle to fall asleep or who have other issues
                                with their sleep may find that it is very useful.
                            </li>
                            <li>Dosha balancing: According to Ayurveda, the practice of Sarvanga Abhyanga assists in
                                balancing the three doshas (Vata, Pitta, and Kapha) that reside in the body, fostering
                                total harmony and well-being.<br><br>&nbsp;
                            </li>
                        </ul>
                        <p><strong>Note</strong>: Prior to beginning any Ayurvedic Abhyanga massage treatment, including
                            Sarvanga Abhyanga, it is always recommended to speak with a skilled Ayurvedic practitioner
                            or therapist to ensure that the treatment is appropriate for your unique health condition
                            and constitution.</p>
                        <p><strong>Why Choose Dr. Axico -&nbsp;</strong>If you are in Delhi and keep looking for an
                            affordable Ayurvedic full body massage near me. Dr. Axico’s Ayurvedic body massage centers
                            are available across the city.&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'nasyam'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Nasyam</h4>
                    </div>
                    <div class="card-body">
                        <p>The preparation of nasal drops involves preparing medicinal oils or herbal juices
                            specifically for delivery through the nasal passages.<br>The individual is instructed to lie
                            down with their head tilted back, and a few drops of the prepared nasal drops are inserted
                            into each of their nostrils. This is known as the nasal administration method.<br>Inhalation
                            and massage: The therapist will massage the face and sinus regions before inhaling the nasal
                            drops to make it easier for the drops to be absorbed. The user is given the instruction to
                            take several deep breaths while inhaling the scent of the oils. 4. The duration of the
                            treatment is often somewhere between 15 and 30 minutes.<br>After receiving Nasyam treatment,
                            it is best to steer clear of draughts of cold air, dust, and strong smells for the rest of
                            the day. Further enhancement of the advantages can be achieved by taking a short break and
                            maintaining an elevated head position.</p>
                        <h2>Benefits</h2>
                        <p>Nasyam treatment helps to
                            clear the nasal passages, relieve pressure in the sinuses, and enhance respiratory function,
                            all of which are beneficial to sinus and respiratory health.<br>Relieving headaches and
                            migraines, as well as tension in the head and face, are some of the conditions that may
                            benefit from its use.<br>Increased olfactory sensitivity Nasyam therapy can improve
                            olfactory sensitivity, which may be advantageous for patients suffering from certain
                            illnesses, such as anosmia (loss of smell).<br>The use of herbal oils in the practice of
                            Nasyam helps to eliminate toxins from the head and neck area, which in turn promotes
                            detoxification on a more general level.<br>Relaxation and improved mental clarity: The
                            therapy has a relaxing impact on the mind, which helps improve mental clarity while also
                            lowering anxiety and stress.<br><br></p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'kizhi'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Kizhi(Potli)</h4>
                    </div>
                    <div class="card-body">
                        <p>Kizhi is an Ayurvedic treatment that involves massaging the body with heated poultices that
                            contain herbal powders, oils, or mixtures. To create boluses, the natural healing herbs are
                            mashed and wrapped in muslin linen. Warm poultices are applied to the body (or selected
                            areas) to massage and cause sudation. The application of the herbs causes the entire body or
                            certain areas to sweat. Today, locate an Ayurvedic Potli Massage Centre in Delhi. Book your
                            Panchkarma Therapy now and treat yourself with Kizhi Treatment with Dr.
                            Axico.&nbsp;&nbsp;</p>
                        <h2>Kizhi Panchakarma Procedure</h2>
                        <p>The individual's condition is taken into consideration when selecting the herbs and other
                            components, such as herbal powders, rice, medicinal leaves, and combinations thereof.</p>
                        <ul>
                            <li>To prepare an herbal bundle, the chosen components are first wrapped in cloth and then
                                tied into a bundle or poultice.
                            </li>
                            <li>The application of the bundle consists of gently massaging or patting the afflicted
                                parts or the entirety of the body with the herbal bundle after it has been heated in
                                medicinal oils.
                            </li>
                            <li>The treatment lasts for a period that is normally between thirty and sixty minutes, and
                                during this time the herbal bundle is continuously applied to the body.
                            </li>
                        </ul>
                        <p><strong>Post-treatment:</strong>
                            Following a Kizhi Ayurvedic session, it is recommended that
                            the patient take some time to relax and let their body process the positive effects of the
                            treatment. It's possible that taking a hot shower or bath will make the therapeutic effects
                            even stronger.</p>
                        <h2>Benefits of Kizhi massage Ayurveda:</h2>
                        <p>Kizhi Massage Benefits - Kizhi treatment in Ayurveda is a successful method for reducing
                            pain, inflammation, and stiffness in joints, muscles, and other specific body regions. This
                            results in pain relief.</p>
                        <ul>
                            <li>Increased blood flow: The use of heated herbal bundles has been shown to increase blood
                                flow by stimulating circulation, which in turn improves the delivery of oxygen and
                                nutrients to the tissues.
                            </li>
                            <li>Kizhi Treatment Back Pain and Muscles: Kizhi therapy benefits relieving muscle tension,
                                relaxing the muscles, and increasing flexibility. Kizhi Ayurveda helps relax the
                                muscles.
                            </li>
                            <li>Detoxification: The herbal substances that are included in the bundles help remove
                                toxins and waste materials from the body, which contributes to the detoxification
                                process.
                            </li>
                            <li>Improved joint mobility: Kizhi treatment benefits, when used regularly, can help improve
                                joint mobility, particularly in conditions such as arthritis and joint stiffness.
                            </li>
                            <li>Rejuvenation The treatment restores youth to the body, fosters a feeling of general
                                well-being and boosts the vitality of the individual as a whole.
                            </li>
                        </ul>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best kizhi massage near me. Dr. Axico’s Ayurvedic centres are available across the city with
                            the best client service and affordable Kizhi treatment costs.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'swedana'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Swedana</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>In Swedana, also known as Sudation Therapy, the patient is made to perspire. It directs amam,
                            or trapped toxins within the body, to areas from which the Pradhanakarma procedure can
                            easily expel them. After Pachana (Digestion Therapy) and Snehana (Oleation Therapy), it is
                            typically administered as the final stage of purvakarma. However, it also has value as a
                            stand-alone therapy. Panchakarma Swedana Ayurvedic Massage Therapy with Dr Axico Now!</p>
                        <p>Patients with conditions like asthma, problems with their weight and digestion, and
                            inflammation of the body are advised to try swedana, a therapy that cleans the body while
                            also reducing stress.&nbsp;</p>
                        <h2>The Swedana Panchakarma Procedure</h2>
                        <p>The first step in the preparation process for swedana Ayurveda is to apply herbal oils or
                            poultices all over the body or to certain parts of the body.</p>
                        <ul>
                            <li>Application of heat: Heat can be applied to the body in several different ways,
                                including the use of steam, hot herbal poultices, or hot massages using oil infused with
                                herbs.
                            </li>
                            <li>The length of time that the therapy session lasts can range anywhere from 15 to 30
                                minutes on average.
                            </li>
                            <li>The application of heat causes sweating, which helps the body rid itself of harmful
                                substances by facilitating the process of perspiration.
                            </li>
                        </ul>
                        <p>Following treatment with panchakarma swedana, it is important to get plenty of rest, maintain
                            a warm body temperature, and stay away from droughts of cold air and rapid shifts in
                            temperature.</p>
                        <h2>Nadi Swedana Benefits</h2>
                        <ul>
                            <li>Swedana massage therapy helps open up the pores and encourages sweating, which
                                facilitates the evacuation of toxins through the skin. This is how detoxification is
                                accomplished.
                            </li>
                            <li>Relaxation as well as stress reduction: The treatment brings about a state of
                                relaxation, eases muscle tension, and lowers both stress and anxiety levels.
                            </li>
                            <li>Swedana is known to improve blood circulation, which in turn encourages the delivery of
                                nutrients to the tissues and the elimination of waste items.
                            </li>
                            <li>Pain relief: The treatment has the potential to alleviate pain in the muscles, joints,
                                and even stiffness in the body.
                            </li>
                            <li>Joint mobility: Swedana treatment helps increase joint mobility and flexibility, which
                                is particularly good for disorders such as arthritis. Joint mobility and flexibility
                            </li>
                            <li>Skin health: The therapy has the potential to improve the complexion, encourage a
                                healthy glow, and treat a variety of skin diseases.
                            </li>
                            <li>Swedana therapy is beneficial for the relief of respiratory congestion, the promotion of
                                expectation, and the support of overall respiratory health.
                            </li>
                            <li>Swedana therapy is beneficial for the relaxing of muscles, the relief of muscle
                                stiffness, and the promotion of general healthy muscle function.
                            </li>
                            <li>Rejuvenation: Both the body and the mind experience a sensation of revitalization and
                                overall improvement as a result of the treatment.
                            </li>
                            <li>Enhanced digestion and metabolic function as a result of the stimulation of the
                                digestive fire (agni) that occurs during Swedish therapy.
                            </li>
                            <li>Reduction of tension and enhancement of relaxation are two benefits that can be attained
                                through the use of a treatment that has a sedative impact on the nervous system.
                            </li>
                            <li>Swedana therapy helps promote lymphatic circulation, which supports the body's
                                detoxification process and immune function. This results in increased lymphatic flow.
                            </li>
                            <li>Congestion relief: the therapy may assist in providing congestion relief in the nasal
                                passages, sinuses, and respiratory system.
                            </li>
                            <li>Clarity of thought and a sense of focus are two benefits that can be attained by Swedish
                                therapy, which can also strengthen mental clarity and improve attention.
                            </li>
                        </ul>
                        <p>Individual needs and the recommendations of an experienced Ayurvedic practitioner will
                            determine the precise procedures, length, and advantages of each Panchakarma therapy. It is
                            crucial to keep this in mind because the specific methods, duration, and benefits of each
                            Panchakarma therapy may differ. It is strongly recommended that these treatments be carried
                            out under the guidance of a trained professional to maximize both safety and efficacy.</p>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best Swedana Therapy near me. Experience ultimate relaxation with Abhyanga Swedana Therapy.
                            Rejuvenate your body and soul with this ancient healing technique. Book your session at Dr.
                            Axico!&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'lepam'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Lepam</h4>
                    </div>
                    <div class="card-body text-left">
                        <p><strong>Lepam, Mukh Lepam, and Navara Mukhalepam</strong> are all forms of Ayurvedic
                            treatment that are included in the Panchakarma program. Panchakarma is an all-encompassing
                            detoxification and regeneration regimen. In some of these treatments, herbal pastes or masks
                            are applied to certain areas of the body, most commonly the skin and the face.&nbsp;Book
                            your Panchkarma Therapy now and treat yourself with Lepam, Mukh Lepam, and Navara Mukhalepam
                            with Dr. Axico.&nbsp;</p>
                        <p>The Lepam Ayurvedic therapy concentrates on the body, while the Mukha Lepam in Ayurveda
                            method focuses on the face, and the Navara Mukh Lepam method is an individualized facial
                            treatment that makes use of the Navara rice paste. Lepam Ayurvedic therapy benefits provide
                            a wide range of good effects on the skin, encourages detoxification, and improves general
                            health and wellness.</p>
                        <h2>The procedure is as follows</h2>
                        <p>In the
                            preparation step, the therapist will mix several herbs and other natural components to make
                            an herbal paste or mask. The individual's dosha imbalance and their particular health
                            conditions will determine which herbs are recommended for them.</p>
                        <ul>
                            <li><strong>Application</strong>: Depending on the therapeutic goal, the herbal paste is
                                either applied locally to the problematic area or all over the patient's body in an even
                                layer. The paste is allowed to dry for a predetermined amount of time.
                            </li>
                            <li>Lepam therapy can last anywhere from 30 minutes to an hour, although the average session
                                lasts somewhere in the middle of that range. To increase the efficiency of the paste,
                                the therapist could cover it with a warm towel or a cotton cloth before applying it.
                            </li>
                            <li><strong>Removal</strong>: After the allotted amount of time has passed, the dried herbal
                                paste can be carefully removed by wiping it off with a soft cloth or by using warm
                                water. To maximize the positive effects of the therapy, the therapist could give you a
                                light massage.
                            </li>
                        </ul>
                        <p>Following treatment, it is important to stay out of the sun and away from temperatures that
                            are particularly high for the first several hours after receiving the treatment. You must
                            get some rest and give the skin time to absorb the herbal treatment so that it can be
                            effective.</p>
                        <h2>The Mukha Lepam procedure is as follows</h2>
                        <p>The
                            preparation consists of making an herbal paste or mask that is intended solely for use on
                            the face. The individual's skin type, the individual's doshic imbalance, and the intended
                            outcomes are taken into consideration while selecting the herbal constituents.</p>
                        <ul>
                            <li>The face is meticulously washed to get rid of any dirt, oil, or other impurities that
                                may have accumulated.
                            </li>
                            <li>The herbal paste is gently applied to the face, taking care to avoid getting it in the
                                eye and mouth areas.&nbsp;
                            </li>
                            <li>The paste is applied in an even layer, which ensures that the entire surface of the skin
                                is covered.
                            </li>
                            <li>The patient is instructed to rest and allow the herbal paste to dry for a predetermined
                                amount of time before proceeding with the next step.
                            </li>
                        </ul>
                        <p><br><strong>Duration</strong>: A typical session of Mukha Lepam Treatment lasts somewhere in
                            the neighborhood of half an hour.<br><strong>Removal</strong>: After the paste has had time
                            to dry, it can be removed carefully with a cotton towel or by using warm water. A light face
                            massage may be performed by the therapist using one of the appropriate oils or
                            lotions.<br><strong>Post-therapy</strong>: After the treatment, it is strongly advised to
                            limit the amount of direct sunlight and harsh chemicals that are allowed to come into
                            contact with the face. The herbal advantages are allowed to be absorbed by the skin, and the
                            skin is permitted to organically renew.</p>
                        <h2>The Navara Mukh Lepam procedure is as
                            follows</h2>
                        <p>In the preparation process, the Navara rice that will be used to
                            make the paste is first soaked in a special herbal infusion. After it has been steamed, the
                            rice is crushed into a very smooth paste.</p>
                        <ul>
                            <li><strong>Application:</strong> The Navara rice paste is smoothed onto the face in a
                                circular motion, covering the entire face from the forehead to the chin, including the
                                cheeks, nose, and nasal bridge.
                            </li>
                            <li><strong>Massage</strong>: The therapist applies the Navara rice paste to the client's
                                body and performs a gentle massage utilizing circular and upward movements. This makes
                                it easier for the skin to absorb the medicinal compounds of the herbs, which in turn
                                promotes relaxation.
                            </li>
                            <li><strong>Relaxation:</strong> After applying the Navara rice paste, the user is
                                instructed to relax and let the paste remain on their face for a predetermined amount of
                                time.
                            </li>
                            <li><strong>Duration:</strong> The average length of time required for a session of Navara
                                Mukhalepam therapy is between 30 and 45 minutes.
                            </li>
                            <li><strong>Removal</strong>: After the paste has had time to dry, it can be removed
                                carefully with a cotton towel or by using warm water. To nourish the skin, the therapist
                                could use a light moisturizer or an oil made from herbs.
                            </li>
                            <li><strong>Post-therapy:</strong> After the treatment, it is strongly advised to stay out
                                of direct sunlight as much as possible and to avoid applying any harsh chemicals to the
                                face. Because the skin is allowed to absorb the nourishing properties of the Navara rice
                                paste, the complexion is left looking vibrantly healthy and refreshed.
                            </li>
                        </ul>
                        <h2>Advantages of Using Lepam</h2>
                        <p>Lepam therapy promotes total detoxification
                            and purification by assisting in the evacuation of toxins from the body through the skin.
                            This contributes to the detoxification process.</p>
                        <ul>
                            <li>Lepam therapy uses herbal pastes that nourish and rejuvenate the skin by enhancing its
                                tone, texture, and elasticity. One of the benefits of this treatment is skin renewal.
                            </li>
                            <li>Skin disorders: Lepam may be helpful in the management of a variety of skin diseases,
                                including acne, eczema, psoriasis, and inflammation of the skin. The anti-inflammatory,
                                antibacterial, and therapeutic properties of the herbal constituents in the paste come
                                from their use on the skin.
                            </li>
                            <li>A more even and luminous skin tone can be achieved by the consistent use of Lepam
                                treatments, which also help to reduce pigmentation, blemishes, and dark spots on the
                                skin. This contributes to an improvement in the complexion.
                            </li>
                            <li>Lepam therapy has a soothing impact on the patient as a result of the application of the
                                herbal paste.&nbsp;
                            </li>
                            <li>This effect contributes to the patient's overall sense of relaxation and alleviates the
                                effects of stress.
                            </li>
                            <li>Support for the Joints and Muscles: Lepam therapy can help alleviate joint and muscle
                                discomfort by giving targeted relief and boosting circulation in the affected areas.
                                This can help alleviate the pain felt in the joint and the muscle.
                            </li>
                            <li>The use of herbal pastes helps to activate the lymphatic system, which in turn supports
                                the elimination of waste products and toxins from the body. This results in improved
                                lymphatic drainage.
                            </li>
                            <li>Hydrates and moisturizes the skin: The herbal elements in the paste helps to hydrate and
                                moisturize the skin, which helps to avoid dryness and promote a healthy skin barrier.
                            </li>
                        </ul>
                        <h2>Advantages of Using Mukha Lepam Ayurveda</h2>
                        <p>Mukhalepam Benefits -
                            Mukh Lepam is a very efficient cleanser that removes pollutants, excess oil, and debris from
                            the face's pores. This results in a deep cleansing of the skin.</p>
                        <ul>
                            <li>Skin brightening: Regular treatments with Mukh Lepam can improve the complexion by
                                reducing dullness and creating a brighter, more radiant skin tone. This effect can be
                                seen on the skin.
                            </li>
                            <li>Rejuvenates and nourishes the skin: The herbal paste that is used in the Mukh Lepam
                                therapy both rejuvenates and nourishes the facial skin. It does this by giving the skin
                                needed nutrients and fostering the look of youth.
                            </li>
                            <li>Purifies the skin and lessens the appearance of scars and blemishes. The herbal paste
                                contains therapeutic components that assist in cleansing the skin, lessening the
                                appearance of scars and blemishes, and minimizing acne.
                            </li>
                            <li>Anti-aging actions: Mukh Lepam can help minimize the indications of aging, such as fine
                                lines and wrinkles, by stimulating collagen formation and enhancing skin suppleness.
                                These effects are responsible for Mukh Lepam's anti-aging properties.
                            </li>
                            <li>Calms and soothes sensitive skin: The herbal paste that is used in Mukh Lepam contains
                                calming effects that can help calm sensitive or irritated skin, lowering redness and
                                inflammation. These benefits are achieved through the herbal paste's anti-inflammatory
                                properties.
                            </li>
                        </ul>
                        <h2>Advantages of Utilizing Navara Mukh Lepam</h2>
                        <p>Navara Mukhlepam Benefits
                            - Deep nourishment for the face's skin is provided by the Navara rice paste, which is rich
                            in nutrients and antioxidants. This promotes a healthy glow and helps the facial skin look
                            younger.</p>
                        <ul>
                            <li>The application of Navara rice paste can help enhance skin elasticity, minimize the
                                appearance of fine wrinkles, and promote a more youthful appearance. This is due to the
                                paste's anti-aging and firming benefits.
                            </li>
                            <li>Exfoliation that is not too harsh. The texture of the Navara rice paste gives
                                exfoliation that is not too harsh. This helps remove dead skin cells and promotes a
                                smoother complexion.
                            </li>
                            <li>Inducing a state of deep relaxation and tranquility, Navara Mukh Lepam Ayurveda is
                                effective in lowering stress levels and enhancing general well-being.
                            </li>
                            <li>Hydration of the skin: The natural components of Navara rice help to hydrate and
                                moisturize the skin, which contributes to an overall improvement in the skin's moisture
                                balance.
                            </li>
                        </ul>
                        <p>To determine if Lepam, Mukh Lepam, or Navara Mukh Lepam are appropriate for your specific
                            health state and requirements, it is essential to seek the advice of an experienced
                            Ayurvedic practitioner or therapist. They will tailor the treatment to your specific needs
                            and provide you with the proper information to get the most out of it.</p>
                        <p><strong>Why Choose Dr. Axico -&nbsp;</strong>If you are in Delhi and looking for an
                            affordable best Navara Mukh Lepam in Delhi. Dr. Axico’s Ayurvedic centres are available
                            across the city with best client service.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'pizhichil'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Pizhichil</h4>
                    </div>
                    <div class="card-body text-left">
                        <p><strong>Pizhichil</strong> is a classic Ayurvedic therapy that is used in the Panchakarma
                            treatment protocol. Warm medicated oil is massaged all over the body as part of the
                            Pizhichil treatment, which originates from the Ayurvedic practice of traditional Indian
                            medicine and dates back thousands of years. Book your Panchakarma Therapy now and treat
                            yourself with a Pizhichil message with Dr. Axico.</p>
                        <p>This one-of-a-kind treatment provides a variety of advantages for the patient's mind, body,
                            and spirit. Pizhichil is a revitalizing and therapeutic experience that is provided by
                            skilled therapists who use specialized techniques. Pizhichil therapy should be performed by
                            experienced therapists.</p>
                        <h2>Pizhichil Procedure</h2>
                        <p>The therapist
                            will prepare a unique concoction of herbal oils that are medicated with Ayurvedic herbs,
                            taking into account the individual's unique constitution and the specific requirements of
                            their health. When ready for application, the oils are warmed to a bearable temperature.</p>
                        <ul>
                            <li>The individual will be positioned such that they are lying on a unique hardwood Droni
                                table that has been created to allow the oils to flow and be collected. The table is
                                typically draped in a towel or covered with a sheet.
                            </li>
                            <li>The warm, medicated oil is applied to the body methodically and continuously by the
                                therapist, who begins at the top of the client's head and works their way down to the
                                lower extremities. The oil is poured in a steady manner, which allows it to move freely
                                across the surface of the skin and distribute itself evenly.
                            </li>
                            <li>A simultaneous massage is one in which the therapist uses their hands to give light
                                strokes of massage on the client's body at the same time that they are pouring the oil.
                                The coordinated and synchronized motion of the strokes results in an experience that is
                                both calming and pleasant.
                            </li>
                            <li>During the treatment, the therapist will cultivate a serene atmosphere, during which
                                they will encourage the patient to let go of built-up stress, practice deep relaxation,
                                and have an overall sensation of serenity and well-being.
                            </li>
                        </ul>
                        <p><strong>Post-therapy:</strong> Once the treatment has been completed, the patient is allowed
                            some time to relax and take in the effects of the pizhichil. Before taking a shower, it is
                            advised to wait some amount of time during which the remaining oil can remain on the body.
                        </p>
                        <p><strong>Duration:</strong> The length of time that a Pizhichil massage session lasts normally
                            runs anywhere from sixty to ninety minutes, depending on the requirements and preferences of
                            the individual. To achieve the best possible results from the treatment, it is typically
                            carried out over several days in a row.</p>
                        <h2>Pizhichil Ayurveda Treatment Benefits</h2>
                        <ul>
                            <li>Pizhichil in Ayurveda is well known for the revitalizing effects that it can have on its
                                users. The heated medicinal oil nourishes the body, revitalizes the tissues, and boosts
                                the general vitality and energy levels of the individual.
                            </li>
                            <li>The use of warm oil in conjunction with light, flowing strokes during a massage helps to
                                relax the muscles and remove the tension as well as stiffness and exhaustion in the
                                muscles. It facilitates relaxation, lessens the sensation of discomfort, and increases
                                flexibility.
                            </li>
                            <li>Pizhichil massage benefits detoxification because it assists in the elimination of
                                toxins from the body and supports the body's natural detoxification processes. It aids
                                in the elimination of toxins from the body, strengthens digestion, and promotes general
                                health and wellness.
                            </li>
                            <li>Relieving stress and mental fatigue: The treatment brings on a profound state of
                                relaxation and calmness, which aids in the reduction of tension as well as anxiety and
                                mental weariness. It encourages a tranquil and pleasant frame of mind in its recipients.
                            </li>
                            <li>Pizhichil treatment benefits for Skin care. The therapeutic oil that is used in
                                Pizhichil nourishes and moisturizes the skin, which results in an improvement in the
                                skin's texture as well as a radiant, healthy appearance. It can help relieve dryness,
                                regenerate the skin, and address specific conditions that affect the skin.
                            </li>
                            <li>People who have trouble with their joints or bones can benefit from taking pizhichil,
                                which is good for the joints and bones. Massage treatments using warm oil help to
                                enhance joint mobility, reduce inflammation, and provide relief from ailments like
                                arthritis.
                            </li>
                            <li>Dosha balancing According to Ayurveda, pizhichil is beneficial for the body because it
                                helps balance the three doshas (Vata, Pitta, and Kapha), which in turn promotes overall
                                harmony and well-being.
                            </li>
                            <li>Rejuvenation of the nervous system: The treatment has a calming impact on the nervous
                                system, which helps to improve general nervous system performance by reducing tension
                                and increasing relaxation.
                            </li>
                            <li>Pizhichil therapy makes use of warm medicinal oil, which helps improve blood circulation
                                throughout the body. This is one of the benefits of receiving this treatment. The cells
                                and tissues receive improved oxygenation and nutrition delivery as a result of this,
                                which is beneficial to their overall health and vitality.
                            </li>
                            <li>pizhichil benefits effect the hormonal balance if taken in sufficient amounts.
                                Individuals who struggle with hormonal imbalances or monthly irregularities might
                                benefit from the combination of heated oil and massage techniques because they help
                                regulate hormone production and improve circulation at the same time.
                            </li>
                            <li>Strengthens the immune system: The treatment assists in the elimination of toxins, the
                                enhancement of circulation, and the promotion of general well-being, all of which help
                                to improve the immune system. A healthier immune system can lead to a decreased
                                likelihood of contracting diseases as well as a more rapid rate of recovery from
                                illness.
                            </li>
                            <li>Effects on anti-aging: Pizhichil is well-known for the anti-aging characteristics that
                                it possesses. Because of its nourishing and renewing properties, the heated medicated
                                oil can assist in the reduction of indications of aging, such as wrinkles, fine lines,
                                and sagging skin, contributing to the look of a more youthful person.
                            </li>
                            <li>Warm oil, rhythmic pouring, and gentle massage strokes all work together to generate a
                                state of deep relaxation and tranquility, resulting in mental clarity and calmness. This
                                can assist in calming the mind, improving mental clarity, and promoting a general sense
                                of well-being in one's life.
                            </li>
                            <li>Pizhichil has been shown to alleviate the symptoms of several different medical
                                illnesses, including rheumatism, neuropathy, paralysis, and certain neurological
                                problems. A reduction in pain and inflammation, as well as an improvement in general
                                function, can be achieved by the application of massage techniques with heated oil.
                            </li>
                            <li>Pizhichil has been shown to have a sedative impact on the nervous system, making it
                                useful for the treatment of emotional imbalances such as anxiety, stress, and
                                depression. Emotional stability and a sense of contentment within oneself are
                                facilitated by the therapy.
                            </li>
                            <li>Pizhichil therapy uses a heated medicinal oil that profoundly nourishes the body by
                                delivering important nutrients to the body's tissues, muscles, and organs. This deep
                                feeding can only be achieved through the use of this oil. This food assists in the
                                restoration of vitality, makes overall health improvements, and increases the likelihood
                                of living a long and healthy life.
                            </li>
                        </ul>
                        <p>Overall health and wellness Pizhichil is a holistic therapy that not only treats a variety of
                            health conditions but also works to improve overall health and well-being. It creates a
                            sense of equilibrium, vitality, and inner harmony by bringing the mind, the body, and the
                            spirit into harmony with one another.<br><br>Pizhichil is a therapeutic Ayurvedic treatment
                            that offers a range of benefits to those seeking relaxation, rejuvenation, and improved
                            general health. Pizhichil can be performed by a trained practitioner. It is recommended that
                            you seek the advice of an Ayurvedic practitioner or therapist to assess whether or not
                            Pizhichil is appropriate for your unique requirements and current state of health.</p>
                        <p><strong>Why Choose Dr. Axico -&nbsp;</strong>If you are in Delhi and keep looking for an
                            affordable pizhichil treatment near me. Dr. Axico’s Ayurvedic Pizhichil treatment centres
                            are available across the city.&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'eye-treatments'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Eye Treatments</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>Netra Tarpana and Netra Sekam are Ayurvedic therapies that concentrate on eye care and
                            provide nourishment and regeneration to the eyes. Both of these treatments are called netra.
                            Panchakarma. Akshi Tarpana benefits is a comprehensive plan that focuses on detoxification
                            and rejuvenation of eye health, and these therapies are a part of it. Book your Panchkarma
                            Therapy now and treat yourself with Netra Tarpana and Netra Sekam with Dr. Axico.&nbsp;&nbsp;</p>
                        <p>While Netra Tarpana in ayurveda includes applying therapeutic ghee around the eyes, Netra
                            Sekam entails pouring an herbal decoction or medicated oil over the eyes. Netra Tarpana also
                            known as Akshi Tarpana Treatment and Netra Sekam are both considered eye treatments. These
                            therapies offer a wide range of benefits for eye health, including the reduction of eye
                            strain, the enhancement of visual acuity, and the promotion of general relaxation.</p>
                        <h2>The Netra Tarpana procedure is as follows</h2>
                        <p>The preparation of medicated ghee entails the processing of particular herbs and oils, which
                            are selected for each individual based on the health and condition of their eyes.</p>
                        <ul>
                            <li>The preparation of the eye region involves the formation of a dough-like dam around the
                                eyes using black gramme paste by the therapist to prevent the medicated ghee from
                                escaping.
                            </li>
                            <li>Application of medicated ghee: The medicated ghee is applied by being carefully poured
                                into the dam. This enables the ghee to surround the eyes and maintain contact with the
                                area around the eyes.
                            </li>
                            <li>Eye movement: The person being examined is given specific instructions to move their
                                eyes in a variety of directions while the ghee is kept in its current position. This
                                assists the ghee in penetrating the whole eye.
                            </li>
                            <li>The duration of the Netra Tarpana therapy is typically between 15 and 30 minutes, during
                                which time the medicinal ghee is maintained at a warm temperature and replaced as
                                required.
                            </li>
                        </ul>
                        <p><strong>Massage:</strong> Following the treatment, the therapist may administer a light
                            massage to the area around the eyes to further improve the advantages of the treatment.</p>
                        <p>The dough-like dam and any extra ghee are gently removed, and any residue that may still be
                            there is wiped away from the eye area. This concludes the removal of the ghee.</p>
                        <p><strong>Post-therapy:</strong> Following treatment with Netra Tarpana, it is strongly advised
                            to refrain from looking directly into bright lights or putting undue stress on the eyes. It
                            is recommended to give the eyes a rest and to create an atmosphere that is relaxing.</p>
                        <h2>Netra Seka Procedure</h2>
                        <p>An eye-nourishing herbal decoction or medicinal oil is created by employing particular herbs
                            and oils that are well-known for the nourishing effects they have on the eyes.</p>
                        <ul>
                            <li>Preparation of the eye region involves the patient lying down in a comfortable position
                                while a little dough structure in the shape of a well is formed around the patient's
                                eyes to hold the liquid.
                            </li>
                            <li>Pouring the decoction or oil: Either a heated herbal decoction or a therapeutic oil is
                                poured into the well that has been created around the patient's eyes gradually and
                                steadily.
                            </li>
                            <li>Eye movement and blinking: The person receiving the treatment is given specific
                                instructions to move their eyes and blink softly, which will allow the liquid to wash
                                over the entirety of the eye area.
                            </li>
                            <li>The Netra Sekam therapy normally lasts for around 15 to 30 minutes, during which time
                                the herbal decoction or oil is continually poured in a steady stream. The duration may
                                vary slightly from practitioner to practitioner.
                            </li>
                            <li>During the treatment, the patient is instructed to relax and keep their eyes closed to
                                maximize the effects of the therapy, which are intended to be calming and restorative.
                            </li>
                        </ul>
                        <p><strong>Eye cleansing:</strong> Once the treatment has been completed, the eyes will be
                            carefully wiped out with a clean, warm cloth to eliminate any leftover liquid.</p>
                        <p>Following treatment, it is important to give your eyes plenty of rest, stay away from bright
                            lights, and refrain from putting undue stress on them. Netra Seka benefits and is
                            recommended to create an atmosphere that is relaxing and calming for the eyes.</p>
                        <h2>Benefits of Netra Tarpana</h2>
                        <p>Netra Tarpana Benefits - Relieves eye strain and fatigue: The Netra Tarpana therapy benefits
                            relieve eye strain caused by spending an excessive amount of time in front of a screen,
                            reading, or otherwise using the eyes for an extended period.</p>
                        <ul>
                            <li>Eyes receive sustenance and lubrication: The medicinal ghee that is used in Netra
                                Tarpana provides nourishment and lubrication to the eyes, which helps to reduce dryness
                                and promote overall eye health.
                            </li>
                            <li>Enhances eyesight: Regular treatments with Netra Tarpana can help enhance vision,
                                particularly in cases of dry eyes, fuzzy vision, or eye tiredness.
                            </li>
                            <li>Netra Tarpan therapy contains anti-inflammatory characteristics that help reduce eye
                                inflammation and redness, offering relief from conditions such as conjunctivitis or eye
                                allergies. These benefits are made possible as a result of the therapy's ability to
                                reduce eye inflammation and redness. 5. Eases dryness and discomfort in the eyes. The
                                hydrating and soothing characteristics of the medicinal ghee found in Netra Tarpana
                                Ayurvedic therapy work to ease dryness, irritation, and discomfort in the eyes.
                            </li>
                            <li>Improves eye coordination and reduces eye strain. Regular therapy can assist in
                                improving eye coordination by improving the strength of the eye muscles, which in turn
                                reduces eye strain.
                            </li>
                            <li>Netra Tarpana Ayurvedic Therapy creates a state of profound relaxation, which in turn
                                lowers levels of stress and improves overall well-being. Relaxation and stress
                                alleviation are two benefits of using this product.
                            </li>
                        </ul>
                        <h2>Advantages of Using Netra Sekam</h2>
                        <p>Relieves tiredness and revitalizes the eye tissues. The herbal decoction or medicinal oil
                            that is used in the Netra Sekam therapy has a calming effect on the eyes, which helps to
                            eliminate eye strain and revitalize the eye tissues.</p>
                        <ul>
                            <li>Eyes are made cleaner because the herbal decoction or oil is poured into them, which
                                helps eliminate dirt, dust, and other particles that have accumulated in the eyes over
                                time. This improves cleanliness and lowers the risk of infection.
                            </li>
                            <li>Netra Sekam therapy offers moisture and lubrication to the eyes, reducing dryness and
                                encouraging optimal eye health. This is accomplished by moisturizing and lubricating the
                                eyeballs.
                            </li>
                            <li>Increases in visual acuity Receiving regular Netra Seka treatments can increase visual
                                acuity by relieving strain on the eye, flushing out pollutants, and nourishing the eye
                                tissues.
                            </li>
                            <li>Netra Seka health benefits: It is effective for lowering eye irritation, redness, and
                                discomfort caused by factors such as environmental pollution or excessive screen time.
                                This benefit includes relieving eye irritation produced by these variables.
                            </li>
                            <li>The herbal decoction or oil that is used in the preparation of Netra Sekam has a cooling
                                impact on the eyes, which provides relief from the sensations of burning or itching that
                                may be present.
                            </li>
                            <li>Netra Sekam supports general eye health and rejuvenation by nourishing the eye tissues,
                                boosting circulation, and reducing oxidative stress. These are the three primary ways in
                                which it works to rejuvenate the eye.
                            </li>
                        </ul>
                        <p>Before undergoing either the Netra Tarpana or the Netra Sekam, it is essential to discuss
                            your treatment options with a trained Ayurvedic practitioner or therapist. They will
                            evaluate the state of your eyes and decide which herbs, oils, or concoctions are best suited
                            to meet your specific requirements after doing an examination. They will also advise you on
                            the appropriate timing of treatments and any necessary preventative measures to take.</p>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best netra seka or netra tarpan therapy near me. Dr. Axico’s Ayurvedic centres are available
                            across the city with the best client service and affordable cost.&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'udavartana'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Udavartana</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>The ayurvedic treatments of&nbsp;<strong>Udavartana and Ubtan</strong> are both included in
                            the whole Panchakarma programme. While udavartana includes giving the body a vigorous dry
                            massage with the use of herbal powders, ubtan refers to the practise of applying a herbal
                            paste all over the body. Both of these treatments have a long history of use and have been
                            shown to facilitate detoxification, enhance overall well-being, and improve skin health.
                            Ubtan nourishes the skin, exfoliates dead cells, and enhances its radiance, whereas
                            Udavartana helps manage weight, boosts metabolism, and stimulates the lymphatic system. Both
                            of these effects contribute to better overall health.</p>
                        <h2>The procedure is known as
                            Udavartana.</h2>
                        <p>Choice of herbal powders The therapist will choose particular herbal
                            powders for the individual based on the individual's bodily constitution and current state
                            of health.<br>Dry massage: The client is instructed to lie face down on a massage table, and
                            the therapist applies pressure to certain areas of the body with herbal powders while doing
                            a deep massage. The massage is performed in an upward direction, which is counter to the
                            natural pattern of hair development.<br>Strokes that are firm and vigorous: The therapist
                            will use the herbal powders to give you strokes that are firm and vigorous in order to
                            enhance lymphatic drainage, break down cellulite, and stimulate circulation.<br>The duration
                            of an udavartana therapy session is typically between 30 and 45 minutes; however, this might
                            vary widely depending on the particular requirements of the patient.<br>After the massage,
                            any surplus herbal powders are removed from the body by lightly brushing them with a
                            brush.<br>Following treatment, it is essential that you take some downtime to rest after
                            completing Udavartana so that your body may fully benefit from the therapeutic effects of
                            the treatment. It is also recommended to stay away from draughts of cold air and to take a
                            shower in warm water to eliminate any powders that might be left behind.</p>
                        <h2> The procedure is as follows:</h2>
                        <p>The preparation of herbal paste involves combining a mixture
                            of Ayurvedic herbs, grains, and natural components, such as chickpea flour, turmeric,
                            sandalwood powder, and rosewater, to make the paste.<br>Application of the paste: Depending
                            on the desired impact, the herbal paste can be spread all over the body or on specific areas
                            of the body. The paste is worked into the skin with a light, soothing massage by the
                            therapist.<br>Scrubbing: The therapist may use a light scrubbing motion to help remove dead
                            skin cells and improve blood circulation during the treatment.<br>Ubtan therapy typically
                            lasts anywhere from thirty to forty-five minutes, during which time the herbal paste is
                            given time to dry and absorb into the skin.<br>The removal of the paste consists of either
                            washing it off with warm water or gently rubbing it off using circular motions once the
                            paste has had sufficient time to completely dry.<br>Moisturization: Once the paste has been
                            removed, the therapist may apply a moisturising oil or lotion to the skin in order to
                            nourish and hydrate it.<br>Following treatment with Ubtan, it is recommended that the
                            patient take some time off to recover and refrain from exposing their skin to harsh
                            chemicals or temperatures for an extended period of time. It is recommended to consume a lot
                            of water in order to maintain the skin's hydration level.</p>
                        <h2>Advantages of Using Udavartana</h2>
                        <p>Udavartana helps detoxify the body by stimulating the lymphatic system,
                            which in turn makes it easier for the body to get rid of harmful toxins and waste
                            products.<br>Weight management: In Udavartana, the intense massage and the stimulation of
                            the metabolism can help remove excess body fat and cellulite, which can be beneficial for
                            managing one's weight.<br>Increased blood flow: The massage strokes used in Udavartana
                            increase blood flow throughout the body, leading to increased nutrition delivery to cells
                            and an overall improvement in vitality.<br>The herbal powders that are used in Udavartana
                            work as natural exfoliants to remove dead skin cells and promote a radiant complexion. This
                            process is known as exfoliation.<br>Udavartana helps to reduce body smell by getting rid of
                            pollutants and bringing the activity of sweat glands back into balance. This is how it
                            accomplishes this.<br>Deep relaxation as well as a reduction in stress and anxiety are two
                            benefits that can be gained through the massage techniques that are utilised in Udavartana.
                            These techniques aim to release muscle tension, induce deep relaxation, and lower both
                            stress and anxiety levels.
                        <h2> Advantages of Using Ubtan:</h2>
                        <p>Nourishment of the skin:
                            The Ubtan therapy gives the skin profound nourishment, which improves the skin's overall
                            health and luminosity.<br>Exfoliation: The light scouring action of Ubtan assists in the
                            removal of dead skin cells, the unclogging of pores, and the improvement of skin
                            texture.<br>Ubtan contains natural elements such as turmeric and sandalwood, both of which
                            have been shown to have the ability to brighten the skin, which ultimately leads to a more
                            even complexion.<br>Detoxification The herbal components of Ubtan assist in the removal of
                            toxins from the skin, which in turn promotes detoxification and a radiantly healthy
                            appearance.<br>Hydration and moisturization: Ubtan frequently includes components that
                            hydrate and moisturise the skin, leaving it feeling supple, nourished, and soft after
                            use.<br>Ubtan has been shown to have anti-ageing properties, namely in that it can help
                            minimise the appearance of fine lines, wrinkles, and age spots, resulting in skin that has
                            the appearance of being younger.<br>Relaxing and soothing: Because applying ubtan has a
                            relaxing and soothing effect on the skin, it is beneficial for people who have sensitive
                            skin or skin that is easily inflamed.<br><br>Duration and Frequency: The length of time
                            required for Udavartana and Ubtan therapy, as well as the number of sessions required, might
                            change based on an individual's specific requirements and the suggestions of an Ayurvedic
                            specialist. In most cases, it is recommended to participate in a series of multiple sessions
                            in order to reap all of the benefits. The time allotted for each session is somewhere
                            between 30 and 45 minutes on average. Depending on the specific needs of the individual, the
                            frequency of sessions can range anywhere from once a week to once a month.<br><br>Before
                            pursuing Udavartana or Ubtan therapy, it is absolutely necessary to discuss the treatment
                            with a knowledgeable Ayurvedic practitioner or therapist. They will evaluate your unique
                            requirements, advise you on the herbal powders or pastes that are most appropriate for you,
                            and help you through the length, frequency, and post-therapy care of the treatment.<br>&nbsp;
                        </p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'thalapothichil'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Thalapothichil</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>The preparation of herbal paste involves combining various herbs and medicinal powders with a
                            suitable foundation, such as coconut milk or herbal oils. This results in the production of
                            an herbal paste.<br>Application of the paste The medicinal herb paste is spread out in an
                            even layer on the scalp, making sure to cover the entire head as well as the hair.<br>The
                            paste is allowed to remain on the scalp for the allotted amount of time, which is usually
                            somewhere between 30 and 45 minutes.<br>The paste has a calming effect since it dries out
                            the scalp while also cooling it, and it does both of these things as it&apos;s being
                            applied.<br>The dried paste is then carefully scraped off the scalp using warm water or
                            herbal decoctions. This completes the removal process.<br>Post-treatment: After receiving
                            Thalapothichil, it is recommended to take some downtime in order to let the advantages of
                            the treatment sink into the scalp. It is advisable to stay away from draughts of chilly air
                            and to wear something to shield one&apos;s head from direct
                            sunlight.</p>
                        <h2>Benefits:</h2>
                        <p> Nourishment of the scalp and the hair: Because
                            Thalapothichil nourishes both the scalp and the hair follicles, it encourages the growth of
                            healthy hair and improves the conditions of the scalp.<br>The use of the herbal paste has a
                            cooling effect on the scalp, which provides relief from heat-related concerns such as
                            excessive sweating or sensations of burning on the scalp.<br>Reduces tension and anxiety by
                            inducing a state of calm. Thalapothichil is responsible for this effect.<br>The therapy can
                            enhance the quality of sleep and reduce sleep-related issues, both of which can contribute
                            to improved sleep.<br><br></p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'pichu'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Pichu</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>Popular among ayurveda acharyas is the Pichu ayurvedic treatment. The vata-related disorders
                            of the cranial nerves, the spine, and the head are all effectively treated with pichu. Warm
                            medicinal oil is externally applied to the head during this procedure. Book your Panchkarma
                            Therapy now and treat yourself with Pichu Therapy with Dr. Axico.&nbsp;&nbsp;</p>
                        <p>It is done to a nearby, impacted area. Pichu can be applied to a person's head and spine as
                            the primary treatment or a simpler procedure can be performed using similar ayurvedic oil
                            treatments.</p>
                        <h2>The Protocol of Kati Pichu Panchakarma</h2>
                        <p>Soaking cotton pads or pieces of
                            cloth in warm medicinal oils Cotton pads or pieces of cloth are soaked in heated medicated
                            oils.</p>
                        <ul>
                            <li>The application of pads involves positioning the wet pads so that they cover the
                                affected area or other parts of the body that require therapy.
                            </li>
                            <li>The pads are left in place for a predetermined amount of time, which is often somewhere
                                between 30 and 45 minutes.
                            </li>
                            <li>Reapplication: In certain circumstances, the therapist may decide to reapply warm oil on
                                the pads at various points throughout the session to keep the pads warm and to preserve
                                the therapeutic impact.
                            </li>
                            <li>Removal of pads After the allotted amount of time has passed, the pads are removed, and
                                any extra oil is removed using a light rubbing motion.
                            </li>
                        </ul>
                        <p>Following treatment, it is recommended to get some rest to give the body time to process the
                            positive effects of Pichu therapy. The treated area must be shielded from direct sunlight
                            and protected from extreme temperatures. Additionally, avoiding exposure to cold draughts is
                            important.</p>
                        <h2>Pichu Therapy Benefits</h2>
                        <ul>
                            <li><strong>Pain reduction:</strong> Pichu treatment in ayurveda is effective for reducing
                                pain and inflammation in specific locations, such as joints, muscles, or injuries that
                                are localized. This can be accomplished by providing pain relief.
                            </li>
                            <li>Circulation is enhanced as a result of the application of warm oil, which in turn
                                promotes the delivery of nutrients and the removal of waste items from the afflicted
                                area.
                            </li>
                            <li><strong>Joint mobility:</strong> Pichu massage can help increase joint mobility and
                                flexibility, particularly in patients who suffer from disorders such as arthritis or
                                joint stiffness.
                            </li>
                            <li><strong>Relaxation and a decrease in stress:</strong> The warmth and light pressure used
                                in the Pichu ayurveda treatment bring about relaxation, which in turn decreases the
                                amount of muscle tension and relieves stress.
                            </li>
                        </ul>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best Shiro Pichu therapy near me. Dr. Axico’s Ayurvedic centres are available across the
                            city with the best client service and affordable Pichu therapy costs.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'vamana'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Vamana</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>Doshas (waste products or poisons) are expelled through the upper channels, or mouth, during
                            vamana. Particularly, the Kapha and Pitta Dosha were transported to the Amashaya (stomach
                            and duodenum) from all over the body by the particular preoperative treatments and were
                            subsequently removed by generating emesis. Book your Panchkarma Therapy now and treat
                            yourself with Vamana Treatment with Dr. Axico.&nbsp;&nbsp;</p>
                        <h2>Vamana panchakarma Therapy Procedure:</h2>
                        <p><strong>Vaman process in ayurveda:</strong> Ingestion of medicated ghee or oils is the first
                            step in the Vamana preparation process. This step helps the body get ready for the
                            elimination process that comes next.</p>
                        <ul>
                            <li>Vomiting under medical supervision involves administering a certain herbal concoction or
                                drug to the patient after the preliminary phase. This causes the patient to throw up.
                                This procedure is carried out under the direction and watchful eye of a knowledgeable
                                Ayurvedic practitioner.
                            </li>
                            <li>In the process of elimination, the individual has their vomiting under control to
                                evacuate the poisons and extra doshas from their stomach and upper gastrointestinal
                                system.
                            </li>
                            <li>Following the completion of the Vamana treatment, the patient must adhere to a
                                predetermined diet and way of life to facilitate the body's healing process and prolong
                                the effects of the treatment.
                            </li>
                        </ul>
                        <h2>Ayurveda Vamana Benefits</h2>
                        <p>Vamana therapy benefits detoxification since it helps remove toxins, extra mucus, and doshas
                            that are out of balance in the body.</p>
                        <ul>
                            <li><strong>Purifying the Respiratory System:</strong> This treatment can help clear the
                                respiratory passageways, which in turn can improve lung function and reduce the severity
                                of respiratory conditions.
                            </li>
                            <li><strong>Improved digestion:</strong> The Vamana therapy works to enhance digestion as
                                well as metabolism, which helps to ensure that nutrients are assimilated correctly.
                            </li>
                            <li><strong>Vamana therapy for weight loss:</strong> It can be good for weight management by
                                eliminating excess toxins and encouraging healthy metabolism. Weight management is a
                                common concern among people today.
                            </li>
                            <li>Individuals who suffer from persistent respiratory problems like asthma, bronchitis, or
                                allergies may find relief from their symptoms through the practise of vamana treatment.
                            </li>
                            <li>Vamana therapy's effects on detoxification and purification can lead to clearer and
                                healthier skin, which is one of the many benefits of receiving this treatment.
                            </li>
                            <li><strong>Dosha balancing:&nbsp;</strong>Vaman Ayurveda helps balance the doshas,
                                particularly the Kapha dosha, which is responsible for the congestion, excess mucus, and
                                other health difficulties associated with these conditions.
                            </li>
                        </ul>
                        <p>It is thought that the therapy has a beneficial effect on patients' mental clarity and
                            emotional well-being, as well as their general sense of well-being.</p>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best Vamana therapy near me. Dr. Axico’s Ayurvedic centres are available across the city
                            with the best client service and affordable cost.&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'virechana'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Virechana</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>Virechana is the administration of purgatives for the lower routes' purification of pitta. 3
                            days after vamana, administer virechana. Give virechana if vamana is not advised for a
                            specific patient. In either scenario, three days of internal oleation are required, ideally
                            including both snehana (oleation) and svedana (sweating), both of which are purva karma.
                            Blood toxins, sweat glands, kidneys, stomach, small intestine, colon, liver, spleen, and
                            rakta vaha srotas are all cleansed by virechana. Book your Panchkarma Therapy now and treat
                            yourself with Virechan Treatment with Dr. Axico.&nbsp;&nbsp;</p>
                        <h2>Virechana procedure</h2>
                        <p>Virechana process: In the first step of the virechana ayurvedic treatment, the patient
                            consumes medicinal ghee or oils to get their body ready for the next step, which is the
                            elimination process.</p>
                        <ul>
                            <li>After the preliminary phase, the individual is given a certain herbal mixture or
                                substance to produce controlled purgation. This occurs after the phase known as "induced
                                purgation." This procedure is carried out under the direction and watchful eye of a
                                knowledgeable Ayurvedic practitioner.
                            </li>
                            <li>In the process of elimination, the person has a bowel movement that is under their
                                control to expel toxins, excess bile, and doshas from their body.
                            </li>
                        </ul>
                        <p><strong>Post-therapy:</strong> Following Virechana Panchakarma, it is essential to follow a
                            certain diet and lifestyle regimen to assist the body in its recovery and to continue to
                            reap the benefits of the therapeutic intervention.</p>
                        <h2>Virechana Benefits</h2>
                        <p>Virechana Treatment Benefits: Virechana therapy, a form of detoxification, helps remove
                            toxins, excess bile, and doshas that are out of balance from the body, hence encouraging
                            general detoxification.</p>
                        <ul>
                            <li>The treatment enhances digestion, metabolic rate, and the removal of waste items, which
                                all contribute to better digestive health and support normal digestive function.
                            </li>
                            <li>Virechana in Ayurveda is a helpful treatment for a variety of skin conditions, including
                                acne, eczema, psoriasis, and allergic rashes.
                            </li>
                            <li>The health of the liver is promoted by the treatment, which also contributes to the
                                organ's processes of detoxification and regeneration.
                            </li>
                            <li>Virechana therapy helps balance the doshas, in particular the Pitta dosha, which is
                                involved with digestion, metabolism, and the function of the liver.
                            </li>
                            <li>Increased vitality and energy levels Virechana therapy can accomplish this by flushing
                                the body of toxins and improving the functioning of the digestive system.
                            </li>
                        </ul>
                        <p>It is considered that the therapy has a favorable impact on mental clarity, emotional
                            equilibrium, and general well-being. This includes the well-being of the patient's mental
                            and emotional states.</p>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best virechana treatment near me. Dr. Axico’s Ayurvedic centres are available across the
                            city with the best client service and affordable cost.&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'basti'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Basti</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>The traditional Indian medical system known as Ayurveda holds that ailments and health risks
                            are brought on by a dosha imbalance. It places a strong emphasis on achieving the correct
                            balance between one's body, mind, and spirit through natural means and lifestyle
                            adjustments. One of the most crucial elements of Ayurvedic Panchakarma therapy is Basti.</p>
                        <h2>Basti Procedure</h2>
                        <p>Ayurvedic doctors use the Basti treatment to inject medicinal oils into the body through the
                            anal channel. The beneficial effects of both the carrier oil and medications are absorbed in
                            the intestine.</p>
                        <h2>The steps of Basti in Ayurveda are listed below in detail</h2>
                        <p> Step 1: The doctor instructs the patient to eat foods that are
                            suitable for the illnesses he is experiencing.</p>
                        <p> Step 2: An ayurvedic practitioner uses the appropriate oil to
                            perform an abhyanga massage on the patient. The patient also has a light sedative around his
                            waist.</p>
                        <p> Step 3: The third step entails having the patient lie
                            comfortably in the left lateral position. He must extend his left leg and bend his right leg
                            towards him. He must also maintain his left hand underneath his head.</p>
                        <p> Step 4: The patient's anal area is lubricated by the Ayurvedic
                            practitioner or his assistant.</p>
                        <p> Step 5: A soft rubber catheter is inserted into the patient's
                            anus by the expert</p>
                        <p> Step 6: Using an enema pot or syringe, the medicinal oil is
                            injected into his rectum.</p>
                        <p> Step 7: The Ayurvedic doctor instructs his patient to rigorously
                            adhere to the diet that has been made for him once the Basti treatment is complete.</p>
                        <p>After listing the benefits of the Basti treatment, let's move on to its disadvantages.</p>
                        <h2>Benefits of Basti Ayurveda Therapy</h2>
                        <p>The following are some of the many advantages of the Basti therapy:</p>
                        <p> Abdominal and systemic Vata diseases are successfully treated
                            with Basti treatment.</p>
                        <p> Chronic constipation, sciatica, low back pain, arthritis, gout,
                            and other health disorders can all be successfully treated with this method.</p>
                        <p> It aids in the body's internal nutrient supply.</p>
                        <p> The Basti procedure raises immunity.</p>
                        <p> It increases one's physical and mental toughness.</p>
                        <p> This procedure has anti-aging advantages.</p>
                        <p> Basti in Ayurveda enhances the quality of semen and eggs in
                            addition to revitalizing the body.</p>
                        <p> It rids a person's body of the doshas.</p>
                        <p> Another significant advantage is the improvement of skin tone
                            and texture.</p>
                        <p><strong>Why Choose Dr. Axico</strong> - If you are in Delhi and looking for an affordable
                            best Basti Therapy near me. Experience ultimate relaxation with and rejuvenate your body and
                            soul with this ancient healing technique. Book your session at Dr. Axico!&nbsp;</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'janu-basti'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Janu basti</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>Janu Basti is an Ayurvedic therapy that focuses on providing relief and nourishment to the
                            knee joints. In this treatment, a circular well-like structure is created around the knee
                            using dough made from black gram or wheat flour. Warm medicated oil is then poured into this
                            reservoir and allowed to remain for a specific duration. The oil is absorbed through the
                            skin and penetrates deeply into the joint, lubricating it and alleviating pain, stiffness,
                            and inflammation. Janu Basti is highly beneficial for individuals suffering from
                            knee-related ailments such as osteoarthritis, knee pain, or sports injuries. It helps
                            improve mobility, strengthens the knee joint, and promotes overall joint health.</p>
                        <h2>Procedure</h2>
                        <p>Preparation: The therapist prepares the dough made from black gram or wheat flour, shaping it
                            into a circular well around the knee joint.</p>
                        <p>Positioning: The patient is comfortably positioned on a massage table, lying on their
                            back.</p>
                        <p>Application: The prepared dough structure is placed over the knee joint, ensuring a tight
                            seal to prevent oil leakage.</p>
                        <p>Medicated Oil: Warm medicated oil, such as sesame oil or a specific herbal oil, is slowly
                            poured into the well around the knee joint.</p>
                        <p>Retention: The oil is allowed to remain within the dough well for a specified duration,
                            typically around 20-30 minutes.</p>
                        <p>Gentle Massage: While the oil is retained, the therapist may perform gentle massage and
                            kneading around the knee area to enhance the therapeutic effect.</p>
                        <p>Oil Removal: After the designated time, the oil is carefully drained and collected.</p>
                        <p>Repeat: The procedure may be repeated on the other knee if needed.</p>
                        <p>Rest: Following the treatment, the patient is advised to rest for some time to allow the
                            benefits of the therapy to settle in.</p>
                        <h2>Duration</h2>
                        <p>The duration of Janu Basti can vary depending on the specific needs of the individual and the
                            recommendation of the Ayurvedic practitioner. Generally, the therapy session lasts for about
                            20 to 30 minutes. However, the duration may be adjusted based on factors such as the
                            severity of the condition, the response of the patient, and the specific treatment plan
                            prescribed by the practitioner. It&apos;s important to consult with a qualified Ayurvedic
                            practitioner who can assess your condition and determine the appropriate duration for Janu
                            Basti to ensure optimal results.</p>
                        <p><br></p>
                        <h2>Note</h2>
                        <p>It's important to note that Janu Basti should be performed by trained Ayurvedic professionals
                            in a controlled and sterile environment for safe and effective results.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'kati-basti'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>kati Basti</h4>
                    </div>
                    <div class="card-body text-left">
                        <p>Kati Basti is a specialized Ayurvedic therapy that targets the lower back region,
                            specifically the area around the spine. Derived from the Sanskrit words &quot;kati&quot;
                            meaning waist or lower back and &quot;basti&quot; meaning container or retaining, Kati Basti
                            involves creating a small reservoir of herbalized oil or medicated paste on the lower back.&nbsp;</p>
                        <p>This therapeutic technique aims to alleviate pain, stiffness, and discomfort in the lumbar
                            region. During a Kati Basti session, a dough dam is created on the back using black gram
                            paste, which is then filled with warm herbal oil. The oil is retained in the dam for a
                            specific duration, allowing the therapeutic properties to penetrate deeply into the tissues,
                            muscles, and joints. This helps to nourish and strengthen the lower back, improve
                            circulation, reduce inflammation, and relieve tension. Kati Basti is commonly recommended
                            for conditions such as lower back pain, sciatica, herniated discs, and muscle spasms. It is
                            performed by trained Ayurvedic practitioners and can provide significant relief and support
                            for those suffering from lower back issues.</p>
                        <h2>Procedure</h2>
                        <p>Preparation: The individual is positioned comfortably on a massage table in a face-down
                            position.</p>
                        <p>Oil Selection: The therapist selects a suitable herbal oil based on the individual&apos;s
                            specific needs and condition.</p>
                        <p>Dough Dam Preparation: A dough is prepared using black gram paste and shaped into a circular
                            ring or dam. It is then placed around the lower back, encircling the affected area.</p>
                        <p>Oil Application: Warm herbal oil is heated to a comfortable temperature and poured into the
                            created reservoir within the dough dam.</p>
                        <p>Retention Time: The oil is kept in place for a specific duration, usually ranging from 20 to
                            30 minutes, allowing the therapeutic properties to penetrate deeply into the lower back.</p>
                        <p>Gentle Massage: During the retention period, the therapist may perform gentle massage strokes
                            on the back to enhance relaxation and promote better absorption of the oil.</p>
                        <p>Oil Removal: After the designated time, the oil is carefully drained out of the dam and
                            collected in a container.</p>

                        <p>Post-Procedure Rest: The individual is advised to rest for a short period to allow the body
                            to absorb the therapeutic effects of the treatment.</p>

                        <h2>Duration</h2>
                        <p>The duration of Kati Basti can vary depending on various factors, including the individual&apos;s
                            condition, the severity of symptoms, and the recommendation of the Ayurvedic practitioner.
                            Generally, a single session of Kati Basti typically lasts for about 20 to 30 minutes.
                            However, the overall treatment plan may involve multiple sessions performed over a specific
                            period of time. The frequency and duration of the therapy sessions will be determined by the
                            practitioner based on the individual&apos;s response to treatment and the desired
                            therapeutic outcome. It is important to consult with a qualified Ayurvedic practitioner who
                            can assess your specific needs and provide personalized guidance regarding the duration and
                            frequency of Kati Basti sessions for optimal results.</p>
                        <p><strong>Note</strong>:Kati Basti can be repeated as per the recommendations of an Ayurvedic
                            practitioner, and the
                            entire procedure is carried out by trained professionals in a controlled and hygienic
                            environment.</p>
                    </div>
                </div>
                <div *ngIf="panchkarmaName === 'leech-therapy'" class="card text-left">
                    <div class="card-header" style="color: #0b0b0b;">
                        <h4>Leech therapy</h4>
                    </div>
                    <div class="card-body text-left">
                        <h2>Procedure</h2>
                        <ul>
                            <li>
                                <p>During leech therapy, the following stages are typically performed:</p>
                            </li>
                            <li>
                                <p>Cleansing and preparing the area of the body where leeches will be administered. This
                                    includes removing any moisturizers, lubricants, or other substances that may hinder
                                    the leechs ability to attach.</p>
                            </li>
                            <li>
                                <p>Application of leech: Medicinal leeches are applied to the designated area of
                                    epidermis. Typically, a sterile mesh or adhesive dressing is used to prevent them
                                    from shifting or sliding off.</p>
                            </li>
                            <li>
                                <p>By inserting their mouthparts into the tissue, leeches attach themselves to the
                                    epidermis naturally. They secrete substances containing bioactive compounds in their
                                    sputum.</p>
                            </li>
                            <li>
                                <p>Once affixed, leeches proceed to feed on the blood of their hosts. In doing so, they
                                    inject their secretions into the incision.</p>
                            </li>
                            <li>
                                <p>Depending on the specific treatment objectives and the individuals response,
                                    the leeches are typically left in position for between 30 minutes and one hour.</p>
                            </li>
                            <li>
                                <p>After the allotted time, the leeches are carefully extracted from the epidermis. To
                                    prevent any leech remnants from remaining in the incision, it is imperative not to
                                    remove them with force.</p>
                            </li>
                            <li>
                                <p>The laceration site is meticulously cleansed and bandaged to prevent infection and
                                    promote healing.</p>
                            </li>
                        </ul>
                        <h2>Benefits</h2>
                        <p>Hirudotherapy, or leech treatment, has been used to cure different disorders, although
                            scientific proof is scarce. Leech treatment has been studied for the following ailments and
                            conditions:</p>
                        <p><br></p>
                        <ul>
                            <li>
                                <p>Venous congestion: Leech treatment improves blood flow in venous congestion. Leech
                                    saliva&apos;s anticoagulant and vasodilatory effects may reduce congestion and
                                    improve blood flow.</p>
                            </li>
                            <li>
                                <p>Leech treatment may improve osteoarthritis, a degenerative joint condition. Leech
                                    saliva may relieve arthritic pain and inflammation.</p>
                            </li>
                            <li>
                                <p>Leech treatment has been studied for psoriasis and chronic wounds. Leech saliva may
                                    offer anti-inflammatory and wound-healing properties.</p>
                            </li>
                            <li>
                                <p>Leech treatment improves blood circulation and prevents tissue transplant failure in
                                    reconstructive surgery. Leeches help heal by increasing blood flow and minimizing
                                    venous congestion.</p>
                            </li>
                        </ul>
                        <p>Leech treatment may provide advantages for some ailments, but more study is required to prove
                            it. To avoid difficulties, the treatment should be performed by skilled specialists in a
                            sterile setting. For illness assessment and treatment, see a doctor.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
