<div *ngIf="bannerType === 'live-interaction'" class="page-title-area page-title-four"
     style="background-image: url('assets/img/slider-banner/sliders/1.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Live interaction facility with our trustworthy doctors</h2>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bannerType === 'phyto-nanotechnology'" class="page-title-area page-title-four"
     style="background-image: url('assets/img/slider-banner/sliders/3.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>More virtuous medicines enriched with phyto nanotechnology</h2>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bannerType === 'your-health-our-care'" class="page-title-area page-title-four"
     style="background-image: url('assets/img/slider-banner/sliders/2.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Your Health Our Care</h2>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bannerType === 'self-care-assessment'" class="page-title-area page-title-four"
     style="background-image: url('assets/img/slider-banner/sliders/7.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Importance of Self-care assessment</h2>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bannerType === 'get-guidance'" class="page-title-area page-title-four"
     style="background-image: url('assets/img/slider-banner/sliders/6.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Get the Precise Guidance from our panel of our SKILLED & FINEST DOCTORS</h2>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-xs-12">
                <div class="about-item">
                    <div class="about-left">
                        <img *ngIf="bannerType === 'live-interaction'" alt="Live Interaction"
                             src="assets/img/slider-banner/sliders/1_insider.jpg">
                        <img *ngIf="bannerType === 'phyto-nanotechnology'" alt="Phyto Nanotechnology"
                             src="assets/img/slider-banner/sliders/2_insider.jpg">
                        <img *ngIf="bannerType === 'your-health-our-care'" alt="Your Health Our Care"
                             src="assets/img/slider-banner/sliders/3_insider.jpg">
                        <img *ngIf="bannerType === 'self-care-assessment'" alt="Self Care Assessment"
                             src="assets/img/slider-banner/sliders/4_insider.jpg">
                        <img *ngIf="bannerType === 'get-guidance'" alt="Get Guidance"
                             src="assets/img/slider-banner/sliders/5_insider.jpg">
                    </div>
                </div>
            </div>
            <div *ngIf="bannerType === 'live-interaction'" class="col-lg-6 col-xs-12 about-item about-right">
                <h4>"Live Long - with Good Health & Immense Happiness".</h4>
                <h6>
                    Any Disease / Illness not only brings pain & agitation but also creates perplexity & flurry
                    situations.
                    In such moments we usually take quick decisions that mostly prove to be wrong sooner or later. So,
                    to
                    avoid all such perplex circumstances, connect with Dr. Axico’s Live Interaction facility with
                    Experienced & trustworthy doctors in the blink of an eye.
                </h6>
            </div>
            <div *ngIf="bannerType === 'phyto-nanotechnology'" class="col-lg-6 col-xs-12 about-item about-right">
                <h6>
                    The benefits of Nano medicines are indubitable and unstoppable.
                    Our medicines are created in supervision with a highly skilled research team and with the latest
                    Phyto Nanotechnology which enhances medicinal potential over any disease.
                    Research shows that there are several side effects associated with synthetic drugs, which make them
                    inappropriate for regular long treatment. Whereas, medicines made with Phyto Nanotechnology have
                    very negligible side effects, better ability to eliminate even the smallest disease, healing ability
                    also increases.
                </h6>
            </div>
            <div *ngIf="bannerType === 'your-health-our-care'" class="col-lg-6 col-xs-12 about-item about-right">
                <h6>
                    Ayurveda Shastra aims at keeping a healthy person healthy and managing or curing diseases (mind,
                    body or both) that manifest in a person.
                    Dr. Axico provides simple & user oriented experience for all Dr. Axico has kept the process of
                    consultation & treatment user-friendly. Please follow these steps given below -
                    <ul>
                        <li>
                            <i class="fas fa-circle mr-1 f_12 color-black"></i> If you are sick or have any symptoms,
                            without panic open
                            our website.
                        </li>
                        <li><i class="fas fa-circle mr-1 f_12 color-black"></i>Just tap Book appointment, make payment.
                        </li>
                        <li><i class="fas fa-circle mr-1 f_12 color-black"></i>Soon, we connect you with a specialized
                            doctor as per your
                            problem.
                        </li>
                        <li><i class="fas fa-circle mr-1 f_12 color-black"></i>Doctor will diagnose your problem by
                            asking several health
                            related questions, and will prescribe treatment & medicines
                        </li>
                    </ul>
                </h6>
            </div>
            <div *ngIf="bannerType === 'self-care-assessment'" class="col-lg-6 col-xs-12 about-item about-right">
                <h6>
                    One who is established in Self, who has balanced dosas (primary life force), balanced agni (fire of
                    digestion), properly formed dhatus (tissues), proper elimination of malas (waste products),
                    well-functioning bodily processes, and whose mind, soul and senses are full of bliss is called a
                    healthy person.
                    Here, they refer to diagnosing the disease at a very early stage, Dr. Axico has made this successful
                    to all. Now, with the help of Self Health Assessment you can self-assess your body & find out if
                    your body is suffering, which you are unaware?
                    Once you diagnose yourself, then our specialized & trustworthy team of Doctors is available 24*7 to
                    assist you.
                </h6>
            </div>
            <div *ngIf="bannerType === 'get-guidance'" class="col-lg-6 col-xs-12 about-item about-right">
                <h6>
                    Exercise results in good health, long life, strength and happiness. Good health is the greatest
                    blessing. Health is means of everything. Every normal person in India spends a lot of time in
                    finding a trustworthy & a reliable doctor, trust us it is true.
                    Dr. Axico provides you this comfort where we value your Trust and connect you only with Honest &
                    Forthright Doctors who understand your disease in a very simpler way. Health is the most vital
                    topic, but we ignore it a lot. So, no more ignorance. Dr. Axico brings you attentive, vigilant,
                    skilled & trustworthy doctors so that you end up thinking about a second opinion.
                </h6>
            </div>
        </div>
    </div>
</div>
