import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AddToCartService} from "../../../shared/services/add-to-cart.service";

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

    constructor(public router: Router,
                public addToCartService: AddToCartService) {
    }

    ngOnInit(): void {
        this.removeCartProducts()
    }

    removeCartProducts() {
        this.addToCartService.deleteAll().subscribe(data => {
        }, error => {
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
