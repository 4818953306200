import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../../../shared/services/alert.service";
import {AppointmentService} from "../../../shared/services/appointment.service";
import {DoctorService} from "../../../shared/services/doctor.service";
import {Subject} from "rxjs";
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    public contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    id: any;
    data: any;
    userDetails: any;
    public returnUrl: string;
    public error = '';
    doctors: any = [];
    user: any;

    successMsg: any;
    nameError: any;
    emailError: any;
    genderError: any;
    numberError: any;
    ageError: any;
    symptomsError: any;

    private _unsubscribeAll: Subject<any>;
    isBusy = false;
    loginPressed = false;

    contactUsArr: any;
    contactUsUrl: any;

    hostName: any = 'https://www.draxico.com';

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private appointmentService: AppointmentService,
        private doctorService: DoctorService,
        private route: ActivatedRoute,
        private titleService: TitleService,
        private titleLibraryService: Title,
        private metaService: Meta,
        private metaServices: MetaService,
    ) {
    }

    ngOnInit(): void {
        this.refresh()
        this.contactUsUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.contactUsUrl);
    }

    refresh() {
        this.contactForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.required],
            'phone': ['', Validators.required],
            'message': ['', Validators.required]
        });
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm.value && this.contactForm.value.name) ? this.contactForm.value.name : '',
                'email': (this.contactForm.value && this.contactForm.value.email) ? this.contactForm.value.email : '',
                'phone': (this.contactForm.value && this.contactForm.value.phone) ? this.contactForm.value.phone : '',
                'message': (this.contactForm.value && this.contactForm.value.message) ? this.contactForm.value.message : '',
                'type': 'ENQUIRYFORM'
            };
            // this.submitting = true;
            this.appointmentService.postContactus(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.contactForm.reset();
                    this.router.navigate(['contact/submitted']);
                }
            }, error => {
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].phone && error['error']['errors'].phone.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].phone[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }


    getAllTitle() {
        this.titleService.getContactUsTitleJson()
            .subscribe(res => {
                this.contactUsArr = res.title;
                if (this.contactUsUrl && this.contactUsArr.length > 0) {
                    this.contactUsArr.forEach(contactUsUrl => {
                        if (contactUsUrl.url === this.contactUsUrl) {
                            this.titleLibraryService.setTitle(contactUsUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: contactUsUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: contactUsUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: contactUsUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: contactUsUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: contactUsUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: contactUsUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: contactUsUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: contactUsUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: contactUsUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: contactUsUrl.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: contactUsUrl.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: contactUsUrl.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: contactUsUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: contactUsUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: contactUsUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: contactUsUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: contactUsUrl.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: contactUsUrl.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: contactUsUrl.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: contactUsUrl.twitter_image});
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: contactUsUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: contactUsUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: contactUsUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: contactUsUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: contactUsUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: contactUsUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: contactUsUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: contactUsUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: contactUsUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }
}
