import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ActivatedRoute} from '@angular/router';
import {MetaService} from 'src/app/shared/services/meta.service';
import {TitleService} from 'src/app/shared/services/title.service';
import {Title} from '@angular/platform-browser';
import {Meta} from '@angular/platform-browser';

@Component({
    selector: 'app-self-assessments',
    templateUrl: './self-assessments.component.html',
    styleUrls: ['./self-assessments.component.scss']
})
export class SelfAssessmentsComponent implements OnInit {
    selfAssessmentsUrlArr: any;
    selfAssessmentUrl: any;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private metaServices: MetaService,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta) {
    }

    ngOnInit(): void {
        this.selfAssessmentUrl = window.location.href;
        this.getAllTitle();
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }


    getAllTitle() {
        this.titleService.getSelfAssessmentTitleJson()
            .subscribe(res => {
                this.selfAssessmentsUrlArr = res.title;
                if (this.selfAssessmentUrl && this.selfAssessmentsUrlArr.length > 0) {
                    this.selfAssessmentsUrlArr.forEach(selfAssessmentUrl => {
                        if (selfAssessmentUrl.url === this.selfAssessmentUrl) {
                            this.titleLibraryService.setTitle(selfAssessmentUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: selfAssessmentUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: selfAssessmentUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: selfAssessmentUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: selfAssessmentUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: selfAssessmentUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: selfAssessmentUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: selfAssessmentUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: selfAssessmentUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: selfAssessmentUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: selfAssessmentUrl.og_ib_edge});
                            this.metaService.updateTag({
                                property: 'og:ib.libge',
                                content: selfAssessmentUrl.og_ib_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:im.libge',
                                content: selfAssessmentUrl.og_im_libge
                            });
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: selfAssessmentUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: selfAssessmentUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: selfAssessmentUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: selfAssessmentUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: selfAssessmentUrl.twitter_card});
                            this.metaService.updateTag({
                                name: 'twitter:title',
                                content: selfAssessmentUrl.twitter_title
                            });
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: selfAssessmentUrl.twitter_description
                            });
                            this.metaService.updateTag({
                                name: 'twitter:image',
                                content: selfAssessmentUrl.twitter_image
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: selfAssessmentUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: selfAssessmentUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: selfAssessmentUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: selfAssessmentUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: selfAssessmentUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: selfAssessmentUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: selfAssessmentUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: selfAssessmentUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: selfAssessmentUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }
}
