import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {DoctorService} from '../../../shared/services/doctor.service';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {StorageService} from 'src/app/shared/services/storage.service';
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GlobalService} from "../../../shared/services/global.service";


@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
    appointmentUrlArr: any;
    appintmentUrl: any;
    hostName: any = 'https://www.draxico.com';
    appointmentForm: FormGroup;
    OtpForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    id: any;
    data: any;
    userDetails: any;
    public returnUrl: string;
    public error = '';
    doctors: any = [];
    user: any;
    private _unsubscribeAll: Subject<any>;
    isBusy = false;
    loginPressed = false;
    route: any;
    content: any;
    decodedOtp: any;
    appointment: any;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private appointmentService: AppointmentService,
        private doctorService: DoctorService,
        private activatedRoute: ActivatedRoute,
        private titleService: TitleService,
        private titleLibraryService: Title,
        private metaService: Meta,
        private metaServices: MetaService,
        private modalService: NgbModal,
        private globalService: GlobalService,
    ) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.appintmentUrl = window.location.pathname;
        this.refresh();
        this.appintmentUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.appintmentUrl);
    }

    getAllTitle() {
        this.titleService.getAppointmentsTitleJson()
            .subscribe(res => {
                this.appointmentUrlArr = res.title;
                if (this.appintmentUrl && this.appointmentUrlArr.length > 0) {
                    this.appointmentUrlArr.forEach(appintmentUrl => {
                        if (appintmentUrl.url === this.appintmentUrl) {
                            this.titleLibraryService.setTitle(appintmentUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: appintmentUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: appintmentUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: appintmentUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: appintmentUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: appintmentUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: appintmentUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: appintmentUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: appintmentUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: appintmentUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: appintmentUrl.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: appintmentUrl.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: appintmentUrl.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: appintmentUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: appintmentUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: appintmentUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: appintmentUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: appintmentUrl.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: appintmentUrl.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: appintmentUrl.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: appintmentUrl.twitter_image});
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: appintmentUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: appintmentUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: appintmentUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: appintmentUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: appintmentUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: appintmentUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({name: 'twitter:idfmge', content: appintmentUrl.twitter_idfmge});
                            this.metaService.updateTag({name: 'twitter:idmmge', content: appintmentUrl.twitter_idmmge});
                            this.metaService.updateTag({name: 'twitter:idrmge', content: appintmentUrl.twitter_idrmge});
                        }

                    });
                }
            });
    }

    refresh() {
        this.appointmentForm = this.fb.group({
            name: ['', Validators.required],
            contact: ['', Validators.required],
            symptoms: ['', Validators.required],
            age: ['', Validators.required],
            gender: ['', Validators.required]
        });
        this.OtpForm = this.fb.group({
            otp: ['', Validators.required],
        })
    }

    getDoctors() {
        this.doctorService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.doctors = data['data'];
            }
        });
    }

    submit(content) {
        this.submitted = true;
        if (!this.appointmentForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.appointmentForm.value && this.appointmentForm.value.name) ? this.appointmentForm.value.name : '',
                'contact': (this.appointmentForm.value && this.appointmentForm.value.contact) ? this.appointmentForm.value.contact : '',
                'symptoms': (this.appointmentForm.value && this.appointmentForm.value.symptoms) ? this.appointmentForm.value.symptoms : '',
                'age': (this.appointmentForm.value && this.appointmentForm.value.age) ? this.appointmentForm.value.age : '',
                'gender': (this.appointmentForm.value && this.appointmentForm.value.gender) ? this.appointmentForm.value.gender : '',
                'mode': 'ONLINE'
            };
            this.appointmentService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.appointment = data;
                    this.decodedOtp = (this.appointment && this.appointment['decoded_otp']) ? this.appointment['decoded_otp'] : '';
                    this.modalService.open(content, {'backdrop': 'static', 'keyboard': false});
                    this.appointmentForm.patchValue({
                        'name': '',
                        'contact': '',
                        'symptoms': '',
                        'age': '',
                        'gender': ''
                    });
                }
            }, error => {
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].symptoms && error['error']['errors'].symptoms.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].symptoms[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gender[0]);
                        return;
                    }
                    if (error['error']['errors'].mode && error['error']['errors'].mode.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].mode[0]);
                        return;
                    }
                }
            });
        }
    }

    submitOtp(content2) {
        this.submitted = true;
        if (!this.OtpForm.valid) {
            return;
        }
        let params = {
            'contact': (this.appointment && this.appointment['contact']) ? this.appointment['contact'] : '',
            'otp': (this.OtpForm.value && this.OtpForm.value['otp']) ? this.OtpForm.value['otp'] : '',
            'decoded_otp': this.decodedOtp
        };
        this.appointmentService.verifyOtp(params).subscribe(data => {
            if (data) {
                this.globalService.setAccessToken(data.token);
                this.globalService.setSelf({
                    'name': data.name,
                    'user_id': data.id,
                    'username': data.username,
                    'email': data.email,
                    'contact': data.contact,
                    'user_type': data.user_type
                });
                this.decodedOtp = undefined;
                this.submitting = false;
                this.submitted = false;
                this.refresh();
                this.modalService.dismissAll('');
                this.router.navigate(['/']).then(() => {
                    window.location.reload();
                });
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                if (error['error']['errors'].otp && error['error']['errors'].otp.length > 0) {
                    this.alertService.showErrors(error['error']['errors'].otp[0]);
                    return;
                }
            }
        });
    }

    login() {
        this.router.navigateByUrl('login');
    }
}
