<section class="blog-area-two mt-lg-5">
    <div class="container">
        <div class="row pt-sm-5 pt-md-5 pt-lg-0">
            <div class="col-lg-8 col-xs-12 my-sm-5 my-md-5 my-lg-0 cart-mobile-margin">
                <div class="card">
                    <div class="card-header" style="background-color: #2874f0;">
                        <h5 style="color: white;">My Cart</h5>
                    </div>
                    <!-- Without user login cary item started-->
                    <div *ngIf="nouseritems && ccc > 0;" class="card-body">
                        <div *ngFor="let itemdata of nouseritems" class="row mt-20 shadow-upper-light">
                            <div class="col-lg-3 col-xs-12 text-center mt-15px">
                                <img (click)="goTo('products/'+itemdata?.product?.url)" class="mb-20 cursor-pointer"
                                     height="100px" src="{{itemdata?.product?.product_image[0]?.file?.url}}">
                            </div>
                            <div class="col-lg-9 col-xs-12 mt-40px">
                                <h5 (click)="goTo('products/'+itemdata?.product?.url)"
                                    class="cursor-pointer">{{itemdata?.product?.name}}</h5>
                                <p>
                                    <span>Price</span>
                                    <span
                                        class="ml-10">&#x20B9;{{itemdata?.product?.total_price | number : '1.2-2'}}</span>
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-7 col-sm-9">
                                    <div aria-label="Basic example" class="btn-group" role="group"
                                         style="margin-left: 13%;">
                                        <div aria-label="Basic example" class="btn-group" role="group"
                                             style="height: 33.5px;">
                                            <button (click)="decrement(itemdata)" class="btn btn-primary" type="button">
                                                -
                                            </button>
                                            <button class="btn btn-primary" style="color: white"
                                                    type="button">{{itemdata?.quantity}}</button>
                                            <button (click)="increment(itemdata)" class="btn btn-primary" type="button">
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 col-sm-3">
                                    <i (click)="removenouseritem(itemdata)"
                                       class="fas fa-trash text-danger mr-10 cursor-pointer mt-10"></i><span
                                    (click)="removenouseritem(itemdata)"
                                    class="ml-10 cursor-pointer text-info">Remove</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                     Without user login cary items ended-->
                    <div *ngIf="items && items.length > 0; else noProductToShow" class="card-body">
                        <div *ngFor="let item of items; let i = index" class="row mt-20 shadow-upper-light">
                            <div class="col-lg-3 col-xs-12 text-center mt-15px">
                                <img (click)="goTo('products/'+item?.product?.url)" class="mb-20 cursor-pointer"
                                     height="100px" src="{{item?.product?.product_image[0]?.file?.url}}">
                            </div>
                            <div class="col-lg-9 col-xs-12 mt-40px">
                                <h5 (click)="goTo('products/'+item?.product?.url)"
                                    class="cursor-pointer">{{item?.product?.name}}</h5>
                                <p>
                                    <span>Price</span>
                                    <span class="ml-10">&#x20B9;{{item?.product?.total_price | number : '1.2-2'}}</span>
                                </p>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div aria-label="Basic example" class="btn-group" role="group"
                                         style="margin-left: 13%;">
                                        <div aria-label="Basic example" class="btn-group" role="group"
                                             style="height: 33.5px;">
                                            <button (click)="decrement(item)" class="btn btn-primary" type="button">-
                                            </button>
                                            <button class="btn btn-primary" style="color: white"
                                                    type="button">{{item?.quantity}}</button>
                                            <button (click)="increment(item)" class="btn btn-primary" type="button">+
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-right">
                                    <i (click)="remove(item)"
                                       class="fas fa-trash text-danger mr-10 cursor-pointer mt-10"></i><span
                                    (click)="remove(item)"
                                    class="ml-10 cursor-pointer text-info">Remove</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #noProductToShow>

                    </ng-template>
                    <div *ngIf="nouseritems && ccc > 0" class="card-footer">
                        <div class="row">
                            <div class="col-md-6 mb-3 mb-md-0 text-center text-md-start">
                                <button (click)="prodcuturl()"
                                        class="btn btn-outline-primary btn-block"
                                        style="background-color: #fb641b; color: white; height: 48px; border: none;">
                                    <i class="icofont-arrow-left" style="margin-right: 5px"></i>
                                    Continue Shopping
                                </button>
                            </div>
                            <div class="col-md-6 text-center text-md-end">
                                <button (click)="login()"
                                        class="btn btn-success btn-block"
                                        style="color: white; height: 48px; border: none;">
                                    Proceed to Checkout
                                    <i class="icofont-arrow-right ml-10"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="items && items.length > 0" class="card-footer">
                        <div class="row">
                            <div class="col-md-6 mb-2 mb-md-0 text-center text-md-start">
                                <button (click)="shoppingPage()"
                                        class="btn btn-outline-primary"
                                        style="background-color: #fb641b; color: white; height: 48px; border: none;">
                                    <i class="icofont-arrow-left" style="margin-right: 5px"></i>
                                    Continue Shopping
                                </button>
                            </div>
                            <div class="col-md-6 text-center text-md-end">
                                <button (click)="checkoutPage()"
                                        class="btn btn-success"
                                        style="color: white; height: 48px; border: none;">Proceed to
                                    Checkout<i
                                        class="icofont-arrow-right ml-10"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="items && items.length > 0; else tempPrice" class="col-lg-4 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="mt-10">Price Details</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-xs-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div *ngIf="items && items.length > 0; else noTotalToShow" class="row">
                                            <div class="col-12">
                                                <table class="table-striped" style="width:100%">
                                                    <tr>
                                                        <th>Price:</th>
                                                        <td>(<b>&#x20b9;</b>) {{actualSum | number : '1.2-2'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sub Total:</th>
                                                        <td>(<b>&#x20b9;</b>) {{discountedPriceSum | number : '1.2-2'}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Shipping:</th>
                                                        <td>Free</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <ng-template #noTotalToShow>
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <h5 style="padding: 91px">No data to show</h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="items && items.length > 0" class="card-footer">
                                        <table style="width:100%">
                                            <tr>
                                                <th>Total Amount</th>
                                                <td class="text-right">(<b>&#x20b9;</b>)
                                                    <strong>{{discountedPriceSum | number : '1.2-2'}}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="items && items.length > 0" class="card-body text-center">
                        <button (click)="checkoutPage()" class="btn btn-outline-primary text-center">Proceed to
                            Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #tempPrice>
            <div class="col-lg-4 col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="mt-10">Price Details</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-xs-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <table class="table-striped" style="width:100%">
                                                    <tr>
                                                        <th>Price:</th>
                                                        <td>(<b>&#x20b9;</b>) {{actualSum | number : '1.2-2'}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sub Total:</th>
                                                        <td>(<b>&#x20b9;</b>) {{discountedPriceSum | number : '1.2-2'}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Shipping:</th>
                                                        <td>Free</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <ng-template #noTotalToShow>
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <h5 style="padding: 91px">No data to show</h5>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="card-footer">
                                        <table style="width:100%">
                                            <tr>
                                                <th>Total Amount</th>
                                                <td class="text-right">(<b>&#x20b9;</b>)
                                                    <strong>{{discountedPriceSum | number : '1.2-2'}}</strong></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body text-center">
                        <button (click)="login()" class="btn btn-outline-primary text-center">Proceed to
                            Checkout
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</section>

