<ng-template #content>
    <div id="modal">
        <div class="modal-header font-weight-bold">
            <h5 *ngIf="modalType === 'SUBMIT_PASSWORD'" class="modal-title font-weight-bold">Update Password</h5>
            <!-- -->
            <h5 *ngIf="modalType === 'SUBMIT_CONTACT'" class="font-weight-400 share-emotion"><!---->
                <i class="fas fa-user-circle cursor-pointer" style="margin-right: 10px"></i>
                <span>Enter Contact No.</span>
            </h5>
            <i (click)="modalService.dismissAll('')" class="fas fa-times-circle f_16 text-danger cursor-pointer"></i>
        </div>
        <div class="modal-body">
            <div *ngIf="modalType === 'SUBMIT_PASSWORD'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="forgotPasswordForm" class="form" novalidate>
                        <div class="container">
                            <div class="row" style="margin-top: -15px">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <input class="form-control mt-4" formControlName="newPassword" id="new_password"
                                               placeholder="New Password"
                                               type="password">
                                        <small
                                            *ngIf="submitted && forgotPasswordForm.get('newPassword').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <input class="form-control" formControlName="confirmPassword"
                                               id="confirm_password"
                                               placeholder="Confirm Password"
                                               type="password">
                                        <small
                                            *ngIf="submitted && forgotPasswordForm.get('confirmPassword').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <fieldset class="form-group">
                                        <input class="form-control mt-2" formControlName="otp" placeholder="Enter OTP"
                                               type="number">
                                    </fieldset>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
                                        <small>Resend OTP in - </small>
                                        <small *ngIf="timerSixtySeconds > 0"
                                               class="text-danger">{{timerSixtySeconds}}
                                            sec.</small>
                                        <small (click)="resendOTP()" *ngIf="timerSixtySeconds === 0"
                                               class="text-primary cursor-pointer">Resend OTP ?</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="modalType === 'SUBMIT_CONTACT'" class="row"><!---->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="contactForm" class="form" novalidate>
                        <div class="container">
                            <div class="row" style="margin-top: -15px">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <input class="form-control mt-4" formControlName="contact" id="name"
                                               placeholder="Enter Here"
                                               type="text"/>
                                        <small
                                            *ngIf="submittedContact && contactForm.get('contact').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                        <small
                                            *ngIf="submittedContact && contactForm.get('contact').hasError('pattern')"
                                            class="text-danger">
                                            Enter a Valid Contact Number
                                        </small>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="submitContact()" *ngIf="modalType === 'SUBMIT_CONTACT'"
                    [disabled]="submitting" class="btn btn-success color-white pull-right"><!---->
                <i *ngIf="!submitting" class="fas fa-pen-square" style="margin-right: 10px"></i>
                <i *ngIf="submitting" class="fas fa-spinner fa-pulse" style="margin-right: 10px"></i>Submit
            </button>
            <button (click)="submitPassword()" *ngIf="modalType === 'SUBMIT_PASSWORD'"
                    [disabled]="submitting" class="btn btn-success color-white pull-right"><!---->
                <i *ngIf="!submitting" class="fas fa-pen-square" style="margin-right: 10px"></i>
                <i *ngIf="submitting" class="fas fa-spinner fa-pulse" style="margin-right: 10px"></i>Update Password
            </button>
        </div>
    </div>
</ng-template>
<div class="signup-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 pl-0">
                <div class="login-left">
                    <img alt="Login" src="assets/img/loginpage.png">
                </div>
            </div>

            <div class="col-lg-6 ptb-100">
                <div class="signup-item">
                    <div class="signup-head">
                        <h2>Login Here</h2>
                        <p>Didn't have account yet? <a routerLink="/signup">Register Here</a></p>
                    </div>

                    <div class="signup-form">
                        <form [formGroup]='loginForm' novalidate>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input class="form-control" formControlName="username" id="username"
                                               placeholder="Username"/>
                                        <small *ngIf="submitted && loginForm.get('username').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input [type]="passwordTextType ? 'text' : 'password'"
                                               class="form-control form-control-merge"
                                               formControlName="password"
                                               id="password"
                                               placeholder="············">
                                        <small *ngIf="submitted && loginForm.get('password').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="forgot-pass cursor-pointer">
                                            <a (click)="openSubmitModal(content)">Forgot Password?</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <button (click)="submit()" class="btn signup-btn" type="submit">Login</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
