import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {AuthService} from '../../../shared/services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from 'src/app/shared/services/storage.service';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    public registerForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    id: any;
    data: any;
    OtpForm: FormGroup;
    timerSixtySeconds: number = 60;
    decodedOtp: any;
    interval: any;
    user: any;
    options = {
        size: 'md', // You can adjust the size if needed ('md' stands for medium).
        backdrop: 'static', // The backdrop will not be closed when clicking outside the modal.
        keyboard: false, // Disables the modal closing when the user presses the 'Escape' key.
    };

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        public modalService: NgbModal,
    ) {
        this.user = StorageService.getToken();
        if (this.user) {
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            });
        }
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.registerForm = this.fb.group({
            'name': ['', (!this.id) ? Validators.required : ''],
            'email': ['', (!this.id) ? Validators.required : ''],
            'password': ['', (!this.id) ? Validators.required : ''],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
        });
        this.OtpForm = this.fb.group({
            'otp': ['', Validators.required]
        });
    }

    submit(content) {
        this.submitted = true;
        if (!this.registerForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.registerForm.value && this.registerForm.value.name) ? this.registerForm.value.name : '',
                'contact': (this.registerForm.value && this.registerForm.value.contact) ? this.registerForm.value.contact : '',
                'email': (this.registerForm.value && this.registerForm.value.email) ? this.registerForm.value.email : '',
                'password': (this.registerForm.value && this.registerForm.value.password) ? this.registerForm.value.password : '',
            };

            this.submitting = true;
            this.authService.add(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.user = data;
                    this.decodedOtp = (this.user && this.user['decoded_otp']) ? this.user['decoded_otp'] : '';
                    this.resendOTPTimer();
                    // @ts-ignore
                    this.modalService.open(content, this.options)
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errorDetails']) {
                    if (error['error']['errorDetails']) {
                        this.alertService.showErrors(error['error']['errorDetails']);
                        return;
                    }
                }
            });
        }
    }

    submitOtp() {
        this.submitted = true;
        if (!this.OtpForm.valid) {
            return;
        }
        let params = {
            'user_id': (this.user && this.user['id']) ? this.user['id'] : '',
            'contact': (this.user && this.user['contact']) ? this.user['contact'] : '',
            'otp': (this.OtpForm.value && this.OtpForm.value['otp']) ? this.OtpForm.value['otp'] : '',
            'decoded_otp': this.decodedOtp,
            // 'device': 'WEB'
        };
        this.authService.verifyOtp(params).subscribe(data => {
            StorageService.setItem('self', {
                'name': this.user['name'],
                'user_id': this.user['id'],
                'username': this.user['username'],
                'email': this.user['email'],
                'contact': this.user['contact'],
                'user_type': this.user.user_type
            });
            StorageService.setItem('accessToken', data['token']);
            this.decodedOtp = undefined;
            this.submitting = false;
            this.submitted = false;
            this.refresh();
            this.modalService.dismissAll('');
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            });
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

    resendOTP() {
        this.submitting = true;
        if (!this.registerForm.valid) {
            return;
        }
        this.authService.resendOTP(this.registerForm.value).subscribe(data => {
            if (data && data['decoded_otp']) {
                this.decodedOtp = data['decoded_otp'];
                this.resendOTPTimer();
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    resendOTPTimer() {
        this.timerSixtySeconds = 60;
        this.interval = setInterval(() => {
            if (this.timerSixtySeconds > 0) {
                this.timerSixtySeconds--;
            } else {
                this.interval = undefined;
            }
        }, 1000);
    }

}
