import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DoctorService} from "../../../shared/services/doctor.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-meeting',
    templateUrl: './meeting.component.html',
    styleUrls: ['./meeting.component.scss']
})
export class MeetingComponent implements OnInit {
    doctorUsername: any
    doctors: any = [];

    constructor(private route: ActivatedRoute,
                private doctorService: DoctorService,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.doctorUsername = this.route.snapshot.params['username'];
        this.getDoctorsDetails();
    }

    getDoctorsDetails() {
        this.doctorService.detail(this.doctorUsername).subscribe(data => {
            if (data) {
                this.doctors = data;
                window.open(this.doctors['link'], "_self")
            }
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }
}
