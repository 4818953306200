<section id="self-assessment">
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 *ngIf="assessmentType === 'liver-health-assessment'" class="modal-title">Liver Savior Assessment</h4>
            <button (click)="modalService.dismissAll('')" aria-label="Close" class="btn-close" type="button"></button>
        </div>
        <div class="modal-body">
            <div *ngIf="assessmentType === 'liver-health-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        Liver problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/liver-savior-kit')"
                        class="text-info cursor-pointer">Liver Savior Kit (AHY - 23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'women-health-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        health related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/women-care-kit')"
                        class="text-info cursor-pointer">Women Care Kit (RPL-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'male-health-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        health related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/male-health-assessment')"
                        class="text-info cursor-pointer">Male Rejuvenator (RNS-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'obesity-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        Obesity related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/obesity-assessment')"
                        class="text-info cursor-pointer">Obesity Pacifire (AMS-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'diabetes-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        diabetic problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/diabetes-assessment')"
                        class="text-info cursor-pointer">Diabetes Manager (DMY-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'thyroid-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        thyroid related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/thyro–guard')"
                        class="text-info cursor-pointer">Thyro – Guard (KTA-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'acidity-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        acidity related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/acidity-assessment')"
                        class="text-info cursor-pointer">Acidity Uprooter (DKS-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'joints-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        ortho related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/joints-assessment')"
                        class="text-info cursor-pointer">Ortho Fortify (OSE-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'general-health-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        health related problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/general-health-assessment')"
                        class="text-info cursor-pointer">Detox Management (TIA-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'uterine-fibroid-assessment'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5>You have <span class="text-danger text-capitalize">{{chances.toLowerCase()}}</span> chances of
                        health problems</h5>
                    <p style="margin-top: 50px">You should prefer Dr. Axico's <span
                        (click)="goTo('product-detail/uterine-fibroid-assessment')"
                        class="text-info cursor-pointer">Fibroid (FKV-23)</span>
                    </p>
                    <h5>Or</h5>
                    <p>You can directly <span (click)="goTo('appointment')" class="text-info cursor-pointer">Book
                            Appointment</span> with Dr. Axico's
                        experienced
                        Doctors</p>
                </div>
            </div>
            <div *ngIf="assessmentType === 'prakriti'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h5 style="font-size: 1.25rem">Maximum percentage achieved by any dosha is to be shown as prakriti
                        of user like
                        <span class="text-info text-capitalize" style="font-size: 0.9rem"><br> kapha
                            got {{((points / 23) * 100) | number : '1.2-2'}}
                            % Pitta got {{((pittaPoints / 17) * 100) | number : '1.2-2'}}
                            % and Vata got {{((vataPoints / 27) * 100) | number : '1.2-2'}}%</span>
                    </h5>
                    <hr>
                    <div class="row">
                        <div class="col-5 align-self-center">
                            <img alt="suggestion" src="assets/img/modals_img/suggestion_v02.png">
                        </div>
                        <div class="col-7 text-start">
                            <p>You can directly After result user will have link showing him symptoms of his<span
                                (click)="goTo('blog/prakriti-anaysis')" class="text-info cursor-pointer">
                                    prakriti.</span> Different blogs will be prepared for
                                each
                                <span (click)="goTo('blog/prakriti-anaysis')" class="text-info cursor-pointer"> prakriti
                                    symptoms.</span>
                            </p>
                            <hr>
                            <p>To known more about diet and lifestyle related to your prakriti book your <span
                                (click)="goTo('appointment')" class="text-info cursor-pointer">Appointment</span>
                                with Dr. Axico's
                                experienced
                                Doctors</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div>
        <img class="banner-img" src="assets/img/banners/medicinal-kits.jpg">
    </div>
    <div class="container">
        <div class="row mt-50px mb-50px">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1 class="">
                    <span class="font-bold">Self Assessment</span>
                </h1>
                <p style="text-align: justify;">Your health status will be revealed by taking this brief exam, which
                    will also help you make
                    better decisions. To obtain the most accurate result, you must take the following
                    precautions:</p>
                <ol style="text-align: justify;">
                    <li>
                        <p>In your responses, be open-minded and sincere.</p>
                    </li>
                    <li>
                        <p>This test is not meant to take the place of an in-person checkup and consultation with an
                            ayurvedic physician.</p>
                    </li>
                    <li>
                        <p>The tests might be completed in about two minutes.</p>
                    </li>
                </ol>
                <p style="text-align: justify;">At the end of the entire exercise, you will learn your Health
                    Status.</p>
                <div *ngIf="assessmentType === 'prakriti'" class="conatiner" style="text-align: justify">
                    <h3>Prakriti Test</h3>
                    <p>This quick exam will reveal your own psychosomatic temperament and guide you toward improved
                        decision-making. Recall that Prakriti is neutral; it is neither good nor harmful. You must take
                        the following precautions in order to get the most accurate result:</p>
                    <ol>
                        <li>Be frank and truthful in your responses.</li>
                        <li>Mention who you are iprakritin reality, not who you would like to be.</li>
                        <li>There may be more than one solution to some questions.</li>
                        <li>This test is not intended to replace a direct examination and consultation with an ayurvedic
                            doctor.
                        </li>
                        <li>It could take up to 30 minutes to finish the 3 tests.</li>
                    </ol>
                    <p>You will discover your Prakriti Analysis or Ayurvedic Body Profile at the conclusion of the
                        complete activity.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container mb-100px overflow">
        <div class="card" style="overflow: auto;">
            <div class="card-header">
                <h4 *ngIf="assessmentType === 'liver-health-assessment'" class="mt-10">Liver Health Assessment</h4>
                <h4 *ngIf="assessmentType === 'women-health-assessment'" class="mt-10">Women Health Assessment</h4>
                <h4 *ngIf="assessmentType === 'male-health-assessment'" class="mt-10">Male Health Assessment</h4>
                <h4 *ngIf="assessmentType === 'obesity-assessment'" class="mt-10">Obesity Assessment</h4>
                <h4 *ngIf="assessmentType === 'diabetes-assessment'" class="mt-10">Diabetes Assessment</h4>
                <h4 *ngIf="assessmentType === 'thyroid-assessment'" class="mt-10">Thyroid Assessment</h4>
                <h4 *ngIf="assessmentType === 'acidity-assessment'" class="mt-10">Acidity Assessment</h4>
                <h4 *ngIf="assessmentType === 'joints-assessment'" class="mt-10">Joints Assessment</h4>
                <h4 *ngIf="assessmentType === 'general-health-assessment'" class="mt-10">General Health Assessment</h4>
                <h4 *ngIf="assessmentType === 'uterine-fibroid-assessment'" class="mt-10">Uterine Fibroid
                    Assessment</h4>
                <h4 *ngIf="assessmentType === 'kidney-assessment'" class="mt-10">Kidney Assessment</h4>
                <h4 *ngIf="assessmentType === 'prakriti'" class="mt-10">Prakriti</h4>
            </div>
            <div *ngIf="assessmentType === 'liver-health-assessment'" class="card-body">
                <form [formGroup]="liverSaviorForm" class="bs-stepper" id="stepperLiver" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-liver-savior-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-liver-savior-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-liver-savior-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-liver-savior-1">
                            <div class="form-group">
                                <label class="font-bold">1. Have you heard that your cholesterol levels are abnormal
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="cholesterol_levels" name="cholesterol_levels"
                                       type="radio"
                                       value="YES_CHOLESTEROL"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="cholesterol_levels" name="cholesterol_levels"
                                       type="radio"
                                       value="NO_CHOLESTEROL"/><span class="ml-10">No</span>
                                <br>
                                <input class="ml-10" formControlName="cholesterol_levels" name="cholesterol_levels"
                                       type="radio" value="DONT_KNOW_CHOLESTEROL"/><span
                                class="ml-10">Don't Know</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">2. Do you drink Alcohol ?</label>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="NEVER"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="OCASSIONALLY_SOMETIMES">
                                <span class="ml-10">Ocassionally Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="YES_DAILY"><span class="ml-10">Yes, Daily</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">3. Family history (do your family members have the same
                                    problem)</label>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="MOTHER"><span class="ml-10">Mother</span>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="FATHER"><span class="ml-10">Father</span>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="SIBLINGS"><span class="ml-10">Siblings</span>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="NONE"><span class="ml-10">None</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Medical History</label>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="DIABETIC"><span class="ml-10">Diabetic</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="HYPERTENSION"><span class="ml-10">Hypertension</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="THYROID"><span class="ml-10">Thyroid</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="OTHER"><span class="ml-10">Other</span>
                            </div>
                        </div>
                        <div class="content" id="test-liver-savior-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Urine Colour</label>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="CLEAR_URINE"><span class="ml-10">Clear Urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="YELLOWISH_TO_AMBER_URINE"><span
                                class="ml-10">Yellowish to amber urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="RED_OR_PINK_URINE"><span class="ml-10">Red or pink urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="ORANGE_URINE"><span class="ml-10">Orange urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="DARK_BROWN_URINE"><span class="ml-10">Dark Brown Urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="CLOUDY_URINE"><span class="ml-10">Cloudy Urine</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Abnormalities of the fingernail (If any)</label>
                                <br>
                                <input class="ml-10" formControlName="abnormalities_fingernail"
                                       name="abnormalities_fingernail" type="radio"
                                       value="DISCOLORATION"><span class="ml-10">Discoloration (dark
                                    streaks, white streaks, or changes in nail colour)</span>
                                <br>
                                <input class="ml-10" formControlName="abnormalities_fingernail"
                                       name="abnormalities_fingernail"
                                       type="radio" value="CHANGES_IN_NAIL_SHAPE"><span
                                class="ml-10">Changes in nail shape (curling or clubbing)</span>
                                <br>
                                <input class="ml-10" formControlName="abnormalities_fingernail"
                                       name="abnormalities_fingernail" type="radio"
                                       value="PALE_NAILS"><span class="ml-10">Pale Nails</span>
                                <br>
                                <input class="ml-10" formControlName="abnormalities_fingernail"
                                       name="abnormalities_fingernail"
                                       type="radio"
                                       value="WHITE_NAILS_WITH_DARKER_RIMS"><span class="ml-10">White nails with
                                    darker rims</span>
                                <br>
                                <input class="ml-10" formControlName="abnormalities_fingernail"
                                       name="abnormalities_fingernail" type="radio"
                                       value="NO_SYMPTOMS"><span class="ml-10">No Symptoms</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Eyes</label>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="YELLOW_WHITES_OF_YOUR_EYES"><span
                                class="ml-10">Yellow whites of your eyes</span>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="DRY_AND_ITCHY_EYES"><span class="ml-10">Dry and itchy eyes</span>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="XANTHELASMA"><span class="ml-10">Xanthelasma - small collections of fat on
                                    the eyelids</span>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="NONE_OF_THESE"><span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Tongue</label>
                                <br>
                                <input class="ml-10" formControlName="tongue" name="tongue" type="radio"
                                       value="YELLOWISH_TONGUE"><span class="ml-10">Yellowish tongue</span>
                                <br>
                                <input class="ml-10" formControlName="tongue" name="tongue" type="radio"
                                       value="CRACK_DOWN_THE_MIDDLE"><span class="ml-10">Crack down the middle</span>
                                <br>
                                <input class="ml-10" formControlName="tongue" name="tongue" type="radio"
                                       value="NONE_OF_THESE"><span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Do you feel puffiness or swelling on face ?</label>
                                <br>
                                <input class="ml-10" formControlName="puffiness_or_swelling_face"
                                       name="puffiness_or_swelling_face" type="radio"
                                       value="NEVER"><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="puffiness_or_swelling_face"
                                       name="puffiness_or_swelling_face" type="radio"
                                       value="RARELY"><span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="puffiness_or_swelling_face"
                                       name="puffiness_or_swelling_face" type="radio"
                                       value="SOMETIMES"><span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="puffiness_or_swelling_face"
                                       name="puffiness_or_swelling_face" type="radio"
                                       value="OFTEN"><span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Do you have swelling on your leg ?</label>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_your_leg" name="swelling_on_your_leg"
                                       type="radio"
                                       value="NEVER"><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_your_leg" name="swelling_on_your_leg"
                                       type="radio"
                                       value="RARELY"><span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_your_leg" name="swelling_on_your_leg"
                                       type="radio"
                                       value="SOMETIMES"><span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_your_leg" name="swelling_on_your_leg"
                                       type="radio"
                                       value="OFTEN_SWELLING"><span class="ml-10">Often</span>
                            </div>
                        </div>
                        <div class="content" id="test-liver-savior-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && liverSaviorForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && liverSaviorForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && liverSaviorForm && liverSaviorForm.controls && liverSaviorForm.controls['email'] && liverSaviorForm.controls['email'].errors && liverSaviorForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && liverSaviorForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && liverSaviorForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && liverSaviorForm && liverSaviorForm.controls && liverSaviorForm.controls['contact'] && liverSaviorForm.controls['contact'].errors && liverSaviorForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && liverSaviorForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'women-health-assessment'" class="card-body">
                <form [formGroup]="womenCareForm" class="bs-stepper" id="stepperWomenCare" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-women-care-savior-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-women-care-savior-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-women-care-savior-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-women-care-savior-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical History</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="DIABETIC"><span class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="HYPERTENSION"><span class="ml-10">Hypertension</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="THYROID"><span class="ml-10">Thyroid</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="OTHER"><span class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have the same
                                        problem)</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="MOTHER"><span class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="FATHER"><span class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="SIBLINGS"><span class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE"><span class="ml-10">None</span>
                                </div>
                                <label class="font-bold">3. Do you experience heaviness in your body or head ?</label>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head" type="radio"
                                       value="NEVER_HEAVINESS"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head" type="radio"
                                       value="RARELY_HEAVINESS"/><span class="ml-10">No</span>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head"
                                       type="radio" value="DONT_KNOW_HEAVINESS"/><span
                                class="ml-10">Don't Know</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. How is your appetite ?</label>
                                <br>
                                <input class="ml-10" formControlName="appetite" name="appetite" type="radio"
                                       value="REGULAR_AND_NORMAL"/><span class="ml-10">Regular & Normal</span>
                                <br>
                                <input class="ml-10" formControlName="appetite" name="appetite" type="radio"
                                       value="MODERATE">
                                <span class="ml-10">Moderate</span>
                                <br>
                                <input class="ml-10" formControlName="appetite" name="appetite" type="radio"
                                       value="EXCESSIVE"><span class="ml-10">Excessive</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Menstrual History ?</label>
                                <br>
                                <input class="ml-10" formControlName="menstrual_history" name="menstrual_history"
                                       type="radio"
                                       value="REGULAR"/><span class="ml-10">Regular & Normal</span>
                                <br>
                                <input class="ml-10" formControlName="menstrual_history" name="menstrual_history"
                                       type="radio"
                                       value="IRREGULAR">
                                <span class="ml-10">Moderate</span>
                                <br>
                                <input class="ml-10" formControlName="menstrual_history" name="menstrual_history"
                                       type="radio"
                                       value="NORMAL"><span class="ml-10">Excessive</span>
                            </div>
                        </div>
                        <div class="content" id="test-women-care-savior-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Do you have white discharge ?</label>
                                <br>
                                <input class="ml-10" formControlName="white_discharge" name="white_discharge"
                                       type="radio"
                                       value="NEVER_WHITE_DISCHARGE"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="white_discharge" name="white_discharge"
                                       type="radio"
                                       value="RARELY_WHITE_DISCHARGE">
                                <span class="ml-10">Rerely</span>
                                <br>
                                <input class="ml-10" formControlName="white_discharge" name="white_discharge"
                                       type="radio" value="SOMETIMES_WHITE_DISCHARGE"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="white_discharge" name="white_discharge"
                                       type="radio"
                                       value="OFTEN_WHITE_DISCHARGE"><span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Are you feeling stressed / Anxiety ?</label>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio" value="NEVER_FEELING_STRESSED"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio" value="RARELY_FEELING_STRESSED">
                                <span class="ml-10">Rerely</span>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio"
                                       value="SOMETIMES_FEELING_STRESSED"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio" value="OFTEN_FEELING_STRESSED"><span
                                class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Do you have a lack of taste ?</label>
                                <br>
                                <input class="ml-10" formControlName="lack_of_taste" name="lack_of_taste" type="radio"
                                       value="NEVER_LACK_OF_TASTE"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="lack_of_taste" name="lack_of_taste" type="radio"
                                       value="RARELY_LACK_OF_TASTE">
                                <span class="ml-10">Rerely</span>
                                <br>
                                <input class="ml-10" formControlName="lack_of_taste" name="lack_of_taste" type="radio"
                                       value="SOMETIMES_LACK_OF_TASTE"><span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="lack_of_taste" name="lack_of_taste" type="radio"
                                       value="OFTEN_LACK_OF_TASTE"><span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Do you feel frequent urination ?</label>
                                <br>
                                <input class="ml-10" formControlName="frequent_urination" name="frequent_urination"
                                       type="radio" value="NEVER_FREQUENT_URINATION"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="frequent_urination" name="frequent_urination"
                                       type="radio" value="RARELY_FREQUENT_URINATION">
                                <span class="ml-10">Rerely</span>
                                <br>
                                <input class="ml-10" formControlName="frequent_urination" name="frequent_urination"
                                       type="radio" value="SOMETIMES_FREQUENT_URINATION"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="frequent_urination" name="frequent_urination"
                                       type="radio" value="OFTEN_FREQUENT_URINATION"><span
                                class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Are you feeling post coital pain / bleeding ?</label>
                                <br>
                                <input class="ml-10" formControlName="coital_pain_bleeding" name="coital_pain_bleeding"
                                       type="radio" value="NEVER_COITAL_BLEEDING"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="coital_pain_bleeding" name="coital_pain_bleeding"
                                       type="radio" value="RARELY_COITAL_BLEEDING">
                                <span class="ml-10">Rerely</span>
                                <br>
                                <input class="ml-10" formControlName="coital_pain_bleeding" name="coital_pain_bleeding"
                                       type="radio" value="SOMETIMES_COITAL_BLEEDING"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="coital_pain_bleeding" name="coital_pain_bleeding"
                                       type="radio" value="OFTEN_COITAL_BLEEDING"><span
                                class="ml-10">Often</span>
                            </div>
                        </div>
                        <div class="content" id="test-women-care-savior-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && womenCareForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && womenCareForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && womenCareForm && womenCareForm.controls && womenCareForm.controls['email'] && womenCareForm.controls['email'].errors && womenCareForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && womenCareForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && womenCareForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && womenCareForm && womenCareForm.controls && womenCareForm.controls['contact'] && womenCareForm.controls['contact'].errors && womenCareForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && womenCareForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'male-health-assessment'" class="card-body">
                <form [formGroup]="maleRejuvenatorForm" class="bs-stepper" id="stepperMaleRejuvenator" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-male-rejuvenator-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-male-rejuvenator-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-male-rejuvenator-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-male-rejuvenator-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical History</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="DIABETIC"><span class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="HYPERTENSION"><span class="ml-10">Hypertension</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="THYROID"><span class="ml-10">Thyroid</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="OTHER"><span class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have the same
                                        problem)</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="MOTHER"><span class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="FATHER"><span class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="SIBLINGS"><span class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE"><span class="ml-10">None</span>
                                </div>
                                <label class="font-bold">3. Sexual desire / interest</label>
                                <br>
                                <input class="ml-10" formControlName="sexual_desire_interest"
                                       name="sexual_desire_interest"
                                       type="radio" value="OFTEN_SEXUAL_DESIRE"/><span
                                class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="sexual_desire_interest"
                                       name="sexual_desire_interest"
                                       type="radio" value="SOMETIME_SEXUAL_DESIRE"/><span
                                class="ml-10">Sometime</span>
                                <br>
                                <input class="ml-10" formControlName="sexual_desire_interest"
                                       name="sexual_desire_interest"
                                       type="radio" value="RARELY_SEXUAL_DESIRE"/><span
                                class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="sexual_desire_interest"
                                       name="sexual_desire_interest"
                                       type="radio" value="NEVER_SEXUAL_DESIRE"/><span
                                class="ml-10">Never</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Rapid and delayed ejaculation</label>
                                <br>
                                <input class="ml-10" formControlName="rapid_delayed_ejaculation"
                                       name="rapid_delayed_ejaculation"
                                       type="radio"
                                       value="OFTEN_DELAYED_EJACULATION"/><span class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="rapid_delayed_ejaculation"
                                       name="rapid_delayed_ejaculation"
                                       type="radio"
                                       value="SOMETHING_DELAYED_EJACULATION">
                                <span class="ml-10">Sometime</span>
                                <br>
                                <input class="ml-10" formControlName="rapid_delayed_ejaculation"
                                       name="rapid_delayed_ejaculation"
                                       type="radio"
                                       value="RARELY_DELAYED_EJACULATION"><span
                                class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="rapid_delayed_ejaculation"
                                       name="rapid_delayed_ejaculation"
                                       type="radio"
                                       value="NEVER_DELAYED_EJACULATION"><span
                                class="ml-10">Never</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Pain</label>
                                <br>
                                <input class="ml-10" formControlName="pain" name="pain" type="radio"
                                       value="OFTEN_PAIN"/><span class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="pain" name="pain" type="radio"
                                       value="SOMETHING_PAIN">
                                <span class="ml-10">Sometime</span>
                                <br>
                                <input class="ml-10" formControlName="pain" name="pain" type="radio"
                                       value="RARELY_PAIN"><span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="pain" name="pain" type="radio"
                                       value="NEVER_PAIN"><span class="ml-10">Never</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Bowel Movements</label>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="OFTEN_BOWEL_MOMENTS"/><span class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="SOMETIMES_BOWEL_MOMENTS">
                                <span class="ml-10">Sometime</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="RARELY_BOWEL_MOMENTS"><span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="NEVER_BOWEL_MOMENTS"><span class="ml-10">Never</span>
                            </div>
                        </div>
                        <div class="content" id="test-male-rejuvenator-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Food habit</label>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ALCOHOL_FOOD_HABIT"/><span class="ml-10">Alcohol</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ADDED_SUGAR_FOOD_HABIT">
                                <span class="ml-10">Added Sugar</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="FRIED_FOODS_FOOD_HABIT"><span class="ml-10">Fried Foods</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ADDED_SALT_FOOD_HABIT"><span class="ml-10">Added Salt</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="RED_MEAT_FOOD_HABIT"><span class="ml-10">Red Meat</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="NONE_OF_THESE_FOOD_HABIT"><span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Are you physically active ?</label>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio" value="NEVER_FEELING_STRESSED"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio" value="RARELY_FEELING_STRESSED">
                                <span class="ml-10">Rerely</span>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio"
                                       value="SOMETIMES_FEELING_STRESSED"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="feeling_stressed_anxiety"
                                       name="feeling_stressed_anxiety"
                                       type="radio" value="OFTEN_FEELING_STRESSED"><span
                                class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Do you drink Alcohol ?</label>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="NEVER_DRINK_ALCOHOL"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol"
                                       type="radio" value="OCCASIONALLY_SOMETIMES_DRINK_ALCOHOL">
                                <span class="ml-10">Occasionally sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="YES_DAILY_DRINK_ALCOHOL"><span class="ml-10">Yes, daily</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Do you feel weak ?</label>
                                <br>
                                <input class="ml-10" formControlName="feel_weak" name="feel_weak" type="radio"
                                       value="NEVER_FEEL_WEAK"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="feel_weak" name="feel_weak" type="radio"
                                       value="RARELY_FEEL_WEAK">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="feel_weak" name="feel_weak" type="radio"
                                       value="SOMETIMES_FEEL_WEAK"><span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="feel_weak" name="feel_weak" type="radio"
                                       value="OFTEN_FEEL_WEAK"><span class="ml-10">Often</span>
                            </div>
                        </div>
                        <div class="content" id="test-male-rejuvenator-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && maleRejuvenatorForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && maleRejuvenatorForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && maleRejuvenatorForm && maleRejuvenatorForm.controls && maleRejuvenatorForm.controls['email'] && maleRejuvenatorForm.controls['email'].errors && maleRejuvenatorForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && maleRejuvenatorForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && maleRejuvenatorForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && womenCareForm && maleRejuvenatorForm.controls && maleRejuvenatorForm.controls['contact'] && maleRejuvenatorForm.controls['contact'].errors && maleRejuvenatorForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && maleRejuvenatorForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'obesity-assessment'" class="card-body">
                <form [formGroup]="obesityPacifierForm" class="bs-stepper" id="stepperObesityPacifier" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-obesity-pacifier-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-obesity-pacifier-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-obesity-pacifier-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-obesity-pacifier-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. How much did you weigh in high school ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="weigh_in_high_school"
                                           name="weigh_in_high_school" type="radio"
                                           value="NORMAL"><span class="ml-10">Normal</span>
                                    <br>
                                    <input class="ml-10" formControlName="weigh_in_high_school"
                                           name="weigh_in_high_school" type="radio"
                                           value="UNDER_WEIGHT"><span
                                    class="ml-10">Under Weight</span>
                                    <br>
                                    <input class="ml-10" formControlName="weigh_in_high_school"
                                           name="weigh_in_high_school" type="radio"
                                           value="OBESE"><span class="ml-10">Obese</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. When did you start to gain weight ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="start_gain_weight" name="start_gain_weight"
                                           type="radio"
                                           value="SUDDENLY"><span class="ml-10">Suddenly</span>
                                    <br>
                                    <input class="ml-10" formControlName="start_gain_weight" name="start_gain_weight"
                                           type="radio"
                                           value="1_2_YEARS"><span class="ml-10">1-2 Years</span>
                                    <br>
                                    <input class="ml-10" formControlName="start_gain_weight" name="start_gain_weight"
                                           type="radio" value="AFTER_ANY_MEDICAL_EMERGENCY"><span
                                    class="ml-10">After any medical emergency</span>
                                    <br>
                                    <input class="ml-10" formControlName="start_gain_weight" name="start_gain_weight"
                                           type="radio"
                                           value="AFTER_MENOPAUSE"><span
                                    class="ml-10">After Menopause</span>
                                </div>
                                <label class="font-bold">3. How many times do you eat in a typical day ?</label>
                                <br>
                                <input class="ml-10" formControlName="times_eat_in_a_day" name="times_eat_in_a_day"
                                       type="radio"
                                       value="1_TIME"/><span class="ml-10">1 time</span>
                                <br>
                                <input class="ml-10" formControlName="times_eat_in_a_day" name="times_eat_in_a_day"
                                       type="radio"
                                       value="2_TIME"/><span class="ml-10">2 times</span>
                                <br>
                                <input class="ml-10" formControlName="times_eat_in_a_day" name="times_eat_in_a_day"
                                       type="radio"
                                       value="3_TIMES"/><span class="ml-10">3 times</span>
                                <br>
                                <input class="ml-10" formControlName="times_eat_in_a_day" name="times_eat_in_a_day"
                                       type="radio"
                                       value="4_TIMES_MORE"/><span class="ml-10">4 times or more</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Are you doing any kind of exercise ?</label>
                                <br>
                                <input class="ml-10" formControlName="doing_any_kind_of_excersie"
                                       name="doing_any_kind_of_excersie"
                                       type="radio" value="NEVER_DOING_EXERCISE"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="doing_any_kind_of_excersie"
                                       name="doing_any_kind_of_excersie"
                                       type="radio" value="RARELY_DOING_EXERSIZE">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="doing_any_kind_of_excersie"
                                       name="doing_any_kind_of_excersie"
                                       type="radio"
                                       value="SOMETIMES_DOING_EXERSIZE"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="doing_any_kind_of_excersie"
                                       name="doing_any_kind_of_excersie"
                                       type="radio" value="OFTEN_DOING_EXERSIZE"><span
                                class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Medical History</label>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="DIABETIC"/><span class="ml-10">Diabetic</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="HYPERTENSIONS">
                                <span class="ml-10">Hypertensions</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="THYROID"><span class="ml-10">Thyroid</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="OTHER"><span class="ml-10">Other</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. What do you think might prevent you from losing weight
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="prevent_from_losing_weight"
                                       name="prevent_from_losing_weight"
                                       type="radio" value="LACK_OF_EXCERCISE"/><span
                                class="ml-10">Lack of exercise</span>
                                <br>
                                <input class="ml-10" formControlName="prevent_from_losing_weight"
                                       name="prevent_from_losing_weight"
                                       type="radio" value="EATING_JUNK_FOOD">
                                <span class="ml-10">Eating junk food</span>
                                <br>
                                <input class="ml-10" formControlName="prevent_from_losing_weight"
                                       name="prevent_from_losing_weight"
                                       type="radio"
                                       value="DRINKING_CARBONATED_SUGARY_ALCOHOLIC_DRINKS"><span class="ml-10">Drinking carbonated
                                    / sugary / alcoholic drinks</span>
                                <br>
                                <input class="ml-10" formControlName="prevent_from_losing_weight"
                                       name="prevent_from_losing_weight"
                                       type="radio" value="NOT_SLEPPING_WELL"><span
                                class="ml-10">Not sleeping well</span>
                                <br>
                                <input class="ml-10" formControlName="prevent_from_losing_weight"
                                       name="prevent_from_losing_weight"
                                       type="radio"
                                       value="HAVING_MEDICAL_CONDITION"><span
                                class="ml-10">Having a medical condition</span>
                            </div>
                        </div>
                        <div class="content" id="test-obesity-pacifier-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Are you taking any kind of steroid / thyroid medication
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="taking_steroid_thyroid_med"
                                       name="taking_steroid_thyroid_med"
                                       type="radio"
                                       value="NEVER_STERIOD_THYROID_MED"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="taking_steroid_thyroid_med"
                                       name="taking_steroid_thyroid_med"
                                       type="radio" value="RARELY_STERIOD_THYROID_MED">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="taking_steroid_thyroid_med"
                                       name="taking_steroid_thyroid_med"
                                       type="radio"
                                       value="SOMETIMES_STERIOD_THYROID_MED"><span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="taking_steroid_thyroid_med"
                                       name="taking_steroid_thyroid_med"
                                       type="radio"
                                       value="OFTEN_STERIOD_THYROID_MED"><span
                                class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Are your mother / father / siblings over weight ?</label>
                                <br>
                                <input class="ml-10" formControlName="mother_father_sibling_overweight"
                                       name="mother_father_sibling_overweight"
                                       type="radio"
                                       value="YES_MOTHER_FATHER_SIBLING_OVERWEIGHT"/><span
                                class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="mother_father_sibling_overweight"
                                       name="mother_father_sibling_overweight"
                                       type="radio"
                                       value="NO_MOTHER_FATHER_SIBLING_OVERWEIGHT">
                                <span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Are you taking any kind of medications to lose weight
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="taking_any_med_to_loose_weight"
                                       name="taking_any_med_to_loose_weight"
                                       type="radio"
                                       value="NEVER_TAKING_ANY_MED_TO_LOOSE_WEIGHT"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="taking_any_med_to_loose_weight"
                                       name="taking_any_med_to_loose_weight"
                                       type="radio"
                                       value="RARELY_TAKING_ANY_MED_TO_LOOSE_WEIGHT">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="taking_any_med_to_loose_weight"
                                       name="taking_any_med_to_loose_weight"
                                       type="radio"
                                       value="SOMETIMES_TAKING_ANY_MED_TO_LOOSE_WEIGHT"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="taking_any_med_to_loose_weight"
                                       name="taking_any_med_to_loose_weight"
                                       type="radio"
                                       value="OFTEN_TAKING_ANY_MED_TO_LOOSE_WEIGHT"><span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Bowel movements ?</label>
                                <br>
                                <input class="ml-10" formControlName="bowel_movements" name="bowel_movements"
                                       type="radio"
                                       value="REGULAR_BOWEL_MOVEMENTS"/><span class="ml-10">Regular</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movements" name="bowel_movements"
                                       type="radio" value="CONSTIPATION_BOWEL_MOVEMENTS">
                                <span class="ml-10">Constipation</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movements" name="bowel_movements"
                                       type="radio"
                                       value="NONE_BOWEL_MOVEMENTS"><span class="ml-10">None</span>
                            </div>
                        </div>
                        <div class="content" id="test-obesity-pacifier-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && obesityPacifierForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && obesityPacifierForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && obesityPacifierForm && obesityPacifierForm.controls && obesityPacifierForm.controls['email'] && obesityPacifierForm.controls['email'].errors && obesityPacifierForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && obesityPacifierForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && obesityPacifierForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && obesityPacifierForm && obesityPacifierForm.controls && obesityPacifierForm.controls['contact'] && obesityPacifierForm.controls['contact'].errors && obesityPacifierForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && obesityPacifierForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'diabetes-assessment'" class="card-body">
                <form [formGroup]="diabetesManagerForm" class="bs-stepper" id="stepperDiabetesManager" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-diabetes-manager-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-diabetes-manager-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-diabetes-manager-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-diabetes-manager-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical history</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="DIABETES"><span class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="HYPERTENSIONS"><span class="ml-10">Hypertensions</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="THYROID"><span class="ml-10">Thyroid</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio"
                                           value="OTHER"><span class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have same
                                        problem)</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="MOTHER"><span class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="FATHER"><span class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="SIBLING"><span class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE"><span class="ml-10">None</span>
                                </div>
                                <label class="font-bold">3. How long have you been suffering from diabetes ?</label>
                                <br>
                                <input class="ml-10" formControlName="how_long_suffering_from_diabetes"
                                       name="how_long_suffering_from_diabetes"
                                       type="radio"
                                       value="LESS_THAN_1_YEAR"/><span
                                class="ml-10">Less than 1 year</span>
                                <br>
                                <input class="ml-10" formControlName="how_long_suffering_from_diabetes"
                                       name="how_long_suffering_from_diabetes"
                                       type="radio" value="1_2_YEARS"/><span
                                class="ml-10">1 to 2 years</span>
                                <br>
                                <input class="ml-10" formControlName="how_long_suffering_from_diabetes"
                                       name="how_long_suffering_from_diabetes"
                                       type="radio" value="2_3_YEARS"/><span
                                class="ml-10">2 to 3 years</span>
                                <br>
                                <input class="ml-10" formControlName="how_long_suffering_from_diabetes"
                                       name="how_long_suffering_from_diabetes"
                                       type="radio"
                                       value="MORE_THAN_3_YEARS"/><span
                                class="ml-10">More than 3 years</span>
                            </div>
                        </div>
                        <div class="content" id="test-diabetes-manager-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Are you overweight or obese ?</label>
                                <br>
                                <input class="ml-10" formControlName="overweight_or_obese" name="overweight_or_obese"
                                       type="radio" value="YES_OVERWEIGHT_OBESE"/><span
                                class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="overweight_or_obese" name="overweight_or_obese"
                                       type="radio"
                                       value="NO_OVERWEIGHT_OBESE">
                                <span class="ml-10">No</span>
                                <br>
                                <input class="ml-10" formControlName="overweight_or_obese" name="overweight_or_obese"
                                       type="radio" value="DONT_KNOW_OVERWEIGHT_OBESE"><span
                                class="ml-10">Don't Know</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Are you physically active ?</label>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio"
                                       value="YES_PHYSICALLY_ACTIVE"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio"
                                       value="NO_PHYSICALLY_ACTIVE">
                                <span class="ml-10">No</span>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio" value="SOMETIMES_PHYSICALLY_ACTIVE">
                                <span class="ml-10">Sometimes</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Are you suffering from any diabetes related complications
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="diabetes_related_complications"
                                       name="diabetes_related_complications"
                                       type="radio"
                                       value="YES_DIABETES_RELATED_COMPLICATIONS"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="diabetes_related_complications"
                                       name="diabetes_related_complications"
                                       type="radio"
                                       value="NO_DIABETES_RELATED_COMPLICATIONS">
                                <span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Medicines you are already taking</label>
                                <br>
                                <input class="ml-10" formControlName="medicines_already_taking"
                                       name="medicines_already_taking"
                                       type="radio"
                                       value="ALLOPATHIC_ALREADY_TAKING"/><span
                                class="ml-10">Allopathic</span>
                                <br>
                                <input class="ml-10" formControlName="medicines_already_taking"
                                       name="medicines_already_taking"
                                       type="radio" value="AYURVEDIC_ALREADY_TAKING">
                                <span class="ml-10">Ayurvedic</span>
                                <br>
                                <input class="ml-10" formControlName="medicines_already_taking"
                                       name="medicines_already_taking"
                                       type="radio" value="PANCHAKARMA_ALREADY_TAKING">
                                <span class="ml-10">Panchakarma</span>
                                <br>
                                <input class="ml-10" formControlName="medicines_already_taking"
                                       name="medicines_already_taking"
                                       type="radio" value="NONE_ALREADY_TAKING">
                                <span class="ml-10">None</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Are you doing exercises / walk ?</label>
                                <br>
                                <input class="ml-10" formControlName="doing_excercises_walk"
                                       name="doing_excercises_walk"
                                       type="radio" value="NEVER_EXCERCISE_WALK"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="doing_excercises_walk"
                                       name="doing_excercises_walk"
                                       type="radio" value="RARELY_EXCERCISE_WALK">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="doing_excercises_walk"
                                       name="doing_excercises_walk"
                                       type="radio" value="SOMETIMES_EXCERCISE_WALK">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="doing_excercises_walk"
                                       name="doing_excercises_walk"
                                       type="radio" value="OFTEN_EXCERCISE_WALK">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Do you take proper diabetic diet ?</label>
                                <br>
                                <input class="ml-10" formControlName="proper_diabetic_diet" name="proper_diabetic_diet"
                                       type="radio" value="NO_PROPER_DIABETIC_DIET"/><span
                                class="ml-10">No</span>
                                <br>
                                <input class="ml-10" formControlName="proper_diabetic_diet" name="proper_diabetic_diet"
                                       type="radio" value="SOMETIMES_PROPER_DIABETIC_DIET">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="proper_diabetic_diet" name="proper_diabetic_diet"
                                       type="radio" value="OFTEN_PROPER_DIABETIC_DIET">
                                <span class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="proper_diabetic_diet" name="proper_diabetic_diet"
                                       type="radio" value="ALWAYS_PROPER_DIABETIC_DIET">
                                <span class="ml-10">Always</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Are you checking your blood sugar levels regularly
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="checking_blood_sugar_level_regularly"
                                       name="checking_blood_sugar_level_regularly"
                                       type="radio"
                                       value="NOT_CHECKING_AT_ALL"/><span class="ml-10">Not
                                    checking at all</span>
                                <br>
                                <input class="ml-10" formControlName="checking_blood_sugar_level_regularly"
                                       name="checking_blood_sugar_level_regularly"
                                       type="radio"
                                       value="SOMETIMES_CHECKING_AT_ALL">
                                <span class="ml-10">Checking sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="checking_blood_sugar_level_regularly"
                                       name="checking_blood_sugar_level_regularly"
                                       type="radio"
                                       value="REGULARLY_CHECKING_AT_ALL">
                                <span class="ml-10">Checking always/regularly</span>
                            </div>
                        </div>
                        <div class="content" id="test-diabetes-manager-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && diabetesManagerForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && diabetesManagerForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && diabetesManagerForm && diabetesManagerForm.controls && diabetesManagerForm.controls['email'] && diabetesManagerForm.controls['email'].errors && diabetesManagerForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && diabetesManagerForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && diabetesManagerForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && diabetesManagerForm && diabetesManagerForm.controls && diabetesManagerForm.controls['contact'] && diabetesManagerForm.controls['contact'].errors && diabetesManagerForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && diabetesManagerForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'thyroid-assessment'" class="card-body">
                <form [formGroup]="thyroGuardForm" class="bs-stepper" id="stepperThyroGuard" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-thyro-guard-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-thyro-guard-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-thyro-guard-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-thyro-guard-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical history</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="DIABETES_MEDICAL_HISTORY"><span
                                    class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="HYPERTENSIONS_MEDICAL_HISTORY"><span
                                    class="ml-10">Hypertensions</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="GOITRE_MEDICAL_HISTORY"><span
                                    class="ml-10">Goitre</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="OTHER_MEDICAL_HISTORY"><span
                                    class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have same
                                        problem)</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="MOTHER_FAMILY_HISOTRY"><span
                                    class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="FATHER_FAMILY_HISOTRY"><span
                                    class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="SIBLING_FAMILY_HISOTRY"><span
                                    class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE_FAMILY_HISOTRY"><span class="ml-10">None</span>
                                </div>
                                <label class="font-bold">3. Do you have hair falling out ?</label>
                                <br>
                                <input class="ml-10" formControlName="hair_falling" name="hair_falling" type="radio"
                                       value="YES_HAIR_FALLING"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="hair_falling" name="hair_falling" type="radio"
                                       value="NO_HAIR_FALLING"/><span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Is your weight increasing very fast ?</label>
                                <br>
                                <input class="ml-10" formControlName="weight_increasing_very_fast"
                                       name="weight_increasing_very_fast"
                                       type="radio"
                                       value="YES_WEIGHT_INCREASING_FAST"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="weight_increasing_very_fast"
                                       name="weight_increasing_very_fast"
                                       type="radio" value="NO_WEIGHT_INCREASING_FAST">
                                <span class="ml-10">No</span>
                                <br>
                                <input class="ml-10" formControlName="weight_increasing_very_fast"
                                       name="weight_increasing_very_fast"
                                       type="radio"
                                       value="MAY_BE_WEIGHT_INCREASING_FAST"><span class="ml-10">May be</span>
                            </div>
                        </div>
                        <div class="content" id="test-thyro-guard-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Do you face no weight reduction after doing Gym and regular
                                    exercise ?</label>
                                <br>
                                <input class="ml-10" formControlName="no_weight_reduction_after_gym"
                                       name="no_weight_reduction_after_gym"
                                       type="radio"
                                       value="REDUCTION_AFTER_GYM"/><span
                                class="ml-10">Weight reduction is there</span>
                                <br>
                                <input class="ml-10" formControlName="no_weight_reduction_after_gym"
                                       name="no_weight_reduction_after_gym"
                                       type="radio" value="NO_REDUCTION_AFTER_GYM">
                                <span class="ml-10">No reduction in weight</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Do you feel muscle weakness ?</label>
                                <br>
                                <input class="ml-10" formControlName="muscle_weakness" name="muscle_weakness"
                                       type="radio" value="YES_FEEL_MUSCLE_WEAKNESS"/><span
                                class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="muscle_weakness" name="muscle_weakness"
                                       type="radio"
                                       value="NO_FEEL_MUSCLE_WEAKNESS">
                                <span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Do you feel puffiness and swelling on your face ?</label>
                                <br>
                                <input class="ml-10" formControlName="feel_puffiness_swelling_on_face"
                                       name="feel_puffiness_swelling_on_face"
                                       type="radio"
                                       value="NEVER_FEEL_PUFFINESS_SWELLING"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="feel_puffiness_swelling_on_face"
                                       name="feel_puffiness_swelling_on_face"
                                       type="radio"
                                       value="RARELY_FEEL_PUFFINESS_SWELLING">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="feel_puffiness_swelling_on_face"
                                       name="feel_puffiness_swelling_on_face"
                                       type="radio"
                                       value="SOMETIMES_FEEL_PUFFINESS_SWELLING">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="feel_puffiness_swelling_on_face"
                                       name="feel_puffiness_swelling_on_face"
                                       type="radio"
                                       value="OFTEN_FEEL_PUFFINESS_SWELLING">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Do you feel any muscular fatigue and lethargic or loss of
                                    energy ?</label>
                                <br>
                                <input class="ml-10" formControlName="feel_muscular_fatigue_lethargy"
                                       name="feel_muscular_fatigue_lethargy"
                                       type="radio"
                                       value="NEVER_MUSCULAR_FATIGUE_LETHARGIC"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="feel_muscular_fatigue_lethargy"
                                       name="feel_muscular_fatigue_lethargy"
                                       type="radio"
                                       value="RARELY_MUSCULAR_FATIGUE_LETHARGIC">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="feel_muscular_fatigue_lethargy"
                                       name="feel_muscular_fatigue_lethargy"
                                       type="radio"
                                       value="SOMETIMES_MUSCULAR_FATIGUE_LETHARGIC">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="feel_muscular_fatigue_lethargy"
                                       name="feel_muscular_fatigue_lethargy"
                                       type="radio"
                                       value="OFTEN_MUSCULAR_FATIGUE_LETHARGIC">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Bowel movement ?</label>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="REGULAR_BOWEL_MOVEMENT"/><span class="ml-10">Regular</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement"
                                       type="radio" value="CONSTIPATION_BOWEL_MOVEMENT">
                                <span class="ml-10">Constipation</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="NONE_BOWEL_MOVEMENT">
                                <span class="ml-10">None</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. How many times do you eat in a typical day ?</label>
                                <br>
                                <input class="ml-10" formControlName="how_many_times_eat" name="how_many_times_eat"
                                       type="radio"
                                       value="1_TIME"/><span class="ml-10">1 time</span>
                                <br>
                                <input class="ml-10" formControlName="how_many_times_eat" name="how_many_times_eat"
                                       type="radio"
                                       value="2_TIMES">
                                <span class="ml-10">2 times</span>
                                <br>
                                <input class="ml-10" formControlName="how_many_times_eat" name="how_many_times_eat"
                                       type="radio"
                                       value="2_3_TIMES">
                                <span class="ml-10">2-3 times</span>
                                <br>
                                <input class="ml-10" formControlName="how_many_times_eat" name="how_many_times_eat"
                                       type="radio"
                                       value="4_OR_MORE_TIMES">
                                <span class="ml-10">4 or more times</span>
                            </div>
                        </div>
                        <div class="content" id="test-thyro-guard-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && thyroGuardForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && thyroGuardForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && thyroGuardForm && thyroGuardForm.controls && thyroGuardForm.controls['email'] && thyroGuardForm.controls['email'].errors && thyroGuardForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && thyroGuardForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && thyroGuardForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && thyroGuardForm && thyroGuardForm.controls && thyroGuardForm.controls['contact'] && thyroGuardForm.controls['contact'].errors && thyroGuardForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && thyroGuardForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'acidity-assessment'" class="card-body">
                <form [formGroup]="acidityUprooterForm" class="bs-stepper" id="stepperAcidityUprooter" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-acidity-uprooter-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-acidity-uprooter-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-acidity-uprooter-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-acidity-uprooter-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical history</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="DIABETES_MEDICAL_HISTORY"><span
                                    class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="HYPERTENSIONS_MEDICAL_HISTORY"><span
                                    class="ml-10">Hypertensions</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="THYROID_MEDICAL_HISTORY"><span
                                    class="ml-10">Thyroid</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="OTHER_MEDICAL_HISTORY"><span
                                    class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have same
                                        problem)</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="MOTHER_FAMILY_HISOTRY"><span
                                    class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="FATHER_FAMILY_HISOTRY"><span
                                    class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="SIBLING_FAMILY_HISOTRY"><span
                                    class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE_FAMILY_HISOTRY"><span class="ml-10">None</span>
                                </div>
                                <label class="font-bold">3. Do you experience heaviness in your body or head ?</label>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head"
                                       type="radio"
                                       value="NEVER_HEAVINESS_IN_BODY_HEAD"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head"
                                       type="radio"
                                       value="RARELY_HEAVINESS_IN_BODY_HEAD"/><span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head"
                                       type="radio"
                                       value="SOMETIMES_HEAVINESS_IN_BODY_HEAD"/><span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="heaviness_in_body_head"
                                       name="heaviness_in_body_head"
                                       type="radio"
                                       value="OFTEN_HEAVINESS_IN_BODY_HEAD"/><span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. How is your appetite ?</label>
                                <br>
                                <input class="ml-10" formControlName="appetite" name="appetite" type="radio"
                                       value="REGULAR_NORMAL_APPETITE"/><span class="ml-10">Regular & Normal</span>
                                <br>
                                <input class="ml-10" formControlName="appetite" name="appetite" type="radio"
                                       value="MODERATE_APPETITE">
                                <span class="ml-10">Moderate</span>
                                <br>
                                <input class="ml-10" formControlName="appetite" name="appetite" type="radio"
                                       value="EXCESSIVE_APPETITE"><span class="ml-10">Excessive</span>
                            </div>
                        </div>
                        <div class="content" id="test-acidity-uprooter-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Do you have gas and bloating issues ?</label>
                                <br>
                                <input class="ml-10" formControlName="gas_bloating_issues" name="gas_bloating_issues"
                                       type="radio" value="NEVER_BLOATING_ISSUES"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="gas_bloating_issues" name="gas_bloating_issues"
                                       type="radio" value="RARELY_BLOATING_ISSUES">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="gas_bloating_issues" name="gas_bloating_issues"
                                       type="radio" value="SOMETIMES_BLOATING_ISSUES">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="gas_bloating_issues" name="gas_bloating_issues"
                                       type="radio" value="OFTEN_BLOATING_ISSUES">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Do you experience acidity or heartburn ?</label>
                                <br>
                                <input class="ml-10" formControlName="experience_acidity_heartburn"
                                       name="experience_acidity_heartburn"
                                       type="radio"
                                       value="NEVER_ACIDITY_HEARTBURN"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="experience_acidity_heartburn"
                                       name="experience_acidity_heartburn"
                                       type="radio" value="RARELY_ACIDITY_HEARTBURN">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="experience_acidity_heartburn"
                                       name="experience_acidity_heartburn"
                                       type="radio"
                                       value="SOMETIMES_ACIDITY_HEARTBURN">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="experience_acidity_heartburn"
                                       name="experience_acidity_heartburn"
                                       type="radio" value="OFTEN_ACIDITY_HEARTBURN">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Do you experience nausea ?</label>
                                <br>
                                <input class="ml-10" formControlName="experience_nausea" name="experience_nausea"
                                       type="radio"
                                       value="NEVER_NAUSEA"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="experience_nausea" name="experience_nausea"
                                       type="radio"
                                       value="RARELY_NAUSEA">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="experience_nausea" name="experience_nausea"
                                       type="radio"
                                       value="SOMETIMES_NAUSEA">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="experience_nausea" name="experience_nausea"
                                       type="radio"
                                       value="OFTEN_NAUSEA">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Do you have bad breath ?</label>
                                <br>
                                <input class="ml-10" formControlName="bad_breath" name="bad_breath" type="radio"
                                       value="NEVER_BAD_BREATH"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="bad_breath" name="bad_breath" type="radio"
                                       value="RARELY_BAD_BREATH">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="bad_breath" name="bad_breath" type="radio"
                                       value="SOMETIMES_BAD_BREATH">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="bad_breath" name="bad_breath" type="radio"
                                       value="OFTEN_BAD_BREATH">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Do you experience mucous or irritation in the throat
                                    ?</label>
                                <br>
                                <input class="ml-10" formControlName="mucous_irritation_in_throat"
                                       name="mucous_irritation_in_throat"
                                       type="radio"
                                       value="NEVER_IRRITATION_IN_THROAT"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="mucous_irritation_in_throat"
                                       name="mucous_irritation_in_throat"
                                       type="radio"
                                       value="RARELY_IRRITATION_IN_THROAT">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="mucous_irritation_in_throat"
                                       name="mucous_irritation_in_throat"
                                       type="radio"
                                       value="SOMETIMES_IRRITATION_IN_THROAT">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="mucous_irritation_in_throat"
                                       name="mucous_irritation_in_throat"
                                       type="radio" value="OFTEN_IRRITATION_IN_THROAT">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Do you experience difficulty in bowel evacuation ?</label>
                                <br>
                                <input class="ml-10" formControlName="difficulty_in_bowel_evacuation"
                                       name="difficulty_in_bowel_evacuation"
                                       type="radio"
                                       value="NEVER_BOWEL_EVACUATION"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="difficulty_in_bowel_evacuation"
                                       name="difficulty_in_bowel_evacuation"
                                       type="radio" value="RARELY_BOWEL_EVACUATION">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="difficulty_in_bowel_evacuation"
                                       name="difficulty_in_bowel_evacuation"
                                       type="radio"
                                       value="SOMETIMES_BOWEL_EVACUATION">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="difficulty_in_bowel_evacuation"
                                       name="difficulty_in_bowel_evacuation"
                                       type="radio" value="OFTEN_BOWEL_EVACUATION">
                                <span class="ml-10">Often</span>
                            </div>
                        </div>
                        <div class="content" id="test-acidity-uprooter-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && acidityUprooterForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && acidityUprooterForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && acidityUprooterForm && acidityUprooterForm.controls && acidityUprooterForm.controls['email'] && acidityUprooterForm.controls['email'].errors && acidityUprooterForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && acidityUprooterForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && acidityUprooterForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && acidityUprooterForm && acidityUprooterForm.controls && acidityUprooterForm.controls['contact'] && acidityUprooterForm.controls['contact'].errors && acidityUprooterForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && acidityUprooterForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'joints-assessment'" class="card-body">
                <form [formGroup]="orthoFortifyForm" class="bs-stepper" id="stepperOrthoFortify" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-ortho-fortify-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-ortho-fortify-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-ortho-fortify-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-ortho-fortify-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical history</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="DIABETES_MEDICAL_HISTORY"><span
                                    class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="HYPERTENSIONS_MEDICAL_HISTORY"><span
                                    class="ml-10">Hypertensions</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="THYROID_MEDICAL_HISTORY"><span
                                    class="ml-10">Thyroid</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="OTHER_MEDICAL_HISTORY"><span
                                    class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have same
                                        problem)</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="MOTHER_FAMILY_HISOTRY"><span
                                    class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="FATHER_FAMILY_HISOTRY"><span
                                    class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="SIBLING_FAMILY_HISOTRY"><span
                                    class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE_FAMILY_HISOTRY"><span class="ml-10">None</span>
                                </div>
                                <label class="font-bold">3. How would you describe the pain you usually have in your
                                    joints / knee joint ?</label>
                                <br>
                                <input class="ml-10" formControlName="pain_in_joint_knee_joint"
                                       name="pain_in_joint_knee_joint"
                                       type="radio" value="NONE_PAIN_IN_JOINT"/><span
                                class="ml-10">None</span>
                                <br>
                                <input class="ml-10" formControlName="pain_in_joint_knee_joint"
                                       name="pain_in_joint_knee_joint"
                                       type="radio" value="VERY_MILD_PAIN_IN_JOINT"/><span
                                class="ml-10">Very Mild</span>
                                <br>
                                <input class="ml-10" formControlName="pain_in_joint_knee_joint"
                                       name="pain_in_joint_knee_joint"
                                       type="radio" value="MILD_PAIN_IN_JOINT"/><span
                                class="ml-10">Mild</span>
                                <br>
                                <input class="ml-10" formControlName="pain_in_joint_knee_joint"
                                       name="pain_in_joint_knee_joint"
                                       type="radio" value="SEVERE_PAIN_IN_JOINT"/><span
                                class="ml-10">Severe</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Have you had swelling over joints / knee joint ?</label>
                                <br>
                                <input class="ml-10" formControlName="swelling_over_joint_knee"
                                       name="swelling_over_joint_knee"
                                       type="radio"
                                       value="NONE_SWELLING_OVER_JOINT_KNEE"/><span class="ml-10">None</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_over_joint_knee"
                                       name="swelling_over_joint_knee"
                                       type="radio"
                                       value="VERY_MILD_SWELLING_OVER_JOINT_KNEE">
                                <span class="ml-10">Very Mild</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_over_joint_knee"
                                       name="swelling_over_joint_knee"
                                       type="radio"
                                       value="MILD_SWELLING_OVER_JOINT_KNEE"><span class="ml-10">Mild</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_over_joint_knee"
                                       name="swelling_over_joint_knee"
                                       type="radio"
                                       value="MODERATE_SWELLING_OVER_JOINT_KNEE"><span class="ml-10">Moderate</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_over_joint_knee"
                                       name="swelling_over_joint_knee"
                                       type="radio"
                                       value="SEVERE_SWELLING_OVER_JOINT_KNEE"><span class="ml-10">Severe</span>
                            </div>
                        </div>
                        <div class="content" id="test-ortho-fortify-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Does your joint make a cracking sound ?</label>
                                <br>
                                <input class="ml-10" formControlName="joint_make_cracking_sound"
                                       name="joint_make_cracking_sound"
                                       type="radio" value="OFTEN_CRACKING_SOUND"/><span
                                class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="joint_make_cracking_sound"
                                       name="joint_make_cracking_sound"
                                       type="radio" value="SOMETIMES_CRACKING_SOUND">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="joint_make_cracking_sound"
                                       name="joint_make_cracking_sound"
                                       type="radio" value="RARELY_CRACKING_SOUND">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="joint_make_cracking_sound"
                                       name="joint_make_cracking_sound"
                                       type="radio" value="NEVER_CRACKING_SOUND">
                                <span class="ml-10">Never</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Do you experience stiffness in you joint ?</label>
                                <br>
                                <input class="ml-10" formControlName="stifness_in_joint" name="stifness_in_joint"
                                       type="radio" value="OFTEN_STIFNESS_IN_JOINT"/><span
                                class="ml-10">Often</span>
                                <br>
                                <input class="ml-10" formControlName="stifness_in_joint" name="stifness_in_joint"
                                       type="radio" value="SOMETIMES_STIFNESS_IN_JOINT">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="stifness_in_joint" name="stifness_in_joint"
                                       type="radio" value="RARELY_STIFNESS_IN_JOINT">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="stifness_in_joint" name="stifness_in_joint"
                                       type="radio" value="NEVER_STIFNESS_IN_JOINT">
                                <span class="ml-10">Never</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. For how long are you able to walk before the pain in your
                                    knee becomes severe (with or without a stick) ?</label>
                                <input class="ml-10" formControlName="walk_before_pain_in_knee"
                                       name="walk_before_pain_in_knee"
                                       type="radio"
                                       value="NO_PAIN_60_MINUTE_PAIN_ON_WALKING"/><span class="ml-10">No pain, or longer
                                    than 60 minutes</span>
                                <br>
                                <input class="ml-10" formControlName="walk_before_pain_in_knee"
                                       name="walk_before_pain_in_knee"
                                       type="radio" value="15_60_MINUTE_PAIN_ON_WALKING">
                                <span class="ml-10">15-60 minutes</span>
                                <br>
                                <input class="ml-10" formControlName="walk_before_pain_in_knee"
                                       name="walk_before_pain_in_knee"
                                       type="radio" value="5_15_MINUTE_PAIN_ON_WALKING">
                                <span class="ml-10">5-15 minutes</span>
                                <br>
                                <input class="ml-10" formControlName="walk_before_pain_in_knee"
                                       name="walk_before_pain_in_knee"
                                       type="radio"
                                       value="AROUND_THE_HOUSE_ONLY_PAIN_ON_WALKING">
                                <span class="ml-10">Around the house only</span>
                                <br>
                                <input class="ml-10" formControlName="walk_before_pain_in_knee"
                                       name="walk_before_pain_in_knee"
                                       type="radio"
                                       value="NOT_AT_ALL_SEVERE_PAIN_ON_WALKING">
                                <span class="ml-10">Not at all - severe pain on walking</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Are you taking any kind of treatment for this ?</label>
                                <br>
                                <input class="ml-10" formControlName="taking_any_treatment" name="taking_any_treatment"
                                       type="radio" value="ALLOPATHIC_TREATMENT"/><span
                                class="ml-10">Allopathic</span>
                                <br>
                                <input class="ml-10" formControlName="taking_any_treatment" name="taking_any_treatment"
                                       type="radio" value="AYURVEDIC_TREATMENT">
                                <span class="ml-10">Ayurvedic</span>
                                <br>
                                <input class="ml-10" formControlName="taking_any_treatment" name="taking_any_treatment"
                                       type="radio" value="PANCHAKARMA_TREATMENT">
                                <span class="ml-10">Panchakarma</span>
                                <br>
                                <input class="ml-10" formControlName="taking_any_treatment" name="taking_any_treatment"
                                       type="radio"
                                       value="NONE_TREATMENT">
                                <span class="ml-10">None</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Are you able to do household work on your own ?</label>
                                <br>
                                <input class="ml-10" formControlName="able_to_do_household_work"
                                       name="able_to_do_household_work"
                                       type="radio"
                                       value="YES_EASILY_HOUSE_HOLD_WORK"/><span
                                class="ml-10">Yes, easily</span>
                                <br>
                                <input class="ml-10" formControlName="able_to_do_household_work"
                                       name="able_to_do_household_work"
                                       type="radio" value="WITH_LITTLE_HOUSE_HOLD_WORK">
                                <span class="ml-10">With little difficulty</span>
                                <br>
                                <input class="ml-10" formControlName="able_to_do_household_work"
                                       name="able_to_do_household_work"
                                       type="radio"
                                       value="WITH_MODERATE_HOUSE_HOLD_WORK">
                                <span class="ml-10">With moderate difficulty</span>
                                <br>
                                <input class="ml-10" formControlName="able_to_do_household_work"
                                       name="able_to_do_household_work"
                                       type="radio" value="WITH_EXTREME_HOUSE_HOLD_WORK">
                                <span class="ml-10">With extreme difficulty</span>
                                <br>
                                <input class="ml-10" formControlName="able_to_do_household_work"
                                       name="able_to_do_household_work"
                                       type="radio"
                                       value="NO_IMPOSSIBLE_HOUSE_HOLD_WORK">
                                <span class="ml-10">No, impossible</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Bowel movements</label>
                                <br>
                                <input class="ml-10" formControlName="bowel_movements" name="bowel_movements"
                                       type="radio"
                                       value="REGULAR_BOWEL_MOVEMENTS"/><span class="ml-10">Regular</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movements" name="bowel_movements"
                                       type="radio" value="CONSTIPATION_BOWEL_MOVEMENTS">
                                <span class="ml-10">Constipation</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movements" name="bowel_movements"
                                       type="radio"
                                       value="NONE_BOWEL_MOVEMENTS">
                                <span class="ml-10">None</span>
                            </div>
                        </div>
                        <div class="content" id="test-ortho-fortify-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && orthoFortifyForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && orthoFortifyForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && orthoFortifyForm && orthoFortifyForm.controls && orthoFortifyForm.controls['email'] && orthoFortifyForm.controls['email'].errors && orthoFortifyForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && orthoFortifyForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && orthoFortifyForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && orthoFortifyForm && orthoFortifyForm.controls && orthoFortifyForm.controls['contact'] && orthoFortifyForm.controls['contact'].errors && orthoFortifyForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && orthoFortifyForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'general-health-assessment'" class="card-body">
                <form [formGroup]="detoxManagementForm" class="bs-stepper" id="stepperDetoxManagement" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-detox-management-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-detox-management-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-detox-management-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-detox-management-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Have you heard that your cholesterol levels are abnormal
                                        ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="cholestrol_levels_abnormal"
                                           name="cholestrol_levels_abnormal"
                                           type="radio"
                                           value="YES_CHOLESTROL_LEVELS_ABNORMAL"><span class="ml-10">Yes</span>
                                    <br>
                                    <input class="ml-10" formControlName="cholestrol_levels_abnormal"
                                           name="cholestrol_levels_abnormal"
                                           type="radio"
                                           value="NO_CHOLESTROL_LEVELS_ABNORMAL"><span class="ml-10">No</span>
                                    <br>
                                    <input class="ml-10" formControlName="cholestrol_levels_abnormal"
                                           name="cholestrol_levels_abnormal"
                                           type="radio"
                                           value="DONT_KNOW_CHOLESTROL_LEVELS_ABNORMAL"><span class="ml-10">Don't
                                        Know</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Did you know about fatty liver ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="know_about_fatty_liver"
                                           name="know_about_fatty_liver"
                                           type="radio"
                                           value="YES_KNOW_ABOUT_FATTY_LIVER"><span class="ml-10">Yes</span>
                                    <br>
                                    <input class="ml-10" formControlName="know_about_fatty_liver"
                                           name="know_about_fatty_liver"
                                           type="radio"
                                           value="NO_KNOW_ABOUT_FATTY_LIVER"><span
                                    class="ml-10">No</span>
                                    <br>
                                    <input class="ml-10" formControlName="know_about_fatty_liver"
                                           name="know_about_fatty_liver"
                                           type="radio"
                                           value="MAY_BE_A_LITTLE_KNOW_ABOUT_FATTY_LIVER"><span class="ml-10">May be a
                                        little</span>
                                </div>
                                <label class="font-bold">3. Are you physically active ?</label>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio" value="NEVER_PHYSICALLY_ACTIVE"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio" value="RARELY_PHYSICALLY_ACTIVE"/><span
                                class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio" value="SOMETIMES_PHYSICALLY_ACTIVE"/><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="physically_active" name="physically_active"
                                       type="radio" value="OFTEN_PHYSICALLY_ACTIVE"/><span
                                class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Do you drink Alcohol ?</label>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="NEVER_DRINK_ALCOHOL"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol"
                                       type="radio" value="OCASSIONALLY_DRINK_ALCOHOL">
                                <span class="ml-10">Occasionally sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="DAILY_DRINK_ALCOHOL"><span class="ml-10">Yes, daily</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Medical history ?</label>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio" value="DIABETIC_MEDICAL_HISTORY"/><span
                                class="ml-10">Diabetic</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio" value="HYPERTENSIONS_MEDICAL_HISTORY">
                                <span class="ml-10">Hypertensions</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="THYROID_MEDICAL_HISTORY">
                                <span class="ml-10">Thyroid</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="OTHER_MEDICAL_HISTORY">
                                <span class="ml-10">Other</span>
                            </div>
                        </div>
                        <div class="content" id="test-detox-management-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Urine colour ?</label>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="CLEAR_URINE_COLOR"/><span class="ml-10">Clear urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color"
                                       type="radio" value="YELLOWISH_TO_AMBER_URINE_COLOR">
                                <span class="ml-10">Yellowish to amber urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="RED_PINK_URINE_COLOR">
                                <span class="ml-10">Red or pink urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="ORNAGE_URINE_COLOR">
                                <span class="ml-10">Orange urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="BLUE_GREEN_URINE_COLOR">
                                <span class="ml-10">Blue or green urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="DARK_BROWN_URINE_COLOR">
                                <span class="ml-10">Dark brown urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="CLOUDY_URINE_COLOR">
                                <span class="ml-10">Cloudy urine</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Eyes ?</label>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="YELLOW_WHITES_OF_YOUR_EYES"/><span class="ml-10">Yellow whites of your eyes</span>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="DRY_ITCHY_EYES">
                                <span class="ml-10">Dry and itchy eyes</span>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="XANTHELASMA_FAT_ON_EYELIDS">
                                <span class="ml-10">Xanthelasma - small collections of fat on the eyelids</span>
                                <br>
                                <input class="ml-10" formControlName="eyes" name="eyes" type="radio"
                                       value="NONE_OF_THESE_EYES">
                                <span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Tongue ?</label>
                                <br>
                                <input class="ml-10" formControlName="tongue" name="tongue" type="radio"
                                       value="YELLOWISH_TONGUE"/><span class="ml-10">Yellowish tongue</span>
                                <br>
                                <input class="ml-10" formControlName="tongue" name="tongue" type="radio"
                                       value="CRACK_TONGUE">
                                <span class="ml-10">Crack down the middle</span>
                                <br>
                                <input class="ml-10" formControlName="tongue" name="tongue" type="radio"
                                       value="NONE_OF_THESE_TONGUE">
                                <span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Food habit ?</label>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ALCOHOL_FOOD_HABIT"/><span class="ml-10">Alcohol</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ADDED_SUGAR_FOOD_HABIT">
                                <span class="ml-10">Added sugar</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="FRIED_FOODS_FOOD_HABIT">
                                <span class="ml-10">Fried foods</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ADDED_SALT_FOOD_HABIT">
                                <span class="ml-10">Added salt</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="RED_MEAT_FOOD_HABIT">
                                <span class="ml-10">Red meat</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="NONE_OF_THESE_FOOD_HABIT">
                                <span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Bowel movements</label>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="REGULAR_BOWEL_MOVEMENT"/><span class="ml-10">Regular</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement"
                                       type="radio" value="CONSTIPATION_BOWEL_MOVEMENT">
                                <span class="ml-10">Constipation</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="NONE_BOWEL_MOVEMENT">
                                <span class="ml-10">None</span>
                            </div>
                        </div>
                        <div class="content" id="test-detox-management-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && detoxManagementForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && detoxManagementForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && detoxManagementForm && detoxManagementForm.controls && detoxManagementForm.controls['email'] && detoxManagementForm.controls['email'].errors && detoxManagementForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && detoxManagementForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && detoxManagementForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && detoxManagementForm && detoxManagementForm.controls && detoxManagementForm.controls['contact'] && detoxManagementForm.controls['contact'].errors && detoxManagementForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && detoxManagementForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'uterine-fibroid-assessment'" class="card-body">
                <form [formGroup]="fibroidForm" class="bs-stepper" id="stepperFibroid" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-fibroid-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-fibroid-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-fibroid-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-fibroid-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Age when periods first occurred ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="period_age" name="period_age" type="radio"
                                           value="BEFORE_12_YEARS"><span
                                    class="ml-10">Before 13 years of age</span>
                                    <br>
                                    <input class="ml-10" formControlName="period_age" name="period_age" type="radio"
                                           value="12_15"><span class="ml-10">12 - 15 years of age</span>
                                    <br>
                                    <input class="ml-10" formControlName="period_age" name="period_age" type="radio"
                                           value="AFTER_15_YEARS">
                                    <span class="ml-10">After 15 years of age</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Duration of cycle ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="cycle_duration" name="cycle_duration"
                                           type="radio"
                                           value="LESS_THAN_28_DAYS"><span class="ml-10">Less than 28 days</span>
                                    <br>
                                    <input class="ml-10" formControlName="cycle_duration" name="cycle_duration"
                                           type="radio"
                                           value="28_30_DAYS"><span class="ml-10">28 - 30 Days</span>
                                    <br>
                                    <input class="ml-10" formControlName="cycle_duration" name="cycle_duration"
                                           type="radio"
                                           value="GREATER_THAN_35_DAYS"><span
                                    class="ml-10">Greater than 35 Days</span>
                                </div>
                                <label class="font-bold">3. Interval between periods ?</label>
                                <br>
                                <input class="ml-10" formControlName="periods_interval" name="periods_interval"
                                       type="radio"
                                       value="REGULAR"/><span class="ml-10">Regular</span>
                                <br>
                                <input class="ml-10" formControlName="periods_interval" name="periods_interval"
                                       type="radio"
                                       value="IRREGULAR"/><span class="ml-10">Irregular</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">3. Family history (do your family members have the same
                                    problem)</label>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="MOTHER"><span class="ml-10">Mother</span>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="FATHER"><span class="ml-10">Father</span>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="SIBLINGS"><span class="ml-10">Siblings</span>
                                <br>
                                <input class="ml-10" formControlName="family_history" name="family_history" type="radio"
                                       value="NONE"><span class="ml-10">None</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Medical history ?</label>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio" value="DIABETIC_MEDICAL_HISTORY"/><span
                                class="ml-10">Diabetic</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio" value="HYPERTENSIONS_MEDICAL_HISTORY">
                                <span class="ml-10">Hypertensions</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="THYROID_MEDICAL_HISTORY">
                                <span class="ml-10">Thyroid</span>
                                <br>
                                <input class="ml-10" formControlName="medical_history" name="medical_history"
                                       type="radio"
                                       value="OTHER_MEDICAL_HISTORY">
                                <span class="ml-10">Other</span>
                            </div>
                        </div>
                        <div class="content" id="test-fibroid-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Amount of flow ?</label>
                                <br>
                                <input class="ml-10" formControlName="amount_of_flow" name="amount_of_flow" type="radio"
                                       value="LIGHT"/><span class="ml-10">Light</span>
                                <br>
                                <input class="ml-10" formControlName="amount_of_flow" name="amount_of_flow" type="radio"
                                       value="NORMAL">
                                <span class="ml-10">Normal</span>
                                <br>
                                <input class="ml-10" formControlName="amount_of_flow" name="amount_of_flow" type="radio"
                                       value="HEAVY"><span class="ml-10">Heavy</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Pain with periods ?</label>
                                <br>
                                <input class="ml-10" formControlName="pain_with_periods" name="pain_with_periods"
                                       type="radio"
                                       value="YES_PAIN_WITH_PERIODS"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="pain_with_periods" name="pain_with_periods"
                                       type="radio"
                                       value="NO_PAIN_WITH_PERIODS">
                                <span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Intermenstrual or post coital bleeding ?</label>
                                <br>
                                <input class="ml-10" formControlName="intermenstrual" name="intermenstrual" type="radio"
                                       value="YES_INTERMENSTRUAL"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="intermenstrual" name="intermenstrual" type="radio"
                                       value="NO_INTERMENSTRUAL">
                                <span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Do you have white discharge ?</label>
                                <br>
                                <input class="ml-10" formControlName="white_discharge" name="white_discharge"
                                       type="radio"
                                       value="YES_WHITE_DISCHARGE"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="white_discharge" name="white_discharge"
                                       type="radio"
                                       value="NO_WHITE_DISCHARGE">
                                <span class="ml-10">No</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Do you feel fibroid ?</label>
                                <br>
                                <input class="ml-10" formControlName="feel_fibroid" name="feel_fibroid" type="radio"
                                       value="YES_FEEL_FIBROID"/><span class="ml-10">Yes</span>
                                <br>
                                <input class="ml-10" formControlName="feel_fibroid" name="feel_fibroid" type="radio"
                                       value="NO_FEEL_FIBROID">
                                <span class="ml-10">No</span>
                            </div>
                        </div>
                        <div class="content" id="test-fibroid-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && fibroidForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && fibroidForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && fibroidForm && fibroidForm.controls && fibroidForm.controls['email'] && fibroidForm.controls['email'].errors && fibroidForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && fibroidForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && fibroidForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && fibroidForm && fibroidForm.controls && fibroidForm.controls['contact'] && fibroidForm.controls['contact'].errors && fibroidForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && fibroidForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'kidney-assessment'" class="card-body">
                <form [formGroup]="naturalKidneyCareForm" class="bs-stepper" id="stepperNaturalKidneyCare" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-natural-kidney-care-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Previous History">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">Previous History</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-natural-kidney-care-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="Abnormalities">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">Abnormalities</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-natural-kidney-care-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-natural-kidney-care-1">
                            <div class="form-group">
                                <div class="form-group mt-10">
                                    <label class="font-bold">1. Medical history</label>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="DIABETIC_MEDICAL_HISTORY"/><span
                                    class="ml-10">Diabetic</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="HYPERTENSIONS_MEDICAL_HISTORY">
                                    <span class="ml-10">Hypertensions</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="THYROID_MEDICAL_HISTORY">
                                    <span class="ml-10">Thyroid</span>
                                    <br>
                                    <input class="ml-10" formControlName="medical_history" name="medical_history"
                                           type="radio" value="OTHER_MEDICAL_HISTORY">
                                    <span class="ml-10">Other</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">2. Family history (do your family members have same
                                        problem) ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="MOTHER_MEDICAL_HISTORY"><span
                                    class="ml-10">Mother</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="FATHER_MEDICAL_HISTORY"><span
                                    class="ml-10">Father</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio" value="SIBLINGS_MEDICAL_HISTORY"><span
                                    class="ml-10">Siblings</span>
                                    <br>
                                    <input class="ml-10" formControlName="family_history" name="family_history"
                                           type="radio"
                                           value="NONE_MEDICAL_HISTORY"><span class="ml-10">None</span>
                                </div>
                                <div class="form-group mt-10">
                                    <label class="font-bold">3. Do you feel difficulty while passing urine ?</label>
                                    <br>
                                    <input class="ml-10" formControlName="difficulty_passing_urine"
                                           name="difficulty_passing_urine"
                                           type="radio" value="NEVER_PASSING_URINE"/><span
                                    class="ml-10">Never</span>
                                    <br>
                                    <input class="ml-10" formControlName="difficulty_passing_urine"
                                           name="difficulty_passing_urine"
                                           type="radio"
                                           value="RARELY_PASSING_URINE"/><span
                                    class="ml-10">Rarely</span>
                                    <br>
                                    <input class="ml-10" formControlName="difficulty_passing_urine"
                                           name="difficulty_passing_urine"
                                           type="radio"
                                           value="SOMETIMES_PASSING_URINE"/><span
                                    class="ml-10">Sometimes</span>
                                    <br>
                                    <input class="ml-10" formControlName="difficulty_passing_urine"
                                           name="difficulty_passing_urine"
                                           type="radio" value="OFTEN_PASSING_URINE"/><span
                                    class="ml-10">Often</span>
                                </div>
                            </div>
                        </div>
                        <div class="content" id="test-natural-kidney-care-2">
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Do you have swelling on leg ?</label>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_leg" name="swelling_on_leg"
                                       type="radio"
                                       value="NEVER_SWELLING_ON_LEG"><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_leg" name="swelling_on_leg"
                                       type="radio"
                                       value="RARELY_SWELLING_ON_LEG"><span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_leg" name="swelling_on_leg"
                                       type="radio" value="SOMETIMES_SWELLING_ON_LEG"><span
                                class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_leg" name="swelling_on_leg"
                                       type="radio"
                                       value="OFTEN_SWELLING_ON_LEG"><span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Do you have swelling on your face ?</label>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_face" name="swelling_on_face"
                                       type="radio"
                                       value="NEVER_SWELLING_ON_FACE"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_face" name="swelling_on_face"
                                       type="radio" value="RARELY_SWELLING_ON_FACE">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_face" name="swelling_on_face"
                                       type="radio" value="SOMETIMES_SWELLING_ON_FACE">
                                <span class="ml-10">Sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="swelling_on_face" name="swelling_on_face"
                                       type="radio"
                                       value="OFTEN_SWELLING_ON_FACE">
                                <span class="ml-10">Often</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">6. Do you have backache / abdominal pain ?</label>
                                <br>
                                <input class="ml-10" formControlName="backache_abdomial_pain"
                                       name="backache_abdomial_pain"
                                       type="radio" value="NEVER_ABDOMINAL_PAIN"/><span
                                class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="backache_abdomial_pain"
                                       name="backache_abdomial_pain"
                                       type="radio" value="RARELY_ABDOMINAL_PAIN">
                                <span class="ml-10">Rarely</span>
                                <br>
                                <input class="ml-10" formControlName="backache_abdomial_pain"
                                       name="backache_abdomial_pain"
                                       type="radio" value="SOMETIMES_ABDOMINAL_PAIN"><span
                                class="ml-10">Sometimes</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">7. Food habits ?</label>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ALCOHOL_FOOD_HABIT"/><span class="ml-10">Alcohol</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ADDED_SUGAR_FOOD_HABIT">
                                <span class="ml-10">Added Sugar</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="FRIED_FOODS_FOOD_HABIT">
                                <span class="ml-10">Fried Foods</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="ADDED_SALT_FOOD_HABIT">
                                <span class="ml-10">Added Salt</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="RED_MEAT_FOOD_HABIT">
                                <span class="ml-10">Red Meat</span>
                                <br>
                                <input class="ml-10" formControlName="food_habit" name="food_habit" type="radio"
                                       value="NONE_OF_THESE_FOOD_HABIT">
                                <span class="ml-10">None of these</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">8. Bowel Movement ?</label>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="REGULAR_BOWEL_MOVEMENT"/><span class="ml-10">Regular</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement"
                                       type="radio" value="CONSTIPATION_BOWEL_MOVEMENT">
                                <span class="ml-10">Constipation</span>
                                <br>
                                <input class="ml-10" formControlName="bowel_movement" name="bowel_movement" type="radio"
                                       value="NONE_BOWEL_MOVEMENT">
                                <span class="ml-10">None</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">9. Urine colour ?</label>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="CLEAR_URINE"/><span class="ml-10">Clear Urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="YELLOWISH_TO_AMBER_URINE">
                                <span class="ml-10">Yellowish to amber urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="RED_PINK_URINE">
                                <span class="ml-10">Red or pink urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="ORNAGE_URINE">
                                <span class="ml-10">Orange urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="BLUE_OR_GREEN_URINE">
                                <span class="ml-10">Blue or green urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="DARK_BROWN_URINE">
                                <span class="ml-10">Dark brown urine</span>
                                <br>
                                <input class="ml-10" formControlName="urine_color" name="urine_color" type="radio"
                                       value="CLOUDY_URINE">
                                <span class="ml-10">Cloudy urine</span>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">10. Do you drink Alcohol ?</label>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="NEVER_ALCOHOL"/><span class="ml-10">Never</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol"
                                       type="radio" value="OCASSIONALLY_SOMETHING_ALCOHOL">
                                <span class="ml-10">Ocassionally sometimes</span>
                                <br>
                                <input class="ml-10" formControlName="drink_alcohol" name="drink_alcohol" type="radio"
                                       value="YES_DAILY_ALCOHOL">
                                <span class="ml-10">Yes, Daily</span>
                            </div>
                        </div>
                        <div class="content" id="test-natural-kidney-care-3">
                            <div class="form-group mt-10">
                                <label class="font-bold">11. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && naturalKidneyCareForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">12. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && naturalKidneyCareForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && naturalKidneyCareForm && naturalKidneyCareForm.controls && naturalKidneyCareForm.controls['email'] && naturalKidneyCareForm.controls['email'].errors && naturalKidneyCareForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">13. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && naturalKidneyCareForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">14. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && naturalKidneyCareForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && naturalKidneyCareForm && naturalKidneyCareForm.controls && naturalKidneyCareForm.controls['contact'] && naturalKidneyCareForm.controls['contact'].errors && naturalKidneyCareForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">15. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && naturalKidneyCareForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="assessmentType === 'prakriti'" class="card-body">
                <form [formGroup]="prakritiForm" class="bs-stepper" id="stepperPrakriti" novalidate>
                    <div class="bs-stepper-header">
                        <div class="step" data-target="#test-prakriti-1">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="KAPHA">1</span>
                                <span class="bs-stepper-label d-none d-sm-block">KAPHA </span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-prakriti-2">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="PITTA">2</span>
                                <span class="bs-stepper-label d-none d-sm-block">PITTA </span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-prakriti-3">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="VATA">3</span>
                                <span class="bs-stepper-label d-none d-sm-block">VATA</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#test-prakriti-4">
                            <button class="step-trigger">
                                <span class="bs-stepper-circle" data-placement="top" data-toggle="tooltip"
                                      title="General Details">4</span>
                                <span class="bs-stepper-label d-none d-sm-block">General Details</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content">
                        <div class="content" id="test-prakriti-1">
                            <ng-container *ngIf="!isQuizCompleted">
                                <div class="form-group">
                                    <div class="form-group mt-10">
                                        <label class="font-bold">{{kaphaList[currentQuestion]?.id}}
                                            . {{kaphaList[currentQuestion]?.questionText}}</label>
                                        <br>
                                        <div class="options mt-10">
                                            <ol *ngFor="let option of kaphaList[currentQuestion]?.options" class="mt-10"
                                                style="list-style: none;">
                                                <input (click)="answer(currentQuestion+1,option)" class="ml-10"
                                                       type="radio">
                                                <span class="ml-10">{{option.text}}</span>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="content" id="test-prakriti-2">
                            <ng-container *ngIf="!isPittaQuizCompleted">
                                <div class="form-group mt-10">
                                    <label class="font-bold">{{pittaList[currentPittaQuestion]?.id}}
                                        . {{pittaList[currentPittaQuestion]?.questionText}}</label>
                                    <br>
                                    <div class="options mt-10">
                                        <ol *ngFor="let option of pittaList[currentPittaQuestion]?.options"
                                            class="mt-10"
                                            style="list-style: none;">
                                            <input (click)="pitta(currentPittaQuestion+1,option)" class="ml-10"
                                                   type="radio">
                                            <span class="ml-10">{{option.text}}</span>
                                        </ol>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="content" id="test-prakriti-3">
                            <ng-container *ngIf="!isVataQuizCompleted">
                                <div class="form-group mt-10">
                                    <label class="font-bold">{{vataList[currentVataQuestion]?.id}}
                                        . {{vataList[currentVataQuestion]?.questionText}}</label>
                                    <br>
                                    <div class="options mt-10">
                                        <ol *ngFor="let option of vataList[currentVataQuestion]?.options" class="mt-10"
                                            style="list-style: none;">
                                            <input (click)="vata(currentVataQuestion+1,option)" class="ml-10"
                                                   type="radio">
                                            <span class="ml-10">{{option.text}}</span>
                                        </ol>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="content" id="test-prakriti-4">
                            <div class="form-group mt-10">
                                <label class="font-bold">1. Name</label>
                                <br>
                                <input class="form-control ml-10" formControlName="name" name="name" type="text"/>
                                <small *ngIf="submitted && prakritiForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">2. Email</label>
                                <br>
                                <input class="form-control ml-10" formControlName="email" name="email" type="text"/>
                                <small *ngIf="submitted && prakritiForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && prakritiForm && prakritiForm.controls && prakritiForm.controls['email'] && prakritiForm.controls['email'].errors && prakritiForm.controls['email'].errors.pattern"
                                    class="text-danger">Invalid email format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">3. Gender</label>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="MALE"><span class="ml-10">Male</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="FEMALE"><span class="ml-10">Female</span>
                                <br>
                                <input class="ml-10" formControlName="gender" name="gender" type="radio"
                                       value="OTHERS"><span class="ml-10">Others</span>
                                <small *ngIf="submitted && prakritiForm.get('gender').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">4. Contact</label>
                                <br>
                                <input class="form-control ml-10" formControlName="contact" name="contact"
                                       type="number"/>
                                <small *ngIf="submitted && prakritiForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                                <small
                                    *ngIf="submitted && prakritiForm && prakritiForm.controls && prakritiForm.controls['contact'] && prakritiForm.controls['contact'].errors && prakritiForm.controls['contact'].errors.pattern"
                                    class="text-danger">Invalid contact format</small>
                            </div>
                            <div class="form-group mt-10">
                                <label class="font-bold">5. Age</label>
                                <br>
                                <input class="form-control ml-10" formControlName="age" name="age" type="number"/>
                                <small *ngIf="submitted && prakritiForm.get('age').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="card-footer  background-color-white">
                                <button (click)="submit ('PRAKRITI')" class="btn btn-primary float-end" type="submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="card-footer shadow-light background-color-white">
            <div *ngIf="assessmentType === 'liver-health-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('LIVER_SAVIOR')" *ngIf="liverFormStep === 2 || liverFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('LIVER_SAVIOR')" *ngIf="liverFormStep === 1 || liverFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('LIVER_SAVIOR')" *ngIf="liverFormStep === 3" class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'women-health-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('WOMEN_CARE')" *ngIf="womenCareFormStep === 2 || womenCareFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('WOMEN_CARE')" *ngIf="womenCareFormStep === 1 || womenCareFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('WOMEN_CARE')" *ngIf="womenCareFormStep === 3" class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'male-health-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('MALE_REJUVENATOR')"
                            *ngIf="maleRejuvenatorFormStep === 2 || maleRejuvenatorFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('MALE_REJUVENATOR')"
                            *ngIf="maleRejuvenatorFormStep === 1 || maleRejuvenatorFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('MALE_REJUVENATOR')" *ngIf="maleRejuvenatorFormStep === 3"
                            class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'obesity-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('OBESITY_PACIFIRE')"
                            *ngIf="obesityPacifierFormStep === 2 || obesityPacifierFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('OBESITY_PACIFIRE')"
                            *ngIf="obesityPacifierFormStep === 1 || obesityPacifierFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('OBESITY_PACIFIRE')" *ngIf="obesityPacifierFormStep === 3"
                            class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'diabetes-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('DIABETES_MANAGER')"
                            *ngIf="diabetesManagerFormStep === 2 || diabetesManagerFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('DIABETES_MANAGER')"
                            *ngIf="diabetesManagerFormStep === 1 || diabetesManagerFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('DIABETES_MANAGER')" *ngIf="diabetesManagerFormStep === 3"
                            class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'thyroid-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('THYRO_GUARD')" *ngIf="thyroguardFormStep === 2 || thyroguardFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('THYRO_GUARD')" *ngIf="thyroguardFormStep === 1 || thyroguardFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('THYRO_GUARD')" *ngIf="thyroguardFormStep === 3" class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'acidity-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('ACIDITY_UPROOTER')"
                            *ngIf="acidityUprooterFormStep === 2 || acidityUprooterFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('ACIDITY_UPROOTER')"
                            *ngIf="acidityUprooterFormStep === 1 || acidityUprooterFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('ACIDITY_UPROOTER')" *ngIf="acidityUprooterFormStep === 3"
                            class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'joints-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('ORTHO_FORTIFY')"
                            *ngIf="orthoFortifyFormStep === 2 || orthoFortifyFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('ORTHO_FORTIFY')"
                            *ngIf="orthoFortifyFormStep === 1 || orthoFortifyFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('ORTHO_FORTIFY')" *ngIf="orthoFortifyFormStep === 3" class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'general-health-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('DETOX_MANAGEMENT')"
                            *ngIf="detoxManagementFormStep === 2 || detoxManagementFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('DETOX_MANAGEMENT')"
                            *ngIf="detoxManagementFormStep === 1 || detoxManagementFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('DETOX_MANAGEMENT')" *ngIf="detoxManagementFormStep === 3"
                            class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'uterine-fibroid-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('FIBROID')" *ngIf="fibroidFormStep === 2 || fibroidFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('FIBROID')" *ngIf="fibroidFormStep === 1 || fibroidFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('FIBROID')" *ngIf="fibroidFormStep === 3" class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'kidney-assessment'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                    <button (click)="back('NATURAL_KIDNEY_CARE')"
                            *ngIf="naturalKidneyCareFormStep === 2 || naturalKidneyCareFormStep === 3"
                            class="btn btn-danger mr-2">
                        <i class="fas fa-angle-left"></i><span class="ml-10px">Back</span>
                    </button>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="next('NATURAL_KIDNEY_CARE')"
                            *ngIf="naturalKidneyCareFormStep === 1 || naturalKidneyCareFormStep === 2"
                            class="btn btn-success">
                        <span class="mr-10px">Next</span><i class="fas fa-angle-right"></i>
                    </button>
                    <button (click)="submit('NATURAL_KIDNEY_CARE')" *ngIf="naturalKidneyCareFormStep === 3"
                            class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
            <div *ngIf="assessmentType === 'prakriti'" class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                    <button (click)="submit('PRAKRITI')" *ngIf="prakritiFormStep === 4" class="btn btn-primary"
                            type="submit">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
