import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-prakriti',
    templateUrl: './prakriti.component.html',
    styleUrls: ['./prakriti.component.scss']
})
export class PrakritiComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }


}
