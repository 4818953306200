<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 *ngIf="selectedKeyIngredient" class="modal-title" id="modal-basic-title">Key Ingredient
            - {{selectedKeyIngredient?.ingredient}}</h4>
        <button (click)="modalService.dismissAll('')" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="selectedKeyIngredient?.about">
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalService.dismissAll('')" class="btn btn-danger" type="button">Close</button>
    </div>
</ng-template>
<section class="model">
    <!-- The Modal -->
    <div class="container">
        <div class="modal" id="myModal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title" style="width: 310px; height: 39px; text-align: center; margin-top:-6%;">
                            BULK ORDER ENQUIRY
                        </h4>
                        <span class="close btn btn-danger" data-dismiss="modal" type="button">&times;</span>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body" style="margin-top: -9%;">
                        <form action="/action_page.php">
                            <div class="mb-3 mt-3">
                                <input class="form-control" id="name" name="name" placeholder="Enter Name" type="text">
                            </div>
                            <div class="mb-3 mt-3">
                                <input class="form-control" id="number" name="number" placeholder="Enter number"
                                       type="number">
                            </div>
                            <div class="mb-3 mt-3">
                                <input class="form-control" id="email" name="email" placeholder="Enter email"
                                       type="email">
                            </div>
                            <div class="mb-3 mt-3">
                                <input class="form-control" id="city" name="city" placeholder="Enter city" type="city">
                            </div>
                            <div class="mb-3">
                                <select class="form-control" id="occupation" name="occupation"
                                        placeholder="Enter occupation">
                                    <option>Doctor</option>
                                </select>
                            </div>
                            <div class="mb-3 mt-3">
                                <select class="form-control" id="demand" name="demand" placeholder="Enter demand">
                                    <option>10</option>
                                </select>
                            </div>
                            <div class="mb-3 mt-3">
                                <input class="form-control" id="remark" name="remark" placeholder="Enter Remark"
                                       type="text">
                            </div>
                            <div class="form-check mb-3">
                                <label class="form-check-label">
                                    You can reach us @ 9123456782
                                </label>
                            </div>
                            <div class="mb-3 mt-3">
                                <input class="form-control" id="submit" name="bulk" placeholder="Request a Quote "
                                       type="submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="blog-details-area" style="background-color: #f1f3f6;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay=".3s">
                        <section class="faq-area">
                            <div class="container margin-top px-0">
                                <div class="row">
                                    <div class="video-wrap">
                                        <div class="d-none d-lg-none" style="margin-top:86px;"></div>
                                        <div class="d-none d-md-block d-lg-none" style="margin-top:89px;"></div>
                                        <div class="container mobile-top-margin" style="background-color: white;">
                                            <div class="row">
                                                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 mt-lg-5">
                                                    <div class="row sticky-bar product-image-preview">
                                                        <div
                                                            *ngIf="productDetails && productDetails['product_image']"
                                                            class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 mx-lg-2 justify-content-center"
                                                            id="product-slider"
                                                            style="padding:0">
                                                            <div class="row ">
                                                                <ul class="video-nav nav nav-pills" id="pills-tab"
                                                                    role="tablist" style=" width: 100%">
                                                                    <li *ngFor="let productDetailsImage of productDetails['product_image']; let i = index"
                                                                        class="nav-item display-inline video-nav-item">
                                                                        <a [attr.data-bs-target]="'#pills-'+i"
                                                                           aria-controls="pills-home"
                                                                           aria-selected="true"
                                                                           class="nav-link"
                                                                           data-bs-toggle="tab"
                                                                           id="pills-tab-{{i}}" role="tab"
                                                                           style=""
                                                                           type="button"
                                                                        >
                                                                            <img class="img-thumbnail"
                                                                                 height="66px"
                                                                                 src="{{productDetailsImage?.file?.url}}"
                                                                                 width="66px">
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 justify-content-center padding-bottom"
                                                            style="cursor: zoom-in;padding: 0;">
                                                            <div
                                                                *ngIf="productDetails && productDetails['product_image']"
                                                                class="tab-content"
                                                                id="pills-tabContent">
                                                                <div
                                                                    *ngFor="let product_image of productDetails['product_image']; let i = index"
                                                                    [attr.aria-labelledby]="'pills-tab-'+i"
                                                                    [ngClass]="{'active': i === 0}"
                                                                    class="tab-pane  show"
                                                                    id="pills-{{i}}"
                                                                    role="tabpanel">
                                                                    <div class="video-area">
                                                                        <div class="d-table">
                                                                            <div class="d-table-cell">
                                                                                <div class="container p-0">
                                                                                    <div
                                                                                        class="d-none d-md-block d-lg-none"
                                                                                        style="margin-top: -15px;"></div>
                                                                                    <div class="video-item"
                                                                                         style="margin-top: -97px;">
                                                                                        <img
                                                                                            class="product-image image video-item-img-mv video-item-img img-thumbnail"
                                                                                            src="{{product_image?.file?.url}}"
                                                                                            style="height: 415px;">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="container-fluid ">
                                                            <div class="row">
                                                                <div class="col-lg-12 col-md-12 text-center my-3 ">
                                                                    <div
                                                                        *ngIf="productDetails?.product_status!='SOLD_OUT'"
                                                                        class="row mx-lg-5 mx-md-5 mx-sm-5">
                                                                        <div class="col-6 my-1 text-end">
                                                                            <button
                                                                                (click)="addToCart()"
                                                                                class="btn w-100 btn-primary custom-btn ">
                                                                                Add To Cart
                                                                            </button>
                                                                        </div>
                                                                        <div class="col-6 my-1 text-start">
                                                                            <button
                                                                                (click)="addToCart()"
                                                                                class="btn shadow w-100 btn-success custom-btn">
                                                                                Buy Now
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        *ngIf="productDetails?.product_status==='SOLD_OUT'"
                                                                        class="row justify-content-center">
                                                                        <div class="col-7">
                                                                            <div class="btn btn-danger w-100">Sold Out
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                                    <div class="row ">
                                                        <div class="col-lg-12">
                                                            <div class="faq-item">
                                                                <div class="row margin-top-xs-75">
                                                                    <div class="col-lg-12">
                                                                        <div class="mt-lg-5">
                                                                            <h1 style="font-size: 2rem;">{{productDetails?.name}}</h1>
                                                                            <span
                                                                                class="label color-white product-rating">
                                                                                {{productDetails?.tagline}}
                                                                            </span>
                                                                            <div class="courses-rating">
                                                                                <span
                                                                                    *ngIf="productDetails?.rating == 5">
                                                                                    <i class="fas fa-star text-warning ml-10"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="productDetails?.rating == 4.5">
                                                                                    <i class="fas fa-star text-warning ml-10"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star-half-alt text-warning"></i>
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="productDetails?.rating == 4">
                                                                                    <i class="fas fa-star text-warning ml-10"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-secondary"></i>
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="productDetails?.rating == 3.5">
                                                                                    <i class="fas fa-star text-warning ml-10"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star-half-alt text-warning"></i>
                                                                                    <i class="fas fa-star text-secondary"></i>
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="productDetails?.rating == 3">
                                                                                    <i class="fas fa-star text-warning ml-10"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-warning"></i>
                                                                                    <i class="fas fa-star text-secondary"></i>
                                                                                    <i class="fas fa-star text-secondary"></i>
                                                                                </span>
                                                                                <div class="pt-10">
                                                                                    <h6 class="color-green"><span>Price : </span><span>&#8377;{{productDetails?.total_price | number : '1.2-2'}}</span>
                                                                                    </h6>
                                                                                    <p>Inclusive of all taxes</p>
                                                                                </div>
                                                                                <div class="select-quantity mt-20 ">
                                                                                    <h6 class="mt-20 mb-20">Select
                                                                                        Capacity
                                                                                    </h6>
                                                                                    <span (click)="decrement()"
                                                                                          class="custom-border-box p-10 mx-1"> <i
                                                                                        class="icofont-minus"></i></span>
                                                                                    <span
                                                                                        class="custom-border-box p-10">{{counter}}</span>
                                                                                    <span (click)="increment()"
                                                                                          class="custom-border-box p-10 mx-1"><i
                                                                                        class="icofont-plus"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row faq-wrap mt-30px">
                                                                            <div class="col-lg-12 ">
                                                                                <div
                                                                                    class="accordion accordion-flush custom-product-card"
                                                                                    id="accordionFlushExample">
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header"
                                                                                            id="flush-headingOne">
                                                                                            <button
                                                                                                aria-controls="flush-collapseOne"
                                                                                                aria-expanded="true"
                                                                                                class="accordion-button"
                                                                                                data-bs-target="#flush-collapseOne"
                                                                                                data-bs-toggle="collapse"
                                                                                                type="button">
                                                                                                Description
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div
                                                                                            aria-labelledby="flush-headingOne"
                                                                                            class="accordion-collapse collapse show"
                                                                                            data-bs-parent="#accordionFlushExample"
                                                                                            id="flush-collapseOne">
                                                                                            <div class="accordion-body"
                                                                                                 style="text-align: justify;">
                                                                                                {{productDetails?.description}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header"
                                                                                            id="flush-headingThree">
                                                                                            <button
                                                                                                aria-controls="flush-collapseThree"
                                                                                                aria-expanded="false"
                                                                                                class="accordion-button collapsed"
                                                                                                data-bs-target="#flush-collapseThree"
                                                                                                data-bs-toggle="collapse"
                                                                                                type="button">
                                                                                                Indication
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div
                                                                                            aria-labelledby="flush-headingThree"
                                                                                            class="accordion-collapse collapse"
                                                                                            data-bs-parent="#accordionFlushExample"
                                                                                            id="flush-collapseThree">
                                                                                            <div
                                                                                                class="accordion-body accordion-product-body"
                                                                                                style="text-align: justify;">
                                                                                                <div
                                                                                                    [innerHTML]="productDetails?.action"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header"
                                                                                            id="flush-headingThree-action">
                                                                                            <button
                                                                                                aria-controls="flush-collapseThree-action"
                                                                                                aria-expanded="false"
                                                                                                class="accordion-button collapsed"
                                                                                                data-bs-target="#flush-collapseThree-action"
                                                                                                data-bs-toggle="collapse"
                                                                                                type="button">
                                                                                                Key Ingredients
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div
                                                                                            aria-labelledby="flush-headingThree"
                                                                                            class="accordion-collapse collapse"
                                                                                            data-bs-parent="#accordionFlushExample"
                                                                                            id="flush-collapseThree-action">
                                                                                            <div
                                                                                                *ngIf="productDetails && productDetails['key_ingredient'] && productDetails['key_ingredient'].length > 0"
                                                                                                class="accordion-body accordion-product-body">
                                                                                                <div
                                                                                                    *ngFor="let keyIngredient of productDetails['key_ingredient']"
                                                                                                    class="row">
                                                                                                    <div
                                                                                                        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                        <i class="fas fa-check-circle mr-1 text-success"></i><span
                                                                                                        (click)="openKeyIngredientAbout(content, keyIngredient)"
                                                                                                        class="ml-10 cursor-pointer key-ingr">{{keyIngredient?.ingredient}}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header"
                                                                                            id="flush-headingThree-dosage">
                                                                                            <button
                                                                                                aria-controls="flush-collapseThree-dosage"
                                                                                                aria-expanded="false"
                                                                                                class="accordion-button collapsed"
                                                                                                data-bs-target="#flush-collapseThree-dosage"
                                                                                                data-bs-toggle="collapse"
                                                                                                style="text-align: justify;"
                                                                                                type="button">
                                                                                                Dosage
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div
                                                                                            aria-labelledby="flush-headingThree"
                                                                                            class="accordion-collapse collapse"
                                                                                            data-bs-parent="#accordionFlushExample"
                                                                                            id="flush-collapseThree-dosage">
                                                                                            <div
                                                                                                class="accordion-body accordion-product-body">
                                                                                                <div
                                                                                                    *ngIf="productDetails && productDetails['dosage']"
                                                                                                    class="row">
                                                                                                    <div
                                                                                                        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                        <div
                                                                                                            class="row mb-10">
                                                                                                            <div
                                                                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 font-bold">
                                                                                                                <i class="fas fa-check-circle mr-1 text-success"></i>
                                                                                                                <span
                                                                                                                    class="ml-10">2 - 12 years (Child)</span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                                <div
                                                                                                                    [innerHTML]="productDetails['dosage']?.child_dosage"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="row mb-10">
                                                                                                            <div
                                                                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 font-bold">
                                                                                                                <i class="fas fa-check-circle mr-1 text-success"></i>
                                                                                                                <span
                                                                                                                    class="ml-10">Adult</span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                [innerHTML]="productDetails['dosage']?.adult_dosage"
                                                                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="row">
                                                                                                            <div
                                                                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 font-bold">
                                                                                                                <i class="fas fa-check-circle mr-1 text-success"></i>
                                                                                                                <span
                                                                                                                    class="ml-10">Geriatric</span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                [innerHTML]="productDetails['dosage']?.geriatric_dosage"
                                                                                                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header"
                                                                                            id="flush-headingFive">
                                                                                            <button
                                                                                                aria-controls="flush-collapseFive"
                                                                                                aria-expanded="false"
                                                                                                class="accordion-button collapsed"
                                                                                                data-bs-target="#flush-collapseFive"
                                                                                                data-bs-toggle="collapse"
                                                                                                type="button">
                                                                                                Manufacturer Details
                                                                                            </button>
                                                                                        </h2>
                                                                                        <div
                                                                                            aria-labelledby="flush-headingFive"
                                                                                            class="accordion-collapse collapse"
                                                                                            data-bs-parent="#accordionFlushExample"
                                                                                            id="flush-collapseFive">
                                                                                            <div class="accordion-body">
                                                                                                {{productDetails?.manufacturer_details}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal  fade" id="exampleModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content custom-review-modal ">
            <div class="modal-header m-0">
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
                <h5 class="modal-title text-center review-title">Customer Review</h5>
                <form>
                    <div class=" my-2 mb-4 form-check">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="">
                                <div class="product-review-stars">
                                    <i class='icofont-star'></i>
                                    <i class='icofont-star'></i>
                                    <i class='icofont-star'></i>
                                    <i class='icofont-star'></i>
                                    <i class='icofont-star'></i>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mb-3 d-flex flex-wrap">
                        <div class="review-text ">Awesome</div>
                        <div class="review-text">Happy with this product</div>
                        <div class="review-text">this product is very effective and harmless and also with low price.
                        </div>
                        <div class="review-text">Very good and effective product by Dr Axico</div>
                    </div>
                    <div class="mb-3">
                        <textarea class="form-control" id="review-descritption" placeholder="Enter your feedback...."
                                  type="text"></textarea>
                    </div>
                    <div class=" mt-3">
                        <button class=" w-100 btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
