import {Component, OnInit} from '@angular/core';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../shared/services/auth.service';
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    profileForm: FormGroup;
    profilePasswordForm: FormGroup;
    user: any;
    submitted: any = false;
    submitting: any = false;
    modalType: any;

    constructor(private appointmentService: AppointmentService,
                private authService: AuthService,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private alertService: AlertService,
                public modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.getProfile();

        this.profileForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.required]
        });

        this.profilePasswordForm = this.fb.group({
            'confirmPassword': ['', Validators.required],
            'newPassword': ['', Validators.required],
            'currentPassword': ['', Validators.required]
        });
    };

    getProfile() {
        this.authService.self().subscribe(data => {
            if (data) {
                this.user = data;
            }
        });
    }

    updateProfile() {
        this.submitting = true;
        if (!this.profileForm.valid) {
            return;
        }
        this.authService.update(this.profileForm.value).subscribe(data => {
            this.user = data;
            this.submitting = false;
            this.getProfile();
            this.modalService.dismissAll('');
        }, error => {
            this.submitting = false;
            this.submitted = false;
            if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                    this.alertService.showErrors(error['error']['errors'].email[0]);
                    return;
                }
            }
        });
    }

    updatePassword() {
        this.submitting = true;
        this.submitted = true;
        if (!this.profilePasswordForm.valid) {
            this.submitting = false;
            return;
        }
        if (!this.profilePasswordForm.value || !this.profilePasswordForm.value.newPassword || !this.profilePasswordForm.value.confirmPassword || this.profilePasswordForm.value.newPassword !== this.profilePasswordForm.value.confirmPassword) {
            this.submitting = false;
            this.alertService.showErrors('New Password & Confirm Password didn\'t match');
        } else {
            this.submitting = true;
            this.submitted = true;
            let params = {
                'newPassword': (this.profilePasswordForm.value && this.profilePasswordForm.value.newPassword) ? this.profilePasswordForm.value.newPassword : '',
                'currentPassword': (this.profilePasswordForm.value && this.profilePasswordForm.value.currentPassword) ? this.profilePasswordForm.value.currentPassword : '',
            };
            this.authService.updateSelfPassword(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.getProfile();
                this.modalService.dismissAll('');
            }, error => {
                if (error && error['error'] && error['error']['errorDetails']) {
                    this.alertService.showErrors(error['error']['errorDetails']);
                }
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    openProfileEditModal(content) {
        this.modalType = 'UPDATE_PROFILE';
        this.modalService.open(content, {'size': 'lg'});
        this.profileForm.patchValue({
            'name': (this.user && this.user.name) ? this.user.name : '',
            'email': (this.user && this.user.email) ? this.user.email : '',
            'username': (this.user && this.user.username) ? this.user.username : '',
            'contact': (this.user && this.user.contact) ? this.user.contact : '',
        });
    }

    openPasswordEditModal(content) {
        this.modalType = 'UPDATE_PASSWORD';
        this.profilePasswordForm.patchValue({
            'newPassword': '',
            'currentPassword': '',
            'confirmPassword': '',
        });
        this.modalService.open(content, {'size': 'lg'});
    }
}
