<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h5>HOME>MEDICINE>PRODUCT NAME</h5>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img
                            alt="Blog"
                            class="xzoom"
                            src="https://www.patanjaliayurved.net/assets/product_images/400x300/1539148741shuddhichuran400-300.png"
                            style="background-color: floralwhite;"
                            xoriginal="https://www.patanjaliayurved.net/assets/product_images/400x300/1539148741shuddhichuran400-300.png">
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-bottom">
                    <h2>DIVYA SHUDDHI CHURNA 100 GM</h2>
                    <P>By: DIVYA</P>
                    <hr>
                    11245235 Reviews &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Write a Review
                    <hr>
                    <p>Divya shuddhi churna is an ayurvedic medicine that is primarily used for the treatment of
                        indigestion and useful in constipation, flatulence, loss of appetite etc.</p>
                    <div class="row">

                        <div class="col-sm-6 col-lg-4">
                            <p style="color:green">In Stock
                            </p>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <i class="fab fa-facebook"></i>
                            <i class="fab fa-facebook"></i>
                            <i class="fab fa-facebook"></i>
                        </div>
                    </div>
                    <br>
                    <h5>MRP:<span style="color: orange"> Rs 105</span></h5>
                    <p>(Inclusive of all taxes)</p>
                    <h6>( Free Delivery on order above Rs. 1000 )</h6>
                    <div class="row">
                        <div class="col-sm-6 col-lg-4"><label>Quantity:</label> <input style="width: 50px" type="number"
                                                                                       value="1"></div>
                        <div class="col-sm-6 col-lg-4"><label>Weight:</label> <input style="width: 60px" type="weight"
                                                                                     value="1 gm"></div>
                    </div>
                    <br>
                    <button class="btn btn-warning" type="button">Add to cart</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-success" type="button">Buy Now</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-warning" type="button">Wishlist</button>
                    <br><br>
                </div>
            </div>
            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img
                            alt="Blog"
                            src="https://www.patanjaliayurved.net/assets/img/preview/medicine_advert.jpg"
                            style="height: 350px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
