import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {AlertService} from '../../../shared/services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {GlobalService} from '../../../shared/services/global.service';
import {StorageService} from '../../../shared/services/storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StateService} from '../../../shared/services/state.service';
import {AddressService} from '../../../shared/services/address.service';
import {AddToCartService} from '../../../shared/services/add-to-cart.service';
import {TransactionService} from '../../../shared/services/transaction.service';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    addAddressForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    id: any;
    data: any;
    public error = '';
    private _unsubscribeAll: Subject<any>;
    user: any;
    states: any = [];
    addresses: any = [];
    products: any = [];
    discountSum: any = 0;
    actualSum: any = 0;
    subTotalSum: any = 0;
    discountedPriceSum: any = 0;
    selectedAddress: any;
    paymentOptions = [
        {
            'type': 'ONLINE',
            'textClass': 'text-left',
            'name': 'Online Payment'
        },
        {
            'type': 'CASH',
            'textClass': 'text-right',
            'name': 'Cash On Delivery'
        },
    ];
    pinCodeShippingData: any = [];
    pinCodeBillingData: any = [];
    pinCodeLoader: any = false;
    sameBillingAddress: any = false;
    couponDiscountedPrice: any = []
    couponDiscountedPriceSum: any = []

    constructor(private fb: FormBuilder,
                private alertService: AlertService,
                private _route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private addressService: AddressService,
                private globalService: GlobalService,
                private modalService: NgbModal,
                private stateService: StateService,
                private addToCartService: AddToCartService,
                private transactionService: TransactionService) {
        this._unsubscribeAll = new Subject();
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.addAddressForm = this.fb.group({
            'address_1_billing': ['', Validators.required],
            'address_2_billing': [''],
            'landmark_billing': ['', Validators.required],
            'city_billing': ['', Validators.required],
            'pin_code_billing': ['', Validators.required],
            'state_id_billing': ['', Validators.required],
            'name_billing': ['', Validators.required],
            'contact_billing': ['', Validators.required],
            'address_1_shipping': ['', Validators.required],
            'address_2_shipping': [''],
            'landmark_shipping': ['', Validators.required],
            'city_shipping': ['', Validators.required],
            'pin_code_shipping': ['', Validators.required],
            'state_id_shipping': ['', Validators.required],
            'name_shipping': ['', Validators.required],
            'contact_shipping': ['', Validators.required]
        });
        this.getStates();
        this.getAddresses();
        this.getProducts();
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    getAddresses() {
        this.addressService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.addresses = data['data'];
                if (this.addresses && this.addresses.length > 0) {
                    let selectedAddress: any;
                    this.addresses.forEach(address => {
                        if (address && address['status'] === 'SELECTED') {
                            selectedAddress = address;
                        }
                    });
                    this.selectedAddress = selectedAddress;
                }
            }
        });
    }

    getProducts() {
        this.submitting = true;
        this.products = [];
        this.addToCartService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.products = data['data'];
                this.products.forEach(data => {
                    if (data && data['product'] && data['product']['discounted_price'] && data['product']['igst']) {
                        data['product']['total_price'] = Math.round(parseFloat(data['product']['discounted_price']) + (parseFloat(data['product']['discounted_price']) * parseFloat(data['product']['igst']) / 100));
                        data['product']['actual_total_price'] = Math.round(parseFloat(data['product']['actual_price']) + (parseFloat(data['product']['actual_price']) * parseFloat(data['product']['igst']) / 100));
                    }
                });
                this.getCalculations();
            }
            if (this.products && this.products.length === 0) {
                this.router.navigateByUrl('');
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
            if (this.products && this.products.length === 0) {
                this.router.navigateByUrl('');
            }
        });
    }

    getCalculations() {
        let discount_arr = [];
        let discounted_price_arr = [];
        let actual_price_arr = [];
        this.products.forEach(product => {
            discount_arr.push(Math.round((parseFloat(product['product']['actual_total_price']) * parseFloat(product['quantity'])) - (parseFloat(product['product']['total_price']) * parseFloat(product['quantity']))));
            discounted_price_arr.push(Math.round(parseFloat(product['product']['actual_total_price']) * parseFloat(product['quantity'])));
            actual_price_arr.push(Math.round(parseFloat(product['product']['actual_total_price']) * parseFloat(product['quantity'])));
        });
        this.discountSum = discount_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.actualSum = actual_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.subTotalSum = this.actualSum - this.discountSum;
        this.discountedPriceSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

    }

    addAddressModal(content) {
        this.modalService.open(content, {size: 'xl'});
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    submitAddress() {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'address_1_shipping': (this.addAddressForm.value && this.addAddressForm.value.address_1_shipping) ? this.addAddressForm.value.address_1_shipping : '',
                'address_2_shipping': (this.addAddressForm.value && this.addAddressForm.value.address_2_shipping) ? this.addAddressForm.value.address_2_shipping : '',
                'landmark_shipping': (this.addAddressForm.value && this.addAddressForm.value.landmark_shipping) ? this.addAddressForm.value.landmark_shipping : '',
                'city_shipping': (this.addAddressForm.value && this.addAddressForm.value.city_shipping) ? this.addAddressForm.value.city_shipping : '',
                'pin_code_shipping': (this.addAddressForm.value && this.addAddressForm.value.pin_code_shipping) ? this.addAddressForm.value.pin_code_shipping : '',
                'state_id_shipping': (this.addAddressForm.value && this.addAddressForm.value.state_id_shipping) ? this.addAddressForm.value.state_id_shipping : '',
                'name_shipping': (this.addAddressForm.value && this.addAddressForm.value.name_shipping) ? this.addAddressForm.value.name_shipping : '',
                'contact_shipping': (this.addAddressForm.value && this.addAddressForm.value.contact_shipping) ? this.addAddressForm.value.contact_shipping : '',
                'address_1_billing': (this.addAddressForm.value && this.addAddressForm.value.address_1_billing) ? this.addAddressForm.value.address_1_billing : '',
                'address_2_billing': (this.addAddressForm.value && this.addAddressForm.value.address_2_billing) ? this.addAddressForm.value.address_2_billing : '',
                'landmark_billing': (this.addAddressForm.value && this.addAddressForm.value.landmark_billing) ? this.addAddressForm.value.landmark_billing : '',
                'city_billing': (this.addAddressForm.value && this.addAddressForm.value.city_billing) ? this.addAddressForm.value.city_billing : '',
                'pin_code_billing': (this.addAddressForm.value && this.addAddressForm.value.pin_code_billing) ? this.addAddressForm.value.pin_code_billing : '',
                'state_id_billing': (this.addAddressForm.value && this.addAddressForm.value.state_id_billing) ? this.addAddressForm.value.state_id_billing : '',
                'name_billing': (this.addAddressForm.value && this.addAddressForm.value.name_billing) ? this.addAddressForm.value.name_billing : '',
                'contact_billing': (this.addAddressForm.value && this.addAddressForm.value.contact_billing) ? this.addAddressForm.value.contact_billing : '',
            };
            this.submitting = true;
            this.addressService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.modalService.dismissAll('');
                    this.getAddresses();
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].address_1_billing && error['error']['errors'].address_1_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address_1_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].landmark_billing && error['error']['errors'].landmark_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].landmark_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].city_billing && error['error']['errors'].city_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].city_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].pin_code_billing && error['error']['errors'].pin_code_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].pin_code_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].state_id_billing && error['error']['errors'].state_id_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].state_id_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].name_billing && error['error']['errors'].name_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].contact_billing && error['error']['errors'].contact_billing.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact_billing[0]);
                        return;
                    }
                    if (error['error']['errors'].address_1_shipping && error['error']['errors'].address_1_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].address_1_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].landmark_shipping && error['error']['errors'].landmark_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].landmark_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].city_shipping && error['error']['errors'].city_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].city_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].pin_code_shipping && error['error']['errors'].pin_code_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].pin_code_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].state_id_shipping && error['error']['errors'].state_id_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].state_id_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].name_shipping && error['error']['errors'].name_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name_shipping[0]);
                        return;
                    }
                    if (error['error']['errors'].contact_shipping && error['error']['errors'].contact_shipping.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact_shipping[0]);
                        return;
                    }
                }
            });
        }
    }

    closeModal() {
        this.modalService.dismissAll('');
    }

    chooseAddress(id, status) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.addressService.updateStatus(id, params).subscribe(data => {
                this.getAddresses();
            });
        }
    }

    scrollTo(x, y) {
        window.scrollTo(x, y);
    }

    payNow(type) {
        if (this.selectedAddress && this.selectedAddress.id) {
            this.submitted = true;
            if (this.submitted) {
                const params = {
                    'name': (this.user && this.user.name) ? this.user.name : '',
                    'contact': (this.user && this.user.contact) ? this.user.contact : '',
                    'amount': this.discountedPriceSum,
                    'products': (this.products && this.products.length > 0) ? JSON.stringify(this.products) : '',
                    'type': type,
                    'address_id': this.selectedAddress.id
                };
                this.submitting = true;
                this.transactionService.create(params).subscribe(data => {
                    if (type === 'ONLINE') {
                        window.location.href = data.url;
                    } else {
                        this.router.navigateByUrl('payment/success');
                    }
                });
            }
        } else {
            this.alertService.showErrors('Please choose Billing & Shipping address to proceed');
        }
    }

    getPinCodeData(event, type) {
        if (event && event.target && event.target.value) {
            this.pinCodeLoader = true;
            this.stateService.getPinCodeDetails(event.target.value).subscribe(data => {
                if (data && data['data'].length > 0) {
                    if (type === 'SHIPPING') {
                        this.pinCodeShippingData = data['data'];
                        this.addAddressForm.patchValue({
                            'city_shipping': this.pinCodeShippingData[0]['District']
                        });
                        this.states.forEach(state => {
                            if (state && state['name'] && this.pinCodeShippingData[0]['State']) {
                                let checkSimiliarity = this.similarity(this.pinCodeShippingData[0]['State'], state['name']);
                                if (checkSimiliarity > 0.5) {
                                    this.addAddressForm.patchValue({
                                        'state_id_shipping': state.id
                                    });
                                }
                            }
                        });
                    } else {
                        this.pinCodeBillingData = data['data'];
                        this.addAddressForm.patchValue({
                            'city_billing': this.pinCodeBillingData[0]['District']
                        });
                        this.states.forEach(state => {
                            if (state && state['name'] && this.pinCodeBillingData[0]['State']) {
                                let checkSimiliarity = this.similarity(this.pinCodeBillingData[0]['State'], state['name']);
                                if (checkSimiliarity > 0.5) {
                                    this.addAddressForm.patchValue({
                                        'state_id_billing': state.id
                                    });
                                }
                            }
                        });
                    }
                    this.pinCodeLoader = false;
                }
            }, error => {
                this.alertService.showErrors('Please check the pin code you entered');
                this.pinCodeLoader = false;
            });
        }
    }

    similarity(s1, s2) {
        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
    }

    editDistance(s1, s2) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0)
                    costs[j] = j;
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) != s2.charAt(j - 1))
                            newValue = Math.min(Math.min(newValue, lastValue),
                                costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }

    sameBilling(type) {
        if (this.addAddressForm && this.addAddressForm.getRawValue() && (!this.addAddressForm.getRawValue().address_1_shipping || !this.addAddressForm.getRawValue().city_shipping || !this.addAddressForm.getRawValue().pin_code_shipping || !this.addAddressForm.getRawValue().state_id_shipping || !this.addAddressForm.getRawValue().name_shipping || !this.addAddressForm.getRawValue().contact_shipping)) {
            this.alertService.showErrors('Please fill required shipping address details');
        } else {
            if (type) {
                this.sameBillingAddress = type;
                this.addAddressForm.patchValue({
                    'address_1_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().address_1_shipping) ? this.addAddressForm.getRawValue().address_1_shipping : '',
                    'address_2_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().address_2_shipping) ? this.addAddressForm.getRawValue().address_2_shipping : '',
                    'landmark_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().landmark_shipping) ? this.addAddressForm.getRawValue().landmark_shipping : '',
                    'city_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().city_shipping) ? this.addAddressForm.getRawValue().city_shipping : '',
                    'pin_code_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().pin_code_shipping) ? this.addAddressForm.getRawValue().pin_code_shipping : '',
                    'state_id_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().state_id_shipping) ? this.addAddressForm.getRawValue().state_id_shipping : '',
                    'name_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().name_shipping) ? this.addAddressForm.getRawValue().name_shipping : '',
                    'contact_billing': (this.addAddressForm.getRawValue() && this.addAddressForm.getRawValue().contact_shipping) ? this.addAddressForm.getRawValue().contact_shipping : '',
                });
            } else {
                this.sameBillingAddress = type;
                this.addAddressForm.patchValue({
                    'address_1_billing': '',
                    'address_2_billing': '',
                    'landmark_billing': '',
                    'city_billing': '',
                    'pin_code_billing': '',
                    'state_id_billing': '',
                    'name_billing': '',
                    'contact_billing': '',
                });
            }
        }
    }
}
