<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Departments</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Departments</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-tooth"></i>
                    <h3>Dental Care</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-baby"></i>
                    <h3>Baby Care</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-laboratory"></i>
                    <h3>Laboratory</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-icu"></i>
                    <h3>ICU</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-dna-alt-2"></i>
                    <h3>DNA Test</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-surgeon"></i>
                    <h3>Surgeon</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-xray"></i>
                    <h3>X-ray</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-blood-test"></i>
                    <h3>Blood Test</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="department-item">
                    <i class="icofont-ambulance-crescent"></i>
                    <h3>Ambulance Service</h3>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium eaque omnis corporis, animi
                        aspernatur tempora.</p>
                </div>
            </div>
        </div>
    </div>
</section>
