import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { AppUrl } from '../constants/app-url';


@Injectable({
    providedIn: 'root'
})
export class DoctorService extends BaseService {
    contactUsPostUrl = "http://localhost:8000/api/mainContactUs";

    constructor(public http: HttpClient,
        public alertService: AlertService,
        public globalService: GlobalService,) {
        super(http, alertService, globalService);
    }

    get(): Observable<any> {
        return this.getRequest(AppUrl.DOCTORS());
    }

    detail(username): Observable<any> {
        return this.getRequest(AppUrl.DOCTORS(username));
    }

    postContactus(contactUsFormData: any) {
        return this.http.post(this.contactUsPostUrl, contactUsFormData);
    }
}
