<ng-template #content>
    <div id="modal">
        <div class="modal-header font-weight-bold">
            <h5 *ngIf="modalType === 'UPDATE_PASSWORD'" class="modal-title font-weight-bold">Update Password</h5>
            <h5 *ngIf="modalType === 'UPDATE_PROFILE'" class="font-weight-400 share-emotion">
                <i class="fas fa-user-circle cursor-pointer" style="margin-right: 10px"></i>
                <span>Update Profile</span>
            </h5>
            <i (click)="modalService.dismissAll('')" class="fas fa-times-circle f_16 text-danger cursor-pointer"></i>
        </div>
        <div class="modal-body">
            <div *ngIf="modalType === 'UPDATE_PASSWORD'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="profilePasswordForm" class="form" novalidate>
                        <div class="container">
                            <div class="row" style="margin-top: -15px">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <label for="old_password">Current Password</label>
                                        <input class="form-control" formControlName="currentPassword" id="old_password"
                                               placeholder="Current Password"
                                               type="password"/>
                                        <small
                                            *ngIf="submitted && profilePasswordForm.get('currentPassword').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <label for="new_password">New Password</label>
                                        <input class="form-control" formControlName="newPassword" id="new_password"
                                               placeholder="New Password"
                                               type="password">
                                        <small
                                            *ngIf="submitted && profilePasswordForm.get('newPassword').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <label for="confirm_password">Confirm Password</label>
                                        <input class="form-control" formControlName="confirmPassword"
                                               id="confirm_password"
                                               placeholder="Cofirm Password"
                                               type="password">
                                        <small
                                            *ngIf="submitted && profilePasswordForm.get('confirmPassword').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="modalType === 'UPDATE_PROFILE'" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="profileForm" class="form" novalidate>
                        <div class="container">
                            <div class="row" style="margin-top: -15px">
                                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <label for="name">Name</label>
                                        <input class="form-control" formControlName="name" id="name" placeholder="Name"
                                               type="text"/>
                                        <small *ngIf="submitted && profileForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12 pt-2 pb-2">
                                    <fieldset class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control" formControlName="email" id="email"
                                               placeholder="Email"
                                               type="text">
                                        <small *ngIf="submitted && profileForm.get('email').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="updateProfile()" *ngIf="modalType === 'UPDATE_PROFILE'"
                    [disabled]="submitting" class="btn btn-success color-white pull-right">
                <i *ngIf="!submitting" class="fas fa-pen-square" style="margin-right: 10px"></i>
                <i *ngIf="submitting" class="fas fa-spinner fa-pulse" style="margin-right: 10px"></i>Update Profile
            </button>
            <button (click)="updatePassword()" *ngIf="modalType === 'UPDATE_PASSWORD'"
                    [disabled]="submitting" class="btn btn-success color-white pull-right">
                <i *ngIf="!submitting" class="fas fa-pen-square" style="margin-right: 10px"></i>
                <i *ngIf="submitting" class="fas fa-spinner fa-pulse" style="margin-right: 10px"></i>Update Password
            </button>
        </div>
    </div>
</ng-template>
<div class="page-title-area page-title-profile">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Profile</h2>
            </div>
        </div>
    </div>
</div>
<div class="blog-details-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8 wow fadeInUp" data-wow-delay=".3s">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <h4 class="mt-10">Profile</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-lg col-xs-12">

                                    </div>
                                </div>
                                <div class="row p-60">
                                    <div class="col-12">
                                        <div class="row p-20">
                                            <div class="col-6 p-10">
                                                <label>Name.</label>
                                                <div class="text-capitalize">{{user?.name.toLowerCase()}}</div>
                                            </div>
                                            <div class="col-6 p-10">
                                                <label>Email.</label>
                                                <div *ngIf="user && user['email']">{{user?.email}}</div>
                                                <div *ngIf="!user || !user['email']">-</div>
                                            </div>
                                            <div class="col-6 p-10">
                                                <label>Contact.</label>
                                                <div class="text-capitalize">{{user?.contact}}</div>
                                            </div>
                                            <div class="col-6 p-10">
                                                <label>Username.</label>
                                                <div class="text-capitalize">{{user?.username}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 text-md-start text-center">
                                        <button (click)="openPasswordEditModal(content)" class="btn btn-primary">Update
                                            Password
                                        </button>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 text-md-end text-center mt-2 mt-md-0">
                                        <button (click)="openProfileEditModal(content)" class="btn btn-primary">Update
                                            Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>

