<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Frequently Asked Questions</h2>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button aria-controls="collapseOne" aria-expanded="true" class="accordion-button"
                                    data-bs-target="#collapseOne" data-bs-toggle="collapse" type="button">
                                How to book an appointment on Dr. Axico ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample"
                             id="collapseOne">
                            <div class="accordion-body">
                                You can easily book an appointment through our app and website <span
                                (click)="goTo('https://www.draxico.com/')" class="color-blue cursor-pointer">www.draxico.in</span>
                                by filling
                                your general details and symptoms you are facing and by paying the registration fees.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button aria-controls="collapseTwo" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseTwo" data-bs-toggle="collapse" type="button">
                                What is the procedure of manufacturing of Dr. Axico products ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseTwo">
                            <div class="accordion-body">
                                We are using phytonano technology. Phyto science comprises scientific study of use of
                                medicinal plants and their bioactive compounds.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button aria-controls="collapseThree" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseThree" data-bs-toggle="collapse" type="button">
                                How can I track my order after placing an order ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseThree">
                            <div class="accordion-body">
                                <i class="fas fa-check-circle text-success"></i><span class="ml-10">You will receive an order confirmation on Email and SMS on the registered email address
                                and Phone Number.</span><br>
                                <i class="fas fa-check-circle text-success"></i><span class="ml-10">Soon after placing the order you can always track your order / shipment through your log
                                in account.</span>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button aria-controls="collapseFour" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseFour" data-bs-toggle="collapse" type="button">
                                What is the Refund / Cancellation policy ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseFour">
                            <div class="accordion-body">
                                After placing the order or after delivery of the product, you can always cancel your
                                order and ask for its refund after the approval from the specialist.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <button aria-controls="collapseFive" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseFive" data-bs-toggle="collapse" type="button">
                                Dr. Axico Medicines are certified and approved from which Lab ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseFive">
                            <div class="accordion-body">
                                Dr. Axico medicines are certified and approved from with GMP certified processes we are
                                committed to deliver best quality and price. Empowered by a team of researchers with
                                in-depth knowledge of Ayurveda & Modern Medical Science.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSix">
                            <button aria-controls="collapseSix" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseSix" data-bs-toggle="collapse" type="button">
                                Do I need to create an account to order the items from the website ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseSix">
                            <div class="accordion-body">
                                Yes, you will need to register on site to place an order.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSeven">
                            <button aria-controls="collapseSeven" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseSeven" data-bs-toggle="collapse" type="button">
                                What is the delivery timeline of my order ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseSeven">
                            <div class="accordion-body">
                                The general delivery timeline is 3 to 7 business days (metro cities) and 15 to 21
                                business days (non-metro cities) depending on the delivery address pin code. We deliver
                                across the PAN India. These deliveries are made through reputed courier companies.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingEight">
                            <button aria-controls="collapseEight" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseEight" data-bs-toggle="collapse" type="button">
                                What is the idea behind the setting up the Self Assessment questions ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseEight">
                            <div class="accordion-body">
                                With the help of experienced doctors and consultants, we have prepared a set of
                                questions to help you suggest the list of most appropriate product for any suffering or
                                disease.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingNine">
                            <button aria-controls="collapseNine" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseNine" data-bs-toggle="collapse" type="button">
                                Do I need to pay again for the consultation after consulting doctor once ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseNine">
                            <div class="accordion-body">
                                After the appointment with the doctor there is no consultation fee charged for the
                                period of three days. And the further visit charges are levied as per doctor’s advice
                                and prescription.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTen">
                            <button aria-controls="collapseTen" aria-expanded="false" class="accordion-button collapsed"
                                    data-bs-target="#collapseTen" data-bs-toggle="collapse" type="button">
                                How do i choose products that are suitable to my needs ?
                            </button>
                        </h2>
                        <div aria-labelledby="headingTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample"
                             id="collapseTen">
                            <div class="accordion-body">
                                Our product consultants are available to assist you regarding any such issues. You are
                                requested to call us on +91 9205476292 or write to us on customer@draxico.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
