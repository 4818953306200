import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {BaseService} from './base.service';

@Injectable({
    providedIn: 'root'
})
export class TitleService extends BaseService {


    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    getProductPageTitleJson() {
        return this.http.get<any>('assets/productPage.json');
    }

    getProductTitleJson() {
        return this.http.get<any>('assets/product-detail.json');
    }

    getMedicalKitTitleJson() {
        return this.http.get<any>('assets/medical-kit.json');
    }

    getPanchakarmaJson() {
        return this.http.get<any>('assets/panchakarma.json');
    }


    getSelfAssessmentTitleJson() {
        return this.http.get<any>('assets/self-assessment.json');
    }

    getDiseasesTitleJson() {
        return this.http.get<any>('assets/diseases.json');
    }

    // getblogsTitleJson() {
    //     return this.http.get<any>('assets/blog.json');
    // }

    getblogsTitleJson(): Observable<any> {
        return this.getRequest(AppUrl.BLOGS());
    }

    getAppointmentsTitleJson() {
        return this.http.get<any>('assets/book-appointment.json');
    }

    getAboutDrAxicoTitleJson() {
        return this.http.get<any>('assets/about-dr-axico.json');
    }

    getContactUsTitleJson() {
        return this.http.get<any>('assets/contactUs.json');
    }

    getBmiCalculatorTitleJson() {
        return this.http.get<any>('assets/bmiCalculator.json');
    }

    getDoctorTitleJson() {
        return this.http.get<any>('assets/doctors.json');
    }

    getPrivacyPolicyTitleJson() {
        return this.http.get<any>('assets/privacyPolicy.json');
    }

    getPaymentPolicyTitleJson() {
        return this.http.get<any>('assets/paymentPolicy.json');
    }

    getAwardsTitleJson() {
        return this.http.get<any>('assets/awards.json');
    }


}
