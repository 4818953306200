import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from "../../../shared/services/title.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    hostName: any = 'https://www.draxico.com';
    aboutName: any
    aboutDrAxicoUrlArr: any;
    aboutDrAxicoUrl: any;
    validUrl: any = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta,
                private metaServices: MetaService) {
    }

    ngOnInit(): void {
        this.validUrl = ['axico', 'achievements', 'ayurveda'];
        this.aboutName = this.route.snapshot.params['about-name'];
        if (!this.validUrl.includes(this.aboutName)) {
            this.router.navigateByUrl('/404-error-page');
        }
        this.aboutDrAxicoUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.aboutDrAxicoUrl);
    }


    getAllTitle() {
        this.titleService.getAboutDrAxicoTitleJson()
            .subscribe(res => {
                this.aboutDrAxicoUrlArr = res.title;
                if (this.aboutDrAxicoUrl && this.aboutDrAxicoUrlArr.length > 0) {
                    this.aboutDrAxicoUrlArr.forEach(aboutDrAxicoUrl => {
                        if (aboutDrAxicoUrl.url === this.aboutDrAxicoUrl) {
                            this.titleLibraryService.setTitle(aboutDrAxicoUrl.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: aboutDrAxicoUrl.keywords});
                            this.metaService.updateTag({name: 'description', content: aboutDrAxicoUrl.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: aboutDrAxicoUrl.og_title});
                            this.metaService.updateTag({property: 'og:url', content: aboutDrAxicoUrl.og_url});
                            this.metaService.updateTag({property: 'og:type', content: aboutDrAxicoUrl.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: aboutDrAxicoUrl.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: aboutDrAxicoUrl.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: aboutDrAxicoUrl.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: aboutDrAxicoUrl.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: aboutDrAxicoUrl.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: aboutDrAxicoUrl.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: aboutDrAxicoUrl.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: aboutDrAxicoUrl.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: aboutDrAxicoUrl.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: aboutDrAxicoUrl.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: aboutDrAxicoUrl.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: aboutDrAxicoUrl.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: aboutDrAxicoUrl.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: aboutDrAxicoUrl.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: aboutDrAxicoUrl.twitter_image});
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: aboutDrAxicoUrl.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: aboutDrAxicoUrl.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: aboutDrAxicoUrl.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: aboutDrAxicoUrl.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: aboutDrAxicoUrl.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: aboutDrAxicoUrl.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idfmge',
                                content: aboutDrAxicoUrl.twitter_idfmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idmmge',
                                content: aboutDrAxicoUrl.twitter_idmmge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:idrmge',
                                content: aboutDrAxicoUrl.twitter_idrmge
                            });
                        }

                    });
                }
            });
    }
}
