import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {AddToCartService} from "../../../shared/services/add-to-cart.service";
import {StorageService} from "../../../shared/services/storage.service";
import {AppointmentService} from '../../../shared/services/appointment.service';
import {Environment} from "@angular/cli/lib/config/workspace-schema";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-user-appointments',
    templateUrl: './user-appointments.component.html',
    styleUrls: ['./user-appointments.component.scss']
})
export class UserAppointmentsComponent implements OnInit {
    submitted: any = false;
    submitting: any = false;
    appointments: any = [];
    user: any;

    constructor(public modalService: NgbModal,
                public router: Router,
                public addToCartService: AddToCartService,
                private appointmentService: AppointmentService) {
        this.user = StorageService.getItem('self');
        if (!this.user || !this.user.user_id) {
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            });
        }
    }

    ngOnInit(): void {
        this.get();
    }

    get() {
        this.submitting = true;
        this.appointments = [];
        this.appointmentService.get({'page': -1}).subscribe(data => {
            this.appointments = data['data'];
            if (this.appointments && this.appointments.length > 0) {
                this.appointments.forEach(appointment => {
                    if (appointment) {
                        appointment['consult_show_status'] = 'CLOSE';
                    }
                });
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    openUrl(url) {
        window.open(url, '_blank');
    }

    openExternalUrl(url) {
        window.open(environment.appUrl + url, '_blank');
    }
}
