import {Component, OnInit} from '@angular/core';
import {TitleService} from "../../../shared/services/title.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../../shared/services/meta.service";

@Component({
    selector: 'app-panchakarma-therapies',
    templateUrl: './panchakarma-therapies.component.html',
    styleUrls: ['./panchakarma-therapies.component.scss']
})
export class PanchakarmaTherapiesComponent implements OnInit {
    url: any;
    panchakarmaPageUrl: any;
    panchakarmaPageArr: any;
    hostName: any = 'https://www.draxico.com';

    constructor(private titleService: TitleService,
                private titleLibraryService: Title,
                private metaService: Meta,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private metaServices: MetaService) {
    }

    ngOnInit(): void {
        this.url = this.router.url;
        this.panchakarmaPageUrl = window.location.pathname;
        this.getAllTitle();
        this.metaServices.updateCanonicalUrl(this.hostName + this.panchakarmaPageUrl);
    }

    getAllTitle() {
        this.titleService.getPanchakarmaJson()
            .subscribe(res => {
                this.panchakarmaPageArr = res.title;
                if (this.panchakarmaPageArr) {
                    this.panchakarmaPageArr.forEach(panchakarmaPageUrl => {
                        if (panchakarmaPageUrl.url == this.panchakarmaPageUrl) {
                            this.titleLibraryService.setTitle(this.panchakarmaPageArr[0].title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({
                                name: 'keywords',
                                content: this.panchakarmaPageArr[0].keywords
                            });
                            this.metaService.updateTag({
                                name: 'description',
                                content: this.panchakarmaPageArr[0].descriptions
                            });

                        }

                    });


                }
            });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
