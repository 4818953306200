import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {GlobalService} from '../../../shared/services/global.service';
import {StorageService} from '../../../shared/services/storage.service';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ForgotPasswordService} from '../../../shared/services/forgot-password.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public contactForm: FormGroup;
    public forgotPasswordForm: FormGroup;
    public passwordTextType: boolean;
    submitted: any = false;
    submitting: any = false;
    submittedContact: any = false;
    submittingContact: any = false;
    id: any;
    data: any;
    userDetails: any;
    public returnUrl: string;
    public error = '';
    modalType: any;
    decodedOtp: any;
    timerSixtySeconds: number = 60;
    interval: any;
    user: any;
    selectedContact: any;

    private _unsubscribeAll: Subject<any>;
    isBusy = false;
    loginPressed = false;

    constructor(private fb: FormBuilder,
                private alertService: AlertService,
                private _route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private globalService: GlobalService,
                public modalService: NgbModal,
                private forgotPasswordService: ForgotPasswordService) {
        this._unsubscribeAll = new Subject();
        this.user = StorageService.getToken();
        if (this.user) {
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            });
        }
    }

    ngOnInit(): void {
        this.refresh();
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    }

    // get f() {
    //     return this.loginForm.controls;
    // }

    togglePasswordTextType() {
        this.passwordTextType = !this.passwordTextType;
    }

    refresh() {
        this.loginForm = this.fb.group({
            'username': ['', Validators.required],
            'password': ['', Validators.required],
        });
        this.forgotPasswordForm = this.fb.group({
            'newPassword': ['', Validators.required],
            'confirmPassword': ['', Validators.required],
            'otp': ['', Validators.required]
        });
        this.contactForm = this.fb.group({
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])]
        });
    }

    submit() {
        // if (this.isBusy) {
        //     return;
        // }
        // this.isBusy = true;
        // this.loginPressed = true;
        this.submitted = true;
        if (!this.loginForm.valid) {
            return;
        }
        if (this.checkLoggedInStatus()) {
            this.setSelfData();
            this.router.navigateByUrl('');
        } else {
            this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                if (data) {
                    this.globalService.setAccessToken(data.token);
                    this.globalService.setSelf({
                        'name': data.name,
                        'user_id': data.id,
                        'username': data.username,
                        'email': data.email,
                        'contact': data.contact,
                        'user_type': data.user_type
                    });
                    this.router.navigate(['/']).then(() => {
                        window.location.reload();
                    });
                    this.loginForm.reset();
                    this.loginPressed = false;
                }
            }, error => {
                this.isBusy = false;
                this.loginPressed = false;
                // this.alertService.showErrors("Something went wrong");
            });
        }
    }

    checkLoggedInStatus() {
        const accessToken = StorageService.getItem('accessToken');
        return accessToken && accessToken !== 'null';
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    setSelfData() {
        this.authService.self({all: true}).subscribe((data) => {
            this.isBusy = false;
            this.globalService.setSelf({
                'name': data.name,
                'userId': data.id,
                'username': data.username,
                'user_type': data.user_type
            });
            return;
        }, e => {
            this.isBusy = false;
            StorageService.clearAll();
        });
    }

    openSubmitModal(content) {
        this.modalType = 'SUBMIT_CONTACT';
        this.modalService.open(content, {'size': 'md'});
    }

    submitContact() {
        this.submittedContact = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submittedContact) {
            const params = {
                'contact': (this.contactForm.value && this.contactForm.value.contact) ? this.contactForm.value.contact : '',
            };
            this.selectedContact = params;
            this.submittingContact = true;
            this.forgotPasswordService.create(params).subscribe(data => {
                this.submittingContact = false;
                this.submittedContact = false;
                this.modalType = 'SUBMIT_PASSWORD';
                this.resendOTPTimer();
            }, error => {
                this.submittingContact = false;
                this.submittedContact = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    resendOTP() {
        this.submitting = true;
        if (!this.forgotPasswordForm.valid) {
            return;
        }
        this.authService.resendOTP(this.forgotPasswordForm.value).subscribe(data => {
            if (data && data['decoded_otp']) {
                this.decodedOtp = data['decoded_otp'];
                this.resendOTPTimer();
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    resendOTPTimer() {
        this.timerSixtySeconds = 60;
        this.interval = setInterval(() => {
            if (this.timerSixtySeconds > 0) {
                this.timerSixtySeconds--;
            } else {
                this.interval = undefined;
            }
        }, 1000);
    }

    submitPassword() {
        this.modalType = 'SUBMIT_PASSWORD';
        this.submitting = true;
        this.submitted = true;
        if (!this.forgotPasswordForm.valid) {
            this.submitting = false;
            return;
        }
        if (!this.forgotPasswordForm.value || !this.forgotPasswordForm.value.newPassword || !this.forgotPasswordForm.value.confirmPassword || this.forgotPasswordForm.value.newPassword !== this.forgotPasswordForm.value.confirmPassword) {
            this.submitting = false;
            this.alertService.showErrors('New Password & Confirm Password didn\'t match');
        } else {
            this.submitting = true;
            this.submitted = true;
            const params = {
                'password': (this.forgotPasswordForm.value && this.forgotPasswordForm.value.newPassword) ? this.forgotPasswordForm.value.newPassword : '',
                'confirmPassword': (this.forgotPasswordForm.value && this.forgotPasswordForm.value.confirmPassword) ? this.forgotPasswordForm.value.confirmPassword : '',
                'otp': (this.forgotPasswordForm.value && this.forgotPasswordForm.value.otp) ? this.forgotPasswordForm.value.otp : '',
                'contact': this.selectedContact.contact
            };
            this.forgotPasswordService.add(params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.modalService.dismissAll('');
            }, error => {
                if (error && error['error'] && error['error']['errorDetails']) {
                    this.alertService.showErrors(error['error']['errorDetails']);
                }
                this.submitting = false;
                this.submitted = false;
            });
        }
    }
}
