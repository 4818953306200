import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
    selector: 'app-user-dashboard',
    templateUrl: './user-dashboard.component.html',
    styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit, AfterViewInit {
    id: any;
    user: any;
    users: any = [];

    constructor(private appointmentService: AppointmentService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngAfterViewInit(): void {

    }

    refresh() {
        this.getAppointment();
    }

    getAppointment() {
        this.appointmentService.detail(this.user.userId).subscribe(data => {
            if (data) {
                this.users = data;
            }
        });
    }

}
