import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService)
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.BLOGS(), data);
    }

    detail(title): Observable<any> {
        return this.getRequest(AppUrl.BLOGS(title));
    }

    get_hindi(data): Observable<any> {
        return this.getRequest(AppUrl.HINDI_BLOGS(), data);
    }

    detail_hindi(title): Observable<any> {
        return this.getRequest(AppUrl.HINDI_BLOGS(title));
    }
}
