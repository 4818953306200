import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicines',
  templateUrl: './medicines.component.html',
  styleUrls: ['./medicines.component.scss']
})
export class MedicinesComponent implements OnInit {
    location: any;
  constructor() { }

  ngOnInit(): void {
  }

}
