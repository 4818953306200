import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TitleService} from 'src/app/shared/services/title.service';
import {Meta, Title} from '@angular/platform-browser';
import {MetaService} from 'src/app/shared/services/meta.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AppointmentService} from "../../../shared/services/appointment.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertService} from "../../../shared/services/alert.service";
import {BlogService} from "../../../shared/services/blog.service";
import {GlobalService} from "../../../shared/services/global.service";

@Component({
    selector: 'app-diseases-details',
    templateUrl: './diseases-details.component.html',
    styleUrls: ['./diseases-details.component.scss']
})
export class DiseasesDetailsComponent implements OnInit {
    hostName: any = 'https://www.draxico.com/diseases';
    diseasesName: any;
    diseasesUrlArr: any;
    appointmentForm: FormGroup;
    otpForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    decodedOtp: any;
    appointment: any;
    disease: string;
    blogList: any;
    validUrl: any = [];

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private titleService: TitleService,
        private titleLibraryService: Title,
        private metaServices: MetaService,
        private metaService: Meta,
        private appointmentService: AppointmentService,
        private blogServices: BlogService,
        private modalService: NgbModal,
        private globalService: GlobalService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.validUrl = [
            'acne',
            'arthritis',
            'asthma',
            'bacterial-vaginosis',
            'bronchiectasis',
            'chest-pain',
            'chronic-kidney-diseases',
            'common-cold',
            'dehydration',
            'dengue',
            'food-poisoning',
            'hair-loss-and-balding',
            'heart-disease',
            'kidney-infection',
            'kidney-stone',
            'malaria',
            'migraine',
            'peptic-ulcer-disease',
            'rheumatoid-arthritis',
            'ulcerative-colitis',
            'diabetes',
            'appendicitis',
            'bronchitis',
            'cervical-radiculopathy',
            'chest-infection',
            'cholesterol-level',
            'cirrhosis',
            'constipation',
            'cough',
            'eczema',
            'gastroenteritis',
            'headaches',
            'hepatitis-c',
            'liver-disease',
            'psoriasis',
            'sciatica',
            'urticaria',
            'irritable-bowel-syndrome'
        ];
        this.diseasesName = this.route.snapshot.params['diseases-name'];
        if (!this.validUrl.includes(this.diseasesName)) {
            this.router.navigateByUrl('/404-error-page');
        }
        this.disease = this.transformTerm(this.diseasesName);
        this.metaServices.updateCanonicalUrl(this.hostName + '/' + this.diseasesName);
        this.getAllTitle();
        this.getBlogs();
        this.appointmentForm = this.fb.group({
            name: ['', Validators.required],
            contact: ['', Validators.required],
            symptoms: ['', Validators.required],
            age: ['', Validators.required],
            gender: ['', Validators.required]
        });
        this.otpForm = this.fb.group({
            otp: ['', Validators.required],
        })
    }

    transformTerm(term: string): string {
        return term.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    getBlogs() {
        this.blogServices.get({'page': 1, 'per_page': 3}).subscribe((data: any) => {
            this.blogList = data['data'];
        })
    }

    submit(content) {
        this.submitted = true;
        if (!this.appointmentForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.appointmentForm.value && this.appointmentForm.value.name) ? this.appointmentForm.value.name : '',
                'contact': (this.appointmentForm.value && this.appointmentForm.value.contact) ? this.appointmentForm.value.contact : '',
                'symptoms': (this.appointmentForm.value && this.appointmentForm.value.symptoms) ? this.appointmentForm.value.symptoms : '',
                'age': (this.appointmentForm.value && this.appointmentForm.value.age) ? this.appointmentForm.value.age : '',
                'gender': (this.appointmentForm.value && this.appointmentForm.value.gender) ? this.appointmentForm.value.gender : '',
                'mode': 'ONLINE'
            };
            this.appointmentService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.appointment = data;
                    this.decodedOtp = (this.appointment && this.appointment['decoded_otp']) ? this.appointment['decoded_otp'] : '';
                    this.modalService.open(content, {'backdrop': 'static', 'keyboard': false});
                    this.appointmentForm.patchValue({
                        'name': '',
                        'contact': '',
                        'symptoms': '',
                        'age': '',
                        'gender': ''
                    });
                }
            }, error => {
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].symptoms && error['error']['errors'].symptoms.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].symptoms[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].gender && error['error']['errors'].gender.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].gender[0]);
                        return;
                    }
                    if (error['error']['errors'].mode && error['error']['errors'].mode.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].mode[0]);
                        return;
                    }
                }
            });
        }
    }

    submitOtp(content2) {
        this.submitted = true;
        if (!this.otpForm.valid) {
            return;
        }
        let params = {
            'contact': (this.appointment && this.appointment['contact']) ? this.appointment['contact'] : '',
            'otp': (this.otpForm.value && this.otpForm.value['otp']) ? this.otpForm.value['otp'] : '',
            'decoded_otp': this.decodedOtp
        };
        this.appointmentService.verifyOtp(params).subscribe(data => {
            if (data) {
                this.globalService.setAccessToken(data.token);
                this.globalService.setSelf({
                    'name': data.name,
                    'user_id': data.id,
                    'username': data.username,
                    'email': data.email,
                    'contact': data.contact,
                    'user_type': data.user_type
                });
                this.decodedOtp = undefined;
                this.submitting = false;
                this.submitted = false;
                this.refresh();
                this.modalService.dismissAll('');
                this.router.navigate(['/']).then(() => {
                    window.location.reload();
                });
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
            if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                if (error['error']['errors'].otp && error['error']['errors'].otp.length > 0) {
                    this.alertService.showErrors(error['error']['errors'].otp[0]);
                    return;
                }
            }
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    getAllTitle() {
        this.titleService.getDiseasesTitleJson().subscribe(res => {
                this.diseasesUrlArr = res.title;
                if (this.diseasesName && this.diseasesUrlArr.length > 0) {
                    this.diseasesUrlArr.forEach(diseasesName => {
                        if (diseasesName.url === this.diseasesName) {
                            this.titleLibraryService.setTitle(diseasesName.title);
                            this.metaService.addTags([
                                {
                                    name: 'keywords',
                                    content: ''
                                },
                                {name: 'description', content: ''},
                                {property: 'og:title', content: ''},
                                {property: 'og:url', content: ''},
                                {property: 'og:type', content: ''},
                                {property: 'og:description', content: ''},
                                {property: 'og:image', content: ''},
                                {property: 'og:ib.scge', content: ''},
                                {property: 'og:im.scge', content: ''},
                                {property: 'og:ib.edge', content: ''},
                                {property: 'og:ib.libge', content: ''},
                                {property: 'og:im.libge', content: ''},
                                {property: 'og:ima journalismge', content: ''},
                                {property: 'og:idfmge', content: ''},
                                {property: 'og:idmmge', content: ''},
                                {property: 'og:idrmge', content: ''},
                                {name: 'twitter:card', content: ''},
                                {name: 'twitter:title', content: ''},
                                {name: 'twitter:description', content: ''},
                                {name: 'twitter:url', content: ''},
                                {name: 'twitter:image', content: ''},
                                {name: 'twitter:ib.scge', content: ''},
                                {name: 'twitter:im.scge', content: ''},
                                {name: 'twitter:ib.edge', content: ''},
                                {name: 'twitter:ib.libge', content: ''},
                                {name: 'twitter:im.libge', content: ''},
                                {name: 'twitter:ima journalismge', content: ''},
                                {name: 'twitter:idfmge', content: ''},
                                {name: 'twitter:idmmge', content: ''},
                                {name: 'twitter:idrmge', content: ''},
                            ]);

                            this.metaService.updateTag({name: 'keywords', content: diseasesName.keywords});
                            this.metaService.updateTag({name: 'description', content: diseasesName.descriptions});
                            this.metaService.updateTag({property: 'og:title', content: diseasesName.og_title});
                            this.metaService.updateTag({property: 'og:url', content: diseasesName.og_url});
                            this.metaService.updateTag({property: 'og:type', content: diseasesName.og_type});
                            this.metaService.updateTag({
                                property: 'og:description',
                                content: diseasesName.og_description
                            });
                            this.metaService.updateTag({property: 'og:image', content: diseasesName.og_image});
                            this.metaService.updateTag({property: 'og:ib.scge', content: diseasesName.og_ib_scge});
                            this.metaService.updateTag({property: 'og:im.scge', content: diseasesName.og_im_scge});
                            this.metaService.updateTag({property: 'og:ib.edge', content: diseasesName.og_ib_edge});
                            this.metaService.updateTag({property: 'og:ib.libge', content: diseasesName.og_ib_libge});
                            this.metaService.updateTag({property: 'og:im.libge', content: diseasesName.og_im_libge});
                            this.metaService.updateTag({
                                property: 'og:ima journalismge',
                                content: diseasesName.og_ima_journalismge
                            });
                            this.metaService.updateTag({property: 'og:idfmge', content: diseasesName.og_idfmge});
                            this.metaService.updateTag({property: 'og:idmmge', content: diseasesName.og_idmmge});
                            this.metaService.updateTag({property: 'og:idrmge', content: diseasesName.og_idrmge});
                            this.metaService.updateTag({name: 'twitter:card', content: diseasesName.twitter_card});
                            this.metaService.updateTag({name: 'twitter:title', content: diseasesName.twitter_title});
                            this.metaService.updateTag({
                                name: 'twitter:description',
                                content: diseasesName.twitter_description
                            });
                            this.metaService.updateTag({name: 'twitter:image', content: diseasesName.twitter_image});
                            this.metaService.updateTag({
                                name: 'twitter:ib.scge',
                                content: diseasesName.twitter_ib_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.scge',
                                content: diseasesName.twitter_im_scge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.edge',
                                content: diseasesName.twitter_ib_edge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ib.libge',
                                content: diseasesName.twitter_ib_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:im.libge',
                                content: diseasesName.twitter_im_libge
                            });
                            this.metaService.updateTag({
                                name: 'twitter:ima journalismge', content: diseasesName.twitter_ima_journalismge
                            });
                            this.metaService.updateTag({name: 'twitter:idfmge', content: diseasesName.twitter_idfmge});
                            this.metaService.updateTag({name: 'twitter:idmmge', content: diseasesName.twitter_idmmge});
                            this.metaService.updateTag({name: 'twitter:idrmge', content: diseasesName.twitter_idrmge});
                        }

                    });
                }
            });
    }
}
