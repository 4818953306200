<div class="page-title-area page-title-four" style="background-image: url('assets/img/banners/consult-us.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Consult</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Consult</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="blog-details-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8 wow fadeInUp" data-wow-delay=".3s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <h3 class="p-20"><a routerLink="#"> Consultation Details</a></h3>
                                <div class="card">
                                    <div class="row p-20">
                                        <div class="col-12">
                                            <div class="row p-20">
                                                <div class="col-12 p-30">
                                                    <label>Appointment Id.</label>
                                                    <div>{{users.appointment_id}}</div>
                                                </div>
                                                <div class="col-12 p-30">
                                                    <label>Consult Id.</label>
                                                    <div>{{users.consult_id}}</div>
                                                </div>
                                                <div class="col-12 p-30">
                                                    <label>Doctor Id.</label>
                                                    <div>{{users.doctor_id}}</div>
                                                </div>
                                                <div class="col-12 p-30">
                                                    <label>Start Date Time.</label>
                                                    <div>{{users.start_data_time}}</div>
                                                </div>

                                                <div class="col-12 p-30">
                                                    <label>End Date Time.</label>
                                                    <div>{{users.end_data_time}}</div>
                                                </div>
                                                <div class="col-12 p-30">
                                                    <label>Date.</label>
                                                    <div>{{users.date}}</div>
                                                </div>
                                                <div class="col-12 p-30">
                                                    <label>Remarks.</label>
                                                    <div>{{users.remarks}}</div>
                                                </div>
                                                <div class="col-12 p-30">
                                                    <label>Status.</label>
                                                    <div>{{users.status}}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="blog-bottom">
                                <ul>
                                    <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                                    </li>
                                    <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="blog-area-two">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg11.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="#"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img alt="Blog" src="assets/img/home-one/home-bg13.jpg"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a>
                        </h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
