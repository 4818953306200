<div class="container">
    <div class="row" style="padding-top: 100px; padding-bottom: 100px">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
            <i class="fas fa-times-circle fa-3x text-danger"></i>
            <h2 class="mt-10">Payment Failed</h2>
            <h6 class="mt-10">Please try again later</h6>
            <button (click)="goTo('/products')" class="btn btn-primary">Continue Shopping</button>
        </div>
    </div>
</div>
