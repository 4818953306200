import {Component, OnInit} from '@angular/core';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../../shared/services/storage.service';
import {PrescriptionService} from '../../../shared/services/prescription.service';
import {FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AddToCartService} from "../../../shared/services/add-to-cart.service";

@Component({
    selector: 'app-prescriptions',
    templateUrl: './prescriptions.component.html',
    styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent implements OnInit {
    public appointmentForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    counter = 1;
    removeProduct = true;
    items: any = [];
    discountSum: any;
    actualSum: any;
    subTotalSum: any;
    discountedPriceSum: any = [];
    user: any;

    constructor(public modalService: NgbModal,
                public router: Router,
                public addToCartService: AddToCartService) {
        this.user = StorageService.getItem('self');
        if (!this.user || !this.user.user_id) {
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            });
        }
    }

    ngOnInit(): void {
        this.get();
    }

    get() {
        this.submitting = true;
        this.items = [];
        this.addToCartService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.items = data['data'];
                this.getCalculations();
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    increment() {
        (this.counter) ? this.counter += 1 : 1;
    }

    decrement() {
        (this.counter > 1) ? this.counter -= 1 : 1;
    }

    getCalculations() {
        let discount_arr = [];
        let discounted_price_arr = [];
        let actual_price_arr = [];
        this.items.forEach(item => {
            discount_arr.push(parseFloat(item['product']['actual_price']) - parseFloat(item['product']['discounted_price']));
            discounted_price_arr.push(item['product']['discounted_price']);
            actual_price_arr.push(item['product']['actual_price']);
        });
        this.discountSum = discount_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.actualSum = actual_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.subTotalSum = this.actualSum - this.discountSum;
        this.discountedPriceSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    }

    changeAddressModal(content) {
        this.modalService.open(content, {size: 'xl'});
    }

    submit() {
    }

    checkoutPage() {
        this.router.navigateByUrl('checkout');
    }

    removeItem() {
        this.removeProduct = false;
    }

    addToCart(product: any) {
        this.items.push(product);
    }

    getItems() {
        return this.items;
    }

    clearCart() {
        this.items = [];
        return this.items;
    }

    openSm(content) {
        this.modalService.open(content, {size: 'sm'});
    }

    remove() {
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
