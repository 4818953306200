import {Component, DoCheck, OnChanges, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Meta, Title} from '@angular/platform-browser';
import {TitleService} from "../../../shared/services/title.service";
import {MetaService} from '../../../shared/services/meta.service';
import {BlogService} from "../../../shared/services/blog.service";
import {Location} from "@angular/common";

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit, OnChanges, DoCheck {

    @ViewChild('content') templateRef: TemplateRef<any>;
    hostName: any = 'https://www.draxico.com/blogs';
    blog: any;
    name = 'countUp Demo';
    image: any = '';
    hashtags: any = '';
    url: any = 'https://www.draxico.com/blogs/periods-problem-in-women-or-girls';
    id: any;
    type: any;
    blogsData: any;
    blogsHindiData: any;
    selectedBlogData: any;
    selectedBlogContent: any;
    selectedBlogMetaData: any;
    title: any;
    status: boolean = false;
    languageType: boolean = false;

    constructor(private route: ActivatedRoute,
                public modalService: NgbModal,
                private router: Router,
                public _location: Location,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaServices: MetaService,
                private metaService: Meta,
                private blogServices: BlogService) {
        // this.title = this.route.snapshot.params['blog-name'];
        // this.refresh();
    }

    ngDoCheck(): void {
        if (this.status) {
            this.title = this.route.snapshot.params['blog-name'];
            this.getDetailBlog();
            this.status = false;
        }
    }

    ngOnInit(): void {
        this.title = this.route.snapshot.params['blog-name'];
        this.refresh()
    }

    ngOnChanges(): void {
        this.title = this.route.snapshot.params['blog-name'];
        this.refresh()
    }

    refresh() {
        this.metaServices.updateCanonicalUrl(this.hostName + '/' + this.title);
        this.getDetailBlog();
        this.getBlog();

    }

    goTo(url, id?) {
        this.title = this.route.snapshot.params['blog-name'];
        this.router.navigateByUrl(url);
        this.status = true;
    }

    translateContent() {
        this.languageType = !this.languageType
    }

    getDetailBlog(params: any = {}) {
        this.blogServices.detail(this.title).subscribe(data => {
            if (data) {
                this.selectedBlogData = data;
                this.selectedBlogMetaData = data.marketing_meta_tags;
                if (this.selectedBlogMetaData) {
                    this.titleLibraryService.setTitle(this.selectedBlogMetaData.title);
                    this.metaService.addTags([
                        {
                            name: 'keywords',
                            content: ''
                        },
                        {name: 'description', content: ''},
                        {property: 'og:title', content: ''},
                        {property: 'og:url', content: ''},
                        {property: 'og:type', content: ''},
                        {property: 'og:description', content: ''},
                        {property: 'og:image', content: ''},
                        {property: 'og:ib.scge', content: ''},
                        {property: 'og:im.scge', content: ''},
                        {property: 'og:ib.edge', content: ''},
                        {property: 'og:ib.libge', content: ''},
                        {property: 'og:im.libge', content: ''},
                        {property: 'og:ima journalismge', content: ''},
                        {property: 'og:idfmge', content: ''},
                        {property: 'og:idmmge', content: ''},
                        {property: 'og:idrmge', content: ''},
                        {name: 'twitter:card', content: ''},
                        {name: 'twitter:title', content: ''},
                        {name: 'twitter:description', content: ''},
                        {name: 'twitter:url', content: ''},
                        {name: 'twitter:image', content: ''},
                        {name: 'twitter:ib.scge', content: ''},
                        {name: 'twitter:im.scge', content: ''},
                        {name: 'twitter:ib.edge', content: ''},
                        {name: 'twitter:ib.libge', content: ''},
                        {name: 'twitter:im.libge', content: ''},
                        {name: 'twitter:ima journalismge', content: ''},
                        {name: 'twitter:idfmge', content: ''},
                        {name: 'twitter:idmmge', content: ''},
                        {name: 'twitter:idrmge', content: ''},
                    ]);
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: this.selectedBlogData?.marketing_meta_tags?.meta_keyword
                    });
                    this.metaService.updateTag({
                        name: 'description',
                        content: this.selectedBlogMetaData.meta_description
                    });
                    this.metaService.updateTag({
                        property: 'og:title',
                        content: this.selectedBlogMetaData.meta_og_title
                    });
                    this.metaService.updateTag({
                        property: 'og:url',
                        content: this.selectedBlogMetaData.meta_og_url
                    });
                    this.metaService.updateTag({
                        property: 'og:type',
                        content: this.selectedBlogMetaData.meta_og_type
                    });
                    this.metaService.updateTag({
                        property: 'og:description',
                        content: this.selectedBlogMetaData.meta_og_description
                    });
                    this.metaService.updateTag({
                        property: 'og:image',
                        content: this.selectedBlogMetaData.meta_og_image
                    });
                    this.metaService.updateTag({
                        property: 'og:ib.scge',
                        content: this.selectedBlogMetaData.og_ib_scge
                    });
                    this.metaService.updateTag({
                        property: 'og:im.scge',
                        content: this.selectedBlogMetaData.og_im_scge
                    });
                    this.metaService.updateTag({
                        property: 'og:ib.edge',
                        content: this.selectedBlogMetaData.og_ib_edge
                    });
                    this.metaService.updateTag({
                        property: 'og:ib.libge',
                        content: this.selectedBlogMetaData.og_ib_libge
                    });
                    this.metaService.updateTag({
                        property: 'og:im.libge',
                        content: this.selectedBlogMetaData.og_im_libge
                    });
                    this.metaService.updateTag({
                        property: 'og:ima journalismge',
                        content: this.selectedBlogMetaData.og_ima_journalismge
                    });
                    this.metaService.updateTag({
                        property: 'og:idfmge',
                        content: this.selectedBlogMetaData.og_idfmge
                    });
                    this.metaService.updateTag({
                        property: 'og:idmmge',
                        content: this.selectedBlogMetaData.og_idmmge
                    });
                    this.metaService.updateTag({
                        property: 'og:idrmge',
                        content: this.selectedBlogMetaData.og_idrmge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:card',
                        content: this.selectedBlogMetaData.meta_twitter_card
                    });
                    this.metaService.updateTag({
                        name: 'twitter:title',
                        content: this.selectedBlogMetaData.meta_twitter_title
                    });
                    this.metaService.updateTag({
                        name: 'twitter:description',
                        content: this.selectedBlogMetaData.meta_twitter_description
                    });
                    this.metaService.updateTag({
                        name: 'twitter:image',
                        content: this.selectedBlogMetaData.meta_twitter_image
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ib.scge',
                        content: this.selectedBlogMetaData.twitter_ib_scge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:im.scge',
                        content: this.selectedBlogMetaData.twitter_im_scge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ib.edge',
                        content: this.selectedBlogMetaData.twitter_ib_edge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ib.libge',
                        content: this.selectedBlogMetaData.twitter_ib_libge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:im.libge',
                        content: this.selectedBlogMetaData.twitter_im_libge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ima journalismge',
                        content: this.selectedBlogMetaData.twitter_ima_journalismge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:idfmge',
                        content: this.selectedBlogMetaData.twitter_idfmge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:idmmge',
                        content: this.selectedBlogMetaData.twitter_idmmge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:idrmge',
                        content: this.selectedBlogMetaData.twitter_idrmge
                    });
                }
            }
        }, error => {
            this.router.navigateByUrl('/404-error-page');
        });
    }

    getBlog(params: any = {}) {
        this.blogServices.get({'page': 1, 'per_page': 5}).subscribe(data => {
            if (data) {
                this.blogsData = data['data'];
            }
        });
    }
}
