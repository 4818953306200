<div class="navbar-area border-top border-bottom">
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a [routerLinkActiveOptions]="{exact: true}" class="nav-link" href="javascript:void(0)"
                               routerLink="/" routerLinkActive="active">Offers</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                               routerLink="/about"
                               routerLinkActive="active">Newly Launched</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)" routerLink="/appointment">Combo Pack</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)">Essentials</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle"
                               href="javascript:void(0)"
                               routerLink="/doctor" routerLinkActive="active">Personal
                                Care</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/appointment"
                                       routerLinkActive="active">Face Wash</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/departments"
                                       routerLinkActive="active">Soap</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/testimonials"
                                       routerLinkActive="active">Shampoo &
                                        Conditioners</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/login"
                                       routerLinkActive="active">Toner Cleanser &
                                        Lotion</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/signup"
                                       routerLinkActive="active">Masks,Packs &
                                        scrubs</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/privacy-policy"
                                       routerLinkActive="active">Hair lep</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/terms-condition"
                                       routerLinkActive="active">Skin Care</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link dropdown-toggle " href="javascript:void(0)" routerLink="/appointment">Health
                                And Wellness</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/appointment"
                                       routerLinkActive="active">Personal Wellness</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/departments"
                                       routerLinkActive="active">Juice And Sippers</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/testimonials"
                                       routerLinkActive="active">Ayurvedic
                                        Supplement</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/login"
                                       routerLinkActive="active">Teas</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/signup"
                                       routerLinkActive="active">Chawanprash</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/privacy-policy"
                                       routerLinkActive="active">Honey</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/terms-condition"
                                       routerLinkActive="active">Ghee</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/faq"
                                       routerLinkActive="active">Churna</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/error"
                                       routerLinkActive="active">Syrup</a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="nav-link"
                                       routerLink="/coming-soon"
                                       routerLinkActive="active">Kwath</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
