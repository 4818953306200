<div *ngIf="aboutName === 'axico'" class="page-title-area page-title-one tablet-top-margin"
     style="background-image: url('assets/img/banners/about-us.jpg');" xmlns="http://www.w3.org/1999/html"
     xmlns="http://www.w3.org/1999/html">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Dr.Axico</h2>
                <ul>
                    <li><a routerLink="/">About Us</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Dr.Axico</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="aboutName === 'achievements'" class="page-title-area page-title-one tablet-top-margin"
     style="background-image: url('assets/img/banners/about-us.jpg');" xmlns="http://www.w3.org/1999/html"
     xmlns="http://www.w3.org/1999/html">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Achievements</h2>
                <ul>
                    <li><a routerLink="/">About Us</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Achievements</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="aboutName === 'ayurveda'" class="page-title-area page-title-one tablet-top-margin"
     style="background-image: url('assets/img/banners/about.jpg');" xmlns="http://www.w3.org/1999/html"
     xmlns="http://www.w3.org/1999/html">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>About Ayurveda</h2>
                <ul>
                    <li><a routerLink="/">About Us</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About Ayurveda</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div *ngIf="aboutName === 'axico'" class="about-area pt-100 pb-70 mobile-top-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img alt="About" src="assets/img/about-us.jpg">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img alt="About" src="assets/img/home-one/home-bg5.png">
                    <h2>Certified Doctors</h2>
                    <p>We are having a team of Specialized and skilled Ayurvedic Doctors with experience of 10 +
                        years.</p>
                    <h3>Best medicinal Kits</h3>
                    <p>Our specialized medicines kits are clinically proved, recommended by experienced doctors and
                        trusted by our patients.</p>
                    <h4>Modern Technology</h4>
                    <p>Our medicines are manufactured in supervision with a highly-skilled research team and with the
                        latest Phyto nanotechnology which enhances medicinal potential over any disease.</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-5">
            <div class="col-lg-12 col-xs-12">
                <div class="about-item about-right">
                    <img alt="About" src="assets/img/home-one/home-bg5.png">
                    <h2 class="mt-10">What is Dr. Axico</h2>
                    <p>
                        “Dr. Axico” – an online communication interface platform where people can consult with doctors
                        and address their health-related concerns to seek reliable advices.
                        Dr. Axico, vertical of Axico HealthCare Pvt Ltd functioning since 2012 to become paramount in
                        the healthcare industry. Dr. Axico allows you to conduct face-to-face video calls with a doctor
                        after an appointment, it also has a self-health assessment feature to self-diagnose various
                        health problems, with Dr. Axico you get medicines manufactured with the latest Phyto
                        Nanotechnology which enhances medicinal benefits to cure faster. Dr. Axico is the doctor’s
                        recommended app/website which aims to provide overall solutions to health-related issues.
                        Dr. Pawan Kumar Singhal, director of MS Group of Companies, with his vast expertise build
                        several leading trusted brands like MS Institute of Education Pvt. Ltd, Axico Health Care Pvt.
                        Ltd., Jan Media Publication Pvt. Ltd., Nation Live, Shiksha Bharti Prakashan Pvt. Ltd,
                        Edutantra.
                        MS group was incorporated in 1996 with an aim to enrich & add values to the education sector
                        with our skilled proficiency, with the growing time we are now the well-known face in India.
                        We have come a long way, but the destination is still far away, working hard with dedication &
                        sincerity to become a world-famous company.
                    </p>
                    <h3 class="mt-30px">Axico’s Mission and Vision</h3>
                    <p class="text-center">
                        ॐ<br>
                        सर्वे भवन्तु सुखिनः।<br>
                        सर्वे सन्तु निरामयाः।<br>
                        सर्वे भद्राणि पश्यन्तु।<br>
                        मा कश्चित् दुःख भाग्भवेत्॥<br>
                        ॐ शान्तिः शान्तिः शान्तिः॥<br>
                    </p>
                    <p>हिन्दी भावार्थ:<br>
                        सभी सुखी होवें, सभी रोगमुक्त रहें, सभी का जीवन मंगलमय बनें और कोई भी दुःख का भागी न बने। हे भगवन
                        हमें ऐसा वर दो!</p>
                    <h3 class="mt-30px">Mission</h3>
                    <p>
                        <i class="fas fa-circle mr-1 f_12"></i>&nbsp;&nbsp;Dr. Axico’s mission is driven by a passion to
                        provide cure with care to make health care
                        accessible reliable and affordable.
                    </p>
                    <p>
                        <i class="fas fa-circle mr-1 f_12"></i>&nbsp;&nbsp;Dr. Axico is committed to improving community
                        wellness through
                        integrated primary healthcare.
                    </p>
                    <h4 class="mt-30px">Vision</h4>
                    <p>
                        <i class="fas fa-circle mr-1 f_12"></i>&nbsp;&nbsp;To be the future of most inclusive,
                        compassionate and trusted healthcare provider.
                    </p>
                    <p>
                        <i class="fas fa-circle mr-1 f_12"></i>&nbsp;&nbsp;To become the most leading and valued company
                        to meet the healthcare needs of the citizens.
                    </p>
                    <h4 class="mt-30px">Aim</h4>
                    <p>
                        Our aim is to fill the void between the doctors and patients with the expertise of our
                        Professional & Trustworthy Doctors where we act as the sieve of trust & sterling service to
                        build a Nation that turns towards Health Consciousness from Taste Consciousness.
                        Dr. Axico has to reach the pinnacle where people only remember us for any health-related
                        concern. With the same determination & hard work, we endeavour to make Dr Axico the No. 1 brand
                        in the health sector.
                    </p>
                    <h4 class="mt-30px">Why Dr. Axico ?</h4>
                    <p>
                        <i class="icofont-check-circled mr-1 color-blue"></i>&nbsp;&nbsp;Our strong belief in <span
                        class="font-bold">“Swasth Bharat Samarth Bharat”</span>
                        has made our goal & vision clear. <span class="font-bold">Mainly we focus on providing an effective solution to every people
                        without any side effects.</span><br>
                        <i class="icofont-check-circled mr-1 color-blue"></i><span class="font-bold">&nbsp;&nbsp;We give the solutions for every Health problem</span>,
                        you can rest assured and leave your problem with us.<br>
                        <i class="icofont-check-circled mr-1 color-blue"></i><span
                        class="font-bold">&nbsp;&nbsp;Change with Time</span> <br>The
                        blending of ancient wisdom & modern technology has become our work culture with in-depth
                        expertise in Ayurveda, we are enhancing our
                        capacity to cater to the whole spectrum of this medical system.
                        <br><i class="icofont-check-circled mr-1 color-blue"></i><span class="font-bold">&nbsp;&nbsp;Flexibility</span><br>The
                        journey
                        for providing holistic solutions at affordable prices and accountability has become
                        our success mantra. We are enriched & powered by a highly-motivated workforce and for them, the
                        wellness of mankind is a challenge and we are committed to overcoming it.
                        <br><i class="icofont-check-circled mr-1 color-blue"></i><span
                        class="font-bold">&nbsp;&nbsp;24X7 service</span><br>Our motto is
                        to give time and full support team to our patients 24x7. We provide solutions faced by our
                        customers and other people who need help as they are our first priority.
                    </p>

                </div>
            </div>

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h4 class="mt-30px">Our Expertise</h4>
                    <p>
                        <i class="icofont-check-circled mr-1 color-blue"></i>&nbsp;&nbsp;<span class="font-bold">Best Treatment Strategies</span><br>
                        Our expert team & experienced Ayurvedic Doctors together have made the treatment of complex
                        diseases very simple.
                    </p>
                    <p>
                        <i class="icofont-check-circled mr-1 color-blue"></i>&nbsp;&nbsp;<span class="font-bold">Best Medical Kits</span><br>
                        Our doctors will provide special kit of Ayurvedic medicines affined to their respective disease
                        so that you can get the advantages of Ayurveda at an ease.
                    </p>
                    <p>
                        <i class="icofont-check-circled mr-1 color-blue"></i>&nbsp;&nbsp;<span class="font-bold">Diet & Lifestyle Planners</span><br>
                        Our experts assist in selecting righteous food & adopting constructive habits.
                    </p>
                    <p>
                        <i class="icofont-check-circled mr-1 color-blue"></i>&nbsp;&nbsp;<span class="font-bold">24 Hours Doctors Availability</span><br>
                        Our Doctors are available 24*7 to serve you.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="aboutName === 'achievements' " class="about-area pt-100 pb-70">
    <div class="container mb-5">
        <div class="row align-items-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="about-item about-right">
                    <h4 class="mt-30px">Achievements</h4>
                    <p>
                        <span class="font-bold">Following awards and certificates received by the Director Prerna Aggarwal of the Axico
                            Healthcare PVT. LTD</span><br>
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <img src="assets/img/6-oct-award.jpg">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p class="mt-30px">
                        <span class="font-bold">
                            6th October 2021 - Young entrepreneur award  by Mr. Omprakash
                        Dhankad BJP Haryana chief along with Member of Parliament Sirsa Mrs. Sunita Duggal on the
                        precious occasion of Prime Minister Narendra Modi birthday.
                        </span>

                </p>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center pt-2 pb-2">
                <img src="assets/img/25-nov-award.jpeg">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p class="mt-30px">
                        <span class="font-bold">
                        25th November 2021 - Award for Excellence work in the Healthcare sector</span>
                    by FICCI and IPC event organized by Healthcopeia Foundation.
                </p>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center pt-2 pb-2">
                <img src="assets/img/18-dec-award-olds.jpeg">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>
                    <span class="font-bold">18th December 2021 Businesswomen of the year </span>worldwide impact
                    awards, 2021 organized by vishesh foundation.
                </p>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center pt-2 pb-2">
                <img src="assets/img/8-march-award.jpg">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p class="mt-30px">
                    <span class="font-bold">8th March, 2022- Awarded as a Young Entrepreneur- Delhi as Women Achievers Award organized by Arya Group of Colleges.</span>
                </p>
            </div>
        </div>
    </div>
</div>

<!--About Ayurveda-->
<div *ngIf="aboutName === 'ayurveda' " class="about-area" style="padding-top: 5rem!important;">
    <div class="container mb-5">
        <!--        <div class="row">-->
        <!--            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-lg-none text-center"-->
        <!--                 style="margin-top: 41px;">-->
        <!--                <h1 class=" mt-50px mt&#45;&#45;60px">-->
        <!--                    <span class="font-bold">About Ayurveda</span>-->
        <!--                </h1>-->
        <!--            </div>-->
        <!--            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-none d-lg-block text-center">-->
        <!--                <h1 class=" mt-50px mt&#45;&#45;60px">-->
        <!--                    <span class="font-bold">About Ayurveda</span>-->
        <!--                </h1>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="row align-items-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="about-item about-right">
                    <p>Ayurveda is in existence since the existence of life and universe. Its principles of healing are
                        based on laws of nature which are ageless and eternal. According to Ayurveda all physical matter
                        as well as our body is made up of five elements-</p>
                    <h6 class="pt-2 pb-2">Earth, Water, Ether, Air, Fire.</h6>
                    <p>
                        The only difference is that our body has a sixth element called Soul while non-living things
                        have only five elements in different ratio and proportion. The working components of these five
                        elements are represented by the three basic physiological humours.
                    </p>
                    <blockquote class="font-bold">"Hitahitam Sukham Dukham Aayustsya Hitahitam Maanam Chtachh yatroktam
                        Ayurved S Ucchayate"
                    </blockquote>
                    <p>This ancient saying defines Ayurveda as the "Science of Life Management", which explains about
                        the principles of diet and lifestyles that are good or bad for life, what leads to a happy or
                        unhappy life and what is the exact span of life, is Ayurveda.</p>
                    <blockquote class="font-bold">"Samdosha samagnisch samdhatu malkriya Prassnatma indriya man swasth
                        ityabhidhiyate"
                    </blockquote>
                    <h6 class="pt-2 pb-2">According to Ayurveda Health is a balance of these tri components. Their
                        imbalance is the cause
                        of diseases or ill health.</h6>
                    <p>Health is defined in Ayurveda in the as following:-</p>
                    <p class="font-bold">
                        Perfect balance between physical and mental bio energies, digestive and physiological fires,
                        balanced anabolism, proper excretory functions, blissful soul, senses and mind is health and
                        their imbalance is disease.
                    </p>
                    <p><span class="font-bold">Veda-</span>Vedic literature is the ancient treasure of knowledge which
                        is originated from God himself. He has created this world and has given us a manual in the form
                        of Vedas explaining how this world works. Ayurveda is a manual of life management given by God
                        explaining how to lead a healthy and happy life in every aspect. <span
                            class="font-bold">Ayur-</span> literally means life span
                        and Ayurveda is knowledge of life. It is God's manual to tell us how to lead a happy, healthy,
                        peaceful and prosperous life, preventing diseases and leading a respectful social life.<span
                            class="font-bold">'Tri-components'</span>
                        doing all the movement, metabolic reactions , providing support and nutrition.
                        These biological tri-components are called – <span class="font-bold">Vata ( Kinetic Energy) , Pitta ( Thermal Energy)
                        and Kapha (Potential Energy)</span>Vata ( Kinetic Energy) , Pitta ( Thermal Energy)
                        and Kapha (Potential Energy)</p>
                    <p>Ancient Ayurveda scholar Charaka explains about What is Ayurveda and why it is important:-</p>
                    <blockquote class="font-bold">"Dharmartha- kama- moksanam arogyam uttamam rogastasyapahartarah
                        sreyaso jivitasya ca"
                    </blockquote>
                    <p>Goals of religion or Karma, material world, sensual pleasure or liberation can be achieved only
                        if the person is healthy. The disease destroys health, well being and whole life.
                    </p>
                    <span class="font-bold">(Charka Sutra Sthana 1- 15) - </span> This ancient verse clearly indicates
                    the importance of health to
                    lead a happy, prosperous and peaceful life. Ayurvedic techniques focus on achieving that balance
                    of <span class="font-bold">Vata, Pitta, Kapha – called the trienergies.</span>
                    <p>The balance of these 'Tri-energies' can be maintained by our diet, lifestyle, different
                        Ayurvedic herbs, minerals and purification procedures.
                        Their balance is disturbed by irregular <span class="font-bold">life style, prolonged physical and mental stress, wrong
                        dietary habits, incompatible foods, misuse of senses.</span>
                        <span class="font-bold">Ayurveda</span> is a way of living in harmony with nature and guides us
                        how to maintain a steady
                        healthy state. It explains details about <span class="font-bold">body care, daily routine, diet and various foods</span>
                        to
                        promote health and maintain the balance of the <span class="font-bold">'Tri-energies'</span> .
                    </p>
                    <p>If we do not sleep early in the night, the vata (Air element) increases leading to painful
                        bones, cramps in the muscles, dark circles around eyes or sleep bags, constipation, dry mouth
                        and hastens ageing process. Stale and kept overnight food also causes increased Vata. Excessive
                        work, long travelling leads to fatigue, which is a symptom of increased Vata. This all leads to
                        diseases like joint pains, cervical pain, backache, nervousness, confusion, insomnia,
                        constipation etc.</p>
                    <p>Similarly Pitta (Fire) is excessive heat. Consuming food, herbs and our behaviour similar to
                        properties of Pitta leads to increase in Pitta. Excessive consumption of hot potency food,
                        spices, alcohol, disturbs Pitta. The seat of Pitta is liver and stomach. Excessive aggression,
                        anger, jealousy, hatred etc. leads to increased Pitta. This leads to diseases like peptic
                        ulcers, liver diseases, blood pressure etc.</p>
                    <p>
                        Excessive consumption of fat rich food, sedentary life style leads to vitiation of Kapha.
                        Excessive Kapha blocks all the nutrition and elimination channels leading to obesity, diabetes.
                        Diet and behaviour opposite to the nature of Kapha leads to cleaning up the system and balancing
                        the kapha. (Earth + Water) There are other concepts like Ojus, Aama, and Dhatus which need
                        clarification here.
                    </p>
                    <p><span class="font-bold">According to Ayurveda -</span>
                        The 'Ojus' is the vital energy present in our body that is responsible for our healthy physical
                        and mental outlook. It gives glow to our skin, strength to our heart and body. The toxins or
                        free radicals deplete the 'Ojus' within our body and there is a feeling of fatigue and
                        depression. The toxins create imbalance of the tri-energies which also has a great negative
                        impact on 'Ojus'. In Ayurveda, a lot of emphasis is laid on improving the vital energy or the
                        resistance of the person called 'Ojus".</p>
                    <p><span class="font-bold">Aama –</span>
                        It is a kind of sticky substance that forms within the body due to excessive consumption
                        of heavy, greasy food on regular basis. Aama can be understood as disturbed form of 'kapha'
                        which coats the tongue, the entire length of intestines, blocks entry and exit through micro
                        channels of the cells, clogging of arteries. This leads to congestion within the system, leading
                        to fermentation of the stale, undigested food lying in the intestines. This leads to formation
                        of endotoxins, which disturb the immunological system leading to all auto-immune diseases.
                        Herbal remedies like Sanjeevani Vati, Arogyavardhni Vati etc. clear this "Aama" and offer an
                        effective solution to such problems.
                    </p>
                    <p>Similarly there are great chances in females getting ovarian cysts, breast cancer or uterine
                        fibroids due to abortion or a miscarriage. Abortion or miscarriage leads to accumulation of left
                        over hormones/chemicals which are released from the hypothalamus and pituitary to help maintain
                        a pregnancy. After abortion, those hormones which brought changes in female reproductive organs,
                        within the breasts are no longer useful. The changes which they did are not completely undone.
                        God decided for a woman to be pregnant and give life to someone and that woman decided to abort
                        it. She suffers ultimately with problems caused by "Aama".</p>
                    <p>
                        These leftover hormones can also be understood as "Aama". Other things which can be correlated
                        with "Aama" are Rheumatoid factor, HLA B 27 and other immunological factors responsible for
                        autoimmune diseases. The digestive fire and individual tissues fire burns these factors and
                        mediators, if treated properly.
                    </p>
                    <p><span class="font-bold">
                        Dhatus (Tissues) - There are seven Dhatus which support our body. They are Rasa (Plasma) Rakta
                        (Blood) Mamsa (Muscles) Meda (Fatty tissue) Asthi (Bones) Majja (Bone Marrow) Shukra (Semen &
                        Reproductive tissue)
                    </span>
                    </p>
                    <p>
                        The <span class="font-bold">"Dhatus"</span> described in Ayurveda are the various organs and
                        parts of our body.
                    </p>
                    <h6 class="pt-2 pb-2">Eight branches of Ayurveda</h6>
                    <p>Ayurveda is one of the ancient systems of Medicine in world. The meaning of Ayurveda is "Science
                        of Life." Ayurveda is originated from two Sanskrit words ayur (life) and veda (knowledge). It is
                        broad system of treatment with different therapies and herbal remedies for treating different
                        ailments. In Ayurveda, human beings are treated on the basis of their physical and mental set up
                        so same medicines are not recommended for different persons. Ayurveda follows a principle 'One
                        man's food is another man's poison'. Ayurveda comprises different branches according to the
                        medical need of human body. It mainly uses two ways- Curative and Preventive. Preventive
                        measures mainly focus on prevention of disease. It gives more importance to regular practice
                        (Din Acharya) to prevent the disease rather than to treat health issues.</p>
                    <p>Curative methods deal with the treatment of diseased conditions.</p>
                    <p>
                        Ayurveda is categorized into eight different branches and collectively it is known as Ashtang
                        Ayurveda. Ashtang means eight parts or limbs. The basic mode of treatment of all these is same.
                        <span class="font-bold">
                            "Kaaya baala graha urdhvaanga shalya damshtra jara vrshan Ashtau angaani tasyaahuh chikitsa
                        yeshu samsthitaah."Ashtanga hrdayam
                        </span>
                    </p>
                    <p>The eight branches of Ayurveda are:</p>
                    <p>
                        <span class="font-bold">Kaaya Chikitsa (Internal medicine) Baala Chikitsa (Pediatrics treatment) Graha Chikitsa or Bhoot Vidya (Psychiatry) Urdhyaanga Chikitsa (treatment of eyes, nose, throat, head related disease) Shalyaroga Chikitsa (Surgery) Damstra Chikitsa – Agad Tantra (toxicology) Jara Chikitsa – Rasayana (Geriatrics) Vrishya Chikitsa or Vajjikarana (Aphrodisiac therapy)</span>
                    </p>
                    <p>
                        <span class="font-bold">
                        1.  Kaaya Chikitsa
                        </span> Kaaya means agni – the digestive fire in the body. It is holistic approach of
                        medicine which is mainly helpful in treatment of whole body. It is derived from Sanskrit word
                        "Chin- Chayane" which means to collect. This collection refers to food. Kaaya mainly helps in
                        food digestion and metabolism. So it is considered as Kaaya Chikitsa that means treatment of
                        disease due to metabolism problem in body. Imbalance of kaaya may occurs which results in many
                        diseases. It deals with treatment of general diseases like fever, cough by balancing agni.
                        Treatment may be internal or external.
                    </p>
                    <p>
                        <span class="font-bold">
                           Internal Treatment
                        </span> :- The medicines are given orally or through Sodhana i.e. eliminative
                        procedures of Panchakarmas means five treatments which are Vamana (Emesis), Virechana
                        (purgation), Vasti (Enema), Nasya (nasal medications) and Raktamokshna (bloodletting).
                    </p>
                    <p>
                         <span class="font-bold">
                          External Treatment
                        </span>:- It involves local application of creams (lepan), lotions and ointments.
                    </p>

                    <p>
                        <span class="font-bold">
                          2.  Baala Chikitsa
                        </span> It is also called Kaumara Bhritya. It is the branch of Ayurveda which is concerned with
                        treatment of children. In this the care of baby and mother is discussed. This branch deals with
                        child care and it includes diseases of children, causes of vitiation of breast milk, diseases
                        caused by this vitiated milk and their treatment, any infection in child. It also includes
                        methods of conception, age, gender, childhood diseases.
                    </p>
                    <p>
                        <span class="font-bold">
                          To treat children three things must be remembered:-
                        </span>
                    </p>
                    <ul>
                        <li>1- Children cannot explain their problem.</li>
                        <li>2- Dose regimen is different for children.</li>
                        <li>3- Medicines must be palatable.</li>
                        According to Acharya Harita, antenatal care and management of pueperium (end stage of pregnancy)
                        is also included in this branch.
                    </ul>
                    <ul>
                        <li><span class="font-bold">3. Graha Chikitsa</span> It is the branch of ayurvedic science which
                            deals with treatment of
                            diseases of mind. Management of psychiatric disease like Unmada and Apasmara are also under
                            this branch. Besides herbs, diet chart, yoga therapy, Deep-breathing, Mantra Chikitsa
                            etc.are used.
                        </li>
                        <li><span class="font-bold">4. Urdhyaanga Chikitsa</span> It is also called Shalakaya Tantra.
                            The Physician uses a special
                            instrument called Shalaka. It mainly deals with treatment of upper part of body that are
                            eyes, nose, throat and ears.
                        </li>
                        <li><span class="font-bold">5. Shalyaroga Chikitsa</span> Shalya means foreign, roga means
                            disease. It is also mentioned in
                            Susruta samhita which is synonym for surgery. This branch deals with surgical procedures.
                            This is done with various instruments like scissors, scalpel etc. Various operations like
                            cataract, Kidney stones, piles or even perforations of abdomen were described by
                            Sushrutacharya. Sushruta is considered as father of Plastic Surgery.
                        </li>
                        <li>
                            <span class="font-bold">6. Damstra Chikitsa </span>This branch deals with study and
                            treatment of toxins or poisons like air and water pollution, toxins in animals, minerals and
                            vegetables.
                        </li>
                        <li>
                            <span class="font-bold">7. Jara Chikitsa  </span>Jara means old age. It is
                            also known as Rasayana means rejuvenating property. It deals with various diseases
                            associated with aging process. Its main aim is to achieve long and healthy life. It includes
                            different aspects like longevity, memory, complexion, glow, strength of senses.
                        </li>
                        <li>
                            <span class="font-bold">8. Vajjikaran Chikitsa </span> (Vaji - Horse) As per
                            Ayurveda, sex is one of the important aspect of life this branch deals with pleasure and
                            satisfaction of sex. It also deals with the diseased conditions related to infertility
                            problem and problems with shukra dhatus or vital reproductive fluid in the body.
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5 pt-5">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-lg-none text-center"
                 style="margin-top: 41px;">
                <h1 class=" mt-50px mt--60px">
                    <span class="font-bold">About Phytoscience</span>
                </h1>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-none d-lg-block text-center">
                <h1 class=" mt-50px mt--60px">
                    <span class="font-bold">About Phytoscience</span>
                </h1>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="about-item about-right">
                    <ul>
                        <li>
                            <h6>What is Phyto Science ?</h6>
                            <p>Phyto Science comprises scientific study of use of medicinal plants and their bioactive
                                compounds. It owns the pride of integration of disciplines that never have been linked
                                before.It has combined the diverse areas of political, economic and social fields,
                                chemisty, biochemistry, physiology, microbiology,medicine and agriculture.</p>
                        </li>
                        <li>
                            <h6>History of Phyto Science</h6>
                            <p>To trace the history of phytotherapy is to trace the history of mankind itself. Primitive
                                people first used plants as food and as a result of this ingestion, the link with some
                                plant properties would have been learnt. Medicinal plants were the main source of
                                products used to sustain health until ninetheenth century but thereafter the synthetic
                                compounds took their place and found good space in health sector till they were found to
                                have dire impact on health in addition to which they were taken for.</p>
                        </li>
                        <li>
                            <h6>Future of Phyto Science</h6>
                            <p>In recent years there has been an increasing interest worldwide in the use of medicines
                                derived from plants or phytomedicines as health supplement or alternative therapies for
                                the treatment of diseases in both developing and developed countries.It is estimated
                                that 25% of all medicines contain plant derivatives & use plant products as their base
                                material for many semi synthetic drugs. Systematic studies of effect of herbal medicines
                                on immune system is in process to attain an evidence based scientific knowledge for the
                                use of traditional medicinal herbs.</p>
                        </li>
                        <li>
                            <h6>What are Phytochemicals ?</h6>
                            <p>The non-nutritive plant chemicals possessing disease preventive properties are called
                                Phytochemicals. They are not essential as nutrients but provide a shield to human beings
                                against diseases and body malfunctionings. Basically they provide armour to plant
                                against natural or man initiated challenges but also found helpful for preventing
                                humanbeings from varioue diseases.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
