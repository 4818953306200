import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MedicinesRoutingModule} from './medicines-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {AuthService} from '../../../shared/auth/auth.service';
import {AuthGuard} from '../../../shared/auth/auth-guard.service';
import {GlobalService} from '../../../shared/services/global.service';
import {HtpInterceptor} from '../../../shared/services/interceptor.service';
import {AlertService} from '../../../shared/services/alert.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MedicinesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SnotifyModule,
    ],
    exports: [],
    providers: [
        AuthService,
        AuthGuard,
        GlobalService,
        HtpInterceptor,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
    ]

})
export class MedicinesModule {
}
