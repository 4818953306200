<div class="page-title-area page-title-one" style="background-image: url('assets/img/banners/product.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
            </div>
        </div>
    </div>
</div>
<section class="doctors-area doctors-area-two pt-3 pt-lg-5">
    <div class="doctor-shape">
        <img alt="Shape" src="assets/img/doctor/2.png">
    </div>
    <div class="container-fluid">
        <div class="row">
<!--            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-lg-none text-center"-->
<!--                 style="margin-top: 41px;">-->
<!--                <h1 class=" mt-50px mt&#45;&#45;60px">-->
<!--                    <span class="font-bold">Products</span>-->
<!--                </h1>-->
<!--            </div>-->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1 class="">
                    <span class="font-bold">Products</span>
                </h1>
            </div>
        </div>

        <div class="row sticky-top" style="top: 74px;">
            <div class="col pe-3 d-block d-md-block d-sm-block d-lg-none">
                <ng-template #content let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Filter</h4>
                        <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close"
                                type="button"></button>
                    </div>
                    <div class="modal-body" style="overflow-y: auto;">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-0">
                                <div class="card">
                                    <div class="card-body background-color-grey">
                                        <input (keyup)="searchProduct()" [(ngModel)]="searchRanges" class="form-control"
                                               list="search" placeholder="Search Products"
                                               type="text"/>
                                        <div class="row">
                                            <div class="col-6"></div>
                                            <div class="col-4">
                                                <datalist class="float-end" id="search">
                                                    <option *ngFor="let product of searchProducts"
                                                            value="{{product?.name}}">{{product?.name}}</option>
                                                </datalist>
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5>Product Price</h5>
                            </div>
                            <form [formGroup]="filterProductPrice" action="">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 col-sm-12">
                                        <select (change)="filterPrice($event,maxPrice,'min')"
                                                aria-label="Default select example"
                                                class="form-select"
                                                name="min">
                                            <option value="0">Min</option>
                                            <option *ngFor="let price of minProductPrices" [value]="price?.price">
                                                {{price?.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-md-2 text-mobile-center">
                                        <img alt="" class="mobile-img-width"
                                             src="assets/img/buttons/arrow.png" style="width: 50%;">
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-12">
                                        <select (change)="filterPrice($event,minPrice,'max')"
                                                aria-label="Default select example"
                                                class="form-select"
                                                name="max">
                                            <option value="1500">Max</option>
                                            <option *ngFor="let price of maxProductPrices" [value]="price?.price">
                                                {{price?.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5>Product Type</h5>

                                <select (change)="filterType($event)" *ngIf="productType ; else elseBlock"
                                        [(ngModel)]="productType" class="custom-select form-control"
                                        style="cursor: pointer">
                                    <option selected value="">Select Product</option>
                                    <option *ngFor="let type of productTypes;" value="1"
                                            value="{{type}}">{{type}}</option>
                                </select>
                                <ng-template #elseBlock>
                                    <select (change)="filterType($event)" class="custom-select form-control"
                                            style="cursor: pointer">
                                        <option selected>Select Product</option>
                                        <option *ngFor="let type of productTypes;" value="1"
                                                value="{{type}}">{{type}}</option>
                                    </select>
                                </ng-template>
                            </div>
                        </div>

                        <div class="row mt-30px">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5>Customer Ratings</h5>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [(ngModel)]="rating['4']"
                                           class="form-check-input " name="rating"
                                           style="cursor: pointer" type="radio"
                                           value="4-5">
                                    <label class="form-check-label" title="4-5 Stars">
                                        4 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span></label>
                                </div>
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [(ngModel)]="rating['3']"
                                           class="form-check-input" name="rating"
                                           style="cursor: pointer" type="radio"
                                           value="3-5">
                                    <label class="form-check-label" title="3-4 Stars">
                                        3 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span>
                                    </label>
                                </div>
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [checked]="rating['2']"
                                           class="form-check-input" name="rating"
                                           type="radio" value="2-5">
                                    <label class="form-check-label" title="2-3 Stars">
                                        2 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span></label>
                                </div>
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [(ngModel)]="rating['1']"
                                           class="form-check-input " name="rating"
                                           type="radio" value="1-5">
                                    <label class="form-check-label" title="1-2 Stars">
                                        1 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="d-flex justify-content-center mx-auto">
                            <button (click)="resetProductPage()" class="btn btn-primary me-2">Reset</button>

                            <button (click)="modal.close('Save click')" class="btn btn-outline-dark"
                                    type="button">Apply
                            </button>
                        </div>
                    </div>
                </ng-template>
                <div class="d-grid gap-2">
                    <button (click)="open(content)" class="btn btn-primary" type="button">
                        <i class="fas fa-sliders-h me-2"></i>Filters
                    </button>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-3">
            <!-- Desktop Filter -->
            <div class="col-xl-3 col-lg-3 col-md-3 px-1 d-none d-md-none d-lg-block d-xl-block ">
                <div class="card sticky-bar">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-8 col-xs-8 d-flex">
                                <div class="col-6">
                                    <h4 class="mt-10">Filters</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body Burger" style="overflow-y: auto;">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-0">
                                <div class="card input-card">
                                    <div class="card-body ">
                                        <input (keyup)="searchProduct()" [(ngModel)]="searchRanges" class="form-control"
                                               list="search_id" placeholder="Search Products"
                                               type="text"/>
                                        <div class="row">
                                            <div class="col-6"></div>
                                            <div class="col-4">
                                                <datalist class="float-end" id="search_id">
                                                    <option *ngFor="let product of searchProducts"
                                                            value="{{product?.name}}">{{product?.name}}</option>
                                                </datalist>
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2 product-title">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <h5 class="">Product Price</h5>
                                <hr>
                            </div>
                            <div class="row">
                                <div class="col-lg-5 col-md-5 col-sm-12">
                                    <select (change)="filterPrice($event,maxPrice,'min')"
                                            aria-label="Default select example" class="form-select"
                                            name="min">
                                        <option value="0">Min</option>
                                        <option *ngFor="let price of minProductPrices" [value]="price?.price">
                                            {{price?.name}}</option>
                                    </select>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <img alt="icon-arrow"
                                         src="assets/img/buttons/arrow.png" style="width: 60%;padding-top: 0.6rem;">
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-12">
                                    <select (change)="filterPrice($event,minPrice,'max')"
                                            aria-label="Default select example" class="form-select"
                                            name="max">
                                        <option value="1500">Max</option>
                                        <option *ngFor="let price of maxProductPrices" [value]="price?.price">
                                            {{price?.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2 product-type-section">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5>Product Type</h5>
                            </div>
                            <select (change)="filterType($event)" *ngIf="productType ; else elseBlock"
                                    [(ngModel)]="productType" class="custom-select form-control"
                                    style="cursor: pointer">
                                <option selected value="">Select Product</option>
                                <option *ngFor="let type of productTypes;" value="1" value="{{type}}">{{type}}</option>
                            </select>
                            <ng-template #elseBlock>
                                <select (change)="filterType($event)" class="custom-select form-control"
                                        style="cursor: pointer">
                                    <option selected>Select Product</option>
                                    <option *ngFor="let type of productTypes;" value="1"
                                            value="{{type}}">{{type}}</option>
                                </select>
                            </ng-template>
                        </div>

                        <div class="row mt-1 product-type-section">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5>Customer Ratings</h5>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [(ngModel)]="rating['4']"
                                           class="form-check-input " name="rating"
                                           style="cursor: pointer" type="radio"
                                           value="4-5">
                                    <label class="form-check-label" title="4-5 Stars">
                                        4 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span></label>
                                </div>
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [(ngModel)]="rating['3']"
                                           class="form-check-input" name="rating"
                                           style="cursor: pointer" type="radio"
                                           value="3-5">
                                    <label class="form-check-label" title="3-4 Stars">
                                        3 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span>
                                    </label>
                                </div>
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [checked]="rating['2']"
                                           class="form-check-input" name="rating"
                                           type="radio" value="2-5">
                                    <label class="form-check-label" title="2-3 Stars">
                                        2 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span></label>
                                </div>
                                <div class="form-check mx-lg-4">
                                    <input (change)="ratingFilter($event)" [(ngModel)]="rating['1']"
                                           class="form-check-input " name="rating"
                                           type="radio" value="1-5">
                                    <label class="form-check-label" title="1-2 Stars">
                                        1 <i class='fas fa-star text-warning'></i>
                                        <span> & Above</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-12 text-center my-3 ">
                            <button (click)="resetProductPage()" class="btn btn-primary ">Reset Filter</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-9 col-lg-9 col-md-11 col-sm-12 col-xs-12">
                <div *ngIf="searchProducts && searchProducts.length > 0; else noDataToShow" class="row mt-10">
                    <div
                        *ngFor="let product of searchProducts"
                        [ngClass]="product.product_status==='SOLD_OUT'?'col-sm-6 col-lg-3 my-2 sold-out-effect':'col-sm-6 col-lg-3 my-2' "
                        data-wow-delay=".3s">
                        <div class="doctor-item" style="position: relative">

                            <div class="doctor-top">
                                <div *ngIf="product?.product_status!='IN_STOCK'">
                                    <div class="product-ribbon">
                                        <div *ngIf="product.product_status==='NEW'" class="new-stock-ribbon ">
                                            <span>New</span>
                                        </div>
                                        <div *ngIf="product.product_status==='SOLD_OUT'" class="sold-out-ribbon ">
                                            <span>Sold Out</span>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    *ngIf="product['product_image'] && product['product_image'][0] && product['product_image'][0]['file'] && product['product_image'][0]['file']['url']"
                                    alt="{{product?.name}}"
                                    class="cursor-pointer"
                                    routerLink="/products/{{product.url}}"
                                    src="{{product['product_image'][0]['file']['url']}}">
                                <img
                                    *ngIf="!product['product_image'] || !product['product_image'][0] || !product['product_image'][0]['file'] || !product['product_image'][0]['file']['url']"
                                    alt="{{product?.name}}" class="cursor-pointer"
                                    routerLink="/products/{{product.url}}"
                                    src="assets/img/products/dummy.jpg">
                            </div>
                            <div class="doctor-bottom text-left">
                                <h6 class="cursor-pointer product-name ml-10"
                                    routerLink="/products/{{product.url}}">
                                    {{product?.name}}
                                </h6>
                                <h6 class="cursor-pointer product-name ml-10"
                                    routerLink="/products/{{product.url}}">
                                </h6>
                                <span class="ml-10 f_16 cursor-pointer" routerLink="/products/{{product.url}}">
                                    <span class="color-blue" style="font-size: 18px">
                                        <small>&#8377;</small>{{product?.total_price | number : '1.2-2'}}
                                    </span>
                                </span>
                                <span class="mb-10 mt--5px" routerLink="/products/{{product.url}}"></span>
                                <span *ngIf="product?.rating == 5">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <!-- <i class="fas fa-star-half-alt text-warning"></i>-->
                                </span>
                                <span *ngIf="product?.rating == 4.5">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star-half-alt text-warning"></i>
                                </span>
                                <span *ngIf="product?.rating == 4">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <!-- <i class="fas fa-star-half-alt text-warning"></i>-->
                                    <i class="fas fa-star text-secondary"></i>
                                    <!-- <i class="fas fa-star-half-alt text-warning"></i>-->
                                </span>
                                <span *ngIf="product?.rating == 3.5">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star-half-alt text-warning"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                </span>
                                <span *ngIf="product?.rating == 3">
                                    <i class="fas fa-star text-warning ml-10"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-warning"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                    <i class="fas fa-star text-secondary"></i>
                                </span>
                                <span class="ml-10 f_16 cursor-pointer" routerLink="/products/{{product.url}}">
                                    <span class="color-blue" style="font-size: 18px"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #noDataToShow>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <div class="card">
                                <div class="card-body">
                                    <h5 style="margin-top: 100px; margin-bottom: 100px">No data to show</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</section>
