<div class="page-title-area page-title-one" style="background-image: url('assets/img/banners/medicinal-kits.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
            </div>
        </div>
    </div>
</div>
<section class="welcome-area ptb-50" id="self-assessment">
    <div class="container mt-5 pb-50">
        <div class="container">
            <div class="row mt-50px mb-50px">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                    <h1 class="">
                        <span class="font-bold">Self Assessment</span>
                    </h1>
                    <p style="text-align: justify;">Your health status will be revealed by taking this brief exam, which
                        will also help you make
                        better decisions. To obtain the most accurate result, you must take the following
                        precautions:</p>
                    <ol style="text-align: justify;">
                        <li>
                            <p>In your responses, be open-minded and sincere.</p>
                        </li>
                        <li>
                            <p>This test is not meant to take the place of an in-person checkup and consultation with an
                                ayurvedic physician.</p>
                        </li>
                        <li>
                            <p>The tests might be completed in about two minutes.</p>
                        </li>
                    </ol>
                    <p style="text-align: justify;">At the end of the entire exercise, you will learn your Health
                        Status.</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="justify-content-between p-3">
                <div class="quiz-header">
                    <h4 style="font-family: cursive;text-align: center;">The self-assessment questionnaire to assess
                        Prakriti*</h4>
                </div>
            </div>

            <ng-container *ngIf="!isQuizCompleted">
                <div class="d-flex justify-content-around py-3">
                    <div class="score">
                    </div>
                    <div class="question-remain">
                        <span style="font-style: italic; text-align: center;">Question {{currentQuestion + 1}}
                            of {{questionList.length}}</span>
                    </div>
                </div>
                <div class="progress mb-3">
                    <div [ngStyle]="{'width':progress+'%'}" aria-valuemax="100"
                         aria-valuemin="0"
                         aria-valuenow="25" class="progress-bar progress-bar-striped bg-success"
                         role="progressbar"></div>
                    <span></span>
                </div>
                <div class="question">
                    <div class="card">
                        <h3>{{questionList[currentQuestion]?.questionText}}</h3>
                    </div>
                </div>
                <div class="options">
                    <ol *ngFor="let option of questionList[currentQuestion]?.options">
                        <li (click)="answer(currentQuestion+1,option)">
                            <div class="card">
                                {{option.text}}
                            </div>
                        </li>
                    </ol>
                </div>
            </ng-container>

            <ng-container *ngIf="isQuizCompleted">
                <div class="row d-flex justify-content-between">
                    <img alt="" class="img-fluid col-sm-12 mx-auto"
                         src="https://icon-library.com/images/celebration-icon-png/celebration-icon-png-7.jpg"
                         style="width: 50%;">
                    <div class="result text-center col-md-6 col-sm-12">
                        <h3>Congratulations!! <br>You have completed the quiz. <br>Below is your result:</h3>
                        <p>Total Question Attempted : {{questionList.length}} </p>
                        <p>Total Correct Answered : {{correctAnswer}} </p>
                        <p>Total Wrong Answered : {{inCorrectAnswer}} </p>
                        <p>Your Score : {{points}} Points </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

