import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {AlertService} from "../../../../shared/services/alert.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Title, Meta} from '@angular/platform-browser';
import {TitleService} from "../../../../shared/services/title.service";
import {MetaService} from '../../../../shared/services/meta.service';
import {BlogService} from "../../../../shared/services/blog.service";

@Component({
    selector: 'app-blog-hindi',
    templateUrl: './blog-hindi.component.html',
    styleUrls: ['./blog-hindi.component.scss']
})
export class BlogHindiComponent implements OnInit {
    hostName: any = 'https://www.draxico.com/blogs';
    blogs: any;
    name = 'countUp Demo';
    newNum: number;
    image: any = '';
    hashtags: any = '';
    url: any = 'https://www.draxico.com/blogs/periods-problem-in-women-or-girls';
    fbid: any = '';
    english_title: any;
    blogDetails: any;
    id: any;
    blogsData: any;
    selectedBlogData: any;
    selectedBlogContent: any;
    selectedBlogMetaData: any;
    title: any;

    constructor(private route: ActivatedRoute,
                public modalService: NgbModal,
                private router: Router,
                private titleService: TitleService,
                private titleLibraryService: Title,
                private metaServices: MetaService,
                private metaService: Meta,
                private blogServices: BlogService) {
    }

    ngOnInit(): void {
        this.title = this.route.snapshot.params['blogs-name'];
        this.refresh()
    }

    refresh() {
        this.metaServices.updateCanonicalUrl(this.hostName + '/' + this.title);
        this.getBlogs();
        this.getDetailBlog();
    }

    getBlogs(params: any = {}) {
        this.blogServices.get(params).subscribe(data => {
            this.blogsData = data;
        });
    }

    goTo(url) {
        this.modalService.dismissAll('');
        this.router.navigateByUrl(url);
    }

    getDetailBlog(params: any = {}) {
        // params['english_title'] = this.english_title;
        this.blogServices.detail_hindi(this.title).subscribe(data => {
            if (data) {
                this.selectedBlogData = data;
                this.selectedBlogContent = data.content;
                this.selectedBlogMetaData = data.marketing_meta_tags;
                if (this.selectedBlogMetaData) {
                    this.titleLibraryService.setTitle(this.selectedBlogMetaData.title);
                    this.metaService.addTags([
                        {
                            name: 'keywords',
                            content: ''
                        },
                        {name: 'description', content: ''},
                        {property: 'og:title', content: ''},
                        {property: 'og:url', content: ''},
                        {property: 'og:type', content: ''},
                        {property: 'og:description', content: ''},
                        {property: 'og:image', content: ''},
                        {property: 'og:ib.scge', content: ''},
                        {property: 'og:im.scge', content: ''},
                        {property: 'og:ib.edge', content: ''},
                        {property: 'og:ib.libge', content: ''},
                        {property: 'og:im.libge', content: ''},
                        {property: 'og:ima journalismge', content: ''},
                        {property: 'og:idfmge', content: ''},
                        {property: 'og:idmmge', content: ''},
                        {property: 'og:idrmge', content: ''},
                        {name: 'twitter:card', content: ''},
                        {name: 'twitter:tblog_cover_iditle', content: ''},
                        {name: 'twitter:description', content: ''},
                        {name: 'twitter:url', content: ''},
                        {name: 'twitter:image', content: ''},
                        {name: 'twitter:ib.scge', content: ''},
                        {name: 'twitter:im.scge', content: ''},
                        {name: 'twitter:ib.edge', content: ''},
                        {name: 'twitter:ib.libge', content: ''},
                        {name: 'twitter:im.libge', content: ''},
                        {name: 'twitter:ima journalismge', content: ''},
                        {name: 'twitter:idfmge', content: ''},
                        {name: 'twitter:idmmge', content: ''},
                        {name: 'twitter:idrmge', content: ''},
                    ]);
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: this.selectedBlogData?.marketing_meta_tags?.meta_keyword
                    });
                    this.metaService.updateTag({
                        name: 'description',
                        content: this.selectedBlogMetaData.meta_description
                    });
                    this.metaService.updateTag({
                        property: 'og:title',
                        content: this.selectedBlogMetaData.meta_og_title
                    });
                    this.metaService.updateTag({
                        property: 'og:url',
                        content: this.selectedBlogMetaData.meta_og_url
                    });
                    this.metaService.updateTag({
                        property: 'og:type',
                        content: this.selectedBlogMetaData.meta_og_type
                    });
                    this.metaService.updateTag({
                        property: 'og:description',
                        content: this.selectedBlogMetaData.meta_og_description
                    });
                    this.metaService.updateTag({
                        property: 'og:image',
                        content: this.selectedBlogMetaData.meta_og_image
                    });
                    this.metaService.updateTag({
                        property: 'og:ib.scge',
                        content: this.selectedBlogMetaData.og_ib_scge
                    });
                    this.metaService.updateTag({
                        property: 'og:im.scge',
                        content: this.selectedBlogMetaData.og_im_scge
                    });
                    this.metaService.updateTag({
                        property: 'og:ib.edge',
                        content: this.selectedBlogMetaData.og_ib_edge
                    });
                    this.metaService.updateTag({
                        property: 'og:ib.libge',
                        content: this.selectedBlogMetaData.og_ib_libge
                    });
                    this.metaService.updateTag({
                        property: 'og:im.libge',
                        content: this.selectedBlogMetaData.og_im_libge
                    });
                    this.metaService.updateTag({
                        property: 'og:ima journalismge',
                        content: this.selectedBlogMetaData.og_ima_journalismge
                    });
                    this.metaService.updateTag({
                        property: 'og:idfmge',
                        content: this.selectedBlogMetaData.og_idfmge
                    });
                    this.metaService.updateTag({
                        property: 'og:idmmge',
                        content: this.selectedBlogMetaData.og_idmmge
                    });
                    this.metaService.updateTag({
                        property: 'og:idrmge',
                        content: this.selectedBlogMetaData.og_idrmge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:card',
                        content: this.selectedBlogMetaData.meta_twitter_card
                    });
                    this.metaService.updateTag({
                        name: 'twitter:title',
                        content: this.selectedBlogMetaData.meta_twitter_title
                    });
                    this.metaService.updateTag({
                        name: 'twitter:description',
                        content: this.selectedBlogMetaData.meta_twitter_description
                    });
                    this.metaService.updateTag({
                        name: 'twitter:image',
                        content: this.selectedBlogMetaData.meta_twitter_image
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ib.scge',
                        content: this.selectedBlogMetaData.twitter_ib_scge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:im.scge',
                        content: this.selectedBlogMetaData.twitter_im_scge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ib.edge',
                        content: this.selectedBlogMetaData.twitter_ib_edge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ib.libge',
                        content: this.selectedBlogMetaData.twitter_ib_libge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:im.libge',
                        content: this.selectedBlogMetaData.twitter_im_libge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:ima journalismge',
                        content: this.selectedBlogMetaData.twitter_ima_journalismge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:idfmge',
                        content: this.selectedBlogMetaData.twitter_idfmge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:idmmge',
                        content: this.selectedBlogMetaData.twitter_idmmge
                    });
                    this.metaService.updateTag({
                        name: 'twitter:idrmge',
                        content: this.selectedBlogMetaData.twitter_idrmge
                    });
                }
            }
        });
    }


    // getAllTitle() {
    //     this.titleService.getblogsTitleJson()
    //         .subscribe(res => {
    //             this.blogsUrlArr = res.title;
    //             if (this.blog && this.blogsUrlArr.length > 0) {
    //                 this.blogsUrlArr.forEach(blogUrl => {
    //                     if (blogUrl.url === this.blog) {
    //                         this.titleLibraryService.setTitle(blogUrl.title);
    //                         this.metaService.addTags([
    //                             {
    //                                 name: 'keywords',
    //                                 content: ''
    //                             },
    //                             {name: 'description', content: ''},
    //                             {property: 'og:title', content: ''},
    //                             {property: 'og:url', content: ''},
    //                             {property: 'og:type', content: ''},
    //                             {property: 'og:description', content: ''},
    //                             {property: 'og:image', content: ''},
    //                             {property: 'og:ib.scge', content: ''},
    //                             {property: 'og:im.scge', content: ''},
    //                             {property: 'og:ib.edge', content: ''},
    //                             {property: 'og:ib.libge', content: ''},
    //                             {property: 'og:im.libge', content: ''},
    //                             {property: 'og:ima journalismge', content: ''},
    //                             {property: 'og:idfmge', content: ''},
    //                             {property: 'og:idmmge', content: ''},
    //                             {property: 'og:idrmge', content: ''},
    //                             {name: 'twitter:card', content: ''},
    //                             {name: 'twitter:title', content: ''},
    //                             {name: 'twitter:description', content: ''},
    //                             {name: 'twitter:url', content: ''},
    //                             {name: 'twitter:image', content: ''},
    //                             {name: 'twitter:ib.scge', content: ''},
    //                             {name: 'twitter:im.scge', content: ''},
    //                             {name: 'twitter:ib.edge', content: ''},
    //                             {name: 'twitter:ib.libge', content: ''},
    //                             {name: 'twitter:im.libge', content: ''},
    //                             {name: 'twitter:ima journalismge', content: ''},
    //                             {name: 'twitter:idfmge', content: ''},
    //                             {name: 'twitter:idmmge', content: ''},
    //                             {name: 'twitter:idrmge', content: ''},
    //                         ]);
    //
    //                         this.metaService.updateTag({name: 'keywords', content: blogUrl.keywords});
    //                         this.metaService.updateTag({name: 'description', content: blogUrl.description});
    //                         this.metaService.updateTag({property: 'og:title', content: blogUrl.og_title});
    //                         this.metaService.updateTag({property: 'og:url', content: blogUrl.og_url});
    //                         this.metaService.updateTag({property: 'og:type', content: blogUrl.og_type});
    //                         this.metaService.updateTag({property: 'og:description', content: blogUrl.og_description});
    //                         this.metaService.updateTag({property: 'og:image', content: blogUrl.og_image});
    //                         this.metaService.updateTag({property: 'og:ib.scge', content: blogUrl.og_ib_scge});
    //                         this.metaService.updateTag({property: 'og:im.scge', content: blogUrl.og_im_scge});
    //                         this.metaService.updateTag({property: 'og:ib.edge', content: blogUrl.og_ib_edge});
    //                         this.metaService.updateTag({property: 'og:ib.libge', content: blogUrl.og_ib_libge});
    //                         this.metaService.updateTag({property: 'og:im.libge', content: blogUrl.og_im_libge});
    //                         this.metaService.updateTag({
    //                             property: 'og:ima journalismge',
    //                             content: blogUrl.og_ima_journalismge
    //                         });
    //                         this.metaService.updateTag({property: 'og:idfmge', content: blogUrl.og_idfmge});
    //                         this.metaService.updateTag({property: 'og:idmmge', content: blogUrl.og_idmmge});
    //                         this.metaService.updateTag({property: 'og:idrmge', content: blogUrl.og_idrmge});
    //                         this.metaService.updateTag({name: 'twitter:card', content: blogUrl.twitter_card});
    //                         this.metaService.updateTag({name: 'twitter:title', content: blogUrl.twitter_title});
    //                         this.metaService.updateTag({
    //                             name: 'twitter:description',
    //                             content: blogUrl.twitter_description
    //                         });
    //                         this.metaService.updateTag({name: 'twitter:image', content: blogUrl.twitter_image});
    //                         this.metaService.updateTag({name: 'twitter:ib.scge', content: blogUrl.twitter_ib_scge});
    //                         this.metaService.updateTag({name: 'twitter:im.scge', content: blogUrl.twitter_im_scge});
    //                         this.metaService.updateTag({name: 'twitter:ib.edge', content: blogUrl.twitter_ib_edge});
    //                         this.metaService.updateTag({name: 'twitter:ib.libge', content: blogUrl.twitter_ib_libge});
    //                         this.metaService.updateTag({name: 'twitter:im.libge', content: blogUrl.twitter_im_libge});
    //                         this.metaService.updateTag({
    //                             name: 'twitter:ima journalismge', content: blogUrl.twitter_ima_journalismge
    //                         });
    //                         this.metaService.updateTag({name: 'twitter:idfmge', content: blogUrl.twitter_idfmge});
    //                         this.metaService.updateTag({name: 'twitter:idmmge', content: blogUrl.twitter_idmmge});
    //                         this.metaService.updateTag({name: 'twitter:idrmge', content: blogUrl.twitter_idrmge});
    //                     }
    //
    //                 });
    //             }
    //         });
    // }

}
