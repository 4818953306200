import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/layouts/preloader/preloader.component';
import {FooterComponent} from './components/layouts/footer/footer.component';
import {NavbarComponent} from './components/layouts/navbar/navbar.component';
import {AboutComponent} from './components/pages/about/about.component';
import {AppointmentComponent} from './components/pages/appointment/appointment.component';
import {DepartmentsComponent} from './components/pages/departments/departments.component';
import {SignupComponent} from './components/pages/signup/signup.component';
import {LoginComponent} from './components/pages/login/login.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {TermsConditionComponent} from './components/pages/terms-condition/terms-condition.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {DoctorDetailsComponent} from './components/pages/doctor-details/doctor-details.component';
import {DoctorComponent} from './components/pages/doctor/doctor.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {BlogDetailsComponent} from './components/pages/blog-details/blog-details.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {GlobalService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {MedicinesComponent} from './components/pages/medicines/medicines.component';
import {MedicinesModule} from './components/pages/medicines/medicines.module';
import {UserDashboardComponent} from './components/pages/user-dashboard/user-dashboard.component';
import {CommonModule} from '@angular/common';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ConsultComponent} from './components/pages/consult/consult.component';
import {PrescriptionsComponent} from './components/pages/prescriptions/prescriptions.component';
import {
    PharmacyProductDetailsComponent
} from './components/pages/pharmacy-product-details/pharmacy-product-details.component';
import {PharmacyNavbarComponent} from './components/layouts/pharmacy-navbar/pharmacy-navbar.component';
import {CartComponent} from './components/pages/cart/cart.component';
import {CheckoutComponent} from './components/pages/checkout/checkout.component';
import {EstoreComponent} from './components/pages/estore/estore.component';
import {BmiCalculatorComponent} from './components/pages/bmi-calculator/bmi-calculator.component';
import {PoundPipe} from './shared/pipe/usunit/pound.pipe';
import {GramPipe} from './shared/pipe/metricunit/gram.pipe';
import {SafePipe} from "./shared/pipe/safePipe/safe.pipe";
import {TestingComponent} from './components/pages/testing/testing.component';
import {LearnMoreComponent} from './components/pages/learn-more/learn-more.component';
import {MedicinalKitsComponent} from './components/pages/medicinal-kits/medicinal-kits.component';
import {UserAppointmentsComponent} from './components/pages/user-appointments/user-appointments.component';
import {SelfAssessmentComponent} from './components/pages/self-assessment/self-assessment.component';
import {SelfAssessmentsComponent} from './components/pages/self-assessments/self-assessments.component';
import {AwardsComponent} from './components/pages/awards/awards.component';
import {DiseasesComponent} from './components/pages/diseases/diseases.component';
import {DiseasesDetailsComponent} from './components/pages/diseases-details/diseases-details.component';
import {SuccessComponent} from './components/pages/success/success.component';
import {FailedComponent} from './components/pages/failed/failed.component';
import {OrdersComponent} from './components/pages/orders/orders.component';
import {MomentDatePipe} from './shared/pipe/moment-date.pipe';
import {ProductsComponent} from './components/pages/products/products.component';
import {SearchFilterPipe} from './shared/pipe/search-filter.pipe';
import {BlogHindiComponent} from './components/pages/blog-details/blog-hindi/blog-hindi.component';
import {PrakritiComponent} from './components/pages/prakriti/prakriti.component';
import {WelcomeComponent} from './components/pages/prakriti/welcome/welcome.component';
import {QuestionComponent} from './components/pages/prakriti/question/question.component';
import {PaymentPolicyComponent} from './components/pages/payment-policy/payment-policy.component';
import {PanchakarmaTherapiesComponent} from './components/pages/panchakarma-therapies/panchakarma-therapies.component';
import {
    PanchakarmaTherapiesDetailsComponent
} from './components/pages/panchakarma-therapies-details/panchakarma-therapies-details.component';
import {OwlModule} from "ngx-owl-carousel";
import {OurOutletsComponent} from './components/pages/our-outlets/our-outlets.component';
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {MeetingComponent} from './components/pages/meeting/meeting.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        AboutComponent,
        AppointmentComponent,
        DepartmentsComponent,
        SignupComponent,
        LoginComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        FaqComponent,
        ErrorComponent,
        DoctorDetailsComponent,
        DoctorComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactComponent,
        MedicinesComponent,
        UserDashboardComponent,
        ProfileComponent,
        ConsultComponent,
        PrescriptionsComponent,
        PharmacyProductDetailsComponent,
        PharmacyNavbarComponent,
        CartComponent,
        CheckoutComponent,
        EstoreComponent,
        BmiCalculatorComponent,
        PoundPipe,
        GramPipe,
        SafePipe,
        TestingComponent,
        ProductsComponent,
        LearnMoreComponent,
        MedicinalKitsComponent,
        UserAppointmentsComponent,
        SelfAssessmentComponent,
        SelfAssessmentsComponent,
        AwardsComponent,
        DiseasesComponent,
        DiseasesDetailsComponent,
        SuccessComponent,
        FailedComponent,
        OrdersComponent,
        MomentDatePipe,
        SearchFilterPipe,
        BlogHindiComponent,
        PrakritiComponent,
        WelcomeComponent,
        QuestionComponent,
        PaymentPolicyComponent,
        PanchakarmaTherapiesComponent,
        PanchakarmaTherapiesDetailsComponent,
        OurOutletsComponent,
        MeetingComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SnotifyModule,
        MedicinesModule,
        OwlModule,
        NgbPaginationModule,
    ],
    providers: [AuthService,
        AuthGuard,
        GlobalService,
        HtpInterceptor,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
