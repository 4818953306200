<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Remove Item</h4>
        <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div class="modal-body">
        <p>
            Are you sure you want to remove this item?</p>
    </div>
    <div class="modal-footer">
        <button (click)="removeItem()" class="btn btn-primary" type="button">REMOVE</button>
    </div>
</ng-template>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Address</h4>
        <button (click)="d('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div class="modal-body">
        <div class="appointment-form p-10">
            <form [formGroup]="appointmentForm" novalidate>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class=" icofont-address-book"></i>
                            <label class="p-10">Address Line 1</label>
                            <input class="form-control" formControlName="name" id="address-line1" placeholder="Enter Your Address"
                                   type="text">
                            <small *ngIf="submitted && appointmentForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class=" icofont-address-book"></i>
                            <label class="p-10">Address Line 2</label>
                            <input class="form-control" formControlName="name" id="address-line2" placeholder="Enter Your Address"
                                   type="text">
                            <small *ngIf="submitted && appointmentForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class=" icofont-location-pin"></i>
                            <label class="p-10">Land Mark</label>
                            <input class="form-control" formControlName="landmark" id="landmark" placeholder="Enter Your landmark"
                                   type="text">
                            <small *ngIf="submitted && appointmentForm.get('age').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class="icofont-map-pins"></i>
                            <label class="p-10">City</label>
                            <input class="form-control" formControlName="city" id="city" placeholder="Enter City"
                                   type="text">
                            <small *ngIf="submitted && appointmentForm.get('symptoms').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class="icofont-code"></i>
                            <label class="p-10">Pin Code</label>
                            <input class="form-control" formControlName="pinCode" id="pin-code" placeholder="Enter Pincode" rows="3"
                                   type="number">
                            <small *ngIf="submitted && appointmentForm.get('doctor').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class="icofont-map-pins"></i>
                            <label class="p-10">State</label>
                            <select class="form-control" formControlName="state" id="state">
                                <option>Select State</option>
                                <option></option>
                            </select>
                            <small *ngIf="submitted && appointmentForm.get('gender').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class=" icofont-ui-message"></i>
                            <label class="p-10">Email</label>
                            <input class="form-control" formControlName="email" id="email" placeholder="Enter Your Email"
                                   type="email">
                            <small *ngIf="submitted && appointmentForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <i class=" icofont-ui-call"></i>
                            <label class="p-10">Contact no</label>
                            <input class="form-control" formControlName="contact" id="contact" placeholder="Enter Your Contact Number"
                                   type="text">
                            <small *ngIf="submitted && appointmentForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                </div>
                <div class=" mt-20" style="float: right">
                    <button (click)="c('Save click')" class="btn btn-outline-dark" type="button">Submit</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<div class="page-title-area page-title-profile">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Prescription</h2>
            </div>
        </div>
    </div>
</div>
<section class="blog-area-two mt-100px margin-top-xs-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-xs-12">
                <div class="card">
                    <div class="card-header" style="background-color: #2874f0;">
                        <h5 style="color: white; margin-top: 8px">My Prescriptions</h5>
                    </div>
                    <div *ngIf="items && items.length > 0; else noProductToShow" class="card-body">
                        <div *ngFor="let item of items; let i = index" class="row mt-20 shadow-upper-light">
                            <div class="col-lg-3 col-xs-12 text-center mt-15px">
                                <img (click)="goTo('product-detail/'+item?.product?.id)" class="mb-20 cursor-pointer"
                                     height="100px"
                                     src="{{item?.product?.product_image[0]?.file?.url}}" width="50px">
                            </div>
                            <div class="col-lg-9 col-xs-12 mt-40px">
                                <h5 (click)="goTo('product-detail/'+item?.product?.id)"
                                    class="cursor-pointer">{{item?.product?.name}}</h5>
                                <p>
                                    <span>Price</span>
                                    <span class="ml-10">&#x20B9;{{item?.product?.discounted_price}}</span>
                                    <span class="ml-10"><del>&#x20B9;{{item?.product?.actual_price}}</del></span>
                                    <span
                                        class="color-red ml-10">({{(100 - (item?.product?.discounted_price / item?.product?.actual_price) * 100) | number : '1.2-2'}}
                                        %) off</span>
                                </p>
                            </div>
                            <div class="row">
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                    <div aria-label="Basic example" class="btn-group" role="group"
                                         style="margin-left: 13%;">
                                        <div aria-label="Basic example" class="btn-group" role="group"
                                             style="height: 33.5px;">
                                            <button (click)="decrement()" class="btn btn-primary" type="button">-
                                            </button>
                                            <button class="btn btn-primary" type="button">{{counter}}</button>
                                            <button (click)="increment()" class="btn btn-primary" type="button">+
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-12 text-right">
                                    <i (click)="remove()"
                                       class="fas fa-trash text-danger mr-10 cursor-pointer mt-10"></i><span (click)="remove()"
                                                                    class="ml-10 cursor-pointer text-info">Remove</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #noProductToShow>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="card" style="padding: 100px">
                                        <div class="card-body text-center">
                                            <h5>No data to show</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div *ngIf="items && items.length > 0" class="card-footer">
                        <button class="btn btn-outline-primary text-center"
                                style="float: right;background-color: #fb641b; color: white; height: 48px; border: none;">
                            <p style="margin-top: 2%;"><a href="#" style="color: white;">Continue Shopping</a><i
                                class="icofont-arrow-right ml-10"></i>
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
