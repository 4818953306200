<section class="section my-0 my-lg-5">
    <div *ngIf="selectedBlogData?.['hindi_title']" class="container">
        <div class="row justify-content-center mb-5">
            <div class="col-12 d-flex justify-content-center tablet-top-margin">
                <label class="switch custom-switch-btn custom-label" style="cursor:pointer">
                    <input (click)="translateContent()"
                           id="color_modes"
                           name="color_mode" type="checkbox" value="1">
                    <label class="custom-switch-btn-inner custom-label" data-off="English" data-on="Hindi"
                           for="color_modes"></label>
                </label>
            </div>
        </div>
    </div>
    <div *ngIf="!languageType" class="container">
        <div class="row">
            <!-- BLog Start -->
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="card blog blog-detail border-0 shadow rounded">
                    <img alt="" class="img-fluid rounded-top" src=""/>
                    <div class="card-body content p-0">
                        <img *ngIf="selectedBlogData && selectedBlogData?.english_banner?.url;else imageNotFound"
                             [src]="selectedBlogData?.english_banner?.url"
                             alt="Blog"
                             style="width: 100%;"/>
                        <ng-template #imageNotFound>
                            <img alt="Blog" src="assets/img/banner.png"
                                 style=" width: 100%;"/>
                        </ng-template>
                        <div class="px-3">
                            <h1 class="mt-2"><span
                                class="font-bold">{{selectedBlogData && selectedBlogData?.heading |uppercase}}</span>
                            </h1>
                            <p *ngIf="selectedBlogData && selectedBlogData.content"
                               [innerHTML]="selectedBlogData.content"
                               class="text-dark text-justify"></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- BLog End -->
            <!-- START SIDEBAR -->
            <div class="col-lg-4 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 sidebar sticky-bar ms-lg-4">
                    <div class="card-body p-0">
                        <div class="widget mt-4">
                            <span class="bg-soft-dark d-block py-2 rounded shadow text-center h6 mb-0 text-muted"
                                  style="background-color: #ebecee;">
                                Recent Post
                            </span>
                            <div class="mt-4">
                                <div *ngFor="let blog of blogsData  | slice:0:5; let i = index"
                                     class="clearfix post-recent pb-1">
                                    <div class="post-recent-content float-start">
                                        <a (click)="goTo('blogs/'+blog?.title)" class="cursor-pointer">
                                            <p class="text-dark text-justify mb-0 customize_heading">
                                                {{i + 1}}. {{ blog.heading | lowercase}}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widget mt-4">
                            <span class="bg-soft-dark d-block py-2 rounded shadow text-center h6 mb-0 text-muted"
                                  style="background-color: #ebecee;">
                                Tagclouds
                            </span>
                            <div class="tagcloud text-center mt-4">
                                <a class="rounded" routerLink="/appointment">Book Appointment</a>
                                <a class="rounded" routerLink="/medicinal-kits">Medicinal Kits</a>
                                <a class="rounded" routerLink="/products">Products</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--    Hindi Blog section -->
    <div *ngIf="languageType" class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div class="card blog blog-detail border-0 shadow rounded">
                    <img alt="" class="img-fluid rounded-top" src=""/>
                    <div class="card-body content p-0">
                        <img *ngIf="selectedBlogData && selectedBlogData?.english_banner?.url;else imageNotFound"
                             [src]="selectedBlogData?.hindi_blog?.blog_cover?.url"
                             alt="Blog"
                             style="width: 100%"/>
                        <ng-template #imageNotFound>
                            <img alt="Blog" src="assets/img/banner.png"
                                 style=" width: 100%;"/>
                        </ng-template>
                        <div class="px-3">
                            <h1 class="mt-2"><span
                                class="font-bold">{{selectedBlogData && selectedBlogData?.hindi_blog?.heading |uppercase}}</span>
                            </h1>
                            <p *ngIf="selectedBlogData && selectedBlogData?.hindi_blog?.content"
                               [innerHTML]="selectedBlogData?.hindi_blog?.content"
                               class="text-dark text-justify"></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- BLog End -->
            <!-- START SIDEBAR -->
            <div class="col-lg-4 col-md-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 sidebar sticky-bar ms-lg-4">
                    <div class="card-body p-0">
                        <div class="widget mt-4">
                            <span class="bg-soft-dark d-block py-2 rounded shadow text-center h6 mb-0 text-muted"
                                  style="background-color: #ebecee;">
                                Recent Post
                            </span>
                            <div class="mt-4">
                                <div *ngFor="let blog of blogsData  | slice:0:5; let i = index"
                                     class="clearfix post-recent pb-1">
                                    <div class="post-recent-content float-start">
                                        <a (click)="goTo('blogs/'+blog?.title)" class="cursor-pointer">
                                            <p class="text-dark text-justify mb-0 customize_heading">
                                                {{i + 1}}. {{ blog.heading | lowercase}}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widget mt-4">
                            <span class="bg-soft-dark d-block py-2 rounded shadow text-center h6 mb-0 text-muted"
                                  style="background-color: #ebecee;">
                                Tagclouds
                            </span>
                            <div class="tagcloud text-center mt-4">
                                <a class="rounded" routerLink="/appointment">Book Appointment</a>
                                <a class="rounded" routerLink="/medicinal-kits">Medicinal Kits</a>
                                <a class="rounded" routerLink="/products">Products</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container mt-5" style="margin-top: -37px;">
    <dl>
        <dt style="color: #444a97;">Disclaimer</dt>
        <dd style="color: #f41522;">
            <marquee onMouseOut="this.start()" onMouseOver="this.stop()">
                Information on this site is provided for informational purpose and is not meant to substitute the advice
                provided by physician or any other medical professional.
            </marquee>
        </dd>
    </dl>
</div>
