import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService extends BaseService {
    //contactUsPostUrl = "http://localhost:8000/api/mainContactUs";

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    postContactus(contactUsFormData: any) {
        //return this.http.post(this.contactUsPostUrl, contactUsFormData);
        return this.postRequest(AppUrl.CONTACTUS(), contactUsFormData);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.APPOINTMENTS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.APPOINTMENTS_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.APPOINTMENTS(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.APPOINTMENTS(), data);
    }

    verifyOtp(data): Observable<any> {
        return this.postRequest(AppUrl.OTP_VERIFY(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.APPOINTMENTS(id), data);
    }


  /*  updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.APPOINTMENTS_STATUS(id), data);
    }


    getAdminReportCount(id): Observable<any> {
        return this.getRequest(AppUrl.APPOINTMENTS_PROGRESS_REPORT_COUNT(id));
    }*/

    appointmentForm(data): Observable<any> {
        return this.postRequest(AppUrl.APPOINMENT_FORM(), data);
    }
}
