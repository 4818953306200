<div class="page-title-area page-title-two" style="background-image: url('assets/img/banners/consult.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2 class="mt-50px" style="color: #467e41;">
                    <span>Get Your  Appointment</span>
                </h2>
            </div>
        </div>
    </div>
</div>

<section class="appointment-area-two">
    <div class="container">
        <ng-template #content>
            <div id="modal">
                <div class="modal-header font-weight-bold">
                    <h4 class="modal-title font-weight-bold">
                        <span class="ml-10">Enter OTP sent to your Contact No.</span>
                    </h4>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <form [formGroup]="OtpForm" class="form mb--8px" novalidate>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <fieldset class="form-group">
                                            <input class="form-control" formControlName="otp" placeholder="Enter OTP"
                                                   type="number">
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="submitOtp(content2)" [disabled]="!OtpForm.valid"
                            class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                            type="submit"><b>
                        Submit</b></button>
                </div>
            </div>
        </ng-template>

        <ng-template #content2 let-modal2>
            <div id="modal2">
                <div class="modal-header font-weight-bold">
                    <h4 class="modal-title font-weight-bold">
                        <i class="fa-lock fas cursor-pointer mr-2"></i>
                        <span class="ml-10">Appointment Details</span>
                    </h4>
                    <button (click)="modal2.close('Save click')" aria-label="Close" class="btn-close"
                            type="button"></button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-header">
                            <p class="text-success text-center">
                                Hey {{this.appointment.name}}, Your appointment booking process is initiated
                                successfully, Your Appointment Id is {{this.appointment.appointment_id}}.
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col" style="text-align: -webkit-center;">
                                    <img alt="" class="mx-auto" height="100px" src="assets/img/checkmark.gif"
                                         width="100px">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group" style="text-align: center;">
                                        <button (click)="modal2.close('Save click')" class="btn btn-outline-dark"
                                                type="button">close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="row align-items-center appointment-wrap-two">
            <div class="col-lg-7 wow fadeInUp" data-wow-delay=".3s">
                <div class="appointment-item appointment-item-two">
                    <div class="appointment-shape d-none d-md-block">
                        <img alt="Shape" src="assets/img/appointment/3.png">
                    </div>
                    <h2>Book your appointment</h2>
                    <span>We will confirm your  appointment within 2 hours</span>
                    <div class="appointment-layer">
                        <div class="appointment-form">
                            <form [formGroup]="appointmentForm" novalidate>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <i class="icofont-business-man-alt-1"></i>
                                            <label>Name</label>
                                            <input class="form-control" formControlName="name" placeholder="Enter your name"
                                                   type="text">
                                            <small *ngIf="submitted && appointmentForm.get('name').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <i class="icofont-ui-call"></i>
                                            <label>Phone</label>
                                            <input class="form-control" formControlName="contact"
                                                   placeholder="Enter your number"
                                                   type="number">
                                            <small *ngIf="submitted && appointmentForm.get('contact').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <i class="icofont-business-man"></i>
                                            <label>Age</label>
                                            <input class="form-control" formControlName="age" placeholder="Your Age"
                                                   type="number">
                                            <small *ngIf="submitted && appointmentForm.get('age').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <i class="icofont-user-alt-1"></i>
                                            <label>Gender</label>
                                            <select class="form-control" formControlName="gender">
                                                <option value="">Select gender</option>
                                                <option value="MALE">Male</option>
                                                <option value="FEMALE">Female</option>
                                                <option value="OTHER">Transgender</option>
                                            </select>
                                            <small *ngIf="submitted && appointmentForm.get('gender').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class="icofont-hospital"></i>
                                            <label>Symptoms</label>
                                            <input class="form-control" formControlName="symptoms"
                                                   placeholder="Enter your symptoms"
                                                   type="text">
                                            <small *ngIf="submitted && appointmentForm.get('symptoms').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button (click)="submit(content)" class="btn btn-outline-primary btn-lg" type="submit"><b>
                                        Submit</b></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!--            <div class="col-lg-5 wow fadeInUp" data-wow-delay=".5s" style="margin-top: 100px">-->
            <!--                <div class="appointment-item-two-right" style="padding: 40px">-->
            <!--                    <div class="appointment-item-content" style="padding: 47px 33px 59px; !important;">-->
            <!--                        <h2>Working Hours</h2>-->
            <!--                        <div class="content-one">-->
            <!--                            <ul>-->
            <!--                                <li>Monday</li>-->
            <!--                                <li>Tuesday</li>-->
            <!--                                <li>Wednesday</li>-->
            <!--                                <li>Thursday</li>-->
            <!--                                <li>Friday</li>-->
            <!--                                <li>Saturday</li>-->
            <!--                            </ul>-->
            <!--                        </div>-->
            <!--                        <div class="content-two">-->
            <!--                            <ul>-->
            <!--                                <li>10:00 am - 6:00 pm</li>-->
            <!--                                <li>10:00 am - 6:00 pm</li>-->
            <!--                                <li>10:00 am - 6:00 pm</li>-->
            <!--                                <li>10:00 am - 6:00 pm</li>-->
            <!--                                <li>10:00 am - 6:00 pm</li>-->
            <!--                                <li>10:00 am - 6:00 pm</li>-->
            <!--                            </ul>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="col-lg-5 wow fadeInUp marzin-zero" data-wow-delay=".5s" style="margin-top: 115px">
                <div class="card p-5 mobile-padding-zero"
                     style="background-color: rgba(0, 0, 0, 0.2);border-radius: 10px;">
                    <div class="card-body" style="background-color: #e7e7e7;border-radius: 10px;">
                        <h2 style="color: #0046c0;font-weight: 600;font-size: 24px;margin-bottom: 30px;">Working
                            Hours</h2>
                        <div class="row">
                            <div class="col-4 px-0 px-sm-2"><p>Monday</p></div>
                            <div class="col-8"><p>10:00 am - 6:00 pm</p></div>
                            <div class="col-4 px-0 px-sm-2"><p>Tuesday</p></div>
                            <div class="col-8"><p>10:00 am - 6:00 pm</p></div>
                            <div class="col-4 px-0 px-sm-2"><p>Wednesday</p></div>
                            <div class="col-8"><p>10:00 am - 6:00 pm</p></div>
                            <div class="col-4 px-0 px-sm-2"><p>Thursday</p></div>
                            <div class="col-8"><p>10:00 am - 6:00 pm</p></div>
                            <div class="col-4 px-0 px-sm-2"><p>Friday</p></div>
                            <div class="col-8"><p>10:00 am - 6:00 pm</p></div>
                            <div class="col-4 px-0 px-sm-2"><p>Saturday</p></div>
                            <div class="col-8"><p>10:00 am - 6:00 pm</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
