import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AddToCartService} from "../../../shared/services/add-to-cart.service";
import {StorageService} from "../../../shared/services/storage.service";
import {DataService} from "../../../shared/services/data.service";
import {ProductService} from "../../../shared/services/product.service";
import {NavbarService} from "../../../shared/services/navbar.service";

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
    submitted: any = false;
    submitting: any = false;
    items: any = [];
    nouseritems: any = [];
    checkstore: any = [];
    discountSum: any;
    localCart: any;
    actualSum: any;
    subTotalSum: any;
    ccc: any;
    discountedPriceSum: any = [];
    couponDiscountedPrice: any = []
    couponDiscountedPriceSum: any = []
    user: any;
    noUserData: any;
    getnouserproduct: any;
    objects: any[] = JSON.parse(localStorage.getItem('objects') || '[]');

    constructor(public modalService: NgbModal,
                private productService: ProductService,
                public router: Router,
                public addToCartService: AddToCartService,
                public dataService: DataService,
                public navbarService: NavbarService) {
        this.user = StorageService.getItem('self');
        if (!this.user || !this.user.user_id) {
            this.router.navigate(['/cart']).then(() => {
                this.fetchData();
            });
        }

    }

    ngOnInit(): void {
        if (localStorage.getItem('localProducts')) {
            const products = JSON.parse(localStorage.getItem('localProducts'));
            this.navbarService.passValue(products.length);
        }

        if (!(this.user)) {
        } else {
            this.updateLocalData();
        }
    }

    updateLocalData() {
        this.submitting = true;
        this.items = [];
        this.localCart = localStorage.getItem("localProducts");
        this.localCart = JSON.parse(this.localCart);
        if (this.localCart && this.localCart.length > 0) {
            this.submitting = true;
            Object.entries(this.localCart).forEach(([key, value]) => {
                let params = {
                    'product_id': value['product'].id,
                    'quantity': (value['quantity']),
                    'user_id': (this.user && this.user['user_id']) ? this.user['user_id'] : ''
                };
                this.addToCartService.create(params).subscribe(data => {
                    this.submitting = false;
                }, error => {
                    this.submitting = false;
                });
            });
            localStorage.removeItem('localProducts')
            setTimeout(() => this.get(), 500);

        } else {
            this.get();
        }


    }

    get() {
        this.addToCartService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.items = data['data'];
                this.items.forEach(data => {
                    if (data && data['product'] && data['product']['discounted_price'] && data['product']['igst']) {
                        data['product']['total_price'] = Math.round(parseFloat(data['product']['discounted_price']) + (parseFloat(data['product']['discounted_price']) * parseFloat(data['product']['igst']) / 100));
                        data['product']['actual_total_price'] = Math.round(parseFloat(data['product']['actual_price']) + (parseFloat(data['product']['actual_price']) * parseFloat(data['product']['igst']) / 100));
                    }
                });
                this.getCalculations();
                this.navbarService.passValue(this.items.length);

            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }


    increment(item) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'quantity': parseInt(item['quantity']) + 1
            };
            if (this.user) {
                this.submitting = true;
                this.addToCartService.updateQuantity(item.id, params).subscribe(data => {
                    this.get();
                });
            } else {
                let products: any = localStorage.getItem('localProducts');
                products = JSON.parse(products);
                products.forEach((items) => {
                    if (items.product.id === item['product']['id']) {
                        items['quantity'] = JSON.parse(items['quantity']) + 1;
                        localStorage.removeItem('localProducts');
                        localStorage.setItem('localProducts', JSON.stringify(products));
                        this.fetchData();
                    }
                });
            }
        }
    }

    decrement(item) {
        if (item && item['quantity'] > 1) {
            this.submitted = true;
            if (this.submitted) {
                const params = {
                    'quantity': parseInt(item['quantity']) - 1
                };
                this.submitting = true;

                if (this.user) {
                    this.submitting = true;
                    this.addToCartService.updateQuantity(item.id, params).subscribe(data => {
                        this.get();
                    });
                } else {
                    let products: any = localStorage.getItem('localProducts');
                    products = JSON.parse(products);
                    products.forEach((items) => {
                        if (items.product.id === item['product']['id']) {
                            items['quantity'] = JSON.parse(items['quantity']) - 1;
                            localStorage.removeItem('localProducts');
                            localStorage.setItem('localProducts', JSON.stringify(products));
                            this.fetchData();
                        }
                    });
                }
            }
        } else {
            this.remove(item);
        }
    }

    getCalculations() {
        let discount_arr = [];
        let discounted_price_arr = [];
        let actual_price_arr = [];
        this.items.forEach(item => {
            discount_arr.push(Math.round((parseFloat(item['product']['actual_total_price']) * parseFloat(item['quantity'])) - (parseFloat(item['product']['total_price']) * parseFloat(item['quantity']))));
            discounted_price_arr.push(Math.round(parseFloat(item['product']['actual_total_price']) * parseFloat(item['quantity'])));
            actual_price_arr.push(Math.round(parseFloat(item['product']['actual_total_price']) * parseFloat(item['quantity'])));
        });
        this.discountSum = discount_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.actualSum = actual_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.actualSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.subTotalSum = this.actualSum - this.discountSum;
        this.subTotalSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.discountedPriceSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    }

    submit() {
    }

    checkoutPage() {
        this.router.navigateByUrl('checkout');
    }

    shoppingPage() {
        this.router.navigateByUrl('products');
    }

    remove(item) {
        this.submitted = true;
        if (this.submitted) {
            this.submitting = true;
            this.addToCartService.delete(item.id).subscribe(data => {
                this.get();
            });
        }
    }

    removenouseritem(item) {

        if (this.user) {
            this.submitted = true;
            if (this.submitted) {
                localStorage.removeItem('localProducts')
                this.submitted = true;
            }
        } else {
            let products: any = localStorage.getItem('localProducts');
            products = JSON.parse(products);
            products.forEach((items, index) => {
                if (items.product.id === item['product']['id']) {
                    products.splice(index, 1);
                    localStorage.removeItem('localProducts');
                    localStorage.setItem('localProducts', JSON.stringify(products));
                    this.navbarService.passValue(products.length);
                    this.fetchData();
                }
            });

        }
    }


    goTo(url) {
        this.router.navigateByUrl(url);
    }

    login() {
        this.router.navigateByUrl('login');
    }

    prodcuturl() {
        this.router.navigateByUrl('products')
    }

    fetchData() {
        const storedData = localStorage.getItem('localProducts');
        if (storedData) {
            this.objects = JSON.parse(storedData);
            this.ccc = this.objects.length;
            if (this.objects && this.objects.length > 0) {
                this.nouseritems = this.objects;
                this.nouseritems.forEach(data => {
                    if (data && data['product'] && data['product']['discounted_price'] && data['product']['igst']) {
                        data['product']['total_price'] = Math.round(parseFloat(data['product']['discounted_price']) + (parseFloat(data['product']['discounted_price']) * parseFloat(data['product']['igst']) / 100));
                        data['product']['actual_total_price'] = Math.round(parseFloat(data['product']['actual_price']) + (parseFloat(data['product']['actual_price']) * parseFloat(data['product']['igst']) / 100));
                    }
                    this.noUsergetCalculations();
                });
            }
        }
    }

    noUsergetCalculations() {
        let discount_arr = [];
        let discounted_price_arr = [];
        let actual_price_arr = [];
        this.nouseritems.forEach(item => {
            discount_arr.push(Math.round((parseFloat(item['product']['actual_total_price']) * parseFloat(item['quantity'])) - (parseFloat(item['product']['total_price']) * parseFloat(item['quantity']))));
            discounted_price_arr.push(Math.round(parseFloat(item['product']['actual_total_price']) * parseFloat(item['quantity'])));
            actual_price_arr.push(Math.round(parseFloat(item['product']['actual_total_price']) * parseFloat(item['quantity'])));
        });
        this.discountSum = discount_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.actualSum = actual_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.actualSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.subTotalSum = this.actualSum - this.discountSum;
        this.subTotalSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        this.discountedPriceSum = discounted_price_arr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    }
}
